import * as React from "react";
import { useTranslation } from "react-i18next";
import EditVarietyModal, { EditVarietyModalProps, useVarietyModal } from "./";

interface EditVarietyModalWithButtonProps
  extends Omit<EditVarietyModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditVarietyModalWithButton: React.FC<EditVarietyModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useVarietyModal({});

  const label = buttonLabel ?? t("edit");

  const validVarietyId = props.varietyId != null && props.varietyId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validVarietyId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validVarietyId ? (
        <EditVarietyModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditVarietyModalWithButton;
