const operatorSelectorTranslation = {
  en: {
    EQ: "=",
    NE: "!=",
    GT: ">",
    LT: "<",
    GE: ">=",
    LE: "<=",
    STARTS_WITH: "starts with",
    ENDS_WITH: "ends with",
    CONTAINS: "contains",
    NOT_CONTAINS: "does not contains",
  },

  fr: {
    EQ: "=",
    NE: "!=",
    GT: ">",
    LT: "<",
    GE: ">=",
    LE: "<=",
    STARTS_WITH: "Commence par",
    ENDS_WITH: "Se termine par",
    CONTAINS: "Contient",
    NOT_CONTAINS: "Ne contient pas",
  },
};

export default operatorSelectorTranslation;
