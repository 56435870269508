import { Photo, UpdatePhotoInput } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as FormMappers from "util/formMappers";
import { PhotoFormFields } from "./PhotoForm";

export function mapDomainModelToForm(domainModel?: Partial<Maybe<Photo>>) {
  const out: Partial<PhotoFormFields> = {};
  if (!domainModel) return out;

  FormMappers.mapBilingualRichText(out, "title", domainModel.title);
  FormMappers.mapPrimitiveType(out, "dateTaken", domainModel?.dateTaken ?? "");
  FormMappers.mapBilingualRichText(
    out,
    "description",
    domainModel?.description
  );
  FormMappers.mapArrayUsingFn(
    FormMappers.mapOverviewWsSpecies,
    out,
    `[photoSpecies]`,
    domainModel?.photoSpecies,
    `associatedSpecies`
  );
  FormMappers.mapRichText(out, "usageHistory", domainModel?.usageHistory);

  return out;
}

export function mapFormToDomainModel(form: Partial<PhotoFormFields>) {
  const out: Partial<UpdatePhotoInput> = {};

  FormMappers.mapBilingualRichTextInput(out, "title", form.title);
  FormMappers.mapPrimitiveTypeInput(out, "dateTaken", form.dateTaken);
  FormMappers.mapBilingualRichTextInput(out, "description", form.description);
  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapOverviewWsSpeciesInput,
    out,
    `[photoSpecies]`,
    form.photoSpecies
  );
  FormMappers.mapRichTextInput(out, "usageHistory", form.usageHistory);

  return out;
}
