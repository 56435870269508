import DatePicker from "components/molecules/DatePicker";
import {
  ListingProcessDocument,
  ListingProcessDocumentInput,
} from "generated/gql-types";
import * as React from "react";
import {
  Controller,
  ControllerProps,
  FieldPath,
  useFormContext,
} from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import { ListingProcessFormFields } from "../ListingProcessForm/ListingProcessForm";

export interface ListingProcessDocumentFormProps
  extends ListingProcessDocument {
  name: FieldPath<ListingProcessFormFields>;
  datePickerLabel: string;
  uriLabel: string;
  value: ListingProcessDocumentInput;
  disabled?: boolean;
  onChange: (value: ListingProcessDocumentInput) => void;
}

export const ListingProcessDocumentForm: React.FC<
  ListingProcessDocumentFormProps
> = (props) => {
  const { t } = useTranslation();
  const { setError, clearErrors } = useFormContext<ListingProcessFormFields>();
  const [publicationDate, setPublicationDate] = React.useState(
    props.value?.publicationDate ?? ""
  );
  const [uri, setUri] = React.useState(props.value?.uri ?? "");

  const publicationDateIsEmpty =
    publicationDate == null || publicationDate.length === 0;
  const uriIsEmpty = uri.length === 0;

  const showUriRequiredErr = !publicationDateIsEmpty && uriIsEmpty;
  const showPubDateRequiredErr = !uriIsEmpty && publicationDateIsEmpty;

  React.useEffect(() => {
    if (showUriRequiredErr || showPubDateRequiredErr) {
      setError(props.name, {
        type: "required",
        message: t("required"),
      });
    } else {
      clearErrors(props.name);
    }
  }, [showPubDateRequiredErr, showUriRequiredErr]);

  const onDateChanged = (newPubDate: string) => {
    setPublicationDate(newPubDate);
    props.onChange({ type: props.type, uri, publicationDate: newPubDate });
  };

  const onUriChanged = (e: any) => {
    const newUri = e.currentTarget.value;
    setUri(newUri);
    props.onChange({ type: props.type, uri: newUri, publicationDate });
  };

  return (
    <div className="form-group flex-col gap-md">
      <div>
        <DatePicker
          id={`${props.name}.publicationDate`}
          value={publicationDate ?? ""}
          onInput={onDateChanged}
          label={props.datePickerLabel}
          disabled={props.disabled}
        />
        {showPubDateRequiredErr ? (
          <FieldValidationError>{t("required")}</FieldValidationError>
        ) : null}
      </div>

      <div>
        <label htmlFor={`${props.name}.uri`}>{props.uriLabel}</label>
        <input
          className="form-control full-width"
          type="text"
          placeholder={t("start_typing_here")}
          id={`${props.name}.uri`}
          data-testid={`${props.name}.uri`}
          value={uri}
          onInput={onUriChanged}
          disabled={props.disabled}
        />
        {showUriRequiredErr ? (
          <FieldValidationError>{t("required")}</FieldValidationError>
        ) : null}
      </div>
    </div>
  );
};

export interface ListingProcessDocumentFormWithControllerProps<TFieldValues>
  extends Omit<ListingProcessDocumentFormProps, "onChange" | "value" | "name">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const ListingProcessDocumentFormWithController = <TFieldValues,>({
  datePickerLabel,
  uriLabel,
  type,
  ...props
}: ListingProcessDocumentFormWithControllerProps<TFieldValues>) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <ListingProcessDocumentForm
          disabled={props.disabled}
          name={props.name as any}
          datePickerLabel={datePickerLabel}
          uriLabel={uriLabel}
          type={type}
          value={value as any}
          onChange={onChange}
        />
      )}
    />
  );
};
