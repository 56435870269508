import { UseQueryResult } from "@tanstack/react-query";
import { resolveAzureEnvironment, RunEnvironment } from "azure/environment";
import { xor } from "lodash";
// import { fullBilingualNameForLanguage } from "mappers";
import React from "react";
import { useTranslation } from "react-i18next";
import { convertCosewicKeyToTagWithTranslation } from "util/queryTool/convertCosewicKeyToTag";

type QueryField = {
  key: string;
  name: string;
  type: string;
};

interface QueryFieldsListResponse {
  queryFieldsList: QueryField[];
}

export const CosewicCheckboxes: React.FC<{
  onChange: (cosewicItems: string[]) => void;
  defaultSelected: string[];
  cosewicFieldsQuery: UseQueryResult<QueryFieldsListResponse, unknown>;
}> = ({ onChange, defaultSelected = [], cosewicFieldsQuery }) => {
  const { t, i18n } = useTranslation();
  const env = resolveAzureEnvironment();

  const [selectedKeys, setSelectedKeys] =
    React.useState<string[]>(defaultSelected);

  const onToggleKey = (key: string) => {
    if (
      cosewicFieldsQuery.data == null ||
      cosewicFieldsQuery.data.queryFieldsList == null
    )
      return;
    const newKeys = xor(selectedKeys, [key]);

    onChange(newKeys);
    setSelectedKeys(newKeys);
  };

  if (cosewicFieldsQuery.isLoading) return <p>Loading...</p>; // TODO: show loading indicator
  if (cosewicFieldsQuery.isError) {
    console.error("Error loading COSEWIC list.");
    // TODO: better error display
    return <p>Error loading COSEWIC list</p>;
  }

  const cosewicItems = cosewicFieldsQuery.data.queryFieldsList ?? [];

  return (
    <>
      <div className="checkbox-columns-lg">
        {cosewicItems
          .sort((a, b) =>
            convertCosewicKeyToTagWithTranslation(a.key).localeCompare(
              convertCosewicKeyToTagWithTranslation(b.key)
            )
          )
          .map((x: QueryField) => {
            if (!x) return <label>Error: Missing COSEWIC field</label>;
            const key = `cosewic_checkbox_${x.key}`;

            return (
              <div className="checkbox" key={key}>
                <label htmlFor={key}>
                  <input
                    type="checkbox"
                    id={key}
                    onChange={() => {
                      onToggleKey(x.key);
                    }}
                    defaultChecked={selectedKeys.includes(x.key)}
                  />
                  <span>{convertCosewicKeyToTagWithTranslation(x.key)}</span>
                </label>
              </div>
            );
          })}
      </div>
      {(env === RunEnvironment.AZURE_TEST ||
        env === RunEnvironment.AZURE_DEV ||
        env === RunEnvironment.AZURE_ALTDEV ||
        env === RunEnvironment.LOCAL) && (
        <details className="mrgn-tp-md">
          <summary style={{ backgroundColor: "#fcf8e3" }}>
            Cosewic selected keys - Display for debugging purposes
          </summary>
          <ul>
            {selectedKeys.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </details>
      )}
    </>
  );
};
