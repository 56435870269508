import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/TwoColumn";
import NoteCard from "components/molecules/NoteCard/NoteCard";
import SideNav from "components/molecules/SideNav";
import WSNameChangesCard from "components/molecules/changeLog/WSNameChangesCard";
import UnpublishedChangesWarning from "components/molecules/cosewic/UnpublishedChangesWarning/UnpublishedChangesWarning";
import WSAssessmentCard from "components/molecules/cosewic/WSAssessmentCard";
import WSInfoCard from "components/molecules/cosewic/WSInfoCard";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import MakeCosewicInfoPublishButtonAndModal from "components/organisms/MakeCosewicInfoPublishButtonAndModal/MakeCosewicInfoPublishButtonAndModal";
import MakePublishedButtonAndModal from "components/organisms/MakePublishedButtonAndModal/MakePublishedButtonAndModal";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  AssessmentStage,
  ChangeLog,
  CosewicProfileDocument,
  CosewicWsPendingRegistryUpdatesDocument,
  CosewicWsState,
  CosewicWsStatus,
  DeleteCosewicWsDocument,
  UpdateCosewicWsDocument,
  UpdateCosewicWsStageDocument,
  UpdateCosewicWsStateDocument,
  WsStatus,
} from "generated/gql-types";
import useCheckbox from "hooks/util/useCheckbox";
import useCosewicRefetchOverview from "hooks/util/useCosewicRefetchOverview";
import { SearchPathHelpers } from "pages/search/SearchRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";
import { CosewicPathHelpers } from "../CosewicRouter";
import timeoutPromise from "util/timeoutPromise";
import { COSEWIC_PUBLISH_BTN_WAIT_BEFORE_REFRESH_TIME_MS } from "config/constants";

const Index: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [updateCosewicWs, { error: savingError }] = useMutation(
    UpdateCosewicWsDocument,
    {
      refetchQueries: ["CosewicProfile", "CosewicWsPendingRegistryUpdates"],
    }
  );

  const [deleteCosewicWs, deleteCosewicWsStatus] = useMutation(
    DeleteCosewicWsDocument,
    { refetchQueries: ["CosewicWsSearch"] }
  );

  const [runUpdateCosewicWsStage, updateCosewicWsStageMutationStatus] =
    useMutation(UpdateCosewicWsStageDocument, {
      refetchQueries: ["CosewicProfile"],
    });

  const [runUpdateCosewicWsState, updateCosewicWsStateMutationStatus] =
    useMutation(UpdateCosewicWsStateDocument, {
      refetchQueries: ["CosewicProfile"],
    });

  const {
    loading,
    error,
    data,
    refetch: refetchCosewicProfile,
  } = useQuery(CosewicProfileDocument, {
    variables: {
      id: cosewicWsId,
    },
  });

  const cosewicRefetchOverview = useCosewicRefetchOverview(
    data?.cosewicWs?.state === CosewicWsState.Live
  );

  const onUpdateNote = async (newNoteText: string) => {
    try {
      const note = htmlIsNullOrEmpty(newNoteText)
        ? null
        : { text: { text: newNoteText } };

      const res = await updateCosewicWs({
        variables: {
          id: cosewicWsId,
          input: { note },
        },
      });

      if (res.errors) throw res.errors;
    } catch (e) {}

    console.log("Saved Note for COSEWIC WS", cosewicWsId);
  };

  const onDelete = async () => {
    try {
      const res = await deleteCosewicWs({
        variables: {
          id: cosewicWsId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: "Successfully deleted COSEWIC WS",
      });

      history.push({
        pathname: SearchPathHelpers.Cosewic(),
      });
    } catch (e) {}
  };

  const onMakePermanent = async () => {
    try {
      const res = await runUpdateCosewicWsStage({
        variables: {
          id: cosewicWsId,
          status: CosewicWsStatus.Permanent,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  const onPublish = async () => {
    try {
      const res = await runUpdateCosewicWsState({
        variables: {
          id: cosewicWsId,
          state: CosewicWsState.Live,
        },
      });
      if (res.errors) throw res.errors;

      setTimeout(() => {
        refectPendingRegistryUpdates();
        refetchCosewicProfile();
        alertContext.showSuccess({
          message: t("publish_changes_success_message"),
        });
      }, 3000);

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const canMakePermanent = React.useMemo(() => {
    if (loading) return false;

    if (data?.cosewicWs?.status === CosewicWsStatus.Permanent) {
      console.log("[Make Permanent Btn] Disabled - WS is already permanent");
      return false;
    }

    if (data?.assessmentLatest?.stage !== AssessmentStage.Assessed) {
      console.log(
        "[Make Permanent Btn] Disabled - Latest Assessment Stage is not Assessed"
      );
      return false;
    }

    if (data?.assessmentLatest?.statusAndCriteria?.status == null) {
      console.log(
        "[Make Permanent Btn] Disabled - COSEWIC WS status (assessment status) is empty"
      );
      return false;
    }

    if (data?.cosewicWs?.scientificName?.genus?.id == null) {
      console.log("[Make Permanent Btn] Disabled - Genus field is empty");
      return false;
    }

    if (data?.cosewicWs?.scientificName?.species?.id == null) {
      console.log("[Make Permanent Btn] Disabled - Species field is empty");
      return false;
    }

    if (data?.cosewicWs?.commonNameFrench?.name == null) {
      console.log(
        "[Make Permanent Btn] Disabled - Common Name French field is empty"
      );
      return false;
    }

    return true;
  }, [
    loading,
    data?.cosewicWs?.status,
    data?.cosewicWs?.scientificName?.genus?.id,
    data?.cosewicWs?.scientificName?.species?.id,
    data?.assessmentLatest?.stage,
    data?.assessmentLatest?.statusAndCriteria?.status,
    data?.cosewicWs?.commonNameFrench?.name,
  ]);

  const canPublish = React.useMemo(() => {
    if (loading) return false;

    if (data?.assessmentLatest?.stage !== AssessmentStage.Assessed) {
      console.log(
        "[WS Publish Btn] Disabled - Latest Assessment Stage is not Assessed"
      );
      return false;
    }

    if (data?.assessmentLatest?.statusAndCriteria?.status === null) {
      console.log(
        "[WS Publish Btn] Disabled - WS Current Status (AKA latest assessment status) is empty"
      );
      return false;
    }

    if (
      data?.assessmentLatest?.statusAndCriteria?.status ===
      WsStatus.DeferredRejectWithdrawn
    ) {
      console.log(
        "[WS Publish Btn] Disabled - WS Current Status (AKA latest assessment status) is DeferredRejectedWithdrawn"
      );
      return false;
    }

    if (data?.cosewicWs?.status !== CosewicWsStatus.Permanent) {
      console.log(
        "[WS Publish Btn] Disabled - COSEWIC WS Status is not PERMANENT"
      );
      return false;
    }

    if (data?.cosewicWs?.state === CosewicWsState.Live) {
      console.log(
        "[WS Publish Btn] Disabled - WS is already published (State is LIVE)"
      );
      return false;
    }

    if (data?.cosewicWs?.commonNameFrench?.name == null) {
      console.log(
        "[WS Publish Btn] Disabled - Common Name French field is empty"
      );
      return false;
    }

    return true;
  }, [
    loading,
    data?.cosewicWs?.status,
    data?.cosewicWs?.state,
    data?.assessmentLatest?.stage,
    data?.assessmentLatest?.statusAndCriteria?.status,
    data?.cosewicWs?.commonNameFrench?.name,
  ]);

  const {
    data: unpublishedChangesData,
    loading: unpublishedChangesLoading,
    error: unpublishedChangesError,
    refetch: refectPendingRegistryUpdates,
  } = useQuery(CosewicWsPendingRegistryUpdatesDocument, {
    variables: { cosewicWsPendingRegistryUpdatesId: cosewicWsId },
    skip: data?.cosewicWs?.state !== CosewicWsState.Live,
  });

  const canPublishChanges = React.useMemo(() => {
    if (
      loading ||
      unpublishedChangesLoading ||
      error ||
      unpublishedChangesError
    )
      return false;

    if (data?.cosewicWs?.state !== CosewicWsState.Live) {
      console.log(
        "[WS Publish Changes Btn] Disabled - WS is not published (State is not LIVE)"
      );
      return false;
    }

    if (
      unpublishedChangesData?.cosewicWsPendingRegistryUpdates == null ||
      unpublishedChangesData.cosewicWsPendingRegistryUpdates.length <= 0
    ) {
      console.log("[WS Publish Changes Btn] Disabled - No unpublished changes");
      return false;
    }

    return true;
  }, [
    loading,
    unpublishedChangesLoading,
    error,
    unpublishedChangesError,
    data?.cosewicWs?.state,
    unpublishedChangesData?.cosewicWsPendingRegistryUpdates,
  ]);

  // show the delete button if the COSEWIC information is not permanent and not published (BUG 7742)
  const showDeleteButton =
    !loading &&
    data?.cosewicWs?.status !== CosewicWsStatus.Permanent &&
    data?.cosewicWs?.state !== CosewicWsState.Live;

  const onPublishChanges = async () => {
    try {
      const res = await runUpdateCosewicWsState({
        variables: {
          id: cosewicWsId,
          state: CosewicWsState.Live,
        },
      });

      if (res.errors) throw res.errors;

      setTimeout(() => {
        refectPendingRegistryUpdates();
        alertContext.showSuccess({
          message: t("publish_changes_success_message"),
        });
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <UnpublishedChangesWarning
            cosewicWsPendingRegistryUpdates={
              unpublishedChangesData?.cosewicWsPendingRegistryUpdates
            }
            isPublished={data?.cosewicWs?.state === CosewicWsState.Live}
          />

          <GlobalAlert />

          <h1>{t("cosewic_information")}</h1>

          <GraphqlError
            title="Error fetching COSEWIC WS profile data"
            errors={error}
          />
          <GraphqlError
            title="Error deleting COSEWIC WS profile"
            errors={deleteCosewicWsStatus.error}
          />
          <GraphqlError
            title="Error setting COSEWIC WS stage"
            errors={updateCosewicWsStageMutationStatus.error}
          />
          <GraphqlError
            title="Error setting COSEWIC WS state"
            errors={updateCosewicWsStateMutationStatus.error}
          />
          <GraphqlError
            title="Error updating COSEWIC WS"
            errors={savingError}
          />

          {cosewicRefetchOverview.error && (
            <Alert
              type={AlertTypes.DANGER}
              title="Error fetching overview data"
              content={"Please refresh the page"}
            />
          )}

          <div className="flex justify-between align-center mrgn-bttm-md">
            <div className="flex font-size-18">
              <div>
                {t("ws_stage")}{" "}
                <div className="label label-info">
                  {data?.cosewicWs?.status ? (
                    t(data?.cosewicWs?.status)
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
              <div className="mrgn-lft-md">
                {t("state")}{" "}
                <div className="label label-info">
                  {/*Nested ternary below, may be hard to read:
                  Returns "<MissingData />" if status is missing,
                  otherwise returns whether status is published or not.*/}
                  {data?.cosewicWs?.state == null ? (
                    <MissingData />
                  ) : data?.cosewicWs.state === CosewicWsState.Live ? (
                    t("PUBLISHED")
                  ) : (
                    t("NOT_PUBLISHED")
                  )}
                </div>
              </div>
            </div>
            {cosewicRefetchOverview.refetching && <LoadingIndicator />}
            <div>
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.makePermanent}
              >
                {canMakePermanent ? (
                  <MakePermanentButtonAndModal
                    canMakePermanent={canMakePermanent}
                    onMakePermanent={onMakePermanent}
                  />
                ) : null}
              </RenderWhenAuthorized>

              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.update}
              >
                {canPublish ? (
                  <MakePublishedButtonAndModal
                    canMakePublished={canPublish}
                    onMakePublished={onPublish}
                    warningMessage={t("publish_ws_to_registry_warning")}
                  />
                ) : null}
              </RenderWhenAuthorized>

              {/* <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.update}
              >
                {canPublishChanges ? (
                  // <MakePublishedButtonAndModal
                  //   canMakePublished={canPublish}
                  //   onMakePublished={onPublish}
                  //   warningMessage={t("publish_ws_to_registry_warning")}
                  // />
                  <button type="button" className="btn btn-primary">
                    Publish changes (placeholder)
                  </button>
                ) : null}
              </RenderWhenAuthorized> */}

              {canPublishChanges ? (
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.cosewic.publish}
                >
                  <MakeCosewicInfoPublishButtonAndModal
                    canPublishChanges={canPublishChanges}
                    onPublishChanges={onPublishChanges}
                    warningTitle={t("publish_changes_warning_title")}
                    warningMessage={t("publish_changes_warning_message")}
                    buttonText="publish_changes"
                    ConfirmationMessage="i_confirm"
                  />
                </RenderWhenAuthorized>
              ) : null}

              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.delete}
              >
                {showDeleteButton ? (
                  <DeleteButtonAndModal
                    onDelete={onDelete}
                    disabled={!showDeleteButton}
                  />
                ) : null}
              </RenderWhenAuthorized>
            </div>
          </div>

          {loading && (
            <SectionCard
              header={
                <div className="flex justify-between">
                  <h2 className="mrgn-tp-sm">
                    {t("wildlife_species_information")}
                  </h2>
                </div>
              }
            >
              <LoadingIndicator centered />
            </SectionCard>
          )}
          {data?.cosewicWs && (
            <WSInfoCard cosewicWs={data?.cosewicWs} loading={loading} />
          )}

          <NoteCard
            header={t("wildlife_species_note")}
            note={data?.cosewicWs?.note}
            loading={loading}
            onUpdateNote={onUpdateNote}
            role={ROLE_ACTIONS.cosewic.update}
          />

          <WSAssessmentCard
            assessmentList={data?.assessmentList?.assessment}
            cosewicWsId={cosewicWsId}
            loading={loading}
          />

          <WSNameChangesCard
            changeLogList={data?.changeLogList?.changeLog as Array<ChangeLog>}
            sectionTitle={t("cosewic_name_changes")}
            correlationId={cosewicWsId}
            loading={loading}
            changeLogDetailsLinkCallback={CosewicPathHelpers.ChangeLogDetails}
            refetchProfile={() => refetchCosewicProfile()}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

const DeleteButtonAndModal: React.FC<{
  onDelete: () => void;
  disabled?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const confirmDeleteModal = useConfirmationModal({});
  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-sm"
        onClick={confirmDeleteModal.open}
        disabled={props.disabled}
        data-testid="button-delete"
      >
        {t("delete")}
      </button>

      {props.disabled ? null : (
        <ConfirmationModal
          modalState={confirmDeleteModal}
          title={t("delete_a_cosewicws")}
          confirmBtnEnabled={confirmDeleteState}
          onConfirm={props.onDelete}
          onCancel={() => {
            confirmDeleteModal.close();
            setConfirmDeleteState(false);
          }}
        >
          <Alert
            type={AlertTypes.WARNING}
            content={t("delete_cosewic_warning")}
          />

          <label>
            <input
              type="checkbox"
              checked={confirmDeleteState}
              onChange={(e) => setConfirmDeleteState((x) => !x)}
              data-testid="modal-checkbox-confirm-delete"
            />{" "}
            {t("delete_confirm")}
          </label>
        </ConfirmationModal>
      )}
    </>
  );
};

const MakePermanentButtonAndModal: React.FC<{
  canMakePermanent: boolean;
  onMakePermanent: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const confirmMakePermanentModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  return (
    <>
      <button
        className="btn btn-primary btn-sm mrgn-rght-sm"
        disabled={!props.canMakePermanent}
        onClick={confirmMakePermanentModal.open}
        data-testid="button-make-permanent"
      >
        {t("make_permanent")}
      </button>

      <ConfirmationModal
        modalState={confirmMakePermanentModal}
        title={t("make_permanent")}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={props.onMakePermanent}
        onCancel={() => {
          confirmMakePermanentModal.close();
          checkboxState.reset();
        }}
      >
        <Alert
          type={AlertTypes.WARNING}
          content={t("permanent_cosewic_warning")}
        />

        <label>
          <input
            type="checkbox"
            checked={checkboxState.checked}
            onChange={checkboxState.toggle}
            data-testid="modal-checkbox-confirm-make-permanent"
          />{" "}
          {t("confirm_make_permanent")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default Index;
