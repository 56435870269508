import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import { ListingWs } from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import ListingWsAutocomplete from "../ListingWsAutocomplete";
import useListingWsAutocomplete from "../ListingWsAutocomplete/useListingWsAutocomplete";

export interface ListingWsMultiSelectListProps {
  value: Array<ListingWs>;
  onChange: (listItems: Array<ListingWs>) => void;
  required?: boolean;
  disabled?: boolean;
}

export const ListingWsMultiSelectList: React.FC<
  ListingWsMultiSelectListProps
> = (props) => {
  const { value, onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    ListingWs | undefined
  >(undefined);
  const listingWsAutocompleteInstance = useListingWsAutocomplete();

  const listingWsNameForLanguage = React.useCallback(
    (item: ListingWs) => {
      if (i18n.language === "fr") {
        return {
          id: item?.id,
          name: item?.commonNameFrench?.name?.plainText,
          population: item?.population?.nameFr,
        };
      }

      return {
        id: item?.id,
        name: item?.commonNameEnglish?.name?.plainText,
        population: item?.population?.nameEn,
      };
    },
    [i18n.language]
  );

  const onRemoveFromList = (id: string) => {
    const newList = value.filter((x) => x.id !== id);
    onChange(newList);
  };

  const onSelectionChange = (newListingWs?: ListingWs | undefined) => {
    setSelectedSearchItem(newListingWs);
    listingWsAutocompleteInstance.clear();
  };

  const isFound = value.some((x) => x.id === selectedSearchItem?.id);

  React.useEffect(() => {
    if (value && selectedSearchItem && !isFound) {
      onChange([...value, selectedSearchItem]);
    }
  }, [isFound, onChange, selectedSearchItem, value]);

  React.useEffect(() => {
    if (selectedSearchItem) {
      setSelectedSearchItem(undefined);
    }
  }, [selectedSearchItem]);

  return (
    <div>
      <div className="flex gap-sm align-end">
        <ListingWsAutocomplete
          onSelectionChange={onSelectionChange}
          label={t("affected_wildlife_species")}
          required={props.required}
          useListingWsAutocompleteInstance={listingWsAutocompleteInstance}
          disabled={props.disabled}
        />
      </div>

      <ul className="multi-select-list">
        {value.map((x) => {
          return (
            <MultiSelectListItem
              id={x.id as string}
              name={listingWsNameForLanguage(x)?.name ?? "error"}
              onRemove={() => onRemoveFromList(x.id as string)}
              key={x.id}
              disabled={props.disabled}
            />
          );
        })}
      </ul>
    </div>
  );
};

interface ListingWsMultiSelectListWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<ListingWsMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
  disabled?: boolean;
}

export const ListingWsMultiSelectListWithController = <
  TFieldValues extends FieldValues
>(
  props: ListingWsMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      rules={{ required: props.required }}
      render={({ field: { value, onChange } }) => (
        <ListingWsMultiSelectList
          value={value as Array<ListingWs>}
          onChange={onChange}
          required={props.required}
          disabled={props.disabled}
        />
      )}
    />
  );
};

export default ListingWsMultiSelectList;
