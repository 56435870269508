import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplate,
  BptTemplateStage,
  BptTemplateStep,
  Maybe,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import EditStepBtn from "../EditStepModal/EditStepBtn";
import { StepInformation } from "../StepInformationCard/StepInformation";

export interface StepDetailsProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  index?: number;
  isOpen?: boolean;
  bundleItem?: Maybe<Array<BptTemplateStep>>;
  item?: BptTemplateStep;
  bptTemplate?: BptTemplate;
  templateId: string;
  isLoading?: boolean;
  onDeleteStep: (a: any, b: any) => void;
}

export const StepDetails = React.forwardRef((props: StepDetailsProps, ref) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("step"));
  const { isOpen, item, bptTemplate, templateId, isLoading, onDeleteStep } =
    props;

  return (
    <details
      key={item?.id}
      className="detail-list bg-grey expand-collapse-list"
      open={isOpen}
    >
      <summary>
        <div className="flexbug-9-workaround">
          <div className="flex justify-between align-center">
            <div className="font-size-18">
              {
                <SafeRenderHtml
                  htmlString={htmlRemoveOuterPTag(item?.name?.text ?? "Error")}
                />
              }
            </div>
            {bptTemplate?.stage === BptTemplateStage.Draft ? (
              <div className="flex gap-sm align-center flex-wrap">
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.bpt.updateRoles}
                >
                  <EditStepBtn
                    refetchQueries={["BptTemplateStepList"]}
                    templateId={templateId}
                    stepInfo={item}
                    onCompleted={(data: any) => {
                      alertContext.showSuccess({
                        message: data?.UpdateBptTemplateStep?.name?.text,
                        timeOut: 5000,
                      });
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>

                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.bpt.updateRoles}
                >
                  <DeleteButtonModal
                    buttonText={t("delete")}
                    buttonTitle={t("delete_step")}
                    modalTitle={t("delete_step")}
                    alertContent={t("html_delete_step_warning")}
                    alertConfirm={t("delete_confirm")}
                    alertType={AlertTypes.DANGER}
                    onDelete={() => onDeleteStep(templateId!, item?.id!)}
                    className="font-size-14 hover-grey vertical-align-baseline"
                  />
                </RenderWhenAuthorized>
              </div>
            ) : null}
          </div>
        </div>
      </summary>

      {isLoading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : (
        <StepInformation templateId={templateId} stepInfo={item} />
      )}
    </details>
  );
});

export default StepDetails;
