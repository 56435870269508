import { useQuery } from "@apollo/client";
import AddLink from "components/atoms/AddLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import BadgeBar from "components/molecules/BadgeBar";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import ListingLandListSection from "components/organisms/listing/ListingLandListSection/ListingLandListSection";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  LandType,
  ListingWsLandSummaryCountsDocument,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "util/router";

export interface ListingLandsPageProps {}

export const ListingLandsPage: React.FC<ListingLandsPageProps> = (props) => {
  const { t } = useTranslation();
  const overviewContext = useOverviewContext();

  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );
  const { listingWsId } = match.params as any;

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { loading, error, data } = useQuery(
    ListingWsLandSummaryCountsDocument,
    {
      variables: {
        listingWsId: listingWsId,
        fieldName: "lands.landRef.landType",
      },
    }
  );

  const federalCount =
    data?.listingWsLandSummaryCounts?.find((x) => x?.key === LandType.Federal)
      ?.count ?? 0;
  const provinceTerritoryCount =
    data?.listingWsLandSummaryCounts?.find(
      (x) => x?.key === LandType.ProvinceTerritory
    )?.count ?? 0;
  const privateCount =
    data?.listingWsLandSummaryCounts?.find((x) => x?.key === LandType.Private)
      ?.count ?? 0;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("lands")}</h1>
          <GraphqlError errors={error} />

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("land_types")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.land.create}
                >
                  <AddLink
                    linkTo={ListingPathHelpers.ListingLandsCreate(listingWsId)}
                    text={t("add_land")}
                  ></AddLink>
                </RenderWhenAuthorized>
              </div>
            }
            showLine={loading}
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_federal_lands")}
                    titleLink={relative("/federal")}
                    counter={federalCount}
                  />
                </div>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_provincial_territorial_lands")}
                    titleLink={relative("/provincialTerritorial")}
                    counter={provinceTerritoryCount}
                  />
                </div>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_private_lands")}
                    titleLink={relative("/private")}
                    counter={privateCount}
                  />
                </div>
              </>
            )}
          </SectionCard>

          <Switch>
            <Route path={relative("/")} exact>
              {/* Fallback route, render federal lands page */}
              <ListingLandListSection
                sectionTitle={t("federal_lands")}
                listingWsId={listingWsId}
                landType={LandType.Federal}
              />
            </Route>
            <Route path={relative("/federal")}>
              <ListingLandListSection
                sectionTitle={t("federal_lands")}
                listingWsId={listingWsId}
                landType={LandType.Federal}
              />
            </Route>
            <Route path={relative("/provincialTerritorial")}>
              <ListingLandListSection
                sectionTitle={t("provincial_territorial_lands")}
                listingWsId={listingWsId}
                landType={LandType.ProvinceTerritory}
              />
            </Route>
            <Route path={relative("/private")}>
              <ListingLandListSection
                sectionTitle={t("private_lands")}
                listingWsId={listingWsId}
                landType={LandType.Private}
              />
            </Route>
          </Switch>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingLandsPage;
