import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import EditRegionButtonAndModal from "components/organisms/listing/EditRegionButtonAndModal/EditRegionButtonAndModal";
import { mapDomainModelToForm } from "components/organisms/listing/ListingProcessTifForm/mappers";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { ListingProcessTif, Maybe } from "generated/gql-types";
import {
  bilingualNameForLanguage,
  bilingualRichTextForLanguage,
} from "mappers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

export interface TifInformationSectionProps {
  isFirstListingProcess: boolean;
  listingProcessId?: string | null;
  tifs?: Maybe<Maybe<ListingProcessTif>[]>;
  preventEditButton?: boolean;
}

export const TifInformationSection: React.FC<TifInformationSectionProps> = (
  props
) => {
  const { isFirstListingProcess, listingProcessId, tifs, preventEditButton } =
    props;
  const { t, i18n } = useTranslation();

  const sortedTifs = React.useMemo(() => {
    if (!tifs || tifs.length === 0) return [];
    return [...tifs].sort((a, b) =>
      bilingualNameForLanguage(i18n.language, a?.region).localeCompare(
        bilingualNameForLanguage(i18n.language, b?.region)
      )
    );
  }, [i18n.language, tifs]);

  return (
    <>
      <h4 className="mrgn-bttm-md">{t("tif_information")}</h4>
      {!tifs || tifs.length === 0 ? (
        <NoResults centered />
      ) : (
        sortedTifs.map((item) => {
          const proposedConsultationPath =
            i18n.language === "fr"
              ? item?.proposedConsultationPath?.name?.french
              : item?.proposedConsultationPath?.name?.english;
          const region =
            i18n.language === "fr"
              ? item?.region?.nameFr
              : item?.region?.nameEn;

          const roleRegionTif = item?.region?.nameEn?.toLowerCase() + "Tif";
          const roleActionsRegionTifUpdate =
            (ROLE_ACTIONS[roleRegionTif as keyof typeof ROLE_ACTIONS] as any)
              ?.update ?? [];

          return (
            <details
              key={region}
              className="species-details species-field mrgn-lft-md mrgn-rght-md"
            >
              <summary>{region}</summary>
              <div className="mrgn-lft-md">
                {/* ---------- Proposed Consultation path ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("proposed_consultation_path")}
                    </dt>
                    <dd>{proposedConsultationPath ?? <MissingData />}</dd>
                  </div>
                </dl>
                {/* ---------- Range of impact ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("range_of_impact")} ({t("english")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(item?.rangeOfImpact?.english?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.rangeOfImpact?.english?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("range_of_impact")} ({t("french")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(item?.rangeOfImpact?.french?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.rangeOfImpact?.french?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                {/* ---------- Consultation requirements ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("consultation_requirements")} ({t("english")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(
                        item?.consultationRequirements?.english?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={
                            item?.consultationRequirements?.english?.text
                          }
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("consultation_requirements")} ({t("french")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(
                        item?.consultationRequirements?.french?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={
                            item?.consultationRequirements?.french?.text
                          }
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                {/* ---------- Information uncertainties ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("information_uncertainties")} ({t("english")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(
                        item?.informationUncertainties?.english?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={
                            item?.informationUncertainties?.english?.text
                          }
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("information_uncertainties")} ({t("french")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(
                        item?.informationUncertainties?.french?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={
                            item?.informationUncertainties?.french?.text
                          }
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                {/* ---------- Species data source ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("species_data_source")} ({t("english")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(
                        item?.speciesDataSource?.english?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.speciesDataSource?.english?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("species_data_source")} ({t("french")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(
                        item?.speciesDataSource?.french?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.speciesDataSource?.french?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                {/* ---------- Methodology ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("methodology")} ({t("english")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(item?.methodology?.english?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.methodology?.english?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("methodology")} ({t("french")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(item?.methodology?.french?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.methodology?.french?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                {/* ------------ GIS contact ------------ */}
                <div className="row">
                  <dl>
                    {/* ------------ person ------------ */}
                    <div className="species-data col-sm-6">
                      <dt className="text-muted">{t("gis_contact_person")}</dt>
                      <dd>
                        {item?.gisContactFormatted == null ? (
                          <MissingData />
                        ) : (
                          <SafeRenderHtml
                            htmlString={bilingualRichTextForLanguage(
                              i18n.language,
                              item.gisContactFormatted
                            )}
                          />
                        )}
                      </dd>
                    </div>
                    {/* ------------ note ------------ */}
                    <div className="species-data col-sm-6">
                      <dt className="text-muted">{t("gis_contact_note")}</dt>
                      <dd>
                        {item?.gisContactNotes?.text == null ? (
                          <MissingData />
                        ) : (
                          <SafeRenderHtml
                            htmlString={item.gisContactNotes.text}
                          />
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
                {/* ------------ Biologist contact ------------ */}
                <div className="row">
                  <dl>
                    {/* ------------ person ------------ */}
                    <div className="species-data col-sm-6">
                      <dt className="text-muted">
                        {t("biologist_contact_person")}
                      </dt>
                      <dd>
                        {item?.biologistContactFormatted == null ? (
                          <MissingData />
                        ) : (
                          <SafeRenderHtml
                            htmlString={bilingualRichTextForLanguage(
                              i18n.language,
                              item.biologistContactFormatted
                            )}
                          />
                        )}
                      </dd>
                    </div>
                    {/* ------------ note ------------ */}
                    <div className="species-data col-sm-6">
                      <dt className="text-muted">
                        {t("biologist_contact_note")}
                      </dt>

                      <dd>
                        {item?.biologistContactNotes?.text == null ? (
                          <MissingData />
                        ) : (
                          <SafeRenderHtml
                            htmlString={item.biologistContactNotes.text}
                          />
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
                {/* ---------- Comments ---------- */}
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("comments")} ({t("english")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(item?.comments?.english?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.comments?.english?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="col-sm-12 species-data">
                    <dt className="text-muted">
                      {t("comments")} ({t("french")})
                    </dt>
                    <dd>
                      {htmlIsNullOrEmpty(item?.comments?.french?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={item?.comments?.french?.text}
                        />
                      )}
                    </dd>
                  </div>
                </dl>

                <RenderWhenAuthorized
                  authorizedRoles={roleActionsRegionTifUpdate}
                >
                  {/* Only show edit region buttons on the first listing process */}
                  {isFirstListingProcess && !preventEditButton ? (
                    <EditRegionButtonAndModal
                      listingProcessId={listingProcessId ?? ""}
                      tifId={item?.id ?? ""}
                      region={item?.region}
                      refetchQueries={["ListingWsProcessList"]}
                      defaultValues={mapDomainModelToForm(item)}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            </details>
          );
        })
      )}
    </>
  );
};

export default TifInformationSection;
