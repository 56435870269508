import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import Layout from "components/layouts/TwoColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SideNav from "components/molecules/SideNav";
import IssuesCard from "components/molecules/listing/sectors/IssuesCard/IssuesCard";
import { SectorCard } from "components/organisms/admin/SectorCard/SectorCard";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  DeleteListingSectorDocument,
  ListingProfileDocument,
  ListingWsSectorsListDocument,
  UpdateListingDocument,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";
import Ribbon from "../../../../components/organisms/cosewic/Ribbon";

export interface ListingSectorsPageProps {}

export const ListingSectorsPage: React.FC<ListingSectorsPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const overviewContext = useOverviewContext();
  const pagination = usePagination();
  const alertContext = useGlobalAlertContext();

  const { loading, error, data } = useQuery(ListingWsSectorsListDocument, {
    variables: {
      params: {
        listingId: listingWsId,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
      },
    },
  });

  const {
    loading: issuesLoading,
    error: issuesError,
    data: issuesData,
  } = useQuery(ListingProfileDocument, {
    variables: {
      id: listingWsId,
    },
  });

  const [updateListingWs, { error: savingError }] = useMutation(
    UpdateListingDocument,
    {
      refetchQueries: ["ListingProfile"],
    }
  );

  const [deleteSector, deleteSectorStatus] = useMutation(
    DeleteListingSectorDocument,
    {
      refetchQueries: ["ListingWsSectorsList"],
    }
  );

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const onDeleteSector = async (sectorId: string) => {
    try {
      const res = await deleteSector({
        variables: {
          listingWsId,
          sectorId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        // TODO:i18n
        message: "Successfully deleted sector",
      });
    } catch (e) {}
  };

  const onUpdateIssues = async (newIssuesText: string) => {
    try {
      const sectorIssues = htmlIsNullOrEmpty(newIssuesText)
        ? null
        : { text: { text: newIssuesText } };

      const res = await updateListingWs({
        variables: {
          id: listingWsId,
          input: { sectorIssues },
        },
      });

      if (res.errors) throw res.errors;
    } catch (e) {}

    console.log("Saved Sector Issues for Listing", listingWsId);
  };

  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  return (
    <>
      <Ribbon />

      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <GraphqlError title="Error fetching Sectors List" errors={error} />
          <GraphqlError
            title="Error fetching Sectors Issues"
            errors={issuesError}
          />
          <GraphqlError
            title="Error deleting sector"
            errors={deleteSectorStatus.error}
          />
          <GraphqlError
            title="Error saving Sector Issues"
            errors={savingError}
          />

          <h1>{t("sectors")}</h1>
          <IssuesCard
            header={t("issues")}
            issues={issuesData?.listingWs?.sectorIssues}
            loading={issuesLoading}
            onUpdateIssues={onUpdateIssues}
            role={ROLE_ACTIONS.listing.update}
          ></IssuesCard>
          <div className="flex justify-between align-center mrgn-bttm-md">
            <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.sector.create}>
              <Link
                to={ListingPathHelpers.ListingSectorsCreate(listingWsId)}
                className="btn btn-default"
                data-testid={"add-sector-btn"}
              >
                {t("add_a_sector")}
              </Link>
            </RenderWhenAuthorized>
            <PageSizeSelect
              pageSize={pagination.pageSize}
              onChangePageSize={pagination.setPageSize}
            />
          </div>

          {loading ? (
            <LoadingIndicator centered />
          ) : (
            <>
              {isNullOrEmpty(data?.listingWsSectorList?.sectors) ? (
                <div className="width-100 flex justify-center mrgn-bttm-lg">
                  <MissingData />
                </div>
              ) : null}

              {data?.listingWsSectorList?.sectors.map((x) => {
                return (
                  <SectorCard
                    key={x?.sectorId}
                    sector={x}
                    onDelete={onDeleteSector}
                    listingId={listingWsId}
                  />
                );
              })}

              <Pagination
                {...pagination.paginationComponentProps}
                totalPages={
                  data?.listingWsSectorList?.pagination?.totalPages ?? 0
                }
              />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingSectorsPage;
