import { usePrompt } from "hooks/util/usePrompt";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface FormButtonsProps {
  disabled?: boolean;
  isDirty?: boolean;
  errors?: Record<string, any>;
  isSubmitting?: boolean;
  onCancel: () => void;
  submitBtnText?: string;
  submitBtnIcon?: string;
}

const FormButtons: React.FC<FormButtonsProps> = (props) => {
  const { t } = useTranslation();
  const { disabled = false, isDirty = true, errors } = props;
  const hasErrors = errors != null && Object.keys(errors).length > 0;
  const btnEnabled = isDirty && !hasErrors && !disabled;
  const submitBtnText = props.submitBtnText ?? t("save");
  const submitBtnIcon = props.submitBtnIcon ?? "fa fa-save";

  usePrompt(isDirty!, t("leave_dirty_page_warning"));

  return (
    <ul className="list-inline lst-spcd mrgn-bttm-0">
      <li>
        <button
          type="submit"
          disabled={!btnEnabled || props.isSubmitting}
          className="btn btn-primary"
          data-testid="form-button-save"
        >
          {props.isSubmitting ? (
            <>
              <i className="fa fa-spinner fa-spin" />
              <span>&nbsp; {t("saving")}</span>
            </>
          ) : (
            <>
              <i className={submitBtnIcon} />
              <span>&nbsp; {submitBtnText}</span>
            </>
          )}
        </button>
      </li>
      <li>
        <button
          type="button"
          onClick={props.onCancel}
          className="btn btn-default"
          data-testid="form-button-cancel"
          disabled={props.isSubmitting}
        >
          {t("cancel")}
        </button>
      </li>
    </ul>
  );
};

export default FormButtons;
