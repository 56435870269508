import { useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import { DocumentPendingRegistryUpdateDocument } from "generated/gql-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface RegistryPageNeedsPublishChangesWarningProps {
  documentId: string;
}

const RegistryPageNeedsPublishChangesWarning: React.FC<
  RegistryPageNeedsPublishChangesWarningProps
> = (props) => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(
    DocumentPendingRegistryUpdateDocument,
    {
      variables: {
        documentId: props.documentId,
      },
    }
  );

  return error ? (
    <GraphqlError errors={error} />
  ) : loading ? (
    <LoadingIndicator centered className="mrgn-bttm-md" />
  ) : data?.documentPendingRegistryUpdate === true ? (
    <Alert
      type={AlertTypes.WARNING}
      title={t("document_information_has_been_updated")}
      content={t(
        "registry_page_information_needs_publish_changes_to_view_on_registry"
      )}
    />
  ) : null;
};

export default RegistryPageNeedsPublishChangesWarning;
