import { useMutation, useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/TwoColumn";
import NoteCard from "components/molecules/NoteCard/NoteCard";
import SideNav from "components/molecules/SideNav";
import AssessmentInformationCard, {
  CosewicWsStateAndStatusFields,
} from "components/molecules/cosewic/AssessmentInformationCard";
import HistoryOfStatusDesignationCard from "components/molecules/cosewic/HistoryOfStatusDesignationCard";
import WildlifeSpeciesStatusAndCriteriaCard from "components/molecules/cosewic/WildlifeSpeciesStatusAndCriteriaCard";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import MakePublishedButtonAndModal from "components/organisms/MakePublishedButtonAndModal/MakePublishedButtonAndModal";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  AssessmentStage,
  AssessmentState,
  AssessmentStatusChange,
  CosewicAssessmentProfileDocument,
  CosewicWsState,
  CosewicWsStatus,
  DeleteAssessmentDocument,
  UpdateAssessmentDocument,
  UpdateAssessmentStageDocument,
  UpdateAssessmentStateDocument,
  WsStatus,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import GraphqlError from "../../../../components/GraphqlError";
import { RenderWhenAuthorized } from "../../../../features/auth/components";
import { ROLE_ACTIONS } from "../../../../features/auth/roles";
import useCheckbox from "../../../../hooks/util/useCheckbox";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";

export interface AssessmentProps {}

const Assessment: React.FC<AssessmentProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId, assessmentId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();
  const confirmDeleteModal = useConfirmationModal({});
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  const { loading, error, data } = useQuery(CosewicAssessmentProfileDocument, {
    variables: { cosewicWsId, assessmentId },
  });

  const [changeAssessmentStage, changeAssessmentStageMutationStatus] =
    useMutation(UpdateAssessmentStageDocument, {
      refetchQueries: ["CosewicAssessmentProfile"],
    });

  const [changeAssessmentState, changeAssessmentStateMutationStatus] =
    useMutation(UpdateAssessmentStateDocument, {
      refetchQueries: ["CosewicAssessmentProfile"],
    });

  const [updateAssessment, updateAssessmentMutationStatus] = useMutation(
    UpdateAssessmentDocument,
    {
      refetchQueries: ["CosewicAssessmentProfile"],
    }
  );

  const [runDeleteAssessment, deleteAssessmentStatus] = useMutation(
    DeleteAssessmentDocument
  );

  const onUpdateNote = async (newNoteText: string) => {
    try {
      const note = htmlIsNullOrEmpty(newNoteText)
        ? null
        : { text: { text: newNoteText } };

      const res = await updateAssessment({
        variables: {
          assessmentId,
          input: { note },
        },
      });

      if (res.errors) throw res.errors;
    } catch (e) {}

    console.log("Saved Note for Assessment", assessmentId);
  };

  const onMakeAssessedClick = async () => {
    try {
      const res = await changeAssessmentStage({
        variables: {
          assessmentId,
          stage: AssessmentStage.Assessed,
        },
      });

      if (res.errors) throw res.errors;

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {}
  };

  const onPublishClick = async () => {
    try {
      const res = await changeAssessmentState({
        variables: {
          assessmentId,
          state: AssessmentState.Published,
        },
      });

      if (res.errors) throw res.errors;

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {}
  };

  const onDeleteClick = async () => {
    try {
      const res = await runDeleteAssessment({
        variables: {
          id: assessmentId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        // TODO:i18n
        message: "Successfully deleted assessment",
      });

      history.push({
        pathname: CosewicPathHelpers.CosewicProfile(cosewicWsId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const canMakeAssessed = React.useMemo(() => {
    if (
      loading ||
      changeAssessmentStateMutationStatus.loading ||
      changeAssessmentStageMutationStatus.loading
    ) {
      return false;
    }

    if (
      data?.assessment?.statusAndCriteria?.status == null ||
      data?.assessment?.statusAndCriteria?.status === WsStatus.NotInitialized ||
      // CR: #55383, story #238 - If the assessment status is No status it is considered not assessed so the assessed button should be disabled (Aug. 4 2023)
      data?.assessment?.statusAndCriteria?.status === WsStatus.NoStatus
    ) {
      console.log(
        "[Make Assessed Btn] Disabled - Assessment status is empty or NotInitialized or NoStatus (",
        data?.assessment?.statusAndCriteria?.status,
        ")"
      );
      return false;
    }

    if (
      data?.assessment?.statusAndCriteria?.statusChange == null ||
      data?.assessment?.statusAndCriteria?.statusChange ===
        AssessmentStatusChange.NotInitialized
    ) {
      console.log(
        "[Make Assessed Btn] Disabled - Assessment status change is empty or NotInitialized (",
        data?.assessment?.statusAndCriteria?.statusChange,
        ")"
      );
      return false;
    }

    if (data?.assessment?.stage === AssessmentStage.Assessed) {
      console.log(
        "[Make Assessed Btn] Disabled - Assessment is already assessed"
      );
      return false;
    }

    return true;
  }, [
    loading,
    changeAssessmentStateMutationStatus.loading,
    changeAssessmentStageMutationStatus.loading,
    data?.assessment?.statusAndCriteria?.status,
    data?.assessment?.stage,
  ]);

  const canEdit = React.useMemo(() => {
    if (
      loading ||
      changeAssessmentStateMutationStatus.loading ||
      changeAssessmentStageMutationStatus.loading
    ) {
      return false;
    }
    if (
      data?.assessment?.stage === AssessmentStage.Assessed &&
      data?.assessment?.state === AssessmentState.Published
    ) {
      if (assessmentId !== overviewContext.latestAssessment?.id) {
        return false;
      }
    }
    return true;
  }, [
    loading,
    changeAssessmentStateMutationStatus.loading,
    changeAssessmentStageMutationStatus.loading,
    data?.assessment?.statusAndCriteria?.status,
    overviewContext.loading,
    overviewContext.latestAssessment?.id,
    assessmentId,
    data?.assessment?.stage,
    data?.assessment?.state,
  ]);

  const canDelete = React.useMemo(() => {
    if (
      loading ||
      changeAssessmentStateMutationStatus.loading ||
      changeAssessmentStageMutationStatus.loading
    ) {
      return false;
    }

    if (
      data?.cosewicWs?.status === CosewicWsStatus.Permanent &&
      data?.assessment?.stage === AssessmentStage.Assessed
    ) {
      console.log(
        "[Assessment Delete Btn] Disabled - COSEWIC WS is permanent and Assessment is assessed"
      );
      return false;
    }

    if (
      data?.cosewicWs?.state === CosewicWsState.Live &&
      data?.assessment?.stage === AssessmentStage.Assessed
    ) {
      console.log(
        "[Assessment Delete Btn] Disabled - COSEWIC WS is published and Assessment is assessed"
      );
      return false;
    }

    return true;
  }, [
    loading,
    changeAssessmentStateMutationStatus.loading,
    changeAssessmentStageMutationStatus.loading,
    data?.assessment?.stage,
    data?.cosewicWs?.status,
  ]);

  const canPublish = React.useMemo(() => {
    if (
      loading ||
      changeAssessmentStateMutationStatus.loading ||
      changeAssessmentStageMutationStatus.loading
    ) {
      return false;
    }

    if (data?.cosewicWs?.status !== CosewicWsStatus.Permanent) {
      console.log(
        "[Assessment Publish Btn] Disabled - COSEWIC WS is not permanent"
      );
      return false;
    }

    if (data?.cosewicWs?.state !== CosewicWsState.Live) {
      console.log(
        "[Assessment Publish Btn] Disabled - COSEWIC WS is not published"
      );
      return false;
    }

    if (data?.assessment?.stage !== AssessmentStage.Assessed) {
      console.log(
        "[Assessment Publish Btn] Disabled - Assessment is not assessed"
      );
      return false;
    }

    if (data?.assessment?.state === AssessmentState.Published) {
      console.log(
        "[Assessment Publish Btn] Disabled - Assessment is already published"
      );
      return false;
    }

    if (
      data?.assessment?.statusAndCriteria?.status == null ||
      data?.assessment?.statusAndCriteria?.status === WsStatus.NotInitialized
    ) {
      console.log(
        "[Assessment Publish Btn] Disabled - Assessment status is empty or NotInitialized (",
        data?.assessment?.statusAndCriteria?.status,
        ")"
      );
      return false;
    }

    if (
      data?.assessment?.statusAndCriteria?.statusChange == null ||
      data?.assessment?.statusAndCriteria?.statusChange ===
        AssessmentStatusChange.NotInitialized
    ) {
      console.log(
        "[Assessment Publish Btn] Disabled - Assessment status change is empty or NotInitialized (",
        data?.assessment?.statusAndCriteria?.statusChange,
        ")"
      );
      return false;
    }

    if (
      data?.assessment?.statusAndCriteria?.status ===
      WsStatus.DeferredRejectWithdrawn
    ) {
      console.log(
        "[Assessment Publish Btn] Disabled - Assessment status is DeferredRejectedWithdrawn"
      );
      return false;
    }

    return true;
  }, [
    loading,
    changeAssessmentStateMutationStatus.loading,
    changeAssessmentStageMutationStatus.loading,
    data?.cosewicWs?.status,
    data?.cosewicWs?.state,
    data?.assessment?.stage,
    data?.assessment?.state,
    data?.assessment?.statusAndCriteria?.status,
  ]);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("cosewic_assessment")}</h1>
          <GraphqlError title="Error fetching assessment data" errors={error} />
          <GraphqlError
            title="Error making assessment assessed"
            errors={changeAssessmentStageMutationStatus.error}
          />
          <GraphqlError
            title="Error publishing assessment"
            errors={changeAssessmentStateMutationStatus.error}
          />
          <GraphqlError
            title="Error deleting assessment"
            errors={deleteAssessmentStatus.error}
          />
          <GraphqlError
            title="Error updating assessment"
            errors={updateAssessmentMutationStatus.error}
          />

          <div className="flex justify-between align-center mrgn-bttm-md">
            <div className="flex font-size-18">
              <div>
                {t("assessment_stage")}{" "}
                <div className="label label-info">
                  {data?.assessment?.stage ? (
                    t(data?.assessment?.stage)
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
              <div className="mrgn-lft-md">
                {t("state")}{" "}
                <div className="label label-info">
                  {data?.assessment?.state ? (
                    t(data?.assessment.state)
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
            </div>
            <div>
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.update}
              >
                {canMakeAssessed ? (
                  <MakeAssessedButtonAndModal
                    canMakeAssessed={canMakeAssessed}
                    onMakeAssessed={onMakeAssessedClick}
                  />
                ) : null}
              </RenderWhenAuthorized>

              <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.cosewic.publish}
              >
                {canPublish ? (
                  <MakePublishedButtonAndModal
                    canMakePublished={canPublish}
                    onMakePublished={onPublishClick}
                    warningMessage={t("publish_assessment_to_registry_warning")}
                  />
                ) : null}
              </RenderWhenAuthorized>

              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.delete}
              >
                {canDelete ? (
                  <button
                    type="button"
                    onClick={confirmDeleteModal.open}
                    className="btn btn-sm btn-danger"
                    disabled={!canDelete}
                    data-testid="button-delete"
                  >
                    {t("delete")}
                  </button>
                ) : null}

                <ConfirmationModal
                  modalState={confirmDeleteModal}
                  title={t("delete_an_assessment")}
                  confirmBtnEnabled={confirmDeleteState}
                  onConfirm={onDeleteClick}
                  onCancel={() => {
                    confirmDeleteModal.close();
                    setConfirmDeleteState(false);
                  }}
                >
                  <Alert
                    type={AlertTypes.WARNING}
                    content={t("delete_assessment_warning")}
                  />

                  <label>
                    <input
                      type="checkbox"
                      checked={confirmDeleteState}
                      onChange={() => setConfirmDeleteState((x) => !x)}
                      data-testid="modal-checkbox-confirm-delete"
                    />{" "}
                    {t("delete_confirm")}
                  </label>
                </ConfirmationModal>
              </RenderWhenAuthorized>
            </div>
          </div>

          {/* ------------- Assessment Summary ------------- */}
          <SectionCard
            header={<h2 className="mrgn-tp-sm">{t("assessment_summary")}</h2>}
          >
            <div className="species-field">
              <dl className="row">
                <div className="species-data col-sm-4">
                  <dt className="text-muted">{t("assessment_date")}</dt>
                  <dd>
                    {formatTimestamp(data?.assessment?.date) ?? <MissingData />}
                  </dd>
                </div>

                <div className="species-data col-sm-4">
                  <dt className="text-muted">{t("cosewic_ws_status")}</dt>
                  <dd>
                    {data?.assessment?.statusAndCriteria?.status &&
                    data.assessment.statusAndCriteria.status !==
                      WsStatus.NotInitialized ? (
                      t(data?.assessment?.statusAndCriteria?.status)
                    ) : (
                      <MissingData />
                    )}
                  </dd>
                </div>

                <div className="species-data col-sm-4">
                  <dt className="text-muted">
                    {t("year_to_be_sent_to_minister")}
                  </dt>
                  <dd>
                    {formatTimestamp(
                      data?.assessment?.yearSentToMinister,
                      DisplayFormat.YEAR
                    ) ?? <MissingData />}
                  </dd>
                </div>
              </dl>
            </div>
          </SectionCard>

          {data != null && data.assessment != null ? (
            <div>
              {/* ------------- Assessment Information ------------- */}
              <AssessmentInformationCard
                header={t("assessment_information")}
                open
                editLinkTo={CosewicPathHelpers.AssessmentEdit(
                  cosewicWsId,
                  assessmentId
                )}
                cosewicWs={data?.cosewicWs as CosewicWsStateAndStatusFields}
                assessment={data?.assessment}
                editDisabled={!canEdit}
              ></AssessmentInformationCard>

              {/* ------------- Wildlife Species Status and Criteria ------------- */}
              <WildlifeSpeciesStatusAndCriteriaCard
                header={t("wildlife_species_status_and_criteria")}
                open
                editLinkTo={CosewicPathHelpers.AssessmentEdit(
                  cosewicWsId,
                  assessmentId
                )}
                assessment={data?.assessment}
                editDisabled={!canEdit}
              ></WildlifeSpeciesStatusAndCriteriaCard>

              {/* ------------- History of Status Designation ------------- */}
              <HistoryOfStatusDesignationCard
                header={t("history_status_designation")}
                open
                editLinkTo={CosewicPathHelpers.AssessmentEdit(
                  cosewicWsId,
                  assessmentId
                )}
                assessment={data?.assessment}
                editDisabled={!canEdit}
              ></HistoryOfStatusDesignationCard>
            </div>
          ) : (
            <MissingData></MissingData>
          )}

          <NoteCard
            header={t("assessment_note")}
            note={data?.assessment?.note}
            loading={loading}
            editDisabled={!canEdit}
            onUpdateNote={onUpdateNote}
            role={ROLE_ACTIONS.cosewic.update}
            maxCharacters={2000}
          />
          <br />
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

const MakeAssessedButtonAndModal: React.FC<{
  canMakeAssessed: boolean;
  onMakeAssessed: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const confirmMakeAssessedModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  return (
    <>
      <button
        className="btn btn-primary btn-sm mrgn-rght-sm"
        disabled={!props.canMakeAssessed}
        onClick={confirmMakeAssessedModal.open}
        data-testid="button-make-assessed"
      >
        {t("make_assessed")}
      </button>

      <ConfirmationModal
        modalState={confirmMakeAssessedModal}
        title={t("make_assessed")}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={props.onMakeAssessed}
        onCancel={() => {
          confirmMakeAssessedModal.close();
          checkboxState.reset();
        }}
      >
        <Alert type={AlertTypes.WARNING} content={t("make_assessed_warning")} />

        <label>
          <input
            type="checkbox"
            checked={checkboxState.checked}
            onChange={checkboxState.toggle}
            data-testid="modal-checkbox-confirm-make-assessed"
          />{" "}
          {t("confirm_make_assessed")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default Assessment;
