import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import NAICSSectorDetails from "components/molecules/listing/sectors/NAICSSectorDetails";
import { NAICSCodeLookupWithController } from "components/organisms/NAICS/NAICSCodeLookupForm/NAICSCodeLookupForm";
import { NAICS_VERSION } from "config/constants";
import {
  BilingualAbbreviationLookupText,
  NaicsCodeDetailsDocument,
  NaicsResource,
} from "generated/gql-types";
import { bilingualTextNameForLanguage } from "mappers";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { filterForDirtyFields } from "util/forms";
import isNullOrEmpty from "util/isNullOrEmpty";
import { ActivityMultiSelectListWithController } from "../ActivityMultiSelectList/ActivityMultiSelectList";
import { OutcomeMultiSelectListWithController } from "../OutcomeMultiSelectList/OutcomeMultiSelectList";
import {
  NAICSVersionProvider,
  useNAICSVersionContext,
} from "./NAICSVersionContext";

export interface ListingSectorFormFields {
  naics: NaicsResource;
  activities: BilingualAbbreviationLookupText[];
  outcomes: BilingualAbbreviationLookupText[];
}

export interface ListingSectorFormProps {
  onSubmit: SubmitHandler<Partial<ListingSectorFormFields>>;
  initialValues?: Partial<ListingSectorFormFields>;
}

export const ListingSectorForm: React.FC<ListingSectorFormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;

  const form = useForm<ListingSectorFormFields>();
  const { handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty } = formState;

  const [naicsVersionNumber] = useNAICSVersionContext();

  const onSubmit: SubmitHandler<Partial<ListingSectorFormFields>> = async (
    formData
  ) => {
    const values = filterForDirtyFields(formData, dirtyFields);
    props.onSubmit(values);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingSectors(listingWsId ?? "error"),
    });
  };

  const shouldSkipNaicsDetails = props?.initialValues?.naics == null;
  const naicsDetailsForInitialValue = useQuery(NaicsCodeDetailsDocument, {
    variables: {
      params: {
        code: props?.initialValues?.naics?.code || "error",
        version: NAICS_VERSION,
      },
    },
    skip: shouldSkipNaicsDetails,
  });

  const btnEnabled = dirtyFields.naics;

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionCard
            collapsible
            header={
              // Note: flexbug div is needed when collapsible is used in SectionCard.
              <div className="flexbug-9-workaround">
                <div className="flex justify-between align-start">
                  <h2 className="mrgn-tp-sm mrgn-bttm-sm">
                    {t("naics_classification")}
                  </h2>
                  <a
                    href="https://www23.statcan.gc.ca/imdb/p3VD.pl?Function=getVD&TVD=1369825"
                    target="_blank"
                    className="hover-underline"
                  >
                    {isNullOrEmpty(naicsVersionNumber) ? null : (
                      <h2 className="mrgn-tp-sm mrgn-bttm-sm">
                        (NAICS) Canada Version {naicsVersionNumber}
                      </h2>
                    )}
                  </a>
                </div>
              </div>
            }
          >
            {props.initialValues != null &&
            naicsDetailsForInitialValue?.error == null ? (
              <>
                <div className="species-field">
                  {naicsDetailsForInitialValue?.loading ? (
                    <LoadingIndicator centered />
                  ) : (
                    <>
                      <h5>
                        {props?.initialValues?.naics?.code} -{" "}
                        {bilingualTextNameForLanguage(
                          i18n.language,
                          props.initialValues?.naics?.className
                        )}
                      </h5>

                      <NAICSSectorDetails
                        naicsCodeDetails={
                          naicsDetailsForInitialValue?.data?.naicsCodeDetails
                        }
                      />
                    </>
                  )}
                </div>

                <h2>{t("select_new_sector")}</h2>
              </>
            ) : null}

            <NAICSCodeLookupWithController control={control} name="naics" />
          </SectionCard>

          <SectionCard
            collapsible
            header={<h2>{t("activity_and_outcomes")}</h2>}
          >
            <div className="flex-col gap-md">
              <ActivityMultiSelectListWithController
                control={control}
                name="activities"
                defaultValue={props?.initialValues?.activities}
              />
              <OutcomeMultiSelectListWithController
                control={control}
                name="outcomes"
                defaultValue={props?.initialValues?.outcomes}
              />
            </div>
          </SectionCard>
          <div className="form-group">
            <ul className="list-inline lst-spcd">
              <li>
                <button
                  type="submit"
                  disabled={!btnEnabled}
                  className="btn btn-primary"
                  data-testid="form-button-save"
                >
                  {
                    <>
                      <i className="fa fa-save" />
                      <span>&nbsp; {t("save")}</span>
                    </>
                  }
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={onCancel}
                  className="btn btn-default"
                  data-testid="form-button-cancel"
                >
                  {t("cancel")}
                </button>
              </li>
            </ul>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default function ListingSectorFormWithVersionContext(
  props: ListingSectorFormProps
) {
  return (
    <>
      <NAICSVersionProvider>
        <ListingSectorForm {...props} />
      </NAICSVersionProvider>
    </>
  );
}
