import isNullOrEmpty from "util/isNullOrEmpty";

export const appBuildVersion = !isNullOrEmpty(
  process.env.REACT_APP_PIPELINE_BUILD_NUMBER as string
)
  ? (process.env.REACT_APP_PIPELINE_BUILD_NUMBER as string)
  : undefined;

export const appBuildTimestamp = !isNullOrEmpty(
  process.env.REACT_APP_PIPELINE_BUILD_TIMESTAMP as string
)
  ? (process.env.REACT_APP_PIPELINE_BUILD_TIMESTAMP as string)
  : undefined;
