const permitTranslation = {
  en: {
    permit: "Permit",
    permit_description: "Permit description",
    permit_descriptions: "Permit descriptions",
    list_of_permit_descriptions: "List of permit descriptions",
    search_permit_id: "Search permit ID",
    add_permit_description: "Add permit description",
    permit_issuers: "Permit issuers",
    permit_id: "Permit ID",
    select_an_issuing_authority: "Select an issuing authority",
    add_permit_issuer: "Add permit issuer",
    issuing_authority: "Issuing authority",
    contact_person: "Contact person",
    enter_a_permit_id: "Enter a permit ID",
    permit_description_information: "Permit description information",
    permit_type: "Permit type",
    explanation_for: "Explanation for",
    purpose: "Purpose",
    description_of_activity: "Description of activity",
    pre_conditions: "Pre-conditions",
    authority_used_act_of_parliament: "Authority used (Act of Parliament)",
    location_of_activity: "Location of activity",
    terms_and_conditions: "Terms and conditions",
    other_relevant_information: "Other relevant information",
    parks_canada_places: "Parks Canada administered places",
    select_parks_canada_places: "Select Parks Canada administered places",
    affected_wildlife_species: "Affected wildlife species",
    select_wildlife_species: "Select wildlife species",
    permit_purpose: "Permit purpose",
    finalize: "Finalize",
    finalize_modal_title: "Finalize Permit description",
    finalize_permit_to_registry_warning:
      "Permit description will move from Draft to Final",
    finalize_permit_to_registry_confirmation:
      "I confirm that I'd like to finalize.",
    activity_used: "Activity used",
    federal_parks: "Federal parks",
    permit_description_successfully_added:
      "Permit description successfully added",
    permit_description_successfully_updated:
      "Permit description successfully updated",
    permit_description_successfully_deleted:
      "Permit description successfully deleted",
    publish_permit_to_registry_warning:
      "Permit description will be published on the Registry",
    unpublish_permit_from_registry_warning:
      "Permit description will be unpublished and will not be visible on the Registry",
    publish: "Publish",
    unpublish: "Unpublish",
    delete_permit_warning:
      "Permit description will be removed from the system and not accessible on the Registry",
    delete_a_permit: "Delete permit description",
    federal_canada_places: "Federal Canada Places",
    select_federal_canada_places: "Select Federal Canada Places",
    issuance_date: "Issuance date",
    permit_ids: "Permit IDs",
    id: "ID",

    // Enum
    SECTION_73: "Section 73",
    SECTION_74: "Section 74",

    PERMIT: "Permit",
    AGREEMENT: "Agreement",
    LICENSE: "Licence",
    ORDER: "Order",
    OTHER_SIMILAR_DOCUMENTS: "Other similar documents",

    ACTIVITY_AFFECTING_SPECIES:
      "Affecting the species is incidental to the carrying out of the activity",
    ACTIVITY_NECESSARY_OR_BENEFICIAL:
      "Activity necessary or beneficial to the species",
    SCIENTIFIC_RESEARCH:
      "Scientific research for the conservation of the species",

    CANADA_NATIONAL_PARKS_ACT: "Canada National Parks Act",
    CANADA_WILDLIFE_ACT: "Canada Wildlife Act",
    FISHERIES_ACT: "Fisheries Act",
    MIGRATORY_BIRD_CONVENTION_ACT: "Migratory Birds Convention Act",
    OTHER_ACT_OF_PARLIAMENT: "Other Act of Parliament",
    SPECIES_AT_RISK_ACT: "Species at Risk Act",

    // Tooltips
    required_to_make_pd_final_tooltip:
      "Required to make permit description Final.",
  },

  fr: {
    permit: "Permis",
    permit_description: "Descriptions de permis",
    permit_descriptions: "Descriptions de permis",
    list_of_permit_descriptions: "Liste des descriptions de permis",
    search_permit_id: "Recherche du numéro du permis",
    add_permit_description: "Ajouter une nouvelle description de permis",
    permit_issuers: "Délivreurs de permis",
    permit_id: "Numéro du permis",
    select_an_issuing_authority: "Sélectionner une autorité de délivrance",
    add_permit_issuer: "Ajouter des délivreurs de permis",
    issuing_authority: "Autorité de délivrance",
    contact_person: "Personne contact",
    enter_a_permit_id: "Entrer un numéro du permis",
    permit_description_information: "Informations sur la description du permis",
    permit_type: "Type de permis",
    explanation_for: "Explication pour",
    purpose: "Activités visées",
    description_of_activity: "Description de l'activité",
    pre_conditions: "Conditions préalables",
    authority_used_act_of_parliament: "Autorité utilisée (Loi du Parlement)",
    location_of_activity: "Lieu de l'activité",
    terms_and_conditions: "Conditions d'utilisation",
    other_relevant_information: "Autres informations pertinentes",
    parks_canada_places: "Lieux administrés par Parcs Canada",
    select_parks_canada_places: "Sélectionner les lieux administrés par Parcs Canada",
    affected_wildlife_species: "Espèces sauvages affectées",
    select_wildlife_species: "Sélectionner les espèces sauvages",
    permit_purpose: "Objet du permis",
    federal_parks: "Parcs fédéraux",
    finalize: "Finaliser",
    finalize_modal_title: "Finaliser la description du permis",
    finalize_permit_to_registry_warning:
      'La description du permis passera de "Provisoire" à "Final"',
    finalize_permit_to_registry_confirmation:
      "Je confirme que je souhaite finaliser",
    permit_description_successfully_added:
      "La description du permis a été ajoutée avec succès",
    permit_description_successfully_updated:
      "La mise à jour de la description du permis est réussie",
    permit_description_successfully_deleted:
      "La description du permis a été supprimée avec succès",
    publish_permit_to_registry_warning:
      "La description de permis sera publiée sur le Registre",
    unpublish_permit_from_registry_warning:
      "La description du permis sera non publiée et ne sera pas visible sur le Registre.",
    publish: "Publié",
    unpublish: "Dépublier",
    delete_permit_warning:
      "La description du permis sera supprimée du système et ne sera pas accessible sur le Registre.",
    delete_a_permit: "Supprimer la description du permis",
    federal_canada_places: "Lieux fédéraux du Canada",
    select_federal_canada_places: "Sélectionner les lieux fédéraux du Canada",
    issuance_date: "Date de délivrance du permis",
    permit_ids: "ID des permis",
    id: "ID",

    // Enum
    SECTION_73: "Article 73",
    SECTION_74: "Article 74",

    PERMIT: "Permis",
    AGREEMENT: "Accord",
    LICENSE: "Licence",
    ORDER: "Arrêté",
    OTHER_SIMILAR_DOCUMENTS: "Autres documents similaires",

    ACTIVITY_AFFECTING_SPECIES:
      "Activité qui ne touche l'espèce que de façon incidente",
    ACTIVITY_NECESSARY_OR_BENEFICIAL:
      "Activité nécessaire ou profitable à l'espèce",
    SCIENTIFIC_RESEARCH:
      "Recherche scientifique sur la conservation de l'espèce",

    CANADA_NATIONAL_PARKS_ACT: "Loi sur les parcs nationaux du Canada",
    CANADA_WILDLIFE_ACT: "Loi sur les espèces sauvages du Canada",
    FISHERIES_ACT: "Loi sur les pêches",
    MIGRATORY_BIRD_CONVENTION_ACT:
      "Loi sur la convention concernant les oiseaux migrateurs",
    OTHER_ACT_OF_PARLIAMENT: "Autre Loi du Parlement",
    SPECIES_AT_RISK_ACT: "Loi sur les espèces en péril",

    // Tooltips
    required_to_make_pd_final_tooltip:
      "Requis pour finaliser la description du permis.",
  },
};

export default permitTranslation;
