import cc from "classcat";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import { RenderWhenAuthorized, Roles } from "features/auth/components";
import { ResponseStatementPathHelpers } from "pages/responseStatement/responseStatementRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ListingPathHelpers } from "./ListingRouter";
import styles from "./index.module.css";

const Index: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Layout.Root>
        <Layout.HomeContent>
          <div className={cc(["provisional bg-cover", styles.hero_bg_img])}>
            <div className={cc(["container", styles.hero_container])}>
              <div className="well header-rwd brdr-0 brdr-rds-0 text-white bg-gctheme opct-80">
                <h1
                  property="name"
                  id="wb-cont"
                  className="h2 mrgn-tp-lg mrgn-bttm-lg"
                >
                  {t("listing_home")}
                </h1>
              </div>
            </div>
          </div>
          <div className="home experimental">
            <div className="gc-srvinfo container mrgn-tp-lg">
              <div className="wb-eqht row">
                <div className="col-md-6">
                  <SectionCard
                    className="hght-inhrt"
                    header={<h2>{t("listing_wildlife_species_search")}</h2>}
                  >
                    <p>{t("listing_wildlife_species_search_content")}</p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push({
                          pathname: "/search/listing",
                        });
                      }}
                      data-testid="button-search-ws"
                    >
                      {t("search_wildlife_species")}
                    </button>
                  </SectionCard>
                </div>
                <div className="col-md-6">
                  <RenderWhenAuthorized
                    authorizedRoles={[
                      Roles.ListingAdministrator,
                      Roles.ListingEditor,
                    ]}
                  >
                    <SectionCard
                      className="hght-inhrt"
                      header={<h2>{t("add_listing_wildlife_species")}</h2>}
                    >
                      <p>&nbsp;</p>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          history.push({
                            pathname: ListingPathHelpers.ListingCreate,
                          });
                        }}
                        data-testid="button-add-new-ws"
                      >
                        {t("add_a_new_wildlife_species")}
                      </button>
                    </SectionCard>
                  </RenderWhenAuthorized>
                </div>
              </div>

              <div className="wb-eqht row">
                <div className="col-md-6">
                  <SectionCard
                    className="hght-inhrt"
                    header={<h2>{t("response_statement_reports")}</h2>}
                  >
                    <p>&nbsp;</p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push({
                          pathname:
                            ResponseStatementPathHelpers.ResponseStatementBrowse,
                        });
                      }}
                      data-testid="button-response-statement-reports"
                    >
                      {t("response_statement_reports")}
                    </button>
                  </SectionCard>
                </div>
              </div>
            </div>
          </div>
        </Layout.HomeContent>
      </Layout.Root>
    </>
  );
};

export default Index;
