import { useQuery } from "@apollo/client";
import { BilingualName, RangeCheckboxListDocument } from "generated/gql-types";
import { xor } from "lodash";
import { fullBilingualNameForLanguage } from "mappers";
import * as React from "react";
import { useTranslation } from "react-i18next";

const RangeCheckboxes: React.FC<{
  onChange: (ranges: BilingualName[]) => void;
  defaultSelected: DefinitelyGQL<BilingualName>[];
  disabled?: boolean;
}> = ({ onChange, defaultSelected = [], disabled }) => {
  const { i18n } = useTranslation();
  const { loading, error, data } = useQuery(RangeCheckboxListDocument, {
    variables: {
      params: {
        pageNumber: 1,
        pageSize: 30,
        status: "active",
      },
    },
    fetchPolicy: "cache-first",
  });

  const [selectedIds, setSelectedIds] = React.useState<string[]>(
    defaultSelected.map((x) => x?.id ?? "")
  );

  const onToggleId = (id: string) => {
    if (data == null || data.rangeList?.range == null) return;
    const newIds = xor(selectedIds, [id]);

    const resolved = data?.rangeList.range
      .filter((x) => newIds.includes(x!.id))
      .map((x) => ({
        id: x?.id,
        nameEn: x?.nameEnglish,
        nameFr: x?.nameFrench,
      }));

    onChange(resolved);
    setSelectedIds(newIds);
  };

  if (loading) return <p>Loading...</p>; // TODO: show loading indicator
  if (error) {
    console.error(error);
    // TODO: better error display
    return <p>Error loading range list</p>;
  }

  // TODO: map this to Array<BilingualName>, rather than mapping every item to BilingualName in onChange
  const ranges = data?.rangeList?.range ?? [];
  const sortedRanges = [...ranges];
  sortedRanges.sort((a, b) =>
    fullBilingualNameForLanguage(i18n.language, a).localeCompare(
      fullBilingualNameForLanguage(i18n.language, b)
    )
  );

  return (
    <div className="checkbox-columns-lg">
      {sortedRanges.map((x) => {
        if (!x) return <label>Error: Missing Range</label>;
        const id = `range_checkbox_${x?.id}`;
        const dataTestId = `range_checkbox_${x?.nameEnglish}`.replace(
          /\s/g,
          ""
        );
        const onSelected = () => {
          onToggleId(x.id);
        };

        return (
          <div className="checkbox" key={x.id}>
            <label htmlFor={id}>
              <input
                type="checkbox"
                id={id}
                data-testid={dataTestId}
                key={id}
                onChange={onSelected}
                defaultChecked={selectedIds.includes(x.id)}
                disabled={disabled}
              />
              <span>{fullBilingualNameForLanguage(i18n.language, x)}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RangeCheckboxes;
