import { OverlayContainer } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import ModalDialog from "components/molecules/Modal";
import { MAX_LENGTH_OF_NOTE } from "config/constants";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { htmlToPlainText } from "util/richtext";
import { FullHTMLEditor } from "./FullHTMLEditor";

export interface NoteModalProps {
  modalState: OverlayTriggerState;
  defaultValue: string;
  maxCharacters?: number;
  onSave: (newValue: string) => void;
}

const NoteModal: React.FC<NoteModalProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, maxCharacters = 256 } = props;
  const [value, setValue] = React.useState(props.defaultValue);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isMax, setIsMax] = React.useState(false);

  const onSave = () => {
    props.onSave(value);
    modalState.close();
    setIsDirty(false);
  };

  const onClose = () => {
    modalState.close();
    setIsDirty(false);
  };

  const onChange = (v: React.SetStateAction<string>) => {
    setValue(v);
    setIsDirty(true);
    setIsMax(htmlToPlainText(v as string).length > maxCharacters);
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog title={t("note")} isOpen onClose={onClose} isDismissable>
        <FullHTMLEditor
          defaultValue={props.defaultValue}
          id="note_modal"
          placeholder={t("note")}
          onChange={onChange}
        />
        {isMax ? (
          <FieldValidationError>
            {t("reach_the_max_length", {
              count: maxCharacters,
            })}
          </FieldValidationError>
        ) : null}
        <button
          className="btn btn-sm btn-primary mrgn-tp-md"
          data-testid="modal-button-save"
          onClick={onSave}
          disabled={!isDirty || isMax}
        >
          {t("save")}
        </button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export const useNoteModal = useOverlayTriggerState;

export default NoteModal;
