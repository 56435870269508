import EditImage from "components/atoms/EditImage";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";

export interface BilingualHtmlTextProps {
  htmlStringEnglish?: string | null;
  htmlStringFrench?: string | null;
  editLinkTo?: string;
}

export const BilingualHtmlText: React.FC<BilingualHtmlTextProps> = (props) => {
  const { t } = useTranslation();
  const { htmlStringEnglish, htmlStringFrench, editLinkTo } = props;

  return (
    <>
      {editLinkTo == null ||
      !htmlIsNullOrEmpty(htmlStringEnglish) ||
      !htmlIsNullOrEmpty(htmlStringFrench) ? (
        <div>
          <div className="species-field mrgn-tp-md">
            <div className="row">
              <dl>
                <div className="species-data col-sm-12">
                  <dt className="text-muted">{t("english")}</dt>
                  <dd>
                    {htmlIsNullOrEmpty(htmlStringEnglish) ? (
                      <MissingData />
                    ) : (
                      <div className="font-size-18">
                        <SafeRenderHtml htmlString={htmlStringEnglish ?? ""} />
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="species-field">
            <div className="row">
              <dl>
                <div className="species-data col-sm-12">
                  <dt className="text-muted">{t("french")}</dt>
                  <dd>
                    {htmlIsNullOrEmpty(htmlStringFrench) ? (
                      <MissingData />
                    ) : (
                      <div className="font-size-18">
                        <SafeRenderHtml htmlString={htmlStringFrench ?? ""} />
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center mrgn-tp-md">
          <Link to={editLinkTo} className="hover-underline">
            <EditImage />
            <div className="lead mrgn-tp-md mrgn-bttm-sm">
              {t("no_information")}
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default BilingualHtmlText;
