import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import type { ResponseStatementFields } from "components/molecules/admin/ResponseStatementForm";
import ResponseStatementForm from "components/molecules/admin/ResponseStatementForm";
import ModalDialog from "components/molecules/Modal";
import { UpdateAdminResponseStatementDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface EditResponseStatementPopupProps {
  modalState: OverlayTriggerState;
  responseStatementId: string;
  defaultValues: ResponseStatementFields;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditResponseStatementPopup: React.FC<EditResponseStatementPopupProps> = (
  props
) => {
  const { t } = useTranslation();
  const { modalState, responseStatementId, defaultValues } = props;

  const [editResponseStatement, { loading, error, reset }] = useMutation(
    UpdateAdminResponseStatementDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
    }
  );

  const onSubmit: SubmitHandler<ResponseStatementFields> = async (formData) => {
    try {
      const res = await editResponseStatement({
        variables: {
          input: {
            name: {
              english: formData.nameEnglish,
              french: formData.nameFrench,
            },
            type: formData.statementType,
            includeConsultationPeriod: formData.includeConsultationPeriod,
            message: {
              english: {
                text: formData.englishMessage,
              },
              french: {
                text: formData.frenchMessage,
              },
            },
          },
          updateAdminResponseStatementId: responseStatementId,
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving genus");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_response_statement_field")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        <GraphqlError title="" errors={error} />
        {loading && <LoadingIndicator centered />}
        <ResponseStatementForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditResponseStatementPopup;

export const useResponseStatementPopup = useOverlayTriggerState;
export type { ResponseStatementFields };
