import EditImage from "components/atoms/EditImage";
import MissingData from "components/atoms/MissingData";
import { ListingWsLegalProtection, Maybe } from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import isNullOrEmpty from "util/isNullOrEmpty";
import {
  RenderWhenAuthorized,
  ROLE_ACTIONS,
} from "../../../../../features/auth/components";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../../atoms/SafeRenderHtml";

export interface LegalProtectionSectionProps {
  legalProtection?: Maybe<ListingWsLegalProtection>;
  listingWsId: string;
}

export const LegalProtectionSection: React.FC<LegalProtectionSectionProps> = (
  props
) => {
  const { legalProtection, listingWsId } = props;
  const { t, i18n } = useTranslation();

  const [showEditImage, setShowEditImage] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!legalProtection) setShowEditImage(true);
    else setShowEditImage(false);
  }, [legalProtection]);

  const jurisdictionString =
    i18n.language === "fr"
      ? legalProtection?.jurisdiction?.name?.french
      : legalProtection?.jurisdiction?.name?.english;

  const provincialAndTerritorialLegislations =
    legalProtection?.provincialAndTerritorialLegislations;

  const legislationApplicationString = legalProtection?.legislationApplication;

  const otherFederalProtections = legalProtection?.otherFederalProtections;

  const federalProtectionApplicationString =
    legalProtection?.federalProtectionApplication;

  const additionalProtectionUnderSara =
    legalProtection?.additionalProtectionUnderSara;

  return (
    <>
      {showEditImage ? (
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.update}
          fallbackComponent={<MissingData />}
        >
          <div className="text-center mrgn-tp-sm">
            <Link
              to={ListingPathHelpers.ListingEdit(listingWsId)}
              className="hover-underline"
            >
              <EditImage />
              <div className="no-results-content mrgn-tp-sm">
                {t("no_information")}
              </div>
            </Link>
          </div>
        </RenderWhenAuthorized>
      ) : (
        <div className="species-field">
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("jurisdiction")}</dt>
                <dd>
                  {isNullOrEmpty(jurisdictionString) ? (
                    <MissingData />
                  ) : (
                    jurisdictionString
                  )}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("provincial_territorial_legislation")}
                </dt>
                <dd>
                  {!provincialAndTerritorialLegislations ||
                  provincialAndTerritorialLegislations.length === 0 ? (
                    <MissingData />
                  ) : (
                    <ul className="list-unstyled lst-spcd">
                      {provincialAndTerritorialLegislations?.map((item) => (
                        <li key={item?.id}>
                          {i18n.language === "fr"
                            ? item?.name?.french
                            : item?.name?.english}
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12 text-break">
                <dt className="text-muted">{t("legislation_application")}</dt>
                <dd>
                  {htmlIsNullOrEmpty(legislationApplicationString?.text) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={legislationApplicationString?.text!}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("other_federal_protection")}</dt>
                <dd>
                  {!otherFederalProtections ||
                  otherFederalProtections.length === 0 ? (
                    <MissingData />
                  ) : (
                    <ul className="list-unstyled lst-spcd">
                      {otherFederalProtections?.map((item) => (
                        <li key={item?.id}>
                          {i18n.language === "fr"
                            ? item?.name?.french
                            : item?.name?.english}
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12 text-break">
                <dt className="text-muted">
                  {t("federal_protection_application")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    federalProtectionApplicationString?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={federalProtectionApplicationString?.text!}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("additional_protection_under_sara")}
                </dt>
                <dd>
                  {!additionalProtectionUnderSara ||
                  additionalProtectionUnderSara.length === 0 ? (
                    <MissingData />
                  ) : (
                    <ul className="list-unstyled lst-spcd">
                      {additionalProtectionUnderSara?.map((item) => (
                        <li key={item?.id}>
                          {i18n.language === "fr"
                            ? item?.name?.french
                            : item?.name?.english}
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default LegalProtectionSection;
