import FormButtons from "components/molecules/FormButtons/FormButtons";
import type { ChangeMetaData } from "features/changeTracking/types";
import { FeatureFlagKeys } from "features/featureFlags/config";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import {
  BilingualAbbreviationLookupTextInput,
  BilingualLookupTextInput,
  BilingualName,
  BilingualRichText,
  CommonNameInput,
  Gender,
  IdNameType,
  ListingWs,
  NameWithCommaInput,
  RichTextInput,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConsultationConsiderationsSection } from "./ConsultationConsiderationsSection";
import { LegalProtectionSection } from "./LegalProtectionSection";
import { LegalStatusSection } from "./LegalStatusSection";
import { OverallAssessmentSection } from "./OverallAssessmentSection";
import { processListingEditFormData } from "./util";
import { WildlifeSpeciesInformationSection } from "./WildlifeSpeciesInformationSection";

export const ListingForm: React.FC<ListingFormProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;

  const form = useForm<ListingFormOutput>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState } = form;
  const { dirtyFields, isDirty } = formState;

  const onSubmit: SubmitHandler<Partial<ListingFormOutput>> = async (
    formData
  ) => {
    const values = await processListingEditFormData(
      formData,
      dirtyFields,
      initialValues
    );
    await props.onSubmit(values);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingProfile(listingWsId ?? "error"),
    });
  };

  const location = useLocation();
  const editLinkClicked = location.state;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WildlifeSpeciesInformationSection
          listingWs={props.listingWs}
          initialValues={initialValues}
          open={
            editLinkClicked === t("wildlife_species_information") ||
            editLinkClicked == null
          }
        />
        <LegalStatusSection
          initialValues={initialValues}
          open={editLinkClicked === t("sara_status") || editLinkClicked == null}
        />
        <ConsultationConsiderationsSection
          initialValues={initialValues}
          open={
            editLinkClicked === t("consultation_considerations") ||
            editLinkClicked == null
          }
        />
        <LegalProtectionSection
          initialValues={initialValues}
          open={
            editLinkClicked === t("legal_protection") || editLinkClicked == null
          }
        />
        <OverallAssessmentSection
          initialValues={initialValues}
          open={
            editLinkClicked === t("overall_assessment") ||
            editLinkClicked == null
          }
        />

        <FormButtons isDirty={isDirty} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};

export default ListingForm;

// ------------------------------------------------------------------

export interface RemoveFieldRowBtnProps {
  onClick: () => void;
}

export const RemoveFieldRowBtn: React.FC<RemoveFieldRowBtnProps> = (props) => {
  return (
    <span
      className="glyphicon glyphicon-remove mrgn-lft-sm font-size-12 pointer color-danger"
      onClick={props.onClick}
      tabIndex={0}
    />
  );
};

export interface NewItemBtnProps {
  onClick: () => void;
  noContainer?: boolean;
}

export const NewItemBtn: React.FC<NewItemBtnProps> = (props) => {
  const { t } = useTranslation();

  if (props.noContainer)
    return (
      <button type="button" className="btn btn-default" onClick={props.onClick}>
        {t("new_item")}
      </button>
    );

  return (
    <div>
      <button type="button" className="btn btn-default" onClick={props.onClick}>
        {t("new_item")}
      </button>
    </div>
  );
};

///////////////////////////////////////////////////

export interface ListingFormFields {
  // ws info
  commonNameEnglish: CommonNameInput;
  commonNameFrench: CommonNameInput;
  nameWithComma: NameWithCommaInput;
  population: BilingualName;
  genus: IdNameType;
  variety: IdNameType;
  species: IdNameType;
  subSpecies: IdNameType;
  taxonomicGroup: BilingualName;
  gender: Gender;
  ranges: Array<DefinitelyGQL<BilingualName>>;

  legalStatus: {
    competentMinister1: BilingualLookupTextInput;
    competentMinister2: Array<BilingualLookupTextInput>;
    listingHistory: BilingualRichText;
  };

  consultationConsideration: {
    stakeholders: Array<BilingualLookupTextInput>;
    estimatedIndividualAssessment: string;
    indigenousCommunities: Array<BilingualLookupTextInput>;
    wildlifeManagementBoards: Array<BilingualAbbreviationLookupTextInput>;
    conservationActivities: BilingualRichText;
  };

  legalProtection: {
    provincialAndTerritorialLegislations: Array<BilingualLookupTextInput>;
    otherFederalProtections: Array<BilingualLookupTextInput>;
    additionalProtectionUnderSara: Array<BilingualLookupTextInput>;
    jurisdiction: BilingualLookupTextInput;
    legislationApplication: RichTextInput;
    federalProtectionApplication: RichTextInput;
  };

  overallAssessment: {
    expectedImpactOnFederalLands: BilingualLookupTextInput;
    expectedImpactOnFederalLandsRationale: RichTextInput;
    expectedImpactOnNonFederalLands: BilingualLookupTextInput;
    expectedImpactOnNonFederalLandsRationale: RichTextInput;
  };
}

export interface ListingFormChangeTracking {
  commonNameEnglishChangeMetaData: ChangeMetaData;
  commonNameFrenchChangeMetaData: ChangeMetaData;
  nameWithCommaChangeMetaData: ChangeMetaData;
  populationChangeMetaData: ChangeMetaData;
  genusChangeMetaData: ChangeMetaData;
  varietyChangeMetaData: ChangeMetaData;
  speciesChangeMetaData: ChangeMetaData;
  subSpeciesChangeMetaData: ChangeMetaData;
}

export interface ListingFormOutput
  extends ListingFormFields,
    ListingFormChangeTracking {}

export interface ListingFormProps {
  listingWs?: ListingWs;
  initialValues: ListingFormFields;
  onSubmit: SubmitHandler<Partial<ListingFormOutput>>;
}
