import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  AmendmentAlignmentLookupDocument,
  BilingualLookupText,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface AmendmentAlignmentDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  onChange: (value: BilingualLookupText) => void;
}

export const AmendmentAlignmentDropdown = React.forwardRef<
  HTMLSelectElement,
  AmendmentAlignmentDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(AmendmentAlignmentLookupDocument);
  const { t, i18n } = useTranslation();

  if (loading) return <LoadingIndicator />;

  //
  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.listingWsProcessAmendmentAlignment?.find(
      (x) => x?.id === id
    );

    if (!value) {
      console.error(
        `AmendmentAlignmentDropdown couldn't find a value for ID ${id}`
      );
      return;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control"
      ref={ref}
      {...props}
      data-testid={props.id}
      onChange={onChange}
      defaultValue={props.defaultValue}
    >
      <option value={""}>{t("please_select_option")}</option>

      {data?.listingWsProcessAmendmentAlignment?.map((x) => {
        return (
          <option key={x?.id} value={x?.id ?? "error - missing id"}>
            {bilingualLookupTextNameForLanguage(i18n.language, x)}
          </option>
        );
      })}
    </select>
  );
});

export interface AmendmentAlignmentDropdownWithControllerProps<TFieldValues>
  extends Omit<AmendmentAlignmentDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const AmendmentAlignmentDropdownWithController = <TFieldValues,>(
  props: AmendmentAlignmentDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <AmendmentAlignmentDropdown
          id={props.id}
          placeholder={props.placeholder}
          onChange={onChange}
          defaultValue={(value as any)?.id ?? ""}
        />
      )}
    />
  );
};

export default AmendmentAlignmentDropdown;
