import { useMutation, useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import PhotoLicenseDocumentForm, {
  PhotoLicenseDocumentFormFields,
} from "components/organisms/documents/PhotoLicense/PhotoLicenseDocumentForm/PhotoLicenseDocumentForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/documents/PhotoLicense/PhotoLicenseDocumentForm/mappers";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  PhotoLicenseDocument,
  UpdatePhotoLicenseDocument,
} from "generated/gql-types";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import GraphqlError from "../../../../components/GraphqlError";

export interface PhotoLicenseEditPageProps {}

export const PhotoLicenseEditPage: React.FC<PhotoLicenseEditPageProps> = () => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch();
  const { photoLicenseId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const { loading, error, data } = useQuery(PhotoLicenseDocument, {
    errorPolicy: "all",
    variables: {
      photoLicenseId: photoLicenseId,
    },
    fetchPolicy: "network-only",
  });

  const [updatePhotoLicense, { loading: saving, error: savingError }] =
    useMutation(UpdatePhotoLicenseDocument);

  const onSubmit: SubmitHandler<
    Partial<PhotoLicenseDocumentFormFields>
  > = async (FilteredFormData) => {
    const mappedDomainModel = mapFormToDomainModel(FilteredFormData);
    try {
      const res = await updatePhotoLicense({
        variables: {
          updatePhotoLicenseId: photoLicenseId,
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_updated_document"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.PhotoLicenseProfile(
          res.data?.updatePhotoLicense?.id ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: DocumentsPathHelpers.PhotoLicenseProfile(
        photoLicenseId ?? "error"
      ),
    });
  };

  const photoLicense = data?.photoLicense;
  const initialFormFields = mapDomainModelToForm(photoLicense);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("edit_photo_licence_document")}</h1>
          <GraphqlError
            title="Error loading photo licence document information"
            errors={error}
          />
          <GraphqlError
            title="Error editing photo licence document"
            errors={savingError}
          />
          {loading ? (
            <LoadingIndicator />
          ) : (
            <PhotoLicenseDocumentForm
              initialValues={initialFormFields}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PhotoLicenseEditPage;
