import * as React from "react";
import { useTranslation } from "react-i18next";
import Alert, { AlertTypes } from "../../../atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "../../ConfirmationModal";

export interface RemoveContactButtonAndModalProps {
  selectedMemberId: string;
  onRemove: (selectedItemId: string) => void;
  modalTitle: string;
  warningText: string;
}

export const RemoveContactButtonAndModal: React.FC<
  RemoveContactButtonAndModalProps
> = (props) => {
  const { selectedMemberId, onRemove, modalTitle, warningText } = props;
  const { t } = useTranslation();

  const confirmationModal = useConfirmationModal({});
  const [confirmCheckboxState, setConfirmCheckboxState] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setConfirmCheckboxState(false);
  }, [selectedMemberId]);

  const onConfirm = () => {
    onRemove(selectedMemberId);
    confirmationModal.close();
  };

  return (
    <>
      <button
        className="btn btn-link card-header-button-link hover-underline-danger"
        type="button"
        title={t("remove_contact_organization")}
        data-testid="button-remove-contact-organization"
        onClick={confirmationModal.open}
        disabled={selectedMemberId === ""}
      >
        <i className="far fa-times-circle mrgn-rght-sm text-danger"></i>
        <span className="text-danger">{t("remove")}</span>
      </button>
      <ConfirmationModal
        onConfirm={onConfirm}
        onCancel={() => {}}
        title={modalTitle}
        confirmBtnEnabled={confirmCheckboxState}
        modalState={confirmationModal}
      >
        <>
          <Alert type={AlertTypes.WARNING} content={warningText} />

          <label>
            <input
              type="checkbox"
              checked={confirmCheckboxState}
              onChange={(e) => setConfirmCheckboxState((x) => !x)}
              data-testid="modal-checkbox-confirm-remove"
            />{" "}
            {t("i_confirm")}
          </label>
        </>
      </ConfirmationModal>
    </>
  );
};
