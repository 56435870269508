import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useBasicSearch from "../../../../pages/search/hooks/useBasicSearch";
import { formatTimestamp } from "../../../../util/formatTimestamp";

export interface FilterTagsProps {
  onReset: () => void;
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const FilterTags: React.FC<FilterTagsProps> = (props) => {
  const { t } = useTranslation();

  const checkboxFilters = useMemo(
    () =>
      Object.values(props.basicSearch.state.checkboxFilters).flatMap((x) => x),
    [props.basicSearch.state.checkboxFilters]
  );

  const numberRangeFilters = useMemo(
    () => Object.values(props.basicSearch.state.numberRangeFilters),
    [props.basicSearch.state.numberRangeFilters]
  );

  const dateRangeFilters = useMemo(
    () => Object.values(props.basicSearch.state.dateRangeFilters),
    [props.basicSearch.state.dateRangeFilters]
  );

  const Tag = (props: {
    label: string;
    value: string;
    onRemove: () => void;
  }) => {
    const id = `removetag-${props.value}`;

    return (
      <div className="tag-item" key={id}>
        <button type="button" onClick={props.onRemove} id={id} data-testid={id}>
          <span className="mrgn-rght-sm text-overflow-ellipsis">
            {t(props.label)}
          </span>
          <span className="fa fa-times"></span>
        </button>
      </div>
    );
  };

  if (
    checkboxFilters.length === 0 &&
    dateRangeFilters.length === 0 &&
    numberRangeFilters.length === 0
  ) {
    return null;
  }

  return (
    <>
      <div className="tag-container mrgn-bttm-md">
        <div className="tag-group">
          {checkboxFilters.map((filter) => {
            const onRemove = () => {
              props.basicSearch.dispatch({
                type: "toggle_checkbox_filter",
                filter: filter,
              });
            };

            return (
              <Tag
                label={filter.label}
                key={filter.value}
                value={filter.value}
                onRemove={onRemove}
              />
            );
          })}

          {numberRangeFilters.map((filter) => {
            const numberRange = filter.value;

            const geString =
              numberRange.ge != null && numberRange.ge >= 0
                ? ` from ${numberRange.ge.toString()}`
                : "";
            const leString =
              numberRange.le != null && numberRange.le >= 0
                ? ` to ${numberRange.le.toString()}`
                : "";
            const label = `${
              filter.label ?? filter.facet
            }${geString}${leString}`;

            const onRemove = () => {
              props.basicSearch.dispatch({
                type: "delete_number_range_filter",
                filter: filter,
              });
            };

            return (
              <Tag
                label={label}
                key={filter.facet}
                value={filter.facet}
                onRemove={onRemove}
              />
            );
          })}

          {dateRangeFilters.map((filter) => {
            const dateRange = filter.value;
            const geString = dateRange.ge
              ? ` from ${formatTimestamp(dateRange.ge.toString())}`
              : "";
            const leString = dateRange.le
              ? ` to ${formatTimestamp(dateRange.le.toString())}`
              : "";
            const label = `${
              filter.label ?? filter.facet
            }${geString}${leString}`;

            const onRemove = () => {
              props.basicSearch.dispatch({
                type: "delete_date_range_filter",
                filter: filter,
              });
            };

            return (
              <Tag
                label={label}
                key={filter.facet}
                value={filter.facet}
                onRemove={onRemove}
              />
            );
          })}
        </div>

        <div className="tag-reset">
          <button
            type="button"
            className="btn btn-sm"
            onClick={props.onReset}
            data-testid="button-tag-reset"
          >
            <span className="fa fa-times"></span>
            <span className="mrgn-lft-sm text-overflow-ellipsis">
              {t("reset_all")}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default FilterTags;
