import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import { GlobalAlert } from "features/globalAlert";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AdminPathHelpers } from "./AdminRouter";

const AdminIndexPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("administration")}</h1>

          <div className="row">
            <div className="col-md-6">
              {/* ------------- SAR-DMTS properties ------------- */}
              <SectionCard
                header={
                  <div className="flex justify-between align-start">
                    <h2>{t("sar_dmts_properties")}</h2>
                  </div>
                }
                showLine={false}
                className="pb-0"
              >
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Genus}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">{t("manage_genus")}</span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Species}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">{t("manage_species")}</span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Subspecies}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">
                      {t("manage_subspecies")}
                    </span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Population}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">
                      {t("manage_population")}
                    </span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Variety}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">{t("manage_variety")}</span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Range}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">{t("manage_range")}</span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.TaxonomicGroup}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">
                      {t("manage_taxonomic_group")}
                    </span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Lands}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">{t("manage_lands")}</span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
              </SectionCard>
            </div>

            <div className="col-md-6">
              {/* ------------- Document management ------------- */}
              <SectionCard
                header={
                  <div className="flex justify-between">
                    <h2>{t("document_management")}</h2>
                  </div>
                }
                showLine={false}
                className="pb-0"
              >
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.ResponseStatement}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">
                      {t("manage_response_statement_fields")}
                    </span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
              </SectionCard>

              {/* ------------- Contact management ------------- */}

              <SectionCard
                header={
                  <div className="flex justify-between">
                    <h2>{t("contact_management")}</h2>
                  </div>
                }
                showLine={false}
                className="pb-0"
              >
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Contacts}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">{t("manage_contacts")}</span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
                <div className="list-item separator-line px-3 py-2_5">
                  <Link
                    to={AdminPathHelpers.Organizations}
                    className="flex justify-between align-center text-decoration-none"
                  >
                    <span className="font-size-18">
                      {t("manage_organizations")}
                    </span>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </div>
              </SectionCard>

              {/* ------------- Authorization management ------------- */}
              <RenderWhenFeatureIsEnabled
                flagKey={FeatureFlagKeys.Authorization}
              >
                <SectionCard
                  header={
                    <div className="flex justify-between">
                      <h2>{t("authorization_management")}</h2>
                    </div>
                  }
                  showLine={false}
                  className="pb-0"
                >
                  <div className="list-item separator-line px-3 py-2_5">
                    <Link
                      to={AdminPathHelpers.Users}
                      className="flex justify-between align-center text-decoration-none"
                    >
                      <span className="font-size-18">{t("manage_users")}</span>
                      <i className="fas fa-angle-right"></i>
                    </Link>
                  </div>
                </SectionCard>
              </RenderWhenFeatureIsEnabled>

              {/* ------------- Business process tracking management ------------- */}
              <RenderWhenFeatureIsEnabled
                flagKey={FeatureFlagKeys.BusinessProcessTracking}
              >
                <SectionCard
                  header={
                    <div className="flex justify-between">
                      <h2>{t("bpt_management")}</h2>
                    </div>
                  }
                  showLine={false}
                  className="pb-0"
                >
                  <div className="list-item separator-line px-3 py-2_5">
                    <Link
                      to={AdminPathHelpers.BptTemplates}
                      className="flex justify-between align-center text-decoration-none"
                    >
                      <span className="font-size-18">
                        {t("manage_bpt_templates")}
                      </span>
                      <i className="fas fa-angle-right"></i>
                    </Link>
                  </div>
                </SectionCard>
              </RenderWhenFeatureIsEnabled>
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AdminIndexPage;
