import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useCheckbox from "../../../hooks/util/useCheckbox";
import Alert, { AlertTypes } from "../../atoms/Alert";
import ConfirmationModal, { useConfirmationModal } from "../ConfirmationModal";

export const BackToDraftButtonAndModal: React.FC<{
  canBackToDraft: boolean;
  onBackToDraft: () => Promise<void>;
  warningMessage?: string;
  buttonText?: string;
  ConfirmationMessage?: string;
  warningTitle?: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmBackToDraftModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const onBackToDraftWithSpinner = () => {
    setShowButtonSpinner(true);

    props.onBackToDraft().finally(() => {
      setShowButtonSpinner(false);
    });
  };

  return (
    <>
      <button
        className="btn btn-primary btn-sm mrgn-rght-sm"
        disabled={!props.canBackToDraft}
        onClick={confirmBackToDraftModal.open}
        data-testid="button-back-to-draft"
      >
        {showButtonSpinner ? (
          <>
            <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
            <span className="wb-inv">&nbsp;{t("loading")}...</span>
          </>
        ) : null}
        {props.buttonText ? t(props.buttonText) : t("back_to_draft")}
      </button>

      <ConfirmationModal
        modalState={confirmBackToDraftModal}
        title={t("back_to_draft_modal_title")}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={onBackToDraftWithSpinner}
        onCancel={() => {
          confirmBackToDraftModal.close();
          checkboxState.reset();
        }}
      >
        <Alert
          type={AlertTypes.WARNING}
          title={props.warningTitle ?? t("back_to_draft_modal_warning_title")}
          content={
            props.warningMessage ?? t("back_to_draft_modal_warning_message")
          }
        />

        <label>
          <input
            type="checkbox"
            checked={checkboxState.checked}
            onChange={checkboxState.toggle}
            data-testid="modal-checkbox-confirm-back-to-draft"
          />{" "}
          {props.ConfirmationMessage
            ? t(props.ConfirmationMessage)
            : t("i_confirm")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default BackToDraftButtonAndModal;
