import SectionCard from "components/atoms/SectionCard";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { TextInput } from "components/atoms/forms/TextInput";
import BilingualToggleContainer from "components/molecules/BilingualToggleContainer";
import { DatePickerWithController } from "components/molecules/DatePicker";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { MAX_LENGTH_OF_NOTE, MAX_LENGTH_OF_RATIONALE } from "config/constants";
import {
  BilingualNote,
  ListingProcessConsultationProcess,
  ListingProcessDocument,
  ListingProcessDocumentType,
  ListingProcessRegulatoryProcess,
  ListingProcessTypeGet,
  ListingWsProcess,
  Maybe,
  UpdateListingWsProcessInput,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import isNullOrEmpty from "util/isNullOrEmpty";
import { htmlToPlainText } from "util/richtext";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import { AmendmentAlignmentDropdownWithController } from "../AmendmentAlignedToAssessmentDropdown/AmendmentAlignmentDropdown";
import { ConsultationPathDropdownWithController } from "../ConsultationPathDropdown/ConsultationPathDropdown";
import { CosewicToReassessDropdownWithController } from "../CosewicToReassessDropdown/CosewicToReassessDropdown";
import DateOfAdditionToSchedule1Selector from "../DateOfAdditionToSchedule1Selector/DateOfAdditionToSchedule1Selector";
import { GICDecisionDropdownWithController } from "../GICDecisionLookupDropdown/GICDecisionDropdown";
import { ListingProcessDocumentFormWithController } from "../ListingProcessDocumentForm/ListingProcessDocumentForm";
import { processListingProcessFormData } from "./mappers";

export interface ListingProcessFormProps {
  listingWsProcess?: Maybe<Partial<ListingWsProcess>>;
  initialValues: ListingProcessFormFields;
  onSubmit: SubmitHandler<Partial<UpdateListingWsProcessInput>>;
  isStandaloneListing?: boolean; // We only want to show the DateOfAdditionToSchedule1Selector on a standalone listing.
}

export const ListingProcessForm: React.FC<ListingProcessFormProps> = (
  props
) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const form = useForm<ListingProcessFormFields>({
    defaultValues: initialValues,
  });

  const { handleSubmit, register, formState, control, getValues } = form;
  const { dirtyFields, isDirty, errors } = formState;

  const onSubmit: SubmitHandler<Partial<ListingProcessFormFields>> = async (
    formData
  ) => {
    const values = await processListingProcessFormData(
      formData,
      dirtyFields,
      initialValues
    );

    props.onSubmit(values);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingProcesses(listingWsId ?? "error"),
    });
  };
  /*
   * Rules for:
   * Consultation path field
   * GIC decision date field
   * GIC decision field
   * DateOfAdditionToSchedule1Selector field
   */

  // Scenario 1 in story 8611 - https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/8611
  // COSEWIC status confirmed -> last two assessment statuses are equal
  // GIC Decision date should be immutable and show "not applicable"
  //
  // NOTE: UPDATED Sept 23 2022 - This functionality is being deprecated. User story 8611 has been updated.
  // We are allowing GIC decision/date to be edited any time (and adding "not applicable" as a user-selectable value),
  // In order to allow for this behaviour without significant changes:
  // https://dev.azure.com/ECCC-DevOps/SAR DMTS/_queries/edit/34367/
  // const gicDecisionDateIsNotApplicable =
  //   props?.listingWsProcess?.additionalProperties?.find(
  //     (x) =>
  //       x?.key === "CosewicStatusAfterReassessmentBR" &&
  //       x?.value === "Confirmed"
  //   ) != null;

  // When these fields are populated:
  //   - GIC Decision Date
  //   - GIC Decision
  //   - Date of addition to Schedule 1 // TODO: PENDING: this has to wait until we are persisting the selected date of addition to schedule 1 in the database. See tech debt item: https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/38352
  // We should disable these fields:
  //   - Consultation Path
  //   - GIC Decision Date
  //   - GIC Decision
  //   - Date of addition to Schedule 1
  const shouldDisableGicAndRelatedFields =
    initialValues?.regulatoryProcess?.gicDecision?.id?.length !== 0 &&
    initialValues?.regulatoryProcess?.gicDecisionDate?.length !== 0 &&
    initialValues?.dateOfAdditionOnSchedule1ParentId?.length !== 0;

  const assessmentDate = props.listingWsProcess?.assessmentRef?.assessmentDate;

  // Bug #38236: Disable certain regulatory process and document fields when the consultation path is confirmed because of a cosewic reassessment and
  // the GIC decision is "Species Listed".
  const shouldDisableRegulatoryProcessAndDocuments =
    getValues("consultationProcess.consultationPath")?.name?.english ===
      "Confirmed" &&
    assessmentDate != null &&
    getValues("regulatoryProcess.gicDecision")?.name?.english ===
      "Species listed - No GIC decision anticipated";

  const validateAtLeastOneProcessNote = () =>
    htmlToPlainText(getValues("processNote.english.text") ?? "") !== "" ||
    htmlToPlainText(getValues("processNote.french.text") ?? "") !== "";

  const validateNoteHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_NOTE;

  const validateRationaleHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_RATIONALE;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isNullOrEmpty(initialValues.ministerOpinionDate) ||
        props.isStandaloneListing ? null : (
          <SectionCard header={<h2>{t("listing_type")}</h2>}>
            <div className="form-group">
              <DatePickerWithController
                name="ministerOpinionDate"
                control={control}
                id="ministerOpinionDate"
                label={t("threat_date")}
                required
              />
              {errors.ministerOpinionDate && (
                <FieldValidationError>
                  {errors.ministerOpinionDate.message}
                </FieldValidationError>
              )}
            </div>
          </SectionCard>
        )}

        {/* ------------- Consultation Process ------------- */}
        <SectionCard
          collapsible
          open
          header={<h2>{t("consultation_process")}</h2>}
        >
          <div className="flex-col gap-md">
            {/* ------------- Response statement published date ------------- */}
            <div>
              <DatePickerWithController
                name="consultationProcess.responseStatementPostedDate"
                control={control}
                id="consultationProcess.responseStatementPostedDate"
                label={t("response_statement_posted_date")}
                disabled={true}
              />

              {errors.consultationProcess?.responseStatementPostedDate && (
                <FieldValidationError>
                  {JSON.stringify(
                    errors?.consultationProcess?.responseStatementPostedDate
                  )}
                </FieldValidationError>
              )}
            </div>

            {/* ------------- Consultation path ------------- */}
            <div>
              <label
                htmlFor="consultationProcess.consultationPath"
                className="required"
              >
                {t("consultation_path")}
                <strong className="required"> ({t("required")})</strong>
              </label>

              <ConsultationPathDropdownWithController
                control={control}
                id="consultationProcess.consultationPath"
                name="consultationProcess.consultationPath"
                placeholder={t("please_select_option")}
                disabled={
                  shouldDisableGicAndRelatedFields ||
                  shouldDisableRegulatoryProcessAndDocuments
                }
                rules={{
                  required: true,
                  onChange: () => form.trigger("consultationProcess"),
                  validate: () =>
                    isNullOrEmpty(
                      getValues("regulatoryProcess.gicDecision")?.id
                    ) ||
                    !isNullOrEmpty(
                      getValues("consultationProcess.consultationPath")?.id
                    ) ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english === "Not applicable" ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english === "Pending" ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english === "No GIC decision" ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english ===
                      "Species listed - No GIC decision anticipated",
                }}
              />
              {errors.consultationProcess?.consultationPath && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            </div>

            {/* ------------- Listing batch ------------- */}
            <div>
              <label htmlFor="consultationProcess.listingBatch">
                {t("listing_batch")} {"("}
                {t("max_of_number_digits", { number: 2 })} {")"}
              </label>
              <input
                className="form-control full-width"
                type="number"
                data-rule-digits="true"
                min={0}
                max={99}
                title={t("max_of_number_digits", { number: 2 })}
                placeholder={t("listing_batch")}
                id="consultationProcess.listingBatch"
                data-testid="tbx-listing-batch"
                {...register("consultationProcess.listingBatch")}
              />
              {errors.consultationProcess?.listingBatch && (
                <FieldValidationError>
                  {JSON.stringify(errors.consultationProcess?.listingBatch)}
                </FieldValidationError>
              )}
            </div>

            {/* ------------- Amendment aligned to assessment ------------- */}
            <div>
              <label htmlFor="consultationProcess.amendmentAlignedToAssessment">
                {t("amendment_aligned_to_assessment")}
              </label>
              <AmendmentAlignmentDropdownWithController
                name="consultationProcess.amendmentAlignedToAssessment"
                id="consultationProcess.amendmentAlignedToAssessment"
                control={control}
              />

              {errors.consultationProcess?.amendmentAlignedToAssessment && (
                <FieldValidationError>
                  {JSON.stringify(
                    errors.consultationProcess?.amendmentAlignedToAssessment
                  )}
                </FieldValidationError>
              )}
            </div>
          </div>
        </SectionCard>

        {/* ------------- Regulatory Process ------------- */}
        <SectionCard
          collapsible
          open
          header={<h2>{t("regulatory_process")}</h2>}
        >
          <div className="flex-col gap-md">
            {/* ------------- Regulatory bundle ------------- */}
            <TextInput
              inputClassName="form-control full-width"
              type="text"
              label={t("regulatory_bundle")}
              placeholder={t("regulatory_bundle")}
              id="regulatoryProcess.regulatoryBundle"
              data-testid="tbx-regulatory-bundle"
              {...register("regulatoryProcess.regulatoryBundle")}
              disabled={shouldDisableRegulatoryProcessAndDocuments}
              errors={errors}
            />

            {/* ------------- Rationale ------------- */}
            <div>
              <fieldset className="subheader-grp">
                <legend className="border-bottom-none">
                  <span className="field-name">{t("rationale")}</span>{" "}
                </legend>

                <BilingualToggleContainer
                  className="form-group border-light"
                  data-testid="regulatory-process-rationale-rte"
                  english={
                    <div className="form-group">
                      <label
                        className="font-size-14"
                        htmlFor="regulatoryProcess.rationale.english.text"
                      >
                        {t("english_text")}
                      </label>
                      <FullHTMLEditorWithController
                        control={control}
                        rules={{
                          validate: {
                            maxLengthLimit:
                              validateRationaleHtmlTextMaxLengthLimit,
                          },
                        }}
                        id="regulatoryProcess.rationale.english.text"
                        name="regulatoryProcess.rationale.english.text"
                        defaultValue={
                          initialValues.regulatoryProcess?.rationale?.english
                            ?.text ?? ""
                        }
                      />
                      {(errors.regulatoryProcess?.rationale as any)?.english
                        ?.text &&
                        (errors.regulatoryProcess?.rationale as any)?.english
                          .text?.type === "maxLengthLimit" && (
                          <FieldValidationError>
                            {t("reach_the_max_length", {
                              count: MAX_LENGTH_OF_RATIONALE,
                            })}
                          </FieldValidationError>
                        )}
                    </div>
                  }
                  french={
                    <div className="form-group">
                      <label
                        className="font-size-14"
                        htmlFor="regulatoryProcess.rationale.french.text"
                      >
                        {t("french_text")}
                      </label>
                      <FullHTMLEditorWithController
                        control={control}
                        rules={{
                          validate: {
                            maxLengthLimit:
                              validateRationaleHtmlTextMaxLengthLimit,
                          },
                        }}
                        defaultValue={
                          initialValues.regulatoryProcess?.rationale?.french
                            ?.text ?? ""
                        }
                        id="regulatoryProcess.rationale.french.text"
                        name="regulatoryProcess.rationale.french.text"
                      />
                      {(errors.regulatoryProcess?.rationale as any)?.french
                        ?.text &&
                        (errors.regulatoryProcess?.rationale as any)?.french
                          ?.text?.type === "maxLengthLimit" && (
                          <FieldValidationError>
                            {t("reach_the_max_length", {
                              count: MAX_LENGTH_OF_RATIONALE,
                            })}
                          </FieldValidationError>
                        )}
                    </div>
                  }
                />
              </fieldset>
            </div>

            {/* ------------- GIC receipt date ------------- */}
            <div>
              <DatePickerWithController
                name="regulatoryProcess.gicReceiptDate"
                control={control}
                id="regulatoryProcess.gicReceiptDate"
                label={t("gic_receipt_date")}
                disabled={shouldDisableRegulatoryProcessAndDocuments}
              />

              {errors.regulatoryProcess?.gicReceiptDate && (
                <FieldValidationError>
                  {JSON.stringify(errors.regulatoryProcess?.gicReceiptDate)}
                </FieldValidationError>
              )}
            </div>

            {/* ------------- GIC decision date ------------- */}
            <div>
              <DatePickerWithController
                name="regulatoryProcess.gicDecisionDate"
                control={control}
                id="regulatoryProcess.gicDecisionDate"
                label={t("gic_decision_date")}
                disabled={
                  shouldDisableGicAndRelatedFields ||
                  getValues("regulatoryProcess.gicDecision")?.name?.english ===
                    "Not applicable" ||
                  getValues("regulatoryProcess.gicDecision")?.name?.english ===
                    "Pending" ||
                  getValues("regulatoryProcess.gicDecision")?.name?.english ===
                    "No GIC decision" ||
                  shouldDisableRegulatoryProcessAndDocuments
                }
                rules={{
                  onChange: () => form.trigger("regulatoryProcess"),
                  validate: () =>
                    !isNullOrEmpty(
                      getValues("regulatoryProcess.gicDecisionDate")
                    ) ||
                    isNullOrEmpty(
                      getValues("regulatoryProcess.gicDecision")?.id
                    ) ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english === "Not applicable" ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english === "Pending" ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english === "No GIC decision" ||
                    getValues("regulatoryProcess.gicDecision")?.name
                      ?.english ===
                      "Species listed - No GIC decision anticipated",
                }}
              />

              {errors.regulatoryProcess?.gicDecisionDate && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            </div>

            {/* This condition is part of the user story, but during the pre-demo meeting Dec 7 2022,
             * We determined this cannot work for our demo using the White Sturgeon,
             * Since it has parents which are on schedule 1.
             * We will attempt to review this logic again with the product owners Dec 8 2022.
             */}

            {/*{props.isStandaloneListing ? (*/}
            <DateOfAdditionToSchedule1Selector
              childId={listingWsId}
              disabled={
                shouldDisableGicAndRelatedFields ||
                shouldDisableRegulatoryProcessAndDocuments
              }
            />
            {/*) : null}*/}

            {/* ------------- GIC decision ------------- */}
            <div>
              <label htmlFor="regulatoryProcess.gicDecision">
                {t("gic_decision")}
              </label>

              <GICDecisionDropdownWithController
                name="regulatoryProcess.gicDecision"
                id="regulatoryProcess.gicDecision"
                control={control}
                disabled={
                  shouldDisableGicAndRelatedFields ||
                  shouldDisableRegulatoryProcessAndDocuments
                }
                rules={{
                  onChange: () => form.trigger("regulatoryProcess"),
                  validate: () =>
                    !isNullOrEmpty(
                      getValues("regulatoryProcess.gicDecision")?.id
                    ) ||
                    isNullOrEmpty(
                      getValues("regulatoryProcess.gicDecisionDate")
                    ),
                }}
              />

              {errors?.regulatoryProcess?.gicDecision && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            </div>

            {/* ------------- CG I - Listing proposal ------------- */}
            <div>
              <label htmlFor="regulatoryProcess.cgiListingProposal">
                {t("cgi_listing_proposal")}
              </label>

              <GICDecisionDropdownWithController
                name="regulatoryProcess.cgiListingProposal"
                id="regulatoryProcess.cgiListingProposal"
                control={control}
                disabled={shouldDisableRegulatoryProcessAndDocuments}
              />

              {errors?.regulatoryProcess?.cgiListingProposal && (
                <FieldValidationError>
                  {JSON.stringify(
                    errors?.regulatoryProcess?.cgiListingProposal
                  )}
                </FieldValidationError>
              )}
            </div>

            {/* ------------- COSEWIC to reassess ------------- */}
            <div className="flex-col">
              <label htmlFor={"cosewicToReassessInput"}>
                {t("cosewic_to_reassess")}
              </label>
              <CosewicToReassessDropdownWithController
                control={control}
                name="regulatoryProcess.cosewicToReassess"
                id="cosewicToReassessInput"
                defaultValue={
                  initialValues?.regulatoryProcess?.cosewicToReassess
                }
              />

              {errors.regulatoryProcess?.cosewicToReassess && (
                <FieldValidationError>
                  {JSON.stringify(errors.regulatoryProcess?.cosewicToReassess)}
                </FieldValidationError>
              )}
            </div>
          </div>
        </SectionCard>

        {/* ------------- Documents ------------- */}
        <SectionCard collapsible header={<h2>{t("documents")}</h2>}>
          <div className="flex-col gap-lg">
            {/* ------------- Consultation end date ------------- */}
            {/* ------------- Consultation document ------------- */}
            <ListingProcessDocumentFormWithController
              control={control}
              name="documents_consultationEndDate"
              datePickerLabel={t("consultation_end_publication_date")}
              uriLabel={t("consultation_end_date_document")}
              type={ListingProcessDocumentType.ConsultationEndDate}
              disabled={shouldDisableRegulatoryProcessAndDocuments}
            />

            {/* ------------- CG II - GiC receipt publication date ------------- */}
            {/* ------------- CG II - GiC Receipt document ------------- */}
            <ListingProcessDocumentFormWithController
              control={control}
              name="documents_cgIiGicReceipt"
              datePickerLabel={t("cgii_gic_receipt_publication_date")}
              uriLabel={t("cgii_gic_receipt_document")}
              type={ListingProcessDocumentType.CgIiGicReceipt}
              disabled={shouldDisableRegulatoryProcessAndDocuments}
            />

            {/* ------------- CG I - Proposal publication date ------------- */}
            {/* ------------- CG I - Proposal date document ------------- */}
            <ListingProcessDocumentFormWithController
              control={control}
              name="documents_cgIProposalDate"
              datePickerLabel={t("cgi_proposal_publication_date")}
              uriLabel={t("cgi_proposal_date_document")}
              type={ListingProcessDocumentType.CgIProposalDate}
              disabled={shouldDisableRegulatoryProcessAndDocuments}
            />

            {/* ------------- CG II - Listing order publication date ------------- */}
            {/* ------------- CG II - Listing Order document ------------- */}
            <ListingProcessDocumentFormWithController
              control={control}
              name="documents_cgIiListingOrder"
              datePickerLabel={t("cgii_listing_order_publication_date")}
              uriLabel={t("cgii_listing_order_document")}
              type={ListingProcessDocumentType.CgIiListingOrder}
              disabled={shouldDisableRegulatoryProcessAndDocuments}
            />

            {/* ------------- CG II - Not list / Refer back publication date ------------- */}
            {/* ------------- CG II - Not list / Refer back document ------------- */}
            <ListingProcessDocumentFormWithController
              control={control}
              name="documents_cgIiNotListReferBack"
              datePickerLabel={t("cgii_not_list_refer_back_publication_date")}
              uriLabel={t("cgii_not_list_refer_back_document")}
              type={ListingProcessDocumentType.CgIiNotListReferBack}
            />
          </div>
        </SectionCard>

        {/* ------------- Process note ------------- */}
        <SectionCard header={<h2>{t("process_note")}</h2>}>
          <div>
            <fieldset className="subheader-grp">
              {props.listingWsProcess?.listingProcessType !==
              ListingProcessTypeGet.Regular ? (
                <legend className="required border-bottom-none">
                  <span className="field-name">{t("process_note")}</span>{" "}
                </legend>
              ) : (
                <legend className="border-bottom-none">
                  <span className="field-name">{t("process_note")}</span>{" "}
                </legend>
              )}
              <BilingualToggleContainer
                className="form-group border-light"
                data-testid="process-note-rte"
                english={
                  <div className="form-group">
                    <label
                      className="font-size-14"
                      htmlFor="processNote.english.text"
                    >
                      {t("english_text")}
                    </label>
                    <FullHTMLEditorWithController
                      control={control}
                      rules={{
                        validate: {
                          maxLengthLimit: validateNoteHtmlTextMaxLengthLimit,
                        },
                      }}
                      id="processNote.english.text"
                      name="processNote.english.text"
                      defaultValue={
                        initialValues.processNote?.english?.text ?? ""
                      }
                    />
                    {errors.processNote?.english &&
                      (errors.processNote.english as any)?.text?.type ===
                        "maxLengthLimit" && (
                        <FieldValidationError>
                          {t("reach_the_max_length", {
                            count: MAX_LENGTH_OF_NOTE,
                          })}
                        </FieldValidationError>
                      )}
                  </div>
                }
                french={
                  <div className="form-group">
                    <label
                      className="font-size-14"
                      htmlFor="processNote.french.text"
                    >
                      {t("french_text")}
                    </label>
                    <FullHTMLEditorWithController
                      control={control}
                      rules={{
                        validate: {
                          maxLengthLimit: validateNoteHtmlTextMaxLengthLimit,
                        },
                      }}
                      defaultValue={
                        initialValues.processNote?.french?.text ?? ""
                      }
                      id="processNote.french.text"
                      name="processNote.french.text"
                    />
                    {errors.processNote?.french &&
                      (errors.processNote.french as any)?.text?.type ===
                        "maxLengthLimit" && (
                        <FieldValidationError>
                          {t("reach_the_max_length", {
                            count: MAX_LENGTH_OF_NOTE,
                          })}
                        </FieldValidationError>
                      )}
                  </div>
                }
              />
            </fieldset>
          </div>
        </SectionCard>

        <FormButtons isDirty={isDirty} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};

export default ListingProcessForm;

export interface ListingProcessFormFields {
  ministerOpinionDate?: string;
  processNote?: BilingualNote;
  consultationProcess?: ListingProcessConsultationProcess;
  regulatoryProcess: ListingProcessRegulatoryProcess;
  documents_consultationEndDate: ListingProcessDocument;
  documents_cgIProposalDate: ListingProcessDocument;
  documents_cgIiGicReceipt: ListingProcessDocument;
  documents_cgIiListingOrder: ListingProcessDocument;
  documents_cgIiNotListReferBack: ListingProcessDocument;
  dateOfAdditionOnSchedule1ParentId?: string;
}

/* new i18n keys
process_information
response_statement_posted_date
listing_process_for_date = Listing process for {{date}}
gic_decision
cgi_listing_proposal
amendment_aligned_to_assessment
regulatory_process
regulatory_bundle
gic_receipt_date
gic_decision_date
documents
consultation_end_publication_date
consultation_end_date_document
cgii_gic_receipt_publication_date
cgii_gic_receipt_document
cgi_proposal_publication_date
cgi_proposal_date_document
cgii_listing_order_publication_date
cgii_listing_order_document
cgii_not_list_refer_back_publication_date
cgii_not_list_refer_back_document
*/
