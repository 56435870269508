import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface NoResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
  message?: string;
}

export const NoResults: React.FC<NoResultsProps> = ({
  centered,
  message,
  ...props
}) => {
  const { t } = useTranslation();

  const className = React.useMemo(
    () =>
      cc([
        { "width-100 flex justify-center": centered },
        "text-muted font-style-italic",
        props.className,
      ]),
    [centered, props.className]
  );

  return (
    <div {...props} className={className}>
      {message ?? t("no_results")}
    </div>
  );
};

export default NoResults;
