const listingResponseStatementTranslation = {
  en: {
    response_statement_reports: "Response statement reports",
    browse_response_statements: "Browse response statements",
    search_listing_wildlife_species: "Search Listing wildlife species",
    response_statement: "Response statement",
    response_statements: "Response statements",
    enter_a_minister_receipt_date: "Enter a Minister receipt date",
    active_wildlife_species: "Active wildlife species",
    response_statement_information: "Response statement information",
    back_to_draft: "Back to draft",
    back_to_draft_modal_title: "Back to a draft response statement",
    back_to_draft_modal_warning_title:
      "Are you sure you want to go back to Draft?",
    back_to_draft_modal_warning_message:
      "The response statement information will be editable.",
    approve: "Approve",
    response_statement_successfully_approved:
      "Response statement stage successfully set as Approved.",
    response_statement_can_be_published:
      "The response statement can be be published.",
    approve_modal_title: "Approve response statement",
    approve_modal_warning_title:
      "Are you sure you want to approve the response statement?",
    approve_modal_warning_message:
      "You will not be able to go back to Draft once made Final",
    finalize_modal_title: "Finalize response statement",
    finalize_response_statement_warning:
      "Are you sure you want to finalize the response statement?",
    finalize_response_statement_confirmation:
      "The response statement information will generate PDF and HTML files for approval",
    provinces_and_territories_consulted:
      "Provinces and territories that have been consulted",
    related_documents: "Related Documents",

    // Tooltips
    required_to_make_rs_final_tooltip:
      "Required to make response statement Final.",
    gender_required_to_make_rs_final_tooltip:
      "Listing gender under Listing information has not been defined. This is required to finalize a response statement.",
    consultation_path_required_to_make_rs_final_tooltip:
      "Listing Consultation Path under Listing information has not been defined. This is required to finalize a response statement.",
    publish_modal_title: "Publish response statement",
    publish_modal_warning_title:
      "Are you sure you want to publish to Registry?",
    publish_modal_warning_message:
      "All of the current data will be visible on the Registry.",
    response_statement_successfully_published:
      "Response statement state successfully published.",
    response_statement_updated_on_registry:
      "The response statement is now updated on Registry.",
    unpublish_modal_title: "Unpublish response statement",
    unpublish_modal_warning_title:
      "Are you sure you want to unpublish from Registry?",
    unpublish_modal_warning_message:
      "All of the current data will still be visible on the Registry.",
    response_statement_successfully_unpublished:
      "Response statement stage has been successfully unpublished.",
    response_statement_unpublished_from_registry:
      "The response statement can now go back to draft to update the information.",
  },

  fr: {
    response_statement_reports: "Rapports des Énoncés de réaction",
    browse_response_statements: "Parcourir les Énoncés de réaction",
    search_listing_wildlife_species:
      "Recherche d'inscription des espèces sauvages",
    response_statement: "Énoncé de réaction",
    response_statements: "Les Énoncés de réaction",
    enter_a_minister_receipt_date:
      "Entrer une date de réception par le ministre",
    active_wildlife_species: "Espèces sauvages actives",
    response_statement_information: "Informations sur l'énoncé de la réponse",
    back_to_draft: "Retour à l'ébauche",
    back_to_draft_modal_title: "Retour à l'ébauche de l'énoncé de réaction",
    back_to_draft_modal_warning_title:
      "Êtes-vous sûr de vouloir retourner à la réaction ?",
    back_to_draft_modal_warning_message:
      "Les informations relatives à l'énoncé de réaction seront modifiables",
    approve: "Approuver",
    response_statement_successfully_approved:
      "L'énoncé de réaction est à l'étape approuvée",
    response_statement_can_be_published:
      "L'énoncé de réaction peut maintenant être publié.",
    approve_modal_title: "Approuver l'énoncé de réaction",
    approve_modal_warning_title:
      "Êtes-vous certain de vouloir approuver l'énoncé de réaction?",
    approve_modal_warning_message:
      "Vous ne pourrez pas revenir à la version Ébauche une fois rendu Finale",
    finalize_modal_title: "Finaliser l'énoncé de réaction",
    finalize_response_statement_warning:
      "Êtes-vous sûr de vouloir finaliser l'énoncé de réaction?",
    finalize_response_statement_confirmation:
      "L'énoncé de réaction est disponible en fichiers PDF et HTML pour approbation",
    provinces_and_territories_consulted: "Provinces et territoires consultés",
    related_documents: "Documents liés",

    // Tooltips
    required_to_make_rs_final_tooltip:
      "Requis pour finaliser une énoncé de déclaration.",
    gender_required_to_make_rs_final_tooltip:
      "Le genre sous l'information de l'Inscription n’a pas été défini. Ceci est nécessaire pour finaliser une énoncé de déclaration.",
    consultation_path_required_to_make_rs_final_tooltip:
      "Listing Consultation Path under Listing information has not been defined. This is required to finalize a response statement - FR.",
    publish_modal_title: "Publier l'énoncé de déclaration.",
    publish_modal_warning_title:
      "Êtes-vous certain de vouloir publier sur le Registre ?",
    publish_modal_warning_message:
      "Toutes les données actuelles seront visibles sur le Registre.",
    response_statement_successfully_published:
      "L'état de l'énoncé de déclaration est maintenant publier.",
    response_statement_updated_on_registry:
      "L'énoncé de déclaration est maintenant mis à jour sur le Registre.",
    unpublish_modal_title: "Annuler la publication de l'énoncé de déclaration.",
    unpublish_modal_warning_title:
      "Êtes-vous certain de vouloir retiré la publication du Registre ?",
    unpublish_modal_warning_message:
      "Toutes les données actuelles seront encore visibles sur le registre.",
    response_statement_successfully_unpublished:
      "Le stade de l'énoncé de déclaration a été retiré de la publication avec succès.",
    response_statement_unpublished_from_registry:
      "L'énoncé de déclaration peut maintenant revenir en ébauche pour mettre à jour l'informations.",
  },
};

export default listingResponseStatementTranslation;
