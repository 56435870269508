import { useTranslation } from "react-i18next";
import type {
  Field,
  FieldSelectorProps,
  OptionGroup,
} from "react-querybuilder";

export const CustomFieldSelector = (props: FieldSelectorProps) => {
  const { t, i18n } = useTranslation();

  const fieldOptions: any[] = props.options;
  const optGroups = [
    {
      label: t("cosewic"),
      options: fieldOptions.filter((x: any) => x.program === "cosewic"),
    },
    {
      label: t("listing"),
      options: fieldOptions.filter((x: any) => x.program === "listing"),
    },
  ] as OptionGroup<Field>[];

  const grp =
    optGroups.find((og) =>
      og.options.find((opt) => opt.name === props.value)
    ) ?? optGroups[0];

  return (
    <>
      <select
        className={props.className}
        value={grp.label}
        onChange={(e) =>
          props.handleOnChange(
            optGroups.find((og) => og.label === e.target.value)?.options[0].name
          )
        }
        title={props.title?.split(",", 2)[0]}
      >
        {optGroups.map((og) => (
          <option key={og.label} value={og.label}>
            {og.label}
          </option>
        ))}
      </select>
      <select
        className={props.className}
        style={{ maxWidth: "250px" }} // TODO: may need to remove this temporary width style
        value={props.value}
        onChange={(e) => props.handleOnChange(e.target.value)}
        title={props.title?.split(",", 2)[1]}
      >
        {grp.options
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((opt) => (
            <option
              key={opt.name}
              value={opt.name}
              // TODO: Remove disabled attribute once Lands and Sectors are ready from the backend.
              // Bug 42014, Lands and Sectors are not part of the 1.29.4 release and
              // should be grayed out, and not available for selection.
              disabled={
                opt.name.startsWith("Listing/Sectors/") ||
                opt.name.startsWith("Listing/Lands/") ||
                // BUG#42962: This path retrieves the values from all of the previous listing processes.
                // All of the branching paths have versions of their respective fields that are still in the query tool conditions dropdown under Listing/LatestProcess/ path.
                // They will be disabled until the SMEs decide if they would like to have the functionality to query on the values of the previous listing processes.
                opt.name.startsWith("Listing/Processes/")
              }
            >
              {opt.label}
            </option>
          ))}
      </select>
    </>
  );
};
