import { BptTemplate, BptTemplateStepBundle } from "generated/gql-types";
import * as React from "react";
import StepDetails from "./StepDetails";

export interface StepBundleProps {
  stepBundle?: BptTemplateStepBundle;
  index?: number;
  isOpen?: boolean;
  bptTemplate?: BptTemplate;
  templateId: string;
  isLoading?: boolean;
  onDeleteStep: (a: any, b: any) => void;
}

export const StepBundle: React.FC<StepBundleProps> = (props) => {
  const {
    stepBundle,
    isOpen,
    bptTemplate,
    templateId,
    isLoading,
    onDeleteStep,
  } = props;

  const isBundle = stepBundle?.steps != null && stepBundle?.steps?.length >= 2;

  return (
    <div className={isBundle ? "template-list-bundle" : "template-list-single"}>
      {stepBundle?.steps?.map((item, index) => {
        return (
          <StepDetails
            key={index}
            isOpen={isOpen}
            item={item!}
            bptTemplate={bptTemplate!}
            templateId={templateId}
            isLoading={isLoading}
            onDeleteStep={onDeleteStep}
          />
        );
      })}
    </div>
  );
};
