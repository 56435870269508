import RangeCheckboxes from "components/organisms/cosewic/RangeCheckboxes";
import { MAX_LENGTH_OF_NAME } from "config/constants";
import { RenderWhenAuthorized } from "features/auth/components";
import { useOverviewContext } from "features/overview";
import { Gender, ListingWs, ListingWsState } from "generated/gql-types";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { htmlToPlainText } from "util/richtext";
import { ROLE_ACTIONS } from "../../../../features/auth/roles";
import { ChangeTracking } from "../../../../features/changeTracking";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import Alert, { AlertTypes } from "../../../atoms/Alert";
import SectionCard from "../../../atoms/SectionCard";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import { HTMLItalicsInputWithController } from "../../../molecules/HTMLItalicsInput";
import CreateGenusModalWithButton from "../../admin/CreateGenusModal/ButtonWithModal";
import CreatePopulationModalWithButton from "../../admin/CreatePopulationModal/ButtonWithModal";
import CreateSpeciesModalWithButton from "../../admin/CreateSpeciesModal/ButtonWithModal";
import CreateSubspeciesModalWithButton from "../../admin/CreateSubspeciesModal/ButtonWithModal";
import CreateTaxonomicGroupModalWithButton from "../../admin/CreateTaxonomicGroupModal/ButtonWithModal";
import CreateVarietyModalWithButton from "../../admin/CreateVarietyModal/ButtonWithModal";
import GenusAutocomplete from "../../admin/GenusAutocomplete";
import PopulationAutocomplete from "../../admin/PopulationAutocomplete";
import SpeciesAutocomplete from "../../admin/SpeciesAutocomplete";
import SubspeciesAutocomplete from "../../admin/SubspeciesAutocomplete";
import TaxonomicGroupAutocomplete from "../../admin/TaxonomicGroupAutocomplete";
import VarietyAutocomplete from "../../admin/VarietyAutocomplete";
import { ListingFormChangeTracking, ListingFormFields } from "./index";

export interface WildlifeSpeciesInformationSectionProps {
  listingWs?: ListingWs;
  initialValues: ListingFormFields;
  open: boolean;
}

export const WildlifeSpeciesInformationSection: React.FC<
  WildlifeSpeciesInformationSectionProps
> = (props) => {
  const { initialValues, open } = props;
  const { t } = useTranslation();
  const form = useFormContext<ListingFormFields & ListingFormChangeTracking>();
  const { control, register, formState, watch } = form;
  const { errors } = formState;
  const overviewContext = useOverviewContext();
  const cosewicWs = overviewContext.cosewic;

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_NAME;

  const isStandaloneListing = cosewicWs == null;

  return (
    <SectionCard
      collapsible
      open={open}
      header={<h2>{t("wildlife_species_information")}</h2>}
    >
      <div className="flex-col gap-md">
        {/* COMMON NAME ENGLISH */}

        <div>
          <label htmlFor="commonNameEnglishInput" className="required">
            {t("common_name")} {t("english")}{" "}
            <strong className="required">({"required"})</strong>
          </label>
          <HTMLItalicsInputWithController
            control={control}
            rules={{
              required: true,
              validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
            }}
            name="commonNameEnglish.name.text"
            id="commonNameEnglishInput"
            defaultValue={initialValues.commonNameEnglish?.name?.text ?? ""}
            placeholder={`${t("common_name")} ${t("english")}`}
          />

          {errors.commonNameEnglish &&
            (errors.commonNameEnglish?.name as any)?.text?.type ===
              "required" && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}

          {errors.commonNameEnglish &&
            (errors.commonNameEnglish?.name as any)?.text?.type ===
              "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_NAME,
                })}
              </FieldValidationError>
            )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            trackedValue={watch("commonNameEnglish.name.text")}
            changeMetaDataName="commonNameEnglishChangeMetaData"
            cachedValue={initialValues.commonNameEnglish?.name?.text}
          />
        </div>

        {/* COMMON NAME FRENCH */}

        <div>
          {props?.listingWs?.state === ListingWsState.Published && (
            <label htmlFor="commonNameFrenchInput" className="required">
              {t("common_name")} {t("french")}{" "}
              <strong className="required">({"required"})</strong>
            </label>
          )}
          {props?.listingWs?.state !== ListingWsState.Published && (
            <label htmlFor="commonNameFrenchInput">
              {t("common_name")} {t("french")}
            </label>
          )}
          <HTMLItalicsInputWithController
            control={control}
            rules={{
              required: props?.listingWs?.state === ListingWsState.Published,
              validate: {
                maxLengthLimit: validateHtmlTextMaxLengthLimit,
                publishAndNotEmpty: (value) => {
                  if (props?.listingWs?.state === ListingWsState.Published) {
                    return !isNullOrEmpty(value);
                  }
                },
              },
            }}
            name="commonNameFrench.name.text"
            id="commonNameFrenchInput"
            defaultValue={initialValues.commonNameFrench.name?.text ?? ""}
            placeholder={`${t("common_name")} ${t("french")}`}
          />

          {errors.commonNameFrench &&
            (errors.commonNameFrench?.name as any)?.text?.type ===
              "required" && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}

          {errors.commonNameFrench &&
            (errors.commonNameFrench?.name as any)?.text?.type ===
              "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_NAME,
                })}
              </FieldValidationError>
            )}

          {errors.commonNameFrench &&
            (errors.commonNameEnglish?.name as any)?.text?.type ===
              "publishAndNotEmpty" && (
              <FieldValidationError>
                {t("publishAndNotEmpty")}
              </FieldValidationError>
            )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="commonNameFrenchChangeMetaData"
            cachedValue={initialValues.commonNameFrench.name?.text}
            trackedValue={watch("commonNameFrench.name.text")}
          />
        </div>

        {/* NAME WITH COMMA */}

        <div>
          <label htmlFor="nameWithCommaInput" className="required">
            {t("name_with_comma")}{" "}
            <strong className="required">({t("required")})</strong>
          </label>

          <HTMLItalicsInputWithController
            control={control}
            defaultValue={initialValues.nameWithComma?.name?.text ?? ""}
            id="nameWithCommaInput"
            name="nameWithComma.name.text"
            placeholder={`${t("name_with_comma")}`}
            rules={{
              required: true,
              validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
            }}
          />

          {errors.nameWithComma &&
            (errors.nameWithComma?.name as any)?.text?.type === "required" && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}

          {errors.nameWithComma &&
            (errors.nameWithComma?.name as any)?.text?.type ===
              "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_NAME,
                })}
              </FieldValidationError>
            )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="nameWithCommaChangeMetaData"
            cachedValue={initialValues.nameWithComma?.name?.text}
            trackedValue={watch("nameWithComma.name.text")}
          />
        </div>

        {/* POPULATION */}

        <div>
          <div className="flex align-end gap-md">
            <Controller
              control={control}
              name="population"
              render={({ field: { onChange, value } }) => (
                <PopulationAutocomplete
                  label={t("select_a_population")}
                  initialPopulation={value}
                  onSelectionChange={onChange}
                />
              )}
            />

            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.administration.population.create}
            >
              <CreatePopulationModalWithButton />
            </RenderWhenAuthorized>
          </div>

          {errors.population && (
            <FieldValidationError>
              {JSON.stringify(errors.population)}
            </FieldValidationError>
          )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="populationChangeMetaData"
            cachedValue={initialValues.population.id}
            trackedValue={watch("population.id")}
          />
        </div>

        {/* GENUS */}

        <div>
          <div className="flex align-end gap-md">
            <Controller
              control={control}
              name="genus"
              rules={{
                required: true,
                validate: (value) => !isNullOrEmpty(value.name),
              }}
              render={({ field: { onChange, value } }) => (
                <GenusAutocomplete
                  label={t("genus")}
                  isRequired
                  initialGenus={value}
                  onSelectionChange={onChange}
                />
              )}
            />

            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.administration.genus.create}
            >
              <CreateGenusModalWithButton />
            </RenderWhenAuthorized>
          </div>

          {errors.genus && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="genusChangeMetaData"
            cachedValue={initialValues.genus.id}
            trackedValue={watch("genus.id")}
          />
        </div>

        {/* SPECIES */}

        <div>
          <div className="flex align-end gap-md">
            <Controller
              control={control}
              name="species"
              rules={{
                required: props?.listingWs?.state === ListingWsState.Published,
                validate: (value) => {
                  if (props?.listingWs?.state === ListingWsState.Published) {
                    return !isNullOrEmpty(value?.name);
                  }
                },
              }}
              render={({ field: { onChange, value } }) => (
                <SpeciesAutocomplete
                  label={t("species")}
                  isRequired={
                    props?.listingWs?.state === ListingWsState.Published
                  }
                  initialSpecies={value}
                  onSelectionChange={onChange}
                />
              )}
            />

            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.administration.species.create}
            >
              <CreateSpeciesModalWithButton
                refetchQueries={["SpeciesAutocompleteList"]}
              />
            </RenderWhenAuthorized>
          </div>

          {errors.species && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="speciesChangeMetaData"
            cachedValue={initialValues.species.id}
            trackedValue={watch("species.id")}
          />
        </div>

        {/* SUBSPECIES */}

        <div>
          <div className="flex align-end gap-md">
            <Controller
              control={control}
              name="subSpecies"
              render={({ field: { onChange, value } }) => (
                <SubspeciesAutocomplete
                  label={t("subspecies")}
                  initialSubspecies={value}
                  onSelectionChange={onChange}
                />
              )}
            />

            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.administration.subspecies.create}
            >
              <CreateSubspeciesModalWithButton
                refetchQueries={["SubspeciesAutocompleteList"]}
              />
            </RenderWhenAuthorized>
          </div>

          {errors.subSpecies && (
            <FieldValidationError>
              {JSON.stringify(errors.subSpecies)}
            </FieldValidationError>
          )}

          {cosewicWs != null && (
            <div className="mrgn-tp-md">
              <Alert
                className="mrgn-bttm-0"
                type={AlertTypes.INFO}
                content={t("ssp_msg")}
              />
            </div>
          )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="subSpeciesChangeMetaData"
            cachedValue={initialValues.subSpecies.id}
            trackedValue={watch("subSpecies.id")}
          />
        </div>

        {/* VARIETY */}

        <div>
          <div className="flex align-end gap-md">
            <Controller
              control={control}
              name="variety"
              render={({ field: { onChange, value } }) => (
                <VarietyAutocomplete
                  label={t("variety")}
                  initialVariety={value}
                  onSelectionChange={onChange}
                />
              )}
            />

            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.administration.variety.create}
            >
              <CreateVarietyModalWithButton
                refetchQueries={["VarietyAutocompleteList"]}
              />
            </RenderWhenAuthorized>
          </div>

          {errors.variety && (
            <FieldValidationError>
              {JSON.stringify(errors.variety)}
            </FieldValidationError>
          )}

          {cosewicWs != null && (
            <div className="mrgn-tp-md">
              <Alert
                className="mrgn-bttm-0"
                type={AlertTypes.INFO}
                content={t("var_msg")}
              />
            </div>
          )}

          <ChangeTracking
            hideChangeType={true}
            control={control}
            changeMetaDataName="varietyChangeMetaData"
            cachedValue={initialValues.variety.id}
            trackedValue={watch("variety.id")}
          />
        </div>

        {/* TAXONOMIC GROUP */}

        {isStandaloneListing && (
          <div>
            <div className="flex align-end gap-md">
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    if (
                      !value.id ||
                      value.id === "" ||
                      !value.nameEn ||
                      value.nameEn === "" ||
                      !value.nameFr ||
                      value.nameFr === ""
                    ) {
                      return false;
                    }
                    return true;
                  },
                }}
                name="taxonomicGroup"
                render={({ field: { onChange, value } }) => (
                  <TaxonomicGroupAutocomplete
                    label={t("taxonomic_group")}
                    isRequired={true}
                    initialTaxonomicGroup={value}
                    onSelectionChange={onChange}
                  />
                )}
              />
              <RenderWhenAuthorized
                authorizedRoles={
                  ROLE_ACTIONS.administration.taxonomicGroup.create
                }
              >
                <CreateTaxonomicGroupModalWithButton
                  refetchQueries={["TaxonomicGroupSelectList"]}
                />
              </RenderWhenAuthorized>
            </div>

            {errors.taxonomicGroup && (
              <FieldValidationError>
                {JSON.stringify(errors.taxonomicGroup)}
              </FieldValidationError>
            )}
          </div>
        )}

        {/* GENDER */}
        <div>
          <label htmlFor="sel_gender" className="required">
            {t("gender")}{" "}
            <strong className="required">({t("required")})</strong>
          </label>

          <select
            id="sel_gender"
            className="form-control"
            {...register("gender", {
              required: true,
              validate: {
                notInitialized: (value) => value !== Gender.NotInitialized,
              },
            })}
          >
            <option selected value={Gender.NotInitialized}>
              {t("select_a_gender")}
            </option>
            <option value={Gender.Masculine}>{t(Gender.Masculine)}</option>
            <option value={Gender.Feminine}>{t(Gender.Feminine)}</option>
            <option value={Gender.Other}>{t(Gender.Other)}</option>
          </select>

          {errors.gender && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>

        {/* RANGE */}

        <div>
          <fieldset className="chkbxrdio-grp">
            <legend>
              <span className="field-name">{t("range")}</span>
            </legend>
            <Controller
              name={"ranges"}
              render={({ field: { onChange } }) => (
                <RangeCheckboxes
                  onChange={onChange}
                  defaultSelected={initialValues.ranges}
                />
              )}
            />
          </fieldset>
          {errors.ranges && (
            <FieldValidationError>
              {JSON.stringify(errors.ranges)}
            </FieldValidationError>
          )}
        </div>
      </div>
    </SectionCard>
  );
};
