import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface BilingualToggleContainerProps {
  className?: string;
  showBoth?: boolean;
  english: ReactElement<any, any>;
  french: ReactElement<any, any>;
}

export const BilingualToggleContainer: React.FC<
  BilingualToggleContainerProps
> = ({ showBoth = false, english, french, ...props }) => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    showBoth ? "Both" : i18n.language === "en" ? "English" : "French"
  );

  React.useEffect(() => {
    setSelectedLanguage((selLang) =>
      showBoth || selLang === "Both"
        ? "Both"
        : i18n.language === "en"
        ? "English"
        : "French"
    );
  }, [showBoth, i18n.language]);

  const isEnglish = selectedLanguage === "English";
  const isFrench = selectedLanguage === "French";
  const isBoth = selectedLanguage === "Both";

  return (
    <div {...props}>
      <div style={{ float: "right" }} className="btn-group btn-group-xs">
        <button
          type="button"
          className={
            isEnglish
              ? "border-style-solid btn btn-primary"
              : "border-style-solid btn btn-default"
          }
          onClick={() => setSelectedLanguage("English")}
        >
          {t("english")}
        </button>
        <button
          type="button"
          className={
            isFrench
              ? "border-style-solid btn btn-primary"
              : "border-style-solid btn btn-default"
          }
          onClick={() => setSelectedLanguage("French")}
        >
          {t("french")}
        </button>
        <button
          type="button"
          className={
            isBoth
              ? "border-style-solid btn btn-primary"
              : "border-style-solid btn btn-default"
          }
          onClick={() => setSelectedLanguage("Both")}
        >
          {t("both")}
        </button>
      </div>

      <div style={isEnglish || isBoth ? {} : { display: "none" }}>
        {english}
      </div>
      <div style={isFrench || isBoth ? {} : { display: "none" }}>{french}</div>

      <div style={{ clear: "both" }} />
    </div>
  );
};

export default BilingualToggleContainer;
