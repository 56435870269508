const adminSpeciesTranslation = {
  en: {
    species: "Species",
    list_of_species: "List of species",
    select_a_species: "Select a species",
    create_a_species: "Create a Species",
    enter_species_name: "Enter a species name",
    edit_species: "Edit Species",
    reactivate_a_species: "Reactivate a Species",
    deactivate_a_species: "Deactivate a Species",
    archive_species_warning:
      "You will no longer be able to add this Species to a Wildlife Species.",
    unarchive_species_warning:
      "You will be able to add this Species to a Wildlife Species.",
    no_species_selected: "No species was selected",
    archive_a_species: "Archive a species",
    unarchive_a_species: "Unarchive a species",
  },
  fr: {
    species: "Espèce",
    list_of_species: "Liste d'espèces",
    select_a_species: "Sélectionnez un espèce",
    create_a_species: "Créer une espèce",
    enter_species_name: "Entrez le nom d'une espèce",
    edit_species: "Éditer le espèces",
    reactivate_a_species: "Réactiver un genre",
    deactivate_a_species: "Réactiver un espèce",
    archive_species_warning:
      "Vous ne pourrez plus ajouter cette espèce à une espèce sauvage.",
    unarchive_species_warning:
      "Vous pourrez ajouter cette espèce à une espèce sauvage.",
    no_species_selected: "Aucune espèce n'a été sélectionnée",
    archive_a_species: "Archiver une espèce",
    unarchive_a_species: "Désarchiver une espèce",
  },
};

export default adminSpeciesTranslation;
