import { mapContactToContactRef } from "components/organisms/contacts/AddContactButtonAndModal/addContactUtil";
import {
  ListingProcessTif,
  Maybe,
  UpdateListingProcessTifInput,
} from "generated/gql-types";
import * as FormMappers from "util/formMappers";
import { ListingProcessTifFormFields } from "./ListingProcessTifForm";

export const mapDomainModelToForm = (
  domainModel: Maybe<Partial<ListingProcessTif>>
) => {
  const out: Partial<ListingProcessTifFormFields> = {};

  FormMappers.mapBilingualLookupText(
    out,
    "proposedConsultationPath",
    domainModel?.proposedConsultationPath
  );
  FormMappers.mapBilingualRichText(
    out,
    "rangeOfImpact",
    domainModel?.rangeOfImpact
  );
  FormMappers.mapBilingualRichText(
    out,
    "consultationRequirements",
    domainModel?.consultationRequirements
  );
  FormMappers.mapBilingualRichText(
    out,
    "informationUncertainties",
    domainModel?.informationUncertainties
  );
  FormMappers.mapBilingualRichText(
    out,
    "speciesDataSource",
    domainModel?.speciesDataSource
  );
  FormMappers.mapBilingualRichText(
    out,
    "methodology",
    domainModel?.methodology
  );

  if (domainModel?.gisContactRef != null) {
    FormMappers.mapContactRef(
      out,
      "gisContactPerson[contactRef]",
      domainModel.gisContactRef
    );

    if (out.gisContactPerson) {
      out.gisContactPerson.contactType = "contact";
    }
  }
  FormMappers.mapRichText(out, "gisContactNotes", domainModel?.gisContactNotes);

  if (domainModel?.biologistContactRef != null) {
    FormMappers.mapContactRef(
      out,
      "biologistContactPerson[contactRef]",
      domainModel.biologistContactRef
    );

    if (out.biologistContactPerson) {
      out.biologistContactPerson.contactType = "contact";
    }
  }
  FormMappers.mapRichText(
    out,
    "biologistContactNotes",
    domainModel?.biologistContactNotes
  );

  FormMappers.mapBilingualRichText(out, "comments", domainModel?.comments);

  return out;
};

export function mapFormToDomainModel(
  form: Partial<ListingProcessTifFormFields>
) {
  const out: Partial<UpdateListingProcessTifInput> = {};

  FormMappers.mapBilingualLookupTextInput(
    out,
    "proposedConsultationPath",
    form.proposedConsultationPath
  );
  FormMappers.mapBilingualRichTextInput(
    out,
    "rangeOfImpact",
    form.rangeOfImpact
  );
  FormMappers.mapBilingualRichTextInput(
    out,
    "consultationRequirements",
    form.consultationRequirements
  );
  FormMappers.mapBilingualRichTextInput(
    out,
    "informationUncertainties",
    form.informationUncertainties
  );
  FormMappers.mapBilingualRichTextInput(
    out,
    "speciesDataSource",
    form.speciesDataSource
  );
  FormMappers.mapBilingualRichTextInput(out, "methodology", form.methodology);

  FormMappers.mapContactRefInput_New(
    out,
    "gisContactRef",
    mapContactToContactRef(
      form.gisContactPerson
        ? form.gisContactPerson?.contactRef
        : form.gisContactPerson
    )
  );
  FormMappers.mapRichTextInput(out, "gisContactNotes", form.gisContactNotes);

  FormMappers.mapContactRefInput_New(
    out,
    "biologistContactRef",
    mapContactToContactRef(
      form.biologistContactPerson
        ? form.biologistContactPerson?.contactRef
        : form.biologistContactPerson
    )
  );
  FormMappers.mapRichTextInput(
    out,
    "biologistContactNotes",
    form.biologistContactNotes
  );

  FormMappers.mapBilingualRichTextInput(out, "comments", form.comments);

  return out;
}
