import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
} from "@react-stately/table";
import MissingData from "components/atoms/MissingData";
import * as React from "react";
import isNullOrEmpty from "util/isNullOrEmpty";
import Table, { TableProps } from "../Table";

export type ColumnHeader = { name: string; key: string };

export interface AdminTableProps extends Omit<TableProps, "children"> {
  columns: ColumnHeader[];
  sortable?: boolean;
  rows: any[];
}

export const AdminTable: React.FC<AdminTableProps> = (props) => {
  return (
    <Table {...props}>
      <TableHeader columns={props.columns}>
        {(col) => <Column allowsSorting={props.sortable}>{col.name}</Column>}
      </TableHeader>
      <TableBody items={props.rows}>
        {(item: any) => (
          <Row>
            {(columnKey) => (
              <Cell>
                {!isNullOrEmpty(item[columnKey]) ? (
                  item[columnKey]
                ) : (
                  <MissingData />
                )}
              </Cell>
            )}
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

export default AdminTable;
