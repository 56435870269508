import DatePicker from "components/molecules/DatePicker";
import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RegistryPageFormFields } from "../RegistryPageForm/RegistryPageForm";
import FieldValidationError from "../../../../atoms/forms/FieldValidationError";

export interface AddDocumentsMultiSelectListItemProps {
  id?: string;
  name?: string;
  onRemove: () => void;
  onMoveDown?: () => void;
  index: number;
}

export const AddDocumentsMultiSelectListItem: React.FC<
  AddDocumentsMultiSelectListItemProps
> = (props) => {
  const { t } = useTranslation();

  const form = useFormContext<RegistryPageFormFields>();
  const { control, formState } = form;
  const { errors } = formState;

  const testid = `add-doc-multi-select-list-item-${props.name}`;

  return (
    <li
      className="multi-select-list-item"
      data-id={props.id}
      data-testid={testid}
    >
      <div className="flex justify-between align-center mrgn-bttm-sm">
        <span className="font-size-16">
          <b>{props.name ?? "-"}</b>
        </span>
        <span className="min-width-fit-content">
          <button
            type="button"
            title={t("down")}
            className="btn btn-link p-0 hover-grey"
            data-testid={`button-down-${props.name}`}
            onClick={props.onMoveDown}
            disabled={props.onMoveDown == null}
          >
            <i className="fas fa-arrow-down font-size-14"></i>
          </button>
          <button
            type="button"
            title={t("remove")}
            className="btn btn-link p-0 hover-grey"
            data-testid={`button-remove-${props.name}`}
            onClick={props.onRemove}
            // disabled={isSubmitting}
          >
            <i className="fas fa-times font-size-14 color-danger"></i>
          </button>
        </span>
      </div>

      <div className="border-light mrgn-bttm-sm bg-white">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <Controller
                control={control}
                name={
                  `documentRefs.${props.index}.consultationDates.fromDate` as const
                }
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    label={t("public_consultation_start_date")}
                    className="full-width line-height-normal"
                    value={formatDateForDatePicker(value)}
                    onInput={onChange}
                    key={`${props.id}-consultationDates.fromDate`}
                    id={
                      `documentRefs.${props.index}.consultationDates.fromDate` as const
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <Controller
                control={control}
                name={
                  `documentRefs.${props.index}.consultationDates.toDate` as const
                }
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    label={t("public_consultation_end_date")}
                    className="full-width line-height-normal"
                    value={formatDateForDatePicker(value)}
                    onInput={onChange}
                    key={`${props.id}-consultationDates.toDate`}
                    id={
                      `documentRefs.${props.index}.consultationDates.toDate` as const
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <Controller
                control={control}
                name={`documentRefs.${props.index}.publishedDate` as const}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    label={t("publishing_date")}
                    required={true}
                    className="full-width line-height-normal"
                    value={formatDateForDatePicker(value)}
                    onInput={onChange}
                    key={`${props.id}-consultationDates.publishedDate`}
                    id={`documentRefs.${props.index}.publishedDate` as const}
                  />
                )}
              />

              {errors.documentRefs &&
                errors.documentRefs[props.index]?.publishedDate && (
                  <div className="mrgn-bttm-sm">
                    <FieldValidationError>
                      {t("field_is_required")}
                    </FieldValidationError>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AddDocumentsMultiSelectListItem;

/// For some reason, the date pickers in this component aren't accepting
/// date strings like "2024-01-01T00:00:00", even though they work on other pages..
/// trimming off the "T00:00:00" part seems to make it work correctly.
function formatDateForDatePicker(dateString?: string | null) {
  return dateString ? dateString.split("T")[0] : "";
}
