export const REACT_APP_CONFIGURATION_CONNECTION_STRING = process.env
  .REACT_APP_CONFIGURATION_CONNECTION_STRING as string;

export enum FeatureFlagKeys {
  QueryTool = "QueryTool",
  PermitDescription = "PermitDescription",
  Schedule2and3Species = "Schedule2and3Species",
  DocumentManager = "DocumentManager",
  RegistryPage = "RegistryPage",
  Photos = "Photos",
  EditDocument = "EditDocument",
  DistributionList = "DistributionList",
  Authorization = "Authorization",
  BusinessProcessTracking = "BusinessProcessTracking",
}
