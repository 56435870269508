import {
  getGqlEndpointForEnvironment,
  resolveAzureEnvironment,
  RunEnvironment,
} from "../../azure/environment";
import React from "react";
import { useMsal } from "@azure/msal-react";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import * as ApolloLinkFunctions from "../../util/apollo/links";
import { asyncTokenLookup } from "../auth/asyncTokenLookup";
import { loginRequestConfig, loginSilentRequestConfig } from "../auth/config";
import { useLanguage } from "@arcnovus/wet-boew-react";

const env = resolveAzureEnvironment();
const path = getGqlEndpointForEnvironment();

switch (env) {
  case RunEnvironment.LOCAL:
  case RunEnvironment.AZURE_DEV:
  case RunEnvironment.AZURE_ALTDEV:
  case RunEnvironment.AZURE_TEST:
  case RunEnvironment.AZURE_MIGRATION: {
    console.log("GQL_PATH:", path);
    break;
  }
  case RunEnvironment.UNRECOGNIZED:
  case RunEnvironment.AZURE_CLIENT:
  case RunEnvironment.AZURE_PROD:
  case RunEnvironment.AZURE_PREPROD: {
    // Don't print anything in prod or unrecognized environments
    break;
  }
}

export const CustomApolloProvider: React.FC = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const { currentLanguage } = useLanguage();

  const apolloClient = React.useMemo(() => {
    console.log("Initializing Apollo Client");

    const withAuthTokenLink = setContext(async (_, { headers }) => {
      try {
        let token = await asyncTokenLookup(instance, inProgress);

        if (token == null) {
          const account = instance.getActiveAccount();
          if (!account) {
            await instance.acquireTokenRedirect(loginRequestConfig);
            const result = await instance.handleRedirectPromise();
            token = result?.accessToken;
          } else {
            const result = await instance.acquireTokenSilent({
              ...loginSilentRequestConfig,
              account,
            });
            return result.accessToken;
          }
        }

        const newHeaders = Object.assign(headers ?? {}, {
          Authorization: `Bearer ${token}`,
        });

        return { headers: newHeaders };
      } catch (e) {
        console.error(e);
        await instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });

        return { headers };
      }
    });

    const withAcceptLanguageLink = setContext(async (_, { headers }) => {
      try {
        const newHeaders = Object.assign(headers ?? {}, {
          "Accept-Language": `${currentLanguage}-CA, ${currentLanguage}`,
        });

        return { headers: newHeaders };
      } catch (e) {
        console.error(e);
        return { headers };
      }
    });

    const httpLink = new HttpLink({
      uri: path,
    });

    return new ApolloClient({
      uri: path,
      cache: new InMemoryCache({
        addTypename: false,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
        mutate: {
          errorPolicy: "all",
        },
      },
      link: ApolloLink.from([
        ApolloLinkFunctions.roundTripTimeStartLink,
        withAuthTokenLink,
        withAcceptLanguageLink,
        httpLink,
        ApolloLinkFunctions.errorLink,
        ApolloLinkFunctions.roundTripTimeEndLink,
      ]),
    });
  }, [instance, inProgress]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
