import * as React from "react";
import { useTranslation } from "react-i18next";
import EditStepPopup, {
  EditStepPopupProps,
  useStepPopup,
} from "./EditStepModal";

interface EditStepModalWithButtonProps
  extends Omit<EditStepPopupProps, "modalState"> {
  buttonLabel?: string;
}

const EditStepBtn: React.FC<EditStepModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useStepPopup({});
  const label = buttonLabel ?? t("edit");
  const validStepId = props.templateId != null && props.stepInfo != null;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link font-size-14 hover-grey"
        onClick={modalState.open}
        title={t("edit_step")}
        disabled={!validStepId}
        data-testid="button-edit"
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {validStepId ? (
        <EditStepPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditStepBtn;
