import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SectionCard from "../../../atoms/SectionCard";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import { AdditionalProtectionUnderSaraMultiSelectListWithController } from "../AdditionalProtectionUnderSaraMultiSelectList/AdditionalProtectionUnderSaraMultiSelectList";
import { JurisdictionSelectorWithController } from "../JurisdictionSelector/JurisdictionSelector";
import { OtherFederalProtectionMultiSelectListWithController } from "../OtherFederalProtectionMultiSelectList/OtherFederalProtectionMultiSelectList";
import { ProvincialTerritorialLegislationsMultiSelectListWithController } from "../ProvincialTerritorialLegislationsMultiSelectList/ProvincialTerritorialLegislationsMultiSelectList";
import { ListingFormChangeTracking, ListingFormFields } from "./index";

export interface LegalProtectionSectionProps {
  initialValues: ListingFormFields;
  open: boolean;
}

export const LegalProtectionSection: React.FC<LegalProtectionSectionProps> = (
  props
) => {
  const { initialValues, open } = props;
  const { t } = useTranslation();
  const { control } = useFormContext<
    ListingFormFields & ListingFormChangeTracking
  >();

  return (
    <SectionCard
      collapsible
      open={open}
      header={<h2>{t("legal_protection")}</h2>}
    >
      <div className="flex-col gap-md">
        {/* JURISDICTION */}
        <JurisdictionSelectorWithController
          control={control}
          name="legalProtection.jurisdiction"
        />

        {/* PROVINCIAL/TERRITORIAL LEGISLATION */}
        <section>
          <header>
            <h3 className="mrgn-tp-md page-header">
              {t("provincial_territorial_legislation")}
            </h3>
          </header>

          <div className="flex-col gap-md">
            <ProvincialTerritorialLegislationsMultiSelectListWithController
              control={control}
              defaultValue={
                initialValues.legalProtection
                  .provincialAndTerritorialLegislations
              }
              name="legalProtection.provincialAndTerritorialLegislations"
            />

            <div>
              <label htmlFor="legislationApplicationField">
                {t("legislation_application")}
              </label>
              <FullHTMLEditorWithController
                control={control}
                defaultValue={
                  initialValues.legalProtection.legislationApplication?.text ??
                  ""
                }
                id="legislationApplicationField"
                name="legalProtection.legislationApplication.text"
                maxLength={500}
              />
            </div>
          </div>
        </section>

        {/* OTHER FEDERAL PROTECTION */}

        <section>
          <header>
            <h3 className="mrgn-tp-md page-header">
              {t("other_federal_protection")}
            </h3>
          </header>

          <div className="flex-col gap-md">
            <OtherFederalProtectionMultiSelectListWithController
              control={control}
              defaultValue={
                initialValues.legalProtection.otherFederalProtections
              }
              name="legalProtection.otherFederalProtections"
            />

            <div>
              <label htmlFor="federalProtectionApplicationField">
                {t("federal_protection_application")}
              </label>
              <FullHTMLEditorWithController
                control={control}
                defaultValue={
                  initialValues.legalProtection.federalProtectionApplication
                    ?.text ?? ""
                }
                id="federalProtectionApplicationField"
                name="legalProtection.federalProtectionApplication.text"
              />
            </div>
          </div>
        </section>

        {/* ADDITIONAL PROTECTION UNDER SARA */}
        <section>
          <header>
            <h3 className="mrgn-tp-md page-header">
              {t("additional_protection_under_sara")}
            </h3>
          </header>

          <div className="flex-col gap-md">
            <AdditionalProtectionUnderSaraMultiSelectListWithController
              control={control}
              defaultValue={
                initialValues.legalProtection.additionalProtectionUnderSara
              }
              name="legalProtection.additionalProtectionUnderSara"
            />
          </div>
        </section>
      </div>
    </SectionCard>
  );
};
