import FormButtons from "components/molecules/FormButtons/FormButtons";
import {
  BilingualAbbreviationAcronymTagsLookupTextInput,
  BilingualRichTextInput,
  DocumentContentOwner,
  DocumentPublicationType,
  DocumentState,
  Maybe,
  OverviewWsSpeciesInput,
  RichTextInput,
} from "generated/gql-types";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "util/forms";
import { DocumentInformationCard } from "./DocumentInformationCard";
import { setNullForEmptyFields } from "./utils";

export interface DocumentFormProps {
  initialValues: Partial<DocumentFormFields>;
  documentState?: Maybe<DocumentState>;
  onSubmit: SubmitHandler<Partial<DocumentFormFields>>;
  onCancel: () => void;
}

export const DocumentForm: React.FC<DocumentFormProps> = (props) => {
  const { initialValues, documentState } = props;
  const { t } = useTranslation();

  const form = useForm<DocumentFormFields>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState, setValue, getValues } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<DocumentFormFields>> = async (
    formData
  ) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, initialValues);

    await props.onSubmit(FilteredFormData);
    window.scrollTo(0, 0);
  };

  // Initialize Publication type to Internal on Create document.
  if (
    initialValues.publicationType == null &&
    getValues("publicationType") == null
  ) {
    setValue("publicationType", DocumentPublicationType.Internal, {
      shouldDirty: true,
    });
  }

  // Initialize Content owner to Other on Create document.
  if (initialValues.contentOwner == null && getValues("contentOwner") == null) {
    setValue("contentOwner", DocumentContentOwner.CosewicSecretariat, {
      shouldDirty: true,
    });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DocumentInformationCard
          initialValues={initialValues}
          documentState={documentState}
        />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onCancel}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
};

export default DocumentForm;

///////////////////////////////////////////////////

export interface DocumentFormFields {
  publicationType: DocumentPublicationType;
  title: BilingualRichTextInput;
  documentType: BilingualAbbreviationAcronymTagsLookupTextInput | undefined;
  contentOwner: DocumentContentOwner;
  programs: BilingualAbbreviationAcronymTagsLookupTextInput[];
  associatedSpecies: OverviewWsSpeciesInput[];
  note: RichTextInput;
}
