import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhaseSelectionDropdown from "./PhaseSelectionDropdown";
import { BptTemplatePhase, Maybe } from "generated/gql-types";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface PhaseSelectionFields {
  selectedItem: string;
}

export const defaultValueFields = {
  selectedItem: undefined,
};

export interface PhaseSelectionDropdownFormProps {
  onSubmit: SubmitHandler<PhaseSelectionFields>;
  phaseid: string;
  phaseList?: Maybe<BptTemplatePhase>[] | null;
}

export const PhaseSelectionDropdownForm: React.FC<
  PhaseSelectionDropdownFormProps
> = (props) => {
  const { t } = useTranslation();
  const { phaseList, phaseid } = props;
  const form = useForm<PhaseSelectionFields>({
    defaultValues: defaultValueFields,
  });
  const { register, handleSubmit, watch } = form;
  const selectedItem = watch("selectedItem");

  const onSubmit: SubmitHandler<PhaseSelectionFields> = (newValues) => {
    const mapped = newValues;
    props.onSubmit(mapped);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-row mfgn-tp-md">
        <div className="flex align-start flex-wrap">
          <div className="flex-col">
            <div className="input-group mrgn-rght-sm">
              <PhaseSelectionDropdown
                {...register("selectedItem")}
                required={false}
                disabled={phaseList != null && phaseList?.length <= 1}
                label={t("switch_to_other_phase")}
                placeholder={t("select_phase")}
                phaseList={phaseList}
                phaseid={phaseid}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-bttm-md"
            data-testid="button-apply"
            disabled={
              isNullOrEmpty(selectedItem) ||
              (phaseid != null && selectedItem === phaseid)
            }
          >
            {t("apply")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default PhaseSelectionDropdownForm;
