import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "util/router";
import PublicProfilePageByReference from ".";
import PublicProfilePage from "./[id]";
import PublicProfileEditPage from "./[id]/edit";

export interface PublicProfileRouterProps {}

export const PublicProfilePathHelpers = {
  PublicProfileByReference: (program: string, id: string) =>
    `/publicProfile?program=${program}&id=${id}`,
  PublicProfile: (speciesProfileId: string) =>
    `/publicProfile/${speciesProfileId}`,
  PublicProfileEdit: (speciesProfileId: string) =>
    `/publicProfile/${speciesProfileId}/edit`,
};

export const PublicProfileRouter: React.FC<PublicProfileRouterProps> = (
  props
) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <PublicProfilePageByReference />
      </Route>

      <Route exact path={relative("/:speciesProfileId")}>
        <PublicProfilePage />
      </Route>

      <Route path={relative("/:speciesProfileId/edit")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.speciesProfile.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <PublicProfileEditPage />
        </RenderWhenAuthorized>
      </Route>
    </Switch>
  );
};

export default PublicProfileRouter;
