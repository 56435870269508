import cc from "classcat";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import { appBuildVersion } from "features/buildVersion/config";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from "util/strings";
import { CosewicPathHelpers } from "./cosewic/CosewicRouter";
import styles from "./index.module.css";
import { ListingPathHelpers } from "./listing/ListingRouter";
import { PermitPathHelpers } from "./permit/PermitRouter";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Layout.Root>
        <Layout.HomeContent>
          <div className={cc(["provisional bg-cover", styles.hero_bg_img])}>
            <div className={cc(["container", styles.hero_container])}>
              <div className="well header-rwd brdr-0 brdr-rds-0 text-white bg-gctheme opct-80">
                <h1
                  property="name"
                  id="wb-cont"
                  className="h2 mrgn-tp-lg mrgn-bttm-lg"
                >
                  {t("sardmts_home_line1")}
                  <br />
                  {t("sardmts_home_line2")}
                </h1>
                <small>
                  {capitalizeFirstLetter(t("version"))}{" "}
                  {appBuildVersion || <MissingData />}
                </small>
              </div>
            </div>
          </div>

          <div className="home experimental">
            <div className="gc-srvinfo container mrgn-tp-lg">
              <div className="wb-eqht row">
                <div className="col-md-6">
                  <SectionCard
                    className="hght-inhrt"
                    header={<h2>{t("cosewic_program")}</h2>}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push({
                          pathname: CosewicPathHelpers.Home,
                        });
                      }}
                      data-testid="button-cosewic-tools"
                    >
                      {t("cosewic_tools")}
                    </button>
                  </SectionCard>
                </div>
                <div className="col-md-6">
                  <SectionCard
                    className="hght-inhrt"
                    header={<h2>{t("listing_program")}</h2>}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push({
                          pathname: ListingPathHelpers.Home,
                        });
                      }}
                      data-testid="button-listing-tools"
                    >
                      {t("listing_tools")}
                    </button>
                  </SectionCard>
                </div>
              </div>
              <div className="wb-eqht row">
                <div className="col-md-6">
                  <SectionCard
                    className="hght-inhrt"
                    header={<h2>{t("recovery_program")}</h2>}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push({
                          pathname: CosewicPathHelpers.Home,
                        });
                      }}
                      disabled={true}
                      data-testid="button-recovery-tools"
                    >
                      {t("recovery_tools")}
                    </button>
                  </SectionCard>
                </div>
                <div className="col-md-6">
                  <SectionCard
                    className="hght-inhrt"
                    header={<h2>{t("protection_program")}</h2>}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push({
                          pathname: CosewicPathHelpers.Home,
                        });
                      }}
                      disabled={true}
                      data-testid="button-protection-tools"
                    >
                      {t("protection_tools")}
                    </button>
                  </SectionCard>
                </div>
              </div>
              <RenderWhenFeatureIsEnabled
                flagKey={FeatureFlagKeys.PermitDescription}
              >
                <div className="wb-eqht row">
                  <div className="col-md-6">
                    <SectionCard
                      className="hght-inhrt"
                      header={<h2>{t("permits_program")}</h2>}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          history.push({
                            pathname: PermitPathHelpers.Home,
                          });
                        }}
                        data-testid="button-permits-tools"
                      >
                        {t("permits_tools")}
                      </button>
                    </SectionCard>
                  </div>
                </div>
              </RenderWhenFeatureIsEnabled>
            </div>
          </div>
        </Layout.HomeContent>
      </Layout.Root>
    </>
  );
};

export default Home;
