import { ListingPathHelpers } from "pages/listing/ListingRouter";
import { ResponseStatementPathHelpers } from "pages/responseStatement/responseStatementRouter";
import { SearchPathHelpers } from "pages/search/SearchRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export const ResponseStatementSideNav: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 id="wb-sec-h" className="wb-inv">
        Section Menu
      </h2>
      <section className="list-group menu list-unstyled">
        <h3>{t("Navigation")}</h3>
        <ul className="list-group menu list-unstyled">
          <li>
            <NavLink
              to={SearchPathHelpers.Listing}
              className="list-group-item"
              activeClassName="wb-navcurr"
              data-testid="side-nav-search-listing"
            >
              {t("search_listing_wildlife_species")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={ListingPathHelpers.ListingCreate}
              className="list-group-item"
              activeClassName="wb-navcurr"
              data-testid="side-nav-add-listing"
            >
              {t("add_listing_wildlife_species")}
            </NavLink>
          </li>

          <li>
            <NavLink
              to={ResponseStatementPathHelpers.ResponseStatementBrowse}
              className="list-group-item"
              activeClassName=""
              data-testid="side-nav-response-statement"
            >
              {t("response_statements")}
            </NavLink>
            <ul className="list-group menu list-unstyled">
              <>
                <li>
                  <NavLink
                    exact
                    to={ResponseStatementPathHelpers.ResponseStatementBrowse}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-browse-response-statement"
                  >
                    {t("browse_response_statements")}
                  </NavLink>
                </li>
              </>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ResponseStatementSideNav;
