import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import { BptTemplatePhase, Maybe } from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import EditPhaseBtn from "../EditPhaseModal/EditPhaseBtn";

export interface PhaseInformationCardProps {
  templateId: string;
  phaseInfo?: Maybe<BptTemplatePhase>;
  isTemplateDraft?: boolean;
}

export const PhaseInformationCard: React.FC<PhaseInformationCardProps> = (
  props
) => {
  const { t } = useTranslation();
  const { templateId, phaseInfo, isTemplateDraft } = props;
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("phase"));

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2>{t("phase_information")}</h2>
            {isTemplateDraft ? (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.administration.bpt.updateRoles}
              >
                <EditPhaseBtn
                  refetchQueries={["BptTemplatePhase"]}
                  templateId={templateId}
                  phaseInfo={phaseInfo}
                  onCompleted={(data: any) => {
                    alertContext.showSuccess({
                      message: data?.UpdateBptTemplatePhase?.name?.text,
                      timeOut: 5000,
                    });
                  }}
                  onError={adminAlertMsg.onCreateError}
                />
              </RenderWhenAuthorized>
            ) : null}
          </div>
        }
      >
        <div className="font-size-18">
          <div className="species-field">
            {/* ------------ Phase Description ------------ */}
            <div className="row">
              <dl>
                <div className="species-data col-sm-12">
                  <dt className="text-muted">{t("description")}</dt>
                  <dd>
                    {props.phaseInfo?.description == null ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml
                        htmlString={props.phaseInfo?.description.text ?? ""}
                      />
                    )}
                  </dd>
                </div>
              </dl>
            </div>

            <div className="row">
              <dl>
                {/* ------------ Phase estimated duration ------------ */}
                <div className="species-data col-sm-5">
                  <dt className="text-muted">
                    {t("calculated_duration")}
                    {props.phaseInfo?.calculatedDuration ?? (
                      <TooltipAlert isVisible={true}>
                        {t("can_not_be_calculated_tooltip")}
                      </TooltipAlert>
                    )}
                  </dt>
                  <dd>
                    {props.phaseInfo?.calculatedDuration == null ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml
                        htmlString={
                          props.phaseInfo?.calculatedDuration +
                          " " +
                          t("business_days")
                        }
                      />
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </SectionCard>
    </>
  );
};

export default PhaseInformationCard;
