import { getApiSearchServiceUrl } from "azure/environment";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import i18n from "i18n";
import { QueryToolAPIDomainModel } from "./types";

const searchServiceUrl = getApiSearchServiceUrl();
const indexName = "wildlife-species-index-v1";

export const getFieldsDefinition = async (filter: string) => {
  const token = await getUserAccessToken();
  return fetch(
    `${searchServiceUrl}index/${indexName}/fields?filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": i18n.language + "-CA",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getQueryOperators = async () => {
  const token = await getUserAccessToken();
  return fetch(`${searchServiceUrl}index/${indexName}/fields/operators`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const postQuery = async (body: Partial<QueryToolAPIDomainModel>) => {
  const token = await getUserAccessToken();

  return fetch(`${searchServiceUrl}query`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(async (res) => {
    if (!res.ok) {
      const bodyRes = await res.text();
      const bodyResObj = JSON.parse(bodyRes);
      const errorMessage =
        i18n.language === "fr"
          ? bodyResObj?.messages[0]?.frenchMessage
          : bodyResObj?.messages[0]?.englishMessage;
      throw new Error(errorMessage);
    }
    return res.json();
  });
};
