import { BilingualRichText } from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";
import { PermitDescriptionFormFields } from "./PermitDescriptionForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<PermitDescriptionFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<PermitDescriptionFormFields>,
  initialValues: Partial<PermitDescriptionFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  if (dirtyFields?.type && isNullOrEmpty(formData?.type)) {
    set(formData, "type", null);
  }
  if (dirtyFields?.explanationFor && isNullOrEmpty(formData?.explanationFor)) {
    set(formData, "explanationFor", null);
  }
  if (
    dirtyFields?.permitDates?.fromDate &&
    isNullOrEmpty(formData?.permitDates?.fromDate)
  ) {
    set(formData, "permitDates.fromDate", null);
  }
  if (
    dirtyFields?.permitDates?.toDate &&
    isNullOrEmpty(formData?.permitDates?.toDate)
  ) {
    set(formData, "permitDates.toDate", null);
  }
  setNullForEmptyBilingualRichTextFields(
    "description",
    formData,
    dirtyFields,
    initialValues
  );
  setNullForEmptyBilingualRichTextFields(
    "preConditions",
    formData,
    dirtyFields,
    initialValues
  );
  setNullForEmptyBilingualRichTextFields(
    "otherRelevantInformation",
    formData,
    dirtyFields,
    initialValues
  );
  if (
    dirtyFields?.note?.text &&
    htmlIsNullOrEmpty(formData?.note?.text) &&
    !htmlIsNullOrEmpty(initialValues?.note?.text)
  ) {
    set(formData, "note", null);
  }
};

export const setNullForEmptyBilingualRichTextFields = (
  bilingualFieldName: keyof typeof formData,
  formData: UnpackNestedValue<Partial<PermitDescriptionFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<PermitDescriptionFormFields>
) => {
  // English
  const enIsDirty = dirtyFields[bilingualFieldName]?.english?.text;
  const enIsEmptyInForm = htmlIsNullOrEmpty(
    (formData[bilingualFieldName] as BilingualRichText)?.english?.text
  );

  const enIsEmptyInInitialValues = htmlIsNullOrEmpty(
    (initialValues[bilingualFieldName] as BilingualRichText)?.english?.text
  );

  // French
  const frIsDirty = dirtyFields[bilingualFieldName]?.french?.text;
  const frIsEmptyInForm = htmlIsNullOrEmpty(
    (formData[bilingualFieldName] as BilingualRichText)?.french?.text
  );

  const frIsEmptyInInitialValues = htmlIsNullOrEmpty(
    (initialValues[bilingualFieldName] as BilingualRichText)?.french?.text
  );

  // Cases where full object should be null
  if (enIsDirty && frIsDirty && enIsEmptyInForm && frIsEmptyInForm) {
    set(formData, bilingualFieldName, null);
    return;
  } else if (
    enIsDirty &&
    enIsEmptyInForm &&
    !frIsDirty &&
    frIsEmptyInInitialValues
  ) {
    set(formData, bilingualFieldName, null);
    return;
  } else if (
    frIsDirty &&
    frIsEmptyInForm &&
    !enIsDirty &&
    enIsEmptyInInitialValues
  ) {
    set(formData, bilingualFieldName, null);
    return;
  }

  // Cases where only en should be set null
  if (enIsDirty && enIsEmptyInForm) {
    set(formData, bilingualFieldName + ".english", null);
    return;
  }

  // Cases where only fr should be set null
  if (frIsDirty && frIsEmptyInForm) {
    set(formData, bilingualFieldName + ".french", null);
    return;
  }

  return;
};
