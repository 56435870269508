import { ADMIN_FORM_INPUT_PATTERN } from "config/constants";
import { AdminResponseStatementType } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../atoms/forms/FieldValidationError";
import { FullHTMLEditorWithController } from "../../organisms/FullHTMLEditor";
import BilingualToggleContainer from "../BilingualToggleContainer";
import FormButtons from "../FormButtons/FormButtons";
import { TextInput } from "components/atoms/forms/TextInput";

const ResponseStatementForm: React.FC<ResponseStatementFormProps> = (props) => {
  const form = useForm<ResponseStatementFields>({
    defaultValues: props.defaultValues,
  });

  const { register, handleSubmit, formState, control, watch, setValue } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();

  const [RSConsultationPeriod, setRSConsultationPeriod] = React.useState(
    props.defaultValues.includeConsultationPeriod ?? false
  );

  const onChangeType = (e: any) => {
    switch (e.target.value) {
      case AdminResponseStatementType.ResponseStatementScenarios:
        setValue("includeConsultationPeriod", RSConsultationPeriod);
        break;
      case AdminResponseStatementType.ApplicableFederalLegislation:
      default:
        setValue("includeConsultationPeriod", false);
    }
  };

  const onSubmit: SubmitHandler<ResponseStatementFields> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/* -------------- Name in English -------------- */}
      <TextInput
        type="text"
        label={t("name_in_english")}
        pattern={ADMIN_FORM_INPUT_PATTERN}
        id="responseStatementNameEnglishInput"
        inputClassName="full-width"
        placeholder={t("name_english")}
        maxLength={100}
        required={true}
        {...register("nameEnglish", {
          required: {
            value: true,
            message: t("field_is_required"),
          },
        })}
        errors={errors}
      />
      <TextInput
        type="text"
        label={t("name_in_french")}
        pattern={ADMIN_FORM_INPUT_PATTERN}
        id="responseStatementNameFrenchInput"
        inputClassName="full-width"
        placeholder={t("name_french")}
        maxLength={100}
        required={true}
        {...register("nameFrench", {
          required: {
            value: true,
            message: t("field_is_required"),
          },
        })}
        errors={errors}
      />

      {/* -------------- Select statement type -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp">
          <legend className="required">
            <span className="field-name">{t("select_statement_type")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>
          <label
            className="radio-inline"
            htmlFor="rdo_responseStatementScenarios"
          >
            <input
              id="rdo_responseStatementScenarios"
              defaultChecked={
                props?.defaultValues.statementType ===
                AdminResponseStatementType.ResponseStatementScenarios
              }
              type="radio"
              value={AdminResponseStatementType.ResponseStatementScenarios}
              {...register("statementType", {
                required: true,
                onChange: (e) => onChangeType(e),
              })}
            />
            &#160;{t("response_statement_scenarios")}
          </label>
          <label
            className="radio-inline"
            htmlFor="rdo_applicableFederalLegislation"
          >
            <input
              id="rdo_applicableFederalLegislation"
              defaultChecked={
                props?.defaultValues.statementType ===
                AdminResponseStatementType.ApplicableFederalLegislation
              }
              type="radio"
              value={AdminResponseStatementType.ApplicableFederalLegislation}
              {...register("statementType", {
                required: true,
                onChange: (e) => onChangeType(e),
              })}
            />
            &#160;{t("applicable_federal_legislation")}
          </label>
        </fieldset>
      </div>

      {/* -------------- Include the Consultation period  -------------- */}
      <div className="form-group">
        <div className="checkbox">
          <label htmlFor="chkbox_includeConsultationPeriod">
            <input
              id="chkbox_includeConsultationPeriod"
              disabled={
                watch("statementType") !==
                AdminResponseStatementType.ResponseStatementScenarios
              }
              type="checkbox"
              {...register("includeConsultationPeriod", {
                onChange: () => {
                  setRSConsultationPeriod((x) => !x);
                  console.log(RSConsultationPeriod);
                },
              })}
            />
            &#160;{t("include_the_consultation_period")}
          </label>
        </div>
      </div>

      {/* -------------- Message -------------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="message-rte"
        showBoth={
          !isDirty ||
          errors.englishMessage != null ||
          errors.frenchMessage != null
        }
        english={
          <div className="form-group">
            <label
              htmlFor="responseStatementEnglishMessage"
              className="required"
            >
              {t("english_msg")}
              <strong className="required"> ({t("required")})</strong>
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{ required: true }}
              defaultValue={props.defaultValues.englishMessage}
              id="responseStatementEnglishMessage"
              name="englishMessage"
            />
            {errors.englishMessage && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        }
        french={
          <div className="form-group">
            <label
              htmlFor="responseStatementFrenchMessage"
              className="required"
            >
              {t("french_msg")}
              <strong className="required"> ({t("required")})</strong>
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{ required: true }}
              defaultValue={props.defaultValues.frenchMessage}
              id="responseStatementFrenchMessage"
              name="frenchMessage"
            />
            {errors.frenchMessage && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        }
      />

      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
        errors={errors}
      />
    </form>
  );
};

export default ResponseStatementForm;
export interface ResponseStatementFields {
  nameEnglish: string;
  nameFrench: string;
  statementType: AdminResponseStatementType;
  includeConsultationPeriod: boolean;
  englishMessage: string;
  frenchMessage: string;
}

interface ResponseStatementFormProps {
  defaultValues: ResponseStatementFields;
  onSubmit: SubmitHandler<ResponseStatementFields>;
  onClose: () => void;
}
// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
