import { AzureSearchDateRange } from "components/organisms/search/FilterOptionsSection/filters/filters";
import { ODataMapper } from "../types";

const registryPageOdataMappers: Record<string, ODataMapper> = {
  RegistryState: {
    key: "RegistryState",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ RegistryState: x })),
      };
    },
  },
  PublishedDate: {
    key: "PublishedDate",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        PublishedDate: dateRange,
      };
    },
  },
  CreatedDate: {
    key: "CreatedDate",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        CreatedDate: dateRange,
      };
    },
  },
  "DocumentRefs/ContentOwner": {
    key: "DocumentRefs/ContentOwner",
    filter(searchValues: string[]) {
      return `DocumentRefs/any(d: d/ContentOwner eq '${searchValues}'
      )`;
    },
  },

  // This is the reference for using strings as filters for Odata  queries: https://www.npmjs.com/package/odata-query?activeTab=readme#strings
  "DocumentRefs/Programs": {
    key: "DocumentRefs/Programs",
    filter(searchValues: string[]) {
      return `DocumentRefs/any(d: d/Programs/any(p: search.in(p, '${searchValues.join(
        ", "
      )}')))`;
    },
  },

  "DocumentRefs/DocumentType/Tags": {
    key: "DocumentRefs/DocumentType/Tags",
    filter(searchValues: string[]) {
      return `DocumentRefs/any(d: d/DocumentType/Tags/any(p: search.in(p, '${searchValues.join(
        ", "
      )}')))`;
    },
  },
};

export default Object.freeze(registryPageOdataMappers);
