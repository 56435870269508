import { isArray, isEqual } from "lodash";
import { Control, Path, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FullHTMLEditorWithController } from "../../components/organisms/FullHTMLEditor";
import styles from "./ChangeTracking.module.css";
import { useChangeTrackingContext } from "./context";
import { ChangeTrackingChangeType } from "./types";

/**
 * ChangeTracking
 * UI component for rendering change tracking inputs for some form data.
 *
 * @param props {ChangeTrackingProps}
 * @constructor
 */
const ChangeTracking = <TFieldValues,>(
  props: ChangeTrackingProps<TFieldValues>
) => {
  const { register } = useFormContext<TFieldValues>();
  const { t } = useTranslation();
  const {
    changeMetaDataName,
    cachedValue,
    trackedValue,
    reasonRequired = true,
    hideChangeType = false,
  } = props;
  const changeTrackingInstance = useChangeTrackingContext();

  // radioGroupName is used as the field name for 'changeType' in the submitted form data.
  // the `.changeType` property corresponds to the TS type ChangeMetaData, but it's not easy to enforce this with types.
  const radioGroupName = `${String(
    changeMetaDataName
  )}.changeType` as Path<TFieldValues>;

  if (!changeTrackingInstance.isEnabled) return null;

  // Call trim to remove trailing whitespace and newlines for comparison
  // TODO: tests for trimming behaviour here;
  // test that intentional newlines aren't removed,
  // test trackedValue being null and undefined on first render,
  // maybe test cachedValue being null and undefined?
  let hasChanges = false;
  if (isArray(trackedValue) || isArray(cachedValue)) {
    hasChanges = !isEqual(trackedValue, cachedValue);
  } else {
    const trimTracked = trackedValue?.toString().trim() ?? undefined;
    const trimCached = cachedValue?.toString().trim() ?? "";
    hasChanges = !isEqual(trimTracked, trimCached);
  }

  if (!hasChanges) return null;

  return (
    <div className={styles.changeTracking}>
      {hideChangeType ? null : (
        <fieldset className="form-group form-inline chkbxrdio-grp">
          <legend>
            <span className="field-name">{t("change_type")}</span>
          </legend>
          <label className="radio-inline" htmlFor={radioGroupName + "_minor"}>
            <input
              {...register(radioGroupName, { required: true })}
              id={radioGroupName + "_minor"}
              type="radio"
              value={ChangeTrackingChangeType.MINOR}
              defaultChecked
            />
            <span className="mrgn-lft-sm">{t("MINOR")}</span>
          </label>

          <label
            className="radio-inline"
            htmlFor={radioGroupName + "_reported_on"}
          >
            <input
              {...register(radioGroupName, { required: true })}
              id={radioGroupName + "_reported_on"}
              type="radio"
              value={ChangeTrackingChangeType.REPORTED_ON}
            />
            <span className="mrgn-lft-sm">{t("REPORTED_ON")}</span>
          </label>

          <label className="radio-inline" htmlFor={radioGroupName + "_delete"}>
            <input
              {...register(radioGroupName, { required: true })}
              id={radioGroupName + "_delete"}
              type="radio"
              value={ChangeTrackingChangeType.DELETE}
            />
            <span className="mrgn-lft-sm">{t("DELETE")}</span>
          </label>
        </fieldset>
      )}

      <label
        htmlFor="reasonForChangeField"
        className={reasonRequired ? "required" : ""}
      >
        {t("change_reason")}
        {reasonRequired ? (
          <strong className="required"> ({t("required")})</strong>
        ) : null}
      </label>
      <FullHTMLEditorWithController
        control={props.control}
        defaultValue=""
        rules={{ required: reasonRequired }}
        id={"reasonForChangeField"}
        placeholder={t("change_reason")}
        name={
          `${String(changeMetaDataName)}.reasonForChange` as Path<TFieldValues>
        }
      />
    </div>
  );
};

export default ChangeTracking;

export interface ChangeTrackingProps<TFieldValues> {
  // We get control as a prop instead of destructuring it from useFormContext only because it gives us
  // TS type inference on TFieldValues, allowing us to render the component without an explicit
  // type parameter every time, for example: <ChangeTracking<SomeFormFieldsType> changeMetaDataName="..." />
  control: Control<TFieldValues>;
  // As a result of the type inference from 'control', the 'changeMetaDataName' prop is automatically
  // checked to make sure it's a valid field name in the form type.
  changeMetaDataName: keyof TFieldValues;
  trackedValue: any;
  cachedValue: any;

  reasonRequired?: boolean;
  hideChangeType?: boolean;
}
