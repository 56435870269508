import { useMutation, useQuery } from "@apollo/client";
import Alert from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import {
  AdminPageFilterForm,
  QueryFields,
  defaultQueryFields,
} from "components/molecules/admin/AdminPageFilterForm";
import AdminArchiveModal from "components/organisms/admin/AdminArchiveModal";
import AdminTable from "components/organisms/admin/AdminTable";
import AdminUnarchiveModal from "components/organisms/admin/AdminUnarchiveModal";
import CreateSpeciesModalButton from "components/organisms/admin/CreateSpeciesModal/ButtonWithModal";
import EditSpeciesModalButton from "components/organisms/admin/EditSpeciesModal/ButtonWithModal";
import {
  ArchiveSpeciesDocument,
  SpeciesForAdminSpeciesDocument,
  UnarchiveSpeciesDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import GraphqlError from "../../components/GraphqlError";
import { RenderWhenAuthorized } from "../../features/auth/components";
import { ROLE_ACTIONS } from "../../features/auth/roles";

export interface AdminSpeciesProps {}

export const AdminSpecies: React.FC<AdminSpeciesProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const adminAlertMsg = useAdminAlertMsg(t("species"));

  const pagination = usePagination();

  const { data, loading, error } = useQuery(SpeciesForAdminSpeciesDocument, {
    variables: {
      params: {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        status: filters.showInactive ? "inactive" : "active",
        search: filters.search.length > 0 ? filters.search : undefined,
        searchType: "contains",
        isCaseSensitive: false,
      },
    },
    fetchPolicy: "network-only",
  });

  const [runArchive] = useMutation(ArchiveSpeciesDocument, {
    refetchQueries: ["SpeciesForAdminSpecies"],
    onCompleted: (data) => {
      adminAlertMsg.onArchiveSuccess(
        data?.deactivateSpecies?.name,
        data?.deactivateSpecies?.name
      );
    },
    onError: (_err) => {
      adminAlertMsg.onArchiveError();
    },
  });
  const [runUnarchive] = useMutation(UnarchiveSpeciesDocument, {
    refetchQueries: ["SpeciesForAdminSpecies"],
    onError: (_err) => {
      adminAlertMsg.onUnarchiveError();
    },
    onCompleted: (data) => {
      adminAlertMsg.onUnarchiveSuccess(
        data?.reactivateSpecies?.name,
        data?.reactivateSpecies?.name
      );
    },
  });

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const onArchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runArchive({ variables: { id: selectedRowId } });
  };

  const onUnarchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runUnarchive({ variables: { id: selectedRowId } });
  };

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  const columns = React.useMemo(
    () => [
      { name: t("name"), key: "name" },
      { name: t("created_by"), key: "createdBy" },
      { name: t("creation_date"), key: "createdAt" },
      { name: t("updated_by"), key: "modifiedBy" },
      { name: t("updated_date"), key: "modifiedAt" },
    ],
    [i18n.language]
  );

  const rows =
    data?.speciesList?.species.map((species) => ({
      ...species,
      createdAt: formatTimestamp(species?.createdAt),
      modifiedAt: formatTimestamp(species?.modifiedAt),
    })) ?? [];

  const selectedRow = rows.find((x) => x.id === selectedRowId);

  function onFilterSubmit(arr: any) {
    flushSync(() => {
      setFilters(arr);
      pagination.goToPage(1);
    });
  }

  return (
    <Layout.Root>
      <Layout.Content>
        <h1>{t("species")}</h1>
        <GraphqlError title="Error loading Species" errors={error} />

        {adminAlertMsg.alertIsVisible ? (
          <Alert
            type={adminAlertMsg.pageAlertType!}
            content={adminAlertMsg.pageAlertMsg!}
            onClose={adminAlertMsg.close}
            timeOut={5000}
          />
        ) : null}

        <SectionCard
          header={
            <div className="flex justify-between align-start">
              <h2>{t("list_of_species")}</h2>
              <div className="flex gap-md">
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.species.create}
                >
                  <CreateSpeciesModalButton
                    refetchQueries={["SpeciesForAdminSpecies"]}
                    onCompleted={(data) => {
                      data?.createSpecies &&
                        adminAlertMsg.onCreateSuccess(
                          data?.createSpecies?.name,
                          data?.createSpecies?.name
                        );
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.species.update}
                >
                  <EditSpeciesModalButton
                    speciesId={selectedRowId}
                    refetchQueries={["SpeciesForAdminSpecies"]}
                    defaultValues={{
                      name: selectedRow?.name ?? "",
                    }}
                    onCompleted={(data) => {
                      data?.renameSpecies &&
                        adminAlertMsg.onEditSuccess(
                          data?.renameSpecies?.name,
                          data?.renameSpecies?.name
                        );
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.species.deactivateReactivate
                  }
                >
                  {!filters.showInactive ? (
                    <AdminArchiveModal
                      onArchive={onArchive}
                      modalTitle={t("archive_a_species")}
                      warningText={t("archive_species_warning")}
                      selectedItemId={selectedRowId}
                    />
                  ) : null}
                </RenderWhenAuthorized>
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.species.deactivateReactivate
                  }
                >
                  {filters.showInactive &&
                  (!selectedRow || selectedRow?.status === "inactive") ? (
                    <AdminUnarchiveModal
                      onUnarchive={onUnarchive}
                      modalTitle={t("unarchive_a_species")}
                      warningText={t("unarchive_species_warning")}
                      selectedItemId={selectedRowId}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            </div>
          }
        >
          {/*FILTERS:*/}
          <div className="flex justify-between flex-wrap mrgn-bttm-sm">
            <AdminPageFilterForm onSubmit={onFilterSubmit} />
            <div className="flex-auto flex-col gap-sm align-end">
              <div>
                <PageSizeSelect
                  pageSize={pagination.pageSize}
                  onChangePageSize={pagination.setPageSize}
                />
              </div>
              <div className="font-size-16 justify-end pb-3">
                {pagination.makeShowingString(
                  data?.speciesList?.pagination?.totalCount
                )}
              </div>
            </div>
          </div>

          {/*RESULTS:*/}
          {loading ? (
            <LoadingIndicator centered className="mrgn-bttm-md" />
          ) : !rows || rows?.length === 0 ? (
            <NoResults centered />
          ) : (
            <>
              <AdminTable
                aria-label="Species table" /*TODO: i18n*/
                selectionMode="single"
                showSelectionCheckboxes
                onSelectionChange={onSelectionChange as any}
                selectedKeys={[selectedRowId]}
                columns={columns}
                rows={rows}
              />

              <Pagination
                {...pagination.paginationComponentProps}
                totalPages={data?.speciesList?.pagination?.totalPages ?? 0}
              />
            </>
          )}
        </SectionCard>
      </Layout.Content>
    </Layout.Root>
  );
};

export default AdminSpecies;
