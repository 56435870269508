import EditLink from "components/atoms/EditLink";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import {
  Assessment,
  AssessmentStatusChange,
  WsStatus,
} from "generated/gql-types";
import React from "react";
import { useTranslation } from "react-i18next";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

export interface WildlifeSpeciesStatusAndCriteriaCardProps {
  header: string;
  open: boolean;
  editLinkTo: string;
  assessment?: Assessment;
  editDisabled: boolean;
}

export const WildlifeSpeciesStatusAndCriteriaCard: React.FC<
  WildlifeSpeciesStatusAndCriteriaCardProps
> = (props) => {
  const {
    assessment,
    editLinkTo,
    header,
    open = false    
  } = props;

  const { t } = useTranslation();

  // Show or hide the Extinct or Extirpated comments (US 4745).
  const showExtinctExtirpatedComments =
    assessment?.statusAndCriteria?.status === WsStatus.Extinct ||
    assessment?.statusAndCriteria?.status === WsStatus.Extirpated;

  const showTooltipAlertForWsStatus =
    assessment?.statusAndCriteria?.status == null ||
    assessment?.statusAndCriteria?.status === WsStatus.NotInitialized;

  const showTooltipAlertForAssessmentStatusChange =
    assessment?.statusAndCriteria?.statusChange == null ||
    assessment?.statusAndCriteria?.statusChange ===
      AssessmentStatusChange.NotInitialized;

  return (
    <SectionCard
      open={open}
      header={
        <div className="flex justify-between">
          <h2 className="mrgn-tp-sm">
            {t("wildlife_species_status_and_criteria")}
          </h2>
          <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.cosewic.update}>
            <EditLink
              linkTo={editLinkTo}
              state={header}
              disabled={props.editDisabled}
            />
          </RenderWhenAuthorized>
        </div>
      }
    >
      <div className="species-field">
        <dl className="row">
          <div className="species-data col-md-6">
            <dt className="text-muted">
              {t("cosewic_ws_status")}
              <TooltipAlert isVisible={showTooltipAlertForWsStatus}>
                {t("required_to_make_as_assessed_tooltip")}
              </TooltipAlert>
            </dt>
            <dd>
              {assessment?.statusAndCriteria?.status &&
              assessment.statusAndCriteria.status !==
                WsStatus.NotInitialized ? (
                t(assessment?.statusAndCriteria?.status)
              ) : (
                <MissingData />
              )}
            </dd>
          </div>

          <div className="species-data col-md-6">
            <dt className="text-muted">
              {t("type_of_status_change")}
              <TooltipAlert
                isVisible={showTooltipAlertForAssessmentStatusChange}
              >
                {t("required_to_make_as_assessed_tooltip")}
              </TooltipAlert>
            </dt>
            <dd>
              {assessment?.statusAndCriteria?.statusChange &&
              assessment.statusAndCriteria.statusChange !==
                AssessmentStatusChange.NotInitialized ? (
                t(assessment?.statusAndCriteria?.statusChange)
              ) : (
                <MissingData />
              )}
            </dd>
          </div>
        </dl>

        {showExtinctExtirpatedComments ? (
          <>
            <dl className="row">
              <div className="species-data col-md-6">
                <dt className="text-muted">
                  {t("extinct_or_extirpated_since")}, {t("english")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    assessment?.statusAndCriteria?.statusComment?.english?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        assessment?.statusAndCriteria?.statusComment?.english
                          ?.text!
                      }
                    />
                  )}
                </dd>
              </div>
              <div className="species-data col-md-6">
                <dt className="text-muted">
                  {t("extinct_or_extirpated_since")}, {t("french")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    assessment?.statusAndCriteria?.statusComment?.french?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        assessment?.statusAndCriteria?.statusComment?.french
                          ?.text!
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </>
        ) : null}
        <dl className="row">
          <div className="species-data col-md-6">
            <dt className="text-muted">{t("status_criteria_english")}</dt>
            <dd>
              {htmlIsNullOrEmpty(
                assessment?.statusAndCriteria?.statusCriteria?.english?.text
              ) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml
                  htmlString={
                    assessment?.statusAndCriteria?.statusCriteria?.english
                      ?.text!
                  }
                />
              )}
            </dd>
          </div>

          <div className="species-data col-md-6">
            <dt className="text-muted">{t("status_criteria_french")}</dt>
            <dd>
              {htmlIsNullOrEmpty(
                assessment?.statusAndCriteria?.statusCriteria?.french?.text
              ) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml
                  htmlString={
                    assessment?.statusAndCriteria?.statusCriteria?.french?.text!
                  }
                />
              )}
            </dd>
          </div>
        </dl>

        <h3 className="font-size-18">{t("applicability_of_criteria")}</h3>

        <div className="flex-col">
          {["A", "B", "C", "D", "E"].map((key: string) => {
            const valueForKey =
              assessment?.statusAndCriteria?.applicabilityOfCriteria?.find(
                (x) => x?.key === key
              )?.value;

            return (
              <div className="flex mrgn-tp-sm" key={key}>
                <div>
                  <div className="circle-letter mrgn-rght-md">{key}</div>
                </div>
                {htmlIsNullOrEmpty(valueForKey?.text) ? (
                  <MissingData />
                ) : (
                  <p className="font-size-18">
                    <SafeRenderHtml htmlString={valueForKey?.text ?? ""} />
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </SectionCard>
  );
};
export default WildlifeSpeciesStatusAndCriteriaCard;
