import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Land,
  LandType,
  Maybe,
  ProvinceAndTerritory,
} from "../../../../generated/gql-types";
import { bilingualTextNameForLanguage } from "../../../../mappers";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import MissingData from "../../../atoms/MissingData";
import { LandNameAutoCompleteWithController } from "../../admin/lands/LandNameAutocomplete/LandNameAutocomplete";

export interface ListingLandAssignLandSectionProps {
  defaultValues?: Land;
  onChange: (newLand?: Maybe<Land>) => void;
}

export interface ListingLandAssignLandSectionFields {
  landType?: Maybe<LandType>;
  provinceAndTerritory?: Maybe<ProvinceAndTerritory>;
  land?: Maybe<Land>;
}

export const ListingLandAssignLandSection: React.FC<
  ListingLandAssignLandSectionProps
> = (props) => {
  const { t, i18n } = useTranslation();

  const { register, watch, control, setValue } =
    useForm<ListingLandAssignLandSectionFields>({
      defaultValues: {
        landType: props?.defaultValues?.landType,
        provinceAndTerritory: props?.defaultValues?.provinceAndTerritory,
        land: props?.defaultValues,
      },
    });

  // Resetting search when land type or province/territory changes
  const landType = watch("landType");
  const provinceAndTerritory = watch("provinceAndTerritory");
  const shouldRenderLandName =
    landType != null &&
    provinceAndTerritory != null &&
    provinceAndTerritory !== ("" as any); // TODO: write a test for this: landName should not render when provinceAndTerritory dropdown is set to "select a value" , where value=""

  // Only update the value if it doesn't match the default values
  useEffect(() => {
    if (
      landType !== props.defaultValues?.landType ||
      provinceAndTerritory !== props.defaultValues?.provinceAndTerritory
    ) {
      setValue("land", undefined);
    }
  }, [landType, provinceAndTerritory]);

  // Emitting the new value when it changes
  const land = watch("land");
  useEffect(() => {
    if (land?.id === props.defaultValues?.id) {
      // This if check avoids calling onChange on initial render,
      // which would mark the form as dirty and
      // trigger the "are you sure you want to leave" dialog
      // before the form page even loads.
      return;
    }

    props.onChange(land);
  }, [land]);

  const onClear = () => {
    setValue("land", undefined);
    props.onChange(undefined);
  };

  return (
    <div className="form-group flex-col gap-md">
      <fieldset className="chkbxrdio-grp">
        <legend className="required">
          <span className="field-name">{t("land_type")}</span>{" "}
          <strong className="required">({t("required")})</strong>
        </legend>
        <label htmlFor="rdo_federal" className="radio-inline">
          <input
            type="radio"
            value={LandType.Federal}
            defaultChecked={props?.defaultValues?.landType === LandType.Federal}
            id="rdo_federal"
            data-rule-required="true"
            {...register("landType", { required: true })}
          />
          &#160;&#160;{t(LandType.Federal)}
        </label>
        <label htmlFor="rdo_provincial_territorial" className="radio-inline">
          <input
            type="radio"
            value={LandType.ProvinceTerritory}
            defaultChecked={
              props?.defaultValues?.landType === LandType.ProvinceTerritory
            }
            id="rdo_provincial_territorial"
            {...register("landType", { required: true })}
          />
          &#160;&#160;{t(LandType.ProvinceTerritory)}
        </label>
        <label htmlFor="rdo_private" className="radio-inline">
          <input
            type="radio"
            value={LandType.Private}
            defaultChecked={props?.defaultValues?.landType === LandType.Private}
            id="rdo_private"
            {...register("landType", { required: true })}
          />
          &#160;&#160;{t(LandType.Private)}
        </label>
      </fieldset>

      <div>
        <label htmlFor="provinceTerritoryInput" className="required">
          {t("province_territory")}
          <strong className="required"> ({t("required")})</strong>
        </label>
        <select
          id="sel_province_territory"
          className="form-control"
          {...register("provinceAndTerritory", { required: true })}
        >
          <option hidden value={""}>
            {t("please_select_option")}
          </option>
          <option value={ProvinceAndTerritory.Alberta}>
            {t(ProvinceAndTerritory.Alberta)}
          </option>
          <option value={ProvinceAndTerritory.BritishColumbia}>
            {t(ProvinceAndTerritory.BritishColumbia)}
          </option>
          <option value={ProvinceAndTerritory.Manitoba}>
            {t(ProvinceAndTerritory.Manitoba)}
          </option>
          <option value={ProvinceAndTerritory.NewBrunswick}>
            {t(ProvinceAndTerritory.NewBrunswick)}
          </option>
          <option value={ProvinceAndTerritory.NewfoundlandAndLabrador}>
            {t(ProvinceAndTerritory.NewfoundlandAndLabrador)}
          </option>
          <option value={ProvinceAndTerritory.NorthwestTerritories}>
            {t(ProvinceAndTerritory.NorthwestTerritories)}
          </option>
          <option value={ProvinceAndTerritory.NovaScotia}>
            {t(ProvinceAndTerritory.NovaScotia)}
          </option>
          <option value={ProvinceAndTerritory.Nunavut}>
            {t(ProvinceAndTerritory.Nunavut)}
          </option>
          <option value={ProvinceAndTerritory.Ontario}>
            {t(ProvinceAndTerritory.Ontario)}
          </option>
          <option value={ProvinceAndTerritory.PrinceEdwardIsland}>
            {t(ProvinceAndTerritory.PrinceEdwardIsland)}
          </option>
          <option value={ProvinceAndTerritory.Quebec}>
            {t(ProvinceAndTerritory.Quebec)}
          </option>
          <option value={ProvinceAndTerritory.Saskatchewan}>
            {t(ProvinceAndTerritory.Saskatchewan)}
          </option>
          <option value={ProvinceAndTerritory.Yukon}>
            {t(ProvinceAndTerritory.Yukon)}
          </option>
          <option value={ProvinceAndTerritory.CanadianWaters}>
            {t(ProvinceAndTerritory.CanadianWaters)}
          </option>
        </select>
      </div>

      {shouldRenderLandName ? (
        <div className="flex-col gap-sm">
          <LandNameAutoCompleteWithController
            label={t("place_name")}
            isRequired
            control={control}
            name="land"
            landType={watch("landType")!}
            provinceAndTerritory={watch("provinceAndTerritory")!}
            id={"landSearchInput"}
            onClear={onClear}
          />
        </div>
      ) : null}

      {watch("land") == null ? null : (
        <div className="species-field">
          <h3>{watch("land")?.landName}</h3>
          <div className="row">
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{`${t("land_type")}`}</dt>
                <dd>
                  {isNullOrEmpty(land?.landType) ? (
                    <MissingData />
                  ) : (
                    t(land?.landType ?? "")
                  )}
                </dd>
              </div>
            </dl>
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("province_territory")}</dt>
                <dd>
                  {isNullOrEmpty(land?.provinceAndTerritory) ? (
                    <MissingData />
                  ) : (
                    t(land?.provinceAndTerritory ?? "")
                  )}
                </dd>
              </div>
            </dl>
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("dfrp")}</dt>
                <dd>
                  {isNullOrEmpty(land?.dfrp) ? <MissingData /> : land?.dfrp}
                </dd>
              </div>
            </dl>
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("land_manager")}</dt>
                <dd>
                  {isNullOrEmpty(land?.landManager) ? (
                    <MissingData />
                  ) : (
                    bilingualTextNameForLanguage(
                      i18n.language,
                      land?.landManager
                    )
                  )}
                </dd>
              </div>
            </dl>
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("land_size")} (ha)</dt>
                <dd>
                  {isNullOrEmpty(land?.landSize) ? (
                    <MissingData />
                  ) : (
                    land?.landSize
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingLandAssignLandSection;
