import LoadingIndicator from "components/atoms/LoadingIndicator";
import { useOverviewContext } from "features/overview";
import {
  CosewicWsState,
  CosewicWsStatus,
  ListingWsState,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import { GalleryPathHelpers } from "pages/gallery/galleryRouter";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import { OverviewPathHelpers } from "pages/overview/OverviewRouter";
import { PublicProfilePathHelpers } from "pages/publicProfile/PublicProfileRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, matchPath, useRouteMatch } from "react-router-dom";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../../features/auth/components";
import RenderWhenFeatureIsEnabled from "../../../features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "../../../features/featureFlags/config";

export const SideNav: React.FC = (props) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const overviewContext = useOverviewContext();

  const isCosewicCreatePath = !!matchPath(path, "/cosewic/create");
  const isListingCreatePath = !!matchPath(path, "/listing/create");

  const isCosewicPath = !!matchPath(path, "/cosewic/:cosewicWsId");
  const isListingPath = !!matchPath(path, "/listing/:listingWsId");
  const isOverviewPath = !!matchPath(path, "/overview/");

  const isCosewicPermanentAndPublished =
    overviewContext.cosewic?.status === CosewicWsStatus.Permanent &&
    overviewContext.cosewic?.state === CosewicWsState.Live;

  const isStandaloneListingAndPublished =
    overviewContext.cosewic?.id == null &&
    overviewContext.listing?.state === ListingWsState.Published;

  const cosewicId = overviewContext.cosewic?.id;
  const listingId = overviewContext.listing?.id;

  return (
    <div>
      <h2 id="wb-sec-h" className="wb-inv">
        Section Menu
      </h2>
      <section className="list-group menu list-unstyled">
        <h3>{t("Navigation")}</h3>
        {overviewContext.loading ? (
          <LoadingIndicator centered className="mrgn-tp-md" />
        ) : (
          !overviewContext.someErrors && (
            <ul className="list-group menu list-unstyled">
              {/* ---------- Overview ---------- */}
              {overviewContext?.overviewReference != null ? (
                <li>
                  <NavLink
                    to={OverviewPathHelpers.SpeciesInformation(
                      overviewContext.overviewReference?.name!,
                      overviewContext.overviewReference?.referenceId!
                    )}
                    className="list-group-item"
                    activeClassName=""
                    data-testid="side-nav-overview"
                  >
                    {t("overview")}
                  </NavLink>
                  <ul className="list-group menu list-unstyled">
                    {isOverviewPath && (
                      <>
                        <li>
                          <NavLink
                            exact
                            to={OverviewPathHelpers.SpeciesInformation(
                              overviewContext.overviewReference?.name!,
                              overviewContext.overviewReference?.referenceId!
                            )}
                            className="list-group-item"
                            activeClassName="wb-navcurr"
                            data-testid="side-nav-overview-information"
                          >
                            {t("wildlife_species_information")}
                          </NavLink>
                        </li>
                        <li>
                          <RenderWhenFeatureIsEnabled
                            flagKey={FeatureFlagKeys.DocumentManager}
                          >
                            <NavLink
                              to={OverviewPathHelpers.Documents(
                                overviewContext.overviewReference?.name!,
                                overviewContext.overviewReference?.referenceId!
                              )}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-documents"
                            >
                              {t("documents")}
                            </NavLink>
                          </RenderWhenFeatureIsEnabled>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              ) : null}
              {/* ---------- Public profile ---------- */}
              {overviewContext?.overviewReference != null &&
              (isCosewicPermanentAndPublished ||
                isStandaloneListingAndPublished) ? (
                <li>
                  <NavLink
                    to={PublicProfilePathHelpers.PublicProfileByReference(
                      overviewContext.overviewReference?.name!,
                      overviewContext.overviewReference?.referenceId!
                    )}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-public-profile"
                  >
                    {t("public_profile")}
                  </NavLink>
                </li>
              ) : null}
              {/* ---------- Gallery ---------- */}
              <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
                {overviewContext?.overviewReference != null ? (
                  <li>
                    <NavLink
                      to={GalleryPathHelpers.GalleryByReference(
                        overviewContext.overviewReference?.name!,
                        overviewContext.overviewReference?.referenceId!
                      )}
                      className="list-group-item"
                      activeClassName="wb-navcurr"
                      data-testid="side-nav-public-profile"
                    >
                      {t("gallery")}
                    </NavLink>
                  </li>
                ) : null}
              </RenderWhenFeatureIsEnabled>
              {/* ---------- COSEWIC ---------- */}
              <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.cosewic.view}>
                {cosewicId != null ? (
                  <li>
                    <NavLink
                      to={CosewicPathHelpers.CosewicProfile(cosewicId)}
                      className="list-group-item"
                      activeClassName=""
                      data-testid="side-nav-cosewic"
                    >
                      {t("cosewic")}
                    </NavLink>
                    <ul className="list-group menu list-unstyled">
                      {isCosewicPath && (
                        <>
                          <li>
                            <NavLink
                              exact
                              to={CosewicPathHelpers.CosewicProfile(cosewicId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-cosewic-information"
                            >
                              {t("cosewic_information")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={CosewicPathHelpers.RelatedSpecies(cosewicId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-cosewic-related-species"
                            >
                              {t("related_species")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={CosewicPathHelpers.ChangeLog(cosewicId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-cosewic-change-log"
                            >
                              {t("change_log")}
                            </NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                ) : (
                  <>
                    {isCosewicCreatePath && (
                      <li>
                        <NavLink
                          exact
                          to={CosewicPathHelpers.Home}
                          className="list-group-item"
                          activeClassName=""
                          data-testid="side-nav-cosewic-home"
                        >
                          {t("cosewic_home")}
                        </NavLink>
                      </li>
                    )}
                    {isCosewicCreatePath && (
                      <li>
                        <NavLink
                          to={CosewicPathHelpers.CosewicCreate}
                          className="list-group-item"
                          activeClassName=""
                          data-testid="side-nav-cosewic"
                        >
                          {t("cosewic")}
                        </NavLink>
                        <ul className="list-group menu list-unstyled">
                          <li>
                            <NavLink
                              to={CosewicPathHelpers.CosewicCreate}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-add-cosewic-ws"
                            >
                              {t("add_cosewic_wildlife_species")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    )}
                  </>
                )}
              </RenderWhenAuthorized>
              {/* ---------- Listing ---------- */}
              <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.listing.view}>
                {listingId != null ? (
                  <li>
                    <NavLink
                      to={ListingPathHelpers.ListingProfile(listingId)}
                      className="list-group-item"
                      activeClassName=""
                      data-testid="side-nav-listing"
                    >
                      {t("listing")}
                    </NavLink>
                    <ul className="list-group menu list-unstyled">
                      {isListingPath && (
                        <>
                          <li>
                            <NavLink
                              exact
                              to={ListingPathHelpers.ListingProfile(listingId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-listing-information"
                            >
                              {t("listing_information")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              // exact
                              to={ListingPathHelpers.ListingProcesses(
                                listingId
                              )}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-listing-processes"
                            >
                              {t("listing_processes")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={ListingPathHelpers.ListingSectors(listingId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-sectors"
                            >
                              {t("sectors")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={ListingPathHelpers.ListingLands(listingId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-lands"
                            >
                              {t("lands")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={ListingPathHelpers.ListingRelatedSpecies(
                                listingId
                              )}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-listing-related-species"
                            >
                              {t("related_species")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={ListingPathHelpers.ChangeLog(listingId)}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-listing-change-log"
                            >
                              {t("change_log")}
                            </NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                ) : (
                  <>
                    {isListingCreatePath && (
                      <li>
                        <NavLink
                          exact
                          to={ListingPathHelpers.Home}
                          className="list-group-item"
                          activeClassName=""
                          data-testid="side-nav-listing-home"
                        >
                          {t("listing_home")}
                        </NavLink>
                      </li>
                    )}
                    {isListingCreatePath && (
                      <li>
                        <NavLink
                          to={ListingPathHelpers.ListingCreate}
                          className="list-group-item"
                          activeClassName=""
                          data-testid="side-nav-listing"
                        >
                          {t("listing")}
                        </NavLink>
                        <ul className="list-group menu list-unstyled">
                          <li>
                            <NavLink
                              to={ListingPathHelpers.ListingCreate}
                              className="list-group-item"
                              activeClassName="wb-navcurr"
                              data-testid="side-nav-add-listing-ws"
                            >
                              {t("add_listing_wildlife_species")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    )}
                  </>
                )}
              </RenderWhenAuthorized>
              {/* ---------- Recovery ---------- */}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to={"#"}*/}
              {/*    className="list-group-item hide"*/}
              {/*    activeClassName=""*/}
              {/*  >*/}
              {/*    Recovery*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*/!* ---------- Protection ---------- *!/*/}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to={"#"}*/}
              {/*    className="list-group-item hide"*/}
              {/*    activeClassName=""*/}
              {/*  >*/}
              {/*    Protection*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
            </ul>
          )
        )}
      </section>
    </div>
  );
};

export default SideNav;
