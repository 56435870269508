import { useMutation } from "@tanstack/react-query";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/OneColumn";
import { postQuery } from "components/organisms/search/queryTool/fetch";
import QueryToolForm, {
  QueryToolFormFields,
} from "components/organisms/search/queryTool/QueryToolForm/QueryToolForm";
import { SharingSettings } from "components/organisms/search/queryTool/QueryToolForm/SettingsSection/SettingsSection";
import { QueryToolAPIDomainModel } from "components/organisms/search/queryTool/types";
import { GlobalAlert } from "features/globalAlert";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SearchPathHelpers } from "../SearchRouter";

const QueryToolCreate: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const saveQueryMutation = useMutation(["queryTool", "saveQuery"], postQuery, {
    onSuccess: () => {
      history.push({
        pathname: SearchPathHelpers.QueryTool(),
      });
    },
    onError: () => {
      console.error("Query save failed!");
    },
  });

  const onSubmit: SubmitHandler<Partial<QueryToolAPIDomainModel>> = async (
    domainData
  ) => {
    saveQueryMutation.mutate(domainData);
  };

  const onCancel = () => {
    history.push({
      pathname: SearchPathHelpers.QueryTool(),
    });
  };

  const initialFormFields: QueryToolFormFields = {
    nameInEnglish: "",
    nameInFrench: "",
    sharingSettings: SharingSettings.Private,
    sharingWith: [],
    conditionRules: { combinator: "and", rules: [], not: false },
    setFixedProperty: "",
    cosewicItems: [],
    listingItems: [],
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("create_query")}</h1>

          {/* TODO: Better error handling */}
          {saveQueryMutation.isLoading && (
            <LoadingIndicator centered className="mrgn-bttm-md" />
          )}
          {saveQueryMutation.isError && (
            <Alert
              type={AlertTypes.DANGER}
              title="Error saving query"
              content={(saveQueryMutation.error as any)?.message}
            />
          )}
          <QueryToolForm
            onSubmit={onSubmit}
            initialValues={initialFormFields}
            onCancel={onCancel}
          />
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default QueryToolCreate;
