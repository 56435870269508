import { useQuery } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { CopyToClipboardButtonWithTooltip } from "components/atoms/Tooltip/CopyToClipboardButtonWithTooltip";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { DistributionListMemberEmailsDocument } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import timeoutPromise from "util/timeoutPromise";

export interface EmailsPopupProps {
  modalState: OverlayTriggerState;
  distributionListId: string;
}

const EmailsPopup: React.FC<EmailsPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;

  // The mailto function currently has a limit of around 2000 (English) characters and
  // any greater than this will prevent the message from being opened in Outlook.
  const MAX_CHARS_MAILTO_ALLOWED = 1800;

  const [openingOutlook, setOpeningOutlook] = React.useState(false);

  const { loading, error, data, refetch } = useQuery(
    DistributionListMemberEmailsDocument,
    {
      errorPolicy: "all",
      variables: {
        distributionListMemberEmailsId: props.distributionListId,
      },
      fetchPolicy: "network-only",
    }
  );

  const emailListArray = data?.distributionListMemberEmails?.memberEmails;
  const emailsText = emailListArray?.join("; ") ?? "";
  const isOpenInOutlookDisabled = emailsText?.length > MAX_CHARS_MAILTO_ALLOWED;

  React.useEffect(() => {
    if (modalState.isOpen) {
      refetch();
    }
  }, [modalState.isOpen, refetch]);

  const onClose = () => {
    modalState.close();
  };

  const onOpenInOutlook = async () => {
    setOpeningOutlook(true);
    window.open("mailto:" + emailsText.replace(/ /g, ""));
    await timeoutPromise(5000);
    setOpeningOutlook(false);
    modalState.close();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("export_emails_for_Outlook")}
        isOpen
        onClose={onClose}
        isDismissable
        showModalFooter={false}
      >
        {<GraphqlError title="Error fetching email list" errors={error} />}
        {loading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : openingOutlook ? (
          <LoadingIndicator
            centered
            className="mrgn-bttm-md"
            message="Opening Outlook"
            visible={true}
          />
        ) : (
          <>
            {isOpenInOutlookDisabled && (
              <Alert
                type={AlertTypes.WARNING}
                content={t("Outlook_button_disabled_warning", {
                  number: MAX_CHARS_MAILTO_ALLOWED,
                })}
              />
            )}

            {/*---------------Email List---------------------*/}
            <div className="form-group">
              <label htmlFor="emails">{t("email_list")}</label>
              <textarea
                id="emails"
                name="emails"
                value={emailsText}
                disabled={true}
                style={{ width: "100%", height: "400px", resize: "vertical" }}
              />
            </div>
            <hr className="mrgn-tp-0 mrgn-bttm-md" />

            {/*--------------- Copy to clipboard button ---------------------*/}
            <CopyToClipboardButtonWithTooltip textToClipboard={emailsText} />

            {/*--------------- Open in Outlook button ---------------------*/}
            <button
              type="button"
              className="btn btn-default btn-sm mrgn-rght-sm"
              data-testid="button-open-in-outlook"
              onClick={onOpenInOutlook}
              disabled={isOpenInOutlookDisabled}
            >
              {t("open_in_outlook")}
            </button>
          </>
        )}
      </ModalDialog>
    </OverlayContainer>
  );
};

export const useEmailsModal = useOverlayTriggerState;

export default EmailsPopup;
