import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import rehypeStringify from "rehype-stringify";
import remark from "remark";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import remarkStringify from "remark-stringify";
import sanitizeHtml from "sanitize-html";
import stripMarkdown from "strip-markdown";
import unified from "unified";

export const markdownToHtml = (markdown?: string) =>
  new Promise<string>((resolve, reject) => {
    if (markdown == null || markdown.length === 0) {
      return resolve("");
    }

    unified()
      .use(remarkParse)
      .use(remarkRehype)
      .use(rehypeStringify)
      .process(markdown)
      .then((file) => resolve(String(file).trim()))
      .catch(reject);
  });

export const htmlToMarkdown = (html: string) =>
  new Promise<string>((resolve, reject) => {
    unified()
      .use(rehypeParse)
      .use(rehypeRemark)
      .use(remarkStringify)
      .process(html)
      .then((file) =>
        resolve(
          String(file)
            .trim()
            .replace(/([\n]*[\\\\]{1})*$/g, "") // Right trim newlines.
        )
      )
      .catch(reject);
  });

export const markdownToPlaintext = (markdown: string) =>
  new Promise<string>((resolve, reject) => {
    remark()
      .use(stripMarkdown)
      .process(markdown, function (err: any, file: any) {
        if (err) return reject(err);
        resolve(String(file).trim());
      });
  });

export const htmlToPlainText = (html: string) => {
  // NOTE: this must only ever run client-side!
  const temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent ?? temp.innerText ?? undefined;
};

export const sanitizeAndTrimHtml = (str: string) => {
  return sanitizeHtml(str, {
    allowedTags: [
      "p",
      "b",
      "i",
      "em",
      "strong",
      "a",
      "u",
      "s",
      "ol",
      "li",
      "sup",
      "sub",
    ],
    allowedAttributes: {
      a: ["href"],
    },
    exclusiveFilter: function (frame: any) {
      // remove empty tags
      return !frame.text.trim();
    },
  })
    .replace(/\r\n/g, "\n") // normalize newlines
    .replace(/\s\s+/g, " ") // collapse duplicate whitespace
    .replace(/(?<=^<p>)\s*\n*\s*/g, "") // leading spaces/newlines/spaces, ONLY in outer p tags
    .replace(/\s+\n*?\s*(?=<\/p>$)/g, "") // trailing spaces/newlines/spaces, ONLY in outer p tags
    .replace(/\u00a0/g, " "); // \u00a0 is nbsp; // from quill src https://github.com/quilljs/quill/blob/6fb1532fbdcfb2d5df4830a81e707160a72da47b/modules/clipboard.ts#L563
};
