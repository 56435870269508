import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "util/isNullOrEmpty";
import SectionCard from "../../../atoms/SectionCard";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import { CompetentMinisterCheckboxListWithController } from "../CompetentMinisterCheckboxList/CompetentMinisterCheckboxList";
import { CompetentMinisterDropdownWithController } from "../CompetentMinisterDropdown/CompetentMinisterDropdown";
import { ListingFormChangeTracking, ListingFormFields } from "./index";

export interface LegalStatusSectionProps {
  initialValues: ListingFormFields;
  open: boolean;
}

export const LegalStatusSection: React.FC<LegalStatusSectionProps> = (
  props
) => {
  const { initialValues, open } = props;
  const { t } = useTranslation();
  const { control, watch } = useFormContext<
    ListingFormFields & ListingFormChangeTracking
  >();

  // HACK: 11/29/22, needed to fix TASK 37769; ideally gets replaced
  watch("legalStatus.competentMinister1");

  return (
    <SectionCard collapsible open={open} header={<h2>{t("sara_status")}</h2>}>
      <div className="flex-col gap-md">
        {/* COMPETENT MINISTER 1 */}
        <div>
          <label htmlFor="competentMinister1Input">
            {t("competent_minister")} 1
          </label>
          <div>
            <CompetentMinisterDropdownWithController
              control={control}
              name="legalStatus.competentMinister1"
              id="competentMinister1Input"
              placeholder={t("select_a_minister")}
              defaultValue={
                initialValues?.legalStatus?.competentMinister1.id ?? ""
              }
            />
          </div>
        </div>

        {/* COMPETENT MINISTER 2 */}
        {isNullOrEmpty(watch("legalStatus.competentMinister1.id")) ? null : (
          <div>
            <fieldset className="chkbxrdio-grp">
              <legend>
                <span className="field-name">{t("competent_minister")} 2</span>
              </legend>
              <CompetentMinisterCheckboxListWithController
                name="legalStatus.competentMinister2"
                control={control}
                defaultChecked={[]}
              />
            </fieldset>
          </div>
        )}

        {/* SARA STATUS HISTORY ENGLISH */}
        <div>
          <label htmlFor="listingHistoryEnglishInput">
            {t("sara_status_history_english")}
          </label>

          <FullHTMLEditorWithController
            control={control}
            name="legalStatus.listingHistory.english.text"
            defaultValue={
              initialValues.legalStatus.listingHistory.english?.text ?? ""
            }
            id="listingHistoryEnglishInput"
            maxLength={300}
          />
        </div>

        {/* SARA STATUS HISTORY FRENCH */}
        <div>
          <label htmlFor="listingHistoryFrenchInput">
            {t("sara_status_history_french")}
          </label>

          <FullHTMLEditorWithController
            control={control}
            name="legalStatus.listingHistory.french.text"
            defaultValue={
              initialValues.legalStatus.listingHistory.french?.text ?? ""
            }
            id="listingHistoryFrenchInput"
            maxLength={300} // TODO: test max length
          />
        </div>
      </div>
    </SectionCard>
  );
};
