import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForTaxonomicGroupDocument,
  BilingualName,
  TaxonomicGroup,
} from "generated/gql-types";
import QueryAutocomplete from "components/organisms/QueryAutocomplete/QueryAutocomplete";
import formatFullOrShortBilingualName from "util/autocompletes/formatFullOrShortBilingualName";

interface TaxonomicGroupAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newTaxonomicGroup?: null | BilingualName) => void;
  initialTaxonomicGroup?: BilingualName;
  label: string | ReactElement;
}

const TaxonomicGroupAutocomplete: React.FC<TaxonomicGroupAutocompleteProps> = (
  props
) => {
  const onSelectionChange = React.useCallback(
    (item: TaxonomicGroup) => {
      // Convert to bilingualName for Create/Update APIs;
      //  the taxgrp admin service returns us a TaxonomicGroup (FullBilingualName format),
      //  but the COSEWIC API POST/PATCH both expect a BilingualName.
      if (item == null) {
        props.onSelectionChange(undefined);
        return;
      }

      const itemAsBilingualName: DefinitelyGQL<BilingualName> = {
        id: item.id,
        nameEn: item.nameEnglish ?? "",
        nameFr: item.nameFrench ?? "",
      };

      props.onSelectionChange(itemAsBilingualName);
    },
    [props.onSelectionChange]
  );

  return (
    <QueryAutocomplete
      id={"taxonomicGroupAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForTaxonomicGroupDocument}
      listDisplayFormatter={formatFullOrShortBilingualName}
      onSelectionChange={onSelectionChange}
      placeholder={formatFullOrShortBilingualName(props.initialTaxonomicGroup)}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
    />
  );
};
export default TaxonomicGroupAutocomplete;
