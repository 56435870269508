import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { BptTemplatePhase, Maybe } from "generated/gql-types";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";

export interface PhaseInformationProps {
  templateId?: string;
  phaseInfo?: Maybe<BptTemplatePhase>;
}

export const PhaseInformation: React.FC<PhaseInformationProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="species-field font-size-18">
        {/* ------------ Phase description ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-8">
              <dt className="text-muted">{t("description")}</dt>
              <dd>
                {props.phaseInfo?.description == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.phaseInfo?.description.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>

          <dl>
            <div>
              {/* ------------ Phase estimated duration ------------ */}
              <div className="species-data col-sm-4">
                <dt className="text-muted">
                  {t("calculated_duration")}
                  {props.phaseInfo?.calculatedDuration == null ? (
                    <TooltipAlert isVisible={true}>
                      {t("can_not_be_calculated_tooltip")}
                    </TooltipAlert>
                  ) : (
                    ""
                  )}
                </dt>
                <dd>
                  {props.phaseInfo?.calculatedDuration == null ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        props.phaseInfo?.calculatedDuration +
                        " " +
                        t("business_days")
                      }
                    />
                  )}
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default PhaseInformation;
