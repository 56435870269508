import * as React from "react";

export interface MultiSelectListItemProps {
  id?: string;
  name?: string;
  onRemove: () => void;
  disabled?: boolean;
}

export const MultiSelectListItem: React.FC<MultiSelectListItemProps> = (
  props
) => {
  const testid = `multi-select-list-item-${props.name}`;
  return (
    <li
      className="multi-select-list-item flex justify-between align-center"
      data-id={props.id}
      data-testid={testid}
      aria-disabled={props.disabled}
    >
      {props.name ?? "-"}
      <button
        type="button"
        title="Remove"
        className="btn btn-link p-0 hover-grey"
        data-testid={"button-remove-" + testid}
        onClick={props.onRemove}
        disabled={props.disabled}
      >
        <i className="fas fa-times font-size-14 color-danger"></i>
      </button>
    </li>
  );
};

export default MultiSelectListItem;
