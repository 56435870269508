import { useApolloClient } from "@apollo/client";
import { AsyncListData, useAsyncList } from "@react-stately/data";
import { SEARCH_DEBOUNCE_TIME_MS } from "config/constants";
import {
  BilingualAbbreviationAcronymLookupText,
  FederalCanadaPlacesListDocument,
} from "generated/gql-types";
import pDebounce from "p-debounce";
import * as React from "react";
import { formatPageNumber } from "util/formatPageNumber";

export interface UseFederalCanadaPlacesAutocomplete {
  list: AsyncListData<BilingualAbbreviationAcronymLookupText>;
  clear: () => void;
}

export const useFederalCanadaPlacesAutocomplete =
  (): UseFederalCanadaPlacesAutocomplete => {
    const client = useApolloClient();
    const debouncedSearch = React.useMemo(() => {
      return pDebounce((cursor: number, filterText: string) => {
        return client.query({
          query: FederalCanadaPlacesListDocument,
          variables: {
            params: {
              pageNumber: formatPageNumber(cursor),
              pageSize: 50,
              search: filterText,
              searchType: "contains",
              isCaseSensitive: false,
            },
          },
        });
      }, SEARCH_DEBOUNCE_TIME_MS);
    }, [client]);

    const list = useAsyncList<BilingualAbbreviationAcronymLookupText>({
      async load({ cursor, filterText }: any) {
        // check for -1 to halt fetching the next page
        if (cursor === "-1") return { items: [], cursor: "-1" };

        const results = await debouncedSearch(cursor, filterText);
        const items = (results?.data?.federalCanadaPlacesList?.lookups ??
          []) as Iterable<BilingualAbbreviationAcronymLookupText>;

        if (
          results?.data?.federalCanadaPlacesList?.pagination?.hasNextPage ===
          false
        ) {
          return {
            items, // return the final set of items
            cursor: "-1", // Use -1 to halt fetching
          };
        }

        const newPageNumber =
          formatPageNumber(
            results?.data?.federalCanadaPlacesList?.pagination?.currentPage
          ) + 1;

        return {
          items,
          cursor: newPageNumber.toString(),
        };
      },
    });

    const clear = () => {
      list.setFilterText("");
      list.setSelectedKeys(new Set());
    };

    return {
      list,
      clear,
    };
  };

export default useFederalCanadaPlacesAutocomplete;
