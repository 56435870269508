import { AsyncListData, useAsyncList } from "@react-stately/data";
import {
  ListingWs,
  ListingWsAutocompleteListDocument,
  ListingWsState,
} from "generated/gql-types";
import { useApolloClient } from "@apollo/client";
import * as React from "react";
import pDebounce from "p-debounce";
import { SEARCH_DEBOUNCE_TIME_MS } from "config/constants";
import { formatPageNumber } from "util/formatPageNumber";

export interface UseListingWsAutocomplete {
  list: AsyncListData<ListingWs>;
  clear: () => void;
}

export const useListingWsAutocomplete = (): UseListingWsAutocomplete => {
  const client = useApolloClient();
  const debouncedSearch = React.useMemo(() => {
    return pDebounce((cursor: number, filterText: string) => {
      return client.query({
        query: ListingWsAutocompleteListDocument,
        variables: {
          params: {
            pageNumber: formatPageNumber(cursor),
            pageSize: 50,
            searchTerms: filterText,
            state: ListingWsState.Published
          },
        },
      });
    }, SEARCH_DEBOUNCE_TIME_MS);
  }, [client]);

  const list = useAsyncList<ListingWs>({
    async load({ cursor, filterText }: any) {
      // check for -1 to halt fetching the next page
      if (cursor === "-1") return { items: [], cursor: "-1" };

      const results = await debouncedSearch(cursor, filterText);
      const items = (results?.data?.listingWsList?.listingWs ??
        []) as Iterable<ListingWs>;

      if (results?.data?.listingWsList?.pagination?.hasNextPage === false) {
        return {
          items, // return the final set of items
          cursor: "-1", // Use -1 to halt fetching
        };
      }

      const newPageNumber =
        formatPageNumber(
          results?.data?.listingWsList?.pagination?.currentPage
        ) + 1;

      return {
        items,
        cursor: newPageNumber.toString(),
      };
    },
  });

  const clear = () => {
    list.setFilterText("");
    list.setSelectedKeys(new Set());
  };

  return {
    list,
    clear,
  };
};

export default useListingWsAutocomplete;
