import ResetIcon from "assets/svg/reset-icon.svg";
import { ContactTag } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface QueryFields {
  search: string;
  showInactive: boolean;
  tags?: string;
}

export const defaultQueryFields = {
  search: "",
  showInactive: false,
  tags: "",
};

export interface ContactsAdminPageFilterFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const ContactsAdminPageFilterForm: React.FC<
  ContactsAdminPageFilterFormProps
> = (props) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, handleSubmit, setFocus, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    form.setValue("showInactive", false);
    form.setValue("tags", "");
    setFocus("search");
  };

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    const mapped = newValues;

    // Don't send a tags filter if the default value (empty) is selected in the dropdown
    if (mapped.tags === "") delete mapped["tags"];

    props.onSubmit(mapped);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-col">
        <div className="flex align-start flex-wrap">
          <div className="flex-col">
            <div className="flex gap-sm align-start flex-wrap">
              <div className="input-group">
                <label className="wb-inv" htmlFor="adminSearch">
                  {t("search")}
                </label>
                <input
                  type="text"
                  id="adminSearch"
                  className="form-control search-box"
                  placeholder={t("search")}
                  {...register("search")}
                />

                <button
                  type="button"
                  aria-label="Clear search"
                  className="search-box-reset"
                  hidden={watch("search") === ""}
                  onClick={onClearSearch}
                  data-testid="button-clear"
                >
                  <img src={ResetIcon} alt={t("reset")} />
                </button>
              </div>
              <div className="mrgn-rght-sm">
                <select
                  id="sel_tag"
                  className="form-control"
                  {...register("tags")}
                >
                  <option value="">{t("select_a_tag")}</option>
                  <option value="anyTags">{t("any_tags")}</option>
                  <option value="noTags">{t("no_tags")}</option>
                  <option className="divider" disabled></option>
                  <option value={ContactTag.ConsulteeIndigenous}>
                    {t(ContactTag.ConsulteeIndigenous)}
                  </option>
                  <option value={ContactTag.ConsulteeExpert}>
                    {t(ContactTag.ConsulteeExpert)}
                  </option>
                  <option value={ContactTag.ConsulteeCitizen}>
                    {t(ContactTag.ConsulteeCitizen)}
                  </option>
                  <option value={ContactTag.Education}>
                    {t(ContactTag.Education)}
                  </option>
                  <option value={ContactTag.Photographer}>
                    {t(ContactTag.Photographer)}
                  </option>
                  <option value={ContactTag.Scientist}>
                    {t(ContactTag.Scientist)}
                  </option>
                </select>
              </div>
            </div>
            <div className="checkbox">
              <label htmlFor="showInactiveCheckbox">
                <input
                  type="checkbox"
                  id="showInactiveCheckbox"
                  {...register("showInactive")}
                />
                &nbsp;
                {t("show_archived_only")}
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-bttm-md"
            data-testid="button-search"
          >
            <span className="glyphicon glyphicon-search mrgn-rght-sm" />
            {t("search")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactsAdminPageFilterForm;
