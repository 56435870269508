import { ApolloError } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddPhotosForm, { PhotosFields } from "../PhotosForm/AddPhotosForm";

export interface AddPhotosPopupProps {
  modalState: OverlayTriggerState;
  photoLicenseId: string;
  defaultValues?: PhotosFields;
  refetchQuery: () => void;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const AddPhotosPopup: React.FC<AddPhotosPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, photoLicenseId, defaultValues, refetchQuery } = props;

  const onClose = () => {
    modalState.close();
    refetchQuery();
    // reset();
  };

  if (!modalState.isOpen) return null;
  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_photos")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        {/* <GraphqlError title="" errors={error} /> */}
        {/* {loading && <LoadingIndicator centered />} */}

        <AddPhotosForm
          photoLicenseId={photoLicenseId}
          defaultValues={defaultValues}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddPhotosPopup;

export const useAddPhotosPopup = useOverlayTriggerState;
export type { PhotosFields };
