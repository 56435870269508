import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, ControllerProps, useForm } from "react-hook-form";
import { NAICSSectorDropdownWithController } from "../NAICSSectorDropdown";
import { NAICSSublevelDropdownWithController } from "../NAICSSublevelDropdown";
import { Maybe, NaicsResource } from "../../../../generated/gql-types";
import { Control } from "react-hook-form/dist/types";

export interface NAICSCodeLookupProps {
  onChange: (resource: Maybe<NaicsResource>) => void;
  // NOTE(Mar 2022): This component doesn't currently accept default values. It's a
  // challenge to get default values working along with these progressively loaded dropdowns,
  // and we don't currently have the time to continue research on a solution.
  // defaultValue?: never;
}

export interface NAICSCodeFormFields {
  sector?: NaicsResource;
  subsector?: NaicsResource;
  industryGroup?: NaicsResource;
  industry?: NaicsResource;
  canadianIndustry?: NaicsResource;
}

export const NAICSCodeLookupForm: React.FC<NAICSCodeLookupProps> = (props) => {
  const form = useForm<NAICSCodeFormFields>();
  const { watch, control, setValue } = form;
  const { t } = useTranslation();

  const sector = watch("sector");
  const subsector = sector && watch("subsector");
  const industryGroup = subsector && watch("industryGroup");
  const industry = industryGroup && watch("industry");
  const canadianIndustry = industry && watch("canadianIndustry");

  const deepestSelected =
    canadianIndustry ?? industry ?? industryGroup ?? subsector ?? sector;

  // Call onChange when the deepest selected value changes
  useEffect(() => {
    if (deepestSelected != null) {
      props.onChange(deepestSelected);
    }
  }, [deepestSelected]);

  // Each dropdown should be reset if its parent level changes
  useEffect(() => setValue("subsector", undefined), [sector?.code]);
  useEffect(() => setValue("industryGroup", undefined), [subsector?.code]);
  useEffect(() => setValue("industry", undefined), [industryGroup?.code]);
  useEffect(() => setValue("canadianIndustry", undefined), [industry?.code]);

  return (
    <div className="flex-col gap-md">
      <NAICSSectorDropdownWithController control={control} name="sector" />

      {/* subsector searches for: top level // outputs: 3 digit code */}
      {sector == null || sector?.code == null ? null : (
        <NAICSSublevelDropdownWithController
          control={control}
          name="subsector"
          label={t("subsector")}
          key={sector.code}
          searchCode={sector.code}
        />
      )}

      {/* industry group searches for: 3 digit code, from subsector // outputs: 4 digit code */}
      {subsector == null || subsector?.code == null ? null : (
        <NAICSSublevelDropdownWithController
          control={control}
          name="industryGroup"
          label={t("industry_groups")}
          key={subsector.code}
          searchCode={subsector.code}
        />
      )}

      {/* industry searches for: 4 digit code, from industry group // outputs: 5 digit code */}
      {/* outputs 5 digit code */}
      {industryGroup == null || industryGroup?.code == null ? null : (
        <NAICSSublevelDropdownWithController
          control={control}
          name="industry"
          label={t("industry")}
          key={industryGroup.code}
          searchCode={industryGroup.code}
        />
      )}

      {/* canadian industry searches for: 5 digit code, from industry // outputs: 6 digit code */}
      {industry == null || industry?.code == null ? null : (
        <NAICSSublevelDropdownWithController
          control={control}
          name="canadianIndustry"
          label={t("canadian_industry")}
          key={industry.code}
          searchCode={industry.code}
        />
      )}
    </div>
  );
};

export default NAICSCodeLookupForm;

export interface NAICSCodeLookupWithControllerProps<TFieldValues>
  extends Omit<NAICSCodeLookupProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const NAICSCodeLookupWithController = <TFieldValues,>(
  props: NAICSCodeLookupWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <NAICSCodeLookupForm onChange={onChange} />
      )}
    />
  );
};
