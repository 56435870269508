import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  BilingualLookupText,
  ConsultationPathLookupDocument,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface ConsultationPathDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any;
  disabled?: boolean;
  onChange: (value: BilingualLookupText) => void;
}

export const ConsultationPathDropdown = React.forwardRef<
  HTMLSelectElement,
  ConsultationPathDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(ConsultationPathLookupDocument);
  const { t, i18n } = useTranslation();

  //if (loading) return <LoadingIndicator />;

  //
  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.listingWsProcessConsultationPath?.find(
      (x) => x?.id === id
    );

    if (!value) {
      console.error(
        `ConsultationPathDropdown couldn't find a value for ID ${id}`
      );
      return;
    }
    props.onChange(value);
  };

  return (
    <>
      {loading ? <LoadingIndicator className="mrgn-lft-sm" /> : null}
      <select
        className="form-control"
        value={props?.defaultValue ?? ""}
        ref={ref}
        {...props}
        data-testid={props.id}
        onChange={onChange}
        disabled={props.disabled}
      >
        <option value={""}>
          {t(props.placeholder ?? "please_select_option")}
        </option>
        {data?.listingWsProcessConsultationPath?.map((x) => {
          return (
            <option key={x?.id} value={x?.id ?? "error - missing id"}>
              {bilingualLookupTextNameForLanguage(i18n.language, x)}
            </option>
          );
        })}
      </select>
    </>
  );
});

export interface ConsultationPathDropdownWithControllerProps<TFieldValues>
  extends Omit<ConsultationPathDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const ConsultationPathDropdownWithController = <TFieldValues,>(
  props: ConsultationPathDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <ConsultationPathDropdown
          id={props.id}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={onChange}
          defaultValue={(value as any)?.id ?? ""}
        />
      )}
    />
  );
};

export default ConsultationPathDropdown;
