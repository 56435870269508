import { useQuery } from "@tanstack/react-query";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import BasicSearchCheckboxListDetails from "components/molecules/search/BasicSearchCheckboxListDetails";
import FilterTags from "components/organisms/search/FilterTags/FilterTags";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import { GlobalAlert } from "features/globalAlert";
import { useBasicSearchQuery } from "features/search/hooks/useBasicSearchQuery";
import { LAND_INDEX } from "features/search/listingLands/constants";
import listingLandsFacets from "features/search/listingLands/listingLandsFacets.json";
import odataMappers from "features/search/listingLands/listingLandsOdataMappers";
import { fetchFilters } from "features/search/utils/fetchFilters";
import { makeFilterString } from "features/search/utils/makeFilterString";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicSearchFacetMultiSelectList } from "../../../components/organisms/search/Autocomplete/BasicSearchFacetMultiSelectList";
import { makePaging } from "../../../features/search/utils/makePaging";
import useBasicSearch from "../hooks/useBasicSearch";

interface ListingLandsSearchPageColumns {
  id: string;
  "Land/LandName": string;
  "Land/LandType": string;
  "Land/LandManager/English": string;
  "Land/LandManager/French": string;
  "Land/ProvinceAndTerritory": string;
  "ListingLandsRef/Count": string; // NOTE: not a real facet in cognitive search
}

const searchFields =
  "Land/LandName, Land/LandType, Land/LandManager, Land/ProvinceAndTerritory, ListingLandsRef";
const queryType = "full";
const select =
  "Id, Land/LandId, Land/LandName, Land/LandType, Land/LandManager, Land/ProvinceAndTerritory, ListingLandsRef";

const ListingLandsSearchPage: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);

  const basicSearch = useBasicSearch("basicsearch_listing_lands");

  const filtersQuery = useQuery(["basicSearch", "filters"], () => {
    return fetchFilters(listingLandsFacets, LAND_INDEX);
  });

  const { data, loading, error, newRunSearchQuery } =
    useBasicSearchQuery(LAND_INDEX);

  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const [showAllFilters, setShowAllFilters] = React.useState(true);

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      setSortDescriptor({
        column: "Land/LandName",
        direction: "ascending",
      });
    }
    return makeOrderByString(sortDescriptor);
  }, [sortDescriptor]);

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      const filterString = makeFilterString(odataMappers, basicSearch.state);
      const paging = makePaging(pagination);

      return {
        filter: filterString,
        queryType,
        searchFields,
        select,
        orderby: orderByString,
        count: true,
        ...paging,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      newRunSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  useEffect(
    function autoResetPageNumber() {
      const newPageNumber = 1;
      pagination.goToPage(newPageNumber);
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      i18n.language,
    ]
  );

  useEffect(
    function resetLandManagerFilterWhenLanguageChanges() {
      // When the language switches to French, clear filters for the English facet, and vice-versa.
      const newLanguage = i18n.language;
      const facetToRemove =
        newLanguage === "fr"
          ? "Land/LandManager/English"
          : "Land/LandManager/French";

      basicSearch.dispatch({
        type: "delete_checkbox_filter_by_facet",
        filter: {
          facet: facetToRemove,
        },
      });
    },
    [i18n.language]
  );

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const columns: Array<{
    name: string;
    key: keyof ListingLandsSearchPageColumns;
    sortable: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("place_name"),
        key: "Land/LandName",
        sortable: true,
        hasLink: true,
      },
      {
        name: t("land_type"),
        key: "Land/LandType",
        sortable: true,
      },
      {
        name: t("land_manager"),
        key:
          i18n.language === "fr"
            ? "Land/LandManager/French"
            : "Land/LandManager/English",
        sortable: true,
      },
      {
        name: t("province_territory"),
        key: "Land/ProvinceAndTerritory",
        sortable: true,
      },
      {
        name: t("wildlife_species"),
        key: "ListingLandsRef/Count",
        sortable: false,
      },
    ],
    [i18n.language]
  );

  const rows: Array<ListingLandsSearchPageColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let landManagerName = "";
        if (i18n.language === "fr") {
          landManagerName = value?.Land?.LandManager?.French;
        } else {
          landManagerName = value?.Land?.LandManager?.English;
        }
        return {
          _href: `/search/listingLands/speciesByLand?landId=${value?.Land?.LandId}&landName=${value?.Land?.LandName}`,
          id: value?.Land?.LandId,
          "Land/LandName": value?.Land?.LandName,
          "Land/LandType": t(value?.Land?.LandType),
          [i18n.language === "fr"
            ? "Land/LandManager/French"
            : "Land/LandManager/English"]: landManagerName,
          "Land/ProvinceAndTerritory": t(value?.Land?.ProvinceAndTerritory),
          "ListingLandsRef/Count": value?.ListingLandsRef.length,
        };
      }),
    [data?.value]
  );

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("listing_lands_search")}</h1>
          <div className="row">
            <div className="col-md-3">
              <SectionCard
                header={<h2 className="mrgn-tp-0">{t("search_filters")}</h2>}
                id="search-filters"
                showLine={false}
              >
                <div className="pt-0 separator-line-top"></div>
                <button
                  type="button"
                  className="btn btn-link px-2 py-2"
                  onClick={() => setShowAllFilters(!showAllFilters)}
                  data-testid="button-show-all-listing-lands"
                >
                  {!showAllFilters ? t("show_all") : t("hide_all")}
                </button>
                <BasicSearchCheckboxListDetails
                  allFacets={allFacets}
                  basicSearch={basicSearch}
                  facetName={"Land/LandType"}
                  header={t("land_type")}
                  open={showAllFilters}
                  translateItems
                />
                <BasicSearchCheckboxListDetails
                  allFacets={allFacets}
                  basicSearch={basicSearch}
                  facetName={"Land/ProvinceAndTerritory"}
                  header={t("land_province_territory")}
                  open={showAllFilters}
                  translateItems
                />
                <hr className="mrgn-tp-0 mrgn-bttm-md" />
                <BasicSearchFacetMultiSelectList
                  id="land_name_autocomplete"
                  label={t("place_name")}
                  facetName={"Land/LandName"}
                  basicSearch={basicSearch}
                />

                {i18n.language === "fr" ? (
                  <BasicSearchFacetMultiSelectList
                    id="land_manager_autocomplete"
                    label={t("land_manager")}
                    facetName={"Land/LandManager/French"}
                    basicSearch={basicSearch}
                  />
                ) : (
                  <BasicSearchFacetMultiSelectList
                    id="land_manager_autocomplete"
                    label={t("land_manager")}
                    facetName={"Land/LandManager/English"}
                    basicSearch={basicSearch}
                  />
                )}

                <BasicSearchFacetMultiSelectList
                  id="dfrp_autocomplete"
                  label={t("dfrp")}
                  facetName="Land/Dfrp"
                  basicSearch={basicSearch}
                />
              </SectionCard>
            </div>
            <div className="col-md-9">
              <SectionCard
                header={
                  <div className="flex justify-between align-start">
                    <h2>{t("lands_search_results")}</h2>
                  </div>
                }
              >
                <>
                  <FilterTags onReset={onResetTags} basicSearch={basicSearch} />

                  {/*RESULTS:*/}
                  {loading ? (
                    <LoadingIndicator centered className="mrgn-bttm-md" />
                  ) : rows && rows?.length === 0 ? (
                    <NoResults centered />
                  ) : (
                    <>
                      <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                        <div className="font-size-18">
                          {pagination.makeShowingString(
                            data ? data["@odata.count"] : 0
                          )}
                        </div>
                        <div>
                          <PageSizeSelect
                            pageSize={pagination.pageSize}
                            onChangePageSize={onChangePageSize}
                          />
                        </div>
                      </div>
                      {!loading && error ? (
                        <pre>{JSON.stringify(error)}</pre>
                      ) : null}
                      {rows == null || rows.length === 0 ? null : (
                        <ResultsTable
                          rows={rows}
                          columns={columns}
                          sortable
                          sortDescriptor={sortDescriptor}
                          onSortChange={onSortChange as any}
                        />
                      )}

                      <Pagination
                        {...pagination.paginationComponentProps}
                        onPageSelected={onPageSelected}
                        totalPages={
                          data && data["@odata.count"] && pagination.pageSize
                            ? Math.ceil(
                                data["@odata.count"] / pagination.pageSize
                              )
                            : 0
                        }
                      />
                    </>
                  )}
                </>
              </SectionCard>
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default ListingLandsSearchPage;
