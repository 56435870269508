import cc from "classcat";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import { SearchPathHelpers } from "pages/search/SearchRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

const Index: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Layout.Root>
        <Layout.HomeContent>
          <div className={cc(["provisional bg-cover", styles.hero_bg_img])}>
            <div className={cc(["container", styles.hero_container])}>
              <div className="well header-rwd brdr-0 brdr-rds-0 text-white bg-gctheme opct-80">
                <h1
                  property="name"
                  id="wb-cont"
                  className="h2 mrgn-tp-lg mrgn-bttm-lg"
                >
                  {t("cosewic_home")}
                </h1>
              </div>
            </div>
          </div>
          <div className="home experimental">
            <div className="gc-srvinfo container mrgn-tp-lg">
              <div className="row">
                <div className="col-md-6">
                  <SectionCard
                    header={
                      <div className="flex justify-between align-start">
                        <h2>{t("cosewic_wildlife_species")}</h2>
                      </div>
                    }
                    showLine={false}
                    className="pb-0"
                  >
                    {/* ------------- Search wild life Species ------------- */}
                    <div className="list-item separator-line px-3 py-2_5">
                      <Link
                        to={CosewicPathHelpers.CosewicSearch}
                        className="flex justify-between align-center text-decoration-none"
                      >
                        <span className="font-size-18">
                          {t("search_wildlife_species")}
                        </span>
                        <i className="fas fa-angle-right"></i>
                      </Link>
                    </div>

                    {/* ------------- Add a new wild life Species ------------- */}
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.cosewic.create}
                    >
                      <div className="list-item separator-line px-3 py-2_5">
                        <Link
                          to={CosewicPathHelpers.CosewicCreate}
                          className="flex justify-between align-center text-decoration-none"
                        >
                          <span className="font-size-18">
                            {t("add_a_new_wildlife_species")}
                          </span>
                          <i className="fas fa-angle-right"></i>
                        </Link>
                      </div>
                    </RenderWhenAuthorized>

                    {/* ------------- search assessment ------------- */}
                    <div className="list-item separator-line px-3 py-2_5">
                      <Link
                        to={SearchPathHelpers.Assessment()}
                        className="flex justify-between align-center text-decoration-none"
                      >
                        <span className="font-size-18">
                          {t("search_assessments")}
                        </span>
                        <i className="fas fa-angle-right"></i>
                      </Link>
                    </div>
                  </SectionCard>
                </div>

                {/* ------------- Communications ------------- 
                <div className="col-md-6">
                  <SectionCard
                    header={
                      <div className="flex justify-between">
                        <h2>{t("communications")}</h2>
                      </div>
                    }
                    showLine={false}
                    className="pb-0"
                  >
                   
                    <div className="list-item separator-line px-3 py-2_5">
                      <Link
                        to={CosewicPathHelpers.CosewicManageDistributionList}
                        className="flex justify-between align-center text-decoration-none"
                      >
                        <span className="font-size-18">
                          {t("manage_distribution_lists")}
                        </span>
                        <i className="fas fa-angle-right"></i>
                      </Link>
                    </div>

                    
                    <div className="list-item separator-line px-3 py-2_5">
                      <Link
                        to={CosewicPathHelpers.CosewicManageNotification}
                        className="flex justify-between align-center text-decoration-none"
                      >
                        <span className="font-size-18">
                          {t("manage_notifications")}
                        </span>
                        <i className="fas fa-angle-right"></i>
                      </Link>
                    </div>
                  </SectionCard>
                </div>
              */}
              </div>
            </div>
          </div>
        </Layout.HomeContent>
      </Layout.Root>
    </>
  );
};

export default Index;
