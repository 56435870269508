import SectionCard from "components/atoms/SectionCard";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import AddRemoveContactButtonAndModal from "components/organisms/contacts/AddContactButtonAndModal/AddRemoveContactButtonAndModal";
import { MAX_LENGTH_OF_500, MAX_LENGTH_OF_NAME } from "config/constants";
import { PhotoLicenseType } from "generated/gql-types";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { htmlToPlainText } from "util/richtext";
import { PhotoLicenseDocumentFormFields } from "./PhotoLicenseDocumentForm";

export interface PhotoLicenseDocumentInformationCardProps {
  initialValues: Partial<PhotoLicenseDocumentFormFields>;
}

export const PhotoLicenseDocumentInformationCard: React.FC<
  PhotoLicenseDocumentInformationCardProps
> = (props) => {
  const { initialValues } = props;
  const { t, i18n } = useTranslation();

  const form = useFormContext<PhotoLicenseDocumentFormFields>();
  const { control, register, formState, watch, getValues, trigger } = form;
  const { errors } = formState;

  const validateAtLeastOneTitle = () =>
    htmlToPlainText(getValues("title.english.text") ?? "") !== "" ||
    htmlToPlainText(getValues("title.french.text") ?? "") !== "";

  const validateHtmlNameMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_NAME;

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_500;

  const onRemoveContactOrOrg = () => {
    form.setValue("photographer", null, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <SectionCard header={<h2>{t("photo_licence_document_information")}</h2>}>
      {/* -------------- Document name -------------- */}
      <div className="form-group">
        <fieldset className="fieldset-grp">
          <legend className="required">
            <span className="field-name">{t("document_name")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>
          <div className="border-light">
            {/* -------------- English -------------- */}
            <div className="form-group">
              <label htmlFor="documentTitleEnglishRichText">
                {t("english")}
              </label>
              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  validate: {
                    atLeastOneName: validateAtLeastOneTitle,
                    maxLengthLimit: validateHtmlTextMaxLengthLimit,
                  },
                  onChange: () => trigger("title"),
                }}
                name="title.english.text"
                id="documentTitleEnglishRichText"
                defaultValue={initialValues.title?.english?.text ?? ""}
                placeholder={t("enter_some_text")}
              />
              {errors.title?.english &&
                (errors.title?.english as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_NAME,
                    })}
                  </FieldValidationError>
                )}
            </div>

            {/* -------------- French -------------- */}
            <div className="form-group">
              <label htmlFor="documentTitleFrenchRichText">{t("french")}</label>
              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  validate: {
                    atLeastOneName: validateAtLeastOneTitle,
                    maxLengthLimit: validateHtmlTextMaxLengthLimit,
                  },
                  onChange: () => trigger("title"),
                }}
                name="title.french.text"
                id="documentTitleFrenchRichText"
                defaultValue={initialValues.title?.french?.text ?? ""}
                placeholder={t("enter_some_text")}
              />
              {errors.title?.french &&
                (errors.title?.french as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_NAME,
                    })}
                  </FieldValidationError>
                )}
            </div>
          </div>
        </fieldset>
        {errors.title?.english &&
          (errors.title?.english as any)?.text?.type === "atLeastOneName" &&
          errors.title?.french &&
          (errors.title?.french as any)?.text?.type === "atLeastOneName" && (
            <FieldValidationError>
              {t("at_least_one_language_is_required")}
            </FieldValidationError>
          )}
      </div>

      {/* -------------- License type -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp">
          <legend className="required">
            <span className="field-name">{t("licence_type")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>
          {Object.values(PhotoLicenseType).map((value) => {
            return (
              <label
                htmlFor={`radio-license-type-${value}`}
                className="radio-inline"
                key={value}
              >
                <input
                  type="radio"
                  value={value}
                  id={`radio-license-type-${value}`}
                  {...register("licenseType", {
                    required: true,
                  })}
                />
                &#160;{t(value)}
              </label>
            );
          })}
        </fieldset>
        {errors.licenseType && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Photographer -------------- */}
      <div className="form-group">
        <label htmlFor="ContactInput" className="required">
          {t("photographer")}
          <strong className="required"> ({t("required")})</strong>
        </label>
        <Controller
          name="photographer"
          control={control}
          rules={{ validate: (value) => value != null }}
          render={({ field: { value, onChange } }) => {
            return (
              <AddRemoveContactButtonAndModal
                id={"ContactInput"}
                contact={value}
                onSave={(newValue, contactType) => {
                  if (contactType === "contact") {
                    onChange({
                      contactType,
                      contactRef: newValue,
                      organizationRef: null,
                    });
                  } else if (contactType === "organization") {
                    onChange({
                      contactType,
                      contactRef: null,
                      organizationRef: newValue,
                    });
                  }
                  form.trigger("photographer");
                }}
                onRemove={onRemoveContactOrOrg}
                isPhotographer={true}
              />
            );
          }}
        />
        {errors.photographer != null && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Note -------------- */}
      <div className="form-group">
        <label htmlFor="note">{t("note")}</label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            validate: {
              maxLengthLimit: validateHtmlTextMaxLengthLimit,
            },
          }}
          defaultValue={initialValues.note?.text ?? ""}
          id="note"
          name="note.text"
        />
        {/* {errors.note && (
          <FieldValidationError>{errors.note}</FieldValidationError>
        )} */}
        {errors.note && (errors.note as any)?.text?.type === "maxLengthLimit" && (
          <FieldValidationError>
            {t("reach_the_max_length", {
              count: MAX_LENGTH_OF_500,
            })}
          </FieldValidationError>
        )}
      </div>
    </SectionCard>
  );
};
