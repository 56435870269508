import Alert, { AlertTypes } from "components/atoms/Alert";
import SectionCard from "components/atoms/SectionCard";
import { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import useTextInput from "../../../../hooks/util/useTextInput";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface FilterKeywordSectionProps {
  defaultValue?: string;
  onChange: (value: string) => void;
  onSearch: () => void;
  onReset: () => void;
}

export const FilterKeywordSection: React.FC<FilterKeywordSectionProps> = (
  props
) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const keywordTextInput = useTextInput(props.defaultValue);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * If the user runs a 'clear all' operation, the default value prop will arrive as an empty string.
   * Since it's just the DEFAULT value, the field will not automatically update its value to be empty.
   * This effect handles empty string as a special case - one where the field value SHOULD be updated.
   */
  useEffect(
    function setEmptyDefaultValue() {
      if (isNullOrEmpty(props.defaultValue)) {
        keywordTextInput.setValue("");
      }
    },
    [props.defaultValue]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.onChange(keywordTextInput.value);
    }, 800);

    debounceTimeoutRef.current = timeout;

    // cleanup function
    return () => {
      clearTimeout(timeout);
    };
  }, [keywordTextInput.value]);

  const cancelNextDebounce = () => {
    if (debounceTimeoutRef.current == null) {
      return;
    }

    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = null;
  };

  const onSearch = () => {
    // When the search button is clicked, we should call onChange to make sure any parent event listeners get
    // the current text right away; otherwise they will only get the current text after the automatic debounce period.
    flushSync(() => {
      cancelNextDebounce();
      props.onChange(keywordTextInput.value);
      props.onSearch();
    });
  };

  const onReset = () => {
    flushSync(() => {
      keywordTextInput.setValue("");
      props.onReset();
    });
  };

  return (
    <SectionCard
      header={
        <div>
          <div className="flex justify-between align-start">
            <h2>{t("keyword_search")}</h2>
            <button
              type="button"
              className="btn btn-link card-header-button-link"
              onClick={() => setShowInfo(!showInfo)}
              data-testid="button-info"
            >
              <span className="far fa-question-circle mrgn-rght-sm"></span>{" "}
              {t("info")}
            </button>
          </div>

          <div>
            {showInfo && (
              // TODO: i18n
              <Alert type={AlertTypes.INFO} title={t("info_header")}>
                <div className="flex-col pb-0">
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_and")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("and_msg1")}</li>
                      <li>{t("and_msg2")}</li>
                      <li>{t("and_msg3")}</li>
                    </ul>
                  </SectionCard>
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_or")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("or_msg1")}</li>
                      <li>{t("or_msg2")}</li>
                      <li>{t("or_msg3")}</li>
                    </ul>
                  </SectionCard>
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_not")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("not_msg1")}</li>
                      <li>{t("not_msg2")}</li>
                      <li>{t("not_msg3")}</li>
                    </ul>
                  </SectionCard>
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_trunc")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("trunc_msg1")}</li>
                      <li>{t("trunc_msg2")}</li>
                      <li>{t("trunc_msg3")}</li>
                    </ul>
                  </SectionCard>
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_quote")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("quote_msg1")}</li>
                      <li>{t("quote_msg2")}</li>
                      <li>{t("quote_msg3")}</li>
                    </ul>
                  </SectionCard>
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_brackets")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("brackets_msg1")}</li>
                      <li>{t("brackets_msg2")}</li>
                      <li>{t("brackets_msg3")}</li>
                    </ul>
                  </SectionCard>
                  <SectionCard
                    collapsible
                    open={false}
                    header={<h3>{t("use_contain_operator")}</h3>}
                    showLine={false}
                    contentAreaProps={{ className: "font-size-16" }}
                    classNameSummary={"py-2"}
                  >
                    <ul className="mrgn-bttm-0">
                      <li>{t("contain_operator_msg1")}</li>
                      <li>{t("contain_operator_msg2")}</li>
                      <li>{t("contain_operator_msg3")}</li>
                    </ul>
                  </SectionCard>
                </div>
              </Alert>
            )}
          </div>
        </div>
      }
      showHeader={true}
      showLine={false}
      contentAreaProps={{ className: "mrgn-tp-0" }}
    >
      <div className="form-group">
        <label className="wb-inv" htmlFor="search_input">
          {t("keyword_search")}
        </label>
        <input
          type="text"
          className="form-control full-width"
          title={t("enter_a_keyword")}
          placeholder={t("enter_a_keyword")}
          data-testid="search-field"
          id="search_input"
          onKeyDown={(e) => {
            if (e?.code?.toLowerCase() === "enter") {
              onSearch();
            }
          }}
          {...keywordTextInput.props}
        />
      </div>
      <button
        type="button"
        data-testid="search-btn"
        className="btn btn-primary btn-sm mrgn-rght-sm"
        onClick={onSearch}
      >
        <span className="fa fa-search mrgn-rght-sm"></span>
        {t("search")}
      </button>
      <button
        type="button"
        data-testid="reset-btn"
        className="btn btn-default btn-sm mrgn-rght-sm"
        onClick={onReset}
      >
        {t("reset")}
      </button>
    </SectionCard>
  );
};
