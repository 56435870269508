import { useMsal } from "@azure/msal-react";
import { Roles } from "./roles";
import { useCallback, useMemo } from "react";
import { checkIsAuthorized } from "./checkIsAuthorized";

export const useUserAuthorization = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const userRoles = useMemo(() => {
    if (account?.idTokenClaims == null) return [];
    const typedIdTokenClaims = account.idTokenClaims as SARDMTSTokenClaims;
    return typedIdTokenClaims.roles ?? [];
  }, [account?.idTokenClaims]);

  const checkUserIsAuthorized = useCallback(
    (authorizedRoles: Roles[]) => checkIsAuthorized(userRoles, authorizedRoles),
    [userRoles]
  );

  return { userRoles, checkUserIsAuthorized };
};

export interface SARDMTSTokenClaims {
  aud: string; // ID
  exp: number; // Timestamp
  iat: number; // Timestamp
  iss: string; // URL
  name: string;
  nbf: number; // Timestamp
  nonce: string; // ID
  oid: string; // ID
  preferred_username: string;
  rh?: string;
  roles: string[];
  sub: string; // ID
  tid: string; // ID
  uti?: string; // ID
  ver: string;
}
