import * as React from "react";

interface IChangeTrackingContextProps {
  isEnabled: boolean;
}

interface IChangeTrackingContext {
  isEnabled: boolean;
  enable: () => void;
  disable: () => void;
}

const ChangeTrackingContext = React.createContext<
  IChangeTrackingContext | undefined
>(undefined);

const ChangeTrackingProvider: React.FC<IChangeTrackingContextProps> = (
  props
) => {
  const [isEnabled, _setIsEnabled] = React.useState(props.isEnabled);

  const enable = () => _setIsEnabled(true);
  const disable = () => _setIsEnabled(false);

  const api = {
    isEnabled,
    enable,
    disable,
  };

  return (
    <ChangeTrackingContext.Provider value={api}>
      {props.children}
    </ChangeTrackingContext.Provider>
  );
};

const useChangeTrackingContext = () => {
  const context = React.useContext(ChangeTrackingContext);
  if (context === undefined) {
    throw new Error(
      "useChangeTrackingContext must be used within a ChangeTrackingProvider"
    );
  }
  return context;
};

export { ChangeTrackingProvider, useChangeTrackingContext };
