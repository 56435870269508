import Alert, { AlertTypes } from "components/atoms/Alert";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import { useTranslation } from "react-i18next";
import useBasicSearch from "../../../pages/search/hooks/useBasicSearch";
import NoResults from "../../atoms/NoResults";

export interface BasicSearchCheckboxListDetailsProps {
  facetName: string;
  header: string;
  translateItems?: boolean;
  open?: boolean; // whether or not the details section is expanded or not
  allFacets: any;
  basicSearch: ReturnType<typeof useBasicSearch>;
  infoMessage?: string;
  infoTitle?: string;
}

export const BasicSearchCheckboxListDetails: React.FC<
  BasicSearchCheckboxListDetailsProps
> = (props) => {
  const { t } = useTranslation();

  const items: any[] =
    props.allFacets == null ? [] : props.allFacets[props.facetName] ?? [];

  const buildLabelForCheckbox = (filterName: string) => {
    let value = filterName;

    // Special case: Cosewic active status - "YES/NO" should be "Active/Inactive"
    if (props.facetName === "Cosewic/ActiveStatus") {
      if (filterName === "YES") {
        value = t("active");
      }

      if (filterName === "NO") {
        value = t("other");
      }
    }

    // Special case: cosewic state "Inactive" should have a label showing "Not Published" (ticket https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/33379/)
    if (props.facetName === "Cosewic/State" && filterName === "INACTIVE") {
      value = "NOT_PUBLISHED";
    }

    // Special case: listing jurisdiction value "Provincial" should update to a label "Provincial / territorial"
    if (
      (props.facetName === "Listing/LegalProtection/Jurisdiction/NameEn" ||
        props.facetName === "Listing/LegalProtection/Jurisdiction/NameFr") &&
      filterName === "Provincial"
    ) {
      value = t("provincial_territorial");
    }

    // Special case: listing state "NOT_PUBLISHED" should update to a label "Not published (Ghost)"
    if (props.facetName === "Listing/State" && filterName === "NOT_PUBLISHED") {
      value = "Not published (Ghost)";
    }

    if (props.translateItems) {
      return t(value);
    }

    return value;
  };

  const selectedCountInSection = props.basicSearch.getCountForSection(
    props.facetName
  );

  return (
    <>
      <details className="species-details" open={props.open}>
        <summary>
          {props.header}{" "}
          {selectedCountInSection > 0 ? (
            <span className="badge">
              {selectedCountInSection}
              <span className="wb-inv"> options selected</span>
            </span>
          ) : null}
          {props.infoMessage != null ? (
            <span className="info">
              <InfoButtonAndModal title={props.infoTitle}>
                <Alert type={AlertTypes.INFO} content={props.infoMessage} />
              </InfoButtonAndModal>
            </span>
          ) : null}
        </summary>

        <div className="flex-col">
          {items.length === 0 ? (
            <NoResults />
          ) : (
            items
              .sort((a, b) => {
                return buildLabelForCheckbox(a.value).localeCompare(
                  buildLabelForCheckbox(b.value)
                );
              })
              .map((filter) => {
                if (filter.count === 0) return null;
                const name = `${props.facetName}/${filter.value}`;
                const label = buildLabelForCheckbox(filter.value);

                return (
                  <div className="checkbox" key={name}>
                    <div className="flex justify-between">
                      <label>
                        <input
                          type="checkbox"
                          name={name}
                          id={name}
                          data-testid={name}
                          checked={props.basicSearch.isCheckboxSelected(
                            props.facetName,
                            filter.value
                          )}
                          onChange={() => {
                            props.basicSearch.dispatch({
                              type: "toggle_checkbox_filter",
                              filter: {
                                value: filter.value,
                                label: `${props.header} = ${label}`,
                                facet: props.facetName,
                              },
                            });
                          }}
                        />{" "}
                        {label}
                      </label>
                      <span className="badge">{filter.count}</span>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </details>
    </>
  );
};

export default BasicSearchCheckboxListDetails;
