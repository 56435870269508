import { useMutation, useQuery } from "@apollo/client";
import MissingData from "components/atoms/MissingData";
import ContactOrOrgInUseWarning from "components/molecules/admin/ContactOrOrgInUseWarning";
import ConfirmSavePopup, {
  useConfirmSaveModalState,
} from "components/organisms/contacts/ConfirmSavePopup";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";
import GraphqlError from "../../../../components/GraphqlError";
import LoadingIndicator from "../../../../components/atoms/LoadingIndicator";
import Layout from "../../../../components/layouts/TwoColumn";
import AdminSideNav from "../../../../components/molecules/AdminSideNav";
import * as ContactsFormMappers from "../../../../components/organisms/contacts/contactsFormUtil";
import ContactsForm, {
  ContactFormFields,
} from "../../../../components/organisms/contacts/form";
import { useGlobalAlertContext } from "../../../../features/globalAlert";
import {
  ContactForEditDocument,
  ContactOrOrgInUseDocument,
  EditContactDocument,
  OrganizationDocument,
} from "../../../../generated/gql-types";
import { AdminPathHelpers } from "../../AdminRouter";

const ContactsEditPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { contactId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();
  const confirmSaveModalState = useConfirmSaveModalState({});

  const [formDataState, setFormDataState] =
    React.useState<Partial<ContactFormFields>>();

  const { loading, error, data } = useQuery(ContactForEditDocument, {
    variables: {
      id: contactId,
    },
    skip: contactId == null,
    fetchPolicy: "network-only",
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    data: dataContactOrOrgInUse,
    loading: loadingContactOrOrgInUse,
    error: errorContactOrOrgInUse,
  } = useQuery(ContactOrOrgInUseDocument, {
    variables: {
      contactRefId: contactId ?? null,
      orgRefId: null,
    },
    skip: contactId == null,
    fetchPolicy: "network-only",
  });

  // Fetch the contactDetails from Organization
  const {
    data: dataOrganization,
    loading: loadingOrganization,
    error: errorOrganization,
  } = useQuery(OrganizationDocument, {
    variables: {
      organizationId: data?.contact?.organizationDetails?.id!,
    },
    skip:
      contactId == null ||
      isNullOrEmpty(data?.contact?.organizationDetails?.id),
    fetchPolicy: "network-only",
  });

  const [runSubmit, { loading: saving, error: savingError }] =
    useMutation(EditContactDocument);

  const onSubmit: SubmitHandler<Partial<ContactFormFields>> = async (
    formData
  ) => {
    const input = ContactsFormMappers.formIntoUpdateInput(formData);

    try {
      const res = await runSubmit({
        variables: {
          id: contactId,
          contactUpdateInput: input,
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: AdminPathHelpers.Contacts(),
      });

      alertContext.showSuccess({
        message: t("successfully_updated_contact"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onConfirmSaveSubmit = (formData: Partial<ContactFormFields>) => {
    if (!dataContactOrOrgInUse?.contactOrOrgInUse) {
      onSubmit(formData);
    } else {
      setFormDataState(formData);
      confirmSaveModalState.open();
    }
  };

  const orgContactDetails = dataOrganization?.organization?.contactDetails;

  const formDefaultValues = ContactsFormMappers.domainModelIntoForm(
    data?.contact,
    orgContactDetails
  );

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("edit_a_contact")}</h1>
          <GraphqlError title="Error loading Contact" errors={error} />
          <GraphqlError
            title="Error loading ContactOrOrgInUse"
            errors={errorContactOrOrgInUse}
          />
          <GraphqlError
            title="Error loading Organization"
            errors={errorOrganization}
          />
          <GraphqlError title="Error updating Contact" errors={savingError} />
          <ContactOrOrgInUseWarning contactRefId={contactId} />
          {loading || loadingContactOrOrgInUse || loadingOrganization ? (
            <LoadingIndicator />
          ) : !error && !errorContactOrOrgInUse && !errorOrganization ? (
            <>
              <div className="flex flex-wrap font-size-16 text-muted mrgn-bttm-md">
                <div>
                  {t("created_date")}
                  {": "}
                  {formatTimestamp(data?.contact?.createdAt) ?? <MissingData />}
                </div>
                <span className="px-2_5">{"|"}</span>
                <div style={{ maxWidth: 450 }}>
                  {t("created_by")}
                  {": "}
                  {!isNullOrEmpty(data?.contact?.createdBy) ? (
                    data?.contact?.createdBy
                  ) : (
                    <MissingData />
                  )}
                </div>
                <span className="px-2_5">{"|"}</span>
                <div>
                  {t("updated_date")}
                  {": "}
                  {formatTimestamp(data?.contact?.modifiedAt) ?? (
                    <MissingData />
                  )}
                </div>
                <span className="px-2_5">{"|"}</span>
                <div>
                  {t("updated_by")}
                  {": "}
                  {!isNullOrEmpty(data?.contact?.modifiedBy) ? (
                    data?.contact?.modifiedBy
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
              <ContactsForm
                defaultValues={formDefaultValues}
                contactOrOrgInUse={dataContactOrOrgInUse?.contactOrOrgInUse}
                onSubmit={onConfirmSaveSubmit}
                isSaving={saving}
              />
            </>
          ) : null}

          <ConfirmSavePopup
            modalState={confirmSaveModalState}
            onConfirmSave={() => onSubmit(formDataState!)}
          />
        </Layout.Content>

        <Layout.Sidebar>
          <AdminSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ContactsEditPage;
