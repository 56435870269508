import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { TextInput } from "components/atoms/forms/TextInput";
import BilingualToggleContainer from "components/molecules/BilingualToggleContainer";
import DatePicker, {
  formatDateForDatePicker,
} from "components/molecules/DatePicker";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { MAX_LENGTH_OF_500, MAX_LENGTH_OF_NAME } from "config/constants";
import {
  BilingualRichText,
  OverviewWsSpecies,
  RichText,
} from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "util/forms";
import { htmlToPlainText } from "util/richtext";
import { AssociatedSpeciesMultiSelectListWithController } from "../../AssociatedSpeciesMultiSelectList/AssociatedSpeciesMultiSelectList";
import { setNullForEmptyFields } from "./utils";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";

interface PhotoFormProps {
  defaultValues: Partial<PhotoFormFields>;
  photoLicenseId: string;
  onClose: () => void;
  onSubmit: SubmitHandler<PhotoFormFields>;
}

const PhotoForm: React.FC<PhotoFormProps> = (props) => {
  const { t } = useTranslation();

  const form = useForm<PhotoFormFields>({
    defaultValues: props.defaultValues,
  });
  const { control, register, handleSubmit, formState, getValues, trigger } =
    form;
  const { isDirty, errors, isSubmitting, dirtyFields } = formState;

  const onSubmit: SubmitHandler<PhotoFormFields> = async (formData) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, props.defaultValues);
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(FilteredFormData as PhotoFormFields);
        resolve();
      }, 500);
    });
  };

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_500;

  const validateHtmlNameMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_NAME;

  const validateAtLeastOneTitle = () =>
    htmlToPlainText(getValues("title.english.text") ?? "") !== "" ||
    htmlToPlainText(getValues("title.french.text") ?? "") !== "";

  return (
    <>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* -------------- Photo title -------------- */}
        <div className="form-group">
          <fieldset className="fieldset-grp">
            <legend className="required">
              <span className="field-name">{t("photo_name")}</span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>
            <div className="border-light">
              {/* -------------- English -------------- */}
              <div className="form-group">
                <label htmlFor="documentTitleEnglishRichText">
                  {t("english")}
                </label>
                <HTMLItalicsInputWithController
                  control={control}
                  rules={{
                    validate: {
                      atLeastOneName: validateAtLeastOneTitle,
                      maxLengthLimit: validateHtmlNameMaxLengthLimit,
                    },
                    onChange: () => trigger("title"),
                  }}
                  name="title.english.text"
                  id="documentTitleEnglishRichText"
                  defaultValue={props.defaultValues.title?.english?.text ?? ""}
                  placeholder={t("enter_some_text")}
                />
                {errors.title?.english &&
                  (errors.title?.english as any)?.text?.type ===
                    "maxLengthLimit" && (
                    <FieldValidationError>
                      {t("reach_the_max_length", {
                        count: MAX_LENGTH_OF_NAME,
                      })}
                    </FieldValidationError>
                  )}
              </div>

              {/* -------------- French -------------- */}
              <div className="form-group">
                <label htmlFor="documentTitleFrenchRichText">
                  {t("french")}
                </label>
                <HTMLItalicsInputWithController
                  control={control}
                  rules={{
                    validate: {
                      atLeastOneName: validateAtLeastOneTitle,
                      maxLengthLimit: validateHtmlNameMaxLengthLimit,
                    },
                    onChange: () => trigger("title"),
                  }}
                  name="title.french.text"
                  id="documentTitleFrenchRichText"
                  defaultValue={props.defaultValues.title?.french?.text ?? ""}
                  placeholder={t("enter_some_text")}
                />
                {errors.title?.french &&
                  (errors.title?.french as any)?.text?.type ===
                    "maxLengthLimit" && (
                    <FieldValidationError>
                      {t("reach_the_max_length", {
                        count: MAX_LENGTH_OF_NAME,
                      })}
                    </FieldValidationError>
                  )}
              </div>
            </div>
          </fieldset>
          {errors.title?.english &&
            (errors.title?.english as any)?.text?.type === "atLeastOneName" &&
            errors.title?.french &&
            (errors.title?.french as any)?.text?.type === "atLeastOneName" && (
              <FieldValidationError>
                {t("at_least_one_language_is_required")}
              </FieldValidationError>
            )}
        </div>

        <div className="row">
          {/* ------ Date taken ------ */}
          <div className="col-sm-6 form-group">
            <Controller
              control={control}
              name="dateTaken"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={t("date_taken")}
                  value={formatDateForDatePicker(value)}
                  onInput={onChange}
                  id="dateTakenDatePicker"
                  className="min-width-fit-available line-height-normal"
                />
              )}
            />
          </div>
        </div>

        {/* -------------- Photo description -------------- */}
        <BilingualToggleContainer
          className="form-group border-light"
          data-testid="description-rte"
          showBoth={errors.description != null}
          english={
            <div className="form-group">
              <label htmlFor="PhotoDescriptionEnglishRichText">
                {t("photo_description")} ({t("english")})
              </label>
              <FullHTMLEditorWithController
                control={control}
                rules={{
                  required: false,
                  validate: {
                    maxLengthLimit: validateHtmlTextMaxLengthLimit,
                  },
                }}
                defaultValue={
                  props.defaultValues?.description?.english?.text ?? ""
                }
                id="PhotoDescriptionEnglishRichText"
                name="description.english.text"
              />
              {errors.description?.english &&
                (errors.description.english as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_500,
                    })}
                  </FieldValidationError>
                )}
            </div>
          }
          french={
            <div className="form-group">
              <label htmlFor="PhotoDescriptionFrenchRichText">
                {t("photo_description")} ({t("french")})
              </label>
              <FullHTMLEditorWithController
                control={control}
                rules={{
                  required: false,
                  validate: {
                    maxLengthLimit: validateHtmlTextMaxLengthLimit,
                  },
                }}
                defaultValue={
                  props.defaultValues?.description?.french?.text ?? ""
                }
                id="PhotoDescriptionFrenchRichText"
                name="description.french.text"
              />
              {errors.description?.french &&
                (errors.description.french as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_500,
                    })}
                  </FieldValidationError>
                )}
            </div>
          }
        />
        {/* -------------- Associated wildlife species -------------- */}
        <div className="form-group">
          <AssociatedSpeciesMultiSelectListWithController
            control={control}
            defaultValue={props.defaultValues?.photoSpecies}
            name="photoSpecies"
          />
        </div>
        {/* -------------- Photo usage history -------------- */}
        <div className="form-group">
          <label htmlFor="usageHistoryRichText">
            {t("photo_usage_history")}
          </label>
          <FullHTMLEditorWithController
            control={control}
            rules={{
              validate: {
                maxLengthLimit: validateHtmlTextMaxLengthLimit,
              },
            }}
            defaultValue={props.defaultValues?.usageHistory?.text ?? ""}
            id="usageHistoryRichText"
            name="usageHistory.text"
          />

          {errors.usageHistory &&
            (errors.usageHistory as any)?.text?.type === "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_500,
                })}
              </FieldValidationError>
            )}
        </div>

        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default PhotoForm;

export interface PhotoFormFields {
  title: BilingualRichText;
  dateTaken: string;
  description: BilingualRichText;
  photoSpecies: OverviewWsSpecies[];
  usageHistory: RichText;
}
