import { ODataMapper } from "../types";

const listingLandsOdataMappers: Record<string, ODataMapper> = {
  "Land/LandType": {
    key: "Land/LandType",
    filter(searchValues: string[]) {
      return {
        Land: {
          or: searchValues.map((x) => ({ LandType: x })),
        },
      };
    },
  },

  "Land/ProvinceAndTerritory": {
    key: "Land/ProvinceAndTerritory",
    filter(searchValues: string[]) {
      return {
        Land: {
          or: searchValues.map((x) => ({ ProvinceAndTerritory: x })),
        },
      };
    },
  },

  "Land/LandName": {
    key: "Land/LandName",
    filter(searchValues: string[]) {
      return {
        Land: {
          or: searchValues.map((x) => ({ LandName: x })),
        },
      };
    },
  },

  "Land/LandManager/English": {
    key: "Land/LandManager/English",
    filter(searchValues: string[]) {
      return {
        Land: {
          or: searchValues.map((x) => ({ 'LandManager/English': x })),
        },
      };
    },
  },

  "Land/LandManager/French": {
    key: "Land/LandManager/French",
    filter(searchValues: string[]) {
      return {
        Land: {
          or: searchValues.map((x) => ({ 'LandManager/French': x })),
        },
      };
    },
  },

  "Land/Dfrp": {
    key: "Land/Dfrp",
    filter(searchValues: string[]) {
      return {
        Land: {
          or: searchValues.map((x) => ({ Dfrp: x })),
        },
      };
    },
  },
  
};

export default Object.freeze(listingLandsOdataMappers);
