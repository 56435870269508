import { Language, useLanguage, useLngLinks } from "@arcnovus/wet-boew-react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export function useAutoLngLinks() {
  const { currentLanguage } = useLanguage();
  const location = useLocation();

  // We depend on the react-router `location` to memoize new translatedPage links when the user navigates,
  // but we use the `window.location.pathname` to generate the actual link;
  // the react-router `location` doesn't contain the /en or /fr text, since these prefixes are
  // provided to the router's `baseName` prop. The router strips them automatically, but the browser does not.
  // The useMemo can't depend on `window.location.pathname` directly, since it's not part of the react lifecycle.
  const translatedPage = useMemo(() => {
    const otherLanguage =
      currentLanguage === Language.FR ? Language.EN : Language.FR;

    const currentPathLang = `/${currentLanguage}`;
    const otherPathLang = `/${otherLanguage}`;

    return new URL(window.location.href)
      .toString()
      .replace(currentPathLang, otherPathLang) as any;
  }, [location.pathname, currentLanguage]);

  return useLngLinks({ currentLanguage, translatedPage });
}
