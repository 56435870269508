import {
  Maybe,
  ResponseStatement,
  UpdateResponseStatementInput,
} from "generated/gql-types";
import { makeBilingualName } from "mappers";
import {
  DisplayFormat,
  formatTimestamp,
} from "../../../../util/formatTimestamp";
import { ResponseStatementFormFields } from "./ResponseStatementForm";

export const mapDomainModelToForm = (
  domainModel: Maybe<ResponseStatement> | undefined
) => {
  const out: Partial<ResponseStatementFormFields> = {};

  if (domainModel?.dueDate != null) {
    out.dueDate =
      formatTimestamp(domainModel?.dueDate, DisplayFormat.YEAR_MONTH_DAY) ?? "";
  }

  if (domainModel?.scenario != null) {
    out.scenario = {
      id: domainModel?.scenario?.id ?? "",
      name: {
        english: domainModel?.scenario?.name?.english ?? "",
        french: domainModel?.scenario?.name?.french ?? "",
      },
      message: domainModel.scenario.message,
      includeConsultationPeriod:
        domainModel?.scenario?.includeConsultationPeriod ?? false,
    };
  }

  // The Provinces and territories consulted field (checkboxes)
  if (domainModel?.consultedProvincesAndTerritories != null) {
    out.consultedProvincesAndTerritories =
      domainModel.consultedProvincesAndTerritories.map(
        (x) => makeBilingualName(x) ?? []
      );
  }

  // The listingWsRanges is used to pre-populate the Provinces and territories consulted field
  if (domainModel?.referenceData?.listingRef?.ranges != null) {
    out.listingWsRanges = domainModel?.referenceData?.listingRef?.ranges;
  }

  return out;
};

export function mapFormToDomainModel(
  form: Partial<ResponseStatementFormFields>,
  defaultIncludeConsultationPeriod?: boolean | null
) {
  const out: Partial<UpdateResponseStatementInput> = {};

  if (form.dueDate != null) {
    out.dueDate = form.dueDate;
  }

  if (form.scenario != null) {
    out.scenario = {
      id: form.scenario.id,
      name: {
        english: form.scenario.name?.english,
        french: form.scenario.name?.french,
      },

      message: {
        english: { text: form.scenario.message?.english?.text },
        french: { text: form.scenario.message?.french?.text },
      },
      includeConsultationPeriod: form.scenario?.includeConsultationPeriod,
    };
  }

  const includeConsultationPeriod =
    (form?.scenario != null
      ? form?.scenario.includeConsultationPeriod
      : defaultIncludeConsultationPeriod) ?? false;

  if (form?.consultedProvincesAndTerritories != null) {
    if (includeConsultationPeriod) {
      out.consultedProvincesAndTerritories =
        form.consultedProvincesAndTerritories.map(makeBilingualName);
    } else {
      out.consultedProvincesAndTerritories = [];
    }
  } else {
    if (!includeConsultationPeriod) {
      out.consultedProvincesAndTerritories = [];
    }
  }

  return out;
}
