import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import ChangeLogListItem from "components/molecules/changeLog/ChangeLogListItem";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { useOverviewContext } from "features/overview";
import { ChangeLog, ChangeLogListDocument } from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

const Index: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [sortByDate, setSortByDate] = React.useState<"asc" | "desc">("desc");

  const pagination = usePagination();

  const { data, loading, error } = useQuery(ChangeLogListDocument, {
    variables: {
      changeLogListParams: {
        contextName: "CosewicWS",
        correlationId: cosewicWsId,
        sortByDate: sortByDate,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
      },
    },
  });

  const onChangeSortByDate = (e: any) => {
    setSortByDate(e.currentTarget.value);
  };

  const changeLogListItems = data?.changeLogList?.changeLog;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("cosewic_change_log")}</h1>
          <GraphqlError errors={error} />

          <div id="dataset_filter" className="Card mrgn-tp-md">
            {/*instant filters:*/}
            <div className="filter-controls">
              <section className="flex justify-between mrgn-bttm-md">
                <div className="flex">
                  <div className="flex-col">
                    <label htmlFor="sel_sort_order">{t("sort_order")}</label>
                    <select
                      id="sel_sort_order"
                      className="form-control"
                      value={sortByDate}
                      onChange={onChangeSortByDate}
                    >
                      <option value="asc">{t("ascending")}</option>
                      <option value="desc">{t("descending")}</option>
                    </select>
                  </div>
                </div>

                <div className="flex">
                  {/* <p>Export to Excel</p> */}
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
              </section>
            </div>

            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !changeLogListItems || changeLogListItems?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                {changeLogListItems?.map((item) => {
                  const changeLog = item as ChangeLog; // Only for type conversion
                  return (
                    <div className="list-item separator-line" key={item?.id}>
                      <ChangeLogListItem
                        changeLog={changeLog}
                        changeLogDetailsLinkCallback={
                          CosewicPathHelpers.ChangeLogDetails
                        }
                      />
                    </div>
                  );
                })}
                <Pagination
                  {...pagination.paginationComponentProps}
                  totalPages={data?.changeLogList?.pagination?.totalPages ?? 0}
                />
              </>
            )}
          </div>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Index;
