import { isString } from "lodash";
import { htmlToPlainText } from "./richtext";
import isNullOrEmpty from "./isNullOrEmpty";

const htmlIsNullOrEmpty = (s?: any | number | null) => {
  if (isString(s)) {
    const asPlainText = htmlToPlainText(s);
    return isNullOrEmpty(asPlainText);
  }

  return isNullOrEmpty(s);
};

export default htmlIsNullOrEmpty;
