import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useCheckbox from "../../../hooks/util/useCheckbox";
import Alert, { AlertTypes } from "../../atoms/Alert";
import ConfirmationModal, { useConfirmationModal } from "../ConfirmationModal";

export const MakeCosewicInfoPublishButtonAndModal: React.FC<{
  canPublishChanges: boolean;
  onPublishChanges: () => Promise<void>;
  warningMessage?: string;
  buttonText?: string;
  ConfirmationMessage?: string;
  warningTitle?: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmPublishModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const onPublishWithSpinner = () => {
    setShowButtonSpinner(true);

    props.onPublishChanges().finally(() => {
      setShowButtonSpinner(false);
    });
  };

  return (
    <>
      <button
        className="btn btn-primary btn-sm mrgn-rght-sm"
        disabled={!props.canPublishChanges}
        onClick={confirmPublishModal.open}
        data-testid="button-cosewic-info-publish-changes"
      >
        {showButtonSpinner ? (
          <>
            <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
            <span className="wb-inv">&nbsp;{t("loading")}...</span>
          </>
        ) : null}
        {props.buttonText ? t(props.buttonText) : t("publish_changes")}
      </button>

      <ConfirmationModal
        modalState={confirmPublishModal}
        title={t("publish_changes")}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={onPublishWithSpinner}
        onCancel={() => {
          confirmPublishModal.close();
          checkboxState.reset();
        }}
      >
        <Alert
          type={AlertTypes.WARNING}
          title={props.warningTitle ?? t("publish_changes_warning_title")}
          content={props.warningMessage ?? t("publish_changes_warning_message")}
        />

        <label>
          <input
            type="checkbox"
            checked={checkboxState.checked}
            onChange={checkboxState.toggle}
            data-testid="modal-checkbox-confirm-cosewic-info-publish-changes"
          />{" "}
          {props.ConfirmationMessage
            ? t(props.ConfirmationMessage)
            : t("i_confirm")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default MakeCosewicInfoPublishButtonAndModal;
