import cc from "classcat";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import * as React from "react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Alert, { AlertTypes } from "./Alert";
import { isHTMLString } from "util/strings";

export interface DeleteButtonModalProps {
  buttonText?: string;
  showButtonText?: boolean;
  buttonTitle?: string;
  modalTitle: string | ReactElement;
  alertContent: string;
  alertConfirm: string;
  onDelete: () => any;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  dataTestid?: string;
  alertType?: AlertTypes;
}

export const DeleteButtonModal: React.FC<DeleteButtonModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    buttonText = t("delete"),
    showButtonText = true,
    buttonTitle = t("delete"),
    modalTitle = t("delete"),
    alertContent,
    alertConfirm,
    onDelete,
    className,
    loading = false,
    disabled,
    dataTestid = "button-delete",
    alertType,
  } = props;
  const confirmDeleteModal = useConfirmationModal({});
  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className={cc([
          "btn btn-link card-header-button-link hover-underline-danger",
          className,
        ])}
        title={buttonTitle}
        onClick={confirmDeleteModal.open}
        disabled={disabled}
        data-testid={dataTestid}
      >
        {showButtonText ? (
          <>
            {loading ? (
              <i className="fa fa-spinner fa-spin color-danger mrgn-rght-sm" />
            ) : (
              <i className="fas fa-trash color-danger mrgn-rght-sm"></i>
            )}
            <span className="color-danger">{buttonText}</span>
          </>
        ) : (
          <>
            {loading ? (
              <i className="fa fa-spinner fa-spin color-danger" />
            ) : (
              <i className="fas fa-trash color-danger"></i>
            )}
          </>
        )}
      </button>

      {disabled ? null : (
        <ConfirmationModal
          modalState={confirmDeleteModal}
          title={modalTitle}
          confirmBtnEnabled={confirmDeleteState}
          onConfirm={() => {
            onDelete();
            setConfirmDeleteState(false);
          }}
          onCancel={() => {
            confirmDeleteModal.close();
            setConfirmDeleteState(false);
          }}
        >
          <Alert
            type={alertType != null ? alertType : AlertTypes.WARNING}
            content={alertContent}
          />

          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={confirmDeleteState}
                onChange={() => setConfirmDeleteState((x) => !x)}
                data-testid="modal-checkbox-confirm-delete"
              />
              &nbsp;
              {alertConfirm}
            </label>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

export default DeleteButtonModal;
