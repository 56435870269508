import * as React from "react";
import { useTranslation } from "react-i18next";
import CreateRangeModal, { CreateRangeModalProps, useRangeModal } from "./";

interface CreateRangeModalWithButtonProps
  extends Omit<CreateRangeModalProps, "modalState"> {
  buttonLabel?: string;
}

const CreateRangeModalWithButton: React.FC<CreateRangeModalWithButtonProps> = (
  props
) => {
  const { t } = useTranslation();
  const modalState = useRangeModal({});

  const label = props.buttonLabel ?? t("add");

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        data-testid="button-add"
      >
        {label}
      </button>
      <CreateRangeModal modalState={modalState} {...props} />
    </>
  );
};

export default CreateRangeModalWithButton;
