import { ODataMapper } from "../types";

const speciesByLandOdataMappers: Record<string, ODataMapper> = {
  "ListingLand/WildlifeSpeciesPresence": {
    key: "ListingLand/WildlifeSpeciesPresence",
    filter(searchValues: string[]) {
      return {
        ListingLand: {
          or: searchValues.map((x) => ({ WildlifeSpeciesPresence: x })),
        },
      };
    },
  },
};

export default Object.freeze(speciesByLandOdataMappers);
