import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import { PhotoImage } from "components/atoms/PhotoImage/PhotoImage";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { useOverviewContext } from "features/overview";
import {
  PhotoGallery,
  PhotoLicenseType,
  PhotoType,
  PublishState,
} from "generated/gql-types";
import { GalleryPathHelpers } from "pages/gallery/galleryRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

interface PhotosByLicenseTypeCardProps {
  photoLicenseType: PhotoLicenseType;
  photoGalleryData: PhotoGallery[];
}

export const PhotosByLicenseTypeCard: React.FC<PhotosByLicenseTypeCardProps> = (
  props
) => {
  const { photoLicenseType, photoGalleryData } = props;
  const { t, i18n } = useTranslation();
  const overviewContext = useOverviewContext();

  const photoGalleryByType = photoGalleryData?.filter(
    (x) => x?.licenseType === photoLicenseType
  );

  return (
    <SectionCard header={<h2>{t(photoLicenseType)}</h2>}>
      {photoGalleryByType && photoGalleryByType.length > 0 ? (
        <div className="flex gap-md flex-wrap">
          {photoGalleryByType?.map((x: PhotoGallery, index) => (
            <div key={index} className="species-photo-gallery">
              <figure>
                <Link
                  to={GalleryPathHelpers.PhotoProfileByReference(
                    overviewContext.overviewReference?.name!,
                    overviewContext.overviewReference?.referenceId!,
                    x.photo?.id!
                  )}
                  data-testid="link-to-photo-profile"
                >
                  <div className="img-overlay-container">
                    <PhotoImage
                      name={x?.photo?.name ?? ""}
                      size={PhotoType.Medium}
                      className="img-rounded img-responsive"
                      style={{ width: "300px" }}
                    />
                    {(x.photo?.state === PublishState.Published ||
                      x.photo?.state === PublishState.Publishing ||
                      x.photo?.state === PublishState.PublishFailed ||
                      x.photo?.state === PublishState.Unpublishing ||
                      x.photo?.state === PublishState.UnpublishFailed) && (
                      <div className="img-overlay">{t(x.photo?.state)}</div>
                    )}
                  </div>
                </Link>
                <figcaption className="caption font-size-14 text-break">
                  {htmlIsNullOrEmpty(
                    i18n.language === "fr"
                      ? x?.title?.french?.text
                      : x?.title?.english?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        i18n.language === "fr"
                          ? x?.title?.french?.text
                          : x?.title?.english?.text
                      }
                    />
                  )}
                </figcaption>
              </figure>
            </div>
          ))}
        </div>
      ) : (
        <NoResults centered />
      )}
    </SectionCard>
  );
};

export default PhotosByLicenseTypeCard;
