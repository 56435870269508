// wrapper around buildQuery to remove the output string '$filter=' prefix
import { default as baseBuildQuery } from "odata-query";

export const buildQuery: typeof baseBuildQuery = (...args) => {
  const queryString = baseBuildQuery(...args);
  const params = new URLSearchParams(queryString);
  return params.get("$filter") as string;
};

export default buildQuery;
