import { AppConfigurationClient } from "@azure/app-configuration";
import { useMemo, useState } from "react";
import isNullOrEmpty from "util/isNullOrEmpty";
import {
  FeatureFlagKeys,
  REACT_APP_CONFIGURATION_CONNECTION_STRING,
} from "./config";

export const getConfigurationSetting = async (flagKey: FeatureFlagKeys) => {
  if (!client) {
    return;
  }

  if (!flagKey || !flagKey.toString().trim().length) {
    console.error("A feature flag key must be supplied.");
  } else {
    try {
      const result = await client.getConfigurationSetting({
        key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
      });

      if (result && typeof result === "object") {
        return JSON.parse(result.value as any).enabled;
      }
    } catch (error) {
      console.error(error);
    }
  }
};

let client: AppConfigurationClient | undefined;
if (!isNullOrEmpty(REACT_APP_CONFIGURATION_CONNECTION_STRING)) {
  client = new AppConfigurationClient(
    REACT_APP_CONFIGURATION_CONNECTION_STRING
  );
} else {
  console.warn(
    "REACT_APP_CONFIGURATION_CONNECTION_STRING was empty! No connection will be made to app configuration, and all feature flags will be off by default."
  );
}
/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (flagKey: FeatureFlagKeys) => {
  const [enabled, setEnabled] = useState(false);

  useMemo(async () => {
    const result = await getConfigurationSetting(flagKey);
    setEnabled(result);
  }, [flagKey]);
  return { enabled };
};
/**
 * Retrieves the specified configuration from Azure App Configuration.
 * @param {string} configKey App Config Key
 * @returns the configuration for the specified key
 */
const useConfiguration = (configKey = "") => {
  const [config, setConfig] = useState("");

  useMemo(async () => {
    if (!client) {
      return;
    }

    if (!configKey || !configKey.toString().trim().length) {
      console.error("A config key must be supplied.");
    } else {
      try {
        const result = await client.getConfigurationSetting({
          key: configKey.toString().trim(),
        });

        if (result) {
          setConfig(result.value as any);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [configKey]);
  return { config };
};
export { useConfiguration, useFeatureFlag };
