const cosewicAssessmentTranslation = {
  en: {
    cosewic_assessment: "COSEWIC Assessment",
    date_examined: "Date Examined",
    assessment_date: "Assessment date",
    date_of_assessment: "Date of assessment",
    order_of_assessment: "Order of Assessment",
    assessment_type: "Assessment type",
    type_of_report: "Type of report",
    stage_of_assessment: "Stage of assessment",
    assessment_stage: "Assessment stage",
    assessment: "Assessment",
    assessments: "Assessments",
    year_to_be_sent_to_minister: "Year to be sent to minister",
    add_your_first_assessment: "Add your first assessment",
    stage: "Stage",
    assessment_summary: "Assessment Summary",
    assessment_information: "Assessment Information",
    cosewic_ws_status: "COSEWIC WS Status",
    status_change: "Status change",
    designation: "Designation",
    history_status_designation: "History of Status Designation",
    reason_designation: "Reason for Designation",
    extirpitated_comment: "Extirpitated / Extinct Comment",
    status_criteria: "Status Criteria",
    applicability_criteria: "Applicability Criteria",
    make_assessed: "Make Assessed",
    delete_an_assessment: "Delete Assessment",
    delete_assessment_warning:
      "You will not be able to recover this COSEWIC Wildlife Species Assessment",
    edit_ws_status_and_criteria: "Edit Wildlife Species status and criteria",
    cosewic_assessments_search: "COSEWIC Assessments Search",
    assessment_filters: "Assessment filters",
    wildlife_species_assessments: "Wildlife Species Assessments",
    ws_stage: "WS stage",
    ws_state: "WS state",
    publish_to_registry: "Publish to registry",
    publish_assessment_to_registry_warning:
      "You will be publishing this assessment to the registry",
    related_ws_are_still_draft_alert_title:
      "Related wildlife species are still draft",
    related_ws_are_still_draft_alert_content:
      "In order to add a new assessment, all of the related children must be made permanent. You can access all of the related species in the side navigation under COSEWIC.",
    type_of_status_change: "Type of status change",
    extinct_or_extirpated_since: "Extinct / Extirpated since",
    make_assessed_warning:
      "Are you sure you want to make this assessment assessed?",
    confirm_make_assessed:
      "I confirm that I'd like to make the assessment assessed.",
    new_assessment_could_not_be_added_alert_title:
      "New assessment could not be added",
    no_more_than_one_draft_assessment_alert_content:
      "Cannot have more than one draft assessment per COSEWIC wildlife Species.",
    only_one_assessment_cosewic_draft_alert_content:
      "Only 1 Assessment is allowed when COSEWIC Species is in Draft.",
    required_to_make_as_assessed_tooltip:
      "Required to make the assessment stage 'assessed'.",
    publish_changes: "Publish changes",
    publish_changes_warning_title:
      "Wildlife species information will be published to the Registry.",
    publish_changes_warning_message:
      "All of the changes will be visible on the Registry.",
    publish_changes_success_message:
      "Wildlife species information successfully published to Registry.",
    publish_changes_view_on_registry: "View on Registry",
    endemic: "Endemic",
    wsInfoChangesUnpublished: "WS info changes unpublished",
  },

  fr: {
    cosewic_assessment: "Évaluation du COSEPAC",
    date_examined: "Date de l'évaluation",
    assessment_date: "Date de l'évaluation",
    date_of_assessment: "Date de l'évaluation",
    order_of_assessment: "Ordre de l'évaluation",
    assessment_type: "Type d'évaluation",
    type_of_report: "Type de rapport",
    stage_of_assessment: "Stade de l'évaluation",
    assessment_stage: "Stade d'évaluation",
    assessment: "Évaluation",
    assessments: "Évaluations",
    year_to_be_sent_to_minister: "Année de l'envoi au ministre",
    add_your_first_assessment: "Ajouter votre première évaluation",
    stage: "Étape",
    assessment_summary: "Résumé de l'évaluation",
    assessment_information: "Information sur l'évaluation",
    cosewic_ws_status: "Statut de l'ES du COSEPAC",
    status_change: "Modification du statut",
    designation: "Désignation",
    history_status_designation: "Historique du statut",
    reason_designation: "Justification de la désignation",
    extirpitated_comment:
      "Commentaire sur la disparition du pays ou de la planète",
    status_criteria: "Critères de désignation du statut",
    applicability_criteria: "Applicabilité des critères",
    make_assessed: "Rendre permanent",
    delete_an_assessment: "Supprimer l'évaluation",
    delete_assessment_warning:
      "Vous ne pourrez pas récupérer cette évaluation des espéces sauvages du COSEPAC",
    edit_ws_status_and_criteria:
      "Modifier le statut et les critères d'évaluation de l'espèce sauvage",
    cosewic_assessments_search: "Recherche d’évaluations du COSEPAC",
    assessment_filters: "Filtres des évaluations",
    wildlife_species_assessments: "Évaluations des espèces sauvages",
    ws_stage: "Stade de l'ES",
    ws_state: "État de l'ES",
    publish_to_registry: "Publié sur le registre",
    publish_assessment_to_registry_warning:
      "Vous allez publier cette évaluation sur le registre",
    related_ws_are_still_draft_alert_title:
      "Les espèces sauvages apparentées sont encore en ébauche",
    related_ws_are_still_draft_alert_content:
      "Afin d'ajouter une nouvelle évaluation à une espèce sauvage considéré comme un parent, toutes les espèces sauvages considérées comme enfants doivent être permanentes. Vous pouvez accéder à toutes les espèces apparentées dans la navigation latérale sous COSEPAC.",
    type_of_status_change: "Type de changement de statut",
    extinct_or_extirpated_since: "Éteint / Disparu depuis",
    make_assessed_warning:
      "Êtes-vous sur de vouloir identifier cette évaluation comme évaluée ?",
    confirm_make_assessed:
      "Je confirme que je souhaite identifier évaluation comme évaluée.",
    new_assessment_could_not_be_added_alert_title:
      "Une nouvelle évaluation n'a pas pu être ajoutée",
    no_more_than_one_draft_assessment_alert_content:
      "Impossible d'avoir plus d'un brouillon d'évaluation par espèce COSEPAC.",
    only_one_assessment_cosewic_draft_alert_content:
      "Une seule évaluation est autorisée lorsque l'espèce COSEPAC est à l'ébauche.",
    required_to_make_as_assessed_tooltip:
      "Nécessaire pour rendre cette évaluation au stade 'évaluée'.",
    publish_changes: "Publier les changements",
    publish_changes_warning_title:
      "L'informations sur l'espèce sauvage sera publiées sur le Registre.",
    publish_changes_warning_message:
      "Toutes les modifications seront visibles sur le Registre.",
    publish_changes_success_message:
      "L'information sur l'espèce sauvage a été publiée avec succès sur le Registre.",
    publish_changes_view_on_registry: "Afficher sur le Registre.",
    endemic: "Endémique",
    wsInfoChangesUnpublished: "Changements aux ES non-publiés",
  },
};

export default cosewicAssessmentTranslation;
