import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { PermitPathHelpers } from "pages/permit/PermitRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useRouteMatch } from "react-router-dom";

export const SideNav: React.FC = (props) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <div>
      <h2 id="wb-sec-h" className="wb-inv">
        Section Menu
      </h2>
      <section className="list-group menu list-unstyled">
        <h3>{t("Navigation")}</h3>
        <ul className="list-group menu list-unstyled">
          <li>
            <NavLink
              to={PermitPathHelpers.Home}
              className="list-group-item"
              activeClassName=""
              data-testid="side-nav-permit-descriptions"
            >
              {t("permit_descriptions")}
            </NavLink>

            <ul className="list-group menu list-unstyled">
              <>
                <li>
                  <NavLink
                    exact
                    to={PermitPathHelpers.PermitBrowse}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-browse-permit"
                  >
                    {t("browse_permit_descriptions")}
                  </NavLink>
                </li>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.permit.update}
                >
                  <li>
                    <NavLink
                      exact
                      to={PermitPathHelpers.PermitCreate}
                      className="list-group-item"
                      activeClassName="wb-navcurr"
                      data-testid="side-nav-add-permit"
                    >
                      {t("add_permit_description")}
                    </NavLink>
                  </li>
                </RenderWhenAuthorized>
              </>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default SideNav;
