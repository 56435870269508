import * as React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, NavLink, useRouteMatch } from "react-router-dom";
import { AdminPathHelpers } from "../../../pages/admin/AdminRouter";

export const AdminSideNav: React.FC = (props) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  // currently there are no DMTS property pages where this sidenav will show
  const isDmtsPropertiesPath = false;

  // currently there are no document management pages where this sidenav will show
  const isDocumentManagementPath = false;

  const isContactManagementPath =
    !!matchPath(path, "/admin/contacts/:contactId") ||
    !!matchPath(path, "/admin/organizations/:organizationId");

  return (
    <div>
      <h2 id="wb-sec-h" className="wb-inv">
        Section Menu
      </h2>
      <section className="list-group menu list-unstyled">
        <h3>{t("Navigation")}</h3>

        <ul className="list-group menu list-unstyled">
          <li>
            <NavLink
              exact
              to={AdminPathHelpers.Home()}
              className="list-group-item"
              activeClassName="wb-navcurr"
              data-testid="side-nav-dmts-properties"
            >
              {t("sar_dmts_properties")}
            </NavLink>

            {isDmtsPropertiesPath && (
              <ul className="list-group menu list-unstyled">
                <li>
                  <NavLink
                    to={AdminPathHelpers.Genus()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-genus"
                  >
                    {t("genus")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Species()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-species"
                  >
                    {t("species")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Subspecies()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-subspecies"
                  >
                    {t("subspecies")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Population()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-population"
                  >
                    {t("population")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Variety()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-variety"
                  >
                    {t("variety")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Range()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-range"
                  >
                    {t("range")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.TaxonomicGroup()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-taxonomicgroup"
                  >
                    {t("taxonomic_group")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Lands()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-lands"
                  >
                    {t("lands")}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>

        <ul className="list-group menu list-unstyled">
          <li>
            <NavLink
              exact
              to={AdminPathHelpers.Home()}
              className="list-group-item"
              activeClassName="wb-navcurr"
              data-testid="side-nav-document-management"
            >
              {t("document_management")}
            </NavLink>

            {isDocumentManagementPath && (
              <ul className="list-group menu list-unstyled">
                <li>
                  <NavLink
                    to={AdminPathHelpers.ResponseStatement()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-response-statement"
                  >
                    {t("response_statement")}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>

        <ul className="list-group menu list-unstyled">
          <li>
            <NavLink
              exact
              to={AdminPathHelpers.Home()}
              className="list-group-item"
              activeClassName="wb-navcurr"
              data-testid="side-nav-contact-management"
            >
              {t("contact_management")}
            </NavLink>

            {isContactManagementPath && (
              <ul className="list-group menu list-unstyled">
                <li>
                  <NavLink
                    to={AdminPathHelpers.Contacts()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-contacts"
                  >
                    {t("contacts")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={AdminPathHelpers.Organizations()}
                    className="list-group-item"
                    activeClassName="wb-navcurr"
                    data-testid="side-nav-organizations"
                  >
                    {t("organizations")}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </section>
    </div>
  );
};

export default AdminSideNav;
