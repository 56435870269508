import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import AddLink from "components/atoms/AddLink";
import Alert, { AlertTypes } from "components/atoms/Alert";
import EditLink from "components/atoms/EditLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import ConsultationProcessSection from "components/molecules/listing/processes/ConsultationProcessSection/ConsultationProcessSection";
import DocumentsSection from "components/molecules/listing/processes/DocumentsSection/DocumentsSection";
import RegulatoryProcessSection from "components/molecules/listing/processes/RegulatoryProcessSection/RegulatoryProcessSection";
import SummaryOfProcessesTable from "components/molecules/listing/processes/SummaryOfProcessesTable/SummaryOfProcessesTable";
import TifInformationSection from "components/molecules/listing/processes/TifInformationSection/TifInformationSection";
import RelatedSpeciesWarning from "components/molecules/listing/relatedSpecies/relatedSpeciesWarning";
import { FeatureFlagKeys } from "features/featureFlags/config";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  ListingProfileDocument,
  ListingWsProcess,
  ListingWsProcessListDocument,
  ListingWsSchedule,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ListingProcessType } from "types/listing/enums";
import { formatTimestamp } from "util/formatTimestamp";
import SafeRenderHtml from "../../../../components/atoms/SafeRenderHtml";
import Ribbon from "../../../../components/organisms/cosewic/Ribbon";
import { RenderWhenAuthorized } from "../../../../features/auth/components";
import { ROLE_ACTIONS } from "../../../../features/auth/roles";
import { useFeatureFlag } from "../../../../features/featureFlags/useFeatureFlag";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";

const ListingProcesses: React.FC = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  const { enabled: isSchedule2And3Enabled } = useFeatureFlag(
    FeatureFlagKeys.Schedule2and3Species
  );

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { data, loading, error } = useQuery(ListingWsProcessListDocument, {
    variables: {
      params: {
        id: listingWsId,
        pageNumber: 1,
        pageSize: 100, // Max default 100
      },
    },
  });

  const {
    loading: listingWsLoading,
    error: listingWsError,
    data: listingWsData,
  } = useQuery(ListingProfileDocument, {
    variables: { id: listingWsId },
  });

  const otherScheduleData = listingWsData?.listingWs?.otherSchedule;
  const listingSchedule = otherScheduleData?.schedule;

  const schedule2Schedule3 =
    listingSchedule === ListingWsSchedule.Schedule2 ||
    listingSchedule === ListingWsSchedule.Schedule3;
  const listingWsProcesses = data?.listingWsProcessList?.listingWsProcess;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("listing_processes")}</h1>

          <GlobalAlert />
          <GraphqlError
            title="Error fetching Listing WS Process List data"
            errors={error}
          />

          <GraphqlError
            title="Error fetching Other schedule Listing WS Process List data"
            errors={listingWsError}
          />
          <RelatedSpeciesWarning listingWsId={listingWsId} />

          {/* ----- Summary of Processes section ----- */}
          <SectionCard
            header={<h2>{t("summary_of_processes")}</h2>}
            contentAreaProps={{ className: "mrgn-tp-0" }}
          >
            {!schedule2Schedule3 && (
              <SectionCard
                collapsible
                open={false}
                header={t("notice")}
                showLine={false}
                classNameSummary={"py-2"}
              >
                <Alert className="mrgn-bttm-0" type={AlertTypes.INFO}>
                  {t("schedule_1_on_receipt_info")}
                </Alert>
              </SectionCard>
            )}

            {loading || listingWsLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <SummaryOfProcessesTable
                listingWsProcesses={listingWsProcesses}
              />
            )}
          </SectionCard>

          {/* ----- The All processes section ----- */}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("all_processes")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                >
                  <AddLink
                    text={t("add_a_process")}
                    linkTo={ListingPathHelpers.ListingProcessCreate(
                      listingWsId
                    )}
                  ></AddLink>
                </RenderWhenAuthorized>
              </div>
            }
            // contentAreaProps={{ className: "mrgn-tp-0" }}
            className="pb-0"
            showLine={false}
          >
            {listingWsProcesses?.map((item, index) => {
              const listingWsProcess = item as ListingWsProcess; // Only for type conversion
              const listingProcessType =
                listingWsProcess.listingProcessType as unknown as ListingProcessType;
              const isFirstListingProcess = index === 0;
              const hasGicDecisionAndGicDecisionDate =
                listingWsProcess.regulatoryProcess?.gicDecision != null &&
                listingWsProcess.regulatoryProcess?.gicDecisionDate != null;

              return (
                <>
                  <div className="pt-0 separator-line-top"></div>
                  <details
                    key={index}
                    className="species-details expand-collapse-list"
                    open={isFirstListingProcess}
                  >
                    <summary className="py-2_5">
                      <div className="flexbug-9-workaround">
                        <div className="flex justify-between align-center">
                          <div className="flex">
                            {listingWsProcess.isNew && (
                              <div className="label label-info mrgn-rght-sm pt-2_5">
                                {t("new")}
                              </div>
                            )}
                            <h3 className="mrgn-tp-sm mrgn-bttm-sm">
                              {listingProcessType ===
                                ListingProcessType.LinkToAssessment ||
                              listingProcessType === ListingProcessType.Regular
                                ? formatTimestamp(
                                    listingWsProcess.assessmentRef
                                      ?.assessmentDate
                                  ) ?? <MissingData />
                                : formatTimestamp(
                                    listingWsProcess.ministerOpinionDate
                                  ) ?? <MissingData />}
                              {" - "}
                              {listingProcessType ===
                              ListingProcessType.LinkToAssessment
                                ? t("existing_assessment")
                                : listingProcessType ===
                                  ListingProcessType.ImminentThreat
                                ? t("imminent_threat")
                                : listingProcessType ===
                                  ListingProcessType.Other
                                ? t("other")
                                : t("regular")}
                            </h3>
                          </div>
                          <RenderWhenAuthorized
                            authorizedRoles={ROLE_ACTIONS.listing.update}
                          >
                            <EditLink
                              linkTo={ListingPathHelpers.ListingProcessEdit(
                                listingWsId,
                                item?.id ?? "error"
                              )}
                            />
                          </RenderWhenAuthorized>
                        </div>
                      </div>
                    </summary>

                    {loading ? (
                      <LoadingIndicator centered className="mrgn-bttm-md" />
                    ) : (
                      <div className="mrgn-lft-sm mrgn-rght-sm">
                        <TifInformationSection
                          isFirstListingProcess={isFirstListingProcess}
                          preventEditButton={hasGicDecisionAndGicDecisionDate}
                          listingProcessId={listingWsProcess.id}
                          tifs={listingWsProcess.tifs}
                        />
                        <hr className="mrgn-tp-md" />

                        <ConsultationProcessSection
                          listingProcessType={listingProcessType}
                          consultationProcess={
                            listingWsProcess.consultationProcess
                          }
                        />
                        {!listingWsProcess.isNew && (
                          <>
                            <hr className="mrgn-tp-md" />
                            <RegulatoryProcessSection
                              regulatoryProcess={
                                listingWsProcess.regulatoryProcess
                              }
                            />
                            <hr className="mrgn-tp-md" />
                            <DocumentsSection
                              documents={listingWsProcess.documents}
                            />
                          </>
                        )}
                        {(!htmlIsNullOrEmpty(
                          listingWsProcess.processNote?.english?.text
                        ) ||
                          !htmlIsNullOrEmpty(
                            listingWsProcess.processNote?.french?.text
                          )) && (
                          <>
                            <hr className="mrgn-tp-md" />
                            <h4 className="mrgn-bttm-md">
                              {t("process_note")}
                            </h4>
                            <div className="species-field">
                              <div className="row">
                                <dl>
                                  <div className="species-data col-sm-12">
                                    <dt className="text-muted">
                                      {t("english")}
                                    </dt>
                                    <dd>
                                      {htmlIsNullOrEmpty(
                                        listingWsProcess.processNote?.english
                                          ?.text
                                      ) ? (
                                        <MissingData />
                                      ) : (
                                        <div className="font-size-18">
                                          <SafeRenderHtml
                                            htmlString={
                                              listingWsProcess.processNote
                                                ?.english?.text ?? ""
                                            }
                                          />
                                        </div>
                                      )}
                                    </dd>
                                  </div>
                                </dl>
                              </div>
                            </div>
                            <div className="species-field">
                              <div className="row">
                                <dl>
                                  <div className="species-data col-sm-12">
                                    <dt className="text-muted">
                                      {t("french")}
                                    </dt>
                                    <dd>
                                      {htmlIsNullOrEmpty(
                                        listingWsProcess.processNote?.french
                                          ?.text
                                      ) ? (
                                        <MissingData />
                                      ) : (
                                        <div className="font-size-18">
                                          <SafeRenderHtml
                                            htmlString={
                                              listingWsProcess.processNote
                                                ?.french?.text ?? ""
                                            }
                                          />
                                        </div>
                                      )}
                                    </dd>
                                  </div>
                                </dl>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </details>
                </>
              );
            })}
          </SectionCard>
          {/* ------------- Other Schedule ------------- */}
          {schedule2Schedule3 && isSchedule2And3Enabled ? (
            <SectionCard
              header={<h2 className="mrgn-tp-sm">{t("other_schedule")}</h2>}
            >
              <div className="species-field">
                <dl className="row">
                  <div className="species-data col-sm-4">
                    <dt className="text-muted">{t("schedule")}</dt>
                    <dd>
                      {listingSchedule ? t(listingSchedule) : <MissingData />}
                    </dd>
                  </div>

                  <div className="species-data col-sm-4">
                    <dt className="text-muted">{t("date_of_addition")}</dt>
                    <dd>
                      {formatTimestamp(otherScheduleData?.dateAdded?.value) ?? (
                        <MissingData />
                      )}
                    </dd>
                  </div>

                  <div className="species-data col-sm-4">
                    <dt className="text-muted">{t("date_removed")}</dt>
                    <dd>
                      {formatTimestamp(
                        otherScheduleData?.dateRemoved?.value
                      ) ?? <MissingData />}
                    </dd>
                  </div>
                </dl>
                <dl className="row">
                  <div className="species-data col-sm-12">
                    <dt className="text-muted">{t("note")}</dt>
                    <dd>
                      {htmlIsNullOrEmpty(otherScheduleData?.note?.text) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={otherScheduleData?.note?.text ?? ""}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </SectionCard>
          ) : null}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingProcesses;
