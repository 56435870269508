import * as React from "react";
import { useTranslation } from "react-i18next";
import { BilingualLookupTextInput } from "generated/gql-types";
import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import { bilingualLookupTextNameForLanguage } from "mappers";
import OutcomeAutocomplete from "../OutcomeAutocomplete/OutcomeAutocomplete";
import { Control, Controller, ControllerProps } from "react-hook-form";

export interface OutcomeMultiSelectListProps {
  value: Array<BilingualLookupTextInput>;
  onChange: (listItems: Array<BilingualLookupTextInput>) => void;
}

export const OutcomeMultiSelectList: React.FC<OutcomeMultiSelectListProps> = (
  props
) => {
  const { value, onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    BilingualLookupTextInput | undefined
  >(undefined);

  const onRemoveFromList = (id: string) => {
    const newList = value.filter((x) => x.id !== id);
    onChange(newList);
  };

  const isFound = value.some((x) => x.id === selectedSearchItem?.id);

  return (
    <div>
      <div className="flex gap-sm align-end">
        <OutcomeAutocomplete onChange={setSelectedSearchItem} />
        {value &&
          selectedSearchItem &&
          !isFound &&
          onChange([...value, selectedSearchItem])}
        {selectedSearchItem && setSelectedSearchItem(undefined)}
        {/* <button
          type="button"
          className="btn btn-default flex align-center"
          onClick={onAddSelectedToList}
          data-testid="activityMultiSelectList_addToListBtn"
        >
          <i className="fa fa-plus-circle mrgn-rght-sm" />
        </button> */}
      </div>

      <ul className="multi-select-list">
        {value?.map((x) => {
          return (
            <MultiSelectListItem
              id={x.id as string}
              key={x.id}
              name={bilingualLookupTextNameForLanguage(i18n.language, x)}
              onRemove={() => onRemoveFromList(x.id as string)}
            />
          );
        })}
      </ul>
    </div>
  );
};

export interface OutcomeMultiSelectListWithControllerProps<TFieldValues>
  extends Omit<OutcomeMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const OutcomeMultiSelectListWithController = <TFieldValues,>(
  props: OutcomeMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <OutcomeMultiSelectList
          value={(value ?? []) as Array<BilingualLookupTextInput>}
          onChange={onChange}
        />
      )}
    />
  );
};

export default OutcomeMultiSelectList;
