import * as React from "react";
import { useTranslation } from "react-i18next";

export interface MissingDataProps {
  message?: string;
}

export const MissingData: React.FC<MissingDataProps> = (props) => {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <span className="opct-70 font-style-italic">{message ?? t("no_data")}</span>
  );
};

export default MissingData;
