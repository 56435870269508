import { SplashTemplate, WetProvider } from "@arcnovus/wet-boew-react";
import { resolveAzureEnvironment } from "azure/environment";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

// Note: TopNav links don't start with /en/ or /fr/.
const REGEX_TOPNAV_LINK = /^(?!(\/en\/|\/fr\/)).*/g;
const isTopNavLink = (str: string) => str.match(REGEX_TOPNAV_LINK) !== null;

function CustomWetProvider({ currentLanguage, children }: any) {
  const history = useHistory();

  // react-router click handler; more info here as of Feb 2022: https://arcnovus.github.io/wet-booew-x/?path=/story/instructions-routing--page
  // Our version includes handling for the current language, as our react-router instance
  //  the current language as its base route (basename prop) e.g. /en/... and /fr/...
  //
  // Updated 2023-01-12: Fix duplicate path history issue (BUG 38181).
  const handleLinkClick = useCallback(
    (a) => {
      const currentURI = a.href.replace(window.location.origin, "");
      // TopNav links need to be pushed to the history.
      if (isTopNavLink(currentURI)) {
        history.push(currentURI.replace("/" + currentLanguage, ""));
      }
    },
    [history, currentLanguage]
  );

  const env = resolveAzureEnvironment(); // For changing the app-bar color by env.

  return (
    <WetProvider linkHandler={handleLinkClick}>
      {currentLanguage == null ? (
        <SplashTemplate
          nameEng={"Species at Risk Data Management Tracking System"}
          nameFra={
            "Système de gestion et de suivi des données sur les espèces en péril"
          }
        />
      ) : (
        <>
          <div id={env}></div>
          {children}
        </>
      )}
    </WetProvider>
  );
}

export default CustomWetProvider;
