import {
  Contact,
  ContactRef,
  Organization,
  OrganizationRef,
} from "../../../../generated/gql-types";

// INFO: for use in forms that rely on the output of the add contact modal, but expect to be mapping a ref type
//       instead of a whole organization.
export const mapOrganizationToOrganizationRef = (
  organization?: Organization
) => {
  if (!organization) return undefined;
  const out: OrganizationRef = {};

  if (organization.contactDetails) {
    out.contactDetails = organization.contactDetails;
  }

  out.organizationDetails = {};

  if (organization.id) {
    out.organizationDetails.id = organization.id;
  }
  if (organization.name) {
    out.organizationDetails.name = organization.name;
  }
  if (organization.departments) {
    out.organizationDetails.departments = organization.departments;
  }

  // INFO: if we didn't successfully set any of the keys above,
  //       just leave the whole organizationDetails object out.
  if (Object.keys(out.organizationDetails).length === 0) {
    out.organizationDetails = undefined;
  }

  return out;
};

export const mapContactToContactRef = (contact?: Contact | null) => {
  if (contact == null) return contact;
  const out: ContactRef = {};

  if (contact.id) {
    out.id = contact.id;
  }
  if (contact.name) {
    out.name = contact.name;
  }
  if (contact.jobTitle) {
    out.jobTitle = contact.jobTitle;
  }
  if (contact.contactDetails) {
    out.contactDetails = contact.contactDetails;
  }
  if (contact.organizationDetails) {
    out.organizationDetails = contact.organizationDetails;
  }

  return out;
};
