import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Maybe } from "../../generated/gql-types";
import useQueryParams from "./useQueryParams";

export const usePagination = (defaults?: {
  currentPage?: number;
  pageSize?: number;
}) => {
  const { i18n } = useTranslation();
  const query = useQueryParams();
  const history = useHistory();
  const location = useLocation();

  const initialPageNumber =
    query.get("currentPage") ?? defaults?.currentPage ?? 1;

  const initialPageSize = query.get("pageSize") ?? defaults?.pageSize ?? 10;

  const [currentPage, setCurrentPage] = React.useState<number>(
    Number(initialPageNumber)
  );
  const [pageSize, _setPageSize] = React.useState<number>(
    Number(initialPageSize)
  );

  const setPageSize = (newPageSize: number) => {
    _setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const nextPage = () => setCurrentPage((x) => x + 1);
  const prevPage = () => setCurrentPage((x) => x - 1);

  React.useEffect(() => {
    query.set("currentPage", String(currentPage));
    query.set("pageSize", String(pageSize));
    history.replace({ pathname: location.pathname, search: query.toString() });
  }, [currentPage, pageSize, history, location.pathname, query]);

  const makeShowingString = (totalCount?: Maybe<number>) => {
    if (totalCount == null || totalCount === 0) {
      if (i18n.language === "fr") {
        return `Afficher 0 résultats`;
      }
      return "Showing 0 results";
    }

    const max = currentPage * pageSize;
    const min = totalCount === 0 ? 0 : max - pageSize + 1;
    const cappedMax = max > (totalCount ?? 0) ? totalCount : max;

    if (i18n.language === "fr") {
      return `Affiche ${min} à ${cappedMax} de ${totalCount} entrées`;
    }

    return `Showing ${min} to ${cappedMax} of ${totalCount} entries`;
  };

  return {
    goToPage: setCurrentPage,
    nextPage,
    prevPage,
    setPageSize,

    currentPage,
    pageSize,
    makeShowingString,

    paginationComponentProps: {
      pageIndex: currentPage,
      onPageSelected: setCurrentPage,
    },
  };
};
