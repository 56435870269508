const listingTranslation = {
  en: {
    additional_protection_under_sara: "Additional Protection Under SARA",
    competent_minister: "Competent Minister",
    competent_minister_1: "Competent Minister 1",
    competent_minister_2: "Competent Minister 2",
    conservation_activities_english: "Conservation activities in English",
    conservation_activities_french: "Conservation activities in French",
    consultation_considerations: "Consultation Considerations",
    cosewic_reports_sent_to_minister_date:
      "COSEWIC reports sent to Minister date",
    date_of_addition_on_Schedule_1: "Date of addition on Schedule 1",
    department_of_fisheries_and_oceans: "Department of Fisheries & Oceans",
    edit_listing_information: "Edit listing information",
    environment_and_climate_change_canada:
      "Environment and Climate Change Canada",
    estimated_total_mature_individuals_at_assessment:
      "Estimated total mature individuals at assessment",
    expected_impact_on_federal_lands: "Expected impact on Federal Lands",
    expected_impact_on_federal_lands_rationale:
      "Expected impact on federal lands rationale",
    expected_impact_on_non_federal_lands:
      "Expected impact on non federal lands",
    expected_impact_on_non_federal_lands_rationale:
      "Expected impact on non federal lands rationale",
    federal: "Federal",
    federal_protection_application: "Federal protection application",
    indigenous_communities: "Indigenous communities",
    indigenous_community: "Indigenous community",
    jurisdiction: "Jurisdiction",
    legal_protection: "Legal Protection",
    sara_status: "SARA Status",
    legislation: "Legislation",
    legislation_application: "Legislation application",
    listing: "Listing",
    listing_id: "Listing ID",
    listing_information: "Listing information",
    listing_name_changes: "Listing name changes",
    listing_processes: "Listing processes",
    listing_related_species: "Listing related species",
    add_parents: "Add Parents",
    selected_parents: "Selected Parents",
    listing_status: "Listing status",
    other_federal_protection: "Other Federal Protection",
    overall_assessment: "Overall assessment",
    parks_canada: "Parks Canada",
    provincial_territorial: "Provincial / Territorial",
    provincial_territorial_legislation: "Provincial / Territorial Legislation",
    relevant_provisions: "Relevant provisions",
    sara_status_history_english: "SARA status history (English)",
    sara_status_history_french: "SARA status history (French)",
    schedule: "SARA Schedule",
    select_a_minister: "Select a Minister",
    select_an_impact: "Select an impact",
    stakeholder: "Stakeholder",
    stakeholders: "Stakeholders",
    wildlife_management_board: "Wildlife management board",
    wildlife_management_boards: "Wildlife management boards",
    assessment_status: "Assessment status",
    summary_of_processes: "Summary of processes",
    all_processes: "All processes",
    sch_1_status_on_receipt: "Sch 1 status on receipt",
    minister_receipt_date: "Date of last received assessment",
    consultation_path: "Consultation path",
    gic_decision_date: "GIC decision date",
    gic_decision: "GIC decision",
    sara_schedule: "SARA Schedule",
    listing_batch: "Listing batch",
    consultation_process: "Consultation process",
    response_statement_due_date: "Response statement due date",
    response_statement_posted_date: "Response statement published date",
    timeline_policy_report_due_date: "Timeline policy report due date",
    amendment_aligned_to_assessment: "Amendment aligned to assessment",
    cosewic_to_reassess: "COSEWIC to reassess",
    cgi_listing_proposal: "CG I - Listing proposal",
    anticipated_decision_date: "Anticipated decision date",
    gic_receipt_date: "GIC receipt date",
    rationale: "Rationale",
    rationale_english: "Rationale, English",
    rationale_french: "Rationale, French",
    regulatory_bundle: "Regulatory bundle",
    regulatory_process: "Regulatory Process",
    documents: "Documents",
    batch_number: "Batch number",
    listing_process_for_date: "Listing process for {{date}}",
    consultation_end_publication_date: "Consultation end date",
    consultation_end_date_document: "Consultation document",
    cgi_proposal_publication_date: "CG I - Proposal publication date",
    cgi_proposal_date_document: "CG I - Proposal date document",
    cgii_gic_receipt_publication_date: "CG II - GiC receipt publication date",
    cgii_gic_receipt_document: "CG II - GiC Receipt document",
    cgii_listing_order_publication_date:
      "CG II - Listing order publication date",
    cgii_listing_order_document: "CG II - Listing Order document",
    cgii_not_list_refer_back_publication_date:
      "CG II - Not list / Refer back publication date",
    cgii_not_list_refer_back_document: "CG II - Not list / Refer back document",
    addition_to_schedule_1: "Addition to Schedule 1",
    on_schedule_1: "On Schedule 1",
    add_a_process: "Add a process",
    create_new_listing_process: "Create new Listing process",
    listing_type: "Listing type",
    select_a_type_of_listing_process: "Select a type of listing process",
    LINK_TO_ASSESSMENT: "Associate to a COSEWIC assessment",
    IMMINENT_THREAT: "Imminent Threat",
    OTHER: "Other",
    existing_assessment: "Existing Assessment",
    associate_to_a_cosewic_assessment: "Associate to a COSEWIC assessment",
    imminent_threat: "Imminent Threat",
    other: "Other",
    regular: "Regular",
    threat_date: "Threat Date",
    process_note: "Process note",
    select_one_or_many_items: "Select one or many items",
    gender: "Gender",
    select_a_gender: "Select a gender",

    // Processes
    tif_information: "TIF Information",
    proposed_consultation_path: "Proposed consultation path",
    range_of_impact: "Range of impact",
    consultation_requirements: "Consultation requirements",
    information_uncertainties: "Information uncertainties",
    comments: "Comments",
    edit_region: "Edit region",
    required_to_complete_listing_process_tooltip:
      "Required to complete a Listing process.",
    species_data_source: "Species data source",
    methodology: "Methodology",
    gis_contact_person: "GIS contact person",
    gis_contact_note: "GIS contact note",
    biologist_contact_person: "Biologist contact person",
    biologist_contact_note: "Biologist contact note",

    // Sectors
    select_new_sector: "Select a new sector",
    delete_a_sector: "Delete a sector",
    delete_sector_warning: "Are you sure you want to delete this sector?",
    naics_classification: "NAICS classification",
    activity: "Activity",
    activities: "Activities",
    outcome: "Outcome",
    outcomes: "Outcomes",
    activity_and_outcomes: "Activity and outcomes",
    edit_sector: "Edit Sector",
    sector: "Sector",
    sectors: "Sectors",
    add_a_sector: "Add a sector",
    subsector: "Subsector",
    subsectors: "Subsectors",
    issues: "Issues",
    sector_issues: "Sector Issues",
    industry_group: "Industry group",
    industry_groups: "Industry groups",
    industry: "Industry",
    industries: "Industries",
    canadian_industry: "Canadian industry",
    canadian_industries: "Canadian industries",

    // Lands
    land_types: "Land types",
    add_land: "Add land",
    view_federal_lands: "View Federal lands",
    view_provincial_territorial_lands: "View Provincial / territorial lands",
    view_private_lands: "View Private lands",
    federal_lands: "Federal lands",
    provincial_territorial_lands: "Provincial / territorial lands",
    private_lands: "Private lands",
    remove_land_warning: "Land will be removed",
    related_wildlife_species_information:
      "Related wildlife species information",
    approx_num_individuals: "Approximate number of individuals",
    number_of_sites: "Number of sites",
    wildlife_species_presence: "Wildlife Species presence",
    circumstance: "Circumstance",
    source: "Source",
    add_wildlife_species_information: "Add Wildlife Species Information",

    // Related species
    remove_parent: "Remove parent",
    remove_parent_warning: "Parent will be removed",
    remove_confirm: "I confirm that I'd like to remove.",
    parents_should_remove_from_sch1_warning:
      "One or more parent are associated to this wildlife species and should be removed from Schedule 1 before a GIC decision and a GIC decision date of the parent is given to the child.",
    parent: "Parent",
    child: "Child",
    gic_decision_date_of_child: "GIC decision date of the child",

    // WS name different across programs warning
    ws_name_different_warning_title:
      "Wildlife species names are different between COSEWIC and Listing.",
    ws_name_different_warning_details:
      "If you accept these name changes you will be replacing the Listing names with the COSEWIC names.",
    name_change: "Name change",
    accept_name_change_warning:
      "Accepting the name changes will replace the current Listing data with the COSEWIC data.",
    accept_name_change_confirm: "I confirm that I'd like to replace.",
    cosewic_name_is: "COSEWIC {{name}} is",
    change_during_assessment_on:
      "This change was made during an assessment on {{date}}.",
    not_part_of_an_assessment: "Not part of an assessment.",
    other_schedule: "Other Schedule",
    date_of_addition: "Date of addition",
    date_removed: "Date removed",
    SCHEDULE2: "Schedule 2",
    SCHEDULE3: "Schedule 3",
  },

  fr: {
    additional_protection_under_sara:
      "Protection supplémentaire en vertu de la LEP",
    competent_minister: "Ministre compétent",
    competent_minister_1: "Ministre compétent 1",
    competent_minister_2: "Ministre compétent 2",
    conservation_activities_english: "Activités de conservation en anglais",
    conservation_activities_french: "Activités de conservation en français",
    consultation_considerations: "Considérations relatives à la consultation",
    cosewic_reports_sent_to_minister_date:
      "Date à laquelle les rapports du COSEPAC sont envoyés au ministre",
    date_of_addition_on_Schedule_1: "Date de l'ajout à l'annexe 1",
    department_of_fisheries_and_oceans: "Ministère des Pêches et des Océans",
    edit_listing_information: "Modifier les informations de l'inscription",
    environment_and_climate_change_canada:
      "Environnement et changement climatique Canada",
    estimated_total_mature_individuals_at_assessment:
      "Estimation du nombre total d'individus matures à l'évaluation",
    expected_impact_on_federal_lands: "Impact prévu sur les terres fédérales",
    expected_impact_on_federal_lands_rationale:
      "Justification de l'impact prévu sur les terres fédérales",
    expected_impact_on_non_federal_lands:
      "Impact prévu sur les terres non fédérales",
    expected_impact_on_non_federal_lands_rationale:
      "Justification de l'impact prévu sur les terres non fédérales",
    federal: "Fédéral",
    federal_protection_application: "Demande de protection fédérale",
    indigenous_communities: "Communautés autochtones",
    indigenous_community: "Communauté autochtone",
    jurisdiction: "Compétence",
    legal_protection: "Protection juridique",
    sara_status: "Statut de la LEP",
    legislation: "Législation",
    legislation_application: "Application de la législation",
    listing: "Inscription",
    listing_id: "Identifiant de l'inscription",
    listing_information: "Information de l'inscription",
    listing_name_changes: "Changement de nom lors de l'inscription",
    listing_processes: "Processus d'inscription",
    listing_related_species: "Espèces reliées à l'inscription",
    add_parents: "Ajouter un parent",
    selected_parents: "Sélectionner un parent",
    listing_status: "Statut de l'inscription",
    other_federal_protection: "Autre protection fédérale",
    overall_assessment: "Évaluation globale",
    parks_canada: "Parcs Canada",
    provincial_territorial: "Provinciale / Territoriale",
    provincial_territorial_legislation:
      "Provinciale / Territoriale législation",
    relevant_provisions: "Dispositions pertinentes",
    sara_status_history_english: "Historique du statut de la LEP (anglais)",
    sara_status_history_french: "Historique du statut de la LEP (français)",
    schedule: "Annexe de la LEP",
    select_a_minister: "Choisir un ministre",
    select_an_impact: "Sélectionner un impact",
    stakeholder: "Parties prenantes",
    stakeholders: "Parties prenantes",
    wildlife_management_board: "Conseil de gestion de la faune",
    wildlife_management_boards: "Conseils de gestion de la faune",
    assessment_status: "Statut de l'évaluation",
    summary_of_processes: "Résumé des processus",
    all_processes: "Tous les processus",
    sch_1_status_on_receipt: "Statut Ann. 1 à la réception",
    minister_receipt_date: "Date de la dernière évaluation reçue",
    consultation_path: "Parcours de consultation",
    gic_decision_date: "Date de la décision du GEC",
    gic_decision: "Décision du GEC",
    sara_schedule: "Annexe de la LEP",
    listing_batch: "Lot de l'inscription",
    consultation_process: "Processus de consultation",
    response_statement_due_date: "Date d'échéance de la déclaration de réponse",
    response_statement_posted_date:
      "Date de la publication de l'énoncé de réaction",
    timeline_policy_report_due_date: "Date d'échéance du rapport",
    amendment_aligned_to_assessment: "Amendement aligné sur l'évaluation",
    cosewic_to_reassess: "Réévaluation du COSEPAC",
    cgi_listing_proposal: "CG I - Proposition d'inscription",
    anticipated_decision_date: "Date de décision anticipée",
    gic_receipt_date: "Date de réception du GEC",
    rationale: "Justification",
    rationale_english: "Justification, anglais",
    rationale_french: "Justification, français",
    regulatory_bundle: "Paquet réglementaire",
    regulatory_process: "Processus de réglementation",
    documents: "Documents",
    batch_number: "Numéro de lot",
    listing_process_for_date: "Processus d'inscription pour le {{date}}",
    consultation_end_publication_date: "Date de la fin de la consultation",
    consultation_end_date_document: "Document relatif à la consultation",
    cgi_proposal_publication_date:
      "CG I - Date de publication de la proposition",
    cgi_proposal_date_document:
      "CG I - Document relatif à la date de la proposition",
    cgii_gic_receipt_publication_date:
      "CG II - Date de publication du reçu du GEC",
    cgii_gic_receipt_document: "CG II - Document de réception du GEC",
    cgii_listing_order_publication_date:
      "CG II - Date de publication du décret",
    cgii_listing_order_document: "CG II - L'inscription du décret",
    cgii_not_list_refer_back_publication_date:
      "CG II - Date de publication du document non inscrit/renvoyé",
    cgii_not_list_refer_back_document: "CG II - Document non inscrit/renvoyé",
    addition_to_schedule_1: "Ajout à l'Annexe 1",
    on_schedule_1: "À l'Annexe 1",
    add_a_process: "Ajouter un processus",
    create_new_listing_process: "Créer un nouveau processus d'inscription",
    listing_type: "Type d'inscription",
    select_a_type_of_listing_process:
      "Sélectionnez un type de processus d'inscription",
    LINK_TO_ASSESSMENT: "Associer à une évaluation du COSEPAC",
    IMMINENT_THREAT: "Menace imminente",
    OTHER: "Autre",
    existing_assessment: "Évaluation existante",
    associate_to_a_cosewic_assessment: "Associer à une évaluation du COSEPAC",
    imminent_threat: "Menace imminente",
    other: "Autre",
    regular: "Régulier",
    threat_date: "Date de la menace",
    process_note: "Note de processus",
    select_one_or_many_items: "Sélectionnez un ou plusieurs éléments",
    gender: "Genre",
    select_a_gender: "Sélectionnez un genre",

    // Processes
    tif_information: "IET Information",
    proposed_consultation_path: "Parcours de consultation proposé",
    range_of_impact: "Ampleur de l'impact",
    consultation_requirements: "Exigences en matière de consultation",
    information_uncertainties: "Incertitudes en matière d'information",
    comments: "Commentaires",
    edit_region: "Modifier la région",
    required_to_complete_listing_process_tooltip:
      "Requis pour compléter un processus d’Inscription.",
    species_data_source: "Source de données de l'espèce.",
    methodology: "Méthodologie",
    gis_contact_person: "Personne de contact SIG",
    gis_contact_note: "Note du contact SIG",
    biologist_contact_person: "Personne de contact biologiste",
    biologist_contact_note: "Note du contact biologiste",

    // Sectors
    select_new_sector: "Sélectionnez un nouveau secteur",
    delete_a_sector: "Supprimer un secteur",
    delete_sector_warning: "Êtes-vous sûr de vouloir supprimer ce secteur ?",
    naics_classification: "Classification SCIAN",
    activity: "Activité",
    activities: "Activités",
    outcome: "Résultat",
    outcomes: "Résultats",
    activity_and_outcomes: "Activité et résultats",
    edit_sector: "Modifier un secteur",
    sector: "Secteur",
    sectors: "Secteurs",
    add_a_sector: "Ajouter un secteur",
    subsector: "Sous-secteur",
    subsectors: "Sous-secteurs",
    issues: "Problèmes",
    sector_issues: "Problèmes du secteur",
    industry_group: "Groupe industriel",
    industry_groups: "Groupes industriels",
    industry: "Industrie",
    industries: "Industries",
    canadian_industry: "Industrie canadienne",
    canadian_industries: "Industries canadiennes",

    // Lands
    land_types: "Types de terres",
    add_land: "Ajouter une terre",
    view_federal_lands: "Voir les terres fédérales",
    view_provincial_territorial_lands:
      "Voir les terres provinciales / territoriales",
    view_private_lands: "Voir les terres privées",
    federal_lands: "Terres fédérales",
    provincial_territorial_lands: "Terres provinciales / territoriales",
    private_lands: "Terres privées",
    remove_land_warning: "La terre sera retirée",
    related_wildlife_species_information:
      "Informations relatives aux espèces sauvages",
    approx_num_individuals: "Nombre approximatif d'individus",
    number_of_sites: "Nombre de sites",
    wildlife_species_presence: "Présence d'espèces sauvages",
    circumstance: "Circonstance",
    source: "Source",
    add_wildlife_species_information:
      "Ajouter des informations sur les espèces sauvages",

    // Related species
    remove_parent: "Enlever un parent",
    remove_parent_warning: "Le parent sera supprimé",
    remove_confirm: "Je confirme vouloir retirer",
    parents_should_remove_from_sch1_warning:
      "Un ou plusieurs parents sont associés à cette espèce sauvage et doivent être retirés de l'annexe 1 avant qu'une décision GEC et une date de décision GEC du parent soient données à l'enfant.",
    gic_decision_date_of_child: "Date de la décision GEC de l'enfant",
    parent: "Parent",
    child: "Enfant",

    // WS name different across programs warning
    ws_name_different_warning_title:
      "Les noms des espèces sauvages sont différents entre le COSEPAC et l'inscription.",
    ws_name_different_warning_details:
      "Si vous acceptez ces changements de noms, vous remplacerez les noms de l'inscription par ceux du COSEPAC.",
    name_change: "Changement de nom",
    accept_name_change_warning:
      "En acceptant les changements de nom, les données de l'inscription actuelle seront remplacées par les données du COSEPAC.",
    accept_name_change_confirm: "Je confirme que je souhaite remplacer le nom.",
    cosewic_name_is: "COSEPAC {{name}} est",
    change_during_assessment_on:
      "Cette modification a été effectuée lors d'une évaluation le {{date}}.",
    not_part_of_an_assessment: "Ne fait pas partie d'une évaluation.",
    other_schedule: "Autre Annexe",
    date_of_addition: "Date d'ajout",
    date_removed: "Date supprimée",
    SCHEDULE2: "Annexe 2",
    SCHEDULE3: "Annexe 3",
  },
};

export default listingTranslation;
