import { useFocusRing } from "@react-aria/focus";
import { useTab, useTabList, useTabPanel } from "@react-aria/tabs";
import { mergeProps } from "@react-aria/utils";
import { Item } from "@react-stately/collections";
import { useTabListState } from "@react-stately/tabs";
import cc from "classcat";
import { useRef } from "react";
export { Item };

export interface TabsModalProps {
  className?: string;
}

export function Tabs(props: any) {
  const { className } = props;
  let state = useTabListState(props);
  let ref = useRef<any>();
  let { tabListProps } = useTabList(props, state, ref);

  let { focusProps, isFocusVisible } = useFocusRing({
    within: true,
  });

  return (
    <div className={cc(["wb-tabs tabs-acc", className])}>
      {/* Div for accessibility / focus */}
      <div
        className={`tab-selection ${isFocusVisible ? "focused" : ""}`}
        style={{ zIndex: -1 }}
      />

      {/* Render the list of clickable tabs. Render as a ul to comply with WET-BOEW tabs structure. */}
      <ul
        className="generated"
        {...mergeProps(tabListProps, focusProps)}
        ref={ref}
      >
        {[...Array.from(state.collection)].map((item) => (
          <Tab key={item.key} item={item} state={state} />
        ))}
      </ul>

      {/* TabPanel renders the currently selected tab */}
      <TabPanel key={state.selectedItem?.key} state={state} />
    </div>
  );
}

function Tab({ item, state }: any) {
  let ref = useRef<any>();
  let { tabProps } = useTab(item, state, ref);
  const isSelected = state.selectedItem?.key === item.key;

  // Render a li and anchor tag to comply with WET-BOEW tabs structure.
  return (
    <li
      ref={ref}
      role={"presentation"}
      className={isSelected ? "active" : undefined}
    >
      <a href="#" {...tabProps}>
        {item.rendered}
      </a>
    </li>
  );
}

function TabPanel({ state, ...props }: any) {
  let ref = useRef<any>();
  let { tabPanelProps } = useTabPanel(props, state, ref);

  // Render a details and summary to comply with WET-BOEW tabs structure.
  return (
    <div className="tabpanels">
      <details open {...tabPanelProps} ref={ref} tabIndex={-1}>
        <summary aria-hidden="true" aria-selected="true" role="tab">
          {state.selectedItem?.props.title}
        </summary>
        <div className="tgl-panel" aria-hidden="false">
          {state.selectedItem?.props.children}
        </div>
      </details>
    </div>
  );
}
