import { useFeatureFlag } from "./useFeatureFlag";
import { FeatureFlagKeys } from "./config";

export interface RenderWhenFeatureIsEnabledProps {
  flagKey: FeatureFlagKeys;
}

export const RenderWhenFeatureIsEnabled: React.FC<
  RenderWhenFeatureIsEnabledProps
> = (props: any) => {
  const { enabled } = useFeatureFlag(props.flagKey);

  if (!enabled) return null;
  return <>{props.children}</>;
};

export default RenderWhenFeatureIsEnabled;
