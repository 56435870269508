import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "../../util/router";
import PermitProfilePage from "./[id]";
import PermitEditPage from "./[id]/edit";
import PermitBrowsePage from "./browse";
import PermitCreatePage from "./create";
import PermitHomePage from "./index";

export interface PermitRouterProps {}

export const PermitPathHelpers = {
  Home: "/permit",
  PermitBrowse: "/permit/browse",
  PermitCreate: "/permit/create",
  PermitProfile: (permitId: string) => `/permit/${permitId}`,
  PermitEdit: (permitId: string) => `/permit/${permitId}/edit`,
};

export const PermitRouter: React.FC<PermitRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <PermitHomePage />
      </Route>

      <Route path={relative("/browse")}>
        <PermitBrowsePage />
      </Route>

      <Route path={relative("/create")}>
        <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.permit.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <PermitCreatePage />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:permitId/edit")}>
        <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.permit.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <PermitEditPage />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:permitId")}>
        <PermitProfilePage />
      </Route>
    </Switch>
  );
};

export default PermitRouter;
