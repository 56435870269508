import { Item } from "@react-stately/collections";
import Autocomplete from "components/molecules/Autocomplete";
import { ListingWs } from "generated/gql-types";
import type { ReactElement } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { UseListingWsAutocomplete } from "./useListingWsAutocomplete";

interface ListingWsAutocompleteProps {
  onSelectionChange: (newListingWs?: ListingWs) => void;
  initialListingWs?: ListingWs;
  label?: string | ReactElement;
  placeholder?: string;
  useListingWsAutocompleteInstance: UseListingWsAutocomplete;
  disabled?: boolean;
  required?: boolean;
  onClear?: () => void;
}

const ListingWsAutocomplete: React.FC<ListingWsAutocompleteProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { list } = props.useListingWsAutocompleteInstance;

  const defaultValue = React.useMemo(
    () => parseInitialListingWs(i18n.language, props.initialListingWs),
    [i18n.language, props.initialListingWs]
  );

  const listingWsNameForLanguage = React.useCallback(
    (item: ListingWs) => {
      if (i18n.language === "fr") {
        return {
          id: item?.id,
          name: item?.commonNameFrench?.name?.plainText,
          population: item?.population?.nameFr,
        };
      }

      return {
        id: item?.id,
        name: item?.commonNameEnglish?.name?.plainText,
        population: item?.population?.nameEn,
      };
    },
    [i18n.language]
  );

  const onSelectionChange = (key: string | number) => {
    const item = list.getItem(key);
    if (item == null) {
      props.onSelectionChange(undefined);
      return;
    }

    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <Autocomplete
      label={props.label}
      items={list.items}
      inputValue={list.filterText}
      defaultInputValue={defaultValue}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      onSelectionChange={onSelectionChange}
      placeholder={(props.placeholder ?? props.label ?? "").toString()}
      disabled={props.disabled}
      isRequired={props.required}
      onClear={onClear}
    >
      {(item: ListingWs) => {
        const name = listingWsNameForLanguage(item).name ?? "error";
        const listingId = t("listing_id") + " " + item.listingId;
        const population = listingWsNameForLanguage(item).population;
        let item_value = name;
        if (population) item_value += ", " + population;

        return (
          <Item key={item.id} textValue={item_value + " - " + listingId}>
            <span className="font-size-16">
              {item_value}
              {" - "}
              <span className="text-muted">{listingId}</span>
            </span>
          </Item>
        );
      }}
    </Autocomplete>
  );
};

const parseInitialListingWs = (
  language: string,
  initialListing?: ListingWs
): string => {
  if (!initialListing) return "";
  if (language === "fr")
    return initialListing.commonNameFrench?.name?.text ?? "";
  return initialListing.commonNameEnglish?.name?.text ?? "";
};

export default ListingWsAutocomplete;
