import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import { OverviewWsSpecies } from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import AssociatedSpeciesAutocomplete from "../AssociatedSpeciesAutocomplete";
import useAssociatedSpeciesAutocomplete from "../AssociatedSpeciesAutocomplete/useAssociatedSpeciesAutocomplete";

export interface AssociatedSpeciesMultiSelectListProps {
  value: Array<OverviewWsSpecies>;
  onChange: (listItems: Array<OverviewWsSpecies>) => void;
  required?: boolean;
}

export const AssociatedSpeciesMultiSelectList: React.FC<
  AssociatedSpeciesMultiSelectListProps
> = (props) => {
  const { value = [], onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    OverviewWsSpecies | undefined
  >(undefined);
  const associatedSpeciesAutocompleteInstance =
    useAssociatedSpeciesAutocomplete();

  const associatedSpeciesNameForLanguage = React.useCallback(
    (item: OverviewWsSpecies) => {
      const enName = item?.commonNameEnglish?.name?.plainText;
      const enPopulation = item?.population?.nameEn;
      const listingId = item?.listingRefId?.legacyId;
      const cosewicId = item?.cosewicRefId?.legacyId;
      let enValue = enName;
      if (enPopulation) enValue += ", " + enPopulation;
      if (cosewicId) enValue += " - " + t("cosewic_id") + " " + cosewicId;
      if (listingId) enValue += ", " + t("listing_id") + " " + listingId;

      const frName = item?.commonNameFrench?.name?.plainText;
      const frPopulation = item?.population?.nameFr;
      let frValue = frName;
      if (frPopulation) frValue += ", " + frPopulation;
      if (cosewicId) frValue += " - " + t("cosewic_id") + " " + cosewicId;
      if (listingId) frValue += ", " + t("listing_id") + " " + listingId;

      if (i18n.language === "fr") {
        return {
          name: frValue,
          // population: item?.population?.nameFr,
        };
      }

      return {
        name: enValue,
        // population: item?.population?.nameEn,
      };
    },
    [i18n.language]
  );

  const onRemoveFromList = (id: string) => {
    const newList = value.filter(
      (x) => (x.listingRefId?.id ?? x.cosewicRefId?.id) !== id
    );
    onChange(newList);
  };

  const onSelectionChange = (newSpecies?: OverviewWsSpecies | undefined) => {
    setSelectedSearchItem(newSpecies);
    associatedSpeciesAutocompleteInstance.clear();
  };

  const isFound = value.some(
    (x) =>
      (x.listingRefId?.id ?? x.cosewicRefId?.id) ===
      (selectedSearchItem?.listingRefId?.id ??
        selectedSearchItem?.cosewicRefId?.id)
  );

  React.useEffect(() => {
    if (value && selectedSearchItem && !isFound) {
      onChange([...value, selectedSearchItem]);
    }
  }, [isFound, onChange, selectedSearchItem, value]);

  React.useEffect(() => {
    if (selectedSearchItem) {
      setSelectedSearchItem(undefined);
    }
  }, [selectedSearchItem]);

  return (
    <>
      <div className="flex gap-sm align-end">
        <AssociatedSpeciesAutocomplete
          onSelectionChange={onSelectionChange}
          label={t("associated_wildlife_species")}
          required={props.required}
          useAssociatedSpeciesAutocompleteInstance={
            associatedSpeciesAutocompleteInstance
          }
        />
      </div>

      <ul className="multi-select-list">
        {value.map((x) => {
          const speciesId = x.listingRefId?.id ?? x.cosewicRefId?.id;
          return (
            <MultiSelectListItem
              id={speciesId as string}
              name={associatedSpeciesNameForLanguage(x)?.name ?? "error"}
              onRemove={() => onRemoveFromList(speciesId as string)}
              key={speciesId}
            />
          );
        })}
      </ul>
    </>
  );
};

export interface AssociatedSpeciesMultiSelectListWithControllerProps<
  TFieldValues
> extends Omit<AssociatedSpeciesMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const AssociatedSpeciesMultiSelectListWithController = <TFieldValues,>(
  props: AssociatedSpeciesMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      rules={{ required: props.required }}
      render={({ field: { value, onChange } }) => (
        <AssociatedSpeciesMultiSelectList
          value={value as Array<OverviewWsSpecies>}
          onChange={onChange}
          required={props.required}
        />
      )}
    />
  );
};

export default AssociatedSpeciesMultiSelectList;
