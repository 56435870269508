import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";

const AddContactModalResult: React.FC<{
  result: AddContactModalItem;
  onChange: (id: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const id = props.result.id;

  const onChange = () => props.onChange(id);

  return (
    <div className="radio mrgn-tp-0 mrgn-bttm-0 py-2_5" key={id}>
      <label htmlFor={id}>
        <input
          id={id}
          type="radio"
          name="search-results"
          value={id}
          onChange={onChange}
        />
        <div className="mrgn-lft-sm">
          <address className="mrgn-bttm-0">
            <strong>{props.result.name ?? t("no_name")}</strong>
            <br />
            {props.result.address && (
              <SafeRenderHtml htmlString={props.result.address} />
            )}
          </address>
          {props.result.note && (
            <div className="mrgn-tp-sm">
              {t("note")}: <span>{props.result.note}</span>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default AddContactModalResult;

export interface AddContactModalItem {
  id: string;
  name?: string; // First name + Last name OR organization name
  address?: string; // Address
  note?: string;
}
