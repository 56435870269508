const listingEnumTranslation = {
  en: {
    // Listing Schedule
    SCHEDULE1: "Schedule 1",
    SCHEDULE2: "Schedule 2",
    SCHEDULE3: "Schedule 3",
    NOT_ON_SCHEDULE1: "Not on Schedule 1",

    // Listing Process Schedule1Status
    //// NO_STATUS: "No Status",
    DELISTED_NOT_AT_RISK: "Delisted; not at risk",
    NOT_LISTED: "Not listed",
    REMOVED_NOW_BELIEVED_EXTINCT: "Removed; now believed extinct",
    REMOVED_NOW_CONSIDERED_A_DIFFERENT_WILDLIFE_SPECIES:
      "Removed; now considered a different wildlife species",
    REMOVED_NO_LONGER_CONSIDERED_A_UNIQUE_WILDLIFE_SPECIES:
      "Removed; no longer considered a unique wildlife species",
    NOT_INITIALIZED: "No data",

    CONFIRMED: "Confirmed",
    HIGH_POTENTIAL: "High potential",
    EXTIRPATED_FROM_THIS_SITE: "Extirpated from this site",
    POSSIBLE: "Possible",
    HABITAT_ONLY: "Habitat only",

    // Gender
    MASCULINE: "Masculine",
    FEMININE: "Feminine",
    OTHER: "Other",
  },

  fr: {
    // Listing Schedule
    SCHEDULE1: "Annexe 1",
    SCHEDULE2: "Annexe 2",
    SCHEDULE3: "Annexe 3",
    NOT_ON_SCHEDULE1: "Pas à l'annexe 1",

    // Listing Process Schedule1Status
    DELISTED_NOT_AT_RISK: "Radiée de la liste; non en péril",
    NOT_LISTED: "Non inscrite",
    REMOVED_NOW_BELIEVED_EXTINCT:
      "Supprimée; maintenant considérée comme disparue",
    REMOVED_NOW_CONSIDERED_A_DIFFERENT_WILDLIFE_SPECIES:
      "Supprimée; maintenant considérée comme une autre espèce sauvage",
    REMOVED_NO_LONGER_CONSIDERED_A_UNIQUE_WILDLIFE_SPECIES:
      "Supprimée; n’est désormais plus considérée comme une espèce sauvage unique",
    NOT_INITIALIZED: "Aucune donnée",

    CONFIRMED: "Confirmer",
    HIGH_POTENTIAL: "Potentiel élevé",
    EXTIRPATED_FROM_THIS_SITE: "Disparue de ce site",
    POSSIBLE: "Possible",
    HABITAT_ONLY: "Habitat uniquement",

    // Gender
    MASCULINE: "Masculin",
    FEMININE: "Féminine",
    OTHER: "Autres",
  },
};

export default listingEnumTranslation;
