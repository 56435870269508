import MissingData from "components/atoms/MissingData";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { ListingProcessConsultationProcess } from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ListingProcessType } from "types/listing/enums";
import { formatTimestamp } from "util/formatTimestamp";

export interface ConsultationProcessSectionProps {
  listingProcessType: ListingProcessType;
  consultationProcess?: ListingProcessConsultationProcess | null;
}

export const ConsultationProcessSection: React.FC<
  ConsultationProcessSectionProps
> = (props) => {
  const { listingProcessType, consultationProcess } = props;
  const { t } = useTranslation();

  const ministerReceiptDate = consultationProcess?.ministerReceiptDate;
  const responseStatementDueDate =
    consultationProcess?.responseStatementDueDate;
  const responseStatementPostedDate =
    consultationProcess?.responseStatementPostedDate;
  const consultationPath =
    i18n.language === "fr"
      ? consultationProcess?.consultationPath?.name?.french
      : consultationProcess?.consultationPath?.name?.english;
  const listingBatch = consultationProcess?.listingBatch;
  const timelinePolicyReportDueDate =
    consultationProcess?.timelinePolicyReportDueDate;
  const amendmentAlignedToAssessment =
    i18n.language === "fr"
      ? consultationProcess?.amendmentAlignedToAssessment?.name?.french
      : consultationProcess?.amendmentAlignedToAssessment?.name?.english;

  const showTooltipAlertForConsultationPath =
    consultationProcess?.consultationPath?.id == null;

  return (
    <>
      <h4 className="mrgn-bttm-md">{t("consultation_process")}</h4>
      <div className="species-field">
        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("minister_receipt_date")}</dt>
              <dd>
                {listingProcessType === ListingProcessType.LinkToAssessment ||
                listingProcessType === ListingProcessType.Regular
                  ? formatTimestamp(ministerReceiptDate) ?? <MissingData />
                  : t("not_applicable")}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("response_statement_due_date")}</dt>
              <dd>
                {listingProcessType === ListingProcessType.LinkToAssessment ||
                listingProcessType === ListingProcessType.Regular ? (
                  consultationProcess?.ministerReceiptDate == null ? (
                    <MissingData />
                  ) : (
                    formatTimestamp(responseStatementDueDate) ?? <MissingData />
                  )
                ) : (
                  t("not_applicable")
                )}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">
                {t("response_statement_posted_date")}
              </dt>
              <dd>
                {listingProcessType === ListingProcessType.LinkToAssessment ||
                listingProcessType === ListingProcessType.Regular
                  ? formatTimestamp(responseStatementPostedDate) ?? (
                      <MissingData />
                    )
                  : t("not_applicable")}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">
                {t("consultation_path")}
                <TooltipAlert isVisible={showTooltipAlertForConsultationPath}>
                  {t("required_to_complete_listing_process_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>{consultationPath ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("listing_batch")}</dt>
              <dd>{listingBatch ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">
                {t("timeline_policy_report_due_date")}
              </dt>
              <dd>
                {consultationProcess?.consultationPath?.name?.english !==
                "Confirmed" ? (
                  consultationProcess?.ministerReceiptDate == null ? (
                    <MissingData />
                  ) : (
                    formatTimestamp(timelinePolicyReportDueDate) ?? (
                      <MissingData />
                    )
                  )
                ) : (
                  t("not_applicable")
                )}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">
                {t("amendment_aligned_to_assessment")}
              </dt>
              <dd>{amendmentAlignedToAssessment ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ConsultationProcessSection;
