const adminUserTranslation = {
  en: {
    user: "User",
    users: "Users",
    roles: "Roles",
    cosewic_users: "COSEWIC Users",
    recovery_users: "Recovery Users",
    listing_users: "Listing Users",
    protection_users: "Protection Users",
    search_name_or_email: "Search name or email",
    enter_name_or_email: "Enter name or email",
    add_new_user: "Add new user",
    added_new_user: "New user successfully added",
    edit_program: "Edit program",
    edit_roles: "Edit roles",
    authorization_management: "Authorization management",
    add_user: "Add User",
    global_admin_user_description:
      "<p>You can modify the roles for the following users. For more information on the access permissions for various roles, please refer to the 'Roles and Permissions' document in the Documents section of DMTS.</p>",
    program_admin_user_description:
      '<p>You can modify the roles for the following users. For more information on the access permissions for various roles, please refer to the <u>Roles and Permissions</u> document in the Documents section of DMTS.<br><br>Please contact <a href="mailto:SGSDEP-SARDMTS@ec.gc.ca">SGSDEP-SARDMTS@ec.gc.ca</a> to request assistance with getting users access/roles.</p>',
    show_deactivated_users_only: "Show deactivated users only",
    edit_roles_modal_description:
      "<p>Here, you can assign and unassign roles to this user. You will only be able to select roles associated to the user's assigned program.</p><p>More information on the access permissions for the various roles, please refer to the <u>Roles and Permissions</u> document, an internal document found in the Documents section of DMTS.</p>",
    edit_program_rest_roles: "Edit program Reset rules",
    edit_program_modal_description:
      "By assigning the user to a different program, all previously added roles will be removed. The user will have 'Global viewer' access, and any additional roles will have to be re-assigned.",
    user_form_heading1:
      "<p>You can add new users to DMTS by searching for their name or email below, and assign them a program. Upon saving, the user will be given the 'Global viewer' role.</p>",
    user_form_heading2:
      '<p>If you cannot find the user from search below, it might mean that they need to be first added into the ECCC directory. Please contact <u>SGSDEP-SARDMTS@ec.gc.ca</u> to request access for their email to be added.</p>',
  },

  fr: {
    user: "Utilisateur",
    users: "Utilisateurs",
    roles: "Rôles",
    cosewic_users: "Utilisateurs du COSEPAC",
    recovery_users: "Utilisateurs du programme de Rétablissement",
    listing_users: "Utilisateurs du programme de l'Inscription",
    protection_users: "Utilisateurs du programme de Protection",
    search_name_or_email: "Rechercher un nom ou un courriel",
    enter_name_or_email: "Saisir un nom ou un courriel",
    add_new_user: "Ajouter un nouvel utilisateur",
    added_new_user: "Nouvel utilisateur ajouté avec succès",
    edit_program: "Modifier le programme",
    edit_roles: "Modifier les rôles",
    authorization_management: "Gestion des autorisations",
    add_user: "Ajouter un utilisateur",
    global_admin_user_description:
      "<p>Vous pouvez modifier les rôles des utilisateurs suivants. Pour plus d'informations sur les autorisations d'accès aux différents rôles, veuillez vous référer au document « Rôles et autorisations » dans la section Documents du SGSD.</p>",
    program_admin_user_description:
      "<p>Vous pouvez modifier les rôles des utilisateurs suivants. Pour plus d'informations sur les autorisations d'accès aux différents rôles, veuillez vous référer au document « Rôles et autorisations » dans la section Documents du SGSD. Veuillez contacter  <a href='mailto:SGSDEP-SARDMTS@ec.gc.ca'>SGSDEP-SARDMTS@ec.gc.ca</a> pour demander de l'aide afin d'obtenir des accès/rôles pour les utilisateurs.</p>",      
    show_deactivated_users_only:
      "Afficher uniquement les utilisateurs désactivés",
    edit_roles_modal_description:
      "<p>Ici, vous pouvez assigner et désassigner des rôles à cet utilisateur. Vous ne pourrez sélectionner que les rôles associés au programme assigné à l'utilisateur. Pour plus d'informations sur les autorisations d'accès aux différents rôles, veuillez vous référer au document « Rôles et autorisations », un document interne qui se trouve dans la section « Documents » du SGSD.</p>",
    edit_program_rest_roles: "Modifier le programme Réinitialiser les règles",
    edit_program_modal_description:
      "<p>En attribuant un programme différent à un utilisateur, tous les rôles précédemment ajoutés seront supprimés. L'utilisateur disposera d'un accès « Visualisateur globale » et tout rôle supplémentaire devra être réattribué.</p>",
    user_form_heading1:
      "<p>Vous pouvez ajouter des nouveaux utilisateurs us SGSD en recherchant leur nom ou leur courriel ci-dessous et en leur attribuant un programme. Lors de l'enregistrement, l'utilisateur se verra attribuer le rôle de « Visualisateur globale ».</p>",
    user_form_heading2:
      "<p>Si vous ne trouvez pas l'utilisateur à partir de la recherche ci-dessous, cela peut signifier qu'il doit d'abord être ajouté au répertoire ECCC. Veuillez contacter  <u>SGSDEP-SARDMTS@ec.gc.ca</u> pour demander que le courriel de l'utilisateur soit ajoutée au répertoire du ECCC.</P>",
  },
};

export default adminUserTranslation;
