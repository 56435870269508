import { UnregisterCallback } from "history";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useCurrentPath = (value: string): string | null => {
  const locationRef = useRef<string | null>(null);

  useEffect(() => {
    locationRef.current = value;
  }, [value]);
  return locationRef.current;
};

export const usePrompt = (
  isDirty: boolean,
  message: string = "Are you sure you want to quit without saving your changes?"
) => {
  const history = useHistory();
  const self = useRef<UnregisterCallback | null>();
  const { pathname } = useLocation();
  const previousLocation = useCurrentPath(pathname);

  const [submitClicked, setSubmitClicked] = useState(false);

  if (!submitClicked && window.event?.type === "submit") {
    setSubmitClicked(true);
  }

  useEffect(() => {
    const onWindowOrTabClose = (event: BeforeUnloadEvent) => {
      if (!isDirty) {
        return;
      }
      if (typeof event === "undefined") {
        event = window.event as BeforeUnloadEvent;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    };

    if (!submitClicked && window.event?.type === "submit") {
      setSubmitClicked(true);
    }

    if (isDirty && !submitClicked) {
      self.current = history.block((location, action) => {
        const isLocationChanged =
          previousLocation !== location.pathname && location.pathname !== "/";

        if (isLocationChanged) {
          const confirm = window.confirm(message);

          if (!confirm && action === "POP") {
            window.history.replaceState(null, "", `/#${previousLocation}`);
          }
          if (confirm) {
            return undefined;
          }
        }
        return false;
      });
      window.addEventListener("beforeunload", onWindowOrTabClose);
    } else {
      self.current = null;
    }

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }
      window.removeEventListener("beforeunload", onWindowOrTabClose);
    };
  }, [message, isDirty, history, previousLocation, submitClicked]);
};
