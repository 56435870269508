import i18n from "i18n";

export const buildTranslations = () => {
  return {
    fields: {
      title: i18n.t("select_program") + "," + i18n.t("property"),
      placeholderName: "~",
      placeholderLabel: "------",
      placeholderGroupLabel: "------",
    },
    operators: {
      title: i18n.t("action"),
      placeholderName: "~",
      placeholderLabel: "------",
      placeholderGroupLabel: "------",
    },
    value: {
      title: i18n.t("detail"),
    },
    removeRule: {
      label: "❌",
      title: i18n.t("remove_condition"),
    },
    removeGroup: {
      // label: "🗑️",
      label: "(❌)",
      title: i18n.t("remove_group"),
    },
    addRule: {
      label: "+ " + i18n.t("add_condition"),
      title: i18n.t("add_condition"),
    },
    addGroup: {
      label: "+ " + i18n.t("add_group"),
      title: i18n.t("add_group"),
    },
    combinators: {
      title: i18n.t("combinators"),
    },
    notToggle: {
      label: "Not",
      title: "Invert this group",
    },
    cloneRule: {
      label: "⧉",
      title: "Clone condition",
    },
    cloneRuleGroup: {
      label: "(⧉)",
      title: "Clone group",
    },
    dragHandle: {
      label: "⁞⁞",
      title: i18n.t("drag_handle"),
    },
    lockRule: {
      label: "🔓",
      title: "Lock rule",
    },
    lockGroup: {
      label: "🔓",
      title: "Lock group",
    },
    lockRuleDisabled: {
      label: "🔒",
      title: "Unlock rule",
    },
    lockGroupDisabled: {
      label: "🔒",
      title: "Unlock group",
    },
  };
};

export const buildClasses = () => {
  return {
    queryBuilder: "queryBuilder-branches mrgn-tp-md", // Root <div> element
    addGroup: "btn btn-default",
    addRule: "btn btn-default",
    cloneGroup: "btn btn-default",
    cloneRule: "btn btn-default",
    lockGroup: "btn btn-default",
    lockRule: "btn btn-default",
    removeGroup: "btn btn-default text-danger",
    removeRule: "btn btn-default text-danger",
    combinators: "form-control form-select form-select-sm",
    fields: "form-control form-select form-select-sm",
    operators: "form-control form-select form-select-sm",
    value: "form-control form-control-sm",
    valueSource: "form-control form-select form-select-sm",
  };
};
