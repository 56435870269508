import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME } from "config/constants";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { htmlToPlainText } from "util/richtext";
import SectionCard from "../../../atoms/SectionCard";
import { SpeciesProfileFormFields } from "./PublicProfileForm";

export interface DescriptionCardProps {
  initialValues: Partial<SpeciesProfileFormFields>;
}

export const DescriptionCard: React.FC<DescriptionCardProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const editLinkClicked = location.state;

  const form = useFormContext<SpeciesProfileFormFields>();
  const { control, formState, watch } = form;
  const { errors } = formState;

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME;

  console.log("editLinkClicked", editLinkClicked);
  return (
    <SectionCard
      collapsible
      open={editLinkClicked === t("description") || editLinkClicked == null}
      header={<h2>{t("description")}</h2>}
    >
      <div className="form-group">
        <label htmlFor="descriptionEnglish">{t("english")}</label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            required:
              watch("description.english.text") === "" &&
              watch("description.french.text") !== "",
            validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
          }}
          defaultValue={initialValues?.description?.english?.text ?? ""}
          id="descriptionEnglish"
          name="description.english.text"
        />
     
        {errors.description?.english &&
          (errors.description.english as any)?.text?.type === "required" && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

        {errors.description?.english &&
          (errors.description.english as any)?.text?.type ===
            "maxLengthLimit" && (
            <FieldValidationError>
              {t("reach_the_max_length", {
                count: MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME,
              })}
            </FieldValidationError>
          )}
      </div>

      <div className="form-group">
        <label htmlFor="descriptionFrench">{t("french")}</label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            required:
              watch("description.french.text") === "" &&
              watch("description.english.text") !== "",
            validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
          }}
          defaultValue={initialValues?.description?.french?.text ?? ""}
          id="descriptionFrench"
          name="description.french.text"
        />

        {errors.description?.french &&
          (errors.description.french as any)?.text?.type === "required" && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

        {errors.description?.french &&
          (errors.description.french as any)?.text?.type ===
            "maxLengthLimit" && (
            <FieldValidationError>
              {t("reach_the_max_length", {
                count: MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME,
              })}
            </FieldValidationError>
          )}
      </div>
    </SectionCard>
  );
};
