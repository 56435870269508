import { useQuery } from "@apollo/client";
import NoResults from "components/atoms/NoResults";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { NAICS_VERSION } from "../../../config/constants";
import {
  Maybe,
  NaicsResource,
  NaicsSectorListDocument,
} from "../../../generated/gql-types";
import LoadingIndicator from "../../atoms/LoadingIndicator";
import { useNAICSVersionContext } from "../listing/ListingSectorForm/NAICSVersionContext";

export interface NAICSSectorDropdownProps {
  value?: NaicsResource;
  onChange: (resource?: Maybe<NaicsResource>) => void;
}

export const NAICSSectorDropdown = React.forwardRef<
  HTMLSelectElement,
  NAICSSectorDropdownProps
>(({ value, onChange, ...props }, ref) => {
  const { t, i18n } = useTranslation();
  const [naicsVersionNumberFromContext, setNaicsVersionNumber] =
    useNAICSVersionContext();

  const { loading, error, data } = useQuery(NaicsSectorListDocument, {
    variables: {
      params: {
        level: "1",
        pageNumber: 1,
        pageSize: 50,
        version: NAICS_VERSION,
      },
    },
    onCompleted: (_data) => {
      // Update the NAICS version context, if it's been provided (see comments in context file for more details on this)
      if (_data?.naicsList?.naics.length === 0) return;
      const foundVersion = _data?.naicsList?.naics[0]?.classVersion;
      if (foundVersion === naicsVersionNumberFromContext) return;
      setNaicsVersionNumber(foundVersion ?? "");
    },
  });

  const findResourceOnChange = (e: any) => {
    const code = e.currentTarget.value;
    const resource = data?.naicsList?.naics.find((x) => x?.code === code);
    onChange(resource);
  };

  return (
    <div>
      <label htmlFor="sectorSelect" className="required">
        {t("sector")}
        <strong className="required"> ({t("required")})</strong>
        <span className="inline-flex gap-sm mrgn-lft-md">
          {loading ? (
            <LoadingIndicator />
          ) : error ? (
            <span className="text-muted font-style-italic">
              {t("loading_error")}
            </span>
          ) : data?.naicsList?.naics.length === 0 ? (
            <NoResults />
          ) : null}
        </span>
      </label>
      <select
        id="sectorSelect"
        className="form-control width-100"
        ref={ref}
        {...props}
        defaultValue={value?.code ?? undefined}
        onChange={findResourceOnChange}
        disabled={
          loading || error != null || data?.naicsList?.naics.length === 0
        }
        required
      >
        <option disabled selected hidden>
          {t("select_a_value")}
        </option>

        {data?.naicsList?.naics.map((x) => {
          return (
            <option key={x?.code} value={x?.code ?? "error - missing code"}>
              {x?.code} {" - "}
              {i18n.language === "fr"
                ? x?.className?.french ?? ""
                : x?.className?.english ?? ""}
            </option>
          );
        })}
      </select>
    </div>
  );
});
export interface NAICSSectorDropdownWithControllerProps<TFieldValues>
  extends Omit<NAICSSectorDropdownProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const NAICSSectorDropdownWithController = <TFieldValues,>({
  ...props
}: NAICSSectorDropdownWithControllerProps<TFieldValues>) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <NAICSSectorDropdown value={value as any} onChange={onChange} />
      )}
    />
  );
};
