import { useMutation } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { Switch } from "components/atoms/Switch";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  DistributionListDocument,
  DistributionListMemberVisibility,
  UpdateDistributionListMemberVisibilityDocument,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface VisibilitySwitchProps {
  distributionListId: string;
  memberId: string;
  visibilityState: DistributionListMemberVisibility;
}

export const VisibilitySwitch: React.FC<VisibilitySwitchProps> = (props) => {
  const { distributionListId, memberId, visibilityState } = props;
  const alertContext = useGlobalAlertContext();
  const { t } = useTranslation();

  const [switchState, setSwitchState] =
    React.useState<DistributionListMemberVisibility>(visibilityState);

  const [updateVisibilityState, updateVisibilityStateMutationStatus] =
    useMutation(UpdateDistributionListMemberVisibilityDocument, {
      refetchQueries: [
        {
          query: DistributionListDocument,
          variables: { distributionListId: distributionListId },
        },
      ],
      // awaitRefetchQueries: true,
      onError: (_err) => {
        alertContext.showError({
          //   title: t("update_fail"),
          message: _err.message,
          timeOut: 5000,
        });
      },
    });

  const onVisibilityStateChange = async (
    memberId: string,
    visibilityState: DistributionListMemberVisibility
  ) => {
    if (memberId == null || memberId === "") return false;
    try {
      const res = await updateVisibilityState({
        variables: {
          distributionListId: distributionListId,
          memberId: memberId,
          visibilityState: visibilityState,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  };

  return (
    <>
      <Switch
        aria-label={"include"}
        defaultSelected={
          switchState === DistributionListMemberVisibility.Include
        }
        isSelected={switchState === DistributionListMemberVisibility.Include}
        onChange={async () => {
          if (switchState === DistributionListMemberVisibility.Include) {
            const changed = await onVisibilityStateChange(
              memberId,
              DistributionListMemberVisibility.Exclude
            );

            if (changed) {
              setSwitchState(DistributionListMemberVisibility.Exclude);
            } else {
              setSwitchState(DistributionListMemberVisibility.Include);
            }
          } else {
            const changed = await onVisibilityStateChange(
              memberId,
              DistributionListMemberVisibility.Include
            );

            if (changed) {
              setSwitchState(DistributionListMemberVisibility.Include);
            } else {
              setSwitchState(DistributionListMemberVisibility.Exclude);
            }
          }
        }}
      />
      {updateVisibilityStateMutationStatus.loading ? (
        <LoadingIndicator className="vertical-align-top" />
      ) : null}
    </>
  );
};
