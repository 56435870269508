import { useApolloClient, useMutation } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import GraphqlError from "components/GraphqlError";
import Alert from "components/atoms/Alert";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import ContactsAdminPageFilterForm, {
  QueryFields,
  defaultQueryFields,
} from "components/molecules/admin/ContactsAdminPageFilterForm";
import AdminArchiveModal from "components/organisms/admin/AdminArchiveModal";
import AdminUnarchiveModal from "components/organisms/admin/AdminUnarchiveModal";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import {
  ArchiveStatus,
  ContactsForAdminContactDocument,
  DeactivateContactDocument,
  ReactivateContactDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../features/auth/components";
import { GlobalAlert } from "../../features/globalAlert";
import isNullOrEmpty from "../../util/isNullOrEmpty";
import { AdminPathHelpers } from "./AdminRouter";

const Contact: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const adminAlertMsg = useAdminAlertMsg(t("contact"));
  const client = useApolloClient();
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const pagination = usePagination();

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "name":
          return "name.firstName";
        case "emailAddress":
          return "contactDetails.emailAddress";
        case "organization":
          return i18n.language === "fr"
            ? "organizationDetails.name.french"
            : "organizationDetails.name.english";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const rows = useAsyncList<any>({
    initialSortDescriptor: {
      column: "name",
      direction: "ascending",
    },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        errorPolicy: "all",
        query: ContactsForAdminContactDocument,
        variables: {
          params: {
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
            status: filterText.showInactive
              ? ArchiveStatus.Inactive
              : ArchiveStatus.Active,
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
            search:
              filterText.search.length > 0 ? filterText.search : undefined,
            tags: filterText.tags === "" ? undefined : filterText.tags,
          },
        },
        fetchPolicy: "network-only",
      });

      const items =
        results.data.contactList?.contact?.map((contact) => {
          let name: string | undefined = "";

          if (contact?.name?.firstName != null) {
            name += `${contact?.name?.firstName} `;
          }

          if (contact?.name?.lastName != null) {
            name += `${contact?.name?.lastName}`;
          }

          // Show "no data" if the name is empty
          if (name.length === 0) {
            name = undefined;
          }

          return {
            ...contact,
            createdAt: formatTimestamp(contact?.createdAt),
            modifiedAt: formatTimestamp(contact?.modifiedAt),
            name: name,
            emailAddress: contact?.contactDetails?.emailAddress,
            organization:
              i18n.language === "fr"
                ? contact?.organizationDetails?.name?.french
                : contact?.organizationDetails?.name?.english,
          };
        }) ?? [];

      setTotalCount(results.data.contactList?.pagination?.totalCount ?? 0);
      setTotalPages(results.data.contactList?.pagination?.totalPages ?? 0);

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const [runArchive] = useMutation(DeactivateContactDocument, {
    onError: (_err) => {
      adminAlertMsg.onArchiveError();
    },
    onCompleted: (data) => {
      const name = getName(data?.deactivateContact);
      adminAlertMsg.onArchiveSuccess(name, name);
      rows.reload(); // refetchQueries
      setSelectedRowId("");
    },
  });

  const [runUnarchive] = useMutation(ReactivateContactDocument, {
    onError: (_err) => {
      adminAlertMsg.onUnarchiveError();
    },
    onCompleted: (data) => {
      const name = getName(data?.reactivateContact);
      adminAlertMsg.onUnarchiveSuccess(name, name);
      rows.reload(); // refetchQueries
      setSelectedRowId("");
    },
  });

  const getName = (data: any) => {
    let name: string | undefined = "";

    if (data?.name?.firstName != null) {
      name += `${data?.name?.firstName} `;
    }

    if (data?.name?.lastName != null) {
      name += `${data?.name?.lastName}`;
    }

    if (name.length === 0) {
      name = "Contact";
    }
    return name;
  };

  const onAddContact = () => {
    history.push({
      pathname: AdminPathHelpers.ContactsCreate(),
    });
  };

  const onEditContact = () => {
    history.push({
      pathname: AdminPathHelpers.ContactsEdit(selectedRowId),
    });
  };

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const onArchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runArchive({
      variables: { deactivateContactId: selectedRowId },
    });
  };

  const onUnarchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runUnarchive({
      variables: { reactivateContactId: selectedRowId },
    });
  };

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  React.useEffect(() => {
    rows.reload(); // refetchQueries
    setSelectedRowId("");
  }, [pagination.currentPage, pagination.pageSize]);

  const columns = React.useMemo(
    () => [
      {
        name: t("name"),
        key: "name",
        sortable: true,
      },
      {
        name: t("email"),
        key: "emailAddress",
        sortable: true,
      },
      {
        name: t("organization"),
        key: "organization",
        sortable: true,
      },
      {
        name: t("created_by"),
        key: "createdBy",
        sortable: true,
      },
      {
        name: t("created_on"),
        key: "createdAt",
        sortable: true,
      },
      {
        name: t("updated_by"),
        key: "modifiedBy",
        sortable: true,
      },
      {
        name: t("updated_date"),
        key: "modifiedAt",
        sortable: true,
      },
    ],
    [t]
  );

  const selectedRow = rows.items.find((x) => x.id === selectedRowId);

  const onFilterSubmit = (arr: QueryFields) => {
    flushSync(() => {
      setFilters(arr);
      rows.setFilterText(arr as any);
      pagination.goToPage(1);
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("contacts")}</h1>
          <GraphqlError title="Error loading Contacts" errors={rows?.error} />

          {adminAlertMsg.alertIsVisible ? (
            <Alert
              type={adminAlertMsg.pageAlertType!}
              content={adminAlertMsg.pageAlertMsg!}
              onClose={adminAlertMsg.close}
              timeOut={5000}
            />
          ) : null}

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("list_of_contacts")}</h2>
                <div className="flex gap-md">
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.contact.create}
                  >
                    <button
                      type="button"
                      className="btn btn-link card-header-button-link"
                      onClick={onAddContact}
                      data-testid="button-contact-add"
                    >
                      {t("add")}
                    </button>
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.contact.update}
                  >
                    <button
                      type="button"
                      className="btn btn-link card-header-button-link"
                      onClick={onEditContact}
                      data-testid="button-contact-edit"
                      disabled={isNullOrEmpty(selectedRowId)}
                    >
                      {t("edit")}
                    </button>
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.contact.deactivateReactivate
                    }
                  >
                    {!filters.showInactive ? (
                      <AdminArchiveModal
                        onArchive={onArchive}
                        modalTitle={t("archive_a_contact")}
                        warningText={t("archive_contact_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.contact.deactivateReactivate
                    }
                  >
                    {filters.showInactive &&
                    (!selectedRow ||
                      selectedRow?.status === ArchiveStatus.Inactive) ? (
                      <AdminUnarchiveModal
                        onUnarchive={onUnarchive}
                        modalTitle={t("unarchive_a_contact")}
                        warningText={t("unarchive_contact_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <ContactsAdminPageFilterForm onSubmit={onFilterSubmit} />
              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16 justify-end pb-3">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>

            {/*RESULTS*/}
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows && rows?.items?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                {rows == null || rows?.items?.length === 0 ? null : (
                  <>
                    <ResultsTable
                      rows={rows.items}
                      columns={columns}
                      onSelectionChange={onSelectionChange as any}
                      selectedKeys={[selectedRowId]}
                      sortable
                      selectionMode="single"
                      showSelectionCheckboxes
                      sortDescriptor={rows.sortDescriptor}
                      onSortChange={rows.sort}
                    />

                    <Pagination
                      {...pagination.paginationComponentProps}
                      totalPages={totalPages ?? 0}
                    />
                  </>
                )}
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default Contact;
