import {
  AssessmentFormFields,
  AssessmentFormOutput,
} from "components/organisms/cosewic/assessment/AssessmentForm";
import {
  ApplicabilityOfCriteriaChangeMetaData,
  Assessment,
  AssessmentInput,
  AssessmentType,
  AssessmentUpdateInput,
  BilingualRichTextChangeMetaData,
  DesignationChangeMetaData,
  DesignationInput,
  KeyValueRichTextInput,
  StatusAndCriteriaChangeMetaData,
  StatusAndCriteriaInput,
} from "generated/gql-types";
import { makeBilingualRichText, makeRichTextInput } from "mappers";
import { checkApplicabilityOfCriteriaIsDirty } from "../../../components/organisms/cosewic/assessment/AssessmentForm/assessmentFormSubmitMapper";
import htmlIsNullOrEmpty from "../../htmlIsNullOrEmpty";

/// Convert GraphQL types -> form field interface, for use in assessment form components.
/// We flatten and map some fields so they're easier to work with in react-hook-form.
export const convertAssessmentToFormFields = (
  assessment?: Assessment
): AssessmentFormFields => {
  return {
    date: assessment?.date?.substring(0, 10) ?? "", // TODO: string hack here. Can we save a timestamp instead?
    type: assessment?.type ?? AssessmentType.Regular,
    reportType: assessment?.reportType ?? undefined,
    order: assessment?.order ?? "",
    dateSentToMinister: assessment?.dateSentToMinister?.substring(0, 10) ?? "",

    // statusAndCriteria
    statusAndCriteria_status:
      assessment?.statusAndCriteria?.status ?? undefined,
    statusAndCriteria_statusComment: makeBilingualRichText(
      assessment?.statusAndCriteria?.statusComment
    ),
    statusAndCriteria_statusChange:
      assessment?.statusAndCriteria?.statusChange ?? undefined,
    statusAndCriteria_statusCriteria: makeBilingualRichText(
      assessment?.statusAndCriteria?.statusCriteria
    ),
    statusAndCriteria_applicabilityOfCriteriaA: makeRichTextInput(
      assessment?.statusAndCriteria?.applicabilityOfCriteria?.find(
        (x) => x?.key === "A"
      )?.value
    ),
    statusAndCriteria_applicabilityOfCriteriaB: makeRichTextInput(
      assessment?.statusAndCriteria?.applicabilityOfCriteria?.find(
        (x) => x?.key === "B"
      )?.value
    ),
    statusAndCriteria_applicabilityOfCriteriaC: makeRichTextInput(
      assessment?.statusAndCriteria?.applicabilityOfCriteria?.find(
        (x) => x?.key === "C"
      )?.value
    ),
    statusAndCriteria_applicabilityOfCriteriaD: makeRichTextInput(
      assessment?.statusAndCriteria?.applicabilityOfCriteria?.find(
        (x) => x?.key === "D"
      )?.value
    ),
    statusAndCriteria_applicabilityOfCriteriaE: makeRichTextInput(
      assessment?.statusAndCriteria?.applicabilityOfCriteria?.find(
        (x) => x?.key === "E"
      )?.value
    ),

    // designation
    designation_historyStatus: makeBilingualRichText(
      assessment?.designation?.historyStatus
    ),
    designation_reason: makeBilingualRichText(assessment?.designation?.reason),
  };
};

/// Convert form output interface -> GraphQL types, for COSEWIC assessment CREATE mutation.
/// Only includes values which are present in the form output.
export const convertFormToCreateInput = (
  formOutput: Partial<AssessmentFormOutput>
): AssessmentInput => {
  // Top level
  const out: AssessmentInput = {};

  // Top level single values
  if (formOutput.date) out.date = formOutput.date;
  if (formOutput.type) out.type = formOutput.type;
  if (formOutput.reportType) out.reportType = formOutput.reportType;
  if (formOutput.order !== undefined) out.order = formOutput.order;

  return out;
};

/// Convert form output interface -> GraphQL types, for COSEWIC assessment UPDATE mutation.
/// Only includes values which are present in the form output.
export const convertFormToUpdateInput = (
  formOutput: Partial<AssessmentFormOutput>,
  dirtyFields?: any
): AssessmentUpdateInput => {
  // Base our output on a conversion to the basic AssessmentInput type,
  // since AssessmentUpdateInput adds extra sections & change tracking fields on top of it.
  const createInput = convertFormToCreateInput(formOutput);

  const out: AssessmentUpdateInput = { ...createInput };
  if (formOutput.dateSentToMinister)
    out.dateSentToMinister = formOutput.dateSentToMinister;

  // Nested objects
  const statusAndCriteria: StatusAndCriteriaInput = {};
  const applicabilityOfCriteria: Array<KeyValueRichTextInput> = [];
  const designation: DesignationInput = {};

  if (formOutput.order) out.order = formOutput.order;
  // Status and Criteria
  if (formOutput.statusAndCriteria_status)
    statusAndCriteria.status = formOutput.statusAndCriteria_status;
  if (formOutput.statusAndCriteria_statusChange)
    statusAndCriteria.statusChange = formOutput.statusAndCriteria_statusChange;
  if (formOutput.statusAndCriteria_statusCriteria)
    statusAndCriteria.statusCriteria =
      formOutput.statusAndCriteria_statusCriteria;
  if (formOutput.statusAndCriteria_statusComment)
    statusAndCriteria.statusComment =
      formOutput.statusAndCriteria_statusComment;

  const processApplicabilityOfCriteriaItem = (key: string, value: any) => {
    if (htmlIsNullOrEmpty(value?.text)) return;
    applicabilityOfCriteria.push({ key, value });
  };

  processApplicabilityOfCriteriaItem(
    "A",
    formOutput.statusAndCriteria_applicabilityOfCriteriaA
  );

  processApplicabilityOfCriteriaItem(
    "B",
    formOutput.statusAndCriteria_applicabilityOfCriteriaB
  );

  processApplicabilityOfCriteriaItem(
    "C",
    formOutput.statusAndCriteria_applicabilityOfCriteriaC
  );

  processApplicabilityOfCriteriaItem(
    "D",
    formOutput.statusAndCriteria_applicabilityOfCriteriaD
  );

  processApplicabilityOfCriteriaItem(
    "E",
    formOutput.statusAndCriteria_applicabilityOfCriteriaE
  );

  // Designation
  if (formOutput.designation_historyStatus)
    designation.historyStatus = formOutput.designation_historyStatus;
  if (formOutput.designation_reason)
    designation.reason = formOutput.designation_reason;

  // Add nested objects
  if (dirtyFields && checkApplicabilityOfCriteriaIsDirty(dirtyFields))
    statusAndCriteria.applicabilityOfCriteria = applicabilityOfCriteria;
  if (Object.keys(statusAndCriteria).length > 0)
    out.statusAndCriteria = statusAndCriteria;
  if (Object.keys(designation).length > 0) out.designation = designation;

  //
  // CHANGE TRACKING

  // Nested status and criteria
  const statusAndCriteriaChangeMetaData: StatusAndCriteriaChangeMetaData = {};
  const statusCommentChangeMetaData: BilingualRichTextChangeMetaData = {};
  const statusCriteriaChangeMetaData: BilingualRichTextChangeMetaData = {};
  const applicabilityOfCriteriaChangeMetaData: Array<ApplicabilityOfCriteriaChangeMetaData> =
    [];
  // Nested designation
  const designationChangeMetaData: DesignationChangeMetaData = {};
  const historyStatusChangeMetaData: BilingualRichTextChangeMetaData = {};
  const reasonChangeMetaData: BilingualRichTextChangeMetaData = {};

  if (formOutput.dateChangeMetaData)
    out.dateChangeMetaData = formOutput.dateChangeMetaData;

  // Status and Criteria nested object
  if (
    formOutput.statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English
  )
    statusCommentChangeMetaData.english =
      formOutput.statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English;
  if (
    formOutput.statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French
  )
    statusCommentChangeMetaData.french =
      formOutput.statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French;
  if (
    formOutput.statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_English
  )
    statusCriteriaChangeMetaData.english =
      formOutput.statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_English;
  if (
    formOutput.statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_French
  )
    statusCriteriaChangeMetaData.french =
      formOutput.statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_French;
  if (
    formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataA
  )
    applicabilityOfCriteriaChangeMetaData.push({
      key: "A",
      value:
        formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataA,
    });
  if (
    formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataB
  )
    applicabilityOfCriteriaChangeMetaData.push({
      key: "B",
      value:
        formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataB,
    });
  if (
    formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataC
  )
    applicabilityOfCriteriaChangeMetaData.push({
      key: "C",
      value:
        formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataC,
    });
  if (
    formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataD
  )
    applicabilityOfCriteriaChangeMetaData.push({
      key: "D",
      value:
        formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataD,
    });
  if (
    formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataE
  )
    applicabilityOfCriteriaChangeMetaData.push({
      key: "E",
      value:
        formOutput.statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataE,
    });
  if (applicabilityOfCriteriaChangeMetaData.length > 0)
    statusAndCriteriaChangeMetaData.applicabilityOfCriteriaChangeMetaData =
      applicabilityOfCriteriaChangeMetaData;
  if (Object.keys(statusCommentChangeMetaData).length > 0)
    statusAndCriteriaChangeMetaData.statusCommentChangeMetaData =
      statusCommentChangeMetaData;
  if (Object.keys(statusCriteriaChangeMetaData).length > 0)
    statusAndCriteriaChangeMetaData.statusCriteriaChangeMetaData =
      statusCriteriaChangeMetaData;

  // Designation nested object
  if (formOutput.designationChangeMetaData_historyStatusChangeMetaData_English)
    historyStatusChangeMetaData.english =
      formOutput.designationChangeMetaData_historyStatusChangeMetaData_English;
  if (formOutput.designationChangeMetaData_historyStatusChangeMetaData_French)
    historyStatusChangeMetaData.french =
      formOutput.designationChangeMetaData_historyStatusChangeMetaData_French;
  if (formOutput.designationChangeMetaData_reasonChangeMetaData_English)
    reasonChangeMetaData.english =
      formOutput.designationChangeMetaData_reasonChangeMetaData_English;
  if (formOutput.designationChangeMetaData_reasonChangeMetaData_French)
    reasonChangeMetaData.french =
      formOutput.designationChangeMetaData_reasonChangeMetaData_French;
  if (Object.keys(historyStatusChangeMetaData).length > 0)
    designationChangeMetaData.historyStatusChangeMetaData =
      historyStatusChangeMetaData;
  if (Object.keys(reasonChangeMetaData).length > 0)
    designationChangeMetaData.reasonChangeMetaData = reasonChangeMetaData;

  // Add nested objects
  if (Object.keys(statusAndCriteriaChangeMetaData).length > 0)
    out.statusAndCriteriaChangeMetaData = statusAndCriteriaChangeMetaData;
  if (Object.keys(designationChangeMetaData).length > 0)
    out.designationChangeMetaData = designationChangeMetaData;

  return out;
};
