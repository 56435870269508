import AssessmentForm, {
  AssessmentFormOutput,
} from "components/organisms/cosewic/assessment/AssessmentForm";
import { Assessment } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { convertAssessmentToFormFields } from "util/cosewic/assessment/forms";

export interface AssessmentDataEntryProps {
  assessment?: Assessment;
  areRelatedWildlifeSpeciesStillDraft: boolean;
  showDateSentToMinister: boolean;
  onSubmit: SubmitHandler<Partial<AssessmentFormOutput>>;
}

const AssessmentDataEntry: React.FC<AssessmentDataEntryProps> = (props) => {
  const {
    assessment,
    areRelatedWildlifeSpeciesStillDraft,
    showDateSentToMinister,
  } = props;

  const formInitialValues = convertAssessmentToFormFields(assessment);

  const onSubmit: SubmitHandler<Partial<AssessmentFormOutput>> = (
    data,
    dirtyFields?: any
  ) => {
    props.onSubmit(data, dirtyFields);
  };

  return (
    <AssessmentForm
      defaultValues={formInitialValues}
      assessment={assessment}
      areRelatedWildlifeSpeciesStillDraft={areRelatedWildlifeSpeciesStillDraft}
      showDateSentToMinister={showDateSentToMinister}
      onSubmit={onSubmit}
    />
  );
};

export default AssessmentDataEntry;
