import { useMutation, useQuery } from "@tanstack/react-query";
import ExportButton from "components/atoms/ExportButton";
import Layout from "components/layouts/OneColumn";
import { FilterKeywordSection } from "components/organisms/search/FilterKeywordSection/FilterKeywordSection";
import odataMappers from "components/organisms/search/FilterOptionsSection/filters/filters";
import { GlobalAlert } from "features/globalAlert";
import { INDEX_NAME } from "features/search/assessment/constants";
import facetsList from "features/search/assessment/facets.json";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import { omit } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import SectionCard from "../../components/atoms/SectionCard";
import PageSizeSelect from "../../components/molecules/PageSizeSelect";
import Pagination from "../../components/molecules/Pagination";
import BasicSearchCheckboxListDetails from "../../components/molecules/search/BasicSearchCheckboxListDetails";
import BasicSearchDateRange from "../../components/molecules/search/BasicSearchDateRange";
import FilterTags from "../../components/organisms/search/FilterTags/FilterTags";
import ResultsTable, {
  ColumnHeader,
} from "../../components/organisms/search/ResultsTable/ResultsTable";
import { useBasicSearchQuery } from "../../features/search/hooks/useBasicSearchQuery";
import { fetchFilters } from "../../features/search/utils/fetchFilters";
import { makeFilterString } from "../../features/search/utils/makeFilterString";
import { makePaging } from "../../features/search/utils/makePaging";
import {
  SearchServiceExportParams,
  fetchExport,
} from "../../util/basicSearch/fetchExport";
import { formatTimestamp } from "../../util/formatTimestamp";
import useBasicSearch from "./hooks/useBasicSearch";

interface AssessmentSearchPageColumns {
  id: string;
  "Cosewic/CosewicId": string;
  "Cosewic/CommonNameEnglish/Name": string;
  "Cosewic/CommonNameFrench/Name": string;
  "Assessment/Date": string;
  "Assessment/StatusAndCriteria/Status": string;
  "Cosewic/Status": string;
  "Cosewic/State": string;
  "Assessment/Stage": string;
  "Assessment/State": string;
}

const searchFields =
  "Cosewic/CosewicIdStr, Cosewic/CommonNameEnglish/Name, Cosewic/CommonNameFrench/Name, Cosewic/Population/NameEn, Cosewic/Population/NameFr";
const queryType = "full";
const searchMode = "any";
const select =
  "Id, WsInfoChangesUnpublished, Cosewic/Id, Assessment/AssessmentId, Cosewic/CosewicId, Cosewic/CommonNameEnglish/Name, Cosewic/CommonNameFrench/Name, Cosewic/Population/NameEn, Cosewic/Population/NameFr, Assessment/Date, Assessment/StatusAndCriteria/Status, Cosewic/Status, Cosewic/State, Assessment/Stage, Assessment/State";

const assessmentExtraFilters = {};

const AssessmentSearchPage: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);

  const basicSearch = useBasicSearch("basicsearch_assessment");
  const filtersQuery = useQuery(
    ["basicSearch", "assessment", "filters"],
    () => {
      return fetchFilters(facetsList, INDEX_NAME, assessmentExtraFilters);
    },
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const [showAllAssessmentFilters, setShowAllAssessmentFilters] =
    useState(false);

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      const column =
        i18n.language === "fr"
          ? "Cosewic/CommonNameFrench/Name asc, Cosewic/Population/NameFr"
          : "Cosewic/CommonNameEnglish/Name asc, Cosewic/Population/NameEn";

      return makeOrderByString({
        column,
        direction: "ascending",
      });
    }

    return makeOrderByString(sortDescriptor);
  }, [i18n.language, sortDescriptor]);

  const { data, loading, error, newRunSearchQuery } =
    useBasicSearchQuery(INDEX_NAME);
  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      const filterString = makeFilterString(
        odataMappers,
        basicSearch.state,
        assessmentExtraFilters
      );

      const paging = makePaging(pagination);

      return {
        filter: filterString,
        search: basicSearch.state.keywordSearchText,
        queryType,
        searchMode,
        searchFields,
        select,
        orderby: orderByString,
        count: true,
        ...paging,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      newRunSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  useEffect(
    function autoResetPageNumber() {
      const newPageNumber = 1;
      pagination.goToPage(newPageNumber);
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      i18n.language,
    ]
  );

  const onSearchClick = async () => {
    await newRunSearchQuery(azureSearchArguments);
  };

  const onResetSearch = async () => {
    basicSearch.dispatch({ type: "reset_keyword_search" });
    pagination.goToPage(1);
    exportMutation.reset();
  };

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("common_name_with_population"),
        key:
          i18n.language === "fr"
            ? "Cosewic/CommonNameFrench/Name"
            : "Cosewic/CommonNameEnglish/Name",
        sortable: true,
        hasLink: true,
      },
      {
        name: t("assessment_date"),
        key: "Assessment/Date",
        sortable: true,
        isHTML: true,
      },
      {
        name: t("cosewic_status"),
        key: "Assessment/StatusAndCriteria/Status",
        sortable: true,
      },
      {
        name: t("ws_stage"),
        key: "Cosewic/Status",
        sortable: true,
      },
      {
        name: t("ws_state"),
        key: "Cosewic/State",
        sortable: true,
        minWidth: 93, // pixel
      },
      {
        name: t("assessment_stage"),
        key: "Assessment/Stage",
        sortable: true,
      },
      {
        name: t("assessment_state"),
        key: "Assessment/State",
        sortable: true,
      },
    ],
    [i18n.language]
  );

  //
  // Map search response to columns

  const rows: Array<AssessmentSearchPageColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let wildlifeSpeciesName = "";
        if (i18n.language === "fr") {
          wildlifeSpeciesName =
            value?.Cosewic?.CommonNameFrench?.Name ??
            value?.Cosewic?.CommonNameEnglish?.Name;
          if (value?.Cosewic?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Cosewic?.Population.NameFr ??
              value?.Cosewic?.Population?.NameEn;
        } else {
          wildlifeSpeciesName = value?.Cosewic?.CommonNameEnglish?.Name;
          if (value?.Cosewic?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Cosewic?.Population?.NameEn ??
              value?.Cosewic?.Population?.NameFr;
        }

        return {
          _href: `/cosewic/${value?.Cosewic?.Id}/assessment/${value?.Assessment?.AssessmentId}`,
          id: value?.Id,
          [i18n.language === "fr"
            ? "Cosewic/CommonNameFrench/Name"
            : "Cosewic/CommonNameEnglish/Name"]: wildlifeSpeciesName,
          "Assessment/Date": formatTimestamp(value?.Assessment?.Date),
          "Assessment/StatusAndCriteria/Status": t(
            value?.Assessment?.StatusAndCriteria?.Status
          ),
          "Cosewic/Status": t(value?.Cosewic?.Status),
          "Cosewic/State": t(value?.Cosewic?.State),
          "Assessment/Stage": t(value?.Assessment?.Stage),
          "Assessment/State": t(value?.Assessment?.State),
        };
      }),
    [data?.value]
  );

  const exportMutation = useMutation(
    ["basicSearch", "assessment", "export"],
    (params: any) => fetchExport(params, INDEX_NAME)
  );

  const runExport = async () => {
    const languageCode = i18n.language as any;

    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    const baseSearchArguments = omit(azureSearchArguments, ["count", "select"]);

    const searchParameters: SearchServiceExportParams = {
      ...baseSearchArguments,

      searchTemplateName: "FULL",
      includeTotalCount: true,
      searchFields: searchFields.split(/,\s?/g),
      orderby: orderByString?.split(/,\s?/g),
    };

    return exportMutation.mutate({
      searchParameters,
      languageCode,
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("assessment_search")}</h1>
          <div className="row">
            <div className="col-md-3">
              <SectionCard
                header={<h2 className="mrgn-tp-0">{t("search_filters")}</h2>}
                id="search-filters"
                className="pb-0"
                showLine={false}
              >
                <div className="pt-0 separator-line-top"></div>
                <details className="species-details" open={true}>
                  <summary>
                    <h3 className="mrgn-tp-0 mrgn-bttm-sm">
                      {t("assessment_filters")}
                    </h3>
                  </summary>
                  <section id="cosewic-filters" className="pt-2 mrgn-bttm-md">
                    <button
                      type="button"
                      className="btn btn-link px-2 py-2"
                      onClick={() =>
                        setShowAllAssessmentFilters(!showAllAssessmentFilters)
                      }
                      data-testid="button-show-all-assessment"
                    >
                      {!showAllAssessmentFilters
                        ? t("show_all")
                        : t("hide_all")}
                    </button>

                    <BasicSearchDateRange
                      label={t("assessment_date")}
                      facetName={"Assessment/Date"}
                      open={showAllAssessmentFilters}
                      basicSearch={basicSearch}
                      allFacets={allFacets}
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("assessment_type")}
                      facetName={"Assessment/Type"}
                      open={showAllAssessmentFilters}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      translateItems
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("type_of_report")}
                      facetName={"Assessment/ReportType"}
                      open={showAllAssessmentFilters}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      translateItems
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("status_change")}
                      facetName={"Assessment/StatusAndCriteria/StatusChange"}
                      open={showAllAssessmentFilters}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      translateItems
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("assessment_stage")}
                      facetName={"Assessment/Stage"}
                      open={showAllAssessmentFilters}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      translateItems
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("assessment_state")}
                      facetName={"Assessment/State"}
                      open={showAllAssessmentFilters}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      translateItems
                    />

                    {i18n.language === "fr" ? (
                      <BasicSearchCheckboxListDetails
                        header={t("taxonomic_group")}
                        facetName={"Cosewic/TaxonomicGroup/NameFr"}
                        open={showAllAssessmentFilters}
                        allFacets={allFacets}
                        basicSearch={basicSearch}
                      />
                    ) : (
                      <BasicSearchCheckboxListDetails
                        header={t("taxonomic_group")}
                        facetName={"Cosewic/TaxonomicGroup/NameEn"}
                        open={showAllAssessmentFilters}
                        allFacets={allFacets}
                        basicSearch={basicSearch}
                      />
                    )}

                    {i18n.language === "fr" ? (
                      <BasicSearchCheckboxListDetails
                        header={t("range")}
                        facetName={"Cosewic/Ranges/NameFr"}
                        open={showAllAssessmentFilters}
                        allFacets={allFacets}
                        basicSearch={basicSearch}
                      />
                    ) : (
                      <BasicSearchCheckboxListDetails
                        header={t("range")}
                        facetName={"Cosewic/Ranges/NameEn"}
                        open={showAllAssessmentFilters}
                        allFacets={allFacets}
                        basicSearch={basicSearch}
                      />
                    )}

                    <BasicSearchCheckboxListDetails
                      header={t("cosewic_status")}
                      facetName={"Assessment/StatusAndCriteria/Status"}
                      open={showAllAssessmentFilters}
                      translateItems={true}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                    />

                    <BasicSearchDateRange
                      label={t("year_to_be_sent_to_minister")}
                      facetName={"Assessment/YearSentToMinister"}
                      open={showAllAssessmentFilters}
                      basicSearch={basicSearch}
                      allFacets={allFacets}
                    />

                    <BasicSearchDateRange
                      label={t("date_sent_to_minister")}
                      facetName={"Assessment/DateSentToMinister"}
                      open={showAllAssessmentFilters}
                      basicSearch={basicSearch}
                      allFacets={allFacets}
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("endemic")}
                      facetName={"Cosewic/EndemicStatus"}
                      open={showAllAssessmentFilters}
                      translateItems={true}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                    />

                    <BasicSearchCheckboxListDetails
                      header={t("wsInfoChangesUnpublished")}
                      facetName={"WsInfoChangesUnpublished"}
                      open={showAllAssessmentFilters}
                      translateItems={true}
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                    />
                  </section>
                </details>
              </SectionCard>
            </div>
            <div className="col-md-9">
              <FilterKeywordSection
                defaultValue={basicSearch.state.keywordSearchText}
                onChange={(text) =>
                  basicSearch.dispatch({ type: "update_keyword_search", text })
                }
                onReset={onResetSearch}
                onSearch={onSearchClick}
              />

              <SectionCard
                header={
                  <div className="flex justify-between align-start">
                    <h2>{t("assessment_search_results")}</h2>
                    <div className="flex gap-md align-start">
                      <ExportButton
                        text={t("export")}
                        onExport={runExport}
                        loading={exportMutation.isLoading}
                      />
                    </div>
                  </div>
                }
              >
                <>
                  <FilterTags basicSearch={basicSearch} onReset={onResetTags} />

                  {/*RESULTS:*/}
                  {loading ? (
                    <LoadingIndicator centered className="mrgn-bttm-md" />
                  ) : rows && rows?.length === 0 ? (
                    <NoResults centered />
                  ) : (
                    <>
                      <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                        <div className="font-size-18">
                          {pagination.makeShowingString(
                            data ? data["@odata.count"] : 0
                          )}
                        </div>
                        <div>
                          <PageSizeSelect
                            pageSize={pagination.pageSize}
                            onChangePageSize={onChangePageSize}
                          />
                        </div>
                      </div>
                      {!loading && error ? (
                        <pre>{JSON.stringify(error)}</pre>
                      ) : null}
                      {rows == null || rows.length === 0 ? null : (
                        <ResultsTable
                          rows={rows}
                          columns={columns}
                          sortable
                          sortDescriptor={sortDescriptor}
                          onSortChange={onSortChange as any}
                        />
                      )}

                      <Pagination
                        {...pagination.paginationComponentProps}
                        onPageSelected={onPageSelected}
                        totalPages={
                          data && data["@odata.count"] && pagination.pageSize
                            ? Math.ceil(
                                data["@odata.count"] / pagination.pageSize
                              )
                            : 0
                        }
                      />
                    </>
                  )}
                </>
              </SectionCard>
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AssessmentSearchPage;
