import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import ChangeLogListItem from "components/molecules/changeLog/ChangeLogListItem";
import ListOfChangesCard from "components/molecules/changeLog/ListOfChangesCard";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { useOverviewContext } from "features/overview";
import { ChangeLog, ChangeLogDetailsDocument } from "generated/gql-types";
import useQueryParams from "hooks/util/useQueryParams";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

const Details: React.FC = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const query = useQueryParams();
  const changeLogId = query.get("changeLogId") as any;
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { data, loading, error } = useQuery(ChangeLogDetailsDocument, {
    variables: {
      changeLogId: changeLogId,
    },
  });

  const changeLog = data?.changeLog as ChangeLog;
  const detailListItems = data?.changeLog?.details;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("listing_change_details")}</h1>
          <GraphqlError errors={error} />

          {loading ? (
            <LoadingIndicator centered className="mrgn-bttm-md" />
          ) : !changeLog ? (
            <NoResults centered />
          ) : (
            <>
              <section className="Card pb-0">
                <ChangeLogListItem changeLog={changeLog} showVersion={false} />
              </section>

              {/* List of Changes Section */}
              <ListOfChangesCard
                detailListItems={detailListItems}
                sectionTitle={t("list_of_changes")}
                hideChangeType
              />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Details;
