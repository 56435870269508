import * as React from "react";
import { useTranslation } from "react-i18next";
import EditRolePopup, { EditRolePopupProps, useRolePopup } from ".";

interface EditRoleModalWithButtonProps
  extends Omit<EditRolePopupProps, "modalState"> {
  buttonLabel?: string;
  disabled?: boolean;
}

const EditRoleButtonWithModal: React.FC<EditRoleModalWithButtonProps> = ({
  buttonLabel,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useRolePopup({});
  const label = buttonLabel ?? t("edit_roles");
  const validUserId = props.userId != null && props.userId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validUserId || disabled}
        data-testid="button-edit-roles"
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {validUserId && !disabled ? (
        <EditRolePopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditRoleButtonWithModal;
