import * as React from "react";
import { useTranslation } from "react-i18next";
import EditPopulationModal, {
  EditPopulationModalProps,
  usePopulationModal,
} from "./";

interface EditPopulationModalWithButtonProps
  extends Omit<EditPopulationModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditPopulationModalWithButton: React.FC<
  EditPopulationModalWithButtonProps
> = ({ buttonLabel, ...props }) => {
  const { t } = useTranslation();
  const modalState = usePopulationModal({});

  const label = buttonLabel ?? t("edit");

  const validPopulationId =
    props.populationId != null && props.populationId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validPopulationId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validPopulationId ? (
        <EditPopulationModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditPopulationModalWithButton;
