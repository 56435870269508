import { useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import {
  ListingWs,
  ListingWsSchedule,
  RelatedSpeciesWarningDocument,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface RelatedSpeciesWarningProps {
  listingWsId: string;
}

const RelatedSpeciesWarning: React.FC<RelatedSpeciesWarningProps> = (props) => {
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery(RelatedSpeciesWarningDocument, {
    variables: {
      parentParams: {
        id: props.listingWsId,
        scheduleFilter: ListingWsSchedule.Schedule1,
        pageNumber: 1,
        pageSize: 50,
      },
      childParams: {
        id: props.listingWsId,
        pageNumber: 1,
        pageSize: 50,
      },
    },
  });

  const CommonNameAndPopulation = (listingWs: ListingWs) => {
    const commonName =
      i18n.language === "fr"
        ? listingWs.commonNameFrench?.name?.plainText
        : listingWs.commonNameEnglish?.name?.plainText;

    const population =
      i18n.language === "fr"
        ? listingWs.population?.nameFr
        : listingWs.population?.nameEn;

    return (
      <>
        {commonName ?? <MissingData />}
        {population && (
          <>
            {", "}
            {population}
          </>
        )}
      </>
    );
  };

  const parentsOnSchedule1 = data?.listingWsParents?.items;
  const children = data?.listingWsChildren?.items;

  return error ? (
    <GraphqlError errors={error} />
  ) : loading ? (
    <LoadingIndicator centered className="mrgn-bttm-md" />
  ) : parentsOnSchedule1 && parentsOnSchedule1.length > 0 ? (
    <Alert
      type={AlertTypes.WARNING}
      content={t("parents_should_remove_from_sch1_warning")}
    >
      <SectionCard
        collapsible
        open={false}
        header={<h3>{t("view_details")}</h3>}
        showLine={false}
        contentAreaProps={{ className: "font-size-16" }}
        className={"mrgn-bttm-0"}
        classNameSummary={"py-2"}
      >
        <div className="mrgn-tp-sm">{t("parent")}:</div>
        <ul>
          {parentsOnSchedule1.map((item) => {
            const listingWs = item?.listingWs as ListingWs; // Only for type conversion
            return (
              <li key={listingWs.id}>
                <Link to={ListingPathHelpers.ListingProfile(listingWs.id)}>
                  {CommonNameAndPopulation(listingWs)}
                </Link>
              </li>
            );
          })}
        </ul>
        {children && children.length > 0 && (
          <>
            <div>{t("child")}:</div>
            <ul>
              {children.map((item) => {
                const listingWs = item?.listingWs as ListingWs; // Only for type conversion
                return (
                  <li key={listingWs.id}>
                    <Link to={ListingPathHelpers.ListingProfile(listingWs.id)}>
                      {CommonNameAndPopulation(listingWs)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </SectionCard>
    </Alert>
  ) : null;
};

export default RelatedSpeciesWarning;
