import { ProvinceTerritoryInput } from "components/atoms/forms/ProvinceTerritoryInput";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { validAddress } from "util/contact/addressFormat";
import {
  formatPostalOrZipCode,
  getPostalOrZipCode,
} from "util/contact/postalOrZipCodeFormat";
import {
  BilingualAbbreviationAcronymLookupTextInput,
  BilingualTextInput,
  ContactDetailsInput,
  Maybe,
  MethodOfCommunication,
  NoteInput,
} from "../../../generated/gql-types";
import FieldValidationError from "../../atoms/forms/FieldValidationError";
import { TextInput } from "../../atoms/forms/TextInput";
import { FullHTMLEditorWithController } from "../../organisms/FullHTMLEditor";
import * as OrganizationFormMappers from "../../organisms/organization/OrganizationFormUtil";
import { OrganizationTagsDropdownWithController } from "../../organisms/organization/OrganizationTagDropdown";
import CountryDropdown from "../CountryDropdown/CountryDropdown";
import FormButtons from "../FormButtons/FormButtons";

interface OrganizationFormProps {
  defaultValues: Partial<OrganizationFields>;
  contactOrOrgInUse?: Maybe<boolean>;
  onSubmit: SubmitHandler<Partial<OrganizationFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

const OrganizationForm: React.FC<OrganizationFormProps> = (props) => {
  const { defaultValues, contactOrOrgInUse } = props;
  const form = useForm<OrganizationFields>({
    defaultValues: props.defaultValues,
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
  } = form;
  const { dirtyFields, isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();
  const countryCode = watch("contactDetails.address.countryCode");

  // Initialize MethodOfCommunication to MailingAddress on Add Organizations for the bug #75192
  if (
    defaultValues.methodOfCommunication == null &&
    getValues("methodOfCommunication") == null
  ) {
    setValue("methodOfCommunication", MethodOfCommunication.MailingAddress, {
      shouldDirty: true,
    });
  }

  const onSubmit: SubmitHandler<Partial<OrganizationFields>> = async (
    formData
  ) => {
    const cleanedValues = OrganizationFormMappers.processFormValues(
      formData,
      dirtyFields,
      defaultValues
    );

    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  const validateAtLeastOneName = () =>
    (getValues("name.english") ?? "") !== "" ||
    (getValues("name.french") ?? "") !== "";

  const postCodeObj = getPostalOrZipCode(countryCode);

  const methodOfCommunication = watch("methodOfCommunication");

  const isEmailRequired = React.useMemo(() => {
    return methodOfCommunication === MethodOfCommunication.EmailAddress;
  }, [methodOfCommunication]);

  const isPhoneRequired = React.useMemo(() => {
    return methodOfCommunication === MethodOfCommunication.PhoneNumber;
  }, [methodOfCommunication]);

  const isFaxRequired = React.useMemo(() => {
    return methodOfCommunication === MethodOfCommunication.FaxNumber;
  }, [methodOfCommunication]);

  const isMailingAddressRequired = React.useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const isProvinceOrStateRequired = React.useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const isCountryRequired = React.useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const disableProvinceStateAndPostalCode = React.useMemo(() => {
    return countryCode == null || countryCode === "NOT_INITIALIZED";
  }, [countryCode]);

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <fieldset disabled={props.isSaving}>
        {/* -------------- organization name, Level 1 -------------- */}
        <div className="form-group">
          <fieldset className="fieldset-grp">
            <legend className="required">
              <span className="field-name">
                {t("organization_name_level_1")}
              </span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>
            <div className="flex justify-between align-center border-light">
              <div className="form-group full-width">
                <label htmlFor="nameLevel1English">{t("english")}</label>
                <input
                  type="text"
                  id="nameLevel1English"
                  className="form-control full-width"
                  {...register("name.english", {
                    validate: { atLeastOneName: validateAtLeastOneName },
                  })}
                  onClick={() => {
                    clearErrors("name");
                  }}
                />
              </div>
              <div className="mrgn-lft-lg mrgn-rght-lg">
                <b className="font-size-14">{t("or")}</b>
              </div>
              <div className="form-group full-width">
                <label htmlFor="nameLevel1French">{t("french")}</label>
                <input
                  type="text"
                  id="nameLevel1French"
                  className="form-control full-width"
                  {...register("name.french", {
                    validate: { atLeastOneName: validateAtLeastOneName },
                  })}
                  onClick={() => {
                    clearErrors("name");
                  }}
                />
              </div>
            </div>
          </fieldset>
          {errors.name?.english && errors.name?.french && (
            <FieldValidationError>
              {t("at_least_one_language_is_required")}
            </FieldValidationError>
          )}
        </div>

        {/* -------------- organization name, Level 2 -------------- */}
        <div className="row">
          <TextInput
            {...register("departments.0.english")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("organization_name_level_2_english")}
            errors={errors}
          />

          <TextInput
            {...register("departments.0.french")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("organization_name_level_2_french")}
            errors={errors}
          />
        </div>

        {/* -------------- organization name, Level 3 -------------- */}
        <div className="row">
          <TextInput
            {...register("departments.1.english")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("organization_name_level_3_english")}
            errors={errors}
          />

          <TextInput
            {...register("departments.1.french")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("organization_name_level_3_french")}
            errors={errors}
          />
        </div>

        {/* -------------- organization name, Level 4 -------------- */}
        <div className="row">
          <TextInput
            {...register("departments.2.english")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("organization_name_level_4_english")}
            errors={errors}
          />

          <TextInput
            {...register("departments.2.french")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("organization_name_level_4_french")}
            errors={errors}
          />
        </div>

        {/* -------------- Method Of Communication Organization -------------- */}
        <div className="form-group">
          <fieldset className="chkbxrdio-grp">
            <legend className="required">
              <span className="field-name">{t("method_of_communication")}</span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>

            <label className="radio-inline" htmlFor="rdo_email">
              <input
                id="rdo_email"
                defaultChecked={
                  defaultValues?.methodOfCommunication ===
                  MethodOfCommunication.EmailAddress
                }
                type="radio"
                value={MethodOfCommunication.EmailAddress}
                {...register("methodOfCommunication", {
                  required: true,
                })}
                onClick={() => {
                  clearErrors("contactDetails");
                }}
              />
              &#160;{t("email")}
            </label>

            <label className="radio-inline" htmlFor="rdo_phone">
              <input
                id="rdo_phone"
                defaultChecked={
                  defaultValues?.methodOfCommunication ===
                  MethodOfCommunication.PhoneNumber
                }
                type="radio"
                value={MethodOfCommunication.PhoneNumber}
                {...register("methodOfCommunication", {
                  required: true,
                })}
                onClick={() => {
                  clearErrors("contactDetails");
                }}
              />
              &#160;{t("phone")}
            </label>

            <label className="radio-inline" htmlFor="rdo_mailing_address">
              <input
                id="rdo_mailing_address"
                defaultChecked={
                  defaultValues?.methodOfCommunication ===
                  MethodOfCommunication.MailingAddress
                }
                type="radio"
                value={MethodOfCommunication.MailingAddress}
                {...register("methodOfCommunication", {
                  required: true,
                })}
                onClick={() => {
                  clearErrors("contactDetails");
                }}
              />
              &#160;{t("mailing_address")}
            </label>

            <label className="radio-inline" htmlFor="rdo_fax">
              <input
                id="rdo_fax"
                defaultChecked={
                  defaultValues?.methodOfCommunication ===
                  MethodOfCommunication.FaxNumber
                }
                type="radio"
                value={MethodOfCommunication.FaxNumber}
                {...register("methodOfCommunication", {
                  required: true,
                })}
                onClick={() => {
                  clearErrors("contactDetails");
                }}
              />
              &#160;{t("fax")}
            </label>
          </fieldset>
          {errors.methodOfCommunication && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>

        <div className="row">
          {/* -------------- Email -------------- */}
          <TextInput
            type="email"
            {...register("contactDetails.emailAddress", {
              required: {
                value: isEmailRequired,
                message: t("field_is_required"),
              },
            })}
            divClassName="col-md-12"
            inputClassName="full-width"
            required={isEmailRequired}
            label={t("email")}
            errors={errors}
          />
        </div>

        {/* -------------- Fax -------------- */}
        <div className="row">
          <TextInput
            type="tel"
            {...register("contactDetails.phoneNumber", {
              required: {
                value: isPhoneRequired,
                message: t("field_is_required"),
              },
            })}
            divClassName="col-md-6"
            inputClassName="full-width"
            required={isPhoneRequired}
            label={t("phone")}
            placeholder={"e.g. 1-320-316-4765"}
            errors={errors}
          />

          <TextInput
            type="tel"
            {...register("contactDetails.faxNumber", {
              required: {
                value: isFaxRequired,
                message: t("field_is_required"),
              },
            })}
            divClassName="col-md-6"
            inputClassName="full-width"
            required={isFaxRequired}
            label={t("fax")}
            placeholder={"e.g. 1-320-316-4765"}
            errors={errors}
          />
        </div>

        {/* -------------- Street address -------------- */}
        <div className="row">
          <TextInput
            {...register("contactDetails.address.addressLines.0.english", {
              required: {
                value: isMailingAddressRequired,
                message: t("field_is_required"),
              },
              pattern: {
                value: validAddress,
                message: t("valid_street_address"),
              },
            })}
            divClassName="col-md-6"
            inputClassName="full-width"
            required={isMailingAddressRequired}
            label={t("address_line_1") + " (" + t("english") + ")"}
            errors={errors}
          />

          <TextInput
            {...register("contactDetails.address.addressLines.0.french", {
              required: {
                value: isMailingAddressRequired,
                message: t("field_is_required"),
              },
              pattern: {
                value: validAddress,
                message: t("valid_street_address"),
              },
            })}
            divClassName="col-md-6"
            inputClassName="full-width"
            required={isMailingAddressRequired}
            label={t("address_line_1") + " (" + t("french") + ")"}
            errors={errors}
          />
        </div>

        {/* -------------- Apartment, suite, etc -------------- */}
        <div className="row">
          <TextInput
            {...register("contactDetails.address.addressLines.1.english")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("address_line_2") + " (" + t("english") + ")"}
            errors={errors}
          />

          <TextInput
            {...register("contactDetails.address.addressLines.1.french")}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("address_line_2") + " (" + t("french") + ")"}
            errors={errors}
          />
        </div>

        <div className="row">
          {/* -------------- City -------------- */}
          <TextInput
            {...register("contactDetails.address.city", {
              required: {
                value: isMailingAddressRequired,
                message: t("field_is_required"),
              },
            })}
            divClassName="col-md-6"
            inputClassName="full-width"
            required={isMailingAddressRequired}
            label={t("city")}
            errors={errors}
          />

          {/* -------------- Country -------------- */}
          <CountryDropdown
            {...register("contactDetails.address.countryCode", {
              required: {
                value: isCountryRequired,
                message: t("field_is_required"),
              },
              onChange: (x) => {
                if (
                  x.target.value ===
                  defaultValues.contactDetails?.address?.countryCode
                ) {
                  setValue(
                    "contactDetails.address.countryCode",
                    defaultValues.contactDetails?.address?.countryCode
                  );
                  setValue(
                    "contactDetails.address.provinceOrState",
                    defaultValues?.contactDetails?.address?.provinceOrState
                  );
                  setValue(
                    "contactDetails.address.postalOrZipCode",
                    defaultValues?.contactDetails?.address?.postalOrZipCode
                  );
                } else {
                  setValue("contactDetails.address.provinceOrState", "", {
                    shouldDirty: true,
                  });
                  setValue("contactDetails.address.postalOrZipCode", "", {
                    shouldDirty: true,
                  });
                }
              },
            })}
            required={isCountryRequired}
            disabled={false}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t("country")}
            errors={errors}
          />
        </div>

        <div className="row">
          {/* ------ Province/territory, State, or Input text ------ */}
          <ProvinceTerritoryInput
            name="contactDetails.address.provinceOrState"
            control={control}
            register={register}
            countryCode={countryCode}
            isRequired={isProvinceOrStateRequired}
            disabled={disableProvinceStateAndPostalCode}
            errors={errors}
          />

          {/* -------------- PostalCode -------------- */}
          <TextInput
            {...register("contactDetails.address.postalOrZipCode", {
              required: {
                value: isMailingAddressRequired,
                message: t("field_is_required"),
              },
              pattern: {
                value: postCodeObj.pattern,
                message: t("invalid_post_or_zip_code_format"),
              },
              onChange: (e) => {
                e.target.value = formatPostalOrZipCode(
                  e.target.value,
                  countryCode
                );
              },
            })}
            divClassName="col-md-6"
            inputClassName="full-width"
            label={t(postCodeObj.label)}
            required={isMailingAddressRequired}
            disabled={disableProvinceStateAndPostalCode}
            placeholder={postCodeObj.placeHolder}
            errors={errors}
            maxLength={postCodeObj.maxLength}
          />
        </div>

        {/* -------------- Organization Tag -------------- */}
        <div className="form-group">
          <label htmlFor="OrganizationTagInput">{t("organization_tag")}</label>{" "}
          <OrganizationTagsDropdownWithController
            name="tag"
            id="OrganizationTagInput"
            control={control}
          />
          {errors?.tag && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>

        {/* -------------- Note -------------- */}
        <div className="form-group">
          <label htmlFor="OrganizationNoteInput">{t("note")}</label>
          <FullHTMLEditorWithController
            control={control}
            defaultValue={defaultValues?.note?.text?.text ?? ""}
            id="OrganizationNoteInput"
            name="note"
          />
          {errors.note && (
            <FieldValidationError>{errors.note}</FieldValidationError>
          )}
        </div>

        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.isSaving}
          onCancel={props.onClose}
          errors={errors}
        />
      </fieldset>
    </form>
  );
};

export default OrganizationForm;
export interface OrganizationFields {
  name: BilingualTextInput;
  departments: BilingualTextInput[];
  tag: BilingualAbbreviationAcronymLookupTextInput;
  contactDetails: ContactDetailsInput;
  methodOfCommunication: MethodOfCommunication;
  note: NoteInput;
}
