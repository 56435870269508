import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import {
  Address,
  ContactInfoDocument,
  OrganizationInfoDocument,
} from "generated/gql-types";

import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

export interface ContactOrganizationInfoProps {
  contactRefId?: string | null;
  organizationRefId?: string | null;
}

export const ContactOrganizationInfo: React.FC<ContactOrganizationInfoProps> = (
  props
) => {
  const { contactRefId, organizationRefId } = props;
  const { t, i18n } = useTranslation();

  const {
    loading: loadingContact,
    error: errorContact,
    data: dataContact,
  } = useQuery(ContactInfoDocument, {
    variables: {
      contactId: contactRefId ?? "",
    },
    skip: contactRefId == null,
    fetchPolicy: "network-only",
  });

  const contactFirstName = dataContact?.contact?.name?.firstName;
  const contactLastName = dataContact?.contact?.name?.lastName;
  const contactJobTitle = dataContact?.contact?.jobTitle;
  const contactContactDetails = dataContact?.contact?.contactDetails;
  const contactOrganizationDetails = dataContact?.contact?.organizationDetails;

  const {
    loading: loadingOrganization,
    error: errorOrganization,
    data: dataOrganization,
  } = useQuery(OrganizationInfoDocument, {
    variables: {
      organizationId: organizationRefId ?? "",
    },
    skip: organizationRefId == null,
    fetchPolicy: "network-only",
  });

  const orgLevel1 = dataOrganization?.organization?.name;
  const orgLevel2 = dataOrganization?.organization?.departments?.[0];
  const orgLevel3 = dataOrganization?.organization?.departments?.[1];
  const orgLevel4 = dataOrganization?.organization?.departments?.[2];
  const orgContactDetails = dataOrganization?.organization?.contactDetails;

  const buildAddress = (address: Address | null | undefined) => {
    if (address == null) return <MissingData />;

    const street = address?.addressLines?.map((x, index) => (
      <React.Fragment key={index}>
        {i18n.language === "fr" ? x?.french : x?.english}
        {(i18n.language === "fr" ? x?.french : x?.english) && <br />}
      </React.Fragment>
    ));

    const cityAndProvince = [
      address?.city,
      address?.provinceOrState && t(address?.provinceOrState),
    ]
      .filter(Boolean)
      .join(", ");

    const postalAndCountry = [address?.postalOrZipCode, address?.countryCode]
      .filter(Boolean)
      .join(", ");

    return [
      street,
      cityAndProvince,
      cityAndProvince && <br />,
      postalAndCountry,
    ];
  };

  if (loadingContact || loadingOrganization) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <GraphqlError
        title="Error loading contact information"
        errors={errorContact}
      />
      <GraphqlError
        title="Error loading organization information"
        errors={errorOrganization}
      />
      {contactRefId != null ? (
        <div className="species-field">
          <div className="row">
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("first_name")}</dt>
                <dd>{contactFirstName ?? <MissingData />}</dd>
              </div>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("last_name")}</dt>
                <dd>{contactLastName ?? <MissingData />}</dd>
              </div>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("job_title")}</dt>
                <dd>
                  {(i18n.language === "fr"
                    ? contactJobTitle?.french
                    : contactJobTitle?.english) ?? <MissingData />}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("email")}</dt>
                <dd>
                  {contactContactDetails?.emailAddress ?? <MissingData />}
                </dd>
              </div>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("phone")}</dt>
                <dd>{contactContactDetails?.phoneNumber ?? <MissingData />}</dd>
              </div>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("fax")}</dt>
                <dd>{contactContactDetails?.faxNumber ?? <MissingData />}</dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("organization")}</dt>
                <dd>
                  {(i18n.language === "fr"
                    ? contactOrganizationDetails?.name?.french
                    : contactOrganizationDetails?.name?.english) ?? (
                    <MissingData />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("address")}</dt>
                <dd>{buildAddress(contactContactDetails?.address)}</dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("contact_tags")}</dt>
                <dd>
                  {!dataContact?.contact?.tags ||
                  dataContact?.contact?.tags.length === 0 ? (
                    <MissingData />
                  ) : (
                    dataContact?.contact?.tags
                      ?.map((x) => t(x ?? ""))
                      .join(", ")
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("note")}</dt>
                <dd>
                  {htmlIsNullOrEmpty(dataContact?.contact?.note?.text?.text) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={dataContact?.contact?.note?.text?.text}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      ) : organizationRefId != null ? (
        <div className="species-field">
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("organization_name_level_number", { number: 1 })}
                </dt>
                <dd>
                  {(i18n.language === "fr"
                    ? orgLevel1?.french
                    : orgLevel1?.english) || <MissingData />}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("organization_name_level_number", { number: 2 })}
                </dt>
                <dd>
                  {(i18n.language === "fr"
                    ? orgLevel2?.french
                    : orgLevel2?.english) || <MissingData />}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("organization_name_level_number", { number: 3 })}
                </dt>
                <dd>
                  {(i18n.language === "fr"
                    ? orgLevel3?.french
                    : orgLevel3?.english) || <MissingData />}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("organization_name_level_number", { number: 4 })}
                </dt>
                <dd>
                  {(i18n.language === "fr"
                    ? orgLevel4?.french
                    : orgLevel4?.english) || <MissingData />}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("email")}</dt>
                <dd>{orgContactDetails?.emailAddress ?? <MissingData />}</dd>
              </div>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("phone")}</dt>
                <dd>{orgContactDetails?.phoneNumber ?? <MissingData />}</dd>
              </div>
              <div className="species-data col-sm-4">
                <dt className="text-muted">{t("fax")}</dt>
                <dd>{orgContactDetails?.faxNumber ?? <MissingData />}</dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("address")}</dt>
                <dd>{buildAddress(orgContactDetails?.address)}</dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("organization_tag")}</dt>
                <dd>
                  {(i18n.language === "fr"
                    ? dataOrganization?.organization?.tag?.name?.french
                    : dataOrganization?.organization?.tag?.name?.english) || (
                    <MissingData />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("note")}</dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    dataOrganization?.organization?.note?.text?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        dataOrganization?.organization?.note?.text?.text
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      ) : (
        <MissingData message={t("no_information")} />
      )}
    </>
  );
};
