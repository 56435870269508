import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import ListingProcessCreateForm, {
  ListingProcessCreateFormFields,
} from "components/organisms/listing/ListingProcessCreateForm/ListingProcessCreateForm";
import { mapFormToCreateInput } from "components/organisms/listing/ListingProcessCreateForm/mappers";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  CreateListingWsProcessDocument,
  ListingProcessCreateFormDocument,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import SectionCard from "../../../../components/atoms/SectionCard";
import LoadingIndicator from "../../../../components/atoms/LoadingIndicator";

export interface ListingProcessesCreateProps {}

const ListingProcessesCreate: React.FC<ListingProcessesCreateProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const history = useHistory();
  const { listingWsId } = params as any;
  const overviewContext = useOverviewContext();
  const alertContext = useGlobalAlertContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { loading, error, data } = useQuery(ListingProcessCreateFormDocument, {
    variables: { id: listingWsId },
    fetchPolicy: "cache-first",
  });

  const isStandaloneListing =
    !loading && !error && data?.listingWs?.cosewicWsRefId == null;

  const [createListingWsProcess, { loading: saving, error: savingError }] =
    useMutation(CreateListingWsProcessDocument, {
      refetchQueries: ["ListingWsProcessList"],
    });

  const onSubmit: SubmitHandler<
    Partial<ListingProcessCreateFormFields>
  > = async (data) => {
    const converted = mapFormToCreateInput(data);

    try {
      const res = await createListingWsProcess({
        variables: {
          listingId: listingWsId,
          input: {
            ...converted,
          },
        },
      });
      if (res.errors) throw res.errors;
      history.push({
        pathname: ListingPathHelpers.ListingProcesses(listingWsId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Ribbon />

      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("create_new_listing_process")}</h1>

          <GlobalAlert />

          {/*TODO:i18n*/}
          <GraphqlError
            title="Error saving listing process"
            errors={savingError}
          />
          {saving ? <p>Saving...</p> : null}

          {loading ? (
            <SectionCard header={<h2>{t("listing_type")}</h2>}>
              <LoadingIndicator centered />
            </SectionCard>
          ) : (
            <ListingProcessCreateForm
              onSubmit={onSubmit}
              isStandaloneListing={isStandaloneListing}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingProcessesCreate;
