import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForObjectTypeDocument,
  BilingualAbbreviationAcronymTagsLookupText,
  Maybe,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";
import i18n from "i18n";
import { bilingualLookupTextNameForLanguage } from "mappers";
import { useTranslation } from "react-i18next";

const additionalQueryVariables = {
  params: {
    searchType: "startsWith",
    isCaseSensitive: false,
    category: "bptobjecttype",
    sortBy: i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
  },
};

const ObjectTypeAutocomplete: React.FC<ObjectTypeAutocompleteProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  return (
    <QueryAutocomplete
      id={"objectTypeAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForObjectTypeDocument}
      listDisplayFormatter={(item) =>
        bilingualLookupTextNameForLanguage(i18n.language, item)
      }
      onSelectionChange={props.onSelectionChange}
      placeholder={
        props.initialValue
          ? bilingualLookupTextNameForLanguage(
              i18n.language,
              props.initialValue
            )
          : t("objectType")
      }
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(undefined)}
      additionalQueryVariables={additionalQueryVariables}
      disabled={props.disabled}
    />
  );
};

interface ObjectTypeAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (
    selectedItem?: BilingualAbbreviationAcronymTagsLookupText
  ) => void;
  label: string | ReactElement;
  disabled: boolean | undefined;
  initialValue?: Maybe<BilingualAbbreviationAcronymTagsLookupText>;
}

export default ObjectTypeAutocomplete;
