import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import Layout from "components/layouts/TwoColumn";
import BadgeBar from "components/molecules/BadgeBar";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { useOverviewContext } from "features/overview";
import { RelatedSpeciesDocument } from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import LoadingIndicator from "../../../components/atoms/LoadingIndicator";
import SafeRenderHtml from "../../../components/atoms/SafeRenderHtml";
import SectionCard from "../../../components/atoms/SectionCard";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../../features/auth/components";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";

// export interface RelatedSpeciesProps {}

const RelatedSpecies: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const { loading, error, data } = useQuery(RelatedSpeciesDocument, {
    variables: { id: cosewicWsId },
  });

  const parentCount = data?.cosewicWsRelatedSpeciesCounts?.parentCount ?? 0;
  const childCount = data?.cosewicWsRelatedSpeciesCounts?.childCount ?? 0;
  const siblingCount = data?.cosewicWsRelatedSpeciesCounts?.siblingCount ?? 0;

  // Rules to display in the History of Status Designation section are defined here:
  // https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_wiki/wikis/SAR-DMTS.wiki/157/Scrum-13-October-20-2021-History-of-Status-Designation-Related-Species-Page
  const latestPublishedAssessment = data?.assessmentLatest;

  const designationHistoryStatusEn =
    latestPublishedAssessment?.designation?.historyStatus?.english?.text;
  const designationHistoryStatusFr =
    latestPublishedAssessment?.designation?.historyStatus?.french?.text;
  const designationReasonEn =
    latestPublishedAssessment?.designation?.reason?.english?.text;
  const designationReasonFr =
    latestPublishedAssessment?.designation?.reason?.french?.text;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          {/*TODO: i18n*/}
          <h1>{t("related_species")}</h1>
          <GraphqlError errors={error} />
          <SectionCard
            header={
              <div className="flex justify-between">
                <h2>{t("relationship_types")}</h2>
                <div>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.cosewic.manageRelatedSpecies}
                  >
                    <Link
                      to={CosewicPathHelpers.Split(cosewicWsId)}
                      className="btn btn-default btn-sm mrgn-rght-md"
                      data-testid="link-split"
                    >
                      {t("split")}
                    </Link>
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.cosewic.manageRelatedSpecies}
                  >
                    <Link
                      to={CosewicPathHelpers.Merge(cosewicWsId)}
                      className="btn btn-default btn-sm mrgn-rght-md"
                      data-testid="link-merge"
                    >
                      {t("merge")}
                    </Link>
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
            showLine={loading}
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_parents")}
                    titleLink={CosewicPathHelpers.ViewParents(cosewicWsId)}
                    counter={parentCount}
                  />
                </div>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_children")}
                    titleLink={CosewicPathHelpers.ViewChildren(cosewicWsId)}
                    counter={childCount}
                  />
                </div>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_siblings")}
                    titleLink={CosewicPathHelpers.ViewSiblings(cosewicWsId)}
                    counter={siblingCount}
                  />
                </div>
              </>
            )}
          </SectionCard>

          <SectionCard header={<h2>{t("history_status_designation")}</h2>}>
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !latestPublishedAssessment ||
              latestPublishedAssessment === 0 ? (
              <NoResults centered />
            ) : (
              <div className="species-field">
                <dl>
                  <dt className="text-muted">
                    {t("designation_history_english")}
                  </dt>
                  <dd>
                    {htmlIsNullOrEmpty(designationHistoryStatusEn) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml
                        htmlString={designationHistoryStatusEn!}
                      />
                    )}
                  </dd>
                  <dt className="text-muted">
                    {t("designation_history_french")}
                  </dt>
                  <dd>
                    {htmlIsNullOrEmpty(designationHistoryStatusFr) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml
                        htmlString={designationHistoryStatusFr!}
                      />
                    )}
                  </dd>
                  <dt className="text-muted">
                    {t("designation_reason_english")}
                  </dt>
                  <dd>
                    {htmlIsNullOrEmpty(designationReasonEn) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml htmlString={designationReasonEn!} />
                    )}
                  </dd>
                  <dt className="text-muted">
                    {t("designation_reason_french")}
                  </dt>
                  <dd>
                    {htmlIsNullOrEmpty(designationReasonFr) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml htmlString={designationReasonFr!} />
                    )}
                  </dd>
                </dl>
              </div>
            )}
          </SectionCard>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default RelatedSpecies;
