import { useTranslation } from "react-i18next";
import * as React from "react";
import { AlertTypes } from "../../components/atoms/Alert";
import { Maybe } from "../../generated/gql-types";

export const useAdminAlertMsg = (localizedResourceName: string) => {
  const { t, i18n } = useTranslation();
  const [pageAlertMsg, setPageAlertMsg] = React.useState<string | null>(null);
  const [pageAlertType, setPageAlertType] = React.useState<AlertTypes | null>(
    null
  );

  const success =
    (successMsg: string) =>
    (englishName?: Maybe<string>, frenchName?: Maybe<string>) => {
      setPageAlertType(AlertTypes.SUCCESS);

      if (i18n.language === "fr") {
        setPageAlertMsg(`${frenchName ?? localizedResourceName} ${successMsg}`);
      } else {
        setPageAlertMsg(
          `${englishName ?? localizedResourceName} ${successMsg}`
        );
      }
    };

  const error = (errorMsg: string) => () => {
    setPageAlertType(AlertTypes.DANGER);
    setPageAlertMsg(errorMsg);
  };

  const onCreateSuccess = success(t("create_success"));
  const onCreateError = error(t("error"));

  const onEditSuccess = success(t("update_success"));
  const onEditError = error(t("error"));

  const onArchiveSuccess = success(t("archive_success"));
  const onArchiveError = error(t("archive_fail"));

  const onDeleteSuccess = success(t("delete_success"));
  const onDeleteError = error(t("delete_fail"));

  const onUnarchiveSuccess = success(t("unarchive_success"));
  const onUnarchiveError = error(t("unarchive_fail"));

  const onClose = () => {
    setPageAlertMsg(null);
    setPageAlertType(null);
  };

  return {
    onCreateSuccess,
    onCreateError,
    onEditSuccess,
    onEditError,
    onArchiveSuccess,
    onArchiveError,
    onUnarchiveSuccess,
    onUnarchiveError,
    close: onClose,
    onDeleteSuccess,
    onDeleteError,
    pageAlertMsg,
    pageAlertType,
    alertIsVisible: pageAlertMsg !== null,
  };
};

export default useAdminAlertMsg;
