const adminVarietyTranslation = {
  en: {
    variety: "Variety",
    list_of_varieties: "List of varieties",
    select_a_variety: "Select a variety",
    create_a_variety: "Create a variety",
    enter_variety_name: "Enter a variety name",
    edit_variety: "Edit variety",
    reactivate_a_variety: "Reactivate a variety",
    deactivate_a_variety: "Deactivate a variety",
    archive_variety_warning:
      "You will no longer be able to add this variety to a wildlife species.",
    unarchive_variety_warning:
      "You will be able to add this variety to a wildlife species.",
    no_variety_selected: "No variety was selected",
    archive_a_variety: "Archive a variety",
    unarchive_a_variety: "Unarchive a variety",
  },
  fr: {
    variety: "Variété",
    list_of_varieties: "Liste des variétés",
    select_a_variety: "Sélectionnez une variété",
    create_a_variety: "Créer une variété",
    enter_variety_name: "Entrez le nom d'une variété",
    edit_variety: "Modifier la variété",
    reactivate_a_variety: "Réactiver une variété",
    deactivate_a_variety: "Désactiver une variété",
    archive_variety_warning:
      "Vous ne pourrez plus ajouter cette variété à une espèce sauvage.",
    unarchive_variety_warning:
      "Vous pouvez associer cette variété à une espèce sauvage.",
    no_variety_selected: "Aucune variété n'a été sélectionnée",
    archive_a_variety: "Archiver une variété",
    unarchive_a_variety: "Désarchiver une variété",
  },
};

export default adminVarietyTranslation;
