import PlaceholderImage from "assets/svg/placeholder.svg";
import cc from "classcat";
import { LabelledData } from "components/molecules/LabelledData";
import { CosewicWs, CosewicWsState, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import translateCosewicWsStatus from "util/cosewic/translateCosewicWsStatus";
import MissingData from "../../atoms/MissingData";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";
import styles from "./SearchListItem.module.css";

type CosewicWsWithExtras = CosewicWs &
  Partial<{
    // NOTE: we had planned to add these properties to the cosewic search response, but it hasn't happened yet.
    // To maintain type hints for the rest of the COSEWIC properties, we've defined this CosewicWsWithExtras type for now.
    assessmentStatus: any;
    wsStage: any;
    wsState: any;
  }>;

interface SearchListItemProps {
  titleLink?: string;
  cosewicWs?: CosewicWsWithExtras;
  imgUrl?: string;
}

const SearchListItem: React.FC<SearchListItemProps> = (props) => {
  const { t, i18n } = useTranslation();

  const wsState = React.useMemo(() => {
    // Special case: cosewic state "Inactive" should have a label showing "Not Published" (ticket https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/33379/)
    if (props.cosewicWs?.wsState === CosewicWsState.Inactive)
      return t("NOT_PUBLISHED");
    return t(props.cosewicWs?.wsState);
  }, [props.cosewicWs?.wsState]);

  if (!props.cosewicWs) return <div>Invalid COSEWIC WS</div>;

  const name =
    i18n.language === "fr"
      ? props.cosewicWs.commonNameFrench?.name?.plainText
      : props.cosewicWs.commonNameEnglish?.name?.plainText;

  const population =
    i18n.language === "fr"
      ? props.cosewicWs.population?.nameFr
      : props.cosewicWs.population?.nameEn;

  const status = t(props.cosewicWs.assessmentStatus ?? "-");

  const parseSciNameHtml = (sciName?: Maybe<string>) => {
    const missing = "-";
    if (sciName == null) return missing;
    if (sciName.length === 0) return missing;
    return sciName;
  };

  const scientificNameHtml = parseSciNameHtml(
    props.cosewicWs.scientificName?.generatedScientificName?.name?.text
  );

  const cosewicId = props.cosewicWs.cosewicId ?? "-";
  const wsStage = translateCosewicWsStatus(t, props.cosewicWs?.wsStage, "-");

  return (
    <div className={cc([styles.SearchListItem, "flex width-100"])}>
      <div className="flex justify-center align-center" /* left (image) */>
        <img
          src={PlaceholderImage}
          className={cc([styles.Thumbnail, "mrgn-rght-md"])}
        />
      </div>
      <div className="flex-col justify-between width-100" /* right */>
        <div>
          {props.titleLink ? (
            <Link to={props.titleLink}>
              <h2 className="mrgn-bttm-sm">{name ?? <MissingData />}</h2>
            </Link>
          ) : (
            <h2 className="mrgn-bttm-sm">{name ?? <MissingData />}</h2>
          )}
          {population && <h4 className={styles.Population}>{population}</h4>}
        </div>
        <div className="flex justify-between">
          <LabelledData label={t("scientific_name")} className="mrgn-rght-xl">
            <SafeRenderHtml htmlString={scientificNameHtml} />
          </LabelledData>

          <div className="flex justify-between gap-xl">
            <LabelledData label={t("cosewic_ws_status")}>
              <p>{t(status ?? "-")}</p>
            </LabelledData>

            <LabelledData label={t("cosewic_id")}>
              <p>{cosewicId}</p>
            </LabelledData>

            <LabelledData label={t("ws_stage")}>
              <p>{wsStage}</p>
            </LabelledData>

            <LabelledData label={t("ws_state")}>
              <p>{wsState}</p>
            </LabelledData>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchListItem;
