import * as React from "react";
import { useTranslation } from "react-i18next";
import AddUserPopup, { AddUserPopupProps, useAddUserModal } from ".";

interface AddUserModalWithButtonProps
  extends Omit<AddUserPopupProps, "modalState"> {
  buttonLabel?: string;
  disabled?: boolean;
}

const AddUserModalWithButton: React.FC<AddUserModalWithButtonProps> = ({
  buttonLabel,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useAddUserModal({});
  const label = buttonLabel ?? t("add_new_user");
  return (
    <>
      <button
        type="button"
        className="btn btn-link mrgn-0 py-0 px-2 card-header-button-link"
        onClick={modalState.open}
        disabled={disabled}
        data-testid="button-add-new-user"
        title={label}
      >
        <i className="fas fa-user-plus mrgn-rght-sm"></i>
        {label}
      </button>
      {!disabled ? <AddUserPopup modalState={modalState} {...props} /> : null}
    </>
  );
};

export default AddUserModalWithButton;
