import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForSubspeciesDocument,
  IdNameType,
  Subspecies,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";

const additionalQueryVariables = {
  params: { searchType: "startsWith", isCaseSensitive: false },
};

const SubspeciesAutocomplete: React.FC<SubspeciesAutocompleteProps> = (
  props
) => {
  return (
    <QueryAutocomplete
      id={"subspeciesAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForSubspeciesDocument}
      listDisplayFormatter={(item) => item.name}
      onSelectionChange={props.onSelectionChange}
      placeholder={props.initialSubspecies?.name ?? ""}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

interface SubspeciesAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newSubspecies?: null | Subspecies | IdNameType) => void;
  initialSubspecies?: Subspecies | IdNameType;
  label: string | ReactElement;
}

export default SubspeciesAutocomplete;
