import dayjs from "dayjs";
import "dayjs/locale/fr-ca";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18n from "i18n";

export enum DisplayFormat {
  DATE = "LL",
  DATE_AND_TIME = "LLL",
  YEAR = "YYYY",
  YEAR_MONTH_DAY = "YYYY-MM-DD",
}

// Determine if running environment is pipeline.
const isRunEnvPipeline = process.env.CI != null;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
const localTimezone = isRunEnvPipeline ? "America/Toronto" : dayjs.tz.guess();

// Determine whether a given string is a DateTime type string or a timestamp
// e.g. isDateTimeString("2013-11-01T00:00:00Z") => true
// e.g. isDateTimeString("1087933780") => false
const REGEX_IS_DATETIME = /-/g;
const isDateTimeString = (str: string) => str.match(REGEX_IS_DATETIME) !== null;

// Return the formatted date according to the timestamp string and displayFormat passed in.
// Input can be dataTime or timestamp string in the following format:
//   "2021-07-30"
//   "2019-01-25T00:00:00Z"
//   "1627481819721"
//   "1087933787"
// Input displayFormat has the following options:
//   - Date only
//   - Date and Time
// Note: Local Time Zone will only be applied to the timestamp input only.
export const formatTimestamp = (
  timestamp?: string | null, // input dateTime or timestamp string
  displayFormat: DisplayFormat = DisplayFormat.DATE
) => {
  try {
    if (!timestamp) return null;
    if (isDateTimeString(timestamp)) {
      let dateTimeString = timestamp;

      if (!dayjs(dateTimeString).isValid)
        throw new Error("date time string is not a valid date");
      return dayjs
        .utc(dateTimeString)
        .locale(i18n.language + "-ca")
        .format(displayFormat);
    } else {
      if (timestamp.length === 9 || timestamp.length === 10) timestamp += "000";
      let timestampNumber = Number(timestamp);

      if (isNaN(timestampNumber))
        throw new Error("timestamp is not a valid date number");
      if (!dayjs(timestampNumber).isValid)
        throw new Error("timestamp is not a valid date");
      return dayjs
        .utc(timestampNumber)
        .tz(localTimezone)
        .locale(i18n.language + "-ca")
        .format(displayFormat);
    }
  } catch (err) {
    console.error(err);
    return "Invalid Date";
  }
};

// For French, if it is the first day of the month, write "1er" instead of "1".
// https://www.btb.termiumplus.gc.ca/tpv2guides/guides/clefsfp/index-eng.html?lang=eng&lettr=indx_catlog_d&page=9lcOojjUrxt8.html#zz9lcOojjUrxt8
export const AddErForFrenchDate = (date?: string | null) => {
  if (i18n.language === "fr" && date && date.slice(0, 2) === "1 ") {
    return [date.slice(0, 1), "er", date.slice(1)].join("");
  } else {
    return date;
  }
};
