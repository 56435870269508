import {ApolloQueryResult, useApolloClient} from "@apollo/client";
import * as React from "react";
import pDebounce from "p-debounce";
import {formatPageNumber} from "../../../util/formatPageNumber";
import {SEARCH_DEBOUNCE_TIME_MS} from "../../../config/constants";
import {useAsyncList} from "@react-stately/data";
import {useEffect} from "react";
import {TypeOfQueryData} from "./QueryAutocomplete";

export function useQueryAutocompleteInstance<TQuery extends TypeOfQueryData<any>>(queryDocument: any, additionalQueryVariables?: any) {
  const client = useApolloClient();

  const debouncedSearch = React.useMemo(() => {
    return pDebounce((pageNumber: number, filterText: string) => {
      // Merge together the dropdown list's pagination variables and any extra variables provided by the caller via props.
      // Allows the page size to be overridden, or extra params (e.g. extra filters) to be passed through.
      const combinedParams = Object.assign(
        additionalQueryVariables?.params ?? {},
        {
          pageNumber: formatPageNumber(pageNumber),
          search: filterText.length > 0 ? filterText : "", // NOTE: filterText cannot be null, or our services will return no items.
          pageSize: additionalQueryVariables?.params?.pageSize ?? 50,
        }
      );

      const variables = Object.assign(additionalQueryVariables ?? {}, {
        params: combinedParams,
      });

      return client.query({
        query: queryDocument,
        variables,
      }) as Promise<ApolloQueryResult<TypeOfQueryData<TQuery>>>; // TODO: passing TQuery here isnt correct, the type arg wants the ITEM type not the query type
    }, SEARCH_DEBOUNCE_TIME_MS);
  }, [additionalQueryVariables]);

  // TODO: any here - derive from document type?
  const list = useAsyncList<any>({
    async load({ cursor, filterText }: any) {
      // check for -1 to halt fetching the next page
      if (cursor === "-1") return { items: [], cursor: "-1" };
      const results = await debouncedSearch(cursor, filterText);
      const items = results?.data?.autocompleteData?.autocompleteItems ?? [];

      if (results?.data?.autocompleteData?.pagination?.hasNextPage === false) {
        return {
          items, // return the final set of items
          cursor: "-1", // Use -1 to halt fetching
        };
      }

      const newPageNumber =
        formatPageNumber(
          results?.data?.autocompleteData?.pagination?.currentPage
        ) + 1;

      return {
        items,
        cursor: newPageNumber.toString(),
      };
    },
  });

  useEffect(() => {
    list.reload();
  }, [additionalQueryVariables]);

  const forceClear = () => {
    list.setFilterText("");
    list.setSelectedKeys(new Set());
  }

  return {
    queryDocument,
    list,
    forceClear
  }
}
