import * as React from "react";
import { useTranslation } from "react-i18next";
import CreatePopulationModal, {
  CreatePopulationModalProps,
  usePopulationModal,
} from "./";

interface CreatePopulationModalWithButtonProps
  extends Omit<CreatePopulationModalProps, "modalState"> {
  buttonLabel?: string;
}

const CreatePopulationModalWithButton: React.FC<
  CreatePopulationModalWithButtonProps
> = (props) => {
  const { t } = useTranslation();
  const modalState = usePopulationModal({});

  const label = props.buttonLabel ?? t("add");

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        data-testid="button-add"
      >
        {label}
      </button>
      <CreatePopulationModal modalState={modalState} {...props} />
    </>
  );
};

export default CreatePopulationModalWithButton;
