import SectionCard from "components/atoms/SectionCard";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import BilingualToggleContainer from "components/molecules/BilingualToggleContainer";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import AddRemoveContactButtonAndModal from "components/organisms/contacts/AddContactButtonAndModal/AddRemoveContactButtonAndModal";
import { MAX_LENGTH_OF_500 } from "config/constants";
import * as React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "util/isNullOrEmpty";
import { htmlToPlainText } from "util/richtext";
import { AddDocumentsMultiSelectListWithController } from "../AddDocumentsMultiSelectList/AddDocumentsMultiSelectList";
import { RegistryPageFormFields } from "./RegistryPageForm";

export interface RegistryPageInformationCardProps {
  initialValues: Partial<RegistryPageFormFields>;
}

export const RegistryPageInformationCard: React.FC<
  RegistryPageInformationCardProps
> = (props) => {
  const { initialValues } = props;
  const { t, i18n } = useTranslation();

  const form = useFormContext<RegistryPageFormFields>();
  const { control, register, formState, getValues, trigger } = form;
  const { errors, isDirty } = formState;
  const relatedResourcesFieldArray = useFieldArray({
    control,
    name: "relatedResources",
  });

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_500;

  const onRemoveContactOrOrg = async () => {
    form.setValue("contact", null, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const validateEnglishResourceLink = (index: number) =>
    (!isNullOrEmpty(getValues("relatedResources")?.[index]?.enReference?.uri) &&
      !isNullOrEmpty(
        getValues("relatedResources")?.[index]?.enReference?.name?.text
      )) ||
    isNullOrEmpty(getValues("relatedResources")?.[index]?.enReference?.uri);

  const validateFrenchResourceLink = (index: number) =>
    (!isNullOrEmpty(getValues("relatedResources")?.[index]?.frReference?.uri) &&
      !isNullOrEmpty(
        getValues("relatedResources")?.[index]?.frReference?.name?.text
      )) ||
    isNullOrEmpty(getValues("relatedResources")?.[index]?.frReference?.uri);

  return (
    <SectionCard header={<h2>{t("registry_page_information")}</h2>}>
      {/* -------------- Page title (English) -------------- */}
      <div className="form-group">
        <label htmlFor="pageTitleEnglishRichText" className="required">
          {t("page_title")} ({t("english")})
          <strong className="required"> ({t("required")})</strong>
        </label>
        <HTMLItalicsInputWithController
          control={control}
          rules={{
            required: true,
            validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
            onChange: () => trigger("title"),
          }}
          name="title.english.text"
          id="pageTitleEnglishRichText"
          defaultValue={initialValues.title?.english?.text ?? ""}
          placeholder={t("enter_some_text")}
        />

        {errors.title?.english &&
          (errors.title?.english as any)?.text?.type === "required" && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

        {errors.title?.english &&
          (errors.title?.english as any)?.text?.type === "maxLengthLimit" && (
            <FieldValidationError>
              {t("reach_the_max_length", {
                count: MAX_LENGTH_OF_500,
              })}
            </FieldValidationError>
          )}
      </div>
      {/* -------------- Page title (French) -------------- */}
      <div className="form-group">
        <label htmlFor="pageTitleFrenchRichText" className="required">
          {t("page_title")} ({t("french")})
          <strong className="required"> ({t("required")})</strong>
        </label>
        <HTMLItalicsInputWithController
          control={control}
          rules={{
            required: true,
            validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
            onChange: () => trigger("title"),
          }}
          name="title.french.text"
          id="pageTitleFrenchRichText"
          defaultValue={initialValues.title?.french?.text ?? ""}
          placeholder={t("enter_some_text")}
        />

        {errors.title?.french &&
          (errors.title?.french as any)?.text?.type === "required" && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

        {errors.title?.french &&
          (errors.title?.french as any)?.text?.type === "maxLengthLimit" && (
            <FieldValidationError>
              {t("reach_the_max_length", {
                count: MAX_LENGTH_OF_500,
              })}
            </FieldValidationError>
          )}
      </div>
      {/* -------------- Description -------------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="description-rte"
        showBoth={
          !isDirty ||
          errors.description?.english != null ||
          errors.description?.french != null
        }
        english={
          <div className="form-group">
            <label htmlFor="DescriptionEnglishRichText" className="required">
              {t("description")} ({t("english")})
              <strong className="required"> ({t("required")})</strong>
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{ required: true }}
              defaultValue={initialValues.description?.english?.text ?? ""}
              id="DescriptionEnglishRichText"
              name="description.english.text"
            />
            {errors.description?.english && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="DescriptionFrenchRichText" className="required">
              {t("description")} ({t("french")})
              <strong className="required"> ({t("required")})</strong>
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{ required: true }}
              defaultValue={initialValues.description?.french?.text ?? ""}
              id="DescriptionFrenchRichText"
              name="description.french.text"
            />
            {errors.description?.french && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        }
      />
      {/* -------------- Add documents -------------- */}
      <div className="form-group">
        <AddDocumentsMultiSelectListWithController
          control={control}
          defaultValue={initialValues.documentRefs}
          name="documentRefs"
        />
      </div>

      {/* -------------- Contact -------------- */}
      <div className="form-group">
        <label htmlFor="ContactInput" className="required">
          {t("contact")}
          <strong className="required"> ({t("required")})</strong>
        </label>
        <Controller
          name="contact"
          control={control}
          rules={{ validate: (value) => value != null }}
          render={({ field: { value, onChange } }) => {
            return (
              <AddRemoveContactButtonAndModal
                id={"ContactInput"}
                contact={value}
                onSave={(newValue, contactType) => {
                  if (contactType === "contact") {
                    onChange({
                      contactType,
                      contactRef: newValue,
                      organizationRef: null,
                    });
                  } else if (contactType === "organization") {
                    onChange({
                      contactType,
                      contactRef: null,
                      organizationRef: newValue,
                    });
                  }
                  form.trigger("contact");
                }}
                onRemove={onRemoveContactOrOrg}
              />
            );
          }}
        />
        {errors.contact != null && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Related resources -------------- */}
      {/* 
        Update max length of link URL to 512
        https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/65436
      */}

      <h2>{t("related_resources")}</h2>
      {relatedResourcesFieldArray.fields.map((field, index) => {
        return (
          <div key={field.id} className="well-normal">
            <div className="flex justify-between align-top">
              <div className="full-width">
                {/* ------ Resource link (English) ------ */}
                <div className="form-group">
                  <label htmlFor={`resource-link-en-${index}`}>
                    {t("resource_link")} ({t("english")})
                  </label>
                  <input
                    id={`resource-link-en-${index}`}
                    className="form-control full-width"
                    maxLength={512}
                    {...register(
                      `relatedResources.${index}.enReference.uri` as const
                    )}
                    required={
                      !isNullOrEmpty(
                        getValues("relatedResources")?.[index]?.enReference
                          ?.name?.text
                      )
                    }
                  />
                </div>
                {/* ------ Resource link (French) ------ */}
                <div className="form-group">
                  <label htmlFor={`resource-link-fr-${index}`}>
                    {t("resource_link")} ({t("french")})
                  </label>
                  <input
                    id={`resource-link-fr-${index}`}
                    className="form-control full-width"
                    maxLength={512}
                    {...register(
                      `relatedResources.${index}.frReference.uri` as const
                    )}
                    required={
                      !isNullOrEmpty(
                        getValues("relatedResources")?.[index]?.frReference
                          ?.name?.text
                      )
                    }
                  />
                </div>
                {/* ------ Resource title (English) ------ */}
                <div className="form-group">
                  <label htmlFor="resourceTitleEnglishRichText">
                    {t("resource_title")} ({t("english")})
                  </label>
                  <HTMLItalicsInputWithController
                    control={control}
                    rules={{
                      validate: {
                        maxLengthLimit: validateHtmlTextMaxLengthLimit,
                        validateLink: () => validateEnglishResourceLink(index),
                      },
                    }}
                    name={
                      `relatedResources.${index}.enReference.name.text` as const
                    }
                    id="resourceTitleEnglishRichText"
                    defaultValue={
                      initialValues.relatedResources?.[index]?.enReference?.name
                        ?.text ?? ""
                    }
                    placeholder={t("enter_some_text")}
                  />
                  {(errors?.relatedResources?.[index]?.enReference as any)
                    ?.name &&
                    (
                      (errors?.relatedResources?.[index]?.enReference as any)
                        ?.name as any
                    )?.text?.type === "maxLengthLimit" && (
                      <FieldValidationError>
                        {t("reach_the_max_length", {
                          count: MAX_LENGTH_OF_500,
                        })}
                      </FieldValidationError>
                    )}
                  {errors?.relatedResources?.[index]?.enReference && (
                    <FieldValidationError>
                      {t("field_is_required")}
                    </FieldValidationError>
                  )}
                </div>
                {/* ------ Resource title (French) ------ */}
                <div className="form-group">
                  <label htmlFor="resourceTitleFrenchRichText">
                    {t("resource_title")} ({t("french")})
                  </label>
                  <HTMLItalicsInputWithController
                    control={control}
                    rules={{
                      validate: {
                        maxLengthLimit: validateHtmlTextMaxLengthLimit,
                        validateLink: () => validateFrenchResourceLink(index),
                      },
                    }}
                    name={
                      `relatedResources.${index}.frReference.name.text` as const
                    }
                    id="resourceTitleFrenchRichText"
                    defaultValue={
                      initialValues.relatedResources?.[index]?.frReference?.name
                        ?.text ?? ""
                    }
                    placeholder={t("enter_some_text")}
                  />
                  {errors?.relatedResources?.[index]?.frReference && (
                    <FieldValidationError>
                      {t("field_is_required")}
                    </FieldValidationError>
                  )}
                  {(errors?.relatedResources?.[index]?.frReference as any)
                    ?.name &&
                    (
                      (errors?.relatedResources?.[index]?.frReference as any)
                        ?.name as any
                    )?.text?.type === "maxLengthLimit" && (
                      <FieldValidationError>
                        {t("reach_the_max_length", {
                          count: MAX_LENGTH_OF_500,
                        })}
                      </FieldValidationError>
                    )}
                </div>
              </div>
              {/* ------ Remove button ------ */}
              <div>
                {relatedResourcesFieldArray.fields &&
                  relatedResourcesFieldArray.fields.length > 1 && (
                    <button
                      type="button"
                      title={t("remove")}
                      className="btn btn-link btn-xs hover-grey"
                      data-testid={`button-delete-${index}`}
                      onClick={() => relatedResourcesFieldArray.remove(index)}
                    >
                      <i className="fas fa-times font-size-14 color-danger"></i>
                    </button>
                  )}
              </div>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        className="btn btn-default btn-sm"
        onClick={() => {
          relatedResourcesFieldArray.append({
            enReference: { name: { text: undefined }, uri: undefined },
            frReference: { name: { text: undefined }, uri: undefined },
          });
        }}
        data-testid="button-add-permit-issuer"
      >
        {/* <span className="far fa-plus-square mrgn-rght-sm" /> */}
        {t("new_item")}
      </button>
    </SectionCard>
  );
};
