import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import RegistryPageShowsUnpublishedChangesWarning from "components/molecules/documents/RegistryPageHasUnpublishedChangesWarning";
import PublishUnPublishButtonAndModal from "components/organisms/PublishUnPublishButtonAndModal/PublishUnPublishButtonAndModal";
import DeleteButtonAndModal from "components/organisms/documents/DeleteButtonAndModal/DeleteButtonAndModal";
import RegistryInfoCard from "components/organisms/registry/RegistryInfoCard/RegistryInfoCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  DeleteRegistryPageDocument,
  DocumentStage,
  RegistryPageDocument,
  RegistryPagePendingUpdatesDocument,
  RegistryPageState,
  UpdateRegistryPageStateDocument,
} from "generated/gql-types";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { delayRefetchQuery } from "util/delayRefetchQuery";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

export interface RegistryProfilePageProps {}

export const RegistryProfilePage: React.FC<RegistryProfilePageProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();
  const { params } = useRouteMatch();
  const { registryPageId } = params as any;

  const { loading, error, data } = useQuery(RegistryPageDocument, {
    errorPolicy: "all",
    variables: {
      registryPageId: registryPageId,
    },
  });

  const {
    data: unpublishedChangesData,
    loading: unpublishedChangesLoading,
    error: unpublishedChangesError,
  } = useQuery(RegistryPagePendingUpdatesDocument, {
    variables: { registryPageId: registryPageId },
  });

  const [updateRegistryPageState, updateRegistryPageStateMutationStatus] =
    useMutation(UpdateRegistryPageStateDocument, {
      onQueryUpdated: delayRefetchQuery,
      awaitRefetchQueries: true,
      refetchQueries: ["RegistryPage", "RegistryPagePendingUpdates"],
    });

  const [deleteRegistryPage, deleteRegistryPageMutationStatus] = useMutation(
    DeleteRegistryPageDocument
  );

  const title =
    i18n.language === "fr"
      ? data?.registryPage?.title?.french?.text
      : data?.registryPage?.title?.english?.text;

  const onPublish = async () => {
    try {
      const res = await updateRegistryPageState({
        variables: {
          updateRegistryPageStateId: registryPageId,
          state: RegistryPageState.Published,
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("registry_page_successfully_published"),
        message: "",
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.RegistryProfile(
          registryPageId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onUnPublish = async () => {
    try {
      const res = await updateRegistryPageState({
        variables: {
          updateRegistryPageStateId: registryPageId,
          state: RegistryPageState.NotPublished,
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("registry_page_successfully_unpublished"),
        message: t("this_page_is_not_visible_on_the_registry"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.RegistryProfile(
          registryPageId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async () => {
    try {
      const res = await deleteRegistryPage({
        variables: {
          deleteRegistryPageId: registryPageId,
        },
      });
      if (res.errors) throw res.errors;

      history.push({
        pathname: DocumentsPathHelpers.DocumentsSearch,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const canPublish = React.useMemo(() => {
    if (loading || error) return false;

    if (data?.registryPage?.state === RegistryPageState.NotPublished)
      return true;
    return false;
  }, [data?.registryPage?.state, error, loading]);

  const canUnPublish = React.useMemo(() => {
    if (loading) return false;
    if (data?.registryPage?.state === RegistryPageState.Published) {
      return true;
    }
    return false;
  }, [data?.registryPage?.state, loading]);

  const canDelete = React.useMemo(() => {
    if (loading) return false;
    if (data?.registryPage?.state === RegistryPageState.NotPublished) {
      return true;
    }
    return false;
  }, [data?.registryPage?.state, loading]);

  const canPublishChanges = React.useMemo(() => {
    if (loading || unpublishedChangesLoading) return false;
    if (
      data?.registryPage?.state === RegistryPageState.Published &&
      unpublishedChangesData?.registryPagePendingUpdates
        ?.documentPendingUpdates != null &&
      unpublishedChangesData?.registryPagePendingUpdates?.documentPendingUpdates
        ?.length > 0
    ) {
      return true;
    }
    return false;
  }, [
    data?.registryPage?.state,
    loading,
    unpublishedChangesData?.registryPagePendingUpdates?.documentPendingUpdates,
    unpublishedChangesLoading,
  ]);

  const showPublishBtn = React.useMemo(() => {
    if (loading) return false;

    const anyArchivedDocs = data?.registryPage?.documentRefs?.some(
      (document, index) => {
        return document?.stage === DocumentStage.Inactive;
      }
    );

    if (anyArchivedDocs) return false;

    if (data?.registryPage?.state === RegistryPageState.NotPublished) {
      return true;
    }
    return false;
  }, [data?.registryPage?.state, loading]);

  const showUnPublishBtn = React.useMemo(() => {
    if (loading) return false;
    if (data?.registryPage?.state === RegistryPageState.Published) {
      return true;
    }
    return false;
  }, [data?.registryPage?.state, loading]);

  const showDeleteBtn = React.useMemo(() => {
    if (loading) return false;
    if (data?.registryPage?.state === RegistryPageState.NotPublished) {
      return true;
    }
    return false;
  }, [data?.registryPage?.state, loading]);

  const showPublishChangesBtn = React.useMemo(() => {
    if (
      loading ||
      unpublishedChangesLoading ||
      updateRegistryPageStateMutationStatus.loading
    )
      return false;
    if (
      data?.registryPage?.state === RegistryPageState.Published &&
      unpublishedChangesData?.registryPagePendingUpdates
        ?.documentPendingUpdates != null &&
      unpublishedChangesData?.registryPagePendingUpdates?.documentPendingUpdates
        ?.length > 0
    ) {
      return true;
    }
    return false;
  }, [
    data?.registryPage?.state,
    loading,
    unpublishedChangesData?.registryPagePendingUpdates?.documentPendingUpdates,
    unpublishedChangesLoading,
    updateRegistryPageStateMutationStatus.loading,
  ]);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <GlobalAlert />
          {!(
            loading ||
            updateRegistryPageStateMutationStatus.loading ||
            unpublishedChangesLoading
          ) && (
            <RegistryPageShowsUnpublishedChangesWarning
              pendingUpdates={unpublishedChangesData}
              isPublished={
                data?.registryPage?.state === RegistryPageState.Published
              }
            />
          )}
          <h1>
            {!loading && (
              <SafeRenderHtml htmlString={htmlRemoveOuterPTag(title)} />
            )}
          </h1>
          <GraphqlError
            title="Error loading unpublished Changes"
            errors={unpublishedChangesError}
          />
          <GraphqlError title="Error loading Registry pages" errors={error} />
          <GraphqlError
            title="Error updating stage of Registry"
            errors={updateRegistryPageStateMutationStatus.error}
          />

          <GraphqlError
            title="Error deleting the registry page"
            errors={deleteRegistryPageMutationStatus.error}
          />

          {loading ||
          updateRegistryPageStateMutationStatus.loading ||
          unpublishedChangesLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  <div className="">
                    {t("state")}{" "}
                    <div className="label label-info">
                      {data?.registryPage?.state ? (
                        t(data?.registryPage?.state)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {showPublishBtn && (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.registry.publish}
                    >
                      <PublishUnPublishButtonAndModal
                        canAction={canPublish}
                        onAction={onPublish}
                        warningTitle={t(
                          "publish_registry_page_modal_warning_title"
                        )}
                        warningMessage={t(
                          "publish_registry_page_modal_warning_message"
                        )}
                        buttonText={t("publish")}
                        modalTitle={t("publish")}
                      />
                    </RenderWhenAuthorized>
                  )}

                  {showPublishChangesBtn && (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.registry.publish}
                    >
                      <PublishUnPublishButtonAndModal
                        canAction={canPublishChanges}
                        onAction={onPublish}
                        warningTitle={t(
                          "publish_registry_page_modal_warning_title"
                        )}
                        warningMessage={t(
                          "publish_registry_page_modal_warning_message"
                        )}
                        buttonText={t("publish_changes")}
                        modalTitle={t("publish_changes")}
                      />
                    </RenderWhenAuthorized>
                  )}

                  {showUnPublishBtn && (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.registry.unPublish}
                    >
                      <PublishUnPublishButtonAndModal
                        canAction={canUnPublish}
                        onAction={onUnPublish}
                        warningTitle={t(
                          "unpublish_registry_page_modal_warning_title"
                        )}
                        warningMessage={""}
                        buttonText={t("unpublish")}
                        modalTitle={t("unpublish")}
                      />
                    </RenderWhenAuthorized>
                  )}
                  {showDeleteBtn && (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.registry.delete}
                    >
                      <DeleteButtonAndModal
                        canAction={canDelete}
                        onAction={onDelete}
                        warningTitle={t(
                          "delete_registry_page_modal_warning_title"
                        )}
                        warningMessage={t(
                          "delete_registry_page_modal_warning_message"
                        )}
                        buttonText={t("delete")}
                        modalTitle={t("delete")}
                      />
                    </RenderWhenAuthorized>
                  )}
                </div>
              </div>
              {<RegistryInfoCard registryData={data?.registryPage} />}
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default RegistryProfilePage;
