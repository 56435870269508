import { useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Alert, { AlertTypes } from "../../../atoms/Alert";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { UpdateAuthorizationUserDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ProgramForm, {
  ProgramFields,
} from "components/molecules/admin/ProgramForm";

export interface EditProgramPopupProps {
  modalState: OverlayTriggerState;
  userId: string; // SarDmts UserId
  userEmail: string;
  defaultValues: ProgramFields;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
}

const EditProgramPopup: React.FC<EditProgramPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, userId, defaultValues, userEmail } = props;

  const [editProgram, { loading, error, reset }] = useMutation(
    UpdateAuthorizationUserDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
    }
  );

  const onSubmit: SubmitHandler<Partial<ProgramFields>> = async (formData) => {
    try {
      const res = await editProgram({
        variables: {
          userId: userId,
          input: {
            program: formData.program,
          },
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error editing program");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_program")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        className="modal-dropdown-visible"
      >
        <GraphqlError title={t("update_fail")} errors={error} />
        <Alert
          type={AlertTypes.WARNING}
          title={t("edit_program_rest_roles")}
          content={t("edit_program_modal_description")}
        />
        {loading && <LoadingIndicator centered />}
        <dl>
          <dt>{t("user")}</dt>
          <dd className="well well-sm bg-lightgrey">{userEmail}</dd>
        </dl>
        <ProgramForm
          initialValues={defaultValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditProgramPopup;

export const useProgramPopup = useOverlayTriggerState;
 