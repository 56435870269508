import SectionCard from "components/atoms/SectionCard";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export interface SelectProgramCardProps {
  selected: "cosewic" | "listing";
}

export const SelectProgramCard: React.FC<SelectProgramCardProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <SectionCard
      header={<h2 className="mrgn-tp-0">{t("select_a_program")}</h2>}
      className="pb-2"
      showLine={false}
    >
      <div className="separator-line-top pt-0"></div>
      <div className="radio">
        <label htmlFor="changelogsearch-cosewic">
          <input
            type="radio"
            name="changelogsearch-cosewic"
            id="changelogsearch-cosewic"
            data-testid="changelogsearch-cosewic"
            defaultChecked={props.selected === "cosewic"}
            onClick={(e) => {
              e.preventDefault();
              history.push({ pathname: "/search/changelog/cosewic" });
            }}
          />
          <span className="mrgn-lft-sm">{t("cosewic")}</span>
        </label>
      </div>

      <div className="radio">
        <label htmlFor="changelogsearch-listing">
          <input
            type="radio"
            name="changelogsearch-listing"
            id="changelogsearch-listing"
            data-testid="changelogsearch-listing"
            defaultChecked={props.selected === "listing"}
            onClick={(e) => {
              e.preventDefault();
              history.push({ pathname: "/search/changelog/listing" });
            }}
          />
          <span className="mrgn-lft-sm">{t("listing")}</span>
        </label>
      </div>
    </SectionCard>
  );
};

export default SelectProgramCard;
