import { useOverlayTriggerState } from "@react-stately/overlays";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import ModalDialog from "components/molecules/Modal";
import { RichTextInput } from "generated/gql-types";
import * as React from "react";
import { OverlayContainer } from "react-aria";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useCheckbox from "../../../hooks/util/useCheckbox";
import Alert, { AlertTypes } from "../../atoms/Alert";
import { FullHTMLEditorWithController } from "../FullHTMLEditor";

export const MakePhotoPublishButtonAndModal: React.FC<{
  defaultValues?: PhotoPublishFormFields;
  onPublishSubmit: SubmitHandler<PhotoPublishFormFields>;
}> = (props) => {
  const { t } = useTranslation();
  const checkboxState = useCheckbox(false);
  const popupState = usePhotoPublishPopup({});
  const form = useForm<PhotoPublishFormFields>({
    defaultValues: props.defaultValues,
  });
  const { control, handleSubmit, formState } = form;
  const { errors, isSubmitting } = formState;

  const onClose = () => {
    checkboxState.reset();
    popupState.close();
  };

  const onSubmit: SubmitHandler<PhotoPublishFormFields> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onPublishSubmit(formData);
        checkboxState.reset();
        popupState.close();
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <button
        className="btn btn-primary btn-sm mrgn-rght-sm"
        onClick={popupState.open}
        data-testid="button-photo-publish"
      >
        {t("publish_to_registry")}
      </button>
      {popupState.isOpen && (
        <OverlayContainer>
          <ModalDialog
            title={t("publish_to_registry")}
            className={"modal-width-70"}
            isOpen={false}
            onClose={onClose}
            showModalFooter={false}
          >
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <div className="form-group">
                <label>{t("photo_usage_history")}</label>
                <FullHTMLEditorWithController
                  control={control}
                  name="usageHistory.text"
                  defaultValue={props?.defaultValues?.usageHistory?.text ?? ""}
                  id="usageHistoryInput"
                  maxLength={500}
                />
              </div>
              <Alert
                type={AlertTypes.WARNING}
                title={t("publish_photo_modal_warning_title")}
                content={t("publish_photo_modal_warning_message")}
              />
              <div className="checkbox mrgn-bttm-md">
                <label>
                  <input
                    type="checkbox"
                    checked={checkboxState.checked}
                    onChange={checkboxState.toggle}
                    data-testid="modal-checkbox-confirm-photo-publish"
                  />
                  &nbsp;&nbsp;
                  {t("i_confirm")}
                </label>
              </div>
              <hr className="mrgn-tp-sm mrgn-bttm-md" />
              <FormButtons
                isDirty={checkboxState.checked}
                isSubmitting={isSubmitting}
                onCancel={onClose}
                errors={errors}
              />
            </form>
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
};

export default MakePhotoPublishButtonAndModal;
export const usePhotoPublishPopup = useOverlayTriggerState;

export interface PhotoPublishFormFields {
  usageHistory?: RichTextInput;
}
