import cc from "classcat";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { PermitPathHelpers } from "./PermitRouter";

const Index: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout.Root>
      <Layout.HomeContent>
        <div className={cc(["provisional bg-cover", styles.hero_bg_img])}>
          <div className={cc(["container", styles.hero_container])}>
            <div className="well header-rwd brdr-0 brdr-rds-0 text-white bg-gctheme opct-80">
              <h1
                property="name"
                id="wb-cont"
                className="h2 mrgn-tp-lg mrgn-bttm-lg"
              >
                {t("permit_home")}
              </h1>
            </div>
          </div>
        </div>
        <div className="home experimental">
          <div className="gc-srvinfo container mrgn-tp-lg">
            <div className="wb-eqht row">
              <div className="col-md-6">
                <SectionCard
                  className="hght-inhrt"
                  header={<h2>{t("browse_permit_descriptions")}</h2>}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      history.push({
                        pathname: PermitPathHelpers.PermitBrowse,
                      });
                    }}
                  >
                    {t("browse_all_permit_descriptions")}
                  </button>
                </SectionCard>
              </div>
              <div>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.permit.create}
                >
                  <div className="col-md-6">
                    <SectionCard
                      className="hght-inhrt"
                      header={<h2>{t("new_permit_description")}</h2>}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          history.push({
                            pathname: PermitPathHelpers.PermitCreate,
                          });
                        }}
                      >
                        {t("add_new_permit_description")}
                      </button>
                    </SectionCard>
                  </div>
                </RenderWhenAuthorized>
              </div>
            </div>
          </div>
        </div>
      </Layout.HomeContent>
    </Layout.Root>
  );
};

export default Index;
