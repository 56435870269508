import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import AdminLandsForm, {
  AdminLandsFormFields,
} from "components/organisms/admin/lands/AdminLandsForm";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CreateLandDocument } from "../../../generated/gql-types";
import { mapFormToCreateInput } from "../../../components/organisms/admin/lands/mappers";
import { useHistory } from "react-router-dom";
import GraphqlError from "../../../components/GraphqlError";

export interface LandsCreatePageProps {}

export const LandsCreatePage: React.FC<LandsCreatePageProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [createLand, { loading: saving, error: savingError }] = useMutation(
    CreateLandDocument,
    {
      refetchQueries: ["LandsForAdminLand"],
    }
  );

  const onSubmit: SubmitHandler<AdminLandsFormFields> = async (formData) => {
    const createInput = mapFormToCreateInput(formData);

    try {
      const res = await createLand({
        variables: {
          input: createInput,
        },
      });
      if (res.errors) throw res.errors;
      history.push({
        pathname: "/admin/lands",
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("add_a_land")}</h1>

          <GraphqlError title="Error creating land" errors={savingError} />

          <AdminLandsForm onSubmit={onSubmit} />
        </Layout.Content>

        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};
