import * as AzureSearchConfig from "../../../pages/search/hooks/azureSearchConfig";
import { AzureSearchParams, FacetValuesResponse } from "../types";
import buildQuery from "../../../util/odata/buildQuery";

/**
 * Given a list of facets, this function will fetch their associated filter values.
 *
 * @param facetsArray list of facets to fetch values for
 * @param indexName name of the azure search index to call
 */
export const fetchFilters = async (
  facetsArray: string[],
  indexName: string,
  extraFilters?: { and?: object[]; or?: object[] }
): Promise<FacetValuesResponse> => {
  const url = new URL(
    // `${AzureSearchConfig.BASE_URL}/${AzureSearchConfig.INDEX_NAME}/docs/search`
    `${AzureSearchConfig.BASE_URL}/${indexName}/docs/search`
  );
  url.searchParams.append("api-version", AzureSearchConfig.API_VERSION);

  const headers = new Headers();
  headers.append("api-key", AzureSearchConfig.API_KEY);
  headers.append("Content-Type", "application/json");

  const facets = facetsArray.map(addCountZero);

  let filterString;
  if (extraFilters) {
    filterString = buildQuery({
      filter: extraFilters,
    });
  }

  const body: Partial<AzureSearchParams> = {
    select: "Id",
    top: 0,
    facets,
    filter: filterString,
  };

  return await fetch(url.toString(), {
    method: "post",
    headers,
    body: JSON.stringify(body),
  }).then((x) => x.json());
};

/**
 * This function is used to add a count query param of 0 (unlimited) to each facet name when we
 * initially fetch all the filter checkboxes. By default the limit is 10, which may
 * leave some filter options missing from the list.
 *
 * Docs for azure search facet options are in the `facet` row of the table here:
 * https://docs.microsoft.com/en-us/rest/api/searchservice/search-documents
 *
 * @param facet facet string, from facetsList JSON
 */
const addCountZero = (facet: string) => facet + ",count:0";
