import { RoleMultiSelectListWithController } from "components/organisms/admin/RoleMultiSelectList/RoleMultiSelectList";
import { AuthorizationSarDmtsRoleInput } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButtons from "../FormButtons/FormButtons";

interface RoleFormProps {
  currentUserProgram: string; // Current user's program.
  initialValues: Partial<RoleFields>;
  onSubmit: SubmitHandler<Partial<RoleFields>>;
  onClose: () => void;
}

const RoleForm: React.FC<RoleFormProps> = (props) => {
  const { currentUserProgram, initialValues } = props;
  const form = useForm<RoleFields>({
    defaultValues: initialValues,
  });

  const { handleSubmit, formState, control } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<Partial<RoleFields>> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/* -------------- Roles -------------- */}
      <div className="form-group">
        <RoleMultiSelectListWithController
          control={control}
          defaultValue={initialValues.roles}
          currentUserProgram={currentUserProgram}
          name="roles"
        />
      </div>
      <hr />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
        errors={errors}
      />
    </form>
  );
};

export default RoleForm;

export interface RoleFields {
  roles?: AuthorizationSarDmtsRoleInput[];
}

// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
