import { Item } from "@react-stately/collections";
import Autocomplete from "components/molecules/Autocomplete";
import { OverviewWsSpecies } from "generated/gql-types";
import type { ReactElement } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { UseAssociatedSpeciesAutocompleteProps } from "./useAssociatedSpeciesAutocomplete";

interface AssociatedSpeciesAutocompleteProps {
  onSelectionChange: (newSpecies?: OverviewWsSpecies) => void;
  initialValue?: OverviewWsSpecies;
  label?: string | ReactElement;
  placeholder?: string;
  useAssociatedSpeciesAutocompleteInstance: UseAssociatedSpeciesAutocompleteProps;
  disabled?: boolean;
  required?: boolean;
  onClear?: () => void;
}

const AssociatedSpeciesAutocomplete: React.FC<
  AssociatedSpeciesAutocompleteProps
> = (props) => {
  const { t, i18n } = useTranslation();
  const { list } = props.useAssociatedSpeciesAutocompleteInstance;

  const defaultValue = React.useMemo(() => {
    const initialValue = props.initialValue;
    if (!initialValue) return "";
    if (i18n.language === "fr")
      return initialValue.commonNameFrench?.name?.text ?? "";
    return initialValue.commonNameEnglish?.name?.text ?? "";
  }, [i18n.language, props.initialValue]);

  const speciesNameForLanguage = React.useCallback(
    (item: OverviewWsSpecies) => {
      if (i18n.language === "fr") {
        return {
          label: item.listingRefId ? "listing_id" : "cosewic_id",
          refId: item.listingRefId ? item.listingRefId : item.cosewicRefId,
          commonName: item?.commonNameFrench?.name?.plainText,
          population: item?.population?.nameFr,
        };
      }

      return {
        label: item.listingRefId ? "listing_id" : "cosewic_id",
        refId: item.listingRefId ? item.listingRefId : item.cosewicRefId,
        commonName: item?.commonNameEnglish?.name?.plainText,
        population: item?.population?.nameEn,
      };
    },
    [i18n.language]
  );

  const onSelectionChange = (key: string | number) => {
    const item = list.items.find(
      (x) => (x.listingRefId?.id ?? x.cosewicRefId?.id) === key
    );
    if (item == null) {
      props.onSelectionChange(undefined);
      return;
    }

    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <Autocomplete
      label={props.label}
      items={list.items}
      inputValue={list.filterText}
      defaultInputValue={defaultValue}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      onSelectionChange={onSelectionChange}
      placeholder={(props.placeholder ?? props.label ?? "").toString()}
      disabled={props.disabled}
      isRequired={props.required}
      onClear={onClear}
    >
      {(item: OverviewWsSpecies) => {
        const species = speciesNameForLanguage(item);
        const commonName = species.commonName ?? "error";
        //const legacyId = t(species.label) + " " + species.refId?.legacyId;
        const population = species.population;
        let item_value = commonName;
        if (population) item_value += ", " + population;
        let cosewic_listing_label = "";

        if (item.cosewicRefId?.legacyId)
          cosewic_listing_label +=
            t("cosewic_id") + " " + item.cosewicRefId?.legacyId;
        if (item.listingRefId?.legacyId)
          cosewic_listing_label +=
            ", " + t("listing_id") + " " + species.refId?.legacyId;

        return (
          <Item
            key={species.refId?.id}
            textValue={item_value + " - " + cosewic_listing_label}
          >
            <span className="font-size-16">
              {item_value}
              {" - "}
              <span className="text-muted">{cosewic_listing_label}</span>
            </span>
          </Item>
        );
      }}
    </Autocomplete>
  );
};

export default AssociatedSpeciesAutocomplete;
