import { isNumber, isString } from "lodash";

export function formatPageNumber(pageNum?: string | number | null): number {
  if (isNumber(pageNum)) return pageNum;
  if (isString(pageNum)) return parseInt(pageNum);
  return 1;
}

export function formatSkipParameter(
  skipNumber?: string | number | null
): number {
  if (isNumber(skipNumber)) return skipNumber;
  if (isString(skipNumber)) return parseInt(skipNumber);
  return 0;
}
