import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import ResultsTable, {
  ColumnHeader,
} from "components/organisms/search/ResultsTable/ResultsTable";
import { useOverviewContext } from "features/overview";
import { DOCUMENT_INDEX } from "features/search/documents/constants";
import { useBasicSearchQuery } from "features/search/hooks/useBasicSearchQuery";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import useQueryParams from "hooks/util/useQueryParams";
import i18n from "i18n";
import useBasicSearch from "pages/search/hooks/useBasicSearch";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";

interface InternalDocumentPageColumns {
  id: string;
  "Title/English/Text"?: string;
  "Title/French/Text"?: string;
  Programs: string;
  "DocumentType/NameEn"?: string;
  "DocumentType/NameFr"?: string;
  Stage: string;
  UploadedDate: string;
}

const InternalDocumentsPage: React.FC = (props) => {
  const { t } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;
  const queryType = "full";
  const searchMode = "any";
  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  const internalDocumentsExtraFilters = useMemo(() => {
    return {
      or: [
        {
          and: [
            {
              AssociatedSpecies: {
                any: [
                  {
                    CosewicRefId: { SpeciesId: { eq: overviewId } },
                  },
                ],
              },
              PublicationType: { eq: "INTERNAL" },
            },
          ],
        },
        {
          and: [
            {
              AssociatedSpecies: {
                any: [
                  {
                    ListingRefId: { SpeciesId: { eq: overviewId } },
                  },
                ],
              },
              PublicationType: { eq: "INTERNAL" },
            },
          ],
        },
      ],
    };
  }, [overviewId]);

  const basicSearch = useBasicSearch("basicsearch_documents");

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      setSortDescriptor({
        column: i18n.language === "fr" ? "Title/French/PlainText" : "Title/English/PlainText",
        direction: "ascending",
      });
    }

    return makeOrderByString(sortDescriptor);
  }, [sortDescriptor]);

  const { data, loading, error, runSearchQuery } =
    useBasicSearchQuery(DOCUMENT_INDEX);

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      return {
        queryType,
        searchMode,

        search: basicSearch.state.keywordSearchText,
        allSelectedFilters: basicSearch.state.checkboxFilters,
        allNumberRanges: basicSearch.state.numberRangeFilters,
        allDateRanges: basicSearch.state.dateRangeFilters,

        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        orderby: orderByString,
        count: true,
        extraFilters: internalDocumentsExtraFilters,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      orderByString,
      internalDocumentsExtraFilters,
    ]
  );

  // Update Ribbon and SideNav.
  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId!);
  }, [overviewProgram, overviewId]);

  useEffect(
    function autoRunSearch() {
      runSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments, runSearchQuery]
  );

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("document_name"),
            key: i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text",
        sortable: true,
        hasLink: true,
        LinkOpenTab: true,
        isHTML: true,
      },
      {
        name: t("program"),
        key: "Programs",
        sortable: false,
      },
      {
        name: t("type"),
        key:
          i18n.language === "fr"
            ? "DocumentType/NameFr"
            : "DocumentType/NameEn",
        sortable: true,
      },
      {
        name: t("stage"),
        key: "Stage",
        sortable: true,
      },
      {
        name: t("upload_date"),
        key: "UploadedDate",
        sortable: true,
      },
    ],
    [t]
  );

  const rows: Array<InternalDocumentPageColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let documentName = "";
        let documentType = "";
        if (i18n.language === "fr") {
          documentName = value?.Title?.French?.Text;
          documentType = value?.DocumentType?.NameFr;
        } else {
          documentName = value?.Title?.English?.Text;
          documentType = value?.DocumentType?.NameEn;
        }

        return {
          _href: `/documents/${value?.Id}`,
          id: value?.DocumentId,
            [i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text"]:
            documentName,
          Programs: value?.Programs?.map((x: any) =>
            i18n.language === "fr" ? (x.NameFr as string) : (x.NameEn as string)).join(", "),
          [i18n.language === "fr"
            ? "DocumentType/NameFr"
            : "DocumentType/NameEn"]: documentType,
          Stage: t(value?.Stage),
          UploadedDate: formatTimestamp(value?.UploadedDate),
        };
      }),
    [data?.value]
  );

  return (
    <>
      {/*Response Statement Documents Table*/}
      {loading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : rows && rows?.length === 0 ? (
        <NoResults centered />
      ) : (
        <>
          <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
            <div className="font-size-18">
              {pagination.makeShowingString(data ? data["@odata.count"] : 0)}
            </div>
            <div>
              <PageSizeSelect
                pageSize={pagination.pageSize}
                onChangePageSize={onChangePageSize}
              />
            </div>
          </div>
          {!loading && error ? <pre>{JSON.stringify(error)}</pre> : null}
          {rows == null || rows.length === 0 ? null : (
            <ResultsTable
              rows={rows}
              columns={columns}
              sortable
              sortDescriptor={sortDescriptor}
              onSortChange={onSortChange as any}
            />
          )}

          <Pagination
            {...pagination.paginationComponentProps}
            onPageSelected={onPageSelected}
            totalPages={
              data && data["@odata.count"] && pagination.pageSize
                ? Math.ceil(data["@odata.count"] / pagination.pageSize)
                : 0
            }
          />
        </>
      )}
    </>
  );
};

export default InternalDocumentsPage;
