import { getApiEndpointForEnvironment } from "azure/environment";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";

export const fetchExportPdf = async (id: string, languageCode: string) => {
  const token = await getUserAccessToken();
  const apiEndpointUrl = getApiEndpointForEnvironment();
  const exportUrl = `${apiEndpointUrl}lwss/v1/responsestatement/${id}/pdf`;

  let outputFileName =
    "sardmts-response-statement-export-" + new Date().toDateString() + ".pdf"; // default fallback filename

  return await fetch(exportUrl, {
    method: "get",
    headers: {
      "Accept-Language": languageCode === "fr" ? "fr-CA" : "en-CA",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }

      const contentDispositionHeader = res.headers.get("Content-Disposition");

      if (contentDispositionHeader != null) {
        const pattern = /filename=(?<filename>.+.pdf);/g;
        const groups = pattern.exec(contentDispositionHeader)?.groups;

        if (
          groups != null &&
          groups.filename != null &&
          groups.filename.length > 0
        ) {
          outputFileName = groups.filename;
        }
      }

      return res;
    })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
};
