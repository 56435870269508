import FieldValidationError from "components/atoms/forms/FieldValidationError";
import BilingualToggleContainer from "components/molecules/BilingualToggleContainer";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import AddRemoveContactButtonAndModal from "components/organisms/contacts/AddContactButtonAndModal/AddRemoveContactButtonAndModal";
import { CustomContact } from "components/organisms/contacts/types";
import {
  MAX_LENGTH_OF_500,
  MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
} from "config/constants";
import {
  BilingualLookupTextInput,
  BilingualRichTextInput,
  RichTextInput,
} from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "util/forms";
import { htmlToPlainText } from "util/richtext";
import { ConsultationPathDropdownWithController } from "../ConsultationPathDropdown/ConsultationPathDropdown";
import { setNullForEmptyFields } from "./util";

interface ListingProcessTifFormProps {
  initialValues: Partial<ListingProcessTifFormFields>;
  onSubmit: SubmitHandler<Partial<ListingProcessTifFormFields>>;
  onClose: () => void;
}

const ListingProcessTifForm: React.FC<ListingProcessTifFormProps> = (props) => {
  const { initialValues } = props;

  const form = useForm<ListingProcessTifFormFields>({
    defaultValues: props.initialValues,
  });

  const { handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<ListingProcessTifFormFields> = async (
    formData
  ) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, initialValues);

    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        // Note: Filter dirty fields and Set null for empty fields are not required for updating Tif.
        // const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
        // setNullForEmptyFields(FilteredFormData, dirtyFields, props.defaultValues);
        props.onSubmit(FilteredFormData);
        resolve();
      }, 500);
    });
  };

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_TIF_REGION_FIELD_NAME;

  const onRemoveGisContactPerson = () => {
    form.setValue("gisContactPerson", null, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  const onRemoveBiologistContactPerson = () => {
    form.setValue("biologistContactPerson", null, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/* ---------- Proposed Consultation path ---------- */}
      <div className="form-group">
        <label tabIndex={-1} htmlFor="proposedConsultationPath">
          {t("proposed_consultation_path")}
        </label>
        <ConsultationPathDropdownWithController
          control={control}
          id="proposedConsultationPath"
          name="proposedConsultationPath"
          // defaultValue={props.defaultValues.proposedConsultationPath}
          placeholder={t("please_select_option")}
        />
        {errors.proposedConsultationPath && (
          <FieldValidationError>
            {JSON.stringify(errors.proposedConsultationPath)}
          </FieldValidationError>
        )}
      </div>

      {/* ---------- Range of impact ---------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="rangeOfImpact-rte"
        english={
          <div className="form-group">
            <label htmlFor="rangeOfImpact.english.text">
              {t("range_of_impact")} ({t("english")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.rangeOfImpact?.english?.text ?? ""
              }
              id="rangeOfImpact.english.text"
              name="rangeOfImpact.english.text"
            />
            {errors.rangeOfImpact?.english &&
              (errors.rangeOfImpact.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="rangeOfImpact.french.text">
              {t("range_of_impact")} ({t("french")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.rangeOfImpact?.french?.text ?? ""
              }
              id="rangeOfImpact.french.text"
              name="rangeOfImpact.french.text"
            />
            {errors.rangeOfImpact?.french &&
              (errors.rangeOfImpact.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />

      {/* ---------- Consultation requirements ---------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="consultationRequirements-rte"
        english={
          <div className="form-group">
            <label htmlFor="consultationRequirements.english.text">
              {t("consultation_requirements")} ({t("english")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.consultationRequirements?.english?.text ??
                ""
              }
              id="consultationRequirements.english.text"
              name="consultationRequirements.english.text"
            />
            {errors.consultationRequirements?.english &&
              (errors.consultationRequirements.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="consultationRequirements.french.text">
              {t("consultation_requirements")} ({t("french")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.consultationRequirements?.french?.text ?? ""
              }
              id="consultationRequirements.french.text"
              name="consultationRequirements.french.text"
            />
            {errors.consultationRequirements?.french &&
              (errors.consultationRequirements.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />

      {/* ---------- Information uncertainties ---------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="informationUncertainties-rte"
        english={
          <div className="form-group">
            <label htmlFor="informationUncertainties.english.text">
              {t("information_uncertainties")} ({t("english")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.informationUncertainties?.english?.text ??
                ""
              }
              id="informationUncertainties.english.text"
              name="informationUncertainties.english.text"
            />
            {errors.informationUncertainties?.english &&
              (errors.informationUncertainties.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="informationUncertainties.french.text">
              {t("information_uncertainties")} ({t("french")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.informationUncertainties?.french?.text ?? ""
              }
              id="informationUncertainties.french.text"
              name="informationUncertainties.french.text"
            />
            {errors.informationUncertainties?.french &&
              (errors.informationUncertainties.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />

      {/* ---------- Species data source ---------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="speciesDataSource-rte"
        english={
          <div className="form-group">
            <label htmlFor="speciesDataSource.english.text">
              {t("species_data_source")} ({t("english")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.speciesDataSource?.english?.text ?? ""
              }
              id="speciesDataSource.english.text"
              name="speciesDataSource.english.text"
            />
            {errors.speciesDataSource?.english &&
              (errors.speciesDataSource.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="speciesDataSource.french.text">
              {t("species_data_source")} ({t("french")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.speciesDataSource?.french?.text ?? ""
              }
              id="speciesDataSource.french.text"
              name="speciesDataSource.french.text"
            />
            {errors.speciesDataSource?.french &&
              (errors.speciesDataSource.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />

      {/* ---------- Methodology ---------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="methodology-rte"
        english={
          <div className="form-group">
            <label htmlFor="methodology.english.text">
              {t("methodology")} ({t("english")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={
                props.initialValues.methodology?.english?.text ?? ""
              }
              id="methodology.english.text"
              name="methodology.english.text"
            />
            {errors.methodology?.english &&
              (errors.methodology.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="methodology.french.text">
              {t("methodology")} ({t("french")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={props.initialValues.methodology?.french?.text ?? ""}
              id="methodology.french.text"
              name="methodology.french.text"
            />
            {errors.methodology?.french &&
              (errors.methodology.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />

      {/* ---------- GIS contact person and notes ---------- */}
      <div className="form-group border-light">
        {/* -- contact person -- */}
        <div className="form-group">
          <label htmlFor="gisContactPerson">{t("gis_contact_person")}</label>
          <Controller
            name="gisContactPerson"
            control={control}
            // rules={{ validate: (value) => value != null }}
            render={({ field: { value, onChange } }) => {
              return (
                <AddRemoveContactButtonAndModal
                  id="gisContactPerson"
                  contact={value}
                  isContactOnly={true}
                  onSave={(newValue, contactType) => {
                    if (contactType === "contact") {
                      onChange({
                        contactType,
                        contactRef: newValue,
                        organizationRef: null,
                      });
                    } else if (contactType === "organization") {
                      console.error(
                        "Invalid Contact type Organization selected."
                      );
                    }
                    form.trigger("gisContactPerson");
                  }}
                  onRemove={onRemoveGisContactPerson}
                />
              );
            }}
          />
        </div>
        {/* -- contact note -- */}
        <div className="form-group">
          <label htmlFor="gisContactNotes">{t("gis_contact_note")}</label>
          <FullHTMLEditorWithController
            control={control}
            rules={{
              validate: {
                maxLengthLimit: validateHtmlTextMaxLengthLimit,
              },
            }}
            defaultValue={props.initialValues.gisContactNotes?.text ?? ""}
            id="gisContactNotes"
            name="gisContactNotes.text"
          />
          {errors.gisContactNotes &&
            (errors.gisContactNotes as any)?.text?.type ===
              "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_500,
                })}
              </FieldValidationError>
            )}
        </div>
      </div>

      {/* ---------- Biologist contact person and notes ---------- */}
      <div className="form-group border-light">
        {/* -- contact person -- */}
        <div className="form-group">
          <label htmlFor="biologistContactPerson">
            {t("biologist_contact_person")}
          </label>
          <Controller
            name="biologistContactPerson"
            control={control}
            // rules={{ validate: (value) => value != null }}
            render={({ field: { value, onChange } }) => {
              return (
                <AddRemoveContactButtonAndModal
                  id="biologistContactPerson"
                  contact={value}
                  isContactOnly={true}
                  onSave={(newValue, contactType) => {
                    if (contactType === "contact") {
                      onChange({
                        contactType,
                        contactRef: newValue,
                        organizationRef: null,
                      });
                    } else if (contactType === "organization") {
                      console.error(
                        "Invalid Contact type Organization selected."
                      );
                    }
                    form.trigger("biologistContactPerson");
                  }}
                  onRemove={onRemoveBiologistContactPerson}
                />
              );
            }}
          />
        </div>
        {/* -- contact note -- */}
        <div className="form-group">
          <label htmlFor="biologistContactNotes">
            {t("biologist_contact_note")}
          </label>
          <FullHTMLEditorWithController
            control={control}
            rules={{
              validate: {
                maxLengthLimit: validateHtmlTextMaxLengthLimit,
              },
            }}
            defaultValue={props.initialValues.biologistContactNotes?.text ?? ""}
            id="biologistContactNotes"
            name="biologistContactNotes.text"
          />
          {errors.biologistContactNotes &&
            (errors.biologistContactNotes as any)?.text?.type ===
              "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_500,
                })}
              </FieldValidationError>
            )}
        </div>
      </div>

      {/* ---------- Comments ---------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="comments-rte"
        english={
          <div className="form-group">
            <label htmlFor="comments.english.text">
              {t("comments")} ({t("english")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={props.initialValues.comments?.english?.text ?? ""}
              id="comments.english.text"
              name="comments.english.text"
            />
            {errors.comments?.english &&
              (errors.comments.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="comments.french.text">
              {t("comments")} ({t("french")})
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
              }}
              defaultValue={props.initialValues.comments?.french?.text ?? ""}
              id="comments.french.text"
              name="comments.french.text"
            />
            {errors.comments?.french &&
              (errors.comments.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_TIF_REGION_FIELD_NAME,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
      />
    </form>
  );
};

export default ListingProcessTifForm;

export interface ListingProcessTifFormFields {
  proposedConsultationPath: BilingualLookupTextInput;
  rangeOfImpact: BilingualRichTextInput;
  consultationRequirements: BilingualRichTextInput;
  informationUncertainties: BilingualRichTextInput;
  speciesDataSource: BilingualRichTextInput;
  methodology: BilingualRichTextInput;
  gisContactPerson: CustomContact | null;
  gisContactNotes: RichTextInput;
  biologistContactPerson: CustomContact | null;
  biologistContactNotes: RichTextInput;
  comments: BilingualRichTextInput;
}
