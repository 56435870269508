import * as React from "react";
import type { AriaListBoxOptions } from "@react-aria/listbox";
import type { Node, LoadingState } from "@react-types/shared";
import type { ListState } from "@react-stately/list";
import { useListBox, useOption } from "react-aria";
import NoResults from "../../atoms/NoResults";

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
  loadingState?: LoadingState;
  onLoadMore?: () => void;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

export function ListBox(props: ListBoxProps) {
  let ref = React.useRef<HTMLUListElement>(null);
  let { listBoxRef = ref, state } = props;
  let { listBoxProps } = useListBox(props, state, listBoxRef);

  let onScroll = (e: React.UIEvent) => {
    const scrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
    const scrollIsAtBottom =
      Math.floor(scrollPosition) <= Math.floor(e.currentTarget.clientHeight);

    if (props.onLoadMore != null && scrollIsAtBottom) {
      if (props.loadingState === "loadingMore") return;
      props.onLoadMore();
    }
  };

  const collection = React.useMemo(
    () => Array.from(state.collection),
    [state.collection]
  );

  return (
    <ul {...listBoxProps} ref={listBoxRef} style={ulStyles} onScroll={onScroll}>
      {collection.map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
      {props.loadingState === "loadingMore" && (
        // Display a spinner at the bottom of the list if we're loading more.
        //
        // If rendering anything other than an <option> here,
        // role="option" is required for valid ARIA semantics since
        // we're inside a role="listbox". The <option> element will
        // implicitly have the role already.
        <option style={optionStyles}>...</option>
      )}
    </ul>
  );
}

function Option({ item, state }: OptionProps) {
  let ref = React.useRef<HTMLLIElement>(null);
  let { optionProps, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  const style = React.useMemo(() => {
    return {
      listStyleType: "none",
      margin: 0,
      padding: "4px 8px",
      background: getBackgroundColor(isFocused, isSelected),
    };
  }, [isFocused, isSelected]);

  return (
    <li {...optionProps} style={style} ref={ref}>
      {item.rendered}
    </li>
  );
}

const getBackgroundColor = (focused: boolean, selected: boolean) => {
  if (focused || selected) return "#b0bed9";
  return "white";
};

const ulStyles = {
  overflow: "auto",
  maxHeight: "300px",
  marginTop: "1px",
  marginBottom: "1px",
  listStyleType: "none",
  margin: 0,
  padding: 0,
};

const optionStyles = {
  paddingTop: "4px",
  paddingBottom: "2px",
  display: "flex",
  justifyContent: "center",
};
