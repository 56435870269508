import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForProgramDocument,
  BilingualAbbreviationAcronymTagsLookupText,
  Maybe,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";
import i18n from "i18n";
import { bilingualLookupTextNameForLanguage } from "mappers";
import { useTranslation } from "react-i18next";

const additionalQueryVariables = {
  params: {
    searchType: "startsWith",
    isCaseSensitive: false,
    category: "programs",
    sortBy: i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
  },
};

const ProgramAutocomplete: React.FC<ProgramAutocompleteProps> = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <QueryAutocomplete
      id={"programAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForProgramDocument}
      listDisplayFormatter={(item) =>
        i18n.language === "fr" ? item.name.french : item.name.english
      }
      onSelectionChange={props.onSelectionChange}
      placeholder={
        i18n.language === "fr"
          ? props.initialValue?.name.french ?? t("program")
          : props.initialValue?.name.english ?? t("program")
      }
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(undefined)}
      additionalQueryVariables={additionalQueryVariables}
      disabled={props.disabled}
    />
  );
};

interface ProgramAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (
    selectedItem?: BilingualAbbreviationAcronymTagsLookupText
  ) => void;
  label: string | ReactElement;
  disabled: boolean | undefined;
  initialValue?: any;
  currentUserProgram?: string;
}

export default ProgramAutocomplete;
