import { useQuery } from "@apollo/client";
import NoResults from "components/atoms/NoResults";
import * as React from "react";
import { useMemo } from "react";
import { getI18n, useTranslation } from "react-i18next";
import {
  AddContactSearchContactsDocument,
  AddContactSearchOrganizationsDocument,
  ArchiveStatus,
  Contact,
  ContactDetails,
  ContactTag,
  Maybe,
  Note,
  Organization,
  OrganizationRef,
} from "../../../../generated/gql-types";
import { usePagination } from "../../../../hooks/util/usePagination";
import { bilingualTextNameForLanguage } from "../../../../mappers";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import GraphqlError from "../../../GraphqlError";
import LoadingIndicator from "../../../atoms/LoadingIndicator";
import { default as PaginationComponent } from "../../../molecules/Pagination";
import AddContactModalResult, {
  AddContactModalItem,
} from "./AddContactModalResult";

export interface AddContactSearchProps {
  search: string;
  tags?: ContactTag | string;
  onChange: (newValue: Maybe<Contact> | Maybe<Organization>) => void;
}

export const AddContactModalSearchContacts: React.FC<AddContactSearchProps> = (
  props
) => {
  const pagination = usePagination();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    pagination.setPageSize(5);
  }, []);

  React.useEffect(() => {
    props.onChange(null);
  }, [pagination.currentPage]);

  const { loading, error, data } = useQuery(AddContactSearchContactsDocument, {
    variables: {
      params: {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        search: props.search,
        status: ArchiveStatus.Active,
        searchType: "contains",
        tags: props.tags,
        sortBy: "name:asc",
      },
    },
  });

  const formattedResults = useMemo(() => {
    if (isNullOrEmpty(data)) return [];
    if (isNullOrEmpty(data?.contactList?.contact)) return [];
    return data?.contactList?.contact?.map(formatContactListItem);
  }, [data]);

  if (loading) return <LoadingIndicator />;
  if (error) return <GraphqlError errors={error} />;

  return (
    <>
      <fieldset className="chkbxrdio-grp">
        <legend className="pull-left width-auto pb-2_5">
          <span className="field-name">{t("search_results")}</span>
        </legend>
        <div className="pull-right py-2">
          {pagination.makeShowingString(
            data?.contactList?.pagination?.totalCount
          )}
        </div>
        <div className="clearfix"></div>

        {formattedResults == null || formattedResults.length === 0 ? (
          <NoResults centered className="mrgn-bttm-md" />
        ) : null}

        {formattedResults?.map((result, index) => (
          <div key={index}>
            <div className="list-item px-2">
              <AddContactModalResult
                result={result}
                onChange={(id: string) => {
                  const newSelectedValue = data?.contactList?.contact?.find(
                    (x) => x?.id === id
                  );
                  props.onChange(newSelectedValue as Contact);
                }}
              />
            </div>
            {index !== formattedResults.length - 1 && (
              <hr className="mrgn-tp-0 mrgn-bttm-0" />
            )}
          </div>
        ))}
      </fieldset>

      <PaginationComponent
        {...pagination.paginationComponentProps}
        totalPages={data?.contactList?.pagination?.totalPages ?? 0}
      />
    </>
  );
};

export const AddContactModalSearchOrganization: React.FC<
  AddContactSearchProps
> = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination();

  React.useEffect(() => {
    pagination.setPageSize(5);
  }, []);

  React.useEffect(() => {
    props.onChange(null);
  }, [pagination.currentPage]);

  const { loading, error, data } = useQuery(
    AddContactSearchOrganizationsDocument,
    {
      variables: {
        params: {
          pageNumber: pagination.currentPage,
          pageSize: pagination.pageSize,
          search: props.search,
          status: ArchiveStatus.Active,
          searchType: "contains",
          tags: props.tags,
          sortBy:
            i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
        },
      },
    }
  );

  const formattedResults = useMemo(() => {
    if (isNullOrEmpty(data)) return [];
    if (isNullOrEmpty(data?.organizationList?.organization)) return [];
    return data?.organizationList?.organization?.map((x: any) =>
      formatOrganizationListItem(x)
    );
  }, [data]);

  if (loading) return <LoadingIndicator />;
  if (error) return <GraphqlError errors={error} />;
  return (
    <>
      <fieldset className="chkbxrdio-grp">
        <legend className="pull-left width-auto pb-2_5">
          <span className="field-name">{t("search_results")}</span>
        </legend>
        <div className="pull-right py-2">
          {pagination.makeShowingString(
            data?.organizationList?.pagination?.totalCount
          )}
        </div>
        <div className="clearfix"></div>

        {formattedResults == null || formattedResults.length === 0 ? (
          <NoResults centered className="mrgn-bttm-md" />
        ) : null}
        {formattedResults?.map((result, index) => (
          <div key={index}>
            <div className="list-item px-2">
              <AddContactModalResult
                result={result}
                onChange={(id: string) => {
                  const newSelectedValue =
                    data?.organizationList?.organization?.find(
                      (x) => x?.id === id
                    );
                  props.onChange(newSelectedValue as Organization);
                }}
              />
            </div>
            {index !== formattedResults.length - 1 && (
              <hr className="mrgn-tp-0 mrgn-bttm-0" />
            )}
          </div>
        ))}
      </fieldset>

      <PaginationComponent
        {...pagination.paginationComponentProps}
        totalPages={data?.organizationList?.pagination?.totalPages ?? 0}
      />
    </>
  );
};

const processContactModalContactDetails = (
  contactDetails?: Maybe<ContactDetails>
) => {
  let out: string = "";

  // Address line 1
  if (!isNullOrEmpty(contactDetails?.address?.addressLines?.[0])) {
    const addressLine1 = bilingualTextNameForLanguage(
      getI18n().language,
      contactDetails?.address?.addressLines?.[0]
    );
    if (!isNullOrEmpty(addressLine1)) {
      out += addressLine1 + "<br />";
    }
  }

  // Address line 2
  if (!isNullOrEmpty(contactDetails?.address?.addressLines?.[1])) {
    const addressLine2 = bilingualTextNameForLanguage(
      getI18n().language,
      contactDetails?.address?.addressLines?.[1]
    );
    if (!isNullOrEmpty(addressLine2)) {
      out += addressLine2 + "<br />";
    }
  }
  if (
    !isNullOrEmpty(contactDetails?.address?.city) &&
    contactDetails?.address?.provinceOrStateName != null
  ) {
    out += contactDetails?.address?.city + ", ";
    out +=
      getI18n().language === "fr"
        ? contactDetails?.address?.provinceOrStateName?.french ?? ""
        : contactDetails?.address?.provinceOrStateName?.english ?? "";
    out += "<br />"; // City, Province
  } else {
    if (!isNullOrEmpty(contactDetails?.address?.city)) {
      out += contactDetails?.address?.city + "<br />"; // City
    }
    if (contactDetails?.address?.provinceOrStateName != null) {
      out +=
        getI18n().language === "fr"
          ? contactDetails?.address?.provinceOrStateName?.french ?? ""
          : contactDetails?.address?.provinceOrStateName?.english ?? "";
      out += "<br />"; // Province
    }
  }
  if (!isNullOrEmpty(contactDetails?.address?.postalOrZipCode)) {
    out += contactDetails?.address?.postalOrZipCode + "<br />"; // Postal code
  }
  if (!isNullOrEmpty(contactDetails?.phoneNumber)) {
    out += getI18n().t("phone") + ": " + contactDetails?.phoneNumber + "<br />"; // phone number
  }
  if (!isNullOrEmpty(contactDetails?.faxNumber)) {
    out += getI18n().t("fax") + ": " + contactDetails?.faxNumber + "<br />"; // fax number
  }
  if (!isNullOrEmpty(contactDetails?.emailAddress)) {
    out +=
      "<a href=mailto:" +
      contactDetails?.emailAddress +
      ">" +
      contactDetails?.emailAddress +
      "</a>"; // Email address
  }

  return out;
};

const processContactModalNote = (note?: Maybe<Note>) => {
  const NOTE_TRUNCATE_LENGTH = 120;
  if (isNullOrEmpty(note?.text?.plainText)) return undefined;

  // Use plain text for the note so we can truncate the string without losing HTML tags
  const text = note?.text?.plainText ?? "";

  if (text.length > NOTE_TRUNCATE_LENGTH) {
    return text.substring(0, NOTE_TRUNCATE_LENGTH) + "...";
  } else {
    return text;
  }
};

/**
 * Full:
 * [First-name] [Last-name]
 * [jobTitle]
 * [OrgL4]
 * [OrgL3]
 * [OrgL2]
 * [OrgL1]
 * [Street name - Address]
 * [Suite number - Address]
 * [City], [Province]
 * [Postal Code]
 * [Phone]
 * [Fax]
 * [Email]
 *
 */

export const formatContactListItem = (x: Contact | null | undefined) => {
  const out: AddContactModalItem = {
    id: x?.id ?? "ERR MISSING ID",
  };

  // Contact name processing
  //
  if (isNullOrEmpty(x?.name?.firstName) && isNullOrEmpty(x?.name?.lastName)) {
    // set first line as undefined, so UI will render translated "No Name" string.
    out.name = undefined;
  } else {
    out.name = `${x?.name?.firstName} ${x?.name?.lastName}`.trim();
  }

  // Initial the address
  out.address = "";

  // Job title information
  if (!isNullOrEmpty(x?.jobTitle)) {
    out.address +=
      bilingualTextNameForLanguage(getI18n().language, x?.jobTitle) + "<br />";
  }

  // Fetch the organization level information (level3/level2/level1)
  //
  let orgLevelsString: string | undefined;
  if (
    x?.organizationDetails?.departments != null &&
    x.organizationDetails.departments.length > 0
  ) {
    // NOTE: we reverse the array to show organization level 4 first, as per the prototype.
    const organizationLevels = x.organizationDetails.departments
      .filter(
        (dept) => !isNullOrEmpty(dept?.english) && !isNullOrEmpty(dept?.french)
      )
      .map((dept) => bilingualTextNameForLanguage(getI18n().language, dept))
      .reverse();
    orgLevelsString = organizationLevels.join("<br />");
  }

  if (!isNullOrEmpty(orgLevelsString)) {
    out.address += orgLevelsString + "<br />";
  }

  if (!isNullOrEmpty(x?.organizationDetails?.name?.english)) {
    out.address += x?.organizationDetails?.name?.english + "<br />";
  }

  // Contact details information
  out.address += processContactModalContactDetails(x?.contactDetails);

  // Note information
  out.note = processContactModalNote(x?.note);

  return out;
};

export const formatOrganizationListItem = (
  x: Either<Organization, OrganizationRef> | null | undefined
) => {
  const out: AddContactModalItem = {
    id: x?.id ?? x?.organizationDetails?.id ?? "ERR MISSING ID",
  };

  // First line processing
  //

  if (x?.organizationDetails != null) {
    // IF: Handle OrganizationRef
    if (
      isNullOrEmpty(x?.organizationDetails?.name?.english) &&
      isNullOrEmpty(x?.organizationDetails?.name?.french)
    ) {
      // set first line as undefined, so UI will render translated "No Name" string.
      out.name = undefined;
    } else {
      out.name = bilingualTextNameForLanguage(
        getI18n().language,
        x?.organizationDetails?.name
      );
    }
  } else {
    // ELSE: Handle Organization
    if (isNullOrEmpty(x?.name?.english) && isNullOrEmpty(x?.name?.french)) {
      // set first line as undefined, so UI will render translated "No Name" string.
      out.name = undefined;
    } else {
      out.name = bilingualTextNameForLanguage(getI18n().language, x?.name);
    }
  }

  // Initial the address
  out.address = "";

  // Fetch the organization level information (level3/level2/level1)
  //
  let orgLevelsString: string | undefined;
  if (x?.organizationDetails != null) {
    // IF: Handle OrganizationRef
    if (
      x?.organizationDetails?.departments != null &&
      x.organizationDetails.departments.length > 0
    ) {
      // NOTE: we reverse the array to show organization level 4 first, as per the prototype.
      const organizationLevels = x.organizationDetails.departments
        .filter(
          (dept) =>
            !isNullOrEmpty(dept?.english) && !isNullOrEmpty(dept?.french)
        )
        .map((dept) => bilingualTextNameForLanguage(getI18n().language, dept))
        .reverse();

      orgLevelsString = organizationLevels.join("<br />");
    }
  } else {
    // ELSE: Handle Organization
    if (x?.departments != null && x.departments.length > 0) {
      // NOTE: we reverse the array to show organization level 4 first, as per the prototype.
      const organizationLevels = x.departments
        .filter(
          (dept) =>
            !isNullOrEmpty(dept?.english) && !isNullOrEmpty(dept?.french)
        )
        .map((dept) => bilingualTextNameForLanguage(getI18n().language, dept))
        .reverse();
      orgLevelsString = organizationLevels.join("<br />");
    }
  }

  if (!isNullOrEmpty(orgLevelsString)) {
    out.address += orgLevelsString + "<br />";
  }

  // Contact details information
  out.address += processContactModalContactDetails(x?.contactDetails);

  // Note information
  out.note = processContactModalNote(x?.note);

  return out;
};
