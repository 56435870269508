import FormButtons from "components/molecules/FormButtons/FormButtons";
import { BptTemplateStepBundle } from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { StepBundlesReorder } from "./StepBundlesReorder";

export interface StepBundlesFormFields {
  stepBundles: Array<BptTemplateStepBundle>;
}

export interface StepBundlesFormProps {
  defaultValues?: Partial<StepBundlesFormFields>;
  onSubmit: SubmitHandler<Partial<StepBundlesFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

export const StepBundlesForm: React.FC<StepBundlesFormProps> = (props) => {
  const form = useForm<StepBundlesFormFields>({
    defaultValues: props.defaultValues,
    mode: "onChange",
  });

  const { control, handleSubmit, formState } = form;
  const { isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<StepBundlesFormFields>> = async (
    formData
  ) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={props.isSaving}>
        <Controller
          control={control}
          name="stepBundles"
          render={({ field: { onChange, value } }) => (
            <StepBundlesReorder
              value={value as Array<BptTemplateStepBundle>}
              onChange={onChange}
            />
          )}
        />
      </fieldset>

      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting || props.isSaving}
        onCancel={props.onClose}
        errors={errors}
      />
    </form>
  );
};
