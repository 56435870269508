import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useRouteMatch } from "react-router-dom";

export const DocumentsSideNav: React.FC = (props) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <div>
      <h2 id="wb-sec-h" className="wb-inv">
        Section Menu
      </h2>
      <section className="list-group menu list-unstyled">
        <h3>{t("Navigation")}</h3>
        <ul className="list-group menu list-unstyled">
          <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
            <li>
              <NavLink
                to={DocumentsPathHelpers.DocumentsSearch}
                className="list-group-item"
                activeClassName=""
                data-testid="side-nav-documents"
              >
                {t("documents")}
              </NavLink>

              <ul className="list-group menu list-unstyled">
                <>
                  <li>
                    <NavLink
                      exact
                      to={DocumentsPathHelpers.DocumentsSearch}
                      className="list-group-item"
                      activeClassName="wb-navcurr"
                      data-testid="side-nav-search-documents"
                    >
                      {t("search_documents")}
                    </NavLink>
                  </li>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.documents.create}
                  >
                    <li>
                      <NavLink
                        exact
                        to={DocumentsPathHelpers.DocumentsCreate}
                        className="list-group-item"
                        activeClassName="wb-navcurr"
                        data-testid="side-nav-create-document"
                      >
                        {t("create_a_document")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to={DocumentsPathHelpers.PhotoLicenseCreate}
                        className="list-group-item"
                        activeClassName="wb-navcurr"
                        data-testid="side-nav-create-photo"
                      >
                        {t("create_photo_licence_document")}
                      </NavLink>
                    </li>
                  </RenderWhenAuthorized>
                  <RenderWhenFeatureIsEnabled
                    flagKey={FeatureFlagKeys.RegistryPage}
                  >
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.registry.create}
                    >
                      <li>
                        <NavLink
                          exact
                          to={DocumentsPathHelpers.RegistryCreate}
                          className="list-group-item"
                          activeClassName="wb-navcurr"
                          data-testid="side-nav-create-document"
                        >
                          {t("create_registry_page")}
                        </NavLink>
                      </li>
                    </RenderWhenAuthorized>
                  </RenderWhenFeatureIsEnabled>
                </>
              </ul>
            </li>
          </RenderWhenFeatureIsEnabled>
        </ul>
      </section>
    </div>
  );
};

export default DocumentsSideNav;
