import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import { ListingLandFormFields } from "../ListingLandForm/ListingLandForm";
import WildlifeSpeciesPresenceInput from "../WildlifeSpeciesPresenceInput/WildlifeSpeciesPresenceInput";
import { TextInput } from "components/atoms/forms/TextInput";

export interface ListingLandWildlifeSpeciesInfoSectionProps {
  defaultValues?: Partial<ListingLandFormFields>;
}

export const ListingLandWildlifeSpeciesInfoSection: React.FC<
  ListingLandWildlifeSpeciesInfoSectionProps
> = (props) => {
  const { t, i18n } = useTranslation();

  const { register, watch, control, setValue, formState } =
    useFormContext<ListingLandFormFields>();

  const { errors } = formState;

  return (
    <div className="form-group flex-col gap-md">
      <WildlifeSpeciesPresenceInput />

      <div className="flex justify-between gap-md flex-wrap">
        <div>
          <label htmlFor="numberOfSitesInput">{t("number_of_sites")}</label>
          <input
            type="number"
            className="form-control width-100"
            min={1}
            max={999999}
            id="numberOfSitesInput"
            {...register("numberOfSites")}
          />
        </div>

        <div>
          <fieldset className="subheader-grp flex-col">
            <TextInput
              type="text"
              inputClassName="form-control width-100"
              maxLength={100}
              id="approximateNumberOfIndividuals"
              label={t("approx_num_individuals")}
              placeholder={t("approx_num_individuals")}
              {...register("approximateNumberOfIndividuals")}
            />
          </fieldset>
        </div>
      </div>

      <div>
        <label htmlFor="circumstanceInput">{t("circumstance")}</label>
        <FullHTMLEditorWithController
          control={control}
          defaultValue={props?.defaultValues?.circumstance?.text ?? ""}
          id="circumstanceInput"
          name="circumstance.text"
        />
      </div>

      <div>
        <label htmlFor="sourceInput">{t("source")}</label>
        <FullHTMLEditorWithController
          control={control}
          defaultValue={props?.defaultValues?.source?.text ?? ""}
          id="sourceInput"
          name="source.text"
        />
      </div>
    </div>
  );
};

export default ListingLandWildlifeSpeciesInfoSection;
