import cc from "classcat";
import { get } from "lodash";
import * as React from "react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../atoms/forms/FieldValidationError";
import regionalCodes from "./ISO-3166-regional-codes.json";

export interface CountryDropdownProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  id?: string;
  label?: string;
  required?: boolean;
  errors?: FieldErrors;
  divClassName?: string;
  inputClassName?: string;
}

/**
 * Country codes follow ISO-3166 alpha-2 standard.
 * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 * JSON representation sourced from https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/slim-2/slim-2.json
 * Creative Commons Attribution-ShareAlike 4.0 International License.
 */

export const CountryDropdown = React.forwardRef(
  (props: CountryDropdownProps, ref) => {
    const { t } = useTranslation();

    const name = props.name ?? "";
    const labelText = props.label ?? props.name ?? "";
    const id = props.id ?? name;

    const err = get(props.errors, name);
    const shouldShowErrors = err != null;

    const _divClassName = React.useMemo(
      () => cc(["form-group", props.divClassName]),
      [props.divClassName]
    );
    const _labelClassName = props.required ? "required" : "";
    const _inputClassName = React.useMemo(
      () => cc(["form-control", props.inputClassName]),
      [props.inputClassName]
    );

    const { required, ...propsWithoutRequired } = props;

    return (
      <div className={_divClassName}>
        <label htmlFor={id} className={_labelClassName}>
          {labelText}
          {props.required ? (
            <strong className="required"> ({t("required")})</strong>
          ) : null}
        </label>

        <select
          id={id}
          className={_inputClassName}
          {...propsWithoutRequired}
          ref={ref as any}
        >
          <option selected value="NOT_INITIALIZED">
            {t("please_select_option")}
          </option>
          {regionalCodes.map((x, index) => (
            <option key={index} value={x["alpha-2"]}>
              {x.name}
            </option>
          ))}
        </select>
        {shouldShowErrors && (
          <FieldValidationError>{err.message}</FieldValidationError>
        )}
      </div>
    );
  }
);

export default CountryDropdown;
