import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplatePhase,
  Maybe,
  UpdateBptTemplatePhasesOrderDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formIntoCreateInput } from "./ReorderPhasesUtil";
import {
  PhasesReorderFormFields,
  PhasesReorderForm,
} from "./ReorderPhasesForm";

export interface ReorderPhasesPopupProps {
  templateId: Maybe<string>;
  phases?: Maybe<Maybe<BptTemplatePhase>[]>; // Phase list
  modalState: OverlayTriggerState;
  onCompleted: (data: any) => void;
  onError?: (error: ApolloError) => void;
}

const ReorderPhasesPopup: React.FC<ReorderPhasesPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();

  const [runSubmit, { loading: saving, error: savingError, reset }] =
    useMutation(UpdateBptTemplatePhasesOrderDocument, {
      refetchQueries: ["BptTemplatePhaseList"],
    });

  const onSubmit: SubmitHandler<Partial<PhasesReorderFormFields>> = async (
    formData
  ) => {
    const input = formIntoCreateInput(formData);

    try {
      const res = await runSubmit({
        variables: {
          templateId: props.templateId ?? "error",
          input: input,
        },
      });
      if (res.errors == null) {
        modalState.close();
      }
      alertContext.showSuccess({
        message: t("successfully_update_phase_order"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={t("reorder_phases")}
          className={"modal-width-50"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError title="Phases reorder error!" errors={savingError} />
          <div>{t("reorder_phases_message")}</div>
          <PhasesReorderForm
            defaultValues={{
              phases: props.phases as BptTemplatePhase[],
            }}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default ReorderPhasesPopup;

export const useReorderPhasesPopup = useOverlayTriggerState;
