import Alert from "components/atoms/Alert";
import * as React from "react";
import { useGlobalAlertContext } from "./context";

/*
 * Global Alert Component
 * Use this component along with the methods in `useAlertContext` to carry
 * alert messages across different pages.
 */

export interface GlobalAlertProps {}

export const GlobalAlert: React.FC<GlobalAlertProps> = (props) => {
  const alertContext = useGlobalAlertContext();

  if (!alertContext.visible || alertContext.alertInfo?.message == null) {
    return null;
  }

  // window.scrollTo(0, 260); // Scroll to page header to show error message.
  window.scrollTo(0, 0); // Scroll to page top to show error message.

  return (
    <Alert
      type={alertContext.alertType}
      content={alertContext.alertInfo.message}
      title={alertContext.alertInfo.title}
      onClose={alertContext.clear}
      timeOut={alertContext.alertInfo?.timeOut}
    />
  );
};

export default GlobalAlert;
