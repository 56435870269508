import { useQuery } from "@tanstack/react-query";
import { getApiDomainName, getApiSearchServiceUrl } from "azure/environment";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { ValueEditor, ValueEditorProps } from "react-querybuilder";

const searchServiceUrl = getApiSearchServiceUrl();
const searchServiceDomain = getApiDomainName();

const getEnumList = async (href: string) => {
  if (!href) return {};
  const token = await getUserAccessToken();
  return fetch(`${searchServiceUrl}${href}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": i18n.language + "-CA",
    },
  }).then((res) => res.json());
};

const getLookupList = async (href: string) => {
  if (!href) return {};
  const token = await getUserAccessToken();
  return fetch(`${searchServiceDomain}${href}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": i18n.language + "-CA",
    },
  }).then((res) => res.json());
};

const getClassificationList = async (href: string) => {
  if (!href) return {};
  const token = await getUserAccessToken();
  return fetch(`${searchServiceDomain}${href}?pageNumber=1`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": i18n.language + "-CA",
    },
  }).then((res) => res.json());
};

const isTaxonomicGroup = (nameKey: string) => {
  return [
    "Cosewic/TaxonomicGroup/NameEn",
    "Cosewic/TaxonomicGroup/NameFr",
    "Listing/TaxonomicGroup/NameEn",
    "Listing/TaxonomicGroup/NameFr",
  ].includes(nameKey);
};

const isPopulation = (nameKey: string) => {
  return [
    "Cosewic/Population/NameEn",
    "Cosewic/Population/NameFr",
    "Listing/Population/NameEn",
    "Listing/Population/NameFr",
  ].includes(nameKey);
};

const isRange = (nameKey: string) => {
  return [
    "Cosewic/Ranges/NameEn",
    "Cosewic/Ranges/NameFr",
    "Listing/Ranges/NameEn",
    "Listing/Ranges/NameFr",
  ].includes(nameKey);
};

const isNameKeyFrench = (nameKey: string) => {
  return nameKey.endsWith("Fr");
};

type BilingualName = {
  id: string;
  nameEnglish: string;
  nameFrench: string;
};

interface QueryTaxonomicGroupResponse {
  taxonomicGroup: BilingualName[];
}

interface QueryPopulationResponse {
  population: BilingualName[];
}

interface QueryRangeResponse {
  range: BilingualName[];
}

type BilingualLookupName = {
  id: string;
  name: {
    french: string;
    english: string;
  };
};

interface QueryLookupResponse extends Array<BilingualLookupName> {}

interface QueryEnumResponse {
  values: string[];
}

export const CustomValueEditor = (props: ValueEditorProps) => {
  const { t, i18n } = useTranslation();

  const queryEnumList = useQuery<QueryEnumResponse | undefined>(
    ["enumList", props.fieldData.hRef],
    () => {
      if (props.fieldData.datatype !== "enum") return undefined;
      return getEnumList(props.fieldData.hRef);
    }
  );

  const queryLookupList = useQuery<QueryLookupResponse | undefined>(
    ["lookupList", props.fieldData.hRef],
    () => {
      if (props.fieldData.datatype !== "lookup") return undefined;
      return getLookupList(props.fieldData.hRef);
    }
  );

  const queryTaxonomicGroupList = useQuery<
    QueryTaxonomicGroupResponse | undefined
  >(["taxonomicGroupList", props.fieldData.hRef], () => {
    if (
      props.fieldData.datatype !== "classification" ||
      !isTaxonomicGroup(props.fieldData.name)
    )
      return undefined;
    return getClassificationList(props.fieldData.hRef);
  });

  const queryPopulationList = useQuery<QueryPopulationResponse | undefined>(
    ["populationList", props.fieldData.hRef],
    () => {
      if (
        props.fieldData.datatype !== "classification" ||
        !isPopulation(props.fieldData.name)
      )
        return undefined;
      return getClassificationList(props.fieldData.hRef);
    }
  );

  const queryRangeList = useQuery<QueryRangeResponse | undefined>(
    ["rangeList", props.fieldData.hRef],
    () => {
      if (
        props.fieldData.datatype !== "classification" ||
        !isRange(props.fieldData.name)
      )
        return undefined;
      return getClassificationList(props.fieldData.hRef);
    }
  );

  switch (props.fieldData.datatype) {
    case "enum":
      return (
        <select
          className={props.className}
          style={{ maxWidth: "400px" }} // TODO: may need to remove this temporary width style
          title={props.title}
          onChange={(e) => props.handleOnChange(e.target.value)}
          value={props.value}
        >
          <option key="" value="">
            - {t("select_an_option")} -
          </option>
          {queryEnumList.data?.values?.map((x) =>
            // Ticket 41839: Remove Deferred/Rejected/Withdrawn from Latest, Wildlife Species Status dropdown
            props.fieldData.name === "Cosewic/LatestAssessment/Status" &&
            x === "DEFERRED_REJECT_WITHDRAWN" ? null : (
              <option key={x} value={x}>
                {t(x)}
              </option>
            )
          )}
        </select>
      );

    case "lookup":
      return (
        <select
          className={props.className}
          style={{ maxWidth: "400px" }} // TODO: may need to remove this temporary width style
          title={props.title}
          onChange={(e) => props.handleOnChange(e.target.value)}
          value={props.value}
        >
          <option key="" value="">
            - {t("select_an_option")} -
          </option>
          {queryLookupList.data?.map((x) => {
            const currentLanguageName = isNameKeyFrench(props.fieldData.name)
              ? x.name.french
              : x.name.english;
            return (
              <option key={x.id} value={currentLanguageName}>
                {currentLanguageName}
              </option>
            );
          })}
        </select>
      );

    case "classification":
      return (
        <select
          className={props.className}
          style={{ maxWidth: "400px" }} // TODO: may need to remove this temporary width style
          title={props.title}
          onChange={(e) => props.handleOnChange(e.target.value)}
          value={props.value}
        >
          <option key="" value="">
            - {t("select_an_option")} -
          </option>
          {queryTaxonomicGroupList.data?.taxonomicGroup?.map((x) => {
            const currentLanguageName = isNameKeyFrench(props.fieldData.name)
              ? x.nameFrench
              : x.nameEnglish;
            return (
              <option key={x.id} value={currentLanguageName}>
                {currentLanguageName}
              </option>
            );
          })}
          {queryPopulationList.data?.population?.map((x) => {
            const currentLanguageName = isNameKeyFrench(props.fieldData.name)
              ? x.nameFrench
              : x.nameEnglish;
            return (
              <option key={x.id} value={currentLanguageName}>
                {currentLanguageName}
              </option>
            );
          })}
          {queryRangeList.data?.range?.map((x) => {
            const currentLanguageName = isNameKeyFrench(props.fieldData.name)
              ? x.nameFrench
              : x.nameEnglish;
            return (
              <option key={x.id} value={currentLanguageName}>
                {currentLanguageName}
              </option>
            );
          })}
        </select>
      );
  }

  if (props.level === 0) {
    return null;
  }

  return <ValueEditor {...props} />;
};
