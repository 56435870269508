import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import { Maybe, PhotoGallery, PhotoLicenseType } from "generated/gql-types";
import i18n from "i18n";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";

interface PhotoInformationCardProps {
  photoData?: Maybe<PhotoGallery>;
}

export const PhotoInformationCard: React.FC<PhotoInformationCardProps> = (
  props
) => {
  const { photoData } = props;
  const { t } = useTranslation();

  const getLicenseConditionMessage = (
    licenseType?: PhotoLicenseType | null
  ) => {
    switch (licenseType) {
      case PhotoLicenseType.FullUse:
        return t("licence_condition_full_use_message");
      case PhotoLicenseType.LimitedUse:
        return t("licence_condition_limited_use_message");
      case PhotoLicenseType.OneTimeUse:
        return t("licence_condition_one_time_use_message");
      case PhotoLicenseType.CrownCopyright:
        return t("licence_condition_crown_copyright_use_message");
      default:
        return "";
    }
  };

  return (
    <SectionCard header={<h2>{t("photo_information")}</h2>}>
      <div className="species-field">
        <div className="row">
          <dl>
            {/* ------------ Photo license document ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("photo_licence_document")}</dt>
              <dd>
                {photoData?.id == null ? (
                  <MissingData />
                ) : (
                  <Link
                    to={DocumentsPathHelpers.PhotoLicenseProfile(
                      photoData.id ?? "error"
                    )}
                    data-testid={"link-photo-license"}
                  >
                    {htmlIsNullOrEmpty(
                      i18n.language === "fr"
                          ? photoData?.title?.french?.text
                          : photoData?.title?.english?.text
                      ) ? (
                        <MissingData />
                      ) : (
                        <SafeRenderHtml
                          htmlString={
                            i18n.language === "fr"
                              ? photoData?.title?.french?.text
                              : photoData?.title?.english?.text
                          }
                        />
                      )}
                  </Link>
                )}
              </dd>
            </div>
            {/* ------------ License type ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("licence_type")}
                <InfoButtonAndModal title={t("licence_conditions")}>
                  <SafeRenderHtml
                    htmlString={getLicenseConditionMessage(
                      photoData?.licenseType
                    )}
                  />
                </InfoButtonAndModal>
              </dt>
              <dd>
                {isNullOrEmpty(photoData?.licenseType) ? (
                  <MissingData />
                ) : (
                  t(photoData?.licenseType ?? "")
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ---------- Photo description ---------- */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("photo_description")}</dt>
              <dd>
                {htmlIsNullOrEmpty(
                  i18n.language === "fr"
                    ? photoData?.photo?.description?.french?.text
                    : photoData?.photo?.description?.english?.text
                ) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={
                      i18n.language === "fr"
                        ? photoData?.photo?.description?.french?.text
                        : photoData?.photo?.description?.english?.text
                    }
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Photo usage history ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("photo_usage_history")}</dt>
              <dd>
                {htmlIsNullOrEmpty(photoData?.photo?.usageHistory?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={photoData?.photo?.usageHistory?.text}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default PhotoInformationCard;
