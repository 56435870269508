if (!process.env.REACT_APP_MSAL_CLIENT_ID) {
  console.error(
    "[AUTH] Environment variable REACT_APP_MSAL_CLIENT_ID is missing"
  );
}

if (!process.env.REACT_APP_MSAL_TENANT_ID) {
  console.error(
    "[AUTH] Environment variable REACT_APP_MSAL_TENANT_ID is missing"
  );
}

export const msalInstanceConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
    // navigateToLoginRequestUrl: true,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequestConfig = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/user.read`],
  prompt: "select_account",
};

export const loginSilentRequestConfig = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/user.read`],
};
