import { useQuery } from "@tanstack/react-query";
import BasicSearchCheckboxListDetails from "components/molecules/search/BasicSearchCheckboxListDetails";
import BasicSearchProgramsCheckboxListDetails from "components/molecules/search/BasicSearchProgramsCheckboxListDetails";
import { FilterKeywordSection } from "components/organisms/search/FilterKeywordSection/FilterKeywordSection";
import { DOCUMENT_INDEX } from "features/search/documents/constants";
import documentsFacets from "features/search/documents/documentsFacets.json";
import odataMappers from "features/search/documents/documentsOdataMappers";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import useBasicSearch from "pages/search/hooks/useBasicSearch";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import SectionCard from "../../components/atoms/SectionCard";
import PageSizeSelect from "../../components/molecules/PageSizeSelect";
import Pagination from "../../components/molecules/Pagination";
import FilterTags from "../../components/organisms/search/FilterTags/FilterTags";
import ResultsTable from "../../components/organisms/search/ResultsTable/ResultsTable";
import { useBasicSearchQuery } from "../../features/search/hooks/useBasicSearchQuery";
import { fetchFilters } from "../../features/search/utils/fetchFilters";
import { makeFilterString } from "../../features/search/utils/makeFilterString";
import { makePaging } from "../../features/search/utils/makePaging";
import { formatTimestamp } from "../../util/formatTimestamp";

interface DocumentsSearchPageColumns {
  id: string;
  "Title/English/Text"?: string;
  "Title/French/Text"?: string;
  ContentOwner: string;
  Programs: string;
  "DocumentType/NameEn"?: string;
  "DocumentType/NameFr"?: string;
  UploadedDate: string;
  State: string;
}

const searchFields =
  "Title/English/PlainText, Title/French/PlainText, DocumentType/NameEn, DocumentType/NameFr, AssociatedSpecies/CommonNameEnglish/Name, AssociatedSpecies/CommonNameFrench/Name, AssociatedSpecies/Population/NameEn, AssociatedSpecies/Population/NameFr, DocumentIdStr";
const queryType = "full";
const searchMode = "any";
const select =
  "Id, DocumentId, Title/English/Text, Title/French/Text, ContentOwner, Programs/NameEn, Programs/NameFr, DocumentType/Tags, DocumentType/NameEn, DocumentType/NameFr, UploadedDate, State";

const DocumentsSearchPage: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);

  const basicSearch = useBasicSearch("basicsearch_documents");

  const [showAllFilters, setShowAllFilters] = React.useState(true);

  const filtersQuery = useQuery(
    ["basicSearch", "filters"],
    () => {
      return fetchFilters(documentsFacets, DOCUMENT_INDEX);
    },
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      setSortDescriptor({
        column:
          i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text",
        direction: "ascending",
      });
    }

    return makeOrderByString(sortDescriptor);
  }, [i18n.language, sortDescriptor]);

  const { data, loading, error, newRunSearchQuery } =
    useBasicSearchQuery(DOCUMENT_INDEX);

  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      const filterString = makeFilterString(odataMappers, basicSearch.state);

      const paging = makePaging(pagination);

      return {
        filter: filterString,
        search: basicSearch.state.keywordSearchText,
        queryType,
        searchMode,
        searchFields,
        select,
        orderby: orderByString,
        count: true,
        ...paging,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      newRunSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  useEffect(
    function autoResetPageNumber() {
      const newPageNumber = 1;
      pagination.goToPage(newPageNumber);
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      i18n.language,
    ]
  );

  const onSearchClick = async () => {
    await newRunSearchQuery(azureSearchArguments);
  };

  const onResetSearch = async () => {
    basicSearch.dispatch({ type: "reset_keyword_search" });
    pagination.goToPage(1);
  };

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const columns: Array<{
    name: string;
    key: keyof DocumentsSearchPageColumns;
    sortable: boolean;
    isHTML?: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("document_name"),
        key:
          i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text",
        sortable: true,
        hasLink: true,
        isHTML: true,
      },
      {
        name: t("content_owner"),
        key: "ContentOwner",
        sortable: true,
      },
      {
        name: t("program"),
        key: "Programs",
        sortable: false,
      },
      {
        name: t("document_type"),
        key:
          i18n.language === "fr"
            ? "DocumentType/NameFr"
            : "DocumentType/NameEn",
        sortable: true,
      },
      {
        name: t("state"),
        key: "State",
        sortable: true,
      },
      {
        name: t("date_created"),
        key: "UploadedDate",
        sortable: true,
      },
    ],
    [i18n.language]
  );

  //
  // Map search response to columns

  const rows: Array<DocumentsSearchPageColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let documentName = "";
        let documentType = "";
        if (i18n.language === "fr") {
          documentName = value?.Title?.French?.Text;
          documentType = value?.DocumentType?.NameFr;
        } else {
          documentName = value?.Title?.English?.Text;
          documentType = value?.DocumentType?.NameEn;
        }
        return {
          _href: `/documents/${value?.Id}`,
          id: value?.DocumentId,
          [i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text"]:
            documentName,
          ContentOwner: t(value?.ContentOwner),
          Programs: value?.Programs?.map((x: any) =>
            i18n.language === "fr" ? (x.NameFr as string) : (x.NameEn as string)
          ).join(", "),
          [i18n.language === "fr"
            ? "DocumentType/NameFr"
            : "DocumentType/NameEn"]: documentType,
          State: t(value?.State),
          UploadedDate: formatTimestamp(value?.UploadedDate),
        };
      }),
    [data?.value]
  );

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <SectionCard
            header={<h2 className="mrgn-tp-0">{t("search_filters")}</h2>}
            id="search-filters"
            className="pb-0"
            showLine={false}
          >
            <div className="pt-0 separator-line-top"></div>
            <button
              type="button"
              className="btn btn-link px-2 py-2"
              onClick={() => setShowAllFilters(!showAllFilters)}
              data-testid="button-show-all-documents"
            >
              {!showAllFilters ? t("show_all") : t("hide_all")}
            </button>
            <BasicSearchCheckboxListDetails
              allFacets={allFacets}
              basicSearch={basicSearch}
              facetName={"Stage"}
              header={t("stage")}
              open={showAllFilters}
              translateItems
            />
            <BasicSearchCheckboxListDetails
              allFacets={allFacets}
              basicSearch={basicSearch}
              facetName={"State"}
              header={t("state")}
              open={showAllFilters}
              translateItems
            />
            <BasicSearchCheckboxListDetails
              allFacets={allFacets}
              basicSearch={basicSearch}
              facetName={"ContentOwner"}
              header={t("content_owner")}
              open={showAllFilters}
              translateItems
            />
            <BasicSearchProgramsCheckboxListDetails
              allFacets={allFacets}
              basicSearch={basicSearch}
              facetName={
                i18n.language === "fr" ? "Programs/NameFr" : "Programs/NameEn"
              }
              header={t("programs")}
              open={showAllFilters}
              translateItems
            />
            <BasicSearchCheckboxListDetails
              allFacets={allFacets}
              basicSearch={basicSearch}
              facetName={"DocumentType/Tags"}
              header={t("document_type")}
              open={showAllFilters}
              translateItems
            />
            <BasicSearchCheckboxListDetails
              allFacets={allFacets}
              basicSearch={basicSearch}
              facetName={"PublicationType"}
              header={t("publication_type")}
              open={showAllFilters}
              translateItems
            />
          </SectionCard>
        </div>

        <div className="col-md-9">
          <FilterKeywordSection
            defaultValue={basicSearch.state.keywordSearchText}
            onChange={(text) =>
              basicSearch.dispatch({ type: "update_keyword_search", text })
            }
            onReset={onResetSearch}
            onSearch={onSearchClick}
          />

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("search_results")}</h2>
                <div className="flex gap-md align-start"></div>
              </div>
            }
          >
            <>
              <FilterTags basicSearch={basicSearch} onReset={onResetTags} />

              {/*RESULTS:*/}
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : rows && rows?.length === 0 ? (
                <NoResults centered />
              ) : (
                <>
                  <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                    <div className="font-size-18">
                      {pagination.makeShowingString(
                        data ? data["@odata.count"] : 0
                      )}
                    </div>
                    <div>
                      <PageSizeSelect
                        pageSize={pagination.pageSize}
                        onChangePageSize={onChangePageSize}
                      />
                    </div>
                  </div>
                  {!loading && error ? (
                    <pre>{JSON.stringify(error)}</pre>
                  ) : null}
                  {rows == null || rows.length === 0 ? null : (
                    <ResultsTable
                      rows={rows}
                      columns={columns}
                      sortable
                      sortDescriptor={sortDescriptor}
                      onSortChange={onSortChange as any}
                    />
                  )}

                  <Pagination
                    {...pagination.paginationComponentProps}
                    onPageSelected={onPageSelected}
                    totalPages={
                      data && data["@odata.count"] && pagination.pageSize
                        ? Math.ceil(data["@odata.count"] / pagination.pageSize)
                        : 0
                    }
                  />
                </>
              )}
            </>
          </SectionCard>
        </div>
      </div>
    </>
  );
};

export default DocumentsSearchPage;
