import { ApolloQueryResult } from "@apollo/client";
import MissingData from "components/atoms/MissingData";
import RefreshButtonLink from "components/atoms/RefreshButton";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import {
  ChangeLog,
  ChangeLogDetail,
  CosewicProfileQuery,
  ListingProfileQuery,
} from "../../../generated/gql-types";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";
import SectionCard from "../../atoms/SectionCard";
import { maybeParseRichText } from "./changelogUtil";

export interface WSNameChangesCardProps {
  changeLogList?: Array<ChangeLog>;
  sectionTitle: string;
  correlationId: string; // CosewicWsId or ListingWsId
  loading?: boolean;
  changeLogDetailsLinkCallback: (
    correlationId: string,
    changeLogId: string
  ) => string;
  refetchProfile?: () =>
    | Promise<ApolloQueryResult<CosewicProfileQuery>>
    | Promise<ApolloQueryResult<ListingProfileQuery>>;
}

export const WSNameChangesCard: React.FC<WSNameChangesCardProps> = (props) => {
  const {
    changeLogList,
    sectionTitle,
    correlationId,
    loading,
    changeLogDetailsLinkCallback,
    refetchProfile,
  } = props;
  const { t } = useTranslation();

  let currentCommonNameEnglish: string = "";
  let currentCommonNameFrench: string = "";
  let currentPopulationEnglish: string | null = null;
  let currentPopulationFrench: string | null = null;
  let currentScientificName: string = "";

  const foundCommonNameEnglish = (changeLog: ChangeLog): boolean => {
    const changeLogDetails = changeLog.details as ChangeLogDetail[]; // Only for type conversion
    for (let detail of changeLogDetails) {
      if (detail.pathProperty === "/commonNameEnglish") {
        currentCommonNameEnglish = detail.currentValue!;
        return true;
      }
    }
    return false;
  };

  const foundCommonNameFrench = (changeLog: ChangeLog): boolean => {
    const changeLogDetails = changeLog.details as ChangeLogDetail[]; // Only for type conversion
    for (let detail of changeLogDetails) {
      if (detail.pathProperty === "/commonNameFrench") {
        currentCommonNameFrench = detail.currentValue!;
        return true;
      }
    }
    return false;
  };

  const foundPopulation = (changeLog: ChangeLog): boolean => {
    const changeLogDetails = changeLog.details as ChangeLogDetail[]; // Only for type conversion
    for (let detail of changeLogDetails) {
      if (detail.pathProperty === "/population") {
        try {
          const populationObj =
            detail.currentValue !== "undefined" && detail.currentValue
              ? JSON.parse(detail.currentValue!)
              : "";

          currentPopulationEnglish = populationObj.NameEn;
          currentPopulationFrench = populationObj.NameFr;
        } catch {
          currentPopulationEnglish = "Error";
          currentPopulationFrench = "Error";
        }
        return true;
      }
    }
    return false;
  };

  const foundScientificName = (changeLog: ChangeLog): boolean => {
    const changeLogDetails = changeLog.details as ChangeLogDetail[]; // Only for type conversion
    for (let detail of changeLogDetails) {
      if (detail.pathProperty === "/scientificName/generatedScientificName") {
        currentScientificName = detail.currentValue!;
        return true;
      }
    }
    return false;
  };

  return (
    <SectionCard
      collapsible
      open={false}
      header={
        <div className="flexbug-9-workaround">
          <div className="flex justify-between">
            <h2 className="mrgn-tp-0 mrgn-bttm-0">{sectionTitle}</h2>
            {refetchProfile && !loading ? (
              <RefreshButtonLink onClick={refetchProfile} />
            ) : null}
          </div>
        </div>
      }
      classNameSummary={"mrgn-bttm-0 py-2_5"}
      showLine={!changeLogList || changeLogList.length === 0}
    >
      {!changeLogList || changeLogList.length === 0 ? (
        <div className="mrgn-tp-md">
          <MissingData />
        </div>
      ) : null}

      {changeLogList?.map((changeLog: ChangeLog) => {
        return (
          <div
            className="row list-item separator-line species-field px-0"
            key={changeLog?.id}
          >
            <dl>
              <div className="col-sm-2 species-data text-break">
                <dt className="text-muted">{t("english_name")}</dt>
                <dd>
                  {foundCommonNameEnglish(changeLog) ? (
                    <SafeRenderHtml
                      htmlString={maybeParseRichText(currentCommonNameEnglish)}
                    />
                  ) : (
                    <MissingData message={t("no_change")} />
                  )}
                </dd>
              </div>
              <div className="col-sm-2 species-data">
                <dt className="text-muted">{t("english_population")}</dt>
                <dd>
                  {foundPopulation(changeLog) &&
                  currentPopulationEnglish != null ? (
                    currentPopulationEnglish
                  ) : (
                    <MissingData message={t("no_change")} />
                  )}
                </dd>
              </div>
              <div className="col-sm-2 species-data text-break">
                <dt className="text-muted">{t("french_name")}</dt>
                <dd>
                  {foundCommonNameFrench(changeLog) ? (
                    <SafeRenderHtml
                      htmlString={maybeParseRichText(currentCommonNameFrench)}
                    />
                  ) : (
                    <MissingData message={t("no_change")} />
                  )}
                </dd>
              </div>
              <div className="col-sm-2 species-data">
                <dt className="text-muted">{t("french_population")}</dt>
                <dd>
                  {foundPopulation(changeLog) &&
                  currentPopulationFrench != null ? (
                    currentPopulationFrench
                  ) : (
                    <MissingData message={t("no_change")} />
                  )}
                </dd>
              </div>
              <div className="col-sm-2 species-data text-break">
                <dt className="text-muted">{t("scientific_name")}</dt>
                <dd>
                  {foundScientificName(changeLog) ? (
                    <SafeRenderHtml
                      htmlString={maybeParseRichText(currentScientificName)}
                    />
                  ) : (
                    <MissingData message={t("no_change")} />
                  )}
                </dd>
              </div>
              <div className="col-sm-2 species-data">
                <dt className="text-muted">{t("date_changed")}</dt>
                <dd>
                  <Link
                    to={changeLogDetailsLinkCallback(
                      correlationId,
                      changeLog.id!
                    )}
                  >
                    {formatTimestamp(changeLog.modifiedAt) ?? <MissingData />}
                  </Link>
                </dd>
              </div>
            </dl>
          </div>
        );
      })}
    </SectionCard>
  );
};

export default WSNameChangesCard;
