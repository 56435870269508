import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";
import { PhotoLicenseDocumentFormFields } from "./PhotoLicenseDocumentForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<PhotoLicenseDocumentFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<PhotoLicenseDocumentFormFields>,
  initialValues: Partial<PhotoLicenseDocumentFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  if (
    dirtyFields?.title?.english &&
    htmlIsNullOrEmpty(formData?.title?.english?.text) &&
    !htmlIsNullOrEmpty(initialValues?.title?.english?.text)
  ) {
    set(formData, "title.english", null);
  }
  if (
    dirtyFields?.title?.french &&
    htmlIsNullOrEmpty(formData?.title?.french?.text) &&
    !htmlIsNullOrEmpty(initialValues?.title?.french?.text)
  ) {
    set(formData, "title.french", null);
  }
  if (
    dirtyFields?.licenseType &&
    isNullOrEmpty(formData?.licenseType) &&
    !isNullOrEmpty(initialValues?.licenseType)
  ) {
    set(formData, "licenseType", null);
  }

  // if (
  //   dirtyFields?.contactRef &&
  //   isNullOrEmpty(formData?.contactRef) &&
  //   !isNullOrEmpty(initialValues?.contactRef)
  // ) {
  //   set(formData, "contactRef", null);
  // }
  // if (
  //   dirtyFields?.organizationRef &&
  //   isNullOrEmpty(formData?.organizationRef) &&
  //   !isNullOrEmpty(initialValues?.organizationRef)
  // ) {
  //   set(formData, "organizationRef", null);
  // }

  if (
    dirtyFields?.note?.text &&
    htmlIsNullOrEmpty(formData?.note?.text) &&
    !htmlIsNullOrEmpty(initialValues?.note?.text)
  ) {
    set(formData, "note", null);
  }
};
