import { TFunction } from "i18next";
import { RuleGroupType, RuleType } from "react-querybuilder";
import isNullOrEmpty from "util/isNullOrEmpty";
import { convertCosewicKeyToTagWithTranslation } from "util/queryTool/convertCosewicKeyToTag";
import { convertListingKeyToTagWithTranslation } from "util/queryTool/convertListingKeyToTag";
import {
  APIColumn,
  APIRuleColumn,
  APIRuleGroup,
  QueryToolAPIDomainModel,
} from "../types";
import { QueryToolFormFields } from "./QueryToolForm";
import { SetFixedProperty } from "./SelectColumnsSection/SelectColumnsSection";

// Global scope
let _t: TFunction;
let _getFieldTypeByKey: Function;

const buildRuleColumnObj = (
  seqNumber: number,
  combinator: string,
  rule: RuleType
) => {
  const ruleColumn: APIRuleColumn = {
    ruleType: combinator,
    column: buildColumnObj(seqNumber, rule.field, false),
    operatorType: rule.operator,
    searchValue: rule.value,
  };

  // Convert date time to ISO-8601 format.
  if (
    ruleColumn.column.type?.toLowerCase() === "datetimeoffset" &&
    !isNullOrEmpty(ruleColumn.searchValue)
  ) {
    ruleColumn.searchValue = new Date(ruleColumn.searchValue).toISOString();
  }

  // console.log("ruleColumn =", ruleColumn);
  return ruleColumn;
};

const buildGroups = (seqNumber: number, ruleGroup: RuleGroupType) => {
  let groups: APIRuleGroup = {
    ruleType: "",
    sequenceNumber: 0,
    rules: [],
    groups: [],
  };

  const combinator = ruleGroup.combinator.toUpperCase();
  groups.ruleType = combinator;
  groups.sequenceNumber = seqNumber;
  ruleGroup.rules.forEach((x, index) => {
    const hasCombinator = x.hasOwnProperty("combinator"); // only Group has combinator.
    if (hasCombinator) {
      const ruleGroup = x as RuleGroupType; // type Cast to RuleGroupType
      // console.log("x(RuleGroup) =", x);
      groups.groups.push(buildGroups(index + 1, ruleGroup));
    } else {
      const rule = x as RuleType; // type Cast to RuleType
      // console.log("x(Rule) =", x);
      groups.rules.push(buildRuleColumnObj(index + 1, combinator, rule));
    }
  });

  return groups;
};

const buildColumnObj = (seqNumber: number, nameKey: string, fixed: boolean) => {
  const program = nameKey.split("/", 1)[0];
  let column: any = {};

  column.sequenceNumber = seqNumber;
  column.programName = program;
  column.key = nameKey;
  column.name =
    program === "Cosewic"
      ? convertCosewicKeyToTagWithTranslation(nameKey)
      : convertListingKeyToTagWithTranslation(nameKey);
  column.type = _getFieldTypeByKey(nameKey);
  column.fixed = fixed;

  return column;
};

const buildColumns = (
  setFixedProperty?: string,
  cosewicItems?: string[], // The Select columns for output - Cosewic Checkboxes
  listingItems?: string[] // The Select columns for output - Listing Checkboxes
) => {
  let columns: APIColumn[] = [];
  let nextNumber: number = 1;

  switch (setFixedProperty) {
    case SetFixedProperty.CosewicCommonNameWithPopulationEnglish:
      columns.push(
        buildColumnObj(nextNumber++, "Cosewic/CommonNameEnglish/Name", true),
        buildColumnObj(nextNumber++, "Cosewic/Population/NameEn", true)
      );
      break;
    case SetFixedProperty.CosewicCommonNameWithPopulationFrench:
      columns.push(
        buildColumnObj(nextNumber++, "Cosewic/CommonNameFrench/Name", true),
        buildColumnObj(nextNumber++, "Cosewic/Population/NameFr", true)
      );
      break;
    case SetFixedProperty.CosewicId:
      columns.push(buildColumnObj(nextNumber++, "Cosewic/CosewicIdStr", true));
      break;
    case SetFixedProperty.ListingCommonNameWithPopulationEnglish:
      columns.push(
        buildColumnObj(nextNumber++, "Listing/CommonNameEnglish/Name", true),
        buildColumnObj(nextNumber++, "Listing/Population/NameEn", true)
      );
      break;
    case SetFixedProperty.ListingCommonNameWithPopulationFrench:
      columns.push(
        buildColumnObj(nextNumber++, "Listing/CommonNameFrench/Name", true),
        buildColumnObj(nextNumber++, "Listing/Population/NameFr", true)
      );
      break;
    case SetFixedProperty.ListingId:
      columns.push(buildColumnObj(nextNumber++, "Listing/ListingIdStr", true));
      break;
  }

  cosewicItems?.forEach((x) => {
    columns.push(buildColumnObj(nextNumber++, x, false));
  });

  listingItems?.forEach((x) => {
    columns.push(buildColumnObj(nextNumber++, x, false));
  });

  return columns;
};

export const mapAndFormatQuery = (
  ruleGroup: RuleGroupType,
  getFieldTypeByKey: Function,
  t: TFunction
) => {
  _getFieldTypeByKey = getFieldTypeByKey;
  _t = t;
  const obj = buildGroups(1, ruleGroup);
  const json = JSON.stringify(obj, null, "  ");
  return json;
};

export function mapFormToAPIDomainModel(
  form: Partial<QueryToolFormFields>,
  getFieldTypeByKey: Function,
  t: TFunction
): Partial<QueryToolAPIDomainModel> {
  _getFieldTypeByKey = getFieldTypeByKey;
  _t = t;
  const out: Partial<QueryToolAPIDomainModel> = {};

  out.name = {
    english: form.nameInEnglish ?? "",
    french: form.nameInFrench ?? "",
  };
  out.sharingSetting = form.sharingSettings;
  if (form.sharingWith) out.sharingWith = form.sharingWith;

  out.groups = [];
  out.groups.push(buildGroups(1, form.conditionRules));

  out.columns = buildColumns(
    form.setFixedProperty,
    form.cosewicItems,
    form.listingItems
  );

  return out;
}

export function mapAPIDomainModelToForm(
  APIDomainModel: Partial<QueryToolAPIDomainModel>
): Partial<QueryToolFormFields> {
  const out: Partial<QueryToolFormFields> = {};

  //   if (domainModel?.landRef)
  //     out.landRef = {
  //       id: domainModel?.landRef?.id ?? "",
  //       landManager: makeBilingualText(domainModel?.landRef?.landManager),
  //       landName: domainModel?.landRef?.landName ?? "",
  //       landSize: domainModel?.landRef?.landSize ?? 0,
  //       landType: domainModel?.landRef?.landType ?? LandType.Federal, // TODO: null?
  //       dfrp: domainModel?.landRef?.dfrp ?? 0,
  //       provinceAndTerritory:
  //         domainModel?.landRef?.provinceAndTerritory ??
  //         ProvinceAndTerritory.Alberta, // TODO: null?
  //     };

  //   if (domainModel?.wildlifeSpeciesPresence)
  //     out.wildlifeSpeciesPresence = domainModel?.wildlifeSpeciesPresence;
  //   if (domainModel?.numberOfSites)
  //     out.numberOfSites = domainModel?.numberOfSites;

  //   if (domainModel?.approximateNumberOfIndividuals)
  //     out.approximateNumberOfIndividuals = domainModel?.approximateNumberOfIndividuals;

  //   if (domainModel?.circumstance) out.circumstance = domainModel?.circumstance;
  //   if (domainModel?.source) out.source = domainModel?.source;

  return out;
}
