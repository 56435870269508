import ReactDOM from "react-dom";
import { useLanguage } from "@arcnovus/wet-boew-react";
import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { OverlayProvider } from "@react-aria/overlays";
import CustomAppTemplate from "features/wet/CustomAppTemplate";
import CustomWetProvider from "features/wet/CustomWetProvider";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { CustomApolloProvider } from "./features/apollo";
import { CustomMsalProvider } from "./features/auth/CustomMsalProvider";
import { ChangeTrackingProvider } from "./features/changeTracking";
import { GlobalAlertProvider } from "./features/globalAlert";
import { OverviewProvider } from "./features/overview";
import i18n from "./i18n";
import Router from "./Router";
import "./wdyr";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const httpQueryClient = new QueryClient();

function App() {
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    const newLang = currentLanguage ?? "en";
    console.log("Changing i18n language to:", newLang);

    i18n.changeLanguage(newLang).catch((error) => {
      console.error("Error changing the react-i18n language.", "error:", error);
    });
  }, [currentLanguage]);

  return (
    <BrowserRouter basename={currentLanguage ?? ""}>
      <CustomMsalProvider>
        <CustomWetProvider currentLanguage={currentLanguage}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <AuthenticatedTemplate>
              <QueryClientProvider client={httpQueryClient}>
                <CustomApolloProvider>
                  <OverlayProvider>
                    <GlobalAlertProvider>
                      <OverviewProvider>
                        <ChangeTrackingProvider isEnabled={false}>
                          <CustomAppTemplate>
                            <Router />
                          </CustomAppTemplate>
                        </ChangeTrackingProvider>
                      </OverviewProvider>
                    </GlobalAlertProvider>
                  </OverlayProvider>
                </CustomApolloProvider>
              </QueryClientProvider>
            </AuthenticatedTemplate>
          </MsalAuthenticationTemplate>
        </CustomWetProvider>
      </CustomMsalProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("sardmtsroot"));
