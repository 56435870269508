const listingChangeLogTranslation = {
  en: {
    // Page titles
    listing_change_log: "Listing change log",
    listing_change_details: "Listing change details",

    // Listing name changes
    listing_name_changes: "Listing name changes",

    // Change Log Description
    listingws_update: "Listing wildlife species profile updated by",
    listingws_date_of_addition_on_schedule1_updated:
      "Date of addition on Schedule 1 updated by",
    listingprocess_manual_listing_process_added:
      "Manual Listing process added by",
    listingws_parent_added: "Parent wildlife species added by",
    listingws_parent_removed: "Parent wildlife species removed by",
    responsestatement_rs_created: "Response statement created by",
    RS_CREATED: "Response statement created by",
    responsestatement_rs_updated: "Response statement updated by",
    RS_UPDATED: "Response statement updated by",
  },

  fr: {
    // Page titles
    listing_change_log: "Journal des modifications de l'inscription",
    listing_change_details: "Détails de la modification de l'inscription",

    // Listing name changes
    listing_name_changes: "Changement de nom lors de l'inscription",

    // Change Log Description
    listingws_update:
      "Mise à jour de l'inscription du profil de l'espèce sauvage",
    listingws_date_of_addition_on_schedule1_updated:
      "Date de l'ajout à l'annexe 1 mise à jour par",
    listingprocess_manual_listing_process_added:
      "Processus d'inscription manuel ajouté par",
    listingws_parent_added: "Espèces sauvages parent ajoutées par",
    listingws_parent_removed: "Espèces sauvages parent enlevées par",
    responsestatement_rs_created: "Énoncé de réaction cré par",
    RS_CREATED: "Énoncé de réaction cré par",
    responsestatement_rs_updated: "Relevé de réponses mis à jour par",
    RS_UPDATED: "Relevé de réponses mis à jour par",
  },
};

export default listingChangeLogTranslation;
