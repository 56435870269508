import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { useGlobalAlertContext } from "features/globalAlert";
import { BptTemplate, BptTemplateStage, Maybe } from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import i18n from "i18n";
import * as React from "react";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "util/isNullOrEmpty";
import EditBptTemplateModalWithButton from "../EditBptTemplateModal/EditBptTemplateButtonWithModal";

export interface TemplateInformationCardProps {
  bptTemplate?: Maybe<BptTemplate>;
  calculatedDuration?: Maybe<number>;
}

export const TemplateInformationCard: React.FC<TemplateInformationCardProps> = (
  props
) => {
  const { t } = useTranslation();
  const globalAlertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("bpt_template"));

  const program =
    i18n.language === "fr"
      ? props.bptTemplate?.program?.name?.french
      : props.bptTemplate?.program?.name?.english;

  const objectType =
    i18n.language === "fr"
      ? props.bptTemplate?.objectType?.name?.french
      : props.bptTemplate?.objectType?.name?.english;

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2>{t("template_information")}</h2>
            {props.bptTemplate?.stage === BptTemplateStage.Draft ? (
              <EditBptTemplateModalWithButton
                refetchQueries={["BptTemplate"]}
                defaultValues={props.bptTemplate}
                disabled={props.bptTemplate?.id == null}
                onCompleted={(data: any) => {
                  globalAlertContext.showSuccess({
                    message: data?.createBptTemplate?.name?.text,
                    timeOut: 5000,
                  });
                }}
                onError={adminAlertMsg.onCreateError}
              />
            ) : null}
          </div>
        }
      >
        <div className="font-size-18">
          <div className="species-field">
            {/* ------------ Description ------------ */}
            <div className="row">
              <dl>
                <div className="species-data col-sm-12">
                  <dt className="text-muted">{t("description")}</dt>
                  <dd>
                    {props.bptTemplate?.description == null ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml
                        htmlString={props.bptTemplate?.description.text ?? ""}
                      />
                    )}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="row">
              <dl>
                {/* ------------ Program ------------ */}
                <div className="species-data col-sm-4">
                  <dt className="text-muted">{t("program")}</dt>
                  <dd>{isNullOrEmpty(program) ? <MissingData /> : program}</dd>
                </div>

                {/* ------------ Visibility ------------ */}
                <div className="species-data col-sm-4">
                  <dt className="text-muted">{t("visibility")}</dt>
                  <dd>
                    {props.bptTemplate?.visibility == null ? (
                      <MissingData />
                    ) : (
                      t(props.bptTemplate?.visibility)
                    )}
                  </dd>
                </div>

                {/* ------------ Object type ------------ */}
                <div className="species-data col-sm-4">
                  <dt className="text-muted">{t("object_type")}</dt>
                  <dd>
                    {isNullOrEmpty(objectType) ? <MissingData /> : objectType}
                  </dd>
                </div>
              </dl>
            </div>

            <div className="row">
              <dl>
                {/* ------------ Calculated duration ------------ */}
                <div className="species-data col-sm-4">
                  <dt className="text-muted">
                    {t("calculated_duration")}
                    {props?.calculatedDuration ?? (
                      <TooltipAlert isVisible={true}>
                        {t("can_not_be_calculated_tooltip")}
                      </TooltipAlert>
                    )}
                  </dt>
                  <dd>
                    {props?.calculatedDuration == null ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml
                        htmlString={
                          props?.calculatedDuration + " " + t("business_days")
                        }
                      />
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </SectionCard>
    </>
  );
};

export default TemplateInformationCard;
