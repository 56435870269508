import * as React from "react";

export interface LabelledDataProps
  extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

export const LabelledData: React.FC<LabelledDataProps> = ({
  label,
  children,
  ...props
}) => {
  return (
    <div {...props}>
      <h5 style={{ color: "#666666", fontWeight: "normal", fontSize: "14px" }}>
        {label}
      </h5>
      {children}
    </div>
  );
};
