import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useCheckbox from "../../../hooks/util/useCheckbox";
import Alert, { AlertTypes } from "../../atoms/Alert";
import ConfirmationModal, { useConfirmationModal } from "../ConfirmationModal";

export const MakePublishedButtonAndModal: React.FC<{
  canMakePublished: boolean;
  onMakePublished: () => Promise<void>;
  warningMessage?: string;
  buttonText?: string;
  ConfirmationMessage?: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmMakePublishedModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const onMakePublishedWithSpinner = () => {
    setShowButtonSpinner(true);

    props.onMakePublished().finally(() => {
      setShowButtonSpinner(false);
    });
  };

  return (
    <>
      <button
        className="btn btn-primary btn-sm mrgn-rght-sm"
        disabled={!props.canMakePublished}
        onClick={confirmMakePublishedModal.open}
        data-testid="button-publish"
      >
        {showButtonSpinner ? (
          <>
            <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
            <span className="wb-inv">&nbsp;{t("loading")}...</span>
          </>
        ) : null}
        {props.buttonText ? t(props.buttonText) : t("publish_to_registry")}
      </button>

      <ConfirmationModal
        modalState={confirmMakePublishedModal}
        title={t("publish_to_registry")}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={onMakePublishedWithSpinner}
        onCancel={() => {
          confirmMakePublishedModal.close();
          checkboxState.reset();
        }}
      >
        <Alert
          type={AlertTypes.WARNING}
          content={props.warningMessage ?? t("publish_ws_to_registry_warning")}
        />

        <label>
          <input
            type="checkbox"
            checked={checkboxState.checked}
            onChange={checkboxState.toggle}
            data-testid="modal-checkbox-confirm-publish"
          />{" "}
          {props.ConfirmationMessage
            ? t(props.ConfirmationMessage)
            : t("publish_to_registry_confirm")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default MakePublishedButtonAndModal;
