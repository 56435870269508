import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { BptTemplatePhase } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

export interface PhasesReorderProps {
  value: Array<BptTemplatePhase>;
  onChange: (phases: Array<BptTemplatePhase>) => void;
}

export const PhasesReorder: React.FC<PhasesReorderProps> = (props) => {
  const { value = [], onChange } = props;
  const { t } = useTranslation();

  function onMoveUp(index: number) {
    const list = [...value];

    if (index <= 0) return;
    const itemAbove = list[index - 1];
    list[index - 1] = list[index];
    list[index] = itemAbove;
    onChange(list);
  }

  function onMoveDown(index: number) {
    const list = [...value];

    if (index >= list.length - 1) return;
    const itemBelow = list[index + 1];
    list[index + 1] = list[index];
    list[index] = itemBelow;
    onChange(list);
  }

  return (
    <>
      <ul className="multi-select-list mrgn-bttm-md">
        {value.map((item, index) => (
          <li key={index} className="multi-select-list-item list-item">
            <div className="flex justify-between align-center">
              <div className="font-size-18">
                {t("phase")} {index + 1}
                {": "}
                <SafeRenderHtml
                  htmlString={htmlRemoveOuterPTag(
                    item?.name ? item.name?.text : ""
                  )}
                />
              </div>

              <div className="flex justify-right gap-sm flex-wrap">
                <button
                  type="button"
                  className="btn btn-link font-size-14 hover-grey"
                  onClick={() => onMoveUp(index)}
                  title={t("move_up")}
                  data-testid={`button-move-up-${index}`}
                  disabled={index === 0}
                >
                  <i className="fas fa-arrow-up"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-link font-size-14 hover-grey"
                  onClick={() => onMoveDown(index)}
                  title={t("move_down")}
                  data-testid={`button-move-down-${index}`}
                  disabled={index === value.length - 1}
                >
                  <i className="fas fa-arrow-down"></i>
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {/* <details className="mrgn-tp-md mrgn-bttm-md">
        <summary style={{ backgroundColor: "#fcf8e3" }}>
          Show data structure for debugging purposes
        </summary>
        <pre>
          <h5 className="mrgn-tp-sm">Steps and Bundles</h5>
          <div>{JSON.stringify(value, null, 2)}</div>
        </pre>
      </details> */}
    </>
  );
};
