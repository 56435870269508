import cc from "classcat";
import * as React from "react";

const LayoutRoot: React.FC<any> = (props) => {
  const { children, className } = props;
  return (
    <div {...props} className={cc(["container", className])}>
      <div className="row">{children}</div>
    </div>
  );
};

const LayoutSidebar: React.FC<any> = (props) => {
  const { children, className } = props;
  const [ribbonFound, setRibbonFound] = React.useState(true);

  const ribbonHeight = document.querySelector("#ribbon")?.clientHeight ?? 0;

  React.useEffect(() => {
    setRibbonFound(ribbonHeight > 0);
  }, [ribbonHeight]);

  return (
    <nav
      typeof="SiteNavigationElement"
      id="wb-sec"
      role="navigation"
      {...props}
      className={cc(["wb-sec col-md-3 col-md-pull-9", className])}
      style={
        !ribbonFound ? { top: 0 } : null
      } /* If no ribbon, side nav sticky top = 0 */
    >
      {children}
    </nav>
  );
};

const LayoutContent: React.FC<any> = (props) => {
  const { children, className } = props;
  return (
    <main
      role="main"
      property="mainContentOfPage"
      {...props}
      className={cc(["col-md-9 col-md-push-3", className])}
    >
      {children}
    </main>
  );
};

const Layout = {
  Root: LayoutRoot,
  Sidebar: LayoutSidebar,
  Content: LayoutContent,
};

export default Layout;
