import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteForProvincialTerritorialLegislationsDocument,
  BilingualLookupText,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import LookupQueryAutocomplete from "components/organisms/LookupQueryAutocomplete/LookupQueryAutocomplete";

export interface ProvincialTerritorialLegislationsAutocompleteProps {
  initialValue?: BilingualLookupText;
  onChange: (selectedItem?: BilingualLookupText) => void;
}

export const ProvincialTerritorialLegislationsAutocomplete: React.FC<
  ProvincialTerritorialLegislationsAutocompleteProps
> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <LookupQueryAutocomplete
      id={"provincialTerritorialLegislationsAutocomplete"}
      label={t("legislation")}
      queryDocument={AutocompleteForProvincialTerritorialLegislationsDocument}
      listDisplayFormatter={(item) =>
        bilingualLookupTextNameForLanguage(i18n.language, item)
      }
      onSelectionChange={props.onChange}
      placeholder={
        props.initialValue
          ? bilingualLookupTextNameForLanguage(
              i18n.language,
              props.initialValue
            )
          : t("select_one_or_many_items")
      }
    />
  );
};

export default ProvincialTerritorialLegislationsAutocomplete;
