const adminSubspeciesTranslation = {
  en: {
    subspecies: "Subspecies",
    list_of_subspecies: "List of subspecies",
    select_a_subspecies: "Select a subspecies",
    create_a_subspecies: "Create a Subspecies",
    enter_subspecies_name: "Enter a subspecies name",
    edit_subspecies: "Edit Subspecies",
    reactivate_a_subspecies: "Reactivate a Subspecies",
    deactivate_a_subspecies: "Deactivate a Subspecies",
    archive_subspecies_warning:
      "You will no longer be able to add this subspecies to a Wildlife Species.",
    unarchive_subspecies_warning:
      "You will be able to add this subspecies to a Wildlife Species.",
    no_subspecies_selected: "No subspecies was selected",
    archive_a_subspecies: "Archive a subspecies",
    unarchive_a_subspecies: "Unarchive a subspecies",
  },
  fr: {
    subspecies: "Sous-espèce",
    list_of_subspecies: "Liste des sous-espèces",
    select_a_subspecies: "Sélectionnez un sous-espèce",
    create_a_subspecies: "Créer une sous-espèce",
    enter_subspecies_name: "Entrez le nom d'une sous-espèce",
    edit_subspecies: "Editer les sous-espèces",
    reactivate_a_subspecies: "Réactiver une sous-espèce",
    deactivate_a_subspecies: "Désactiver une sous-espèce",
    archive_subspecies_warning:
      "Vous ne pourrez plus ajouter cette sous-espèce à une espèce sauvage.",
    unarchive_subspecies_warning:
      "Vous pourrez ajouter cette sous-espèce à une espèce sauvage.",
    no_subspecies_selected: "Aucune sous-espèce n'a été sélectionnée",
    archive_a_subspecies: "Archive une sous-espèce",
    unarchive_a_subspecies: "Désarchiver une sous-espèce",
  },
};

export default adminSubspeciesTranslation;
