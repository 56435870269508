import * as React from "react";
import { useTranslation } from "react-i18next";
import AddResponseStatementPopup, { useResponseStatementPopup } from "./";

const AddResponseStatementBtn: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const popupState = useResponseStatementPopup({});

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={popupState.open}
        data-testid="button-add"
      >
        {t("add")}
      </button>
      <AddResponseStatementPopup modalState={popupState} {...props} />
    </>
  );
};

export default AddResponseStatementBtn;
