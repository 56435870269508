import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";

export function SignOut() {
  const { instance } = useMsal();

  useEffect(() => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .catch(console.error);
  }, []);

  // TODO: i18n
  return <>Signing you out...</>;
}

export default SignOut;
