import type { ReactElement } from "react";
import * as React from "react";
import {
  AuthorizationAdUserListDocument,
  BilingualAbbreviationAcronymTagsLookupText,
  Maybe,
  Scalars,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";
import { useTranslation } from "react-i18next";

const additionalQueryVariables = {
  params: {
    searchType: "contains",
    isCaseSensitive: false,
  },
};

const AuthorizationAdUserAutoComplete: React.FC<
  AuthorizationAdUserAutoCompleteProps
> = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <QueryAutocomplete
      id={"AuthorizationAdUserAutoComplete"}
      label={props.label}
      queryDocument={AuthorizationAdUserListDocument}
      listDisplayFormatter={(item) => item.email}
      onSelectionChange={props.onSelectionChange}
      placeholder={props.initialValue?.email ?? t("user")}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(undefined)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

interface AuthorizationAdUserAutoCompleteProps {
  isRequired?: boolean;
  onSelectionChange: (
    selectedItem?: BilingualAbbreviationAcronymTagsLookupText
  ) => void;
  label: string | ReactElement;
  disabled: boolean | undefined;
  initialValue?: UserAutoCompleteFields;
}

export interface UserAutoCompleteFields {
  id?: Maybe<Scalars["ID"]>;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
}
export default AuthorizationAdUserAutoComplete;
