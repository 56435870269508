import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "util/router";
import SpeciesInformation from ".";
import SpeciesDocumentsPage from "./documents/documents";
import PermitDocumentsPage from "./documents/permitDescriptions";
import PublicDocumentsPage from "./documents/public";
import InternalDocumentsPage from "./documents/internal";
import { FeatureFlagKeys } from "../../features/featureFlags/config";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";

export interface OverviewRouterProps {}

export const OverviewPathHelpers = {
  SpeciesInformation: (program: string, id: string) =>
    `/overview?program=${program}&id=${id}`,
  Documents: (program: string, id: string) =>
    `/overview/documents?program=${program}&id=${id}`,
  PermitDocuments: (program: string, id: string) =>
    `/overview/documents/permitDescriptions?program=${program}&id=${id}`,
  PublicDocuments: (program: string, id: string) =>
    `/overview/documents/public?program=${program}&id=${id}`,
  InternalDocuments: (program: string, id: string) =>
    `/overview/documents/internal?program=${program}&id=${id}`,
};

export const OverviewRouter: React.FC<OverviewRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <SpeciesInformation />
      </Route>

      <Route exact path={relative("/documents")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <SpeciesDocumentsPage />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route exact path={relative("/documents/permitDescriptions")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <PermitDocumentsPage />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route exact path={relative("/documents/public")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <PublicDocumentsPage />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route exact path={relative("/documents/internal")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <InternalDocumentsPage />
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default OverviewRouter;
