import * as React from "react";
import { useTranslation } from "react-i18next";

const FieldValidationError: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="mrgn-tp-sm">
      <span className="label label-danger">
        <span className="prefix">{t("error")}: </span>
        {children}
      </span>
    </div>
  );
};

export default FieldValidationError;
