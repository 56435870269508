import { ProvinceTerritoryInput } from "components/atoms/forms/ProvinceTerritoryInput";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { validAddress } from "util/contact/addressFormat";
import { phoneRegex } from "util/contact/phoneFormat";
import {
  formatPostalOrZipCode,
  getPostalOrZipCode,
} from "util/contact/postalOrZipCodeFormat";
import {
  BilingualText,
  ContactDetailsInput,
  ContactNameInput,
  ContactTag,
  Maybe,
  MethodOfCommunication,
  NoteInput,
  Organization,
  OrganizationDetailsInput,
} from "../../../generated/gql-types";
import isNullOrEmpty from "../../../util/isNullOrEmpty";
import SectionCard from "../../atoms/SectionCard";
import FieldValidationError from "../../atoms/forms/FieldValidationError";
import { TextInput } from "../../atoms/forms/TextInput";
import CountryDropdown from "../../molecules/CountryDropdown/CountryDropdown";
import FormButtons from "../../molecules/FormButtons/FormButtons";
import { FullHTMLEditorWithController } from "../FullHTMLEditor";
import AddOrganizationPopup, {
  useOrganizationPopup,
} from "../admin/CreateOrganizationModal";
import AddRemoveOrganizationButtonAndModal from "../organization/AddRemoveOrganizationButtonAndModal";
import * as ContactsFormMappers from "./contactsFormUtil";
import { mapContactDetails } from "./contactsFormUtil";
import { isNullOrEmptyAddress } from "util/contact/isNullOrEmptyAddress";

export interface ContactFormProps {
  defaultValues?: ContactFormFields;
  contactOrOrgInUse?: Maybe<boolean>;
  onSubmit: SubmitHandler<Partial<ContactFormFields>>;
  isSaving?: boolean;
}

const ContactsForm: FunctionComponent<ContactFormProps> = (props) => {
  const { defaultValues, contactOrOrgInUse } = props;
  const organizationPopupState = useOrganizationPopup({});

  const { t } = useTranslation();
  const history = useHistory();
  const form = useForm<ContactFormFields>({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState,
    register,
    control,
    watch,
    setValue,
    clearErrors,
  } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const methodOfCommunication = watch("methodOfCommunication");
  const useOrganizationAddress = watch("useOrganizationAddress");
  const organizationDetails = watch("organizationDetails");
  const formOrganizationRef = watch("formOrganizationRef");
  const countryCode = watch("contactDetails.address.countryCode");

  const postCodeObj = getPostalOrZipCode(countryCode);
  // TODO:
  // TODO:
  // TODO: test setting null / clearing the field
  // TODO:
  // TODO:
  // Here check if the organization is empty or the organization address is null or empty
  const disableUseOrganizationAddress =
    isNullOrEmpty(organizationDetails?.id) ||
    isNullOrEmptyAddress(formOrganizationRef?.contactDetails?.address); // This is the fix for the bug 85596

  const disableProvinceStateAndPostalCode =
    countryCode == null || countryCode === "NOT_INITIALIZED";

  const [disableAddressFields, setDisableAddressFields] = useState(
    defaultValues?.useOrganizationAddress === true
  );

  function clearAllMailingAddress() {
    // Make an empty mailing address data
    let contactDetails = mapContactDetails(undefined);

    setValue(
      "contactDetails.address.addressLines",
      contactDetails?.address?.addressLines,
      { shouldDirty: true }
    );

    setValue("contactDetails.address.city", contactDetails?.address?.city, {
      shouldDirty: true,
    });

    setValue(
      "contactDetails.address.provinceOrState",
      contactDetails?.address?.provinceOrState,
      { shouldDirty: true }
    );

    setValue(
      "contactDetails.address.postalOrZipCode",
      contactDetails?.address?.postalOrZipCode,
      { shouldDirty: true }
    );

    setValue(
      "contactDetails.address.countryCode",
      contactDetails?.address?.countryCode,
      { shouldDirty: true }
    );
  }

  function clearAddress() {
    if (
      disableUseOrganizationAddress === false &&
      useOrganizationAddress == true
    ) {
      clearAllMailingAddress();
      setDisableAddressFields(false);
    }
  }

  useEffect(
    function populateOrganizationDetails() {
      // when the initialization do nothing
      if (!isDirty) return;

      // TODO: when we clear the field, we probably DO want to handle null?
      if (formOrganizationRef == null) {
        return;
      }
      const mappedOrganizationDetails =
        ContactsFormMappers.mapOrganizationToOrganizationDetails(
          formOrganizationRef
        );
      setValue("organizationDetails", mappedOrganizationDetails, {
        shouldDirty: true,
      });
    },
    [formOrganizationRef]
  );

  useEffect(
    function autoPopulateAddressFields() {
      // when the initialization do nothing
      if (!isDirty) return;

      // When the checkbox is unchecked, we should do nothing to the address fields.
      if (useOrganizationAddress === false) {
        //clearAllMailingAddress();
        setDisableAddressFields(false);
        return;
      }

      // If we have nothing to populate the address fields with, we should do nothing to the address fields.
      // TODO: should we unset them? how do we tell the difference between a null default value, and a null value from manually clearing the field?
      if (
        formOrganizationRef == null ||
        formOrganizationRef?.contactDetails?.address == null
      ) {
        return;
      }

      if (useOrganizationAddress === true) {
        setDisableAddressFields(true);
      }

      if (
        !isNullOrEmpty(formOrganizationRef.contactDetails.address.addressLines)
      ) {
        setValue(
          "contactDetails.address.addressLines",
          formOrganizationRef.contactDetails.address.addressLines,
          { shouldDirty: true }
        );
      }

      if (!isNullOrEmpty(formOrganizationRef.contactDetails.address.city)) {
        setValue(
          "contactDetails.address.city",
          formOrganizationRef.contactDetails.address.city,
          { shouldDirty: true }
        );
      }

      if (
        !isNullOrEmpty(
          formOrganizationRef.contactDetails.address.provinceOrState
        )
      ) {
        setValue(
          "contactDetails.address.provinceOrState",
          formOrganizationRef.contactDetails.address.provinceOrState,
          { shouldDirty: true }
        );
      }

      if (
        !isNullOrEmpty(
          formOrganizationRef.contactDetails.address.postalOrZipCode
        )
      ) {
        setValue(
          "contactDetails.address.postalOrZipCode",
          formOrganizationRef.contactDetails.address.postalOrZipCode,
          { shouldDirty: true }
        );
      }

      if (
        !isNullOrEmpty(formOrganizationRef.contactDetails.address.countryCode)
      ) {
        setValue(
          "contactDetails.address.countryCode",
          formOrganizationRef.contactDetails.address.countryCode,
          { shouldDirty: true }
        );
      }

      clearErrors("contactDetails.address");
    },
    [useOrganizationAddress, formOrganizationRef]
  );

  const nameFieldsRequired = useMemo(() => {
    switch (methodOfCommunication) {
      case MethodOfCommunication.PhoneNumber:
      case MethodOfCommunication.FaxNumber:
      case MethodOfCommunication.MailingAddress:
        return true;
      default:
        return false;
    }
  }, [methodOfCommunication]);

  const emailAddressRequired = useMemo(() => {
    return methodOfCommunication === MethodOfCommunication.EmailAddress;
  }, [methodOfCommunication]);

  const phoneRequired = useMemo(() => {
    return methodOfCommunication === MethodOfCommunication.PhoneNumber;
  }, [methodOfCommunication]);

  const faxRequired = useMemo(() => {
    return methodOfCommunication === MethodOfCommunication.FaxNumber;
  }, [methodOfCommunication]);

  const streetNumberRequired = useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const cityRequired = useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const provinceOrStateRequired = useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const postalCodeRequired = useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const countryRequired = useMemo(() => {
    return (
      methodOfCommunication === MethodOfCommunication.MailingAddress ||
      contactOrOrgInUse === true
    );
  }, [methodOfCommunication, contactOrOrgInUse]);

  const onSubmit: SubmitHandler<Partial<ContactFormFields>> = async (
    formData
  ) => {
    const cleanedValues = ContactsFormMappers.processFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );

    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  const onCancel = () => {
    history.push({
      pathname: "/admin/contacts",
    });
  };

  const onClearOrganization = async () => {
    setValue("organizationDetails", {}, { shouldDirty: true });
    setValue("useOrganizationAddress", false, { shouldDirty: true });
    // Should we clear address fields here? A question to ask the PO's.
    setValue("formOrganizationRef", {}, { shouldDirty: true });
    clearAddress();
  };

  const [orgAutocompleteRefreshKey, setOrgAutocompleteRefreshKey] = useState(
    Date.now().toString()
  );
  const onAddOrganizationCompleted = async () => {
    setOrgAutocompleteRefreshKey(Date.now().toString());
  };

  return (
    <SectionCard header={<h2>{t("contact_information")}</h2>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={props.isSaving}>
          <div className="row">
            <TextInput
              {...register("name.firstName", {
                required: {
                  value: nameFieldsRequired,
                  message: t("field_is_required"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={nameFieldsRequired}
              label={t("first_name")}
              errors={errors}
            />
            <TextInput
              {...register("name.lastName", {
                required: {
                  value: nameFieldsRequired,
                  message: t("field_is_required"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={nameFieldsRequired}
              label={t("last_name")}
              errors={errors}
            />
          </div>
          <div className="form-group">
            <label htmlFor="formOrganizationRef">{t("Organization")}</label>
            <div className="flex justify-left align-start">
              <Controller
                name="formOrganizationRef"
                control={control}
                rules={{ validate: (value) => value != null }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <AddRemoveOrganizationButtonAndModal
                      id={"formOrganizationRef"}
                      contact={value}
                      onSave={(newValue) => {
                        onChange(newValue);
                        form.trigger("formOrganizationRef");
                      }}
                      onRemove={onClearOrganization}
                    />
                  );
                }}
              />
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.administration.contact.create}
              >
                <div className="mrgn-tp-sm mrgn-lft-md mrgn-rght-md hidden-xs">
                  <b className="font-size-14">{t("or")}</b>
                </div>
                <button
                  type="button"
                  className="btn btn-link py-0 px-2"
                  onClick={organizationPopupState.open}
                  data-testid="button-add-organization"
                >
                  <i className="fa fa-plus-circle mrgn-rght-sm"></i>
                  {t("add_new_organization")}
                </button>
                <AddOrganizationPopup
                  modalState={organizationPopupState}
                  onCompleted={onAddOrganizationCompleted}
                  {...props}
                />
              </RenderWhenAuthorized>
            </div>
          </div>

          <div className="row">
            <TextInput
              {...register("jobTitle.english")}
              divClassName="col-md-6"
              inputClassName="full-width"
              label={t("job_title_english")}
              errors={errors}
            />

            <TextInput
              {...register("jobTitle.french")}
              divClassName="col-md-6"
              inputClassName="full-width"
              label={t("job_title_french")}
              errors={errors}
            />
          </div>

          <div className="form-group">
            <fieldset className="chkbxrdio-grp">
              <legend className="required">
                <span className="field-name">
                  {t("method_of_communication")}
                </span>{" "}
                <strong className="required">({t("required")})</strong>
              </legend>

              <label className="radio-inline" htmlFor="rdo_email">
                <input
                  id="rdo_email"
                  defaultChecked={
                    defaultValues?.methodOfCommunication ===
                    MethodOfCommunication.EmailAddress
                  }
                  type="radio"
                  value={MethodOfCommunication.EmailAddress}
                  {...register("methodOfCommunication", {
                    required: true,
                  })}
                  onClick={() => {
                    clearErrors("contactDetails");
                  }}
                  // required={true}
                />
                &#160;{t("email")}
              </label>

              <label className="radio-inline" htmlFor="rdo_phone">
                <input
                  id="rdo_phone"
                  defaultChecked={
                    defaultValues?.methodOfCommunication ===
                    MethodOfCommunication.PhoneNumber
                  }
                  type="radio"
                  value={MethodOfCommunication.PhoneNumber}
                  {...register("methodOfCommunication", {
                    required: true,
                  })}
                  onClick={() => {
                    clearErrors("contactDetails");
                  }}
                  //required={true}
                />
                &#160;{t("phone")}
              </label>

              <label className="radio-inline" htmlFor="rdo_mailing_address">
                <input
                  id="rdo_mailing_address"
                  defaultChecked={
                    defaultValues?.methodOfCommunication ===
                    MethodOfCommunication.MailingAddress
                  }
                  type="radio"
                  value={MethodOfCommunication.MailingAddress}
                  {...register("methodOfCommunication", {
                    required: true,
                  })}
                  onClick={() => {
                    clearErrors("contactDetails");
                  }}
                  //required={true}
                />
                &#160;{t("mailing_address")}
              </label>

              <label className="radio-inline" htmlFor="rdo_fax">
                <input
                  id="rdo_fax"
                  defaultChecked={
                    defaultValues?.methodOfCommunication ===
                    MethodOfCommunication.FaxNumber
                  }
                  type="radio"
                  value={MethodOfCommunication.FaxNumber}
                  {...register("methodOfCommunication", {
                    required: true,
                  })}
                  onClick={() => {
                    clearErrors("contactDetails");
                  }}
                  //required={true}
                />
                &#160;{t("fax")}
              </label>
            </fieldset>
          </div>

          <TextInput
            type="email"
            {...register("contactDetails.emailAddress", {
              required: {
                value: emailAddressRequired,
                message: t("field_is_required"),
              },
            })}
            inputClassName="full-width"
            required={emailAddressRequired}
            label={t("email")}
            errors={errors}
          />

          <div className="row">
            <TextInput
              type="tel"
              {...register("contactDetails.phoneNumber", {
                required: {
                  value: phoneRequired,
                  message: t("field_is_required"),
                },
                pattern: {
                  value: phoneRegex,
                  message: t("valid_phone_number"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={phoneRequired}
              label={t("phone")}
              placeholder={"e.g. 1-320-316-4765"}
              errors={errors}
            />

            <TextInput
              type="tel"
              {...register("contactDetails.faxNumber", {
                required: {
                  value: faxRequired,
                  message: t("field_is_required"),
                },
                pattern: {
                  value: phoneRegex,
                  message: t("valid_fax_number"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={faxRequired}
              label={t("fax")}
              placeholder={"e.g. 1-320-316-4765"}
              errors={errors}
            />
          </div>

          <div className="form-group">
            <div className="checkbox">
              <label htmlFor="useOrganizationAddressCheckbox">
                <input
                  disabled={disableUseOrganizationAddress}
                  type="checkbox"
                  id="useOrganizationAddressCheckbox"
                  {...register("useOrganizationAddress")}
                  onChange={(x) => {
                    clearAddress();
                    setValue(
                      "useOrganizationAddress",
                      !useOrganizationAddress,
                      {
                        shouldDirty: true,
                      }
                    );
                  }}
                />
                &nbsp;
                {t("use_organization_address")}
              </label>
            </div>
          </div>

          <div className="row">
            <TextInput
              {...register("contactDetails.address.addressLines.0.english", {
                required: {
                  value: streetNumberRequired,
                  message: t("field_is_required"),
                },
                pattern: {
                  value: validAddress,
                  message: t("valid_street_address"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={streetNumberRequired}
              disabled={disableAddressFields}
              label={t("address_line_1") + " (" + t("english") + ")"}
              errors={errors}
            />

            <TextInput
              {...register("contactDetails.address.addressLines.0.french", {
                required: {
                  value: streetNumberRequired,
                  message: t("field_is_required"),
                },
                pattern: {
                  value: validAddress,
                  message: t("valid_street_address"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={streetNumberRequired}
              disabled={disableAddressFields}
              label={t("address_line_1") + " (" + t("french") + ")"}
              errors={errors}
            />
          </div>

          <div className="row">
            <TextInput
              {...register("contactDetails.address.addressLines.1.english")}
              divClassName="col-md-6"
              inputClassName="full-width"
              label={t("address_line_2") + " (" + t("english") + ")"}
              disabled={disableAddressFields}
              errors={errors}
            />

            <TextInput
              {...register("contactDetails.address.addressLines.1.french")}
              divClassName="col-md-6"
              inputClassName="full-width"
              label={t("address_line_2") + " (" + t("french") + ")"}
              disabled={disableAddressFields}
              errors={errors}
            />
          </div>

          <div className="row">
            {/* ===== City ===== */}
            <TextInput
              {...register("contactDetails.address.city", {
                required: {
                  value: cityRequired,
                  message: t("field_is_required"),
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              required={cityRequired}
              disabled={disableAddressFields}
              label={t("city")}
              errors={errors}
            />

            {/* ===== Country ===== */}
            <CountryDropdown
              {...register("contactDetails.address.countryCode", {
                required: {
                  value: countryRequired,
                  message: t("field_is_required"),
                },
                onChange: (x) => {
                  if (
                    x.target.value ===
                    defaultValues?.contactDetails?.address?.countryCode
                  ) {
                    setValue(
                      "contactDetails.address.countryCode",
                      defaultValues?.contactDetails?.address?.countryCode
                    );
                    setValue(
                      "contactDetails.address.provinceOrState",
                      defaultValues?.contactDetails?.address?.provinceOrState
                    );
                    setValue(
                      "contactDetails.address.postalOrZipCode",
                      defaultValues?.contactDetails?.address?.postalOrZipCode
                    );
                  } else {
                    setValue("contactDetails.address.provinceOrState", "", {
                      shouldDirty: true,
                    });
                    setValue("contactDetails.address.postalOrZipCode", "", {
                      shouldDirty: true,
                    });
                  }
                },
              })}
              required={countryRequired}
              disabled={disableAddressFields}
              divClassName="col-md-6"
              inputClassName="full-width"
              label={t("country")}
              errors={errors}
            />
          </div>

          <div className="row">
            {/* ====== Province/territory, State, or Input text ====== */}
            <ProvinceTerritoryInput
              name="contactDetails.address.provinceOrState"
              control={control}
              register={register}
              countryCode={countryCode}
              isRequired={provinceOrStateRequired}
              disabled={
                disableAddressFields || disableProvinceStateAndPostalCode
              }
              errors={errors}
            />

            {/* ===== Postal code or Zip code ===== */}
            <TextInput
              {...register("contactDetails.address.postalOrZipCode", {
                required: {
                  value: postalCodeRequired,
                  message: t("field_is_required"),
                },
                pattern: {
                  value: postCodeObj.pattern,
                  message: t("invalid_post_or_zip_code_format"),
                },
                onChange: (e) => {
                  e.target.value = formatPostalOrZipCode(
                    e.target.value,
                    countryCode
                  );
                },
              })}
              divClassName="col-md-6"
              inputClassName="full-width"
              label={t(postCodeObj.label)}
              required={postalCodeRequired}
              disabled={
                disableAddressFields || disableProvinceStateAndPostalCode
              }
              placeholder={postCodeObj.placeHolder}
              errors={errors}
              maxLength={postCodeObj.maxLength}
            />
          </div>

          {/* =============== Contact Tag =============== */}
          <div className="form-group">
            <fieldset className="chkbxrdio-grp">
              <legend>
                <span className="field-name">{t("contact_tags")}</span>
              </legend>
              <div className="checkbox-columns-lg">
                <div className="checkbox">
                  <label htmlFor="contact-tags-indigenous">
                    <input
                      type="checkbox"
                      id="contact-tags-indigenous"
                      value={ContactTag.ConsulteeIndigenous}
                      {...register("tags")}
                    />
                    <span>{t(ContactTag.ConsulteeIndigenous)}</span>
                  </label>
                </div>

                <div className="checkbox">
                  <label htmlFor="contact-tags-expert">
                    <input
                      type="checkbox"
                      id="contact-tags-expert"
                      value={ContactTag.ConsulteeExpert}
                      {...register("tags")}
                    />
                    <span>{t(ContactTag.ConsulteeExpert)}</span>
                  </label>
                </div>

                <div className="checkbox">
                  <label htmlFor="contact-tags-citizen">
                    <input
                      type="checkbox"
                      id="contact-tags-citizen"
                      value={ContactTag.ConsulteeCitizen}
                      {...register("tags")}
                    />
                    <span>{t(ContactTag.ConsulteeCitizen)}</span>
                  </label>
                </div>

                <div className="checkbox">
                  <label htmlFor="contact-tags-education">
                    <input
                      type="checkbox"
                      id="contact-tags-education"
                      value={ContactTag.Education}
                      {...register("tags")}
                    />
                    <span>{t(ContactTag.Education)}</span>
                  </label>
                </div>

                <div className="checkbox">
                  <label htmlFor="contact-tags-photographer">
                    <input
                      type="checkbox"
                      id="contact-tags-photographer"
                      value={ContactTag.Photographer}
                      {...register("tags")}
                    />
                    <span>{t(ContactTag.Photographer)}</span>
                  </label>
                </div>

                <div className="checkbox">
                  <label htmlFor="contact-tags-scientist">
                    <input
                      type="checkbox"
                      id="contact-tags-scientist"
                      value={ContactTag.Scientist}
                      {...register("tags")}
                    />
                    <span>{t(ContactTag.Scientist)}</span>
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

          {/* =============== Note =============== */}
          <div className="form-group">
            <label htmlFor="note">
              <span className="field-name">{t("note")}</span>{" "}
            </label>
            <FullHTMLEditorWithController
              control={control}
              defaultValue={defaultValues?.note?.text?.text ?? ""}
              id="note"
              name="note"
            />
            {errors.note?.text && (
              <FieldValidationError>{errors.note.text}</FieldValidationError>
            )}
          </div>

          <FormButtons
            isSubmitting={isSubmitting || props.isSaving}
            isDirty={isDirty}
            onCancel={onCancel}
            errors={errors}
          />
        </fieldset>
      </form>
    </SectionCard>
  );
};

export default ContactsForm;

export interface ContactFormFields {
  name?: ContactNameInput;
  jobTitle?: BilingualText;
  contactDetails?: ContactDetailsInput;
  methodOfCommunication?: MethodOfCommunication;
  useOrganizationAddress?: boolean;
  organizationDetails?: OrganizationDetailsInput | null;
  formOrganizationRef?: Partial<Organization> | null;
  tags?: Array<Maybe<ContactTag>>;
  note?: NoteInput;
}
