import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  BilingualAbbreviationAcronymTagsLookupTextInput,
  Maybe,
  ProvinceTerritoryLookupListDocument,
} from "generated/gql-types";
import i18n from "i18n";
import { bilingualTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface ProvinceTerritoryDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  required?: boolean;
  disabled?: boolean;
  countryCode?: Maybe<string>;
  onChange: (value: BilingualAbbreviationAcronymTagsLookupTextInput) => void;
}

export const ProvinceTerritoryDropdown = React.forwardRef<
  HTMLSelectElement,
  ProvinceTerritoryDropdownProps
>((props, ref) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(
    ProvinceTerritoryLookupListDocument,
    {
      errorPolicy: "all",
      variables: {
        params: {
          category: "iso3166_subdivisions",
          pageNumber: 1,
          pageSize: 200,
          sortBy:
            i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
          tags: props.countryCode,
        },
      },
      fetchPolicy: "cache-first",
    }
  );

  if (loading) return <LoadingIndicator className="mrgn-lft-sm" />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const acronym = e.currentTarget?.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(acronym)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.lookupList?.lookups?.find(
      (x) => x?.acronym === acronym
    );

    if (!value) {
      console.error(
        `US State dropdown couldn't find a value for acronym ${acronym}`
      );
      return;
    }
    props.onChange(value);
  };

  const { required, ...propsWithoutRequired } = props;

  return (
    <>
      {props.countryCode === "US" ? (
        <>
          <label
            htmlFor="us-state"
            className={props.required ? "required" : ""}
          >
            <span className="field-name">
              {t("state")} ({t("u_s")})
            </span>{" "}
            {props.required ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </label>
        </>
      ) : props.countryCode === "CA" ? (
        <>
          <label
            htmlFor="ca-province-territory"
            className={props.required ? "required" : ""}
          >
            <span className="field-name">
              {t("province_territory")} (Canada)
            </span>{" "}
            {props.required ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </label>
        </>
      ) : (
        <>
          <label
            htmlFor="other-state"
            className={props.required ? "required" : ""}
          >
            <span className="field-name">
              {t("province_territory_state_region_etc")}
            </span>{" "}
            {props.required ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </label>
        </>
      )}
      <select
        className="form-control full-width"
        value={props?.defaultValue ?? ""}
        ref={ref}
        {...propsWithoutRequired}
        onChange={onChange}
      >
        <option value="">
          {t(props.placeholder ?? "please_select_option")}
        </option>
        {data?.lookupList?.lookups?.map((x) => {
          return (
            <option
              key={x?.acronym ?? "error - missing acronym"}
              value={x?.acronym ?? "error - missing acronym"}
            >
              {bilingualTextNameForLanguage(i18n.language, x?.name)}
            </option>
          );
        })}
      </select>
    </>
  );
});

export interface ProvinceTerritoryDropdownWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<ProvinceTerritoryDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
  required: boolean;
}

export const ProvinceTerritoryDropdownWithController = <
  TFieldValues extends FieldValues
>(
  props: ProvinceTerritoryDropdownWithControllerProps<TFieldValues>
) => {
  const { t } = useTranslation();

  const isDisabled = () => {
    if (
      props.countryCode === "" ||
      props.countryCode === null ||
      props.disabled
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Controller
      {...props}
      rules={{
        required: { value: props.required, message: t("field_is_required") },
      }}
      render={({ field: { value, onChange } }) => (
        <ProvinceTerritoryDropdown
          id={props.id}
          placeholder={props.placeholder}
          onChange={(x) => onChange(x?.acronym ?? "")}
          defaultValue={value}
          required={props.required}
          disabled={isDisabled()}
          countryCode={props.countryCode}
        />
      )}
    />
  );
};

export default ProvinceTerritoryDropdownWithController;
