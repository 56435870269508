import { WildLifeSpeciesPresence } from "generated/gql-types";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import { ListingLandFormFields } from "../ListingLandForm/ListingLandForm";

export interface WildlifeSpeciesPresenceInputProps {}

export const WildlifeSpeciesPresenceInput: React.FC<
  WildlifeSpeciesPresenceInputProps
> = (props) => {
  const { t } = useTranslation();
  const { register, formState } = useFormContext<ListingLandFormFields>();

  const { errors } = formState;

  return (
    <div>
      <label htmlFor="wildlifeSpeciesPresenceInput" className="required">
        {t("wildlife_species_presence")}
        <strong className="required"> ({t("required")})</strong>
      </label>
      <select
        className="form-control"
        id="wildlifeSpeciesPresenceInput"
        {...register("wildlifeSpeciesPresence", { required: true })}
      >
        <option value={""} hidden>
          {t("please_select_option")}
        </option>
        <option value={WildLifeSpeciesPresence.Confirmed}>
          {t(WildLifeSpeciesPresence.Confirmed)}
        </option>
        <option value={WildLifeSpeciesPresence.HighPotential}>
          {t(WildLifeSpeciesPresence.HighPotential)}
        </option>
        <option value={WildLifeSpeciesPresence.ExtirpatedFromThisSite}>
          {t(WildLifeSpeciesPresence.ExtirpatedFromThisSite)}
        </option>
        <option value={WildLifeSpeciesPresence.Possible}>
          {t(WildLifeSpeciesPresence.Possible)}
        </option>
        <option value={WildLifeSpeciesPresence.HabitatOnly}>
            {t(WildLifeSpeciesPresence.HabitatOnly)}
        </option>
        <option value={WildLifeSpeciesPresence.NoData}>
            {t(WildLifeSpeciesPresence.NoData)}
        </option>
      </select>

      {errors.wildlifeSpeciesPresence && (
        <FieldValidationError>{t("field_is_required")}</FieldValidationError>
      )}
    </div>
  );
};

export default WildlifeSpeciesPresenceInput;
