import React from "react";
import LoadingIndicator from "../../atoms/LoadingIndicator";
import {
  BilingualAbbreviationAcronymLookupText,
  OrganizationTagsDocument,
} from "../../../generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "../../../mappers";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import isNullOrEmpty from "../../../util/isNullOrEmpty";

export interface OrganizationTagDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  disabled?: boolean;
  onChange: (value: BilingualAbbreviationAcronymLookupText) => void;
}

export const OrganizationTagDropdown = React.forwardRef<
  HTMLSelectElement,
  OrganizationTagDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(OrganizationTagsDocument, {
    variables: {
      params: { pageSize: 100 },
    },
  });
  const { t, i18n } = useTranslation();

  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;
    const value = data?.organizationTags?.lookups.find((x) => x?.id === id);

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    if (!value) {
      console.error(
        `OrganizationTagDropdown couldn't find a value for ID ${id}`
      );
      return;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control"
      ref={ref}
      {...props}
      onChange={onChange}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
    >
      <option value={""}>{t("please_select_option")}</option>
      {data?.organizationTags?.lookups.map((x) => {
        return (
          <option key={x?.id} value={x?.id ?? "error - missing id"}>
            {bilingualLookupTextNameForLanguage(i18n.language, x)}
          </option>
        );
      })}
    </select>
  );
});

export interface OrganizationTagDropdownWithControllerProps<TFieldValues>
  extends Omit<OrganizationTagDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const OrganizationTagsDropdownWithController = <TFieldValues,>(
  props: OrganizationTagDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <OrganizationTagDropdown
          disabled={props.disabled}
          onChange={onChange}
          defaultValue={(value as any)?.id ?? ""}
        />
      )}
    />
  );
};

export default OrganizationTagDropdown;
