import { ListingProcessDocumentType, Maybe } from "generated/gql-types";

// Mapping Document Type (from response) to Publication Date Title (for display)
const docTypeToPubDateTitle = new Map([
  ["CONSULTATION_END_DATE", "consultation_end_publication_date"],
  ["CG_I_PROPOSAL_DATE", "cgi_proposal_publication_date"],
  ["CG_II_GIC_RECEIPT", "cgii_gic_receipt_publication_date"],
  ["CG_II_LISTING_ORDER", "cgii_listing_order_publication_date"],
  ["CG_II_NOT_LIST_REFER_BACK", "cgii_not_list_refer_back_publication_date"],
]);

const docTypeToDocUriTitle = new Map([
  ["CONSULTATION_END_DATE", "consultation_end_date_document"],
  ["CG_I_PROPOSAL_DATE", "cgi_proposal_date_document"],
  ["CG_II_GIC_RECEIPT", "cgii_gic_receipt_document"],
  ["CG_II_LISTING_ORDER", "cgii_listing_order_document"],
  ["CG_II_NOT_LIST_REFER_BACK", "cgii_not_list_refer_back_document"],
]);

export function convertDocTypeToPubDateTitle(
  docType?: Maybe<ListingProcessDocumentType>
): string {
  if (docType) {
    const PubDateTitle = docTypeToPubDateTitle.get(docType);
    if (PubDateTitle) return PubDateTitle;
  }
  return "Undefined listing process document type";
}

export function convertDocTypeToDocUriTitle(
  docType?: Maybe<ListingProcessDocumentType>
): string {
  if (docType) {
    const DocUriTitle = docTypeToDocUriTitle.get(docType);
    if (DocUriTitle) return DocUriTitle;
  }
  return "Undefined listing process document type";
}
