export const BASE_URL =
  process.env.REACT_APP_AZURE_SEARCH_BASIC_SEARCH_URL ??
  "Error: REACT_APP_AZURE_SEARCH_BASIC_SEARCH_URL was empty";
export const INDEX_NAME =
  process.env.REACT_APP_AZURE_SEARCH_BASIC_SEARCH_INDEX_NAME ??
  "Error: REACT_APP_AZURE_SEARCH_BASIC_SEARCH_INDEX_NAME was empty";
export const API_VERSION =
  process.env.REACT_APP_AZURE_SEARCH_BASIC_SEARCH_API_VERSION ??
  "Error: REACT_APP_AZURE_SEARCH_BASIC_SEARCH_API_VERSION was empty";
export const API_KEY =
  process.env.REACT_APP_AZURE_SEARCH_BASIC_SEARCH_API_KEY ??
  "Error: REACT_APP_AZURE_SEARCH_BASIC_SEARCH_API_KEY was empty";
