import * as React from "react";
import { useTranslation } from "react-i18next";
import CreateSubspeciesModal, {
  CreateSubspeciesModalProps,
  useSubspeciesModal,
} from "./";

interface CreateSubspeciesModalWithButtonProps
  extends Omit<CreateSubspeciesModalProps, "modalState"> {
  buttonLabel?: string;
}

const CreateSubspeciesModalWithButton: React.FC<
  CreateSubspeciesModalWithButtonProps
> = (props) => {
  const { t } = useTranslation();
  const modalState = useSubspeciesModal({});

  const label = props.buttonLabel ?? t("add");

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        data-testid="button-add"
      >
        {label}
      </button>
      <CreateSubspeciesModal modalState={modalState} {...props} />
    </>
  );
};

export default CreateSubspeciesModalWithButton;
