import {
  ListingWsSector,
  ListingWsSectorInput,
  ListingWsSectorQuery,
  Maybe,
} from "generated/gql-types";
import { ListingSectorFormFields } from "./ListingSectorForm";
import {
  makeBilingualAbbreviationLookupText,
  makeBilingualText,
} from "mappers";

export function mapDomainModelToForm(
  domainModel:
    | Maybe<Partial<ListingWsSector>>
    | ListingWsSectorQuery["listingWsSector"]
) {
  const out: Partial<ListingSectorFormFields> = {};

  // TODO: untested: ensure this mapping is all good

  out.naics = {
    code: domainModel?.naics?.code,
    className: makeBilingualText(domainModel?.naics?.className),
    classVersion: domainModel?.naics?.classVersion,
  };

  out.activities = domainModel?.activities?.map((x) =>
    makeBilingualAbbreviationLookupText(x)
  );

  out.outcomes = domainModel?.outcomes?.map((x) =>
    makeBilingualAbbreviationLookupText(x)
  );

  return out;
}

export function mapFormToCreateInput(form: Partial<ListingSectorFormFields>) {
  const out: Partial<ListingWsSectorInput> = {};

  if (form.naics) out.naics = form.naics;
  if (form.activities) out.activities = form.activities;
  if (form.outcomes) out.outcomes = form.outcomes;

  return out;
}

// TODO: should there be a different type for update/create?
export function mapFormToUpdateInput(form: Partial<ListingSectorFormFields>) {
  const out: Partial<ListingWsSectorInput> = {};

  if (form.naics) out.naics = form.naics;
  if (form.activities) out.activities = form.activities;
  if (form.outcomes) out.outcomes = form.outcomes;

  return out;
}
