import Autocomplete from "../../molecules/Autocomplete";
import { useAsyncList } from "@react-stately/data";
import { Item } from "@react-stately/collections";
import {
  ApolloQueryResult,
  TypedDocumentNode,
  useApolloClient,
} from "@apollo/client";
import * as React from "react";
import { ReactElement, useEffect } from "react";
import pDebounce from "p-debounce";
import { SEARCH_DEBOUNCE_TIME_MS } from "../../../config/constants";
import { formatPageNumber } from "../../../util/formatPageNumber";
import { useQueryAutocompleteInstance } from "./useQueryAutocompleteInstance";
import { CustomQueryAutocomplete } from "./CustomQueryAutocomplete";

// TODO: try and have the callback props' args be typed correctly
// TODO: withController version
// TODO: types should restrict query document prop to something that matches the TypeOfQueryData shape?
export interface QueryAutocompleteProps<TQuery extends TypeOfQueryData<any>> {
  id: string;
  label: string | ReactElement;
  queryDocument: any;
  // TODO: below any -> derived type of query variables
  additionalQueryVariables?: any;
  // TODO: below any -> derived type of query list results
  listDisplayFormatter: (listItem: any) => string;
  // TODO: below any -> derived type of selected item
  onSelectionChange: (item: any) => void;
  placeholder?: string;
  isRequired?: boolean;
  onClear?: () => void;
  disabled?: boolean | undefined;
}

export const QueryAutocomplete = <TQuery extends TypeOfQueryData<any>>(
  props: QueryAutocompleteProps<TQuery>
) => {
  const instance = useQueryAutocompleteInstance(
    props.queryDocument,
    props.additionalQueryVariables
  );
  return (
    <CustomQueryAutocomplete
      useQueryAutocompleteInstance={instance}
      {...props}
    />
  );
};

export default QueryAutocomplete;
export interface TypeOfQueryData<TItem> {
  autocompleteData?: {
    autocompleteItems?: TItem[];
    pagination: any; // TODO: pagination type
  };
}
