const adminLandsTranslation = {
  en: {
    lands: "Lands",
    add_a_land: "Add a land",
    land_information: "Land information",
    land_type: "Land type",
    province_territory: "Province / territory",
    place_name: "Place name",
    land_name: "Land name",
    land_manager: "Land manager",
    land_manager_english: "Land manager (English)",
    land_manager_french: "Land manager (French)",
    dfrp: "DFRP",
    directory_of_federal_real_property: "Directory of Federal Real Property",
    land_size: "Land size",
    archive_land_warning:
      "You will no longer be able to add this land to a wildlife species.",
    unarchive_land_warning:
      "You will be able to add this Land to a wildlife species.",
    archive_a_land: "Archive a land",
    unarchive_a_land: "Unarchive a land",
    create_a_land_manager: "Create a land manager",
    edit_land: "Edit land",
    assign_a_place: "Assign a place",
    edit_place: "Edit place",
  },
  fr: {
    lands: "Terres",
    add_a_land: "Ajouter une terre",
    land_information: "Information sur la terre",
    land_type: "Type de terre",
    province_territory: "Province / territoire",
    place_name: "Nom du lieu",
    land_name: "Nom de la terre",
    land_manager: "Gestionnaire de la terre",
    land_manager_english: "Gestionnaire de la terre (anglais)",
    land_manager_french: "Gestionnaire de la terre (français)",
    dfrp: "RBIF",
    directory_of_federal_real_property:
      "Répertoire des biens immobiliers fédéraux",
    land_size: "Superficie de la terre",
    archive_land_warning:
      "Vous ne pourrez plus ajouter cette terre à une espèce sauvage.",
    unarchive_land_warning:
      "Vous pourrez ajouter cette terre à une espèce sauvage.",
    archive_a_land: "Archiver la terre",
    unarchive_a_land: "Désarchiver la terre",
    create_a_land_manager: "Créer un gestionnaire de terre",
    edit_land: "Modifier la terre",
    assign_a_place: "Attribuer un lieu",
    edit_place: "Modifier le lieu",
  },
};

export default adminLandsTranslation;
