// Mapping Cosewic key (from response) to tag (for display)
// Used in React-Query-Builder field selector.
import i18n from "i18n";

const cosewicKeyToTag = new Map([
  ["Cosewic/CosewicIdStr", "cosewic_id"],
  ["Cosewic/CommonNameEnglish/Name", "common_name, english"],
  ["Cosewic/CommonNameFrench/Name", "common_name, french"],
  ["Cosewic/NameWithComma/Name", "name_with_comma"],
  ["Cosewic/ScientificName/GeneratedScientificName/Name", "scientific_name"],
  ["Cosewic/TaxonomicGroup/NameEn", "taxonomic_group, english"],
  ["Cosewic/TaxonomicGroup/NameFr", "taxonomic_group, french"],
  [
    "Cosewic/OtherRecognizedCommonNameEnglish/PlainText",
    "other_recognized_english_common_names",
  ],
  [
    "Cosewic/OtherRecognizedCommonNameFrench/PlainText",
    "other_recognized_french_common_names",
  ],
  [
    "Cosewic/OtherRecognizedScientificName/PlainText",
    "other_recognized_scientific_names",
  ],
  ["Cosewic/indigenousName", "indigenous_names"],
  ["Cosewic/Population/NameEn", "population, english"],
  ["Cosewic/Population/NameFr", "population, french"],
  ["Cosewic/Ranges/NameEn", "ranges, english"],
  ["Cosewic/Ranges/NameFr", "ranges, french"],
  ["Cosewic/State", "cosewic_ws_id_state"],
  ["Cosewic/Status", "cosewic_ws_id_stage"],
  ["Cosewic/ActiveStatus", "active_status"],
  ["Cosewic/EndemicStatus", "endemic_status"],
  ["Cosewic/LatestAssessment/Date", "latest, assessment_date"],
  [
    "Cosewic/LatestAssessment/DateSentToMinister",
    "latest, date_sent_to_minister",
  ],
  ["Cosewic/LatestAssessment/ReportType", "latest, type_of_report"],
  ["Cosewic/LatestAssessment/Stage", "latest, assessment_stage"],
  ["Cosewic/LatestAssessment/State", "latest, assessment_state"],
  ["Cosewic/LatestAssessment/Status", "latest, cosewic_ws_status"],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/Status",
    "latest, assessment_status_criteria_status",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/StatusComment/French",
    "latest, assessment_status_criteria_status_comment, french",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/StatusComment/English",
    "latest, assessment_status_criteria_status_comment, english",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/StatusChange",
    "latest, assessment_status_criteria_status_change",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/StatusCriteria/French",
    "latest, assessment_status_criteria_status_criteria, french",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/StatusCriteria/English",
    "latest, assessment_status_criteria_status_criteria, english",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/ApplicabilityOfCriteria/Key",
    "latest, assessment_status_criteria_applicability_criteria_key",
  ],
  [
    "Cosewic/LatestAssessment/StatusAndCriteria/ApplicabilityOfCriteria/Value",
    "latest, assessment_status_criteria_applicability_criteria_value",
  ],
  ["Cosewic/LatestAssessment/Type", "latest, assessment_type"],
  [
    "Cosewic/LatestAssessment/YearSentToMinister",
    "latest, year_to_be_sent_to_minister",
  ],
  ["Cosewic/LatestAssessment/Order", "latest, order_of_assessment"],
  [
    "Cosewic/LatestAssessment/Designation/HistoryStatus/French",
    "latest, history_status_designation, french",
  ],
  [
    "Cosewic/LatestAssessment/Designation/HistoryStatus/English",
    "latest, history_status_designation, english",
  ],
  [
    "Cosewic/LatestAssessment/Designation/Reason/French",
    "latest, reason_designation, french",
  ],
  [
    "Cosewic/LatestAssessment/Designation/Reason/English",
    "latest, reason_designation, english",
  ],
  ["Cosewic/LatestAssessment/Note", "latest, assessment_note"],
  ["Cosewic/Assessments/Date", "assessment_date"],
  ["Cosewic/Assessments/YearSentToMinister", "year_to_be_sent_to_minister"],
  ["Cosewic/Assessments/DateSentToMinister", "date_sent_to_minister"],
  ["Cosewic/Assessments/Order", "order_of_assessment"],
  ["Cosewic/Assessments/Type", "assessment_type"],
  ["Cosewic/Assessments/ReportType", "type_of_report"],
  // ["Cosewic/Assessments/Stage", "stage_of_assessment"],
  ["Cosewic/Assessments/State", "ws_state"],
  ["Cosewic/Assessments/StatusAndCriteria/Status", "cosewic_ws_status"],
  [
    "Cosewic/Assessments/StatusAndCriteria/StatusComment/French",
    "assessment_status_criteria_status_comment, french",
  ],
  [
    "Cosewic/Assessments/StatusAndCriteria/StatusComment/English",
    "assessment_status_criteria_status_comment, english",
  ],
  ["Cosewic/Assessments/StatusAndCriteria/StatusChange", "status_change"],
  [
    "Cosewic/Assessments/StatusAndCriteria/StatusCriteria/French",
    "status_criteria, french",
  ],
  [
    "Cosewic/Assessments/StatusAndCriteria/StatusCriteria/English",
    "status_criteria, english",
  ],
  [
    "Cosewic/Assessments/StatusAndCriteria/ApplicabilityOfCriteria/Key",
    "assessment_status_criteria_applicability_criteria_key",
  ],
  [
    "Cosewic/Assessments/StatusAndCriteria/ApplicabilityOfCriteria/Value",
    "assessment_status_criteria_applicability_criteria_value",
  ],
  [
    "Cosewic/Assessments/Designation/HistoryStatus/French",
    "history_status_designation, french",
  ],
  [
    "Cosewic/Assessments/Designation/HistoryStatus/English",
    "history_status_designation, english",
  ],
  [
    "Cosewic/Assessments/Designation/Reason/French",
    "reason_designation, french",
  ],
  [
    "Cosewic/Assessments/Designation/Reason/English",
    "reason_designation, english",
  ],
]);

export function convertCosewicKeyToTag(key: string): string {
  return cosewicKeyToTag.get(key) ?? "Undefined key '" + key + "'";
}

export function convertCosewicKeyToTagWithTranslation(key: string): string {
  const value = cosewicKeyToTag.get(key);
  if (!value) return "Undefined key '" + key + "'";

  const strings = value.split(",");
  let result = i18n.t(strings[0].trim());
  if (strings[1]) result = result + ", " + i18n.t(strings[1].trim());
  if (strings[2]) result = result + ", " + i18n.t(strings[2].trim());

  return result;
}
