import { AddListingRelatedSpeciesListItemDocument } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ListingWs } from "../../../../generated/gql-types";
import MissingData from "../../../atoms/MissingData";
import { useQuery } from "@apollo/client";

interface AddListingRelatedSpeciesListItemProps {
  titleLink?: string;
  listingWs?: ListingWs;
  imgUrl?: string;
}

const AddListingRelatedSpeciesListItem: React.FC<
  AddListingRelatedSpeciesListItemProps
> = (props) => {
  const { t, i18n } = useTranslation();

  const details = useQuery(AddListingRelatedSpeciesListItemDocument, {
    skip: props.listingWs == null,
    variables: {
      id: props?.listingWs?.id ?? "",
    },
  });

  const name =
    i18n.language === "fr"
      ? props?.listingWs?.commonNameFrench?.name?.plainText
      : props?.listingWs?.commonNameEnglish?.name?.plainText;

  const population =
    i18n.language === "fr"
      ? props?.listingWs?.population?.nameFr
      : props?.listingWs?.population?.nameEn;

  const status = details.data?.listingWsProcessLatest?.schedule1Status ?? "";
  const stage = details.data?.listingWsProcessLatest?.stage ?? "";

  return (
    <div className="flex">
      <div className="flex-col width-100">
        <div className="flex justify-between">
          <div>
            {props.titleLink ? (
              <Link to={props.titleLink} className="hover-underline">
                <h2 className="mrgn-tp-0 mrgn-bttm-0">
                  {name ?? <MissingData />}
                </h2>
              </Link>
            ) : (
              <h2 className="mrgn-tp-0 mrgn-bttm-sm">
                {name ?? <MissingData />}
              </h2>
            )}
            {population == null ? null : (
              <div className="font-size-14 mrgn-bttm-md text-muted">
                {population}
              </div>
            )}
          </div>
          <div>{props.children}</div>
        </div>
        <div className="species-field row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("listing_status")}</dt>
              <dd className="font-size-16">{t(status)}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("listing_id")}</dt>
              <dd className="font-size-16">
                {props.listingWs?.listingId ?? <MissingData />}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("wildlife_species_stage")}</dt>
              <dd className="font-size-16">{t(stage)}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default AddListingRelatedSpeciesListItem;
