import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import { UseQueryResult } from "@tanstack/react-query";
import { resolveAzureEnvironment, RunEnvironment } from "azure/environment";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import React, { useCallback, useMemo, useState } from "react";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Field, formatQuery, QueryBuilder } from "react-querybuilder";
import { convertCosewicKeyToTagWithTranslation } from "util/queryTool/convertCosewicKeyToTag";
import { convertListingKeyToTagWithTranslation } from "util/queryTool/convertListingKeyToTag";
import { mapAndFormatQuery } from "../mappers";
import { QueryToolFormFields } from "../QueryToolForm";
import { CustomFieldSelector } from "./CustomFieldSelector";
import { CustomOperatorSelector } from "./CustomOperatorSelector";
import { CustomValueEditor } from "./CustomValueEditor";
import { buildClasses, buildTranslations } from "./QBSettings";
import "./QueryBuilder.scss";

type QueryField = {
  key: string;
  name: string;
  type: string;
  customMetadata: {
    isPrimitive: boolean;
    isEnum: boolean;
    isLookup: boolean;
    isClassification: boolean;
    hRef: string;
  };
};

type QueryOperator = {
  supportableTypes: string[];
  operatorTypes: string[];
};

export interface QueryFieldsListResponse {
  queryFieldsList: QueryField[];
}

export interface QueryOperatorsResponse {
  operators: QueryOperator[];
}

export interface SetConditionsSectionProps {
  initialValues: QueryToolFormFields;
  cosewicFieldsQuery: UseQueryResult<QueryFieldsListResponse, unknown>;
  listingFieldsQuery: UseQueryResult<QueryFieldsListResponse, unknown>;
  operatorsQuery: UseQueryResult<QueryOperatorsResponse, unknown>;
  getFieldTypeByKey: Function;
}

export const SetConditionsSection: React.FC<SetConditionsSectionProps> = ({
  initialValues,
  cosewicFieldsQuery,
  listingFieldsQuery,
  operatorsQuery,
  getFieldTypeByKey,
}) => {
  const { t, i18n } = useTranslation();
  const env = resolveAzureEnvironment();

  const form = useFormContext<any>(); //TODO: replace any
  const { control, register, watch, formState } = form;
  const { errors } = formState;

  const [query, setQuery] = useState(initialValues.conditionRules);

  const loadingQuery =
    cosewicFieldsQuery.isLoading ||
    listingFieldsQuery.isLoading ||
    operatorsQuery.isLoading;

  const errorQuery =
    cosewicFieldsQuery.isError ||
    listingFieldsQuery.isError ||
    operatorsQuery.isError;

  const buildCombinators = useCallback(() => {
    return [
      { name: "and", label: t("and") },
      { name: "or", label: t("or") },
    ];
  }, [t]);

  const buildOperatorsAndValueEditor = useCallback(
    (field: QueryField) => {
      const operators = operatorsQuery.data?.operators ?? [];
      let fieldOperatorType: string[] = [];
      let ovField: Partial<Field> = {}; // Operator and Value Editor fields

      if (field.customMetadata.isPrimitive) {
        // For the operator selector
        if (operators[0]?.supportableTypes.includes(field.type.toLowerCase()))
          fieldOperatorType = operators[0].operatorTypes;
        if (operators[1]?.supportableTypes.includes(field.type.toLowerCase()))
          fieldOperatorType = operators[1].operatorTypes;
        // For the value editor
        switch (field.type.toLowerCase()) {
          case "int32":
          case "int64":
            ovField.inputType = "number";
            break;
          case "datetimeoffset":
            ovField.inputType = "date";
            break;
          case "string":
          case "double":
          default:
            ovField.inputType = "string";
            break;
        }
      } else {
        let fieldType = "";
        if (field.customMetadata.isClassification) {
          fieldType = "classification";
        } else if (field.customMetadata.isEnum) {
          fieldType = "enum";
        } else if (field.customMetadata.isLookup) {
          fieldType = "lookup";
        } else {
          console.error("customMetaData - Missing non-primitive type");
        }

        if (fieldType !== "") {
          // For the operator selector
          if (operators[2]?.supportableTypes.includes(fieldType))
            fieldOperatorType = operators[2].operatorTypes;
          // For the value editor
          ovField.datatype = fieldType;
          ovField.hRef = field.customMetadata.hRef;
        }
      }

      const fieldOperators = fieldOperatorType.map((x) => ({
        name: x,
        label: t(x),
      }));

      ovField.operators = fieldOperators;
      return ovField;
    },
    [operatorsQuery.data?.operators, t]
  );

  const fields: Field[] = useMemo(() => {
    let _fields: Field[] = [];
    cosewicFieldsQuery.data?.queryFieldsList.forEach((x: QueryField) => {
      const CosewicOperatorsAndValueEditor: any =
        buildOperatorsAndValueEditor(x);
      _fields.push({
        program: "cosewic",
        name: x.key,
        label: convertCosewicKeyToTagWithTranslation(x.key),
        ...CosewicOperatorsAndValueEditor,
      });
    });
    listingFieldsQuery.data?.queryFieldsList.forEach((x: QueryField) => {
      const ListingOperatorsAndValueEditor: any =
        buildOperatorsAndValueEditor(x);
      _fields.push({
        program: "listing",
        name: x.key,
        label: convertListingKeyToTagWithTranslation(x.key),
        ...ListingOperatorsAndValueEditor,
      });
    });

    return _fields;
  }, [
    cosewicFieldsQuery.data?.queryFieldsList,
    listingFieldsQuery.data?.queryFieldsList,
    buildOperatorsAndValueEditor,
  ]);

  // const validator = (q: any) => q.rules.length > 0;

  return (
    <SectionCard
      header={
        <>
          <h2>{t("set_conditions")}</h2>
          {loadingQuery && <LoadingIndicator className="mrgn-lft-md" />}
        </>
      }
      collapsible
      open={false}
    >
      <div>
        {loadingQuery ? (
          <LoadingIndicator centered className="mrgn-tp-md mrgn-bttm-md" />
        ) : errorQuery ? null : (
          <>
            <Controller
              name={"conditionRules"}
              render={({ field }) => (
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    translations={buildTranslations()}
                    combinators={buildCombinators()}
                    onQueryChange={(q: any) => {
                      setQuery(q);
                      field.onChange(q);
                    }}
                    controlElements={{
                      fieldSelector: CustomFieldSelector,
                      operatorSelector: CustomOperatorSelector,
                      valueEditor: CustomValueEditor,
                    }}
                    controlClassnames={buildClasses()}
                    enableMountQueryChange={false} // needed for isDirty to work.
                    // enableDragAndDrop
                    addRuleToNewGroups
                    showCloneButtons
                    showCombinatorsBetweenRules
                    // validator={defaultValidator}
                    // validator={(q: any) => {
                    //   console.log(q.rules.length);
                    //   return true;
                    // }}
                    // debugMode
                  />
                </QueryBuilderDnD>
              )}
              control={control}
            />

            {(env === RunEnvironment.AZURE_TEST ||
              env === RunEnvironment.AZURE_DEV ||
              env === RunEnvironment.AZURE_ALTDEV ||
              env === RunEnvironment.LOCAL) && (
              <details className="mrgn-tp-md">
                <summary style={{ backgroundColor: "#fcf8e3" }}>
                  JSON Query - Show mapped query for debugging purposes
                </summary>
                <div className="row mrgn-tp-md">
                  <div className="col-sm-6">
                    <pre>
                      <h5 className="mrgn-tp-sm">JSON</h5>
                      <code>{formatQuery(query, "json")}</code>
                    </pre>
                  </div>
                  <div className="col-sm-6">
                    <pre>
                      <h5 className="mrgn-tp-sm">JSON (Mapped for API)</h5>
                      <code>
                        {mapAndFormatQuery(query, getFieldTypeByKey, t)}
                      </code>
                    </pre>
                  </div>
                </div>
              </details>
            )}
          </>
        )}
      </div>
    </SectionCard>
  );
};
