import FormButtons from "components/molecules/FormButtons/FormButtons";
import * as React from "react";
import {
  FieldNamesMarkedBoolean,
  SubmitHandler,
  UnpackNestedValue,
  set,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import {
  BilingualAbbreviationAcronymLookupText,
  Maybe,
  NoteInput,
} from "../../../../generated/gql-types";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import ProgramDropdownWithController from "../ProgramDropdown/ProgramDropdown";
import { htmlToPlainText } from "util/richtext";
import { MAX_LENGTH_OF_1K } from "config/constants";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { filterForDirtyFields } from "util/forms";
import { TextInput } from "components/atoms/forms/TextInput";

interface DistributionListFormProps {
  defaultValues?: DistributionListFormFields;
  isEditMode: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<DistributionListFormFields>;
}

const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<DistributionListFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<DistributionListFormFields>,
  initialValues?: DistributionListFormFields
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  if (
    dirtyFields?.note?.text &&
    htmlIsNullOrEmpty(formData?.note?.text?.text) &&
    !htmlIsNullOrEmpty(initialValues?.note?.text?.text)
  ) {
    set(formData, "note.text", null);
  }
};

const DistributionListForm: React.FC<DistributionListFormProps> = (props) => {
  const form = useForm<DistributionListFormFields>({
    defaultValues: props.defaultValues,
  });
  const { register, control, handleSubmit, formState } = form;
  const { isDirty, errors, isSubmitting, dirtyFields } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<DistributionListFormFields> = async (
    formData
  ) => {
    // Fix the issue bug #74338
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, props.defaultValues);

    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(FilteredFormData);
        resolve();
      }, 500);
    });
  };

  const validateHtmlTextMax1KLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_1K;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* -------------- Name in English -------------- */}
        <TextInput
          type="text"
          id="nameInput"
          label={t("name")}
          inputClassName="full-width"
          placeholder={t("name")}
          maxLength={100}
          required={true}
          {...register("name", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
          })}
          errors={errors}
        />
        {/* -------------- Programs -------------- */}
        {props.isEditMode ? (
          <div className="form-group">
            <label htmlFor="sel-program">{t("program")}</label>
            <ProgramDropdownWithController
              control={control}
              name="program"
              id="sel-program"
              data-testid="sel-program"
              disabled={true}
            />
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="sel-program" className="required">
              {t("program")}
              <strong className="required"> ({t("required")})</strong>
            </label>
            <ProgramDropdownWithController
              control={control}
              name="program"
              id="sel-program"
              data-testid="sel-program"
              required
            />
            {errors.program && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        )}

        {/* -------------- Note -------------- */}
        <div className="form-group">
          <label>{t("note")}</label>
          <FullHTMLEditorWithController
            control={control}
            rules={{
              validate: {
                maxLengthLimit: validateHtmlTextMax1KLengthLimit,
              },
            }}
            name="note.text.text"
            defaultValue={props?.defaultValues?.note?.text?.text ?? ""}
            id="note_text"
            maxLength={1000}
          />
          {errors.note &&
            (errors.note as any)?.text?.text?.type === "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_1K,
                })}
              </FieldValidationError>
            )}
        </div>
        <hr className="mrgn-tp-sm mrgn-bttm-md" />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default DistributionListForm;

export interface DistributionListFormFields {
  name?: Maybe<string>;
  program?: Maybe<BilingualAbbreviationAcronymLookupText>;
  note?: NoteInput;
}
