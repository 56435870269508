import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";
import { DocumentFormFields } from "./DocumentForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<DocumentFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<DocumentFormFields>,
  initialValues: Partial<DocumentFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  if (
    dirtyFields?.publicationType &&
    isNullOrEmpty(formData?.publicationType) &&
    !isNullOrEmpty(initialValues?.publicationType)
  ) {
    set(formData, "publicationType", null);
  }
  if (
    dirtyFields?.title?.english &&
    htmlIsNullOrEmpty(formData?.title?.english?.text) &&
    !htmlIsNullOrEmpty(initialValues?.title?.english?.text)
  ) {
    set(formData, "title.english", null);
  }
  if (
    dirtyFields?.title?.french &&
    htmlIsNullOrEmpty(formData?.title?.french?.text) &&
    !htmlIsNullOrEmpty(initialValues?.title?.french?.text)
  ) {
    set(formData, "title.french", null);
  }
  if (
    dirtyFields?.contentOwner &&
    isNullOrEmpty(formData?.contentOwner) &&
    !isNullOrEmpty(initialValues?.contentOwner)
  ) {
    set(formData, "contentOwner", null);
  }
  if (
    dirtyFields?.programs &&
    isNullOrEmpty(formData?.programs) &&
    !isNullOrEmpty(initialValues?.programs)
  ) {
    set(formData, "programs", null);
  }
  if (
    dirtyFields?.documentType &&
    isNullOrEmpty(formData?.documentType) &&
    !isNullOrEmpty(initialValues?.documentType)
  ) {
    set(formData, "documentType", null);
  }
  if (
    dirtyFields?.associatedSpecies &&
    isNullOrEmpty(formData?.associatedSpecies) &&
    !isNullOrEmpty(initialValues?.associatedSpecies)
  ) {
    set(formData, "associatedSpecies", null);
  }
  if (
    dirtyFields?.note?.text &&
    htmlIsNullOrEmpty(formData?.note?.text) &&
    !htmlIsNullOrEmpty(initialValues?.note?.text)
  ) {
    set(formData, "note", null);
  }
};
