import { OverlayContainer } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import ModalDialog from "components/molecules/Modal";
import { MAX_LENGTH_OF_ISSUES } from "config/constants";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { htmlToPlainText } from "util/richtext";
import { FullHTMLEditor } from "../../FullHTMLEditor";

export interface IssuesModalProps {
  modalState: OverlayTriggerState;
  defaultValue: string;
  onSave: (newValue: string) => void;
}

const IssuesModal: React.FC<IssuesModalProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const [value, setValue] = React.useState(props.defaultValue);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isMax, setIsMax] = React.useState(false);

  const onSave = () => {
    props.onSave(value);
    modalState.close();
    setIsDirty(false);
  };

  const onClose = () => {
    modalState.close();
    setIsDirty(false);
  };

  const onChange = (v: React.SetStateAction<string>) => {
    setValue(v);
    setIsDirty(true);
    setIsMax(htmlToPlainText(v as string).length > MAX_LENGTH_OF_ISSUES);
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("sector_issues")}
        isOpen
        onClose={onClose}
        isDismissable
      >
        <FullHTMLEditor
          defaultValue={props.defaultValue}
          id="issues_modal"
          placeholder={t("issues")}
          onChange={onChange}
        />
        {isMax ? (
          <FieldValidationError>
            {t("reach_the_max_length", {
              count: MAX_LENGTH_OF_ISSUES,
            })}
          </FieldValidationError>
        ) : null}
        <button
          className="btn btn-sm btn-primary mrgn-tp-md"
          onClick={onSave}
          disabled={!isDirty || isMax}
          data-testid="modal-button-save"
        >
          {t("save")}
        </button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export const useIssuesModal = useOverlayTriggerState;

export default IssuesModal;
