import * as React from "react";
import { union, xor } from "lodash";

export const useCosewicPaginatedRowSelect = <
  T extends { cosewicWs: { id: string | null | undefined } }
>(
  rowItems?: T[],
  defaultSelectedIds?: string[]
) => {
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>(
    (defaultSelectedIds as any) ?? []
  );
  const [selectedRowIdsCache, setSelectedRowIdsCache] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    if (!defaultSelectedIds || defaultSelectedIds.length === 0) return;
    setSelectedRowIds(defaultSelectedIds as any);
  }, [defaultSelectedIds]);

  const rowItemsValid = rowItems != null && rowItems.length > 0;

  const currentPageIds = React.useMemo(() => {
    if (!rowItemsValid) {
      return [];
    }

    return rowItems?.map((item) => item?.cosewicWs?.id ?? "EMPTY") ?? [];
  }, [rowItems, rowItemsValid]);

  const selectAllEnabled = rowItemsValid
    ? currentPageIds.every((x) => selectedRowIds.includes(x))
    : false;

  const onToggleSelectAll = () => {
    if (!rowItemsValid) {
      return false;
    }

    if (selectAllEnabled) {
      setSelectedRowIds(selectedRowIdsCache);
    } else {
      setSelectedRowIds(union(selectedRowIds, currentPageIds));
    }
  };

  const onToggleRow = (id?: string) => {
    if (id == null) return;
    const newIds = xor(selectedRowIds, [id]);
    setSelectedRowIds(newIds);
    if (!selectAllEnabled) {
      setSelectedRowIdsCache(newIds);
    }
  };

  return {
    onToggleRow,
    onToggleSelectAll,
    selectedRowIds,
    selectAllEnabled,
  };
};

export default useCosewicPaginatedRowSelect;
