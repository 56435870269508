import EditLink from "components/atoms/EditLink";
import MissingData from "components/atoms/MissingData";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "util/isNullOrEmpty";
import {
  RenderWhenAuthorized,
  ROLE_ACTIONS,
} from "../../../features/auth/components";
import { CosewicWs } from "../../../generated/gql-types";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";
import SectionCard from "../../atoms/SectionCard";

export interface WSInfoCardProps {
  cosewicWs?: CosewicWs;
  loading?: boolean;
}

export const WSInfoCard: React.FC<WSInfoCardProps> = (props) => {
  const { cosewicWs, loading } = props;
  const { t, i18n } = useTranslation();

  const taxonomicGroupString =
    i18n.language === "fr"
      ? cosewicWs?.taxonomicGroup?.nameFr
      : cosewicWs?.taxonomicGroup?.nameEn;

  const rangesString =
    i18n.language === "fr"
      ? cosewicWs?.ranges?.map((x) => x?.nameFr).join(", ")
      : cosewicWs?.ranges?.map((x) => x?.nameEn).join(", ");

  const scientificName = cosewicWs?.scientificName;
  const formattedScientificName =
    scientificName?.generatedScientificName?.name?.text;

  const otherRecognizedCommonNameEnglish =
    cosewicWs?.otherRecognizedCommonNameEnglish;
  const otherRecognizedCommonNameFrench =
    cosewicWs?.otherRecognizedCommonNameFrench;
  const otherRecognizedScientificName =
    cosewicWs?.otherRecognizedScientificName;
  const indigenousName = cosewicWs?.indigenousName;

  const showTooltipAlertForScientificName =
    cosewicWs?.scientificName?.species?.id == null;

  const showTooltipAlertForCommonNameFrench =
    cosewicWs?.commonNameFrench == null;

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("wildlife_species_information")}</h2>
          <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.cosewic.update}>
            {!loading ? (
              <EditLink
                linkTo={CosewicPathHelpers.CosewicEdit(
                  cosewicWs?.id ?? "error"
                )}
              />
            ) : null}
          </RenderWhenAuthorized>
        </div>
      }
    >
      <div className="species-field">
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("scientific_name")}
                <TooltipAlert isVisible={showTooltipAlertForScientificName}>
                  {t("species_required_to_make_ws_permanent_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {htmlIsNullOrEmpty(formattedScientificName) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={formattedScientificName!} />
                )}
              </dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("taxonomicgroup")}</dt>
              <dd>{taxonomicGroupString ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("common_name")} ({t("english")})
              </dt>
              <dd>
                {htmlIsNullOrEmpty(cosewicWs?.commonNameEnglish?.name?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={cosewicWs?.commonNameEnglish?.name?.text!}
                  />
                )}
              </dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("common_name")} ({t("french")})
                <TooltipAlert isVisible={showTooltipAlertForCommonNameFrench}>
                  {t("required_to_make_ws_permanent_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {htmlIsNullOrEmpty(cosewicWs?.commonNameFrench?.name?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={cosewicWs?.commonNameFrench?.name?.text!}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("population")} ({t("english")})
              </dt>
              <dd>{cosewicWs?.population?.nameEn ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("population")} ({t("french")})
              </dt>
              <dd>{cosewicWs?.population?.nameFr ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("name_with_comma")}</dt>
              <dd>
                {htmlIsNullOrEmpty(cosewicWs?.nameWithComma?.name?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={cosewicWs?.nameWithComma?.name?.text!}
                  />
                )}
              </dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("endemic_status")}</dt>
              <dd>
                {cosewicWs?.endemicStatus ? (
                  t(cosewicWs?.endemicStatus)
                ) : (
                  <MissingData />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("range")}</dt>
              <dd>
                {isNullOrEmpty(rangesString) ? <MissingData /> : rangesString}
              </dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("cosewic_id")}</dt>
              <dd>{cosewicWs?.cosewicId ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("other_recognized_english_common_names")}
              </dt>
              <dd>
                {!otherRecognizedCommonNameEnglish ||
                otherRecognizedCommonNameEnglish.length === 0 ? (
                  <MissingData />
                ) : (
                  otherRecognizedCommonNameEnglish?.map((item, i) => (
                    <span className="semicolon" key={i}>
                      <SafeRenderHtml htmlString={item?.text ?? "error"} />
                    </span>
                  ))
                )}
              </dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("other_recognized_french_common_names")}
              </dt>
              <dd>
                {!otherRecognizedCommonNameFrench ||
                otherRecognizedCommonNameFrench.length === 0 ? (
                  <MissingData />
                ) : (
                  otherRecognizedCommonNameFrench?.map((item, i) => (
                    <span className="semicolon" key={i}>
                      <SafeRenderHtml htmlString={item?.text ?? "error"} />
                    </span>
                  ))
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("other_recognized_scientific_names")}
              </dt>
              <dd>
                {!otherRecognizedScientificName ||
                otherRecognizedScientificName.length === 0 ? (
                  <MissingData />
                ) : (
                  otherRecognizedScientificName?.map((item, i) => (
                    <span className="semicolon" key={i}>
                      <SafeRenderHtml htmlString={item?.text ?? "error"} />
                    </span>
                  ))
                )}
              </dd>
            </div>
          </dl>
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("indigenous_names")}</dt>
              <dd>
                {!indigenousName || indigenousName.length === 0 ? (
                  <MissingData />
                ) : (
                  indigenousName?.map((item, i) => (
                    <span className="semicolon" key={i}>
                      <SafeRenderHtml htmlString={item?.text ?? "error"} />
                    </span>
                  ))
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default WSInfoCard;
