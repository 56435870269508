import {
  CreateLandInput,
  Land,
  Maybe,
  UpdateLandInput,
} from "generated/gql-types";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import { AdminLandsFormFields } from "./AdminLandsForm";

export function mapDomainModelToForm(model?: Partial<Maybe<Land>>) {
  const out: Partial<AdminLandsFormFields> = {};
  if (!model) return out;

  if (model.landType) out.landType = model.landType;
  if (model.provinceAndTerritory)
    out.provinceAndTerritory = model.provinceAndTerritory;
  if (model.landName) out.landName = model.landName;
  if (model.landManager) out.landManager = model.landManager;
  if (model.dfrp) out.dfrp = parseInt(String(model.dfrp));
  if (model.landSize) out.landSize = parseFloat(String(model.landSize));
  if (model.note) out.note = model.note;

  return out;
}
export function mapFormToCreateInput(form: Partial<AdminLandsFormFields>) {
  const out: Partial<CreateLandInput> = {};

  if (form.landType) out.landType = form.landType;
  if (form.provinceAndTerritory)
    out.provinceAndTerritory = form.provinceAndTerritory;
  if (form.landName) out.landName = form.landName;
  if (form.landManager) out.landManager = form.landManager;
  if (form.dfrp) out.dfrp = parseInt(String(form.dfrp));
  if (form.landSize) out.landSize = parseFloat(String(form.landSize));
  if (form.note) out.note = form.note;
  return out;
}

export function mapFormToUpdateInput(form: Partial<AdminLandsFormFields>) {
  const out: Partial<UpdateLandInput> = {};

  if (form.landType) out.landType = form.landType;
  if (form.provinceAndTerritory)
    out.provinceAndTerritory = form.provinceAndTerritory;
  if (form.landName) out.landName = form.landName;
  if (form.landManager) out.landManager = form.landManager;
  if (form.dfrp || form.dfrp?.toString() === "")
    out.dfrp = String(form.dfrp) === "" ? null : parseInt(String(form.dfrp));
  if (form.landSize || form.landSize?.toString() === "")
    out.landSize =
      String(form.landSize) === "" ? null : parseFloat(String(form.landSize));
  if (form.note) {
    if (htmlIsNullOrEmpty(form.note?.text?.text)) {
      out.note = null;
    } else {
      out.note = form.note;
    }
  }

  return out;
}
