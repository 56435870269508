import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { BptTemplateStep, Maybe } from "generated/gql-types";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";

export interface StepInformationProps {
  templateId: string;
  stepInfo?: Maybe<BptTemplateStep>;
}

export const StepInformation: React.FC<StepInformationProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="species-field font-size-18">
        {/* ------------ Step description ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-8">
              <dt className="text-muted">{t("description")}</dt>
              <dd>
                {props.stepInfo?.description == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.stepInfo?.description.text ?? ""}
                  />
                )}
              </dd>

              <dt className="text-muted">{t("step_owner")}</dt>
              <dd>
                {props.stepInfo?.owner?.text == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.stepInfo?.owner.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>

          <dl>
            <div>
              {/* ------------ Step estimated duration ------------ */}
              <div className="species-data col-sm-4">
                <dt className="text-muted">
                  {t("estimated_duration")}
                  {props.stepInfo?.estimatedDuration == null ? (
                    <TooltipAlert isVisible={true}>
                      {t("can_not_be_calculated_tooltip")}
                    </TooltipAlert>
                  ) : (
                    ""
                  )}
                </dt>
                <dd>
                  {props.stepInfo?.estimatedDuration == null ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        props.stepInfo?.estimatedDuration +
                        " " +
                        t("business_days")
                      }
                    />
                  )}
                </dd>
              </div>

              {/* ------------ Step include house of common holiday ------------ */}
              <div className="species-data col-sm-4">
                <dt className="text-muted">
                  {t("include_house_of_common_holidays")}
                </dt>
                <dd>
                  {props.stepInfo?.includeHouseOfCommons == null ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        props.stepInfo?.includeHouseOfCommons
                          ? t("yes")
                          : t("no")
                      }
                    />
                  )}
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default StepInformation;
