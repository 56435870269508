import isNullOrEmpty from "util/isNullOrEmpty";
import MissingData from "components/atoms/MissingData";
import { bilingualTextNameForLanguage } from "mappers";
import * as React from "react";
import { NaicsDetails } from "generated/gql-types";
import { useTranslation } from "react-i18next";

export interface NAICSSectorDetailsProps {
  naicsCodeDetails?: NaicsDetails | null;
}

export const NAICSSectorDetails: React.FC<NAICSSectorDetailsProps> = (
  props
) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{`${t("sector")}`}</dt>
            <dd>
              {isNullOrEmpty(props.naicsCodeDetails?.sector) ? (
                <MissingData />
              ) : (
                <p>
                  {props.naicsCodeDetails?.sector?.code} -{" "}
                  {bilingualTextNameForLanguage(
                    i18n.language,
                    props.naicsCodeDetails?.sector?.className
                  )}{" "}
                </p>
              )}
            </dd>
          </div>
        </dl>
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{`${t("subsectors")}`}</dt>
            <dd>
              {isNullOrEmpty(props.naicsCodeDetails?.subSector) ? (
                <MissingData />
              ) : (
                <p>
                  {props.naicsCodeDetails?.subSector?.code} -{" "}
                  {bilingualTextNameForLanguage(
                    i18n.language,
                    props.naicsCodeDetails?.subSector?.className
                  )}
                </p>
              )}
            </dd>
          </div>
        </dl>
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{`${t("industry_groups")}`}</dt>
            <dd>
              {isNullOrEmpty(props.naicsCodeDetails?.industryGroup) ? (
                <MissingData />
              ) : (
                <p>
                  {props.naicsCodeDetails?.industryGroup?.code} -{" "}
                  {bilingualTextNameForLanguage(
                    i18n.language,
                    props.naicsCodeDetails?.industryGroup?.className
                  )}
                </p>
              )}
            </dd>
          </div>
        </dl>
      </div>

      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{`${t("industries")}`}</dt>
            <dd>
              {isNullOrEmpty(props.naicsCodeDetails?.industry) ? (
                <MissingData />
              ) : (
                <p>
                  {props.naicsCodeDetails?.industry?.code} -{" "}
                  {bilingualTextNameForLanguage(
                    i18n.language,
                    props.naicsCodeDetails?.industry?.className
                  )}
                </p>
              )}
            </dd>
          </div>
        </dl>
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{`${t("canadian_industries")}`}</dt>
            <dd>
              {isNullOrEmpty(props.naicsCodeDetails?.canadianIndustry) ? (
                <MissingData />
              ) : (
                <p>
                  {props.naicsCodeDetails?.canadianIndustry?.code} -{" "}
                  {bilingualTextNameForLanguage(
                    i18n.language,
                    props.naicsCodeDetails?.canadianIndustry?.className
                  )}
                </p>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};

export default NAICSSectorDetails;
