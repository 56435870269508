import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmSavePopupProps {
  modalState: OverlayTriggerState;
  onConfirmSave: () => void;
}

const ConfirmSavePopup: React.FC<ConfirmSavePopupProps> = (props) => {
  const { t } = useTranslation();

  const onConfirm = () => {
    props.onConfirmSave();
    props.modalState.close();
  };

  const onClose = () => {
    props.modalState.close();
  };

  if (!props.modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("confirm_save")}
        isOpen
        onClose={onClose}
        showModalFooter={true}
        extraButtons={
          <button
            type="button"
            className="btn btn-sm btn-primary pull-left mrgn-rght-sm"
            title="confirm_save"
            data-testid="modal-button-confirm"
            onClick={onConfirm}
          >
            {t("confirm")}
          </button>
        }
      >
        <Alert
          type={AlertTypes.WARNING}
          title={t("changes_publish_on_registry_warning_title")}
          content={t("changes_publish_on_registry_confirm_warning_message")}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default ConfirmSavePopup;

export const useConfirmSaveModalState = useOverlayTriggerState;
