import { OverlayContainer } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import type { ReactElement } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ModalDialog from "../../molecules/Modal";

export interface ConfirmationModalProps {
  /**
   * The modal state; an instance of `useConfirmationModal({})` which is exported from this file.
   */
  modalState: OverlayTriggerState;
  /**
   * Title of the modal. Can be a string or a React component.
   */
  title: string | ReactElement;
  /**
   * Whether or not to invoke 'onCancel' when the modal is closed.
   * Defaults to true.
   */
  cancelOnClose?: boolean;
  /**
   * False disables the confirm button.
   * Useful when rendering a confirmation checkbox as children.
   * Defaults to true.
   */
  confirmBtnEnabled?: boolean;
  /**
   * Callback for clicking on "Confirm"
   */
  onConfirm: () => void;
  /**
   * Callback for clicking on "Cancel"
   */
  onCancel: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    modalState,
    title,
    cancelOnClose = true,
    confirmBtnEnabled = true,
    onConfirm,
    onCancel,    
  } = props;

  if (!modalState.isOpen) return null;

  const onClose = () => {
    if (cancelOnClose) onCancel();
    modalState.close();
  };

  const onConfirmAndClose = () => {
    onConfirm();
    modalState.close();
  };

  // Note: Updated button label from Confirm to Submit.
  // According to the WET Style Guide, 'Do not use the word "Confirm" as a button label'
  // https://wet-boew.github.io/wet-boew-styleguide/design/buttons-en.html

  const ExtraButtons = (
    <button
      type="button"
      disabled={!confirmBtnEnabled}
      className="btn btn-sm btn-primary pull-left popup-modal-dismiss mrgn-rght-sm"
      title={t("submit")}
      aria-controls="confirm_modal"
      onClick={onConfirmAndClose}
      data-testid="extra-button-submit"
    >
      {t("submit")}
      <span className="wb-inv">{t("submit")}</span>
    </button>
  );

  return (
    <OverlayContainer>
      <ModalDialog
        id="confirm_modal"
        title={title || t("submit")}
        isOpen
        onClose={onClose}
        isDismissable
        extraButtons={ExtraButtons}        
      >
        {props.children}
      </ModalDialog>
    </OverlayContainer>
  );
};

export default ConfirmationModal;

export const useConfirmationModal = useOverlayTriggerState;
