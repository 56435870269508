const cosewicEnumTranslation = {
  en: {
    // Assessment Type
    REGULAR: "Regular",
    EMERGENCY: "Emergency",

    // Type of Report
    EXISTING: "Existing",
    EXISTING_ADDENDUM: "Existing + Addendum",
    EXISTING_MODIFIED: "Existing + Modified",
    EXISTING_RAPID: "Existing + Rapid review of classification",
    STATUS_APPRAISAL_SUMMARY: "Existing + Status appraisal summary",
    SUPPORTING_INFO: "Supporting information (no report)",
    UPDATED: "Updated",
    NOT_INITIALIZED: "No data",

    // Assessment Stage
    DRAFT: "Draft",
    ASSESSED: "Assessed",
    FINAL: "Final",
    APPROVED: "Approved",

    // CosewicWs or Assessment State
    PUBLISHED: "Published",
    NOT_PUBLISHED: "Not published",
    PUBLISHING: "Publish",
    PUBLISH_FAILED: "Publish failed",
    UNPUBLISHING: "Unpublish",
    UNPUBLISH_FAILED: "Unpublish failed",

    // WSStatus
    DATA_DEFICIENT: "Data Deficient",
    DEFERRED_REJECT_WITHDRAWN: "Deferred/ rejected/ withdrawn",
    ENDANGERED: "Endangered",
    EXTINCT: "Extinct",
    EXTIRPATED: "Extirpated",
    NON_ACTIVE: "Non-active",
    SPECIAL_CONCERN: "Special concern",
    THREATENED: "Threatened",
    NO_STATUS: "No status",
    NOT_AT_RISK: "Not at risk",
    PERMANENT: "Permanent",

    // Status Change
    NOT_APPLICABLE: "Not applicable",
    CHANGED: "Changed",
    DEACTIVATED: "Deactivated",
    IN_HIGHER_RISK_CATEGORY: "Higher risk category",
    IN_LOWER_RISK_CATEGORY: "Lower risk category",
    NEW: "New",
    NO_CHANGE: "No change",
    NO_LONGER_AT_RISK: "No longer at risk",
    REASSIGNED_AND_REASSESSED: "Reassigned",

    // CosewicWsState
    LIVE: "Published",
    CANCELLED: "Cancelled",
    INACTIVE: "Inactive",

    // CosewicEndemicStatus
    YES: "Yes",
    NO: "No",
    NOT_DETERMINED: "Not determined",
  },

  fr: {
    // Assessment Type
    REGULAR: "Régulier",
    EMERGENCY: "Urgence",

    // Type of Report
    EXISTING: "Existant",
    EXISTING_ADDENDUM: "Existant + addenda",
    EXISTING_MODIFIED: "Existant + modifié",
    EXISTING_RAPID: "Existant + révision rapide de la classification",
    STATUS_APPRAISAL_SUMMARY: "Existant + sommaire du statut de l’espèce",
    SUPPORTING_INFO: "Informations à l'appui (pas de rapport)",
    UPDATED: "Mis à jour",
    NOT_INITIALIZED: "Aucune donnée",

    // Assessment Stage
    DRAFT: "Ébauche",
    ASSESSED: "Évaluée",
    FINAL: "Final",
    APPROVED: "Approuvé",

    // CosewicWs or Assessment State
    PUBLISHED: "Publié",
    NOT_PUBLISHED: "Non publié",
    PUBLISHING: "Publier",
    PUBLISH_FAILED: "Échec de la publication",
    UNPUBLISHING: "Annuler la publication",
    UNPUBLISH_FAILED: "L'annulation de la publication a échoué",

    // WSStatus
    DATA_DEFICIENT: "Données insuffisantes",
    DEFERRED_REJECT_WITHDRAWN: "Reporté/ rejeté/ retiré",
    ENDANGERED: "En voie de disparition",
    EXTINCT: "Disparue",
    EXTIRPATED: "Disparue du pays",
    NON_ACTIVE: "Non active",
    SPECIAL_CONCERN: "Préoccupante",
    THREATENED: "Menacée",
    NO_STATUS: "Aucun statut",
    NOT_AT_RISK: "Non en péril",
    PERMANENT: "Permanent",

    // Status Change
    NOT_APPLICABLE: "Sans objet",
    CHANGED: "Modification",
    DEACTIVATED: "Désactivation",
    IN_HIGHER_RISK_CATEGORY: "Catégorie de risque plus élevé",
    IN_LOWER_RISK_CATEGORY: "Catégorie de risque moins élevée",
    NEW: "Nouvelle",
    NO_CHANGE: "Aucune modification",
    NO_LONGER_AT_RISK: "N’est plus en péril",
    REASSIGNED_AND_REASSESSED: "Réaffecté",

    // CosewicWsState
    LIVE: "Publié",
    CANCELLED: "Annulé",
    INACTIVE: "Inactif",

    // CosewicEndemicStatus
    YES: "Oui",
    NO: "Non",
    NOT_DETERMINED: "Non déterminé",
  },
};

export default cosewicEnumTranslation;
