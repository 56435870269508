import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SectionCard from "../../../atoms/SectionCard";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import { ExpectedImpactDropdownWithController } from "../ExpectedImpactDropdown/ExpectedImpactDropdown";
import { ListingFormChangeTracking, ListingFormFields } from "./index";

export interface OverallAssessmentFormSection {
  initialValues: ListingFormFields;
  open: boolean;
}

export const OverallAssessmentSection: React.FC<
  OverallAssessmentFormSection
> = (props) => {
  const { initialValues, open } = props;
  const { t } = useTranslation();

  const { control } = useFormContext<
    ListingFormFields & ListingFormChangeTracking
  >();

  return (
    <SectionCard
      collapsible
      open={open}
      header={<h2>{t("overall_assessment")}</h2>}
    >
      <div className="flex-col gap-md">
        {/* EXPECTED IMPACT ON FEDERAL LANDS */}

        <div className="flex-col gap-md">
          <div className="flex-col">
            <label htmlFor="expectedImpactFederalLandsInput">
              {t("expected_impact_on_federal_lands")}
            </label>
            <ExpectedImpactDropdownWithController
              control={control}
              name="overallAssessment.expectedImpactOnFederalLands"
              id="expectedImpactFederalLandsInput"
              placeholder={t("select_a_value")}
              defaultValue={
                initialValues?.overallAssessment?.expectedImpactOnFederalLands
              }
            />
          </div>

          {/* EXPECTED IMPACT ON FEDERAL LANDS RATIONALE */}

          <div>
            <label htmlFor="expectedImpactOnFederalLandsRationaleInput">
              {t("expected_impact_on_federal_lands_rationale")}
            </label>
            <FullHTMLEditorWithController
              control={control}
              defaultValue={
                initialValues.overallAssessment
                  .expectedImpactOnFederalLandsRationale?.text ?? ""
              }
              id="expectedImpactOnFederalLandsRationaleInput"
              name="overallAssessment.expectedImpactOnFederalLandsRationale.text"
              maxLength={500} // TODO: should be words not characters
            />
          </div>
        </div>

        {/* EXPECTED IMPACT ON NON FEDERAL LANDS */}

        <div className="flex-col gap-md">
          <div className="flex-col">
            <label htmlFor="expectedImpactNonFederalLandsInput">
              {t("expected_impact_on_non_federal_lands")}
            </label>
            <ExpectedImpactDropdownWithController
              control={control}
              name="overallAssessment.expectedImpactOnNonFederalLands"
              id="expectedImpactNonFederalLandsInput"
              placeholder={t("select_a_value")}
              defaultValue={
                initialValues?.overallAssessment
                  ?.expectedImpactOnNonFederalLands
              }
            />
          </div>

          {/* EXPECTED IMPACT ON NON FEDERAL LANDS RATIONALE */}

          <div>
            <label htmlFor="expectedImpactOnNonFederalLandsRationaleInput">
              {t("expected_impact_on_non_federal_lands_rationale")}
            </label>
            <FullHTMLEditorWithController
              control={control}
              defaultValue={
                initialValues.overallAssessment
                  .expectedImpactOnNonFederalLandsRationale?.text ?? ""
              }
              id="expectedImpactOnNonFederalLandsRationaleInput"
              name="overallAssessment.expectedImpactOnNonFederalLandsRationale.text"
              maxLength={500} // TODO: should be words not characters
            />
          </div>
        </div>
      </div>
    </SectionCard>
  );
};
