import {
  AutocompleteForLandNameDocument,
  Land,
  LandType,
  ProvinceAndTerritory,
} from "generated/gql-types";
import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { useEffect, useMemo } from "react";
import makeQueryStringWithoutEncoding from "util/makeQueryStringWithoutEncoding";
import pDebounce from "p-debounce";
import { formatPageNumber } from "util/formatPageNumber";
import { SEARCH_DEBOUNCE_TIME_MS } from "config/constants";
import { useAsyncList } from "@react-stately/data";
import { isString, uniqBy } from "lodash";
import Autocomplete from "components/molecules/Autocomplete";
import { Item } from "@react-stately/collections";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";

export interface LandNameAutocompleteProps {
  // landType and provinceAndTerritory are used in the land name search query
  landType: LandType;
  provinceAndTerritory: ProvinceAndTerritory;
  initialLandName?: string;
  initialSearchString?: string;
  onSelectionChange: (newLand: Land) => void;
  id: string;
  label: string;
  isRequired?: boolean;
  onClear?: () => void;
  disabled?: boolean;
}

export const LandNameAutocomplete: React.FC<LandNameAutocompleteProps> = ({
  label,
  initialSearchString,
  provinceAndTerritory,
  initialLandName,
  landType,
  id,
  isRequired,
  ...props
}) => {
  const client = useApolloClient();
  const landNameFiltersQueryString = useMemo(() => {
    const filters = new URLSearchParams();
    if (landType) filters.set("landType", landType);
    if (provinceAndTerritory)
      filters.set("provinceAndTerritory", provinceAndTerritory);
    return makeQueryStringWithoutEncoding(filters);
  }, [landType, provinceAndTerritory]);

  const debouncedSearch = React.useMemo(() => {
    return pDebounce((pageNumber: number, filterText: string) => {
      return client.query({
        query: AutocompleteForLandNameDocument,
        variables: {
          params: {
            pageNumber: formatPageNumber(pageNumber),
            search: filterText.length > 0 ? filterText : "",
            filters: landNameFiltersQueryString,
            pageSize: 50,
          },
        },
      });
    }, SEARCH_DEBOUNCE_TIME_MS);
  }, [landNameFiltersQueryString]);

  const list = useAsyncList<any>({
    async load({ cursor, filterText }: any) {
      // check for -1 to halt fetching the next page
      if (cursor === "-1") return { items: [], cursor: "-1" };

      const results = await debouncedSearch(cursor, filterText);
      const items = results?.data?.autocompleteData?.autocompleteItems ?? [];

      const uniqItems = uniqBy(items, "landName");

      if (results?.data?.autocompleteData?.pagination?.hasNextPage === false) {
        return {
          items, // return the final set of items
          cursor: "-1", // Use -1 to halt fetching
        };
      }

      const newPageNumber =
        formatPageNumber(
          results?.data?.autocompleteData?.pagination?.currentPage
        ) + 1;

      return {
        items: uniqItems,
        cursor: newPageNumber.toString(),
      };
    },
  });

  useEffect(() => {
    list.reload();
  }, [provinceAndTerritory, landType]);

  const onSelectionChange = (key: string | number) => {
    const item = list.getItem(key);
    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <>
      <Autocomplete
        id={id}
        label={label}
        items={list.items}
        inputValue={list.filterText}
        onInputChange={list.setFilterText}
        loadingState={list.loadingState}
        placeholder={formatName(initialLandName)}
        onLoadMore={list.loadMore}
        onSelectionChange={onSelectionChange}
        onClear={onClear}
        isRequired={isRequired}
        disabled={props.disabled}
      >
        {(item) => <Item key={item.id}>{formatName(item)}</Item>}
      </Autocomplete>
    </>
  );
};

export interface LandNameAutocompleteWithControllerProps<TFieldValues>
  extends Omit<LandNameAutocompleteProps, "onSelectionChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const LandNameAutoCompleteWithController = <TFieldValues,>({
  label,
  initialSearchString,
  provinceAndTerritory,
  landType,
  id,
  isRequired,
  onClear,
  ...props
}: LandNameAutocompleteWithControllerProps<TFieldValues>) => {
  return (
    <>
      <Controller
        {...props}
        render={({ field: { value, onChange } }) => (
          <LandNameAutocomplete
            landType={landType}
            provinceAndTerritory={provinceAndTerritory}
            onSelectionChange={onChange}
            initialLandName={formatName(value as any)}
            onClear={onClear}
            isRequired={isRequired}
            id={id}
            label={label}
          />
        )}
      />
    </>
  );
};

const formatName = (x?: null | string | Land) => {
  if (x == null) return "";
  if (isString(x)) return x;
  return `${x.landName}`;
};
