import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import { BilingualAbbreviationAcronymLookupText } from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import FederalParksAutocomplete from "./FederalParksAutocomplete";
import UseParksCanadaAutocomplete from "./useFederalCanadaPlacesAutocomplete";

export interface FederalParksMultiSelectListProps {
  value: Array<BilingualAbbreviationAcronymLookupText>;
  onChange: (listItems: Array<BilingualAbbreviationAcronymLookupText>) => void;
  disabled?: boolean;
}

export const FederalParksMultiSelectList: React.FC<
  FederalParksMultiSelectListProps
> = (props) => {
  const { value, onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    BilingualAbbreviationAcronymLookupText | undefined
  >(undefined);
  const parksCanadaAutocompleteInstance = UseParksCanadaAutocomplete();

  const parksCanadaNameForLanguage = React.useCallback(
    (item: BilingualAbbreviationAcronymLookupText) => {
      if (i18n.language === "fr") {
        return {
          id: item?.id,
          name: item?.name?.french,
          acronym: item.acronym,
          abbreviation: item?.abbreviation?.french,
        };
      }

      return {
        id: item?.id,
        name: item?.name?.english,
        acronym: item.acronym,
        abbreviation: item?.abbreviation?.english,
      };
    },
    [i18n.language]
  );

  const onRemoveFromList = (id: string) => {
    const newList = value.filter((x) => x.id !== id);
    onChange(newList);
  };

  const onSelectionChange = (
    newParkCanadaList?: BilingualAbbreviationAcronymLookupText | undefined
  ) => {
    setSelectedSearchItem(newParkCanadaList);
    parksCanadaAutocompleteInstance.clear();
  };
  const isFound = value.some((x) => x.id === selectedSearchItem?.id);

  React.useEffect(() => {
    if (value && selectedSearchItem && !isFound) {
      onChange([...value, selectedSearchItem]);
    }
  }, [isFound, onChange, selectedSearchItem, value]);

  React.useEffect(() => {
    if (selectedSearchItem) {
      setSelectedSearchItem(undefined);
    }
  }, [selectedSearchItem]);

  return (
    <div>
      <div className="flex gap-sm align-end">
        <FederalParksAutocomplete
          onSelectionChange={onSelectionChange}
          label={t("parks_canada_places")}
          useFederalCanadaPlacesAutocompleteInstance={
            parksCanadaAutocompleteInstance
          }
          disabled={props.disabled}
        />
      </div>

      <ul className="multi-select-list">
        {value.map((x) => {
          return (
            <MultiSelectListItem
              id={x.id as string}
              name={parksCanadaNameForLanguage(x)?.name ?? "error"}
              onRemove={() => onRemoveFromList(x.id as string)}
              key={x.id}
              disabled={props.disabled}
            />
          );
        })}
      </ul>
    </div>
  );
};

export interface FederalParksMultiSelectListWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<FederalParksMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
  disabled?: boolean | undefined;
}

export const FederalParksMultiSelectListWithController = <
  TFieldValues extends FieldValues
>(
  props: FederalParksMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <FederalParksMultiSelectList
          value={value as Array<BilingualAbbreviationAcronymLookupText>}
          onChange={onChange}
          disabled={props.disabled}
        />
      )}
    />
  );
};

export default FederalParksMultiSelectList;
