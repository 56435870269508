import { useQuery } from "@apollo/client";
import {
  BilingualAbbreviationLookupTextInput,
  PermitDescriptionIssuingAuthorityDropdownDocument,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { formatPageNumber } from "../../../../util/formatPageNumber";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import LoadingIndicator from "../../../atoms/LoadingIndicator";

export interface PermitDescriptionIssuingAuthorityDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  onChange: (value: BilingualAbbreviationLookupTextInput) => void;
  disabled?: boolean;
}

export const PermitDescriptionIssuingAuthorityDropdown = React.forwardRef<
  HTMLSelectElement,
  PermitDescriptionIssuingAuthorityDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(
    PermitDescriptionIssuingAuthorityDropdownDocument,
    {
      variables: {
        params: {
          pageNumber: formatPageNumber(1),
          pageSize: 50,
        },
      },
    }
  );
  const { t, i18n } = useTranslation();

  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget?.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.permitIssuingAuthorityList?.lookups?.find(
      (x) => x?.id === id
    );

    if (!value) {
      console.error(
        `permitDescriptionIssuingAuthority couldn't find a value for ID ${id}`
      );
      return;
    }
    props.onChange(value);
  };

  // NOTE: this array needs to be cloned before we can sort it
  const unsortedItems =
    data?.permitIssuingAuthorityList?.lookups != null
      ? [...data?.permitIssuingAuthorityList?.lookups]
      : [];

  const sortedItems = unsortedItems.sort((a, b) => {
    if (i18n.language === "fr") {
      if (a?.name?.french == null) return 0;
      if (b?.name?.french == null) return 0;
      return a.name.french.localeCompare(b.name.french);
    } else {
      if (a?.name?.english == null) return 0;
      if (b?.name?.english == null) return 0;
      return a.name.english.localeCompare(b.name.english);
    }
  });

  return (
    <select
      className="form-control"
      value={props?.defaultValue?.id ?? ""}
      ref={ref}
      {...props}
      onChange={onChange}
      data-testid={props.id}
      disabled={props.disabled}
    >
      <option value={""}>
        {t(props.placeholder ?? "please_select_option")}
      </option>
      {sortedItems?.map((x) => {
        return (
          <option
            key={x?.id ?? "error - missing id"}
            value={x?.id ?? "error - missing id"}
          >
            {bilingualLookupTextNameForLanguage(i18n.language, x)}
          </option>
        );
      })}
    </select>
  );
});

export interface PermitDescriptionIssuingAuthorityDropdownWithControllerProps<
  TFieldValues
> extends Omit<PermitDescriptionIssuingAuthorityDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
  disabled?: boolean | undefined;
}

export const PermitDescriptionIssuingAuthorityDropdownWithController = <
  TFieldValues,
>(
  props: PermitDescriptionIssuingAuthorityDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <PermitDescriptionIssuingAuthorityDropdown
          id={props.id}
          placeholder={props.placeholder}
          onChange={onChange}
          defaultValue={value}
          disabled={props.disabled}
        />
      )}
    />
  );
};

export default PermitDescriptionIssuingAuthorityDropdown;
