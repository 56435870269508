import { Link } from "react-router-dom";

export function UnauthorizedPage() {
  return (
    <div>
      <p>
        Your account doesn't have permission to view the page you tried to
        visit.
      </p>
      <Link to="/" data-testid="link-return-home">
        Return to home page
      </Link>
    </div>
  );
}

export default UnauthorizedPage;
