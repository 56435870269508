import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import BadgeBar from "components/molecules/BadgeBar";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useOverviewContext } from "features/overview";
import { ListingWsRelatedSpeciesDocument } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { ListingPathHelpers } from "../../ListingRouter";

const ListingRelatedSpecies: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { loading, error, data } = useQuery(ListingWsRelatedSpeciesDocument, {
    variables: { id: listingWsId },
  });

  const parentCount = data?.listingWsRelatedSpeciesCounts?.parentCount ?? 0;
  const childCount = data?.listingWsRelatedSpeciesCounts?.childCount ?? 0;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("listing_related_species")}</h1>
          <GraphqlError errors={error} />
          <SectionCard
            header={
              <div className="flex justify-between">
                <h2>{t("relationship_types")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.create}
                >
                  <Link
                    to={ListingPathHelpers.AddParents(listingWsId)}
                    className="btn btn-default btn-sm mrgn-rght-md height-fit-content"
                    data-testid="link-add-parents"
                  >
                    {t("add_parents")}
                  </Link>
                </RenderWhenAuthorized>
              </div>
            }
            showLine={loading}
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_parents")}
                    titleLink={ListingPathHelpers.ViewParents(listingWsId)}
                    counter={parentCount}
                  />
                </div>
                <div className="row list-item separator-line">
                  <BadgeBar
                    title={t("view_children")}
                    titleLink={ListingPathHelpers.ViewChildren(listingWsId)}
                    counter={childCount}
                  />
                </div>
              </>
            )}
          </SectionCard>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingRelatedSpecies;
