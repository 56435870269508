import { useMutation } from "@apollo/client";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import ListingLandForm, {
  ListingLandFormFields,
} from "components/organisms/listing/ListingLandForm/ListingLandForm";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import GraphqlError from "../../../../components/GraphqlError";
import { mapFormToCreateInput } from "../../../../components/organisms/listing/ListingLandForm/mappers";
import { CreateListingWsLandDocument } from "../../../../generated/gql-types";

export interface CreateListingLandsPageProps {}

export const CreateListingLandsPage: React.FC<CreateListingLandsPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();
  const alertContext = useGlobalAlertContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const [createListingLand, { loading: saving, error: savingError }] =
    useMutation(CreateListingWsLandDocument);

  const onSubmit: SubmitHandler<Partial<ListingLandFormFields>> = async (
    formData
  ) => {
    const mapped = mapFormToCreateInput(formData);

    try {
      const res = await createListingLand({
        variables: {
          listingWsId,
          input: mapped,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        // TODO:i18n
        title: "Successfully added a new Land",
        message: `You've added "${mapped.landRef?.landName}"`,
      });

      history.push({
        pathname: ListingPathHelpers.ListingLands(listingWsId ?? "error"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingLands(listingWsId ?? "error"),
    });
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("add_a_land")}</h1>
          <GraphqlError
            title="Error saving listing WS land data"
            errors={savingError}
          />

          {saving ? <p>Saving...</p> : null}

          <ChangeTrackingProvider isEnabled={false}>
            <ListingLandForm onSubmit={onSubmit} onCancel={onCancel} />
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default CreateListingLandsPage;
