import { useMutation, useQuery } from "@tanstack/react-query";
import SearchImage from "assets/svg/search.svg";
import { getApiSearchServiceUrl } from "azure/environment";
import AddLink from "components/atoms/AddLink";
import Alert, { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import EditLink from "components/atoms/EditLink";
import ExportButton from "components/atoms/ExportButton";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { GlobalAlert } from "features/globalAlert";
import { usePagination } from "hooks/util/usePagination";
import i18n from "i18n";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import { fetchExport } from "util/queryTool/fetchExport";
import { SearchPathHelpers } from "../SearchRouter";

const searchServiceUrl = getApiSearchServiceUrl();

const getAuthorList = async () => {
  const token = await getUserAccessToken();
  return fetch(`${searchServiceUrl}query/authors/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());
};

const getQueryToolList = async (
  pageNumber: number,
  pageSize: number,
  filterBy: string,
  sortBy: string
) => {
  const token = await getUserAccessToken();
  return fetch(
    `${searchServiceUrl}query?pageSize=${pageSize}&pageNumber=${pageNumber}&filterBy=${filterBy}&sortBy=${sortBy}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": i18n.language + "-CA",
      },
    }
  ).then((res) => res.json());
};

type QueryPagination = {
  currentPage?: number;
  totalCount?: number;
  totalPages?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
};

type QueryList = {
  id: string;
  name: {
    french: string;
    english: string;
  };
  sharingSetting?: string;
  createdAt?: string;
  modifiedAt?: string;
  createdBy?: string;
  modifiedBy?: string;
};

interface QueryToolListResponse {
  pagination?: QueryPagination;
  queries: QueryList[];
}

type AuthorList = {
  name?: string;
};

interface AuthorListResponse {
  authors: AuthorList[];
}

const QueryToolSearchPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [filterFields, setFilterFields] = React.useState("");
  const pagination = usePagination();
  const [exportQueryId, setExportQueryId] = React.useState("");

  const queryToolList = useQuery<QueryToolListResponse | undefined>(
    ["queryToolList", pagination, filterFields, sortOrder],
    () =>
      getQueryToolList(
        pagination.currentPage,
        pagination.pageSize,
        filterFields,
        `createdAt:${sortOrder}`
      )
  );

  const authorList = useQuery<AuthorListResponse | undefined>(
    ["authorList"],
    () => getAuthorList()
  );

  const exportMutation = useMutation(["queryTool", "export"], fetchExport, {
    onError: () => {
      window.scrollTo(0, 260); // Scroll to page header to show error message.
    },
  });

  const runExport = async (queryId: string) => {
    const languageCode = i18n.language as any;

    return exportMutation.mutate({
      queryId,
      languageCode,
    });
  };

  const onChangeFilterBy = async (e: any) => {
    setFilterFields(e.currentTarget.value);
    pagination.goToPage(1);
  };

  const onChangeSortOrder = (e: any) => {
    setSortOrder(e.currentTarget.value);
  };

  const onDelete = async () => {
    console.log("onDelete!");
  };

  const loading = queryToolList.isLoading || authorList.isLoading;
  const error = queryToolList.isError || authorList.isError;

  const listOfQueries = queryToolList.data?.queries ?? [];

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("query_tool")}</h1>
          {error && (
            <Alert type={AlertTypes.DANGER} title="Error loading query list" />
          )}
          {exportMutation.isSuccess ? (
            <Alert
              type={AlertTypes.SUCCESS}
              title={t("export_success")}
              onClose={exportMutation.reset}
            />
          ) : null}

          {exportMutation.isError ? (
            <Alert
              type={AlertTypes.DANGER}
              title={t("export_failure")}
              content={(exportMutation.error as any)?.message ?? undefined}
              onClose={exportMutation.reset}
            />
          ) : null}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("your_saved_queries")}</h2>
                {/* <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                > */}
                <AddLink
                  text={t("create")}
                  linkTo={SearchPathHelpers.QueryToolCreate}
                />
                {/* </RenderWhenAuthorized> */}
              </div>
            }
          >
            <div id="dataset_filter">
              <div className="filter-controls">
                <section className="flex justify-between mrgn-bttm-md">
                  <div className="flex">
                    <div className="flex-col">
                      <label htmlFor="sel_created_by">{t("created_by")}</label>
                      <select
                        id="sel_created_by"
                        className="form-control mrgn-rght-md"
                        value={filterFields}
                        onChange={onChangeFilterBy}
                      >
                        <option value="">{t("all")}</option>
                        {authorList.data?.authors.map((item, index) => (
                          <option key={index} value={"createdBy=" + item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex-col">
                      <label htmlFor="sel_sort_order">
                        {t("date_created")}
                      </label>
                      <select
                        id="sel_sort_order"
                        className="form-control mrgn-rght-md"
                        value={sortOrder}
                        onChange={onChangeSortOrder}
                      >
                        <option value="asc">{t("ascending")}</option>
                        <option value="desc">{t("descending")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex">
                    {/* <p>Export to Excel</p> */}
                    <PageSizeSelect
                      pageSize={pagination.pageSize}
                      onChangePageSize={pagination.setPageSize}
                    />
                  </div>
                </section>
              </div>
              {/*// <!-- Filter Listing -->*/}
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : !listOfQueries || listOfQueries?.length === 0 ? (
                <div className="separator-line">
                  {/* // <RenderWhenAuthorized
                    //   authorizedRoles={ROLE_ACTIONS.cosewic.update}
                    //   fallbackComponent={<MissingData />}
                    // > */}
                  <div className="text-center mrgn-tp-xl mrgn-bttm-xl">
                    <img src={SearchImage} alt="" />
                    <div className="no-results-title">
                      {t("could_not_find_results")}
                    </div>
                    <div className="no-results-content">
                      <Link
                        to={SearchPathHelpers.QueryToolCreate}
                        className="hover-underline"
                        data-testid="link-create-new-query"
                      >
                        {t("create_new_query")}
                      </Link>
                    </div>
                  </div>
                  {/* // </RenderWhenAuthorized> */}
                </div>
              ) : null}

              {listOfQueries.map((item, index) => (
                <div
                  className="row list-item separator-line species-field"
                  key={index}
                >
                  <div className="flex justify-between align-start">
                    <h2 className="mrgn-tp-0">
                      {/* <Link to="#"> */}
                      {i18n.language === "fr"
                        ? item.name.french
                        : item.name.english}
                      {/* </Link> */}
                    </h2>
                    <div className="flex gap-sm align-start flex-wrap justify-right">
                      <ExportButton
                        onExport={() => {
                          setExportQueryId(item.id);
                          runExport(item.id);
                        }}
                        loading={
                          exportMutation.isLoading && exportQueryId === item.id
                        }
                      />
                      <EditLink linkTo="#" disabled />
                      <DeleteButtonModal
                        buttonText={t("delete")}
                        buttonTitle={t("delete_query")}
                        modalTitle={t("delete_query")}
                        alertContent="You will not be able to recover this query data"
                        alertConfirm={t("delete_confirm")}
                        onDelete={onDelete}
                        disabled
                      />
                    </div>
                  </div>
                  <dl>
                    <dt className="text-muted mrgn-bttm-md">
                      {t("created_by") + ": "}
                      {item?.createdBy ? t(item?.createdBy) : <MissingData />}
                      {" | "}
                      {t("shared") + ": "}
                      {item?.sharingSetting ? (
                        t(item?.sharingSetting)
                      ) : (
                        <MissingData />
                      )}
                      {" | "}
                      {t("date_created") + ": "}
                      {item?.createdAt ? (
                        formatTimestamp(t(item?.createdAt))
                      ) : (
                        <MissingData />
                      )}
                      {" | "}
                      {t("date_updated") + ": "}
                      {item?.modifiedAt ? (
                        formatTimestamp(t(item?.modifiedAt))
                      ) : (
                        <MissingData />
                      )}
                    </dt>
                  </dl>
                </div>
              ))}

              <Pagination
                {...pagination.paginationComponentProps}
                totalPages={queryToolList.data?.pagination?.totalPages ?? 0}
              />
            </div>
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default QueryToolSearchPage;
