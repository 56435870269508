import { ADMIN_FORM_INPUT_PATTERN } from "config/constants";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../atoms/forms/FieldValidationError";
import FormButtons from "../FormButtons/FormButtons";
import { TextInput } from "components/atoms/forms/TextInput";

const SpeciesForm: React.FC<SpeciesFormProps> = (props) => {
  const form = useForm<SpeciesFields>({
    defaultValues: props.defaultValues,
  });

  const { register, handleSubmit, formState } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();

  const [name, setName] = React.useState(props.defaultValues.name);

  const onChange = (e: any) => {
    const value = e.target.value;
    const lowerCaseValue = value.toLowerCase();
    setName(lowerCaseValue);
  };

  const onSubmit: SubmitHandler<SpeciesFields> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        formData.name = formData.name.toLowerCase();
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <TextInput
        type="text"
        label={t("name")}
        value={name}
        pattern={ADMIN_FORM_INPUT_PATTERN}
        id="speciesNameInput"
        inputClassName="full-width"
        placeholder={t("name")}
        maxLength={100}
        required={true}
        {...register("name", {
          onChange: onChange,
          required: {
            value: true,
            message: t("field_is_required"),
          },
        })}
        errors={errors}
      />

      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
      />
    </form>
  );
};

export default SpeciesForm;

export interface SpeciesFields {
  name: string;
}

interface SpeciesFormProps {
  defaultValues: SpeciesFields;
  onSubmit: SubmitHandler<SpeciesFields>;
  onClose: () => void;
}
// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
