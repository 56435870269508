import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import {
  ChangeTypeType,
  Gender,
  ListingWs,
} from "../../../../generated/gql-types";
import {
  makeBilingualAbbreviationLookupText,
  makeBilingualLookupText,
  makeBilingualName,
  makeBilingualRichText,
  makeIdName,
  makeRichTextInput,
} from "../../../../mappers";
import { filterForDirtyFields } from "../../../../util/forms";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import {
  ListingFormChangeTracking,
  ListingFormFields,
  ListingFormOutput,
} from "./index";

export const processListingEditFormData = async (
  formData: UnpackNestedValue<Partial<ListingFormOutput>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: ListingFormFields
) => {
  const values = filterForDirtyFields(formData, dirtyFields);
  await setNullForEmptyFields(values, dirtyFields, initialValues);
  await defaultChangeTrackingValues(values);

  return values;
};

const setNullForEmptyFields = async (
  formData: UnpackNestedValue<Partial<ListingFormOutput>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: ListingFormFields
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  if (
    dirtyFields?.commonNameFrench &&
    htmlIsNullOrEmpty(formData?.commonNameFrench?.name?.text)
  ) {
    set(formData, "commonNameFrench", null);
  }

  if (
    dirtyFields?.legalStatus?.competentMinister1 &&
    isNullOrEmpty(formData?.legalStatus?.competentMinister1)
  ) {
    set(formData, "legalStatus.competentMinister1", null);
  }

  const processListingHistory = () => {
    const enIsDirty = dirtyFields?.legalStatus?.listingHistory?.english?.text;
    const enIsEmptyInForm = htmlIsNullOrEmpty(
      formData?.legalStatus?.listingHistory?.english?.text
    );
    const enIsEmptyInInitialValues = htmlIsNullOrEmpty(
      initialValues?.legalStatus?.listingHistory?.english?.text
    );

    const frIsDirty = dirtyFields?.legalStatus?.listingHistory?.french?.text;
    const frIsEmptyInForm = htmlIsNullOrEmpty(
      formData?.legalStatus?.listingHistory?.french?.text
    );
    const frIsEmptyInInitialValues = htmlIsNullOrEmpty(
      initialValues?.legalStatus?.listingHistory?.french?.text
    );

    // Cases where full listingHistory object should be null
    if (enIsDirty && frIsDirty && enIsEmptyInForm && frIsEmptyInForm) {
      set(formData, "legalStatus.listingHistory", null);
      return;
    } else if (
      enIsDirty &&
      enIsEmptyInForm &&
      !frIsDirty &&
      frIsEmptyInInitialValues
    ) {
      set(formData, "legalStatus.listingHistory", null);
      return;
    } else if (
      frIsDirty &&
      frIsEmptyInForm &&
      !enIsDirty &&
      enIsEmptyInInitialValues
    ) {
      set(formData, "legalStatus.listingHistory", null);
      return;
    }

    // Cases where only en should be set null
    if (enIsDirty && enIsEmptyInForm) {
      set(formData, "legalStatus.listingHistory.english", null);
      return;
    }

    // Cases where only fr should be set null
    if (frIsDirty && frIsEmptyInForm) {
      set(formData, "legalStatus.listingHistory.french", null);
      return;
    }
  };
  processListingHistory();

  if (
    dirtyFields?.consultationConsideration?.estimatedIndividualAssessment &&
    isNullOrEmpty(
      formData?.consultationConsideration?.estimatedIndividualAssessment
    )
  ) {
    set(
      formData,
      "consultationConsideration.estimatedIndividualAssessment",
      null
    );
  }

  if (
    dirtyFields?.consultationConsideration?.conservationActivities?.english
      ?.text &&
    htmlIsNullOrEmpty(
      formData?.consultationConsideration?.conservationActivities?.english?.text
    )
  ) {
    set(
      formData,
      "consultationConsideration.conservationActivities.english",
      null
    );
  }

  if (
    dirtyFields?.consultationConsideration?.conservationActivities?.french
      ?.text &&
    htmlIsNullOrEmpty(
      formData?.consultationConsideration?.conservationActivities?.french?.text
    )
  ) {
    set(
      formData,
      "consultationConsideration.conservationActivities.french",
      null
    );
  }

  if (
    dirtyFields?.legalProtection?.jurisdiction &&
    isNullOrEmpty(formData?.legalProtection?.jurisdiction)
  ) {
    set(formData, "legalProtection.jurisdiction", null);
  }

  if (
    dirtyFields?.legalProtection?.legislationApplication?.text &&
    htmlIsNullOrEmpty(formData?.legalProtection?.legislationApplication?.text)
  ) {
    set(formData, "legalProtection.legislationApplication", null);
  }

  if (
    dirtyFields?.legalProtection?.federalProtectionApplication?.text &&
    htmlIsNullOrEmpty(
      formData?.legalProtection?.federalProtectionApplication?.text
    )
  ) {
    set(formData, "legalProtection.federalProtectionApplication", null);
  }

  if (
    dirtyFields?.overallAssessment?.expectedImpactOnFederalLands &&
    isNullOrEmpty(formData?.overallAssessment?.expectedImpactOnFederalLands)
  ) {
    set(formData, "overallAssessment.expectedImpactOnFederalLands", null);
  }

  if (
    dirtyFields?.overallAssessment?.expectedImpactOnFederalLandsRationale
      ?.text != null &&
    htmlIsNullOrEmpty(
      formData?.overallAssessment?.expectedImpactOnFederalLandsRationale?.text
    )
  ) {
    set(
      formData,
      "overallAssessment.expectedImpactOnFederalLandsRationale",
      null
    );
  }

  if (
    dirtyFields?.overallAssessment?.expectedImpactOnNonFederalLands &&
    isNullOrEmpty(formData?.overallAssessment?.expectedImpactOnNonFederalLands)
  ) {
    set(formData, "overallAssessment.expectedImpactOnNonFederalLands", null);
  }

  if (
    dirtyFields?.overallAssessment?.expectedImpactOnNonFederalLandsRationale
      ?.text &&
    htmlIsNullOrEmpty(
      formData?.overallAssessment?.expectedImpactOnNonFederalLandsRationale
        ?.text
    )
  ) {
    set(
      formData,
      "overallAssessment.expectedImpactOnNonFederalLandsRationale",
      null
    );
  }
};

export const defaultChangeTrackingValues = async (
  values: Partial<ListingFormChangeTracking>
) => {
  const defaultsForKey = (key: keyof typeof values) => {
    if (values[key] == null) return;

    if (values[key]?.changeType == null)
      values[key]!.changeType = ChangeTypeType.Minor;
    if (values[key]?.reasonForChange == null)
      values[key]!.reasonForChange = "MISSING_REASON";
  };

  defaultsForKey("commonNameEnglishChangeMetaData");
  defaultsForKey("commonNameFrenchChangeMetaData");
  defaultsForKey("nameWithCommaChangeMetaData");
  defaultsForKey("populationChangeMetaData");
  defaultsForKey("genusChangeMetaData");
  defaultsForKey("varietyChangeMetaData");
  defaultsForKey("speciesChangeMetaData");
  defaultsForKey("subSpeciesChangeMetaData");
};

export const mapListingToListingFormFields = (
  listingWs?: ListingWs | null
): ListingFormFields => {
  const out: ListingFormFields = {
    commonNameEnglish: {
      name: makeRichTextInput(listingWs?.commonNameEnglish?.name),
    },
    commonNameFrench: {
      name: makeRichTextInput(listingWs?.commonNameFrench?.name),
    },
    nameWithComma: { name: makeRichTextInput(listingWs?.nameWithComma?.name) },
    population: makeBilingualName(listingWs?.population),
    genus: makeIdName(listingWs?.scientificName?.genus),
    variety: makeIdName(listingWs?.scientificName?.variety),
    species: makeIdName(listingWs?.scientificName?.species),
    subSpecies: makeIdName(listingWs?.scientificName?.subSpecies),
    taxonomicGroup: makeBilingualName(listingWs?.taxonomicGroup),
    gender: listingWs?.gender ?? Gender.NotInitialized,
    ranges: listingWs?.ranges?.map((x) => makeBilingualName(x)) ?? [],
    legalStatus: {
      competentMinister1: makeBilingualAbbreviationLookupText(
        listingWs?.legalStatus?.competentMinister1
      ),
      competentMinister2:
        listingWs?.legalStatus?.competentMinister2?.map(
          makeBilingualAbbreviationLookupText
        ) ?? [],
      listingHistory: makeBilingualRichText(
        listingWs?.legalStatus?.listingHistory
      ),
    },

    consultationConsideration: {
      stakeholders:
        listingWs?.consultationConsideration?.stakeholders?.map(
          makeBilingualLookupText
        ) ?? [],
      estimatedIndividualAssessment:
        listingWs?.consultationConsideration?.estimatedIndividualAssessment ??
        "",
      indigenousCommunities:
        listingWs?.consultationConsideration?.indigenousCommunities?.map(
          makeBilingualLookupText
        ) ?? [],
      wildlifeManagementBoards:
        listingWs?.consultationConsideration?.wildlifeManagementBoards?.map(
          makeBilingualAbbreviationLookupText
        ) ?? [],
      conservationActivities: makeBilingualRichText(
        listingWs?.consultationConsideration?.conservationActivities
      ),
    },

    legalProtection: {
      provincialAndTerritorialLegislations:
        listingWs?.legalProtection?.provincialAndTerritorialLegislations?.map(
          makeBilingualLookupText
        ) ?? [],
      otherFederalProtections:
        listingWs?.legalProtection?.otherFederalProtections?.map(
          makeBilingualLookupText
        ) ?? [],
      additionalProtectionUnderSara:
        listingWs?.legalProtection?.additionalProtectionUnderSara?.map(
          makeBilingualLookupText
        ) ?? [],
      jurisdiction: makeBilingualLookupText(
        listingWs?.legalProtection?.jurisdiction
      ),
      legislationApplication: makeRichTextInput(
        listingWs?.legalProtection?.legislationApplication
      ),
      federalProtectionApplication: makeRichTextInput(
        listingWs?.legalProtection?.federalProtectionApplication
      ),
    },

    overallAssessment: {
      expectedImpactOnFederalLands: makeBilingualLookupText(
        listingWs?.overallAssessment?.expectedImpactOnFederalLands
      ),
      expectedImpactOnFederalLandsRationale: makeRichTextInput(
        listingWs?.overallAssessment?.expectedImpactOnFederalLandsRationale
      ),
      expectedImpactOnNonFederalLands: makeBilingualLookupText(
        listingWs?.overallAssessment?.expectedImpactOnNonFederalLands
      ),
      expectedImpactOnNonFederalLandsRationale: makeRichTextInput(
        listingWs?.overallAssessment?.expectedImpactOnNonFederalLandsRationale
      ),
    },
  };

  return out;
};
