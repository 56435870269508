const bptTranslations = {
  en: {
    sar_recovery_templates: "Sar Recovery Templates",
    template_description: "Template description",
    project_visibility: "Project visibility",
    object_type: "Object type",
    template_name: "Template name",
    template_information: "Template information",
    template_program: "Program",
    template_visibility: "Visibility",
    template_object_type: "Object type",
    calculated_duration: "Calculated duration",
    total_calculated_duration: "Total calculated duration",
    activate_template_warning: "Activate template warning",
    activate: "Activate",
    expand_all: "Expand all",
    collapse_all: "Collapse all",
    phase: "Phase",
    phases: "Phases",
    phase_name: "Phase name",
    delete_phase: "Delete phase",
    html_delete_phase_warning:
      "<strong>Are you sure want to delete this phase?</strong> <br /> <p>This will permanently delete this phase and its information, including all steps and activities associated with this phase.</p>",
    add_phase: "Add phase",
    delete_draft: "Delete draft",
    delete_draft_warning: "Delete draft warning",
    activate_template: "Activate template",
    activate_template_confirm: "Activate template confirm",
    estimated_duration: "Estimated duration",
    estimated_duration_business_days: "Estimated duration (business days)",
    include_house_of_common_holidays: "Include House of Common Holidays",
    enter_an_phase_name: "Enter an phase name",
    phase_description: "Phase description",
    phase_information: "Phase information",
    successfully_added_phase: "Successfully added phase",
    successfully_delete_phase: "Successfully deleted phase",
    create_new_phase: "Create new phase",
    successfully_updated_phase: "Successfully updated phase",
    successfully_update_phase_order: "Successfully updated phase order",
    edit_phase: "Edit phase",
    business_days: "Business days",
    business_days_for_placeholder: "# Business days",
    manage_templates: "Manage templates",
    add_new_bpt_template: "Create",
    create_new_bpt_template: "Create new BPT template",
    visibility: "Visibility",
    objectType: "Object Type",
    reorder: "Reorder",
    reorder_phases: "Reorder phases",
    reorder_steps: "Reorder steps",
    reorder_activities: "Reorder activities",
    edit_bpt_template: "Edit BPT template",
    updated_bpt_template: "BptTemplate Updated",
    added_new_bpt_template: "Added BptTemplate",
    bpt_template: "BPT template",
    manage_bpt_templates: "Manage BPT templates",
    version: "Version",
    all_templates: "All templates",
    include_deactivated_templates: "Include deactivated templates",
    no_phases_please_add:
      "There are no phases, please add a phase to get started.",
    reorder_phases_message:
      "Reorder the phases by using the move up and move down buttons to move them into the desired order.",
    move_up: "Move up",
    move_down: "Move down",
    business_process_tracking_templates:
      "Business Process Tracking (BPT) templates",
    error_loading_bpt:
      "Error loading Business Process Tracking (BPT) templates",

    // Enums
    ALL_PROGRAMS: "All programs",

    // Steps definitions
    step: "Step",
    steps: "Steps",
    step_name: "Step name",
    add_step: "Add step",
    reorder_and_bundle_steps: "Reorder and bundle steps",
    reorder_and_bundle_steps_message:
      "Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    no_steps_please_add:
      "There are no steps, please add a step to get started.",
    delete_step: "Delete step",
    return_to: "Return to",
    switch_to_other_phase: "Switch to other phase: ",
    select_phase: "Select phase",
    bpt_template_phase: "BPT template phase",
    step_information: "Step information",
    step_owner: "Step owner",
    edit_step: "Edit step",
    can_not_be_calculated_tooltip:
      "Cannot be calculated as some duration were not entered",
    enter_step_name: "Enter step name",
    enter_your_text: "Enter your text",
    enter_group_role_or_person_resposible:
      "Enter group, role or person resposible",
    owner: "owner",
    step_description: "Step description",
    successfully_added_step: "Successfully added step",
    successfully_updated_step: "Successfully updated step",
    view_details: "View details",
    html_delete_step_warning:
      "<strong>Are you sure want to delete this step?</strong> <br /> <p>This will permanently delete this step and all its information, including all activities associated with this step.</p>",
    error_update_bpt_template_step: "Error Update BPT Template Step!",
    error_create_bpt_template_step: "Error Create BPT Template Step!",
    error_update_bpt_template_phase: "Error Update BPT Template Phase!",
    error_create_bpt_template_phase: "Error Create BPT Template Phase!",
    error_loading_bpt_template_phase: "Error Loading BPT Template Phase!",
    error_loading_bpt_template_phase_list:
      "Error Loading BPT Template Phase List!",
    error_loading_bpt_template_step_list:
      "Error Loading BPT Template Step List!",
    error_loading_bpt_Template: "Error Loading BPT Template!",
    no_phases: "There are no phases.",
    no_steps: "There are no steps.",
    error_phases_reorder: "Error phases reorder!",
    error_steps_reorder: "Error steps reorder!",
    create_new_bundle_add_steps:
      "Create a new bundle and add the selected steps into it",
    remove_step_from_bundle: "Remove step from bundle",
    select_for_bundling: "Select for bundling",
    add_selected_steps_into_this_bundle:
      "Add the selected step(s) into this bundle",
    reorder_steps_message:
      "Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    successfully_update_step_order: "Successfully updated step order",
  },

  fr: {
    sar_recovery_templates: "Sar Recovery Templates [FR]",
    template_description: "Template description[FR]",
    project_visibility: "Project visibility[FR]",
    object_type: "Object type[FR]",
    template_name: "Template name[FR]",
    template_information: "Template information[FR]",
    template_program: "Program[FR]",
    template_visibility: "Visibility[FR]",
    template_object_type: "Object type[FR]",
    calculated_duration: "Calculated duration[FR]",
    total_calculated_duration: "Total calculated duration[FR]",
    activate_template_warning: "Activate template warning[FR]",
    activate: "Activate[FR]",
    expand_all: "Expand all[FR]",
    collapse_all: "Collapse all[FR]",
    phase: "Phase[FR]",
    phases: "Phases[FR]",
    phase_name: "Phase name[FR]",
    delete_phase: "Delete phase[FR]",
    html_delete_phase_warning:
      "[FR]<strong>Are you sure want to delete this phase?</strong> <br /> <p>This will permanently delete this phase and its information, including all steps and activities associated with this phase.</p>",
    add_phase: "Add phase[FR]",
    delete_draft: "Delete draft[FR]",
    delete_draft_warning: "Delete draft warning[FR]",
    activate_template: "Activate template[FR]",
    activate_template_confirm: "Activate template confirm[FR]",
    estimated_duration: "Estimated duration[FR]",
    estimated_duration_business_days: "Estimated duration (business days)[FR]",
    include_house_of_common_holidays: "Include House of Common Holidays[FR]",
    enter_an_phase_name: "Enter an phase name[FR]",
    phase_description: "Phase description[FR]",
    phase_information: "Phase information[FR]",
    successfully_added_phase: "Successfully added phase[FR]",
    successfully_delete_phase: "Successfully deleted phase[FR]",
    create_new_phase: "Create new phase[FR]",
    successfully_updated_phase: "Successfully updated phase[FR]",
    successfully_update_phase_order: "Successfully updated phase order[FR]",
    edit_phase: "Edit phase[FR]",
    business_days: "Business days[FR]",
    business_days_for_placeholder: "# Business days[FR]",
    manage_templates: "Manage templates[FR]",
    add_new_bpt_template: "Create[FR]",
    create_new_bpt_template: "Create new BPT template[FR]",
    visibility: "Visibility[FR]",
    objectType: "Object Type[FR]",
    reorder: "Reorder[FR]",
    reorder_phases: "Reorder phases[FR]",
    reorder_steps: "Reorder steps[FR]",
    reorder_activities: "Reorder activities[FR]",
    edit_bpt_template: "Edit BPT template[FR]",
    updated_bpt_template: "BptTemplate Updated[FR]",
    added_new_bpt_template: "Added BptTemplate[FR]",
    bpt_template: "BPT template[FR]",
    manage_bpt_templates: "Manage BPT templates[FR]",
    version: "Version",
    all_templates: "All templates[FR]",
    include_deactivated_templates: "Include deactivated templates[FR]",
    no_phases_please_add:
      "There are no phases, please add a phase to get started. [FR]",
    reorder_phases_message:
      "Reorder the phases by using the move up and move down buttons to move them into the desired order. [FR]",
    move_up: "Move up [FR]",
    move_down: "Move down [FR]",
    business_process_tracking_templates:
      "Modèles des Suivis de processus d'affaires (SPA)",
    error_loading_bpt:
      "Erreur de chargement des Modèles des Suivis de processus d'affaires (SPA)",

    // Enums
    ALL_PROGRAMS: "All programs [Fr]",

    // Steps definitions
    step: "[FR]Step",
    steps: "[FR]Steps",
    step_name: "[FR]Step name",
    add_step: "[FR]Add step",
    reorder_and_bundle_steps: "[FR]Reorder and bundle steps",
    reorder_and_bundle_steps_message:
      "[FR]Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    no_steps_please_add:
      "[FR]There are no steps, please add a step to get started.",
    delete_step: "[FR]Delete step",
    return_to: "[FR]Return to",
    switch_to_other_phase: "[FR]Switch to other phase: ",
    select_phase: "[FR]Select phase",
    bpt_template_phase: "[FR]BPT template phase",
    step_information: "[FR]Step information",
    step_owner: "[FR]Step owner",
    edit_step: "[FR]Edit step",
    can_not_be_calculated_tooltip:
      "[FR]Cannot be calculated as some duration were not entered",
    enter_step_name: "[FR]Enter step name",
    owner: "[FR]owner",
    step_description: "[FR]Step description",
    successfully_added_step: "[FR]Successfully added step",
    successfully_updated_step: "[FR]Successfully updated step",
    view_details: "[FR]View details",
    html_delete_step_warning:
      "[FR]<strong>Are you sure want to delete this step?</strong> <br /> <p>This will permanently delete this step and all its information, including all activities associated with this step.</p>",
    error_update_bpt_template_step: "[FR]Error Update BPT Template Step!",
    error_create_bpt_template_step: "[FR]Error Create BPT Template Step!",
    error_update_bpt_template_phase: "[FR]Error Update BPT Template Phase!",
    error_create_bpt_template_phase: "[FR]Error Create BPT Template Phase!",
    error_loading_bpt_template_phase: "[FR]Error Loading BPT Template Phase!",
    error_loading_bpt_template_phase_list:
      "[FR]Error Loading BPT Template Phase List!",
    error_loading_bpt_template_step_list:
      "[FR]Error Loading BPT Template Step List!",
    error_loading_bpt_Template: "[FR]Error Loading BPT Template!",
    no_phases: "[FR]There are no phases.",
    no_steps: "[FR]There are no steps.",
    error_phases_reorder: "[FR]Error phases reorder!",
    error_steps_reorder: "[FR]Error steps reorder!",
    create_new_bundle_add_steps:
      "[FR]Create a new bundle and add the selected steps into it",
    remove_step_from_bundle: "[FR]Remove step from bundle",
    select_for_bundling: "[FR]Select for bundling",
    add_selected_steps_into_this_bundle:
      "[FR]Add the selected step(s) into this bundle",
    reorder_steps_message:
      "[FR]Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    successfully_update_step_order: "[FR]Successfully updated step order",
  },
};

export default bptTranslations;
