import { OverlayContainer } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import addContactIcon from "assets/svg/add-contact-icon.svg";
import cc from "classcat";
import { ContactType } from "components/organisms/contacts/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Contact,
  ContactTag,
  Organization,
} from "../../../../generated/gql-types";
import { TextInput } from "../../../atoms/forms/TextInput";
import ModalDialog from "../../../molecules/Modal";
import {
  AddContactModalSearchContacts,
  AddContactModalSearchOrganization,
} from "./AddContactModalSearch";

const AddContactButtonAndModal: React.FC<{
  id: string;
  onSave: (
    newValue: Contact | Organization | null | undefined,
    type: ContactType
  ) => void;
  isDisabled?: boolean;
  isPhotographer?: boolean;
  className?: string;
  buttonText?: string;
  buttonTitle?: string;
  error?: any;
  showImage?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const modalState = useOverlayTriggerState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState<ContactType>("contact");
  const [selectedValue, setSelectedValue] = useState<
    Contact | Organization | null | undefined
  >(null);

  useEffect(
    function resetSearchTermWhenTypeChanges() {
      setSearchTerm("");
    },
    [searchType]
  );

  useEffect(() => {
    setSelectedValue(null);
  }, [searchTerm, searchType]);

  const onOpen = () => {
    setSearchTerm("");
    setSearchType("contact");
    setSelectedValue(null);
    modalState.open();
  };

  const onSave = () => {
    props.onSave(selectedValue, searchType);
    modalState.close();
  };

  let searchContactTag = undefined;
  if (props.isPhotographer) {
    searchContactTag = ContactTag.Photographer;
  }

  return (
    <>
      <button
        type="button"
        className={cc(["btn btn-link mrgn-0 py-0 px-2", props.className])}
        onClick={onOpen}
        data-testid="button-add-contact"
        title={props.buttonTitle}
        disabled={props.isDisabled}
      >
        {props.showImage ? (
          <>
            <img src={addContactIcon} alt={t("add_contact")} />
            <div className={"no-results-content"}>
              {props.buttonText ? t(props.buttonText) : t("add_contact")}
            </div>
          </>
        ) : (
          <>
            <i className="fas fa-plus-circle mrgn-rght-sm"></i>
            {props.buttonText || t("add_contact")}
          </>
        )}
      </button>
      <OverlayContainer>
        {modalState.isOpen ? (
          <ModalDialog
            id="add_contact_modal"
            data-testid="add_contact_modal"
            title={t("add_contact_organization")}
            className={"modal-width-70"}
            onClose={modalState.close}
            showModalFooter={false}
            // isDismissable
          >
            <div className="flex gap-md flex-wrap">
              <TextInput
                label={t("who_are_you_looking_for")}
                placeholder={t("enter_a_name_or_email")}
                id="search-for"
                inputClassName="width-100"
                divClassName="flex-auto mrgn-bttm-0"
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
              <div className="form-group">
                <label htmlFor="contactTypeSelect">{t("type")}</label>
                <select
                  id="contactTypeSelect"
                  className="form-control width-230"
                  onChange={(e) =>
                    setSearchType(e.currentTarget.value as ContactType)
                  }
                >
                  <option value="contact">{t("contacts")}</option>
                  <option value="organization">{t("organizations")}</option>
                </select>
              </div>
            </div>

            <div className="min-height-auto">
              {searchType === "contact" ? (
                <AddContactModalSearchContacts
                  search={searchTerm}
                  tags={searchContactTag}
                  onChange={setSelectedValue}
                />
              ) : (
                <AddContactModalSearchOrganization
                  search={searchTerm}
                  onChange={setSelectedValue}
                />
              )}
            </div>
            <hr className="mrgn-tp-0 mrgn-bttm-md" />
            <ul className="list-inline lst-spcd mrgn-bttm-0">
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-testid="form-button-add"
                  disabled={selectedValue == null}
                  onClick={onSave}
                >
                  <i className="fas fa-plus-circle" />
                  <span>&nbsp; {t("add")}</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={modalState.close}
                  className="btn btn-default"
                  data-testid="form-button-cancel"
                >
                  {t("cancel")}
                </button>
              </li>
            </ul>
          </ModalDialog>
        ) : null}
      </OverlayContainer>
    </>
  );
};

export default AddContactButtonAndModal;
