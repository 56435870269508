import * as React from "react";
import sanitizeHtml from "sanitize-html";

export interface SafeRenderHtmlProps {
  htmlString?: string | null
}

export const SafeRenderHtml: React.FC<SafeRenderHtmlProps> = ({htmlString}) => {
  if (!htmlString) return null;

  const sanitized = sanitizeHtml(htmlString);
  if (!sanitized) return null;

  return (
    <span dangerouslySetInnerHTML={{ __html: sanitized }} />
  );
};

export default SafeRenderHtml;
