import cc from "classcat";
import * as React from "react";

const LayoutRoot: React.FC<any> = (props) => {
  const { children, className } = props;
  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};

const LayoutContent: React.FC<any> = (props) => {
  const { children, className } = props;
  return (
    <main
      role="main"
      property="mainContentOfPage"
      {...props}
      className={cc(["container", className])}
    >
      {children}
    </main>
  );
};

// This Layout is used for the home pages.
const LayoutHomeContent: React.FC<any> = (props) => {
  const { children, className } = props;
  return (
    <main
      role="main"
      property="mainContentOfPage"
      {...props}
      className={className}
    >
      {children}
    </main>
  );
};

const Layout = {
  Root: LayoutRoot,
  Content: LayoutContent,
  HomeContent: LayoutHomeContent,
};

export default Layout;
