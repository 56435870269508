import { useQuery } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ResponseStatementHtmlDocument } from "../../../../generated/gql-types";
import LoadingIndicator from "../../../atoms/LoadingIndicator";
import SafeRenderHtml from "../../../atoms/SafeRenderHtml";
import ModalDialog from "../../../molecules/Modal";
import { Item, Tabs } from "../../Tabs/Tabs";

const HtmlButtonAndModal: React.FC<{ id: string }> = (props) => {
  const { t } = useTranslation();
  const modalState = useOverlayTriggerState({});

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        data-testid="button-html"
      >
        HTML
      </button>
      <OverlayContainer>
        {modalState.isOpen ? (
          <ModalDialog
            id="view_html_modal"
            title={t("view_html")}
            className={"modal-width-70"}
            onClose={modalState.close}
            isDismissable
          >
            <Tabs>
              <Item title={t("english") + " HTML"}>
                <EnglishHtml id={props.id} />
              </Item>
              <Item title={t("french") + " HTML"}>
                <FrenchHtml id={props.id} />
              </Item>
            </Tabs>
          </ModalDialog>
        ) : null}
      </OverlayContainer>
    </>
  );
};

const EnglishHtml = (props: { id: string }) => {
  const { loading, data, error } = useQuery(ResponseStatementHtmlDocument, {
    variables: {
      id: props.id,
      language: "en-CA",
    },
  });

  if (error) {
    return <p>Error fetching HTML</p>;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return <SafeRenderHtml htmlString={data?.responseStatementHtml?.html} />;
};

const FrenchHtml = (props: { id: string }) => {
  const { loading, data, error } = useQuery(ResponseStatementHtmlDocument, {
    variables: {
      id: props.id,
      language: "fr-CA",
    },
  });

  if (error) {
    return <p>Error fetching HTML</p>;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return <SafeRenderHtml htmlString={data?.responseStatementHtml?.html} />;
};

export default HtmlButtonAndModal;
