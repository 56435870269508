import FormButtons from "components/molecules/FormButtons/FormButtons";
import { CustomContact } from "components/organisms/contacts/types";
import {
  BilingualRichText,
  PhotoLicenseType,
  RichText,
  UriDocumentReference,
} from "generated/gql-types";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "util/forms";
import { PhotoLicenseDocumentInformationCard } from "./PhotoLicenseDocumentInformationCard";
import { setNullForEmptyFields } from "./utils";

export interface PhotoLicenseDocumentFormProps {
  initialValues: Partial<PhotoLicenseDocumentFormFields>;
  onSubmit: SubmitHandler<Partial<PhotoLicenseDocumentFormFields>>;
  onCancel: () => void;
}

export const PhotoLicenseDocumentForm: React.FC<
  PhotoLicenseDocumentFormProps
> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();

  const form = useForm<PhotoLicenseDocumentFormFields>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState, setValue, getValues } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<
    Partial<PhotoLicenseDocumentFormFields>
  > = async (formData) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, initialValues);

    await props.onSubmit(FilteredFormData);
    window.scrollTo(0, 0);
  };

  // Initialize License type to Full Use on Create form.
  if (initialValues.licenseType == null && getValues("licenseType") == null) {
    setValue("licenseType", PhotoLicenseType.FullUse, {
      shouldDirty: true,
    });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhotoLicenseDocumentInformationCard initialValues={initialValues} />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onCancel}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
};

export default PhotoLicenseDocumentForm;

///////////////////////////////////////////////////

export interface PhotoLicenseDocumentFormFields {
  title: BilingualRichText;
  licenseType: PhotoLicenseType;
  licenseFile: UriDocumentReference;
  photographer: CustomContact | null;
  note: RichText;
}
