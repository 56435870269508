import { OverlayContainer } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import {
  ContactType,
  CustomContact,
} from "components/organisms/contacts/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Contact,
  ContactTag,
  Organization,
} from "../../../../generated/gql-types";
import { TextInput } from "../../../atoms/forms/TextInput";
import ModalDialog from "../../../molecules/Modal";
import { FormContactDisplay } from "../FormContactDisplay";
import {
  AddContactModalSearchContacts,
  AddContactModalSearchOrganization,
} from "./AddContactModalSearch";

interface AddRemoveContactButtonAndModalProps {
  id: string;
  contact: CustomContact | null;
  onSave: (
    newValue: Contact | Organization | null | undefined,
    type: ContactType
  ) => void;
  onRemove: () => void;
  isPhotographer?: boolean;
  isContactOnly?: boolean; // Add Contact only and no Organization?
}

const AddRemoveContactButtonAndModal: React.FC<
  AddRemoveContactButtonAndModalProps
> = (props) => {
  const { isPhotographer = false, isContactOnly = false } = props;
  const { t } = useTranslation();
  const modalState = useOverlayTriggerState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState<ContactType>("contact");
  const [selectedValue, setSelectedValue] = useState<
    Contact | Organization | null | undefined
  >(null);

  useEffect(
    function resetSearchTermWhenTypeChanges() {
      setSearchTerm("");
    },
    [searchType]
  );

  useEffect(() => {
    setSelectedValue(null);
  }, [searchTerm, searchType]);

  const onOpen = () => {
    setSearchTerm("");
    setSearchType("contact");
    setSelectedValue(null);
    modalState.open();
  };

  const onSave = () => {
    props.onSave(selectedValue, searchType);
    modalState.close();
  };

  return props.contact != null ? (
    <div className="flex width-100 align-start justify-between">
      <FormContactDisplay contact={props.contact} />
      <button
        type="button"
        className="btn btn-link py-0 px-2"
        onClick={props.onRemove}
        data-testid="button-remove-contact"
      >
        <i className="far fa-times-circle mrgn-rght-sm"></i>
        {t("remove_contact")}
      </button>
    </div>
  ) : (
    <div>
      <button
        type="button"
        className="btn btn-link mrgn-0 py-0 px-2"
        onClick={onOpen}
        data-testid="button-add-contact"
      >
        <i className="fas fa-plus-circle mrgn-rght-sm"></i>
        {t("add_contact")}
      </button>

      {modalState.isOpen ? (
        <OverlayContainer>
          <ModalDialog
            id="add_contact_modal"
            data-testid="add_contact_modal"
            title={
              isContactOnly ? t("add_contact") : t("add_contact_organization")
            }
            className={"modal-width-70"}
            onClose={modalState.close}
            showModalFooter={false}
            // isDismissable
          >
            <div className="flex gap-md flex-wrap">
              <TextInput
                label={t("who_are_you_looking_for")}
                placeholder={t("enter_a_name_or_email")}
                id="search-for"
                inputClassName="width-100"
                divClassName="flex-auto "
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
              {!isContactOnly && (
                <div className="form-group">
                  <label htmlFor="contactTypeSelect">{t("type")}</label>
                  <select
                    id="contactTypeSelect"
                    className="form-control width-230"
                    onChange={(e) =>
                      setSearchType(e.currentTarget.value as ContactType)
                    }
                  >
                    <option value="contact">{t("contacts")}</option>
                    <option value="organization">{t("organizations")}</option>
                  </select>
                </div>
              )}
            </div>

            <div className="min-height-auto">
              {searchType === "contact" ? (
                <AddContactModalSearchContacts
                  search={searchTerm}
                  tags={isPhotographer ? ContactTag.Photographer : undefined}
                  onChange={setSelectedValue}
                />
              ) : (
                <AddContactModalSearchOrganization
                  search={searchTerm}
                  tags={isPhotographer ? "PHOTO" : undefined}
                  onChange={setSelectedValue}
                />
              )}
            </div>
            <hr className="mrgn-tp-0 mrgn-bttm-md" />
            <ul className="list-inline lst-spcd mrgn-bttm-0">
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-testid="form-button-save"
                  disabled={selectedValue == null}
                  onClick={onSave}
                >
                  <i className="fas fa-plus-circle" />
                  <span>&nbsp; {t("add")}</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={modalState.close}
                  className="btn btn-default"
                  data-testid="form-button-cancel"
                >
                  {t("cancel")}
                </button>
              </li>
            </ul>
          </ModalDialog>
        </OverlayContainer>
      ) : null}
    </div>
  );
};

export default AddRemoveContactButtonAndModal;
