import Autocomplete from "../../molecules/Autocomplete";
import { Item } from "@react-stately/collections";
import * as React from "react";
import { ReactElement } from "react";
import { useQueryAutocompleteInstance } from "./useQueryAutocompleteInstance";

export interface CustomQueryAutocompleteProps<
  TQuery extends TypeOfQueryData<any>
> {
  disabled?: boolean | undefined;
  useQueryAutocompleteInstance: ReturnType<typeof useQueryAutocompleteInstance>;
  id: string;
  label: string | ReactElement;
  additionalQueryVariables?: any;
  listDisplayFormatter: (listItem: any) => string;
  onSelectionChange: (item: any) => void;
  placeholder?: string;
  isRequired?: boolean;
  onClear?: () => void;
}

export const CustomQueryAutocomplete = <TQuery extends TypeOfQueryData<any>>(
  props: CustomQueryAutocompleteProps<TQuery>
) => {
  const { disabled = false } = props;
  const { list } = props.useQueryAutocompleteInstance;
  const onSelectionChange = (key: string | number) => {
    const item = list.getItem(key);
    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <>
      <Autocomplete
        id={props.id}
        label={props.label}
        items={list.items}
        inputValue={list.filterText}
        onInputChange={list.setFilterText}
        loadingState={list.loadingState}
        placeholder={props.placeholder}
        onLoadMore={list.loadMore}
        onSelectionChange={onSelectionChange}
        onClear={onClear}
        isRequired={props.isRequired}
        disabled={disabled}
      >
        {(item) => (
          <Item key={item.id}>{props.listDisplayFormatter(item)}</Item>
        )}
      </Autocomplete>
    </>
  );
};

export default CustomQueryAutocomplete;
export interface TypeOfQueryData<TItem> {
  autocompleteData?: {
    autocompleteItems?: TItem[];
    pagination: any; // TODO: pagination type
  };
}
