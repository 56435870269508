import { useTooltipTriggerState } from "@react-stately/tooltip";
import { TooltipTriggerProps } from "@react-types/tooltip";
import { Maybe } from "generated/gql-types";
import React, { useState } from "react";
import { useTooltipTrigger } from "react-aria";
import { useTranslation } from "react-i18next";
import { Tooltip } from "./Tooltip";

export interface CopyToClipboardButtonWithTooltipTriggerProps
  extends TooltipTriggerProps {
  label?: string;
  textToClipboard?: Maybe<string>;
}

export const CopyToClipboardButtonWithTooltip: React.FC<
  CopyToClipboardButtonWithTooltipTriggerProps
> = (props) => {
  const { t } = useTranslation();
  const { delay = 300, textToClipboard = "", label = t("copy") } = props;

  // Manages state for a tooltip trigger
  const state = useTooltipTriggerState({ ...props, delay });
  const ref = React.useRef(null);
  //   const clipboardStatic = React.useRef("");

  // Get props for the trigger and its tooltip
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);

  const [tooltipText, setTooltipText] = useState(undefined);
  // const [tooltipText, setTooltipText] = useState(t("copy_to_clipboard"));

  return (
    <>
      <span style={{ position: "relative" }}>
        <button
          ref={ref}
          {...triggerProps}
          data-testid="button-copy-to-clipboard"
          className="btn btn-primary btn-sm mrgn-rght-md"
          onClick={() => {
            navigator.clipboard.writeText(textToClipboard || "");
            setTooltipText(t("copied"));
            state.open();
          }}
          onBlur={() => {
            state.close(true);
            setTooltipText(undefined);
            // setTooltipText(t("copy_to_clipboard"));
          }}
          title={t("copy_to_clipboard")}
        >
          <i className="fas fa-copy mrgn-rght-sm" />
          {label}
        </button>
        {state.isOpen && tooltipText && (
          <Tooltip state={state} {...tooltipProps}>
            {tooltipText}
          </Tooltip>
        )}
      </span>
    </>
  );
};
