import { useOverviewContext } from "features/overview";
import * as React from "react";

// When Cosewic is published and listing is still null (No Listing menuitem found on sideNav),
// this hook will refetch the overview data until listing is created (max. 5 times),
// waiting 3 seconds between each refetch. If the listing is still null after
// 5 refetches, the hook will set the error state to true.

export const useCosewicRefetchOverview = (isCosewicPublished: boolean) => {
  const overviewContext = useOverviewContext();
  const [refetching, setRefetching] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    let interval: any = null;
    if (isCosewicPublished && overviewContext.listing === null) {
      let refetchCounter = 0;
      interval = setInterval(() => {
        if (refetchCounter < 5) {
          setRefetching(true);
          overviewContext.refetch();
          refetchCounter++;
          console.log("Refetching overview");
        } else {
          setRefetching(false);
          // setError(new Error("Could not fetch overview"));
          clearInterval(interval);
        }
      }, 3000);
    }
    return () => {
      if (interval) {
        setRefetching(false);
        clearInterval(interval);
      }
    };
  }, [isCosewicPublished, overviewContext.listing]);

  return {
    refetching,
    error,
  };
};

export default useCosewicRefetchOverview;
