import { OverlayContainer } from "@react-aria/overlays";
import { OverlayTriggerState } from "@react-stately/overlays";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "../../../../atoms/forms/FieldValidationError";
import ModalDialog from "../../../../molecules/Modal";
import { TextInput } from "components/atoms/forms/TextInput";

export interface CreateLandNameModalProps {
  modalState: OverlayTriggerState;
  onSubmit: (name: string) => void;
}

interface LandNameFormFields {
  name: string;
}

export const CreateLandNameModal: React.FC<CreateLandNameModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const { modalState } = props;

  const form = useForm<LandNameFormFields>();

  const { register, handleSubmit, formState } = form;
  const { isDirty, errors } = formState;

  const onSubmit: SubmitHandler<LandNameFormFields> = async (formData) => {
    props.onSubmit(formData.name);
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("create_a_place_name")}
        isOpen
        onClose={modalState.close}
        isDismissable
      >
        <form
          onSubmit={(e) => {
            // this component is a nested form; prevent default and stop propagation so we don't also submit the parent form.
            e.preventDefault();
            e.stopPropagation();
            return handleSubmit(onSubmit)(e);
          }}
        >
          <TextInput
            type="text"
            label={t("place_name")}
            id="landNameInput"
            inputClassName="full-width"
            placeholder={t("name_english")}
            required={true}
            {...register("name", {
              required: {
                value: true,
                message: t("field_is_required"),
              },
            })}
            errors={errors}
          />

          <button
            type="submit"
            disabled={!isDirty}
            className="btn btn-primary"
            data-testid="modal-button-save"
          >
            {t("save")}
          </button>
        </form>
      </ModalDialog>
    </OverlayContainer>
  );
};
