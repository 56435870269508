import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "../DatePicker";

export interface QueryFields {
  ministerReceiptDate: string;
}

export const defaultQueryFields = {
  ministerReceiptDate: "",
};

export interface ResponseStatementSearchFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const ResponseStatementSearchForm: React.FC<
  ResponseStatementSearchFormProps
> = (props) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, control, handleSubmit, setFocus, watch } = form;

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    console.log("newValues", newValues);
    props.onSubmit(newValues);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1-1-auto align-end flex-wrap pb-2"
    >
      <div className="mrgn-rght-sm">
        <Controller
          control={control}
          name="ministerReceiptDate"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id="dpk_ministerReceiptDate"
              required={false}
              label={t("minister_receipt_date")}
              value={value as string}
              className="width-230 min-width-fit-available line-height-normal"
              onInput={onChange}
            />
          )}
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-tp-sm"
        data-testid="button-search"
      >
        <span className="glyphicon glyphicon-search mrgn-rght-sm" />
        {t("search")}
      </button>
    </form>
  );
};

export default ResponseStatementSearchForm;
