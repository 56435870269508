import * as React from "react";
import { useTranslation } from "react-i18next";
import AddOrganizationPopup, { useOrganizationPopup } from "./";

const AddOrganizationBtn: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const popupState = useOrganizationPopup({});

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={popupState.open}
        data-testid="button-add"
      >
        {t("add")}
      </button>
      <AddOrganizationPopup modalState={popupState} {...props} />
    </>
  );
};

export default AddOrganizationBtn;
