const AdminTranslation = {
  en: {
    sar_dmts_properties: "Wildlife species properties",
    manage_genus: "Manage genus",
    manage_species: "Manage species",
    manage_subspecies: "Manage subspecies",
    manage_population: "Manage population",
    manage_variety: "Manage variety",
    manage_range: "Manage range",
    manage_taxonomic_group: "Manage taxonomic group",
    manage_lands: "Manage lands",

    document_management: "Document management",
    manage_response_statement_fields: "Manage response statement fields",

    contact_management: "Contact management",
    manage_contacts: "Manage contacts",
    manage_organizations: "Manage organizations",

    list_of_lands: "List of lands",

    authorization_management: "Authorization management",
    manage_users: "Manage users",

    bpt_management: "Business process tracking management",
    manage_bpt_templates: "Manage business process tracking templates",
  },

  fr: {
    sar_dmts_properties: "Propriétés de l'espèces sauvage",
    manage_genus: "Gérer le genre",
    manage_species: "Gérer l'espèce",
    manage_subspecies: "Gérer la sous-espèce",
    manage_population: "Gérer la population",
    manage_variety: "Gérer la variété",
    manage_range: "Gérer l'aire de répartition",
    manage_taxonomic_group: "Gérer le groupe taxinomique",
    manage_lands: "Gérer les terres",

    document_management: "Gestion du document",
    manage_response_statement_fields:
      "Gestion des champs de l'énoncé de réaction",

    contact_management: "Gestion des contacts",
    manage_contacts: "Gérer les contacts",
    manage_organizations: "Gérer les organisations",

    list_of_lands: "Liste des terres",

    authorization_management: "Gestion des autorisations",
    manage_users: "Gérer les utilisateurs",

    bpt_management: "[FR]Business process tracking management",
    manage_bpt_templates: "[FR]Manage business process tracking templates",
  },
};

export default AdminTranslation;
