import { Address, AddressInput } from "generated/gql-types";
import isNullOrEmpty from "util/isNullOrEmpty";

export function isNullOrEmptyAddressInput(input?: AddressInput | null) {
  let ret = true;
  if (input == null) return ret;

  if (input?.addressLines !== undefined) {
    if (input?.addressLines && input?.addressLines?.length > 0) {
      input?.addressLines.forEach((x) => {
        if (!isNullOrEmpty(x?.english) || !isNullOrEmpty(x?.french))
          ret = false;
      });
    }
  }

  if (
    !isNullOrEmpty(input?.city) ||
    !isNullOrEmpty(input?.provinceOrState) ||
    !isNullOrEmpty(input?.postalOrZipCode) ||
    !isNullOrEmpty(input?.countryCode)
  ) {
    ret = false;
  }

  return ret;
}

export function isNullOrEmptyAddress(input?: Address | null) {
  let ret = true;
  if (input == null) return ret;

  if (input?.addressLines !== undefined) {
    if (input?.addressLines && input?.addressLines?.length > 0) {
      input?.addressLines.forEach((x) => {
        if (!isNullOrEmpty(x?.english) || !isNullOrEmpty(x?.french))
          ret = false;
      });
    }
  }

  if (
    !isNullOrEmpty(input?.city) ||
    !isNullOrEmpty(input?.provinceOrState) ||
    !isNullOrEmpty(input?.provinceOrStateName?.english) ||
    !isNullOrEmpty(input?.provinceOrStateName?.french) ||
    !isNullOrEmpty(input?.postalOrZipCode) ||
    !isNullOrEmpty(input?.countryCode)
  ) {
    ret = false;
  }

  return ret;
}
