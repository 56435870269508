import {
  AutocompleteForLandManagerDocument,
  BilingualText,
  Land,
} from "generated/gql-types";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import makeQueryStringWithoutEncoding from "util/makeQueryStringWithoutEncoding";
import { useApolloClient } from "@apollo/client";
import pDebounce from "p-debounce";
import { formatPageNumber } from "../../../../../util/formatPageNumber";
import { SEARCH_DEBOUNCE_TIME_MS } from "../../../../../config/constants";
import { useAsyncList } from "@react-stately/data";
import Autocomplete from "../../../../molecules/Autocomplete";
import { Item } from "@react-stately/collections";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface LandManagerAutocompleteProps {
  initialLandManager?: BilingualText;
  onSelectionChange: (newLandManager?: Land) => void;
  id: string;
  label: string;
  isRequired?: boolean;
  onClear?: () => void;
  useLandManagerAutocompleteInstance: UseLandManagerAutocomplete;
  disabled?: boolean;
}

export interface UseLandManagerAutocomplete
  extends ReturnType<typeof useLandManagerAutocomplete> {}

export interface LandManagerAutocompleteWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<LandManagerAutocompleteProps, "onSelectionChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const useLandManagerAutocomplete = (
  landName?: string,
  landType?: string
) => {
  const client = useApolloClient();

  const landManagerFiltersQueryString = useMemo(() => {
    const filters = new URLSearchParams();
    if (landType) filters.set("landType", landType);
    if (landName) filters.set("landName", landName);

    return makeQueryStringWithoutEncoding(filters);
  }, [landType, landName]);

  const debouncedSearch = React.useMemo(() => {
    return pDebounce((pageNumber: number, filterText: string) => {
      return client.query({
        query: AutocompleteForLandManagerDocument,
        variables: {
          params: {
            pageNumber: formatPageNumber(pageNumber),
            search: filterText.length > 0 ? filterText : "",
            filters: landManagerFiltersQueryString,
            pageSize: 50,
          },
        },
      });
    }, SEARCH_DEBOUNCE_TIME_MS);
  }, [client, landManagerFiltersQueryString]);

  const list = useAsyncList<any>({
    async load({ cursor, filterText }: any) {
      // check for -1 to halt fetching the next page
      if (cursor === "-1") return { items: [], cursor: "-1" };

      const results = await debouncedSearch(cursor, filterText);
      const items = results?.data?.autocompleteData?.autocompleteItems ?? [];

      if (results?.data?.autocompleteData?.pagination?.hasNextPage === false) {
        return {
          items, // return the final set of items
          cursor: "-1", // Use -1 to halt fetching
        };
      }

      const newPageNumber =
        formatPageNumber(
          results?.data?.autocompleteData?.pagination?.currentPage
        ) + 1;

      return {
        items,
        cursor: newPageNumber.toString(),
      };
    },
  });

  useEffect(() => {
    list.reload();
  }, [landManagerFiltersQueryString]);

  const clear = () => {
    list.setFilterText("");
    list.setSelectedKeys(new Set());
  };

  return { clear, landName, landType, list };
};

export const LandManagerAutocomplete = (
  props: LandManagerAutocompleteProps
) => {
  const { i18n } = useTranslation();

  const formatName = useCallback(
    (item?: any) => {
      if (item == null) return "";
      if (item.landManager != null) {
        if (isNullOrEmpty(item?.landManager?.english)) {
          return `${item?.landManager?.french}`;
        } else if (isNullOrEmpty(item?.landManager?.french)) {
          return `${item?.landManager?.english}`;
        }
        return `${item?.landManager?.english}  /  ${item?.landManager?.french}`;
      }
      if (isNullOrEmpty(item?.english)) {
        return `${item?.french}`;
      } else if (isNullOrEmpty(item?.french)) {
        return `${item?.english}`;
      }
      return `${item?.english}  /  ${item?.french}`;
    },
    [i18n.language]
  );

  const onSelectionChange = (key: string | number) => {
    const item = props.useLandManagerAutocompleteInstance.list.getItem(key);
    props.onSelectionChange(item);
  };

  const onClear = () => {
    props.useLandManagerAutocompleteInstance.list.setSelectedKeys(new Set([]));
    props.useLandManagerAutocompleteInstance.list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <>
      <Autocomplete
        id={props.id}
        label={props.label}
        items={props.useLandManagerAutocompleteInstance.list.items}
        inputValue={props.useLandManagerAutocompleteInstance.list.filterText}
        onInputChange={
          props.useLandManagerAutocompleteInstance.list.setFilterText
        }
        loadingState={
          props.useLandManagerAutocompleteInstance.list.loadingState
        }
        placeholder={formatName(props.initialLandManager)}
        onLoadMore={props.useLandManagerAutocompleteInstance.list.loadMore}
        onSelectionChange={onSelectionChange}
        onClear={onClear}
        isRequired={props.isRequired}
        disabled={props.disabled}
      >
        {(item) => <Item key={item.id}>{formatName(item)}</Item>}
      </Autocomplete>
    </>
  );
};

export const LandManagerAutoCompleteWithController = <
  TFieldValues extends FieldValues
>({
  label,
  id,
  isRequired,
  onClear,
  name,
  useLandManagerAutocompleteInstance,
  ...props
}: LandManagerAutocompleteWithControllerProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      rules={{ required: isRequired }}
      //{...props}
      render={({ field: { value, onChange } }: any) => (
        <LandManagerAutocomplete
          isRequired={isRequired}
          id={id}
          useLandManagerAutocompleteInstance={
            useLandManagerAutocompleteInstance
          }
          initialLandManager={value}
          onSelectionChange={onChange}
          label={label}
          onClear={onClear}
        />
      )}
    />
  );
};
