import * as React from "react";
import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BilingualAbbreviationAcronymLookupText } from "generated/gql-types";
import Autocomplete from "components/molecules/Autocomplete";
import { Item } from "@react-stately/collections";
import type { UseFederalCanadaPlacesAutocomplete } from "./useFederalCanadaPlacesAutocomplete";

interface FederalParksAutocompleteProps {
  onSelectionChange: (
    selectedItem?: BilingualAbbreviationAcronymLookupText
  ) => void;
  initialValue?: BilingualAbbreviationAcronymLookupText;
  label?: string | ReactElement;
  Placeholder?: string;
  useFederalCanadaPlacesAutocompleteInstance: UseFederalCanadaPlacesAutocomplete;
  isRequired?: boolean;
  onClear?: () => void;
  disabled?: boolean;
}

const FederalParksAutocomplete: React.FC<FederalParksAutocompleteProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  const { list } = props.useFederalCanadaPlacesAutocompleteInstance;

  const parseInitialValues = (
    language: string,
    initialParks?: BilingualAbbreviationAcronymLookupText
  ): string => {
    if (!initialParks) return "";
    if (language === "fr") return initialParks.name?.french ?? "";
    return initialParks.name?.english ?? "";
  };

  const defaultValue = React.useMemo(
    () => parseInitialValues(i18n.language, props.initialValue),
    [i18n.language, props.initialValue]
  );

  const federalCanadaPlacesNameForLanguage = React.useCallback(
    (item: BilingualAbbreviationAcronymLookupText) => {
      if (i18n.language === "fr") {
        return {
          id: item?.id,
          name: item?.name?.french,
          abbreviation: item?.abbreviation?.french,
        };
      }

      return {
        id: item?.id,
        name: item?.name?.english,
        abbreviation: item?.abbreviation?.english,
      };
    },
    [i18n.language]
  );

  const onSelectionChange = (key: string | number) => {
    const item = list.getItem(key);
    if (item == null) {
      props.onSelectionChange(undefined);
      return;
    }

    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <Autocomplete
      items={list.items}
      label={props.label}
      inputValue={list.filterText}
      defaultInputValue={defaultValue}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      onSelectionChange={onSelectionChange}
      placeholder={t("select_parks_canada_places")}
      onClear={onClear}
      disabled={props.disabled}
      isForMultiSelection={true}
    >
      {(item: BilingualAbbreviationAcronymLookupText) => {
        const name = federalCanadaPlacesNameForLanguage(item).name ?? "error";
        const abbreviation =
          federalCanadaPlacesNameForLanguage(item).abbreviation;
        let item_value = name;
        if (abbreviation) item_value += ", " + abbreviation;

        return (
          <Item key={item.id} textValue={item_value}>
            <span className="font-size-16">{item_value}</span>
          </Item>
        );
      }}
    </Autocomplete>
  );
};

export default FederalParksAutocomplete;
