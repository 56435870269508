import React, { useLayoutEffect, useRef, useState } from "react";
import { mergeProps, useTooltip } from "react-aria";

export const Tooltip: React.FC<any> = (props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [marginLeft, setMarginLeft] = useState(0);
  const { tooltipProps } = useTooltip(props);

  useLayoutEffect(() => {
    if (!ref?.current?.clientWidth) return;
    setMarginLeft((ref?.current?.clientWidth / 2) * -1);
  }, []);

  return (
    <div
      ref={ref}
      role="tooltip"
      data-testid="tooltip-top"
      className="in tooltip top"
      style={{
        position: "absolute",
        left: "50%",
        bottom: "120%",
        width: "max-content",
        maxWidth: "200px",
        marginLeft: marginLeft,
        fontSize: "14px",
      }}
      {...mergeProps(props, tooltipProps)}
    >
      <div className="tooltip-arrow"></div>
      <div className="tooltip-inner">{props.children}</div>
    </div>
  );
};
