import MissingData from "components/atoms/MissingData";
import { Gender, ListingWs, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../../atoms/SafeRenderHtml";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";

export interface WildlifeSpeciesInformationSectionProps {
  listingWs?: Maybe<ListingWs>;
}

export const WildlifeSpeciesInformationSection: React.FC<
  WildlifeSpeciesInformationSectionProps
> = (props) => {
  const { listingWs } = props;
  const { t, i18n } = useTranslation();

  const taxonomicGroupString =
    i18n.language === "fr"
      ? listingWs?.taxonomicGroup?.nameFr
      : listingWs?.taxonomicGroup?.nameEn;

  const rangesString =
    i18n.language === "fr"
      ? listingWs?.ranges?.map((x) => x?.nameFr).join(", ")
      : listingWs?.ranges?.map((x) => x?.nameEn).join(", ");

  const regionString =
    i18n.language === "fr"
      ? listingWs?.regions?.map((x) => x?.nameFr).join(", ")
      : listingWs?.regions?.map((x) => x?.nameEn).join(", ");

  const scientificName = listingWs?.scientificName;
  const formattedScientificName =
    scientificName?.generatedScientificName?.name?.text;
  
  const showTooltipAlertForGender = listingWs?.gender == null || 
    listingWs?.gender === Gender.NotInitialized

  return (
    <div className="species-field">
      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("listing_id")}</dt>
            <dd>{listingWs?.listingId ?? <MissingData />}</dd>
          </div>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("schedule")}</dt>
            <dd>
              {listingWs?.schedule ? t(listingWs?.schedule) : <MissingData />}
            </dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("minister_receipt_date")}</dt>
            <dd>
              {formatTimestamp(listingWs?.dateSentToMinister) ?? (
                <MissingData />
              )}
            </dd>
          </div>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("scientific_name")}</dt>
            <dd>
              {htmlIsNullOrEmpty(formattedScientificName) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={formattedScientificName!} />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{`${t("common_name")} (${t(
              "english"
            )})`}</dt>
            <dd>
              {htmlIsNullOrEmpty(listingWs?.commonNameEnglish?.name?.text) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml
                  htmlString={listingWs?.commonNameEnglish?.name?.text!}
                />
              )}
            </dd>
          </div>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{`${t("common_name")} (${t(
              "french"
            )})`}</dt>
            <dd>
              {htmlIsNullOrEmpty(listingWs?.commonNameFrench?.name?.text) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml
                  htmlString={listingWs?.commonNameFrench?.name?.text!}
                />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{`${t("population")} (${t(
              "english"
            )})`}</dt>
            <dd>{listingWs?.population?.nameEn ?? <MissingData />}</dd>
          </div>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{`${t("population")} (${t(
              "french"
            )})`}</dt>
            <dd>{listingWs?.population?.nameFr ?? <MissingData />}</dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("name_with_comma")}</dt>
            <dd>
              {htmlIsNullOrEmpty(listingWs?.nameWithComma?.name?.text) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml
                  htmlString={listingWs?.nameWithComma?.name?.text!}
                />
              )}
            </dd>
          </div>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("endemic_status")}</dt>
            <dd>
              {listingWs?.endemicStatus && listingWs?.endemicStatus !== "0" ? (
                t(listingWs?.endemicStatus)
              ) : (
                <MissingData />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("taxonomicgroup")}</dt>
            <dd>{taxonomicGroupString ?? <MissingData />}</dd>
          </div>
          <div className="species-data col-sm-6">
            <dt className="text-muted">
              {t("gender")}
                <TooltipAlert
                  isVisible={showTooltipAlertForGender}
                >
                  {t("required_to_complete_listing_process_tooltip")}
                </TooltipAlert></dt>
            <dd>
              {listingWs?.gender &&
              listingWs?.gender !== Gender.NotInitialized ? (
                t(listingWs?.gender)
              ) : (
                <MissingData />
              )}
            </dd>
          </div>
        </dl>
      </div>

      <div className="row">
        <dl>
          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("range")}</dt>
            <dd>
              {isNullOrEmpty(rangesString) ? <MissingData /> : rangesString}
            </dd>
          </div>

          <div className="species-data col-sm-6">
            <dt className="text-muted">{t("region")}</dt>
            <dd>
              {isNullOrEmpty(regionString) ? <MissingData /> : regionString}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default WildlifeSpeciesInformationSection;
