import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import PopulationAutocomplete from "components/organisms/admin/PopulationAutocomplete";
import RangeCheckboxes from "components/organisms/cosewic/RangeCheckboxes";
import { MAX_LENGTH_OF_NAME } from "config/constants";
import {
  ChangeTracking,
  useChangeTrackingContext,
} from "features/changeTracking";
import type { ChangeMetaData } from "features/changeTracking/types";
import {
  BilingualName,
  CommonNameInput,
  CosewicWsStatus,
  IdNameType,
  NameWithCommaInput,
  RichTextInput,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CosewicEndemicStatus } from "types/cosewic/enums";
import { htmlToPlainText } from "util/richtext";
import { RenderWhenAuthorized } from "../../../../features/auth/components";
import { ROLE_ACTIONS } from "../../../../features/auth/roles";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import Alert, { AlertTypes } from "../../../atoms/Alert";
import SectionCard from "../../../atoms/SectionCard";
import {
  HTMLItalicsInput,
  HTMLItalicsInputWithController,
} from "../../../molecules/HTMLItalicsInput";
import CreateGenusModalWithButton from "../../admin/CreateGenusModal/ButtonWithModal";
import CreatePopulationModalWithButton from "../../admin/CreatePopulationModal/ButtonWithModal";
import CreateSpeciesModalWithButton from "../../admin/CreateSpeciesModal/ButtonWithModal";
import CreateSubspeciesModalWithButton from "../../admin/CreateSubspeciesModal/ButtonWithModal";
import CreateTaxonomicGroupModalWithButton from "../../admin/CreateTaxonomicGroupModal/ButtonWithModal";
import CreateVarietyModalWithButton from "../../admin/CreateVarietyModal/ButtonWithModal";
import GenusAutocomplete from "../../admin/GenusAutocomplete";
import SpeciesAutocomplete from "../../admin/SpeciesAutocomplete";
import SubspeciesAutocomplete from "../../admin/SubspeciesAutocomplete";
import TaxonomicGroupAutocomplete from "../../admin/TaxonomicGroupAutocomplete";
import VarietyAutocomplete from "../../admin/VarietyAutocomplete";
import { NameChangeAssessmentRadioWithController } from "../NameChangeAssessmentRadio/NameChangeAssessmentRadio";
import { processCosewicWsFormData } from "./util";

const CosewicWsForm: React.FC<CosewicWsFormProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const form = useForm<CosewicWsFormFields & CosewicWsFormChangeTracking>({
    defaultValues: initialValues,
  });
  const { handleSubmit, register, formState, watch, control } = form;
  const changeTracking = useChangeTrackingContext();

  const otherRecognizedCommonNameEnglishFieldArray = useFieldArray({
    control,
    name: "otherRecognizedCommonNameEnglish",
  });
  const otherRecognizedCommonNameFrenchFieldArray = useFieldArray({
    control,
    name: "otherRecognizedCommonNameFrench",
  });
  const otherRecognizedScientificNameFieldArray = useFieldArray({
    control,
    name: "otherRecognizedScientificName",
  });
  const indigenousNameFieldArray = useFieldArray({
    control,
    name: "indigenousName",
  });
  const { dirtyFields, isDirty, errors } = formState;

  // Change tracking has to be enabled, to indicate that this is a permanent WS, and not a create form.
  const showNameChangeLinkToAssessmentField =
    changeTracking.isEnabled &&
    ((dirtyFields.commonNameEnglish?.name as any)?.text === true ||
      (dirtyFields.commonNameFrench?.name as any)?.text === true ||
      (dirtyFields.nameWithComma?.name as any)?.text === true ||
      dirtyFields.population === true ||
      dirtyFields.genus === true ||
      dirtyFields.species === true ||
      dirtyFields.subSpecies === true ||
      dirtyFields.variety === true);

  const onSubmit: SubmitHandler<Partial<CosewicWsFormOutput>> = async (
    formData
  ) => {
    const processedFormData = await processCosewicWsFormData(
      formData,
      dirtyFields
    );
    props.onSubmit(processedFormData);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: props.cosewicWsId
        ? CosewicPathHelpers.CosewicProfile(props.cosewicWsId)
        : CosewicPathHelpers.Home,
    });
  };

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_NAME;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionCard collapsible open header={<h2>{t("cosewic_ws_name")}</h2>}>
          <div className="flex-col gap-md">
            <div>
              <h3 className="mrgn-tp-md">{t("common_name")}</h3>
              <hr className="mrgn-0" />
            </div>

            <div>
              <label htmlFor="commonNameEnglishInput" className="required">
                {t("common_name")} {t("english")}{" "}
                <strong className="required">({"required"})</strong>
              </label>

              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  required: true,
                  validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
                }}
                id="commonNameEnglishInput"
                name="commonNameEnglish.name.text"
                defaultValue={
                  (initialValues.commonNameEnglish?.name as any)?.text
                }
                placeholder={`${t("common_name")} ${t("english")}`}
              />

              {/*TODO: id, placeholder, aria props, focus when clicking label*/}

              {errors.commonNameEnglish &&
                (errors.commonNameEnglish?.name as any)?.text?.type ===
                  "required" && (
                  <FieldValidationError>
                    {t("field_is_required")}
                  </FieldValidationError>
                )}

              {errors.commonNameEnglish &&
                (errors.commonNameEnglish?.name as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_NAME,
                    })}
                  </FieldValidationError>
                )}

              <ChangeTracking
                control={control}
                changeMetaDataName="commonNameEnglishChangeMetaData"
                cachedValue={initialValues.commonNameEnglish?.name?.text}
                trackedValue={watch("commonNameEnglish.name.text")}
              />
            </div>

            {errors.commonNameEnglishChangeMetaData?.changeType ||
              (errors.commonNameEnglishChangeMetaData?.reasonForChange && (
                <FieldValidationError>
                  Change Tracking - {t("field_is_required")}
                </FieldValidationError>
              ))}

            <div>
              {props?.status === CosewicWsStatus.Permanent && (
                <label htmlFor="commonNameFrenchInput" className="required">
                  {t("common_name")} {t("french")}{" "}
                  <strong className="required">({"required"})</strong>
                </label>
              )}
              {props?.status !== CosewicWsStatus.Permanent && (
                <label htmlFor="commonNameFrenchInput">
                  {t("common_name")} {t("french")}
                </label>
              )}

              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  required: props?.status === CosewicWsStatus.Permanent,
                  validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
                }}
                id="commonNameFrenchInput"
                name="commonNameFrench.name.text"
                defaultValue={
                  (initialValues.commonNameFrench?.name as any)?.text
                }
                placeholder={`${t("common_name")} ${t("french")}`}
              />

              {errors.commonNameFrench &&
                (errors.commonNameFrench?.name as any)?.text?.type ===
                  "required" && (
                  <FieldValidationError>
                    {t("field_is_required")}
                  </FieldValidationError>
                )}
              {errors.commonNameFrench &&
                (errors.commonNameFrench?.name as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_NAME,
                    })}
                  </FieldValidationError>
                )}

              <ChangeTracking
                control={control}
                changeMetaDataName="commonNameFrenchChangeMetaData"
                cachedValue={initialValues.commonNameFrench?.name?.text}
                trackedValue={watch("commonNameFrench.name.text")}
              />
            </div>

            <div>
              <label htmlFor="nameWithCommaInput" className="required">
                {t("name_with_comma")}{" "}
                <strong className="required">({t("required")})</strong>
              </label>

              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  required: true,
                  validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
                }}
                id="nameWithCommaInput"
                name="nameWithComma.name.text"
                defaultValue={(initialValues.nameWithComma.name as any)?.text}
                placeholder={`${t("name_with_comma")}`}
              />

              {errors.nameWithComma &&
                (errors.nameWithComma?.name as any)?.text?.type ===
                  "required" && (
                  <FieldValidationError>
                    {t("field_is_required")}
                  </FieldValidationError>
                )}

              {errors.nameWithComma &&
                (errors.nameWithComma?.name as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_NAME,
                    })}
                  </FieldValidationError>
                )}

              <ChangeTracking
                control={control}
                changeMetaDataName="nameWithCommaChangeMetaData"
                cachedValue={(initialValues.nameWithComma.name as any)?.text}
                trackedValue={watch("nameWithComma.name.text")}
              />
            </div>

            <div>
              <h3>{t("population")}</h3>
              <hr className="mrgn-0" />
            </div>

            <div>
              <div className="flex align-end gap-md">
                <Controller
                  control={control}
                  name="population"
                  render={({ field: { onChange, value } }) => (
                    <PopulationAutocomplete
                      label={t("select_a_population")}
                      initialPopulation={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />

                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.population.create
                  }
                >
                  <CreatePopulationModalWithButton />
                </RenderWhenAuthorized>
              </div>

              {errors.population && (
                <FieldValidationError>
                  {JSON.stringify(errors.population)}
                </FieldValidationError>
              )}

              <ChangeTracking
                control={control}
                changeMetaDataName="populationChangeMetaData"
                cachedValue={initialValues.population.id}
                trackedValue={watch("population.id")}
              />
            </div>

            <div>
              <fieldset className="chkbxrdio-grp">
                <legend>
                  <span className="field-name">{t("endemic_status")}</span>
                </legend>
                <label htmlFor="endemicStatus_yes" className="radio-inline">
                  <input
                    {...register("endemicStatus", { required: true })}
                    id="endemicStatus_yes"
                    name="endemicStatus"
                    type="radio"
                    value={CosewicEndemicStatus.Yes}
                  />
                  <span className="mrgn-lft-sm">
                    {t(CosewicEndemicStatus.Yes)}
                  </span>
                </label>
                <label htmlFor="endemicStatus_no" className="radio-inline">
                  <input
                    {...register("endemicStatus", { required: true })}
                    id="endemicStatus_no"
                    name="endemicStatus"
                    type="radio"
                    value={CosewicEndemicStatus.No}
                  />
                  <span className="mrgn-lft-sm">
                    {t(CosewicEndemicStatus.No)}
                  </span>
                </label>
                <label
                  htmlFor="endemicStatus_notDetermined"
                  className="radio-inline"
                >
                  <input
                    {...register("endemicStatus", { required: true })}
                    id="endemicStatus_notDetermined"
                    name="endemicStatus"
                    type="radio"
                    value={CosewicEndemicStatus.NotDetermined}
                  />
                  <span className="mrgn-lft-sm">
                    {t(CosewicEndemicStatus.NotDetermined)}
                  </span>
                </label>
              </fieldset>

              {errors.endemicStatus && (
                <FieldValidationError>
                  {JSON.stringify(errors.endemicStatus)}
                </FieldValidationError>
              )}

              <ChangeTracking
                control={control}
                changeMetaDataName="endemicStatusChangeMetaData"
                cachedValue={initialValues.endemicStatus}
                trackedValue={watch("endemicStatus")}
              />
            </div>

            <div>
              <div className="flex align-end gap-md">
                <Controller
                  control={control}
                  name="genus"
                  rules={{
                    required: true,
                    validate: (value) => !isNullOrEmpty(value.name),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <GenusAutocomplete
                      label={t("genus")}
                      isRequired
                      initialGenus={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />

                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.genus.create}
                >
                  <CreateGenusModalWithButton />
                </RenderWhenAuthorized>
              </div>

              {errors.genus && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}

              <ChangeTracking
                control={control}
                changeMetaDataName="genusChangeMetaData"
                cachedValue={initialValues.genus.id}
                trackedValue={watch("genus.id")}
              />
            </div>

            <div>
              <div className="flex align-end gap-md">
                <Controller
                  control={control}
                  name="species"
                  rules={{
                    required: props?.status === CosewicWsStatus.Permanent,
                    validate: (value) => {
                      if (props?.status === CosewicWsStatus.Permanent) {
                        return !isNullOrEmpty(value.name);
                      }
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <SpeciesAutocomplete
                      label={t("species")}
                      isRequired={props?.status === CosewicWsStatus.Permanent}
                      initialSpecies={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />

                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.species.create}
                >
                  <CreateSpeciesModalWithButton
                    refetchQueries={["SpeciesAutocompleteList"]}
                  />
                </RenderWhenAuthorized>
              </div>

              {errors.species && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}

              <ChangeTracking
                control={control}
                changeMetaDataName="speciesChangeMetaData"
                cachedValue={initialValues.species.id}
                trackedValue={watch("species.id")}
              />
            </div>

            <div>
              <div className="flex align-end gap-md">
                <Controller
                  control={control}
                  name="subSpecies"
                  render={({ field: { onChange, value } }) => (
                    <SubspeciesAutocomplete
                      label={t("subspecies")}
                      initialSubspecies={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />

                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.subspecies.create
                  }
                >
                  <CreateSubspeciesModalWithButton
                    refetchQueries={["SubspeciesAutocompleteList"]}
                  />
                </RenderWhenAuthorized>
              </div>

              {errors.subSpecies && (
                <FieldValidationError>
                  {JSON.stringify(errors.subSpecies)}
                </FieldValidationError>
              )}
              <div className="mrgn-tp-md">
                <Alert
                  className="mrgn-bttm-0"
                  type={AlertTypes.INFO}
                  content={t("ssp_msg")}
                />
              </div>

              <ChangeTracking
                control={control}
                changeMetaDataName="subSpeciesChangeMetaData"
                cachedValue={initialValues.subSpecies.id}
                trackedValue={watch("subSpecies.id")}
              />
            </div>

            <div>
              <div className="flex align-end gap-md">
                <Controller
                  control={control}
                  name="variety"
                  render={({ field: { onChange, value } }) => (
                    <VarietyAutocomplete
                      label={t("variety")}
                      initialVariety={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />

                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.variety.create}
                >
                  <CreateVarietyModalWithButton
                    refetchQueries={["VarietyAutocompleteList"]}
                  />
                </RenderWhenAuthorized>
              </div>

              {errors.variety && (
                <FieldValidationError>
                  {JSON.stringify(errors.variety)}
                </FieldValidationError>
              )}
              <div className="mrgn-tp-md">
                <Alert
                  className="mrgn-bttm-0"
                  type={AlertTypes.INFO}
                  content={t("var_msg")}
                />
              </div>
              <ChangeTracking
                control={control}
                changeMetaDataName="varietyChangeMetaData"
                cachedValue={initialValues.variety.id}
                trackedValue={watch("variety.id")}
              />
            </div>
          </div>
        </SectionCard>

        <SectionCard
          collapsible
          open={false}
          header={<h2>{t("other_recognized_names")}</h2>}
        >
          <div className="flex-col gap-md">
            <div>
              <label htmlFor="otherRecognizedCommonNameEnglish.0.text">
                {t("other_recognized_english_common_names")}
              </label>

              <div className="flex-col gap-sm">
                {otherRecognizedCommonNameEnglishFieldArray.fields.map(
                  (field, index) => (
                    <Controller
                      key={field.id}
                      control={control}
                      rules={{
                        validate: {
                          maxLengthLimit: validateHtmlTextMaxLengthLimit,
                        },
                      }}
                      name={
                        `otherRecognizedCommonNameEnglish.${index}.text` as const
                      }
                      render={({ field: { name, value, onChange } }) => (
                        <div>
                          <div className="flex width-100 align-center">
                            <HTMLItalicsInput
                              defaultValue={value as any}
                              id={name}
                              onChange={onChange}
                              className="width-100"
                              placeholder={t(
                                "other_recognized_names_placeholder"
                              )}
                            />
                            <span
                              className="glyphicon glyphicon-remove mrgn-lft-sm font-size-12 pointer color-danger"
                              onClick={() =>
                                otherRecognizedCommonNameEnglishFieldArray.remove(
                                  index
                                )
                              }
                              tabIndex={0}
                            />
                          </div>
                          {(errors.otherRecognizedCommonNameEnglish as any)?.[
                            index
                          ] && (
                            <FieldValidationError>
                              {t("reach_the_max_length", {
                                count: MAX_LENGTH_OF_NAME,
                              })}
                            </FieldValidationError>
                          )}
                        </div>
                      )}
                    />
                  )
                )}

                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      otherRecognizedCommonNameEnglishFieldArray.append({
                        text: "",
                      })
                    }
                    data-testid="add-other-recognized-common-name-english"
                  >
                    {t("add_name")}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="otherRecognizedCommonNameFrench.0.text">
                {t("other_recognized_french_common_names")}
              </label>

              <div className="flex-col gap-sm">
                {otherRecognizedCommonNameFrenchFieldArray.fields.map(
                  (field, index) => (
                    <Controller
                      key={field.id}
                      control={control}
                      rules={{
                        validate: {
                          maxLengthLimit: validateHtmlTextMaxLengthLimit,
                        },
                      }}
                      name={
                        `otherRecognizedCommonNameFrench.${index}.text` as const
                      }
                      render={({ field: { name, value, onChange } }) => (
                        <div>
                          <div className="flex width-100 align-center">
                            <HTMLItalicsInput
                              id={name}
                              defaultValue={value as any}
                              onChange={onChange}
                              className="width-100"
                              placeholder={t(
                                "other_recognized_names_placeholder"
                              )}
                            />
                            <span
                              className="glyphicon glyphicon-remove mrgn-lft-sm font-size-12 pointer color-danger"
                              onClick={() =>
                                otherRecognizedCommonNameFrenchFieldArray.remove(
                                  index
                                )
                              }
                              tabIndex={0}
                            />
                          </div>
                          {(errors.otherRecognizedCommonNameFrench as any)?.[
                            index
                          ] && (
                            <FieldValidationError>
                              {t("reach_the_max_length", {
                                count: MAX_LENGTH_OF_NAME,
                              })}
                            </FieldValidationError>
                          )}
                        </div>
                      )}
                    />
                  )
                )}

                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      otherRecognizedCommonNameFrenchFieldArray.append({
                        text: "",
                      })
                    }
                    data-testid="add-other-recognized-common-name-french"
                  >
                    {t("add_name")}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="otherRecognizedScientificName.0">
                {t("other_recognized_scientific_names")}
              </label>

              <div className="flex-col gap-sm">
                {otherRecognizedScientificNameFieldArray.fields.map(
                  (field, index) => (
                    <Controller
                      key={field.id}
                      control={control}
                      rules={{
                        validate: {
                          maxLengthLimit: validateHtmlTextMaxLengthLimit,
                        },
                      }}
                      name={
                        `otherRecognizedScientificName.${index}.text` as const
                      }
                      render={({ field: { name, value, onChange } }) => (
                        <div>
                          <div className="flex width-100 align-center">
                            <HTMLItalicsInput
                              id={name}
                              defaultValue={value as any}
                              onChange={onChange}
                              className="width-100"
                              placeholder={t(
                                "other_recognized_names_placeholder"
                              )}
                            />
                            <span
                              className="glyphicon glyphicon-remove mrgn-lft-sm font-size-12 pointer color-danger"
                              onClick={() =>
                                otherRecognizedScientificNameFieldArray.remove(
                                  index
                                )
                              }
                              tabIndex={0}
                            />
                          </div>
                          {(errors.otherRecognizedScientificName as any)?.[
                            index
                          ] && (
                            <FieldValidationError>
                              {t("reach_the_max_length", {
                                count: MAX_LENGTH_OF_NAME,
                              })}
                            </FieldValidationError>
                          )}
                        </div>
                      )}
                    />
                  )
                )}

                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      otherRecognizedScientificNameFieldArray.append({
                        text: "",
                      })
                    }
                    data-testid="add-other-recognized-scientific-name"
                  >
                    {t("add_name")}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="indigenousName.0">{t("indigenous_names")}</label>

              <div className="flex-col gap-sm">
                {indigenousNameFieldArray.fields.map((field, index) => (
                  <Controller
                    key={field.id}
                    control={control}
                    rules={{
                      validate: {
                        maxLengthLimit: validateHtmlTextMaxLengthLimit,
                      },
                    }}
                    name={`indigenousName.${index}.text` as const}
                    render={({ field: { name, value, onChange } }) => (
                      <div>
                        <div className="flex width-100 align-center">
                          <HTMLItalicsInput
                            id={name}
                            defaultValue={value as any}
                            onChange={onChange}
                            className="width-100"
                            placeholder={t(
                              "other_recognized_names_placeholder"
                            )}
                          />
                          <span
                            className="glyphicon glyphicon-remove mrgn-lft-sm font-size-12 pointer color-danger"
                            onClick={() =>
                              indigenousNameFieldArray.remove(index)
                            }
                            tabIndex={0}
                          />
                        </div>
                        {(errors.indigenousName as any)?.[index] && (
                          <FieldValidationError>
                            {t("reach_the_max_length", {
                              count: MAX_LENGTH_OF_NAME,
                            })}
                          </FieldValidationError>
                        )}
                      </div>
                    )}
                  />
                ))}

                <div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      indigenousNameFieldArray.append({
                        text: "",
                      })
                    }
                    data-testid="add-other-indigenous-name"
                  >
                    {t("add_name")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SectionCard>

        {showNameChangeLinkToAssessmentField ? (
          <SectionCard
            header={
              <h2 aria-hidden hidden>
                {t("select_an_assessment")}
              </h2>
            }
            showLine={false}
          >
            <fieldset className="chkbxrdio-grp">
              <legend className="required">
                <span className="field-name">{t("select_an_assessment")}</span>{" "}
                <strong className="required">({"required"})</strong>
              </legend>
              <NameChangeAssessmentRadioWithController
                id="nameChangedLinkedToAssessmentId"
                name="nameChangedLinkedToAssessmentId"
                control={control}
                cosewicId={props.cosewicWsId!}
              />
            </fieldset>
            {errors.nameChangedLinkedToAssessmentId && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </SectionCard>
        ) : null}

        <SectionCard
          collapsible
          open={false}
          header={<h2>{t("basic_information")}</h2>}
        >
          <div className="flex-col gap-md">
            <div>
              <div className="flex align-end gap-md">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => {
                      if (
                        !value.id ||
                        value.id === "" ||
                        !value.nameEn ||
                        value.nameEn === "" ||
                        !value.nameFr ||
                        value.nameFr === ""
                      ) {
                        return false;
                      }
                      return true;
                    },
                  }}
                  name="taxonomicGroup"
                  render={({ field: { onChange, value } }) => (
                    <TaxonomicGroupAutocomplete
                      isRequired
                      label={t("taxonomic_group")}
                      initialTaxonomicGroup={value}
                      onSelectionChange={onChange}
                    />
                  )}
                />
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.taxonomicGroup.create
                  }
                >
                  <CreateTaxonomicGroupModalWithButton
                    refetchQueries={["TaxonomicGroupSelectList"]}
                  />
                </RenderWhenAuthorized>
              </div>

              {errors.taxonomicGroup && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}

              <ChangeTracking
                control={control}
                changeMetaDataName="taxonomicGroupChangeMetaData"
                cachedValue={initialValues.taxonomicGroup.id}
                trackedValue={watch("taxonomicGroup.id")}
              />
            </div>

            <div>
              <fieldset className="chkbxrdio-grp">
                <legend>
                  <span className="field-name">{t("range")}</span>
                </legend>
                <Controller
                  name={"ranges"}
                  render={({ field: { onChange } }) => (
                    <RangeCheckboxes
                      onChange={onChange}
                      defaultSelected={initialValues.ranges}
                    />
                  )}
                />
              </fieldset>

              {errors.ranges && (
                <FieldValidationError>
                  {JSON.stringify(errors.ranges)}
                </FieldValidationError>
              )}

              <ChangeTracking
                control={control}
                changeMetaDataName="rangesChangeMetaData"
                cachedValue={initialValues.ranges}
                trackedValue={watch("ranges")}
              />
            </div>
          </div>
        </SectionCard>

        <FormButtons isDirty={isDirty} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};

export default CosewicWsForm;

export interface CosewicWsFormFields {
  commonNameEnglish: DefinitelyGQL<CommonNameInput>;
  commonNameFrench: DefinitelyGQL<CommonNameInput>;
  nameWithComma: DefinitelyGQL<NameWithCommaInput>;
  population: DefinitelyGQL<BilingualName>;
  genus: DefinitelyGQL<IdNameType>;
  species: DefinitelyGQL<IdNameType>;
  subSpecies: DefinitelyGQL<IdNameType>;
  variety: DefinitelyGQL<IdNameType>;
  taxonomicGroup: DefinitelyGQL<BilingualName>;
  endemicStatus: CosewicEndemicStatus;
  ranges: Array<DefinitelyGQL<BilingualName>>;
  otherRecognizedCommonNameEnglish: Array<DefinitelyGQL<RichTextInput>>;
  otherRecognizedCommonNameFrench: Array<DefinitelyGQL<RichTextInput>>;
  otherRecognizedScientificName: Array<DefinitelyGQL<RichTextInput>>;
  indigenousName: Array<DefinitelyGQL<RichTextInput>>;
  nameChangedLinkedToAssessmentId?: string;
}

export interface CosewicWsFormChangeTracking {
  commonNameEnglishChangeMetaData: ChangeMetaData;
  commonNameFrenchChangeMetaData: ChangeMetaData;
  nameWithCommaChangeMetaData: ChangeMetaData;
  populationChangeMetaData: ChangeMetaData;
  genusChangeMetaData: ChangeMetaData;
  speciesChangeMetaData: ChangeMetaData;
  subSpeciesChangeMetaData: ChangeMetaData;
  varietyChangeMetaData: ChangeMetaData;
  endemicStatusChangeMetaData: ChangeMetaData;
  rangesChangeMetaData: ChangeMetaData;
  taxonomicGroupChangeMetaData: ChangeMetaData;
}

export interface CosewicWsFormOutput
  extends CosewicWsFormFields,
    CosewicWsFormChangeTracking {}

interface CosewicWsFormProps {
  initialValues: CosewicWsFormFields;
  onSubmit: SubmitHandler<Partial<CosewicWsFormOutput>>;
  cosewicWsId?: string;
  status?: CosewicWsStatus | null;
}
