import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForSpeciesDocument,
  IdNameType,
  Species,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";

const additionalQueryVariables = {
  params: { searchType: "startsWith", isCaseSensitive: false },
};

const SpeciesAutocomplete: React.FC<SpeciesAutocompleteProps> = (props) => {
  return (
    <QueryAutocomplete
      id={"speciesAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForSpeciesDocument}
      listDisplayFormatter={(item) => item.name}
      onSelectionChange={props.onSelectionChange}
      placeholder={props.initialSpecies?.name ?? ""}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

interface SpeciesAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newSpecies?: null | Species | IdNameType) => void;
  initialSpecies?: Species | IdNameType;
  label: string | ReactElement;
}

export default SpeciesAutocomplete;
