import { Maybe } from "generated/gql-types";

const validCanadaPostalCodePattern = /^[A-Z]\d[A-Z] \d[A-Z]\d$/;
const validUsZipCodePattern = /^\d{5}(-\d{4})?$/;
const validOtherPostalCodePattern = /^[\s\S]{0,255}$/;

export function getPostalOrZipCode(countryCode: Maybe<string> | undefined) {
  if (countryCode === "CA") {
    return {
      pattern: validCanadaPostalCodePattern,
      label: "postal_code_Canada",
      placeHolder: "e.g. A1A 1A1",
      maxLength: 7,
    };
  }

  if (countryCode === "US") {
    return {
      pattern: validUsZipCodePattern,
      label: "zip_code_US",
      placeHolder: "e.g. 12345 or 12345-6789",
      maxLength: 10,
    };
  }

  return {
    pattern: validOtherPostalCodePattern,
    label: "postal_code",
    placeHolder: "",
    maxLength: 20,
  };
}

export const formatPostalOrZipCode = (
  value: any,
  countryCode: Maybe<string> | undefined
): any => {
  if (countryCode === "CA") {
    value = value.toUpperCase().replace(" ", "");
    if (value.length > 6) {
      value = value.slice(0, 6);
    }
    if (value.length > 3) {
      value = value.slice(0, 3) + " " + value.slice(3);
    }
  }

  return value;
};
