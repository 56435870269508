import { useMutation } from "@apollo/client";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { CosewicWsFormOutput } from "components/organisms/cosewic/CosewicWsForm";
import CosewicDataEntry from "components/templates/cosewic/DataEntry";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import { CreateCosewicWsDocument } from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GraphqlError from "../../components/GraphqlError";
import { CosewicEndemicStatus } from "../../types/cosewic/enums";

const Create: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.clearOverview();
  }, []);

  const [createCosewicWs, { loading: saving, error: savingError }] =
    useMutation(CreateCosewicWsDocument);

  const onSubmit: SubmitHandler<Partial<CosewicWsFormOutput>> = async (
    values
  ) => {
    // "Visual defaults" is used to provide a default value for fields like radio buttons, which
    // appear selected in the UI but aren't included in the submit payload unless they're changed.
    // When they're not changed, the WS would be saved in the DB without a value, which would be
    // confusing to the user when they view the profile;
    // they saw a value selected for this field in the "Create" page, but after creation it was missing.
    // These values are spread into the input and are overridden by any changed values from the form.
    const visualDefaultValues: Partial<CosewicWsFormOutput> = {
      endemicStatus: CosewicEndemicStatus.NotDetermined,
    };

    try {
      const res = await createCosewicWs({
        variables: {
          input: {
            ...visualDefaultValues,
            ...(values as any),
          },
        },
      });

      if (res.errors) throw res.errors;

      const newId = res.data?.createCosewicWs?.id ?? "MISSING ID";
      history.push({
        pathname: CosewicPathHelpers.CosewicProfile(newId ?? "error"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <GraphqlError
            title="Error creating new COSEWIC WS"
            errors={savingError}
          />

          <h1>{t("add_ws")}</h1>
          {saving ? <p>Saving...</p> : null}

          <ChangeTrackingProvider isEnabled={false}>
            <CosewicDataEntry onSubmit={onSubmit} />
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Create;
