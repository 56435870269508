import { useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import ModalDialog from "components/molecules/Modal";
import {
  Maybe,
  Region,
  UpdateListingWsProcessTifDocument,
} from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../atoms/LoadingIndicator";
import ListingProcessTifForm, {
  ListingProcessTifFormFields,
} from "../ListingProcessTifForm/ListingProcessTifForm";
import { mapFormToDomainModel } from "../ListingProcessTifForm/mappers";

interface EditRegionButtonAndModalProps {
  listingProcessId: string;
  tifId: string;
  region: Maybe<Region> | undefined;
  defaultValues: Partial<ListingProcessTifFormFields>;
  refetchQueries: string[];
}

const EditRegionButtonAndModal: React.FC<EditRegionButtonAndModalProps> = (
  props
) => {
  const { listingProcessId, tifId, region, defaultValues } = props;
  const { t } = useTranslation();
  const modalStateEditRegion = useOverlayTriggerState({});

  const validIds =
    props.listingProcessId != null &&
    props.listingProcessId.length > 0 &&
    props.tifId != null &&
    props.tifId.length > 0;

  const [UpdateListingWsProcessTif, { loading, error, reset }] = useMutation(
    UpdateListingWsProcessTifDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
    }
  );

  const onSubmit: SubmitHandler<Partial<ListingProcessTifFormFields>> = async (
    formData
  ) => {
    try {
      const model = mapFormToDomainModel(formData);

      console.log("onSubmit - model", model);

      const res = await UpdateListingWsProcessTif({
        variables: {
          listingWsProcessId: listingProcessId,
          tifId: tifId,
          input: model,
        },
      });

      if (res.errors == null) {
        modalStateEditRegion.close();
      } else {
        console.error(res.errors);
      }
    } catch (e) {
      console.warn("Error saving region tif");
      console.error(e);
    }
  };

  const onClose = () => {
    modalStateEditRegion.close();
    reset();
  };

  const TitleComponent = (
    <>
      {t("edit_region")}
      {loading ? <LoadingIndicator className="mrgn-lft-md" /> : null}
    </>
  );

  const regionName = i18n.language === "fr" ? region?.nameFr : region?.nameEn;

  return (
    <>
      <button
        type="button"
        className="btn btn-default btn-xs align-center"
        onClick={modalStateEditRegion.open}
        disabled={!validIds}
        data-testid={`button-edit-region-${props.tifId}`}
      >
        <span className="fas fa-pen mrgn-rght-sm" />
        {t("edit_region")}
      </button>
      {modalStateEditRegion.isOpen && validIds ? (
        <OverlayContainer>
          <ModalDialog
            id="edit_region_modal"
            data-testid="edit_region_modal"
            title={TitleComponent}
            className={"modal-width-70"}
            isOpen
            onClose={onClose}
            showModalFooter={false}
            // isDismissable
          >
            {error && (
              <Alert
                type={AlertTypes.WARNING}
                content={
                  i18n.language === "fr"
                    ? error.graphQLErrors[0].extensions?.response?.body
                        ?.frenchMessage
                    : error.graphQLErrors[0].extensions?.response?.body
                        ?.englishMessage
                }
              />
            )}
            <h3 className="font-size-24 mrgn-tp-0">{regionName}</h3>
            <ListingProcessTifForm
              initialValues={defaultValues}
              onSubmit={onSubmit}
              onClose={onClose}
            />
          </ModalDialog>
        </OverlayContainer>
      ) : null}
    </>
  );
};

export default EditRegionButtonAndModal;
