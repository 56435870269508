import { useState } from "react";

function useCheckboxList(initialIds = []) {
  const [checkedIds, setCheckedIds] = useState<string[]>(initialIds);

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = event.target;
    setCheckedIds((prevIds) =>
      checked
        ? [...prevIds, id]
        : prevIds.filter((checkedId) => checkedId !== id)
    );
  }

  const isIdChecked = (id: string) => {
    return checkedIds.includes(id);
  };

  const clearCheckedIds = () => {
    setCheckedIds([]);
  };

  return { checkedIds, handleCheckboxChange, isIdChecked, clearCheckedIds };
}

export default useCheckboxList;
