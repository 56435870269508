import AddFiles from "assets/svg/add.svg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { AddPhotosPopupProps } from "./AddPhotosPopup";
import AddPhotosPopup, { useAddPhotosPopup } from "./AddPhotosPopup";

interface AddPhotosBtnProps extends Omit<AddPhotosPopupProps, "modalState"> {
  buttonLabel?: string;
  showImage?: boolean;
}

const AddPhotosBtn: React.FC<AddPhotosBtnProps> = ({
  buttonLabel,
  showImage,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useAddPhotosPopup({});
  const validPhotoLicenseId =
    props.photoLicenseId != null && props.photoLicenseId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validPhotoLicenseId}
        data-testid="button-add-photos"
      >
        {showImage ? (
          <>
            <img src={AddFiles} alt={t("add_photos")} />
            <div className={"no-results-content"}>
              {buttonLabel ? t(buttonLabel) : t("add_photos")}
            </div>
          </>
        ) : (
          <>
            <i className="far fa-image mrgn-rght-sm" />
            {buttonLabel ? t(buttonLabel) : t("add_photos")}
          </>
        )}
      </button>
      {validPhotoLicenseId ? (
        <AddPhotosPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddPhotosBtn;
