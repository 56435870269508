import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import ProgramAutocomplete from "components/organisms/DistributionLists/ProgramAutocomplete";
import {
  BilingualAbbreviationAcronymTagsLookupText,
  BilingualAbbreviationAcronymTagsLookupTextInput,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { ReactElement } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";

export interface ProgramMultiSelectListProps {
  value: Array<BilingualAbbreviationAcronymTagsLookupText>;
  onChange: (
    listItems: Array<BilingualAbbreviationAcronymTagsLookupText>
  ) => void;
  label: string | ReactElement;
  isRequired?: boolean;
}

export const ProgramMultiSelectList: React.FC<ProgramMultiSelectListProps> = (
  props
) => {
  const { value, onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    BilingualAbbreviationAcronymTagsLookupTextInput | undefined
  >(undefined);

  const onRemoveFromList = (id: string) => {
    const newList = value.filter((x) => x.id !== id);
    onChange(newList);
  };

  const isFound = value.some((x) => x.id === selectedSearchItem?.id);

  return (
    <div>
      <div className="flex gap-sm align-end">
        <ProgramAutocomplete
          onSelectionChange={setSelectedSearchItem}
          label={props.label}
          disabled={false}
          isRequired={props.isRequired}
        />
        {value &&
          selectedSearchItem &&
          !isFound &&
          onChange([...value, selectedSearchItem])}
        {selectedSearchItem && setSelectedSearchItem(undefined)}
      </div>

      <ul className="multi-select-list">
        {value.map((x) => {
          return (
            <MultiSelectListItem
              id={x.id as string}
              name={bilingualLookupTextNameForLanguage(i18n.language, x)}
              onRemove={() => onRemoveFromList(x.id as string)}
              key={x.id}
            />
          );
        })}
      </ul>
    </div>
  );
};

export interface ProgramMultiSelectListWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<ProgramMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render" | "rules"> {
  render?: never;
  control: Control<TFieldValues>;
  label: string | ReactElement;
  isRequired?: boolean;
}

export const ProgramMultiSelectListWithController = <
  TFieldValues extends FieldValues
>(
  props: ProgramMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      rules={{
        required: props.isRequired,
      }}
      render={({ field: { value, onChange } }) => (
        <ProgramMultiSelectList
          value={value as Array<BilingualAbbreviationAcronymTagsLookupText>}
          onChange={onChange}
          label={props.label}
          isRequired={props.isRequired}
        />
      )}
    />
  );
};

export default ProgramMultiSelectList;
