import EditImage from "components/atoms/EditImage";
import MissingData from "components/atoms/MissingData";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { ListingWsOverallAssessment, Maybe } from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import isNullOrEmpty from "util/isNullOrEmpty";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../../atoms/SafeRenderHtml";

export interface OverallAssessmentSectionProps {
  overallAssessment?: Maybe<ListingWsOverallAssessment>;
  listingWsId: string;
}

export const OverallAssessmentSection: React.FC<
  OverallAssessmentSectionProps
> = (props) => {
  const { overallAssessment, listingWsId } = props;
  const { t, i18n } = useTranslation();

  const [showEditImage, setShowEditImage] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!overallAssessment) setShowEditImage(true);
    else setShowEditImage(false);
  }, [overallAssessment]);

  const expectedImpactOnFederalLandsString =
    i18n.language === "fr"
      ? overallAssessment?.expectedImpactOnFederalLands?.name?.french
      : overallAssessment?.expectedImpactOnFederalLands?.name?.english;

  const expectedImpactOnFederalLandsRationaleString =
    overallAssessment?.expectedImpactOnFederalLandsRationale;

  const expectedImpactOnNonFederalLandsString =
    i18n.language === "fr"
      ? overallAssessment?.expectedImpactOnNonFederalLands?.name?.french
      : overallAssessment?.expectedImpactOnNonFederalLands?.name?.english;

  const expectedImpactOnNonFederalLandsRationaleString =
    overallAssessment?.expectedImpactOnNonFederalLandsRationale;

  return (
    <>
      {showEditImage ? (
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.update}
          fallbackComponent={<MissingData />}
        >
          <div className="text-center mrgn-tp-md">
            <Link
              to={ListingPathHelpers.ListingEdit(listingWsId)}
              className="hover-underline"
            >
              <EditImage />
              <div className="lead mrgn-tp-md mrgn-bttm-sm">
                {t("no_information")}
              </div>
            </Link>
          </div>
        </RenderWhenAuthorized>
      ) : (
        <div className="species-field">
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("expected_impact_on_federal_lands")}
                </dt>
                <dd>
                  {isNullOrEmpty(expectedImpactOnFederalLandsString) ? (
                    <MissingData />
                  ) : (
                    expectedImpactOnFederalLandsString
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12 text-break">
                <dt className="text-muted">
                  {t("expected_impact_on_federal_lands_rationale")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    expectedImpactOnFederalLandsRationaleString?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        expectedImpactOnFederalLandsRationaleString?.text!
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("expected_impact_on_non_federal_lands")}
                </dt>
                <dd>
                  {isNullOrEmpty(expectedImpactOnNonFederalLandsString) ? (
                    <MissingData />
                  ) : (
                    expectedImpactOnNonFederalLandsString
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12 text-break">
                <dt className="text-muted">
                  {t("expected_impact_on_non_federal_lands_rationale")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    expectedImpactOnNonFederalLandsRationaleString?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        expectedImpactOnNonFederalLandsRationaleString?.text!
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default OverallAssessmentSection;
