import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "util/router";
import GalleryPageByReference from ".";
import PhotoProfilePageByReference from "./photo";

export interface GalleryRouterProps {}

export const GalleryPathHelpers = {
  Gallery: () => "/gallery",
  GalleryByReference: (program: string, id: string) =>
    `/gallery?program=${program}&id=${id}`,
  PhotoProfileByReference: (program: string, id: string, photoId: string) =>
    `/gallery/photo?program=${program}&id=${id}&photoId=${photoId}`,
};

export const GalleryRouter: React.FC<GalleryRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
          <GalleryPageByReference />
        </RenderWhenFeatureIsEnabled>
      </Route>
      <Route path={relative("/photo")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
          <PhotoProfilePageByReference />
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default GalleryRouter;
