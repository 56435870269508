import { CosewicWsStatus, Maybe } from "../../generated/gql-types";
import type { TFunction } from "i18next";

export const translateCosewicWsStatus = (
  t: TFunction,
  status?: CosewicWsStatus | Maybe<string>,
  fallback?: string
) => {
  if (!status) {
    if (fallback) return fallback;
    // Note(GP Jul 29 2021): the service calls this status, but in the app & prototypes we usually call it stage.
    // Should this return t('missing_stage') instead?
    // Currently using the fallback argument to handle this case.
    return t("missing_status");
  }

  // Note(GP Jul 29 2021): Special case:
  // For most cases, we can translate the status directly (DRAFT etc).
  // The key for enum value "PUBLISH" should show as "Published" in the UI,
  // and we can't have it collide with the translation for "Publish" as that is used on buttons etc.
  if (status === CosewicWsStatus.Publish) return t("PUBLISHED");

  return t(status);
};

export default translateCosewicWsStatus;
