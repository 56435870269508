import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "../../util/router";

import ResponseStatementProfilePage from "./[id]";
import ResponseStatementBrowsePage from "./browse";

export interface ResponseStatementRouterProps {}

export const ResponseStatementPathHelpers = {
  ResponseStatementBrowse: "/responseStatement/browse",
  ResponseStatementProfile: (responseStatementId: string) =>
    `/responseStatement/${responseStatementId}`,
};

export const ResponseStatementRouter: React.FC<ResponseStatementRouterProps> = (
  props
) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route path={relative("/browse")}>
        <ResponseStatementBrowsePage />
      </Route>

      <Route path={relative("/:responseStatementId")}>
        <ResponseStatementProfilePage />
      </Route>
    </Switch>
  );
};

export default ResponseStatementRouter;
