const overviewTranslation = {
  en: {
    overview: "Overview",
    some_alert_title: "Wildlife species name is different across programs.",
    some_alert_content:
      "Please give the GIC decision a value and when saved, the COSEWIC name will be used by Listing.",
    some_note:
      "Name changes can only be accepted or rejected when the GIC decision has a value.",
    schedule_1_status: "Schedule 1 status",
    english_name_with_comma: "Name with comma (English)",
    last_assessment_date: "Last assessment date",
    cosewic_status_date: "COSEWIC WS status date",
    batch: "Batch",
    legacy_response_statements: "Legacy response statements",
  },

  fr: {
    overview: "Aperçu",
    some_alert_title:
      "Le nom de l’espèce sauvage est différent selon les programmes.",
    some_alert_content:
      "Veuillez donner une valeur à la décision du GEC et, une fois sauvegardée, le nom du COSEPAC sera utilisé par l'inscription.",
    some_note:
      "Les changements de nom ne peuvent être acceptés ou rejetés que lorsque la décision du GEC a une valeur.",
    schedule_1_status: "Statut à l'Annexe 1",
    english_name_with_comma: "Nom anglais avec virgule",
    last_assessment_date: "Date de la dernière évaluation",
    cosewic_status_date: "Date de statut de l'ES du COSEPAC",
    batch: "Lot",
    legacy_response_statements: "Déclarations de réponse héritées du passé",
  },
};

export default overviewTranslation;
