import { useQuery } from "@apollo/client";
import {
  BilingualAbbreviationLookupTextInput,
  ExpectedImpactLookupDocument,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../atoms/LoadingIndicator";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface ExpectedImpactDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue: any; // TODO:TYPES:any
  onChange: (value: BilingualAbbreviationLookupTextInput) => void;
}

export const ExpectedImpactDropdown = React.forwardRef<
  HTMLSelectElement,
  ExpectedImpactDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(ExpectedImpactLookupDocument);
  const { t, i18n } = useTranslation();

  // TODO: show the field legend outside this loading check
  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget?.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.listingWsExpectedImpact?.find((x) => x?.id === id);
    if (!value) {
      console.error(
        `ExpectedImpactDropdown couldn't find a value for ID ${id}`
      );
      return;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control"
      value={props?.defaultValue?.id}
      ref={ref}
      {...props}
      onChange={onChange}
    >
      <option value={""}>
        {t(props.placeholder ?? "please_select_option")}
      </option>
      {data?.listingWsExpectedImpact?.map((x) => {
        return (
          <option
            key={x?.id ?? "error - missing id"}
            value={x?.id ?? "error - missing id"}
          >
            {bilingualLookupTextNameForLanguage(i18n.language, x)}
          </option>
        );
      })}
    </select>
  );
});

export interface ExpectedImpactDropdownWithControllerProps<TFieldValues>
  extends Omit<ExpectedImpactDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const ExpectedImpactDropdownWithController = <TFieldValues,>(
  props: ExpectedImpactDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <ExpectedImpactDropdown
          id={props.id}
          placeholder={props.placeholder}
          onChange={onChange}
          defaultValue={value}
        />
      )}
    />
  );
};

export default ExpectedImpactDropdown;
