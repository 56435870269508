import ResetIcon from "assets/svg/reset-icon.svg";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface QueryFields {
  search: string;
  showInactive: boolean;
}

export const defaultQueryFields = {
  search: "",
  showInactive: false,
};

export interface AdminPageFilterFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const AdminPageFilterForm: React.FC<AdminPageFilterFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, handleSubmit, setFocus, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    form.setValue("showInactive", false);
    setFocus("search");
  };

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    props.onSubmit(newValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-col">
        {/*<label htmlFor="adminSearch">{t("search")}</label>*/}
        <div className="flex align-start flex-wrap">
          <div className="flex-col">
            <div className="input-group mrgn-rght-sm">
              <label className="wb-inv" htmlFor="adminSearch">
                {t("search")}
              </label>
              <input
                type="text"
                id="adminSearch"
                className="form-control search-box"
                placeholder={t("search")}
                {...register("search")}
              />
              <button
                type="button"
                aria-label="Clear search"
                className="search-box-reset"
                hidden={watch("search") === ""}
                onClick={onClearSearch}
                data-testid="button-clear"
              >
                <img src={ResetIcon} alt={t("reset")} />
              </button>
            </div>
            <div className="checkbox">
              <label htmlFor="showInactiveCheckbox">
                <input
                  type="checkbox"
                  id="showInactiveCheckbox"
                  {...register("showInactive")}
                />
                &nbsp;
                {t("show_archived_only")}
              </label>
            </div>
          </div>
          {/*<button className="btn btn-default" type="button" onClick={onClearSearch}>*/}
          {/*  {t("clear_search")}*/}
          {/*</button>*/}
          <button
            type="submit"
            className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-bttm-md"
            data-testid="button-search"
          >
            <span className="glyphicon glyphicon-search mrgn-rght-sm" />
            {t("search")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AdminPageFilterForm;
