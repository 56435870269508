import { ApolloError } from "@apollo/client";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { resolveAzureEnvironment, RunEnvironment } from "../azure/environment";

// NOTE: type 'any' here is actually an ApolloError
// NOTE: `error.code` can be one of several codes, representing validation, forbidden, etc.
// Error code reference docs: https://www.apollographql.com/docs/apollo-server/data/errors
const GraphqlError: React.FC<{
  title?: string;
  errors?: any;
  showFrontendErrorMessages?: boolean;
  isProductionEnv?: boolean;
}> = (props) => {
  // const { isProductionEnv = process.env.NODE_ENV !== "development" } = props;
  const { showFrontendErrorMessages = false } = props;
  const [showPanel, setShowPanel] = React.useState(true);
  const { i18n, t } = useTranslation();

  if (props.errors == null) return null;
  const env = resolveAzureEnvironment();
  const errors: ApolloError = props.errors;

  const graphQLErrors = errors?.graphQLErrors?.map((x) => ({
    message: x.message,
    path: JSON.stringify(x.path),
    extensions: x.extensions as SARDMTSApiErrorExtensions,
  }));

  return (
    <>
      <div
        role="alert"
        id="graphql-network-errors-alert"
        className="alert alert-danger"
      >
        <h2>{props.title ?? "Error"}</h2>

        {errors?.networkError && (
          <div>
            [Network Error]{" "}
            {errors?.networkError?.message ?? "(Missing error message)"}
          </div>
        )}

        {graphQLErrors?.map((x) => {
          const ResponseSection = x?.extensions?.response ? (
            <div key={x?.message}>
              {x?.extensions?.response?.body?.messages?.map((msg, i) => {
                const errorCode = msg.code.split(".").join("_");
                const frontendErrorMessage = t(errorCode);
                const isMissingFrontendTranslation =
                  errorCode === frontendErrorMessage;
                if (
                  showFrontendErrorMessages &&
                  !isMissingFrontendTranslation
                ) {
                  // Display frontend's error message
                  return <div key={`msg-${i}`}>{frontendErrorMessage}</div>;
                } else {
                  // Display backend's error message
                  return i18n.language === "fr" ? (
                    <div key={`msg-${i}`}>
                      {msg.frenchMessage ?? "(Missing French message)"}
                    </div>
                  ) : (
                    <div key={`msg-${i}`}>
                      {msg.englishMessage ?? "(Missing English message)"}
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <p>
              <em>No Response</em>
            </p>
          );

          return <>{ResponseSection}</>;
        })}
      </div>

      {(env === RunEnvironment.AZURE_TEST ||
        env === RunEnvironment.AZURE_DEV ||
        env === RunEnvironment.AZURE_ALTDEV ||
        env === RunEnvironment.LOCAL) &&
        showPanel && (
          <>
            <div className="panel panel-warning">
              <div className="panel-body">
                <div className="flex justify-between align-start">
                  <h2 className="mrgn-tp-0 text-warning font-size-16">
                    <span className="fa fa-exclamation-triangle"></span> This
                    panel is only visible in the Test, Dev and Local
                    environments for debugging purposes.
                  </h2>
                  <span
                    className="glyphicon glyphicon-remove mrgn-rght-sm font-size-12 pointer text-warning"
                    onClick={() => {
                      setShowPanel(false);
                    }}
                    tabIndex={0}
                  />
                </div>
                <div>
                  {errors?.networkError && (
                    <div>
                      [Network Error] [
                      {errors?.networkError?.name ?? "(Missing error name)"}]{" "}
                      {errors?.networkError?.message ??
                        "(Missing error message)"}
                    </div>
                  )}

                  {graphQLErrors?.map((x) => {
                    const ResponseSection = x?.extensions?.response ? (
                      <details key={x?.message}>
                        <summary>
                          <b>
                            {x?.extensions?.response?.status ??
                              "(Missing Status)"}{" "}
                            {x?.extensions?.response?.statusText ??
                              "(Missing Status Text)"}
                          </b>
                        </summary>

                        {x?.extensions?.response?.body?.messages?.map(
                          (msg, i) => {
                            return (
                              <div key={`ext-${i}`}>
                                <div>
                                  <b>
                                    {msg.reasonCode ?? "(Missing reason code)"}
                                  </b>
                                  :
                                  <pre className="pre-with-line-breaks">
                                    {i18n.language === "fr"
                                      ? msg.frenchMessage ??
                                        "(Missing French message)"
                                      : msg.englishMessage ??
                                        "(Missing English message)"}
                                  </pre>
                                </div>

                                {msg.code && (
                                  <div>
                                    <b>Code</b>:{" "}
                                    <pre className="pre-with-line-breaks">
                                      {msg.code}
                                    </pre>
                                  </div>
                                )}

                                {msg.propertyName && (
                                  <div>
                                    <b>Property Name</b>:{" "}
                                    <pre className="pre-with-line-breaks">
                                      {msg.propertyName}
                                    </pre>
                                  </div>
                                )}
                                <hr />
                              </div>
                            );
                          }
                        )}

                        <div>
                          <b>Path:</b>
                          <pre className="pre-with-line-breaks">
                            {x?.path ?? "(Missing path)"}
                          </pre>
                        </div>

                        <div>
                          <b>URL:</b>
                          <pre className="pre-with-line-breaks">
                            {x?.extensions?.response?.url ?? "(Missing URL)"}
                          </pre>
                        </div>
                      </details>
                    ) : (
                      <p>
                        <em>No Response</em>
                      </p>
                    );

                    return <>{ResponseSection}</>;
                  })}
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default GraphqlError;

export interface SARDMTSApiErrorExtensions {
  code: string;
  exception?: {
    input: string;
    code: string;
    stacktrace: string[];
  };
  response: {
    url?: string;
    status?: number;
    statusText?: string;
    body: {
      status: number;
      messages: Array<{
        code: string;
        reasonCode: string;
        propertyName: string;
        frenchMessage: string;
        englishMessage: string;
      }>;
    };
  };
}

/*
EXAMPLE ERROR
  {
    "code": "INTERNAL_SERVER_ERROR",
    "response": {
    "url": "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/gcs/v1-dev/genus?pageNumber=-1&pageSize=10&status=active&search=",
    "status": 400,
    "statusText": "Bad Request",
    "body": {
    "code": "Genus.ValidPageNumberRequired",
    "reason": "InvalidRequest",
    "frenchMessage": "Le numéro de la page doit-être supérieur à 0.",
    "englishMessage": "Page number must be greater than 0."
  }
  },
    "exception": {
    "stacktrace": [
    "Error: 400: Bad Request",
    "    at NameInfoAPI.<anonymous> (C:\\Users\\PowersG\\dev\\sar-dmts\\graphql\\node_modules\\apollo-datasource-rest\\dist\\RESTDataSource.js:77:25)",
    "    at Generator.next (<anonymous>)",
    "    at C:\\Users\\PowersG\\dev\\sar-dmts\\graphql\\node_modules\\apollo-datasource-rest\\dist\\RESTDataSource.js:7:71",
    "    at new Promise (<anonymous>)",
    "    at __awaiter (C:\\Users\\PowersG\\dev\\sar-dmts\\graphql\\node_modules\\apollo-datasource-rest\\dist\\RESTDataSource.js:3:12)",
    "    at NameInfoAPI.errorFromResponse (C:\\Users\\PowersG\\dev\\sar-dmts\\graphql\\node_modules\\apollo-datasource-rest\\dist\\RESTDataSource.js:67:16)",
    "    at NameInfoAPI.<anonymous> (C:\\Users\\PowersG\\dev\\sar-dmts\\graphql\\node_modules\\apollo-datasource-rest\\dist\\RESTDataSource.js:50:34)",
    "    at Generator.next (<anonymous>)",
    "    at C:\\Users\\PowersG\\dev\\sar-dmts\\graphql\\node_modules\\apollo-datasource-rest\\dist\\RESTDataSource.js:7:71",
    "    at new Promise (<anonymous>)"
    ]
  }
  }*/
