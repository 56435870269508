import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface RefreshButtonProps {
  text?: string;
  onClick: () => any;
  className?: string;
  disabled?: boolean;
}

export const RefreshButton: React.FC<RefreshButtonProps> = (props) => {
  const { t } = useTranslation();
  const { text = t("refresh"), onClick, className, disabled } = props;

  return (
    <button
      type="button"
      className={cc(["btn btn-link card-header-button-link", className])}
      onClick={onClick}
      disabled={disabled}
      data-testid="button-refresh"
    >
      <i className="fas fa-redo-alt mrgn-rght-sm"></i>
      {text}
    </button>
  );
};

export default RefreshButton;
