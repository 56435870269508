import { useState } from "react";

export const useTextInput = (defaultValue: string = "") => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (e: any) => {
    setValue(e.currentTarget?.value ?? e.target.value);
  };

  return { value, setValue, props: { value, onChange } };
};

export default useTextInput;
