import { Alert, AlertTypes } from "components/atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import { useCheckbox } from "hooks/util/useCheckbox";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const ArchiveUnArchiveButtonAndModal: React.FC<{
  canAction: boolean;
  onAction: () => Promise<void>;
  warningMessage?: string;
  buttonText: string;
  modalTitle: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const onActionSpinner = () => {
    setShowButtonSpinner(true);

    props.onAction().finally(() => {
      setShowButtonSpinner(false);
    });
  };

  const dataTestId = `modal-checkbox-confirm-${props.buttonText}`;
  const btnDataTestId = `button-document-${props.buttonText}`;

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-primary mrgn-rght-sm"
        disabled={!props.canAction}
        onClick={confirmModal.open}
        data-testid={btnDataTestId}
      >
        {showButtonSpinner ? (
          <>
            <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
            <span className="wb-inv">&nbsp;{t("loading")}...</span>
          </>
        ) : null}
        {t(props.buttonText)}
      </button>

      <ConfirmationModal
        modalState={confirmModal}
        title={props.modalTitle}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={onActionSpinner}
        onCancel={() => {
          confirmModal.close();
          checkboxState.reset();
        }}
      >
        <Alert type={AlertTypes.WARNING} content={props.warningMessage} />

        <label>
          <input
            type="checkbox"
            checked={checkboxState.checked}
            onChange={checkboxState.toggle}
            data-testid={dataTestId}
          />{" "}
          {t("i_confirm")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default ArchiveUnArchiveButtonAndModal;
