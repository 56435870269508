import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import IssuesModal, {
  useIssuesModal,
} from "components/organisms/listing/ListingSectorForm/IssuesModal";
import { Note as Issues } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import {
  RenderWhenAuthorized,
  Roles,
} from "../../../../../features/auth/components";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../../atoms/SafeRenderHtml";
import SectionCard from "../../../../atoms/SectionCard";

export interface IssuesCardProps {
  header: string;
  issues?: Issues | null;
  loading: boolean;
  onUpdateIssues: any;
  role: Roles[];
}

export const IssuesCard: React.FC<IssuesCardProps> = (props) => {
  const { header, issues, loading, onUpdateIssues, role } = props;
  const { t } = useTranslation();
  const issuesModalState = useIssuesModal({});

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2 className="mrgn-tp-sm" id="issuesSection">
            {header}
          </h2>
          <RenderWhenAuthorized authorizedRoles={role}>
            {/* TODO: add EditButton or EditButtonModal */}
            <button
              type="button"
              className="btn btn-link card-header-button-link"
              data-testid="issues-button-edit"
              onClick={issuesModalState.open}
            >
              <i className="fas fa-pen mrgn-rght-sm"></i>
              {t("edit")}
            </button>
          </RenderWhenAuthorized>
        </div>
      }
    >
      {loading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : htmlIsNullOrEmpty(issues?.text?.text) ? (
        <MissingData />
      ) : (
        <>
          <div className="font-size-18 text-break">
            <SafeRenderHtml htmlString={issues?.text?.text ?? ""} />
          </div>
          <div className="separator-line-top text-muted font-size-16">
            {formatTimestamp(issues?.modifiedAt, DisplayFormat.DATE_AND_TIME) +
              " by " +
              issues?.modifiedBy}
          </div>
        </>
      )}
      <IssuesModal
        modalState={issuesModalState}
        defaultValue={issues?.text?.text ?? ""}
        onSave={onUpdateIssues}
      />
    </SectionCard>
  );
};

export default IssuesCard;
