import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { useOverviewContext } from "../../../features/overview";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";
import parseSchedule1Date from "../../../util/listing/parseSchedule1Date";
import LoadingIndicator from "../../atoms/LoadingIndicator";
import MissingData from "../../atoms/MissingData";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";

export interface RibbonProps {}

export const Ribbon: React.FC<RibbonProps> = (props) => {
  const { t, i18n } = useTranslation();
  const overview = useOverviewContext();

  let name = null;
  let population = null;
  let ranges: any[] | null | undefined = null;

  if (overview.listing) {
    name =
      i18n.language === "fr"
        ? overview?.listing?.commonNameFrench?.name?.text ??
          overview?.listing?.commonNameEnglish?.name?.text
        : overview?.listing?.commonNameEnglish?.name?.text;

    population =
      i18n.language === "fr"
        ? overview?.listing?.population?.nameFr
        : overview?.listing?.population?.nameEn;

    ranges = overview?.listing?.ranges;
  } else {
    name =
      i18n.language === "fr"
        ? overview?.cosewic?.commonNameFrench?.name?.text ??
          overview?.cosewic?.commonNameEnglish?.name?.text
        : overview?.cosewic?.commonNameEnglish?.name?.text;

    population =
      i18n.language === "fr"
        ? overview?.cosewic?.population?.nameFr
        : overview?.cosewic?.population?.nameEn;

    ranges = overview?.cosewic?.ranges;
  }

  // Build abbreviation of ranges. Ex. "ON, QC, PEI, BC, MN, SK";
  // Map the cosewicWS/listingWS ranges into abbreviations for the current language, using the admin range list as a reference
  const rangesAbbreviation = React.useMemo(() => {
    return ranges
      ?.map((x) => {
        const rangeFound = overview.rangeList?.find((r) => r?.id === x?.id);
        const rangeAbbr =
          i18n.language === "fr"
            ? rangeFound?.abbreviationFrench
            : rangeFound?.abbreviationEnglish;
        return rangeAbbr;
      })
      .join(", ");
  }, [i18n.language, overview.rangeList, ranges]);

  const status = overview.latestAssessment?.statusAndCriteria?.status;
  const schedule1Status = overview?.latestListingProcess?.schedule1Status;
  const dateOfAdditionOnSchedule1 = parseSchedule1Date(
    overview?.listing?.schedule1Date
  );

  return (
    <aside id="ribbon">
      <div className="container">
        {overview.loading ? (
          <div className="py-4">
            <LoadingIndicator centered />
          </div>
        ) : (
          <div className="flex-row2col gap-md py-3">
            <div className="flex-col">
              <h2>
                {htmlIsNullOrEmpty(name) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={htmlRemoveOuterPTag(name)} />
                )}
              </h2>
              {population && <span className="population">{population}</span>}
              {rangesAbbreviation && (
                <span className="range">{rangesAbbreviation}</span>
              )}
            </div>
            <div className="species-field row">
              <dl>
                <div className="species-data text-right col-sm-4">
                  <dt className="text-muted">{t("cosewic_status")}</dt>
                  <dd className="font-size-16">
                    {status ? t(status) : <MissingData />}
                  </dd>
                </div>
                <div className="species-data text-right col-sm-4">
                  <dt className="text-muted">{t("schedule_1_status")}</dt>
                  <dd className="font-size-16">
                    {schedule1Status ? t(schedule1Status) : <MissingData />}
                  </dd>
                </div>
                <div className="species-data text-right col-sm-4">
                  <dt className="text-muted">{t("on_schedule_1")}</dt>
                  <dd className="font-size-16">
                    {dateOfAdditionOnSchedule1 ?? <MissingData />}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}
      </div>
    </aside>
  );
};

export default Ribbon;
