import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import {
  BptTemplate,
  Maybe,
  UpdateBptTemplateDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GraphqlError from "components/GraphqlError";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateDomainModelIntoForm,
  formIntoCreateInputBptTemplate,
} from "../../bptFormUtil";
import BptTemplateForm, { BptTemplateFormFields } from "../BptTemplateForm";

export interface EditBptTemplatePopupProps {
  defaultValues?: Maybe<BptTemplate>;
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
}

const EditBptTemplatePopup: React.FC<EditBptTemplatePopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();
  const { defaultValues } = props;
  const [editBptTemplate, { loading, error, reset }] = useMutation(
    UpdateBptTemplateDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
    }
  );
  const input = BptTemplateDomainModelIntoForm(defaultValues);
  const onSubmit: SubmitHandler<BptTemplateFormFields> = async (formData) => {
    try {
      const input = formIntoCreateInputBptTemplate(formData);
      const res = await editBptTemplate({
        variables: {
          templateId: defaultValues?.id ?? "error",
          input: input,
        },
      });
      alertContext.showSuccess({
        title: t("updated_bpt_template"),
        timeOut: 5000,
      });
      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error editing BptTemplate");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_bpt_template")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        className="modal-dropdown-visible"
      >
        <GraphqlError errors={error} />
        {loading && <LoadingIndicator centered />}
        <BptTemplateForm
          intialValues={{
            name: input.name,
            description: input.description,
            program: input.program,
            visibility: input.visibility,
            objectType: input.objectType,
          }}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditBptTemplatePopup;

export const useEditBptTemplateModal = useOverlayTriggerState;
export type { BptTemplateFormFields as EditBptTemplateFields };
