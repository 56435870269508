import { BptTemplateStepBundlesUpdateInput } from "generated/gql-types";
import { random, set } from "lodash";
import { StepBundlesFormFields } from "./StepBundlesReorderForm";

export const formIntoUpdateInput = (
  formData: Partial<StepBundlesFormFields>
): BptTemplateStepBundlesUpdateInput => {
  let out: BptTemplateStepBundlesUpdateInput = {};

  const stepBundles = formData.stepBundles?.map((b) => ({
    bundleId: b.id,
    stepIds: b.steps?.map((s) => s?.id),
  }));

  set(out, "stepBundles", stepBundles);

  return out;
};

export const getTemporaryNewBundleId = () => {
  const NEW_BUNDLE_ID_PREFIX = "NEW-BUNDLE-";

  // Note: There is a length limitation of 19 characters for the id restricted from the Backend.
  return NEW_BUNDLE_ID_PREFIX + random(100000, 999999);
};

// export const replaceNewBundleIdsWithNull = (
//   formData: Partial<StepBundlesFormFields>
// ) => {
//   // Replace all NEW BUNDLE IDs with null
//   formData.stepBundles?.forEach((sb) => {
//     if (sb.id?.startsWith(NEW_BUNDLE_ID_PREFIX)) {
//       sb.id = null;
//     }
//   });
//   return formData;
// };
