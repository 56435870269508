import FieldValidationError from "components/atoms/forms/FieldValidationError";
import DatePicker from "components/molecules/DatePicker";
import { ChangeTracking } from "features/changeTracking";
import {
  Assessment,
  AssessmentStage,
  AssessmentType,
} from "generated/gql-types";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SectionCard from "../../../../atoms/SectionCard";
import { AssessmentFormChangeTracking, AssessmentFormFields } from "./index";
import { TextInput } from "components/atoms/forms/TextInput";

export interface AssessmentBasicInfoCardProps {
  assessment?: Assessment;
  defaultValues?: AssessmentFormFields;
  open?: boolean;
  showDateSentToMinister?: boolean;
}

export const AssessmentBasicInfoCard: React.FC<AssessmentBasicInfoCardProps> = (
  props
) => {
  const { defaultValues, showDateSentToMinister = false, open } = props;
  const { t } = useTranslation();

  const form = useFormContext<
    AssessmentFormFields & AssessmentFormChangeTracking
  >();

  const { register, formState, watch, control } = form;
  const { errors } = formState;

  return (
    <SectionCard
      collapsible
      open={open}
      header={<h2>{t("assessment_information")}</h2>}
    >
      <div className="flex-col gap-md">
        <div>
          <Controller
            control={control}
            name="date"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                disabled={
                  props.assessment != null &&
                  props.assessment.stage === AssessmentStage.Assessed
                }
                id="assessmentDatePicker"
                required
                label={t("date_examined")}
                onInput={onChange}
                value={value}
              />
            )}
          />

          {errors.date && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

          <ChangeTracking
            control={control}
            changeMetaDataName="dateChangeMetaData"
            cachedValue={defaultValues?.date ?? ""}
            trackedValue={watch("date")}
          />
        </div>

        <div>
          <fieldset className="chkbxrdio-grp">
            <legend>
              <span className="field-name">{t("assessment_type")}</span>{" "}
            </legend>
            <label htmlFor="rdo_regular" className="radio-inline">
              <input
                type="radio"
                value={AssessmentType.Regular}
                id="rdo_regular"
                data-rule-required="true"
                {...register("type", { required: true })}
              />
              &#160;&#160;{t(AssessmentType.Regular)}
            </label>
            <label htmlFor="rdo_emergency" className="radio-inline">
              <input
                type="radio"
                value={AssessmentType.Emergency}
                id="rdo_emergency"
                {...register("type", { required: true })}
              />
              &#160;&#160;{t(AssessmentType.Emergency)}
            </label>
          </fieldset>

          {errors.type && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
          {/*<ChangeTracking*/}
          {/*  changeMetaDataName="typeChangeMetaData"*/}
          {/*  cachedValue={defaultValues.date}*/}
          {/*  trackedValue={watch("date")}*/}
          {/*/>*/}
        </div>

        <div>
          <label htmlFor="sel_reportType">{t("type_of_report")}</label>
          <select
            id="sel_reportType"
            className="form-control"
            {...register("reportType")}
          >
            <option selected value="NOT_INITIALIZED">
              {t("please_select_option")}
            </option>

            <option value="EXISTING">{t("EXISTING")}</option>
            <option value="EXISTING_ADDENDUM">{t("EXISTING_ADDENDUM")}</option>
            <option value="EXISTING_MODIFIED">{t("EXISTING_MODIFIED")}</option>
            <option value="EXISTING_RAPID">{t("EXISTING_RAPID")}</option>
            <option value="STATUS_APPRAISAL_SUMMARY">
              {t("STATUS_APPRAISAL_SUMMARY")}
            </option>
            <option value="NEW">{t("NEW")}</option>
            <option value="SUPPORTING_INFO">{t("SUPPORTING_INFO")}</option>
            <option value="UPDATED">{t("UPDATED")}</option>
          </select>
        </div>

        <TextInput
          id="tbx_orderofassessment"
          type="text"
          label={t("order_of_assessment")}
          pattern="[0-9A-Za-z]*|[0-9A-Za-z]+-[0-9A-Za-z]+"
          maxLength={6}
          placeholder={t("order_of_assessment")}
          {...register("order")}
        />

        {showDateSentToMinister ? (
          <div>
            <Controller
              control={control}
              name="dateSentToMinister"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  id="dateSentToMinisterPicker"
                  required={false}
                  label={t("cosewic_reports_sent_to_minister_date")}
                  onInput={onChange}
                  value={value}
                />
              )}
            />
          </div>
        ) : null}
      </div>
    </SectionCard>
  );
};

export default AssessmentBasicInfoCard;
