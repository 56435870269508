import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import { CreateDocumentLinksDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LinkForm, { LinkFields } from "../LinkForm/LinkForm";

export interface AddLinkPopupProps {
  modalState: OverlayTriggerState;
  documentId: string;
  defaultValues?: LinkFields;
  refetchQuery: () => void;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const AddLinkPopup: React.FC<AddLinkPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, documentId, defaultValues, refetchQuery } = props;

  const [createDocumentLink, { loading, error, reset }] = useMutation(
    CreateDocumentLinksDocument,
    {
      onCompleted: props.onCompleted,
      onError: props.onError,
    }
  );

  const onSubmit: SubmitHandler<LinkFields> = async (formData) => {
    try {
      const res = await createDocumentLink({
        variables: {
          createDocumentLinksId: props?.documentId,
          input: {
            links: [
              {
                reference: {
                  name: {
                    text: formData.name,
                  },
                  uri: formData?.urlToDocument,
                },
                language: formData?.language,
              },
            ],
          },
        },
      });
      if (res.errors == null) {
        modalState.close();
        reset();
        refetchQuery();
      }
    } catch (e) {
      console.warn("Error saving new Link");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
    refetchQuery();
  };

  if (!modalState.isOpen) return null;
  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_link")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
      >
        <GraphqlError title="" errors={error} />
        {loading && <LoadingIndicator centered />}
        <div hidden={loading}>
          <LinkForm
            documentId={documentId}
            defaultValues={defaultValues}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </div>
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddLinkPopup;

export const useManageFilesPopup = useOverlayTriggerState;
export type { LinkFields as AddLinksFields };
