import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import RelatedSpeciesWarning from "components/molecules/listing/relatedSpecies/relatedSpeciesWarning";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import ListingProcessForm from "components/organisms/listing/ListingProcessForm/ListingProcessForm";
import { mapDomainModelToForm } from "components/organisms/listing/ListingProcessForm/mappers";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import {
  ListingWsProcessDocument,
  UpdateListingWsProcessDocument,
  UpdateListingWsProcessInput,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AddErForFrenchDate, formatTimestamp } from "util/formatTimestamp";
import timeoutPromise from "util/timeoutPromise";

export interface ListingProcessesEditPageProps {}

export const ListingProcessesEditPage: React.FC<
  ListingProcessesEditPageProps
> = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId, listingProcessId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { loading, error, data } = useQuery(ListingWsProcessDocument, {
    variables: { id: listingProcessId, listingWsId },
  });

  const [updateListingProcess, { loading: saving, error: savingError }] =
    useMutation(UpdateListingWsProcessDocument);

  const onSubmit: SubmitHandler<Partial<UpdateListingWsProcessInput>> = async (
    input
  ) => {
    try {
      const res = await updateListingProcess({
        variables: {
          id: listingProcessId,
          input,
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: ListingPathHelpers.ListingProcesses(listingWsId),
      });

      await timeoutPromise(3000);
      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) return null;
  const initialValues = mapDomainModelToForm(data?.listingWsProcess);

  const pageHeaderDate = data?.listingWsProcess?.ministerOpinionDate
    ? data.listingWsProcess.ministerOpinionDate
    : data?.listingWsProcess?.assessmentRef?.assessmentDate;

  const isStandaloneListing = data?.listingWs?.cosewicWsRefId == null;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GraphqlError
            title="Error fetching Listing WS process data"
            errors={error}
          />
          <GraphqlError
            title="Error saving listing WS process data"
            errors={savingError}
          />
          <RelatedSpeciesWarning listingWsId={listingWsId} />

          {loading ? (
            <h1>{t("loading")}</h1>
          ) : (
            <h1>
              {t("listing_process_for_date", {
                date: pageHeaderDate
                  ? AddErForFrenchDate(formatTimestamp(pageHeaderDate))
                  : t("error"),
              })}
            </h1>
          )}

          {saving ? <p>Saving...</p> : null}

          <ChangeTrackingProvider isEnabled={false}>
            <ListingProcessForm
              listingWsProcess={data?.listingWsProcess}
              initialValues={initialValues}
              isStandaloneListing={isStandaloneListing}
              onSubmit={onSubmit}
            />
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingProcessesEditPage;
