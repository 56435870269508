import {
  AutocompleteForPopulationDocument,
  BilingualName,
  Population,
} from "generated/gql-types";
import type { ReactElement } from "react";
import * as React from "react";
import formatFullOrShortBilingualName from "../../../../util/autocompletes/formatFullOrShortBilingualName";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";

interface PopulationAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newPopulation?: null | BilingualName) => void;
  initialPopulation?: BilingualName;
  label: string | ReactElement;
}

const additionalQueryVariables = {
  params: { searchType: "contains", isCaseSensitive: false },
};

const PopulationAutocomplete: React.FC<PopulationAutocompleteProps> = (
  props
) => {
  const onSelectionChange = React.useCallback(
    (item: Population) => {
      // Convert to bilingualName for Create/Update APIs;
      //  the population admin service returns us a Population (FullBilingualName format),
      //  but the COSEWIC API POST/PATCH both expect a BilingualName.
      if (item == null) {
        props.onSelectionChange(undefined);
        return;
      }

      const itemAsBilingualName: DefinitelyGQL<BilingualName> = {
        id: item.id,
        nameEn: item.nameEnglish ?? "",
        nameFr: item.nameFrench ?? "",
      };

      props.onSelectionChange(itemAsBilingualName);
    },
    [props.onSelectionChange]
  );

  return (
    <QueryAutocomplete
      id={"populationAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForPopulationDocument}
      listDisplayFormatter={formatFullOrShortBilingualName}
      onSelectionChange={onSelectionChange}
      placeholder={formatFullOrShortBilingualName(props.initialPopulation)}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

export default PopulationAutocomplete;
