import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import isNullOrEmpty from "util/isNullOrEmpty";
import { RegistryPageFormFields } from "./RegistryPageForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<RegistryPageFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<RegistryPageFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  if (
    dirtyFields?.title?.english?.text &&
    isNullOrEmpty(formData?.title?.english?.text) &&
    !isNullOrEmpty(initialValues?.title?.english?.text)
  ) {
    set(formData, "title.english", null);
  }
  if (
    dirtyFields?.title?.french?.text &&
    isNullOrEmpty(formData?.title?.french?.text) &&
    !isNullOrEmpty(initialValues?.title?.french?.text)
  ) {
    set(formData, "title.french", null);
  }
  if (
    dirtyFields?.description?.english?.text &&
    isNullOrEmpty(formData?.description?.english?.text) &&
    !isNullOrEmpty(initialValues?.description?.english?.text)
  ) {
    set(formData, "description.english", null);
  }
  if (
    dirtyFields?.description?.french?.text &&
    isNullOrEmpty(formData?.description?.french?.text) &&
    !isNullOrEmpty(initialValues?.description?.french?.text)
  ) {
    set(formData, "description.french", null);
  }
  if (
    dirtyFields?.documentRefs &&
    isNullOrEmpty(formData?.documentRefs) &&
    !isNullOrEmpty(initialValues?.documentRefs)
  ) {
    set(formData, "documentRefs", null);
  }
  // if (
  //   dirtyFields?.contactRef &&
  //   isNullOrEmpty(formData?.contactRef) &&
  //   !isNullOrEmpty(initialValues?.contactRef)
  // ) {
  //   set(formData, "contactRef", null);
  // }
  // if (
  //   dirtyFields?.organizationRef &&
  //   isNullOrEmpty(formData?.organizationRef) &&
  //   !isNullOrEmpty(initialValues?.organizationRef)
  // ) {
  //   set(formData, "organizationRef", null);
  // }

  const newRelatedResources = formData?.relatedResources
    ?.map((value) => {
      let output = { ...value };
      if (
        isNullOrEmpty(value.enReference?.name?.text) &&
        isNullOrEmpty(value?.enReference?.uri)
      ) {
        delete output.enReference;
      }
      if (
        isNullOrEmpty(value.frReference?.name?.text) &&
        isNullOrEmpty(value?.frReference?.uri)
      ) {
        delete output.frReference;
      }
      return output;
    })
    .filter((x) => Object.keys(x).length > 0);

  set(formData, "relatedResources", newRelatedResources);
};
