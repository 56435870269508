import { useMutation } from "@apollo/client";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { CreateDistributionListDocument } from "generated/gql-types";
import { CommunicationsPathHelpers } from "pages/communications/CommunicationsRouter";
import addContactIcon from "assets/svg/add-contact-icon.svg";
import * as React from "react";
import { OverlayContainer } from "react-aria";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DistributionListForm, {
  DistributionListFormFields,
} from "../DistributionListForm/DistributionListForm";

export const CreateDistributionListButtonAndModal: React.FC<{
  defaultValues?: DistributionListFormFields;
  showImage?: boolean;
  disabled?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const popupState = createDistributionListPopup({});
  const [createDistributionList, { loading: saving, error: savingError }] =
    useMutation(CreateDistributionListDocument);

  const onClose = () => {
    popupState.close();
  };

  const onSubmit: SubmitHandler<DistributionListFormFields> = async (
    formData
  ) => {
    try {
      const res = await createDistributionList({
        variables: {
          input: {
            name: formData?.name,
            note: formData?.note,
            program: {
              id: formData?.program?.id,
              name: formData?.program?.name,
              abbreviation: formData?.program?.abbreviation,
              acronym: formData?.program?.acronym,
            },
          },
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: CommunicationsPathHelpers.DistributionList(
          res.data?.createDistributionList?.id ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <button
        className="btn btn-link card-header-button-link"
        onClick={popupState.open}
        data-testid="button-create-distribution-list"
        title={t("create_distribution_list")}
        disabled={props.disabled}
      >
        {props.showImage? (
        <>
         <img src={addContactIcon} alt={t("create")} />
            <div className={"no-results-content"}>
              {t("create")}
            </div>
       
        </>
        ): (
          <>
           <i className="fas fa-plus-circle mrgn-rght-sm"></i>
          {t("create")}
          </>
        )}
      </button>
      {popupState.isOpen && (
        <OverlayContainer>
          <ModalDialog
            title={t("create_distribution_list")}
            className={"modal-width-70"}
            isOpen={false}
            onClose={onClose}
            showModalFooter={false}
          >
            <GraphqlError
              title="Error creating new distribution list"
              errors={savingError}
            />
            <DistributionListForm
              isEditMode={false}
              defaultValues={props.defaultValues}
              onClose={onClose}
              onSubmit={onSubmit}
            />
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
};

export default CreateDistributionListButtonAndModal;
export const createDistributionListPopup = useOverlayTriggerState;
