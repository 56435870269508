import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  Assessment,
  NameChangeAssessmentRadioDocument,
} from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../../../util/formatTimestamp";

export interface NameChangeAssessmentRadioProps {
  cosewicId: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  name: string;
  id: string;
  onChange: (value: Assessment) => void;
}

export const NameChangeAssessmentRadio = React.forwardRef<
  HTMLSelectElement,
  NameChangeAssessmentRadioProps
>((props, ref) => {
  const { loading, error, data } = useQuery(NameChangeAssessmentRadioDocument, {
    variables: {
      id: props.cosewicId,
    },
  });
  const { t, i18n } = useTranslation();

  if (loading) return <LoadingIndicator />;
  if (!props.cosewicId) return <p>Missing COSEWIC ID</p>;

  //
  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;
    props.onChange(id);
  };

  return (
    <>
      <div className="radio">
        <label htmlFor="nameChangeAssessmentRadio_notRelated">
          <input
            type="radio"
            value="Unset"
            id="nameChangeAssessmentRadio_notRelated"
            name={props.name}
            onChange={onChange}
          />
          <span className="mrgn-lft-sm">
            {t("not_related_to_an_assessment")}
          </span>
        </label>
      </div>
      {data?.assessmentList?.assessment.map((x) => {
        return (
          <div className="radio" key={x?.id}>
            <label htmlFor="nameChangeAssessmentRadio_assessment">
              <input
                type="radio"
                value={x?.id ?? "error - missing id"}
                id="nameChangeAssessmentRadio_assessment"
                name={props.name}
                onChange={onChange}
              />
              <span className="mrgn-lft-sm">{formatTimestamp(x?.date)}</span>
            </label>
          </div>
        );
      })}
    </>
  );
});

export interface NameChangeAssessmentRadioWithControllerProps<TFieldValues>
  extends Omit<NameChangeAssessmentRadioProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render" | "name"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const NameChangeAssessmentRadioWithController = <TFieldValues,>({
  id,
  cosewicId,
  name,
  ...props
}: NameChangeAssessmentRadioWithControllerProps<TFieldValues>) => {
  return (
    <Controller
      name={name as any}
      {...props}
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <NameChangeAssessmentRadio
          onChange={onChange}
          defaultValue={(value as any)?.id ?? ""}
          cosewicId={cosewicId}
          id={id}
          name={name}
        />
      )}
    />
  );
};

export default NameChangeAssessmentRadio;
