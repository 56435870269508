import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteForWildlifeManagementBoardsDocument,
  BilingualAbbreviationLookupTextInput,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import LookupQueryAutocomplete from "../../LookupQueryAutocomplete/LookupQueryAutocomplete";

export interface WildlifeManagementBoardsAutocompleteProps {
  initialValue?: BilingualAbbreviationLookupTextInput;
  isRequired?: boolean;
  onChange: (selectedItem?: BilingualAbbreviationLookupTextInput) => void;
}

export const WildlifeManagementBoardsAutocomplete: React.FC<
  WildlifeManagementBoardsAutocompleteProps
> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <LookupQueryAutocomplete
      id={"wildlifeManagementBoardsAutocomplete"}
      label={t("wildlife_management_board")}
      queryDocument={AutocompleteForWildlifeManagementBoardsDocument}
      listDisplayFormatter={(item) =>
        bilingualLookupTextNameForLanguage(i18n.language, item)
      }
      onSelectionChange={props.onChange}
      placeholder={
        props.initialValue
          ? bilingualLookupTextNameForLanguage(
              i18n.language,
              props.initialValue
            )
          : t("select_one_or_many_items")
      }
      isRequired={props.isRequired}
      onClear={() => props.onChange(undefined)}
    />
  );
};

export default WildlifeManagementBoardsAutocomplete;
