import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import RangeCheckboxes from "components/organisms/cosewic/RangeCheckboxes";
import {
  AdminResponseStatement,
  BilingualName,
  Maybe,
} from "generated/gql-types";
import { makeBilingualName } from "mappers";
import * as React from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "../../../../util/forms";
import AdminResponseStatementDropdownWithController from "../../responseStatement/ResponseStatementDropdown/ResponseStatementDropdown";

interface ResponseStatementFormProps {
  defaultValues: Partial<ResponseStatementFormFields>;
  onSubmit: SubmitHandler<Partial<ResponseStatementFormFields>>;
  onClose: () => void;
}

const ResponseStatementForm: React.FC<ResponseStatementFormProps> = (props) => {
  const form = useForm<ResponseStatementFormFields>({
    defaultValues: props.defaultValues,
  });

  const { handleSubmit, formState, control, setValue } = form;
  const { isDirty, errors, isSubmitting, dirtyFields } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<ResponseStatementFormFields> = async (
    formData
  ) => {
    const values = filterForDirtyFields(formData, dirtyFields);

    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        // Note: Filter dirty fields and Set null for empty fields are not required for updating Tif.
        // const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
        // setNullForEmptyFields(FilteredFormData, dirtyFields, props.defaultValues);
        props.onSubmit(values);
        resolve();
      }, 500);
    });
  };

  const showConsultedProvincesAndTerritories = useWatch({
    control,
    name: "scenario.includeConsultationPeriod",
    defaultValue: props.defaultValues.scenario?.includeConsultationPeriod,
  });

  const consultedProvincesAndTerritories = React.useMemo(() => {
    // avoid doing any of this work if the field is not visible.
    // this is important so we don't dirty the consultedProvincesAndTerritories and
    // accidentally submit a value for the field even though it's not visible.
    if (!showConsultedProvincesAndTerritories) return [];

    // use the provided consultedProvincesAndTerritories as a default value if available
    if (
      props.defaultValues?.consultedProvincesAndTerritories != null &&
      props.defaultValues.consultedProvincesAndTerritories.length > 0
    ) {
      return props.defaultValues?.consultedProvincesAndTerritories;
    }

    // otherwise, try to use the listingWS ranges as a NEW default value
    if (
      props.defaultValues?.listingWsRanges != null &&
      props.defaultValues.listingWsRanges.length > 0
    ) {
      const newDefaultValue =
        props.defaultValues?.listingWsRanges?.map((x) =>
          makeBilingualName(x)
        ) ?? [];

      // this extra setValue call is so that the field gets dirtied, and the form submits it to
      // the API for saving, even when the user doesn't click any checkboxes.
      setValue("consultedProvincesAndTerritories", newDefaultValue, {
        shouldDirty: true,
      });

      return newDefaultValue;
    }

    // default to nothing selected
    return [];
  }, [
    props.defaultValues?.consultedProvincesAndTerritories,
    props.defaultValues.listingWsRanges,
    showConsultedProvincesAndTerritories,
  ]);

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/* ---------- Due date ---------- */}
      {/* Confirmed with PO we should hide the due date field in the response statement form. */}
      {/* <div className="form-group">
        <Controller
          control={control}
          name="dueDate"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id="dpk_start_date"
              data-testid="dpk_start_date"
              required
              label={t("due_date")}
              value={value as string}
              onInput={onChange}
              className="width-230"
              fromController
            />
          )}
        />
        {errors.dueDate && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div> */}

      {/* ---------- Admin response statement dropdown ---------- */}
      <div className="form-group">
        <label htmlFor="scenario-input" className="required">
          {t("minister_response")}
          <strong className="required"> ({t("required")})</strong>
        </label>
        <AdminResponseStatementDropdownWithController
          control={control}
          name="scenario"
          id="scenario-input"
          data-testid="scenario-input"
          required
        />
      </div>

      {/* ---------- Provinces and territories that have been consulted ---------- */}
      {showConsultedProvincesAndTerritories ? (
        <div className="form-group">
          <fieldset className="chkbxrdio-grp">
            <legend className="required">
              <span className="field-name">
                {t("provinces_and_territories_consulted")}
                <strong className="required"> ({t("required")})</strong>
              </span>
            </legend>
            <Controller
              control={control}
              name={"consultedProvincesAndTerritories"}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <RangeCheckboxes
                  onChange={onChange}
                  defaultSelected={consultedProvincesAndTerritories}
                />
              )}
            />
          </fieldset>
          {errors.consultedProvincesAndTerritories && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>
      ) : null}

      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
        errors={errors}
      />
    </form>
  );
};

export default ResponseStatementForm;

export interface ResponseStatementFormFields {
  dueDate: string;
  scenario: Partial<AdminResponseStatement>;
  consultedProvincesAndTerritories: DefinitelyGQL<BilingualName>[];
  listingWsRanges: Maybe<BilingualName>[];
}
