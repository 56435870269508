import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SectionCard from "../../../atoms/SectionCard";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import { IndigenousCommunityMultiSelectListWithController } from "../IndigenousCommunityMultiSelectList/IndigenousCommunityMultiSelectList";
import { StakeholderMultiSelectListWithController } from "../StakeholderMultiSelectList/StakeholderMultiSelectList";
import { WildlifeManagementBoardsMultiSelectListWithController } from "../WildlifeManagementBoardsMultiSelectList/WildlifeManagementBoardsMultiSelectList";
import { ListingFormChangeTracking, ListingFormFields } from "./index";
import { TextInput } from "components/atoms/forms/TextInput";

export interface ConsultationConsiderationsSectionProps {
  initialValues: ListingFormFields;
  open: boolean;
}

export const ConsultationConsiderationsSection: React.FC<
  ConsultationConsiderationsSectionProps
> = (props) => {
  const { initialValues, open } = props;
  const { t } = useTranslation();
  const { control, register } = useFormContext<
    ListingFormFields & ListingFormChangeTracking
  >();

  return (
    <SectionCard
      collapsible
      open={open}
      header={<h2>{t("consultation_considerations")}</h2>}
    >
      <div className="flex-col gap-md">
        {/* COMPETENT MINISTER */}
        <TextInput
          type="text"
          label={t("estimated_total_mature_individuals_at_assessment")}
          inputClassName="width-100"
          id="estimatedTotalMatureIndividualsAtAssessmentInput"
          placeholder={t("enter_some_text")}
          {...register(
            "consultationConsideration.estimatedIndividualAssessment"
          )}
        />

        {/* STAKEHOLDERS */}
        <section>
          <header>
            <h3 className="mrgn-tp-md page-header">{t("stakeholders")}</h3>
          </header>
          <StakeholderMultiSelectListWithController
            control={control}
            defaultValue={initialValues.consultationConsideration.stakeholders}
            name="consultationConsideration.stakeholders"
          />
        </section>

        {/* INDIGENOUS COMMUNITY */}
        <section>
          <header>
            <h3 className="mrgn-tp-md page-header">
              {t("indigenous_communities")}
            </h3>
          </header>
          <IndigenousCommunityMultiSelectListWithController
            control={control}
            defaultValue={
              initialValues.consultationConsideration.indigenousCommunities
            }
            name="consultationConsideration.indigenousCommunities"
          />
        </section>

        {/* WILDLIFE MANAGEMENT BOARDS */}
        <section>
          <header>
            <h3 className="mrgn-tp-md page-header">
              {t("wildlife_management_boards")}
            </h3>
          </header>

          <WildlifeManagementBoardsMultiSelectListWithController
            control={control}
            defaultValue={
              initialValues.consultationConsideration.wildlifeManagementBoards
            }
            name="consultationConsideration.wildlifeManagementBoards"
          />
        </section>

        {/* CONSERVATION ACTIVITIES ENGLISH */}

        <div>
          <label htmlFor="conservationActivitiesEnglish">
            {t("conservation_activities_english")}
          </label>
          <FullHTMLEditorWithController
            control={control}
            defaultValue={
              initialValues.consultationConsideration.conservationActivities
                .english?.text ?? ""
            }
            id="conservationActivitiesEnglish"
            name="consultationConsideration.conservationActivities.english.text"
          />
        </div>

        {/* CONSERVATION ACTIVITIES ENGLISH */}

        <div>
          <label htmlFor="conservationActivitiesFrench">
            {t("conservation_activities_french")}
          </label>
          <FullHTMLEditorWithController
            control={control}
            defaultValue={
              initialValues.consultationConsideration.conservationActivities
                .french?.text ?? ""
            }
            id="conservationActivitiesFrench"
            name="consultationConsideration.conservationActivities.french.text"
          />
        </div>
      </div>
    </SectionCard>
  );
};
