import EditLink from "components/atoms/EditLink";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import {
  Assessment,
  AssessmentReportType,
  AssessmentStage,
  AssessmentState,
  CosewicWsState,
  CosewicWsStatus,
} from "generated/gql-types";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface AssessmentInformationCardProps {
  header: string;
  open: boolean;
  editLinkTo: string;
  assessment?: Assessment;
  cosewicWs?: CosewicWsStateAndStatusFields;
  editDisabled: boolean;
}

export interface CosewicWsStateAndStatusFields {
  state?: CosewicWsState;
  status?: CosewicWsStatus;
}

export const AssessmentInformationCard: React.FC<
  AssessmentInformationCardProps
> = (props) => {
  const { cosewicWs, assessment, editLinkTo, header, open = false } = props;
  const { t } = useTranslation();

  // Show or hide the "COSEWIC reports sent to Minister date" field based on these conditions (US 7252).
  const showDateSentToMinister =
    cosewicWs?.status === CosewicWsStatus.Permanent &&
    cosewicWs?.state === CosewicWsState.Live &&
    assessment?.stage === AssessmentStage.Assessed &&
    assessment?.state === AssessmentState.Published;

  return (
    <SectionCard
      open={open}
      header={
        <div className="flex justify-between align-start">
          <h2 className="mrgn-tp-sm">{t("assessment_information")}</h2>
          <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.cosewic.update}>
            <EditLink
              linkTo={editLinkTo}
              state={header}
              disabled={props.editDisabled}
            />
          </RenderWhenAuthorized>
        </div>
      }
    >
      <div className="species-field">
        <div className="row">
          <dl>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("assessment_date")}</dt>
              <dd>{formatTimestamp(assessment?.date) ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("assessment_type")}</dt>
              <dd>
                {assessment?.type ? t(assessment?.type) : <MissingData />}
              </dd>
            </div>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("type_of_report")}</dt>
              <dd>
                {assessment?.reportType &&
                assessment.reportType !==
                  AssessmentReportType.NotInitialized ? (
                  t(assessment?.reportType)
                ) : (
                  <MissingData />
                )}
              </dd>
            </div>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("order_of_assessment")}</dt>
              <dd>
                {isNullOrEmpty(assessment?.order) ? (
                  <MissingData />
                ) : (
                  assessment?.order
                )}
              </dd>
            </div>
          </dl>
        </div>

        {showDateSentToMinister ? (
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("cosewic_reports_sent_to_minister_date")}
                </dt>
                <dd>
                  {formatTimestamp(assessment?.dateSentToMinister) ?? (
                    <MissingData />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        ) : null}
      </div>
    </SectionCard>
  );
};
export default AssessmentInformationCard;
