/* eslint-disable no-fallthrough */
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { resolveAzureEnvironment, RunEnvironment } from "./environment";

const env = resolveAzureEnvironment();
let appInsights: ApplicationInsights | null = null;

switch (env) {
  case RunEnvironment.UNRECOGNIZED:
  case RunEnvironment.LOCAL:
    break; // Application insights is disabled for local development
  case RunEnvironment.AZURE_DEV:
  case RunEnvironment.AZURE_ALTDEV:
  case RunEnvironment.AZURE_TEST:
  case RunEnvironment.AZURE_MIGRATION:
  case RunEnvironment.AZURE_PREPROD:
  case RunEnvironment.AZURE_PROD:
  case RunEnvironment.AZURE_CLIENT: {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
      },
    });

    appInsights.loadAppInsights();
  }
}

export { appInsights };
