import * as React from "react";
import { useTranslation } from "react-i18next";
import EditLinkPopup, { useManageLinkPopup } from "./EditLinkPopup";

const EditLinkBtn: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation();
  const modalState = useManageLinkPopup({});
  const validDocumentId =
    props.documentId != null && props.documentId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link font-size-14 hover-grey"
        onClick={modalState.open}
        disabled={!validDocumentId}
        data-testid="button-edit-link"
        title={t("edit_link")}
      >
        <i className="fas fa-pen"></i>
      </button>
      {validDocumentId ? (
        <EditLinkPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditLinkBtn;
