import * as React from "react";
import { flushSync } from "react-dom";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import {
  AutocompleteForAddDocumentsDocument,
  DocumentRef,
  DocumentSearchItem,
  DocumentState,
} from "../../../../../generated/gql-types";
import isNullOrEmpty from "../../../../../util/isNullOrEmpty";
import CustomQueryAutocomplete from "../../../QueryAutocomplete/CustomQueryAutocomplete";
import { useQueryAutocompleteInstance } from "../../../QueryAutocomplete/useQueryAutocompleteInstance";
import { RegistryPageFormFields } from "../RegistryPageForm/RegistryPageForm";
import AddDocumentsMultiSelectListItem from "./AddDocumentsMultiSelectListItem";

export interface AddDocumentsMultiSelectListProps {
  value: Array<DocumentRef>;
  onChange: (listItems: Array<DocumentRef>) => void;
  required?: boolean;
}

export const AddDocumentsMultiSelectList: React.FC<
  AddDocumentsMultiSelectListProps
> = (props) => {
  const { value, onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    DocumentRef | undefined
  >(undefined);
  const documentAutocompleteInstance = useQueryAutocompleteInstance(
    AutocompleteForAddDocumentsDocument
  );

  const { resetField } = useFormContext<RegistryPageFormFields>();

  const nameForAddDocumentsMultiSelectListItem = React.useCallback(
    (item: DocumentRef) =>
      makeNameForAddDocumentsMultiSelectListItem(item, i18n.language),
    [i18n.language]
  );

  const onRemoveFromList = (id: string) => {
    const newList = value?.filter((x) => x.id !== id);

    flushSync(() => {
      resetField(`documentRefs`, { defaultValue: null });
      onChange(newList);
    });
  };

  const onSelectionChange = (newSpecies?: DocumentSearchItem | null) => {
    // if the autocomplete gets cleared, clear the selected search item and clear the input.
    if (newSpecies == null) {
      setSelectedSearchItem(undefined);
      documentAutocompleteInstance.forceClear();
      return;
    }

    const newDocumentRef = mapDocumentSearchItemIntoDocumentRef(newSpecies);
    setSelectedSearchItem(newDocumentRef);

    documentAutocompleteInstance.forceClear();
  };

  React.useEffect(() => {
    const isAlreadyInList =
      value?.some((x) => x.id === selectedSearchItem?.id) ?? false;

    if (selectedSearchItem != null && !isAlreadyInList) {
      const newItem = Object.assign({}, selectedSearchItem); // Copy the object here to avoid "object is not extensible" exception.

      if (value == null) {
        onChange([newItem]);
      } else {
        onChange([newItem, ...value]);
      }
    }
  }, [onChange, selectedSearchItem, value]);

  React.useEffect(() => {
    if (selectedSearchItem) {
      setSelectedSearchItem(undefined);
    }
  }, [selectedSearchItem]);

  const onMoveItemDown = (fromIndex: number) => {
    const newArr = [...value];

    let newIndex = fromIndex + 1;
    if (newIndex >= newArr.length) {
      return;
    }

    const backup = newArr[newIndex];
    newArr[newIndex] = newArr[fromIndex];
    newArr[fromIndex] = backup;

    onChange(newArr);
  };

  return (
    <>
      <div className="flex gap-sm align-end">
        <CustomQueryAutocomplete
          useQueryAutocompleteInstance={documentAutocompleteInstance}
          id={"organizationAutocomplete"}
          label={t("add_documents")}
          disabled={false}
          listDisplayFormatter={(item: DocumentRef) => {
            const title =
              i18n.language === "fr"
                ? item?.title?.french?.plainText
                : item?.title?.english?.plainText;
            return `${title}`;
          }}
          onSelectionChange={onSelectionChange}
          placeholder={t("select_a_document")}
        />
      </div>

      <ul className="multi-select-list">
        {value?.map((x, index) => {
          const id = `addDocumentListItem-${x.id}`;
          const onMoveDown =
            index === value?.length - 1
              ? undefined
              : () => onMoveItemDown(index);
          return (
            <AddDocumentsMultiSelectListItem
              id={id}
              key={id}
              name={nameForAddDocumentsMultiSelectListItem(x)?.name ?? "error"}
              onRemove={() => onRemoveFromList(x.id ?? "")}
              onMoveDown={onMoveDown}
              index={index}
            />
          );
        })}
      </ul>
    </>
  );
};

export interface AddDocumentsMultiSelectListWithControllerProps<TFieldValues>
  extends Omit<AddDocumentsMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const AddDocumentsMultiSelectListWithController = <TFieldValues,>(
  props: AddDocumentsMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      rules={{ required: props.required }}
      render={({ field: { value, onChange } }) => (
        <AddDocumentsMultiSelectList
          value={value as Array<DocumentRef>}
          onChange={onChange}
          required={props.required}
        />
      )}
    />
  );
};

export default AddDocumentsMultiSelectList;

export const makeNameForAddDocumentsMultiSelectListItem = (
  item: DocumentRef,
  language: "en" | "fr" | string
) => {
  if (language === "fr") {
    const title = !isNullOrEmpty(item?.title?.french?.plainText)
      ? item.title?.french?.plainText
      : item.title?.english?.plainText;

    const documentType = !isNullOrEmpty(item?.documentType?.name?.english)
      ? item?.documentType?.name?.english
      : item?.documentType?.name?.french;

    const titleStr = title ? title : "";
    const documentTypeStr = documentType ? ` - ${documentType}` : "";

    return {
      name: `${titleStr}${documentTypeStr}`,
    };
  }

  const title = !isNullOrEmpty(item?.title?.english?.plainText)
    ? item?.title?.english?.plainText
    : item?.title?.french?.plainText;

  const documentType = !isNullOrEmpty(item?.documentType?.name?.english)
    ? item.documentType?.name?.english
    : item.documentType?.name?.french;

  const titleStr = title ? title : "";
  const documentTypeStr = documentType ? ` - ${documentType}` : "";

  return {
    name: `${titleStr}${documentTypeStr}`,
  };
};

const mapDocumentSearchItemIntoDocumentRef = (
  documentSearchItem?: DocumentSearchItem | null
) => {
  const out: DocumentRef = {};

  // can't map these dates from a DocumentSearchItem! default to null.
  out.consultationDates = { fromDate: null, toDate: null };
  out.publishedDate = null;

  if (documentSearchItem?.id) out.id = documentSearchItem?.id;

  if (documentSearchItem?.documentId)
    out.documentId = documentSearchItem?.documentId;

  if (documentSearchItem?.documentType)
    out.documentType = documentSearchItem?.documentType;

  if (documentSearchItem?.title) out.title = documentSearchItem?.title;

  if (documentSearchItem?.state)
    out.state = documentSearchItem?.state as DocumentState;

  if (
    documentSearchItem?.associatedSpecies &&
    documentSearchItem?.associatedSpecies.length > 0
  )
    out.associatedSpecies = documentSearchItem?.associatedSpecies;

  return out;
};
