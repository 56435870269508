// Mapping pathProperty (from response) to tag (for display)
const pathToTag = new Map([
  ["/date", "date"],
  ["/subSpecies", "sub_species"],
  ["/variety", "variety"],
  ["/species", "species"],
  ["/genus", "genus"],
  ["/endemicStatus", "endemic_status"],
  ["/ranges", "range"],
  ["/population", "population"],
  ["/taxonomicGroup", "taxonomic_group"],
  ["/nameWithComma", "name_with_comma"],
  ["/commonNameEnglish", "common_name_english"],
  ["/commonNameFrench", "common_name_french"],
  ["/otherRecognizedCommonNameEN", "other_recognized_english_common_names"],
  ["/otherRecognizedCommonNameFR", "other_recognized_french_common_names"],
  ["/otherRecognizedScientificName", "other_recognized_scientific_names"],
  ["/scientificName/generatedScientificName", "scientific_name"],
  ["/indigenousName", "indigenous_names"],
  ["/statusAndCriteria/statusComment/english", "status_comment_english"],
  ["/statusAndCriteria/statusComment/french", "status_comment_french"],
  ["/statusAndCriteria/statusCriteria/english", "status_criteria_english"],
  ["/statusAndCriteria/statusCriteria/french", "status_criteria_french"],
  ["/statusAndCriteria/applicabilityOfCriteria/A", "applicability_criteria_a"],
  ["/statusAndCriteria/applicabilityOfCriteria/B", "applicability_criteria_b"],
  ["/statusAndCriteria/applicabilityOfCriteria/C", "applicability_criteria_c"],
  ["/statusAndCriteria/applicabilityOfCriteria/D", "applicability_criteria_d"],
  ["/statusAndCriteria/applicabilityOfCriteria/E", "applicability_criteria_e"],
  ["/designation/historyStatus/english", "designation_history_english"],
  ["/designation/historyStatus/french", "designation_history_french"],
  ["/designation/reason/english", "designation_reason_english"],
  ["/designation/reason/french", "designation_reason_french"],
  ["/dateSentToMinister", "date_sent_to_minister"],
]);

export function convertPathToTag(path: string | undefined): string {
  if (path)
    return pathToTag.get(path) ?? "Undefined pathProperty '" + path + "'";
  return "Undefined pathProperty";
}
