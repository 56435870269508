import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { MAX_LENGTH_OF_20K, MAX_LENGTH_OF_500 } from "config/constants";
import {
  DocumentContentOwner,
  DocumentPublicationType,
  DocumentState,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { htmlToPlainText } from "util/richtext";
import SectionCard from "../../../atoms/SectionCard";
import { AssociatedSpeciesMultiSelectListWithController } from "../AssociatedSpeciesMultiSelectList/AssociatedSpeciesMultiSelectList";
import DocumentTypeAutocomplete from "../DocumentTypeAutocomplete";
import { ProgramMultiSelectListWithController } from "../ProgramMultiSelectList/ProgramMultiSelectList";
import { DocumentFormFields } from "./DocumentForm";

export interface DocumentInformationCardProps {
  initialValues: Partial<DocumentFormFields>;
  documentState?: Maybe<DocumentState>;
}

export const DocumentInformationCard: React.FC<DocumentInformationCardProps> = (
  props
) => {
  const { initialValues, documentState } = props;
  const { t, i18n } = useTranslation();
  const form = useFormContext<DocumentFormFields>();
  const { control, register, formState, getValues, trigger, setValue, watch } =
    form;
  const { errors } = formState;

  var [publicationType, setPublicationType] =
    React.useState<DocumentPublicationType>(
      initialValues.publicationType === DocumentPublicationType.Internal
        ? DocumentPublicationType.Internal
        : DocumentPublicationType.Public
    );

  const validateAtLeastOneTitle = () =>
    htmlToPlainText(getValues("title.english.text") ?? "") !== "" ||
    htmlToPlainText(getValues("title.french.text") ?? "") !== "";

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_500;

  const validateHtmlTextMax20KLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_20K;

  // Prevent changing publication type for published documents. (Bug 82155)
  const isPublicationTypeDisabled = documentState === DocumentState.Published;

  return (
    <SectionCard header={<h2>{t("document_information")}</h2>}>
      {/* -------------- Publication type -------------- */}
      <div className="form-group">
        <fieldset
          className="chkbxrdio-grp"
          disabled={isPublicationTypeDisabled}
        >
          <legend className="required">
            <span className="field-name">{t("publication_type")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>
          {Object.values(DocumentPublicationType).map((value) => {
            return (
              <label
                htmlFor={`radio-publication-type-${value}`}
                className="radio-inline"
                key={value}
              >
                <input
                  type="radio"
                  value={value}
                  id={`radio-publication-type-${value}`}
                  onClick={() => {
                    setPublicationType(value);
                    setValue("documentType", undefined, {
                      shouldDirty: true,
                    });
                  }}
                  {...register("publicationType", {
                    required: true,
                  })}
                />
                &#160;{t(value)}
              </label>
            );
          })}
        </fieldset>
        {errors.publicationType && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Document name -------------- */}
      <div className="form-group">
        <fieldset className="fieldset-grp">
          <legend className="required">
            <span className="field-name">{t("document_name")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>
          <div className="border-light">
            {/* -------------- English -------------- */}
            <div className="form-group">
              <label htmlFor="documentTitleEnglishRichText">
                {t("english")}
              </label>
              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  validate: {
                    atLeastOneName: validateAtLeastOneTitle,
                    maxLengthLimit: validateHtmlTextMaxLengthLimit,
                  },
                  onChange: () => trigger("title"),
                }}
                name="title.english.text"
                id="documentTitleEnglishRichText"
                defaultValue={initialValues.title?.english?.text ?? ""}
                placeholder={t("enter_some_text")}
              />
              {errors.title?.english &&
                (errors.title?.english as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_500,
                    })}
                  </FieldValidationError>
                )}
            </div>

            {/* -------------- French -------------- */}
            <div className="form-group">
              <label htmlFor="documentTitleFrenchRichText">{t("french")}</label>
              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  validate: {
                    atLeastOneName: validateAtLeastOneTitle,
                    maxLengthLimit: validateHtmlTextMaxLengthLimit,
                  },
                  onChange: () => trigger("title"),
                }}
                name="title.french.text"
                id="documentTitleFrenchRichText"
                defaultValue={initialValues.title?.french?.text ?? ""}
                placeholder={t("enter_some_text")}
              />
              {errors.title?.french &&
                (errors.title?.french as any)?.text?.type ===
                  "maxLengthLimit" && (
                  <FieldValidationError>
                    {t("reach_the_max_length", {
                      count: MAX_LENGTH_OF_500,
                    })}
                  </FieldValidationError>
                )}
            </div>
          </div>
        </fieldset>
        {errors.title?.english &&
          (errors.title?.english as any)?.text?.type === "atLeastOneName" &&
          errors.title?.french &&
          (errors.title?.french as any)?.text?.type === "atLeastOneName" && (
            <FieldValidationError>
              {t("at_least_one_language_is_required")}
            </FieldValidationError>
          )}
      </div>

      <div className="row">
        <div className="col-md-6">
          {/* -------------- Content owner -------------- */}
          <div className="form-group">
            <fieldset className="chkbxrdio-grp">
              <legend className="required">
                <span className="field-name">{t("content_owner")}</span>{" "}
                <strong className="required"> ({t("required")})</strong>
              </legend>
              {Object.values(DocumentContentOwner).map((value) => {
                return (
                  <div className="radio" key={value}>
                    <label htmlFor={`radio-content-owner-${value}`}>
                      <input
                        type="radio"
                        value={value}
                        id={`radio-content-owner-${value}`}
                        {...register("contentOwner", {
                          required: true,
                        })}
                      />
                      &#160;
                      {t(value)}
                    </label>
                  </div>
                );
              })}
            </fieldset>
            {errors.contentOwner && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        </div>
      </div>
      {/*---------------Program Type Multiselect------------------------*/}
      <div className="flex align-end gap-md">
        <div className="relative width-100">
          <div className="form-group">
            <ProgramMultiSelectListWithController
              control={control}
              name="programs"
              isRequired={true}
              label={t("Programs")}
            />
            {errors.programs && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>
        </div>
      </div>
      {/*---------------Document Type Auto Complete---------------------*/}
      <div className="form-group">
        <div className="flex align-end gap-md">
          <Controller
            control={control}
            name="documentType"
            rules={{
              required: true,
              onChange: () => trigger("documentType"),
            }}
            render={({ field: { onChange, value } }) => (
              <DocumentTypeAutocomplete
                label={t("document_type")}
                isRequired={true}
                initialDocumentType={watch("documentType")}
                onSelectionChange={onChange}
                publicationType={publicationType}
              />
            )}
          />
        </div>

        {errors.documentType && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* -------------- Associated wildlife species -------------- */}
      <div className="form-group">
        <AssociatedSpeciesMultiSelectListWithController
          control={control}
          defaultValue={initialValues.associatedSpecies}
          name="associatedSpecies"
        />
      </div>

      {/* -------------- Note -------------- */}
      <div className="form-group">
        <label htmlFor="note">{t("note")}</label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            validate: {
              maxLengthLimit: validateHtmlTextMax20KLengthLimit,
            },
          }}
          defaultValue={initialValues.note?.text ?? ""}
          id="note"
          name="note.text"
        />
        {/* {errors.note && (
          <FieldValidationError>{errors.note}</FieldValidationError>
        )} */}
        {errors.note && (errors.note as any)?.text?.type === "maxLengthLimit" && (
          <FieldValidationError>
            {t("reach_the_max_length", {
              count: MAX_LENGTH_OF_20K,
            })}
          </FieldValidationError>
        )}
      </div>
    </SectionCard>
  );
};
