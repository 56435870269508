import {
  BilingualAbbreviationLookupTextInput,
  BilingualLookupText,
  BilingualLookupTextInput,
  BilingualName,
  BilingualNoteInput,
  BilingualRichText,
  BilingualRichTextInput,
  BilingualText,
  BilingualTextInput,
  IdNameType,
  ListingProcessDocument,
  ListingProcessDocumentInput,
  ListingProcessDocumentType,
  Maybe,
  NaicsDetailsItem,
  NaicsResource,
  NumericRange,
  Range,
  Region,
  RichText,
  RichTextInput,
  TaxonomicGroup,
} from "generated/gql-types";
import { isString } from "lodash";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import { sanitizeDoubleSpaces } from "util/strings";
import { NAICS_VERSION } from "../config/constants";
import isNullOrEmpty from "../util/isNullOrEmpty";
import { sanitizeAndTrimHtml } from "../util/richtext";

// TODO: organize these functions better

export const makeListingProcessDocumentInput = (
  type: ListingProcessDocumentType,
  overrides?: Partial<
    Maybe<ListingProcessDocument> | Maybe<ListingProcessDocumentInput>
  >
) => {
  if (!type) {
    throw new Error(
      "makeListingProcessDocumentInput requires a ListingProcessDocumentType argument."
    );
  }

  const out: ListingProcessDocumentInput = {
    type: overrides?.type ?? type,
    publicationDate:
      formatTimestamp(
        overrides?.publicationDate,
        DisplayFormat.YEAR_MONTH_DAY
      ) ?? "",
    uri: overrides?.uri ?? "",
  };

  return out;
};

export const makeListingProcessDocument = (
  type: ListingProcessDocumentType,
  overrides?: Partial<Maybe<ListingProcessDocument>>
) => {
  if (!type) {
    throw new Error(
      "makeListingProcessDocument requires a ListingProcessDocumentType argument."
    );
  }

  const out: ListingProcessDocument = {
    type: overrides?.type ?? type,
    publicationDate:
      formatTimestamp(
        overrides?.publicationDate,
        DisplayFormat.YEAR_MONTH_DAY
      ) ?? "",
    uri: overrides?.uri ?? "",
  };

  return out;
};

export const makeBilingualName = (overrides?: Maybe<BilingualName>) => {
  const out: DefinitelyGQL<BilingualName> = {
    id: overrides?.id ?? "",
    nameEn: overrides?.nameEn ?? "",
    nameFr: overrides?.nameFr ?? "",
  };
  return out;
};

export const makeBilingualRegionName = (overrides?: Maybe<Region>) => {
  const out: DefinitelyGQL<Region> = {
    id: overrides?.id ?? "",
    nameEn: overrides?.nameEn ?? "",
    nameFr: overrides?.nameFr ?? "",
  };
  return out;
};

export const makeIdName = (overrides?: Maybe<IdNameType>) => {
  const out: DefinitelyGQL<IdNameType> = {
    id: overrides?.id ?? "",
    name: overrides?.name ?? "",
  };

  return out;
};

export const makeBilingualAbbreviationLookupText = (
  overrides?: Maybe<BilingualAbbreviationLookupTextInput>
) => {
  const out: BilingualAbbreviationLookupTextInput = {
    id: overrides?.id ?? "",
    name: {
      english: overrides?.name?.english ?? "",
      french: overrides?.name?.french ?? "",
    },
    abbreviation: {
      english: overrides?.abbreviation?.english ?? "",
      french: overrides?.abbreviation?.french ?? "",
    },
  };

  return out;
};

export const makeBilingualLookupText = (
  overrides?: Maybe<BilingualLookupText>
) => {
  const out: BilingualLookupText = {
    id: overrides?.id ?? "",
    name: {
      english: overrides?.name?.english ?? "",
      french: overrides?.name?.french ?? "",
    },
  };

  return out;
};

export const bilingualNameForLanguage = (
  languageCode: string,
  item?: Maybe<BilingualName> | Maybe<Region>
) => {
  if (item == null || !item.nameEn || !item.nameFr)
    return "[bilingualNameForLanguage got invalid item]";

  if (languageCode === "fr") {
    return item.nameFr;
  }

  return item.nameEn;
};

export const fullBilingualNameForLanguage = (
  languageCode: string,
  item?: Maybe<FullBilingualName> | Maybe<Range> | Maybe<TaxonomicGroup>
) => {
  if (item == null || !item.nameEnglish || !item.nameFrench)
    return "[fullBilingualNameForLanguage got invalid item]";

  if (languageCode === "fr") {
    return item.nameFrench;
  }

  return item.nameEnglish;
};

export const bilingualLookupTextNameForLanguage = (
  languageCode: string,
  item?: Maybe<BilingualLookupText> | Maybe<BilingualLookupTextInput>
) => {
  if (item == null)
    return "[bilingualLookupTextNameForLanguage got invalid item]";

  if (languageCode === "fr") return item?.name?.french ?? "-";
  return item?.name?.english ?? "-";
};

export const bilingualTextNameForLanguage = (
  languageCode: string,
  item?: Maybe<BilingualText> | Maybe<BilingualTextInput>
) => {
  if (item == null) return "[bilingualTextNameForLanguage got invalid item]";

  if (languageCode === "fr") return item?.french ?? "-";
  return item?.english ?? "-";
};

export const bilingualRichTextForLanguage = (
  languageCode: string,
  item?: Maybe<BilingualRichText>
) => {
  if (item == null) {
    console.error("[bilingualRichTextForLanguage got invalid item]");
    return "";
  }

  if (languageCode === "fr") return item?.french?.text ?? "-";
  return item?.english?.text ?? "-";
};

export const bilingualRichTextObjectForLanguage: any = (
  languageCode: string,
  item?: Maybe<BilingualRichText>
) => {
  if (item == null) {
    console.error("[bilingualRichTextObjectForLanguage got invalid item]");
    return undefined;
  }

  if (languageCode === "fr") return item?.french;
  return item?.english;
};

/// Converts a Maybe<BilingualText> into a fully formed BilingualText, with default values where an input field is missing.
export const makeBilingualText = (overrides?: Maybe<BilingualText>) => {
  const out: DefinitelyGQL<BilingualText> = {
    english: sanitizeDoubleSpaces(overrides?.english) ?? "",
    french: sanitizeDoubleSpaces(overrides?.french) ?? "",
  };
  return out;
};

/// Converts a Maybe<RichText> into a fully formed RichText, with default values where an input field is missing.
export const makeRichText = (overrides?: Maybe<RichText>) => {
  const out: DefinitelyGQL<RichText> = {
    text: overrides?.text ? sanitizeAndTrimHtml(overrides?.text) : "",
    // text: overrides?.text ?? "",
    plainText: overrides?.plainText ?? "",
  };
  return out;
};

/// Converts a Maybe<RichTextInput> into a fully formed RichTextInput, with default values where an input field is missing.
export const makeRichTextInput = (overrides?: Maybe<RichTextInput>) => {
  const out: DefinitelyGQL<RichTextInput> = {
    text: overrides?.text ? sanitizeAndTrimHtml(overrides?.text) : "",
    // text: overrides?.text ?? "",
  };
  return out;
};

/// Converts a Maybe<BilingualText> into a fully formed BilingualText, with default values where an input field is missing.
export const makeBilingualRichText = (overrides?: Maybe<BilingualRichText>) => {
  const out: DefinitelyGQL<BilingualRichText> = {
    english: makeRichText(overrides?.english) as any,
    french: makeRichText(overrides?.french) as any,
  };
  return out;
};

/// Converts a Maybe<BilingualRichTextInput> into a fully formed BilingualRichTextInput, with default values where an input field is missing.
export const makeBilingualRichTextInput = (
  overrides?: Maybe<BilingualRichTextInput>
) => {
  const out: DefinitelyGQL<BilingualRichTextInput> = {
    english: makeRichTextInput(overrides?.english) as any,
    french: makeRichTextInput(overrides?.french) as any,
  };
  return out;
};

export const makeBilingualNote = (overrides?: Maybe<BilingualNoteInput>) => {
  const out: DefinitelyGQL<BilingualNoteInput> = {
    english: { text: overrides?.english?.text ?? "" },
    french: { text: overrides?.french?.text ?? "" },
    // modifiedAt: overrides?.modifiedAt ?? "",
    // modifiedBy: overrides?.modifiedBy ?? "",
  };
  return out;
};

export const makeNaicsResource = (
  overrides?: Partial<NaicsResource> | Partial<NaicsDetailsItem>
) => {
  const out: NaicsResource = {};

  if (overrides?.code) out.code = overrides?.code;
  if (overrides?.className)
    out.className = {
      english: overrides?.className.english ?? "",
      french: overrides?.className.french ?? "",
    };

  out.classVersion = NAICS_VERSION;

  return out;
};

export const bilingualLookupLandManagerForLanguage = (
  languageCode: string,
  item?: any
) => {
  if (item == null)
    return "[bilingualLookupLandManagerForLanguage got invalid item]";

  if (languageCode === "fr") return item?.landManager?.french ?? "-";
  return item?.landManager?.english ?? "-";
};

export const makeNumericRange = (input: {
  minimum?: string | number | null;
  maximum?: string | number | null;
}) => {
  const out: NumericRange = {};
  if (input.minimum) {
    out.minimum = isString(input.minimum)
      ? parseInt(input.minimum)
      : input.minimum;
  }
  if (input.maximum) {
    out.maximum = isString(input.maximum)
      ? parseInt(input.maximum)
      : input.maximum;
  }
  return out;
};

export const textFromNumericRange = (numericRange?: Maybe<NumericRange>) => {
  if (numericRange == null) return null;
  if (isNullOrEmpty(numericRange.maximum)) return numericRange.minimum; // TODO: toLocaleString?
  if (isNullOrEmpty(numericRange.minimum)) return numericRange.maximum; // TODO: toLocaleString?
  return `${numericRange.minimum} - ${numericRange.maximum}`;
};
