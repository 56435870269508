import i18n from "i18n";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { convertPathToTag } from "util/changeLog/convertPathToTag";
import { formatTimestamp } from "util/formatTimestamp";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { ChangeLogDetail, Maybe } from "../../../generated/gql-types";
import isNullOrEmpty from "../../../util/isNullOrEmpty";
import NoResults from "../../atoms/NoResults";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";
import SectionCard from "../../atoms/SectionCard";
import { maybeParseRichText } from "./changelogUtil";

export interface ListOfChangesCardProps {
  detailListItems?: Maybe<Array<Maybe<ChangeLogDetail>>>;
  sectionTitle: string;
  hideChangeType?: boolean;
}

const buildBilingualString = (valueJson: string) => {
  let bilingualString;

  const concatUnlessEmpty = (
    firstString?: string | null,
    secondString?: string | null
  ) => {
    return [firstString, secondString]
      .filter((x) => !isNullOrEmpty(x))
      .join(" | ");
  };

  try {
    const valueObj =
      valueJson !== "undefined" && valueJson ? JSON.parse(valueJson) : "";

    if (i18n.language === "fr") {
      bilingualString =
        valueObj !== ""
          ? concatUnlessEmpty(valueObj.NameFr, valueObj.NameEn)
          : "";
    } else {
      bilingualString =
        valueObj !== ""
          ? concatUnlessEmpty(valueObj.NameEn, valueObj.NameFr)
          : "";
    }
  } catch {
    bilingualString = "Error | Error";
  }
  return bilingualString;
};

export const ListOfChangesCard: React.FC<ListOfChangesCardProps> = (props) => {
  const { detailListItems, sectionTitle, hideChangeType } = props;
  const { t } = useTranslation();

  return (
    <SectionCard
      header={<h2>{sectionTitle}</h2>}
      contentAreaProps={{ className: "mrgn-tp-0" }}
      showLine={false}
    >
      {!detailListItems || detailListItems.length === 0 ? (
        <NoResults centered />
      ) : null}

      {detailListItems?.map((item: ChangeLogDetail | null) => {
        return (
          <div
            className="row species-field list-item separator-line"
            key={item?.pathProperty}
          >
            <dl className="dl-inline">
              <div className="row form-group">
                <dt className="col-sm-3 text-right">{t("what_has_changed")}</dt>
                <dd className="col-sm-9">
                  {t(convertPathToTag(item?.pathProperty!))}
                </dd>
              </div>
              {/* <div className="row form-group">
                <dt className="col-sm-3 text-right">{ t('section') }</dt>
                <dd className="col-sm-9">{ t(getChangeSection(item.pathProperty)) }</dd>
              </div> */}
              <div className="row form-group">
                <dt className="col-sm-3 text-right">{t("change_details")}</dt>
                <dd className="col-sm-9 line-height-main">
                  {item?.previousValue ? (
                    <>
                      <div className="bg-danger pr-2 pl-2">
                        <del>
                          <div className="mrgn-bttm-sm">
                            {item.pathProperty === "/endemicStatus" ? (
                              t(item.previousValue)
                            ) : item.pathProperty === "/population" ||
                              item.pathProperty === "/taxonomicGroup" ? (
                              buildBilingualString(item.previousValue!)
                            ) : (
                              <SafeRenderHtml
                                htmlString={htmlRemoveOuterPTag(
                                  maybeParseRichText(item.previousValue!)
                                )}
                              />
                            )}
                          </div>
                        </del>
                      </div>{" "}
                    </>
                  ) : null}

                  <div className="bg-success pr-2 pl-2">
                    {item?.pathProperty === "/endemicStatus" ? (
                      t(item.currentValue ?? "error")
                    ) : item?.pathProperty === "/population" ||
                      item?.pathProperty === "/taxonomicGroup" ? (
                      buildBilingualString(item?.currentValue!)
                    ) : (
                      <SafeRenderHtml
                        htmlString={htmlRemoveOuterPTag(
                          maybeParseRichText(item?.currentValue!)
                        )}
                      />
                    )}
                  </div>
                </dd>
              </div>
              {item?.metadata
                ?.filter((md) => md?.key !== "Tags")
                .filter(
                  (md) =>
                    md?.key?.toLowerCase() !== "changetype" || !hideChangeType
                )
                .map((md) => {
                  let valueComponent = <>{md!.value!}</>;

                  const keyAsLowercase = md?.key?.toLowerCase();
                  if (keyAsLowercase === "changetype") {
                    valueComponent = <>{t(md!.value!)}</>;
                  } else if (keyAsLowercase === "reasonforchange") {
                    valueComponent = <SafeRenderHtml htmlString={md!.value!} />;
                  } else if (keyAsLowercase === "namechangelinktoassessment") {
                    const valueObj =
                      md?.value !== "null" && md?.value
                        ? JSON.parse(md?.value)
                        : "";

                    valueComponent = (
                      <>
                        {valueObj !== ""
                          ? "Id: " +
                            valueObj.AssessmentId +
                            " | Date: " +
                            formatTimestamp(valueObj.Date)
                          : t("NotRelatedToAssessment")}
                      </>
                    );
                  }

                  return (
                    <div className="row form-group" key={md?.key}>
                      <dt className="col-sm-3 text-right">{t(md!.key!)}</dt>
                      <dd className="col-sm-9">{valueComponent}</dd>
                    </div>
                  );
                })}
            </dl>
          </div>
        );
      })}
    </SectionCard>
  );
};

export default ListOfChangesCard;
