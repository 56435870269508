export const getSortByField = (sortBy: string, language: string) => {
  switch (sortBy) {
    case "scientificName":
      return "scientificName.generatedScientificName";
    case "population":
      if (language === "fr") return "population.nameFr";
      return "population.nameEn";
    case "cosewicId":
      return "cosewicId";
    case "listingId":
      return "listingId";
    case "commonName":
    default:
      if (language === "fr") return "commonNameFrench";
      return "commonNameEnglish";
  }
};

export const getSortByOrder = (
  sortBy: string,
  sortOrder: string,
  language: string
) => {
  const sortByField = getSortByField(sortBy, language);
  return `${sortByField}:${sortOrder}`;
};
