export enum CosewicEndemicStatus {
  Unset = "0",
  Yes = "YES",
  No = "NO",
  NotDetermined = "NOT_DETERMINED",
}

export enum CosewicAssessmentStatus {
  Unset = "0",
  NotAtRisk = "NOT_AT_RISK",
  SpecialConcern = "SPECIAL_CONCERN",
  Threatened = "THREATENED",
  Endangered = "ENDANGERED",
  Extinct = "EXTINCT",
  Extirpated = "EXTIRPATED",
  DataDeficient = "DATA_DEFICIENT",
  NonActive = "NON_ACTIVE",
  DeferredRejectedWithdrawn = "DEFERRED_REJECTED_WITHDRAWN",
  NotInitialized = "NOT_INITIALIZED"
}
