export const SEARCH_DEBOUNCE_TIME_MS = 800;
export const NAICS_VERSION = "2022.1";
export const COSEWIC_PUBLISH_BTN_WAIT_BEFORE_REFRESH_TIME_MS = 4000;
export const ASSESSMENT_CREATE_WAIT_BEFORE_REFRESH_TIME_MS = 4000;
export const MAX_NUMBER_OF_PARENTS = 500; // max number of parents (US 243)
export const MAX_NUMBER_OF_CHILDREN = 500; // max number of children (US 242)
export const MAX_LENGTH_OF_NAME = 125; // max length of cosewic and listing name (Bug 32358)
export const MAX_LENGTH_OF_NOTE = 256; // max note characters
export const MAX_LENGTH_OF_ISSUES = 10000; // max sector issues characters (Adjusted for Bug 73217)
export const MAX_LENGTH_OF_RATIONALE = 1000; // max rationale characters

export const MAX_LENGTH_OF_500 = 500; // max character length of 500
export const MAX_LENGTH_OF_1K = 1000; // max character length of 1000
export const MAX_LENGTH_OF_5K = 5000; // max character length of 5,000. Added for the bug #65487
export const MAX_LENGTH_OF_8K = 8000; // max character length of 8,000. Added for the bug #65487
export const MAX_LENGTH_OF_20K = 20000; // max character length of 20,000

// Field names under species profile section are: Need RTE text box (approximately 750 characters) (US 1973)
export const MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME = 7000;

// Field names under Edit region modal popup. RTE text box 1500 characters (US 9871, 52274)
export const MAX_LENGTH_OF_TIF_REGION_FIELD_NAME = 1500;

export const ADMIN_FORM_INPUT_PATTERN =
  "[0-9A-Za-z. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ()-–/\"']*";

export const REASON_FOR_CHANGE_ACCEPTED_COSEWIC_NAME_CHANGE =
  "Accepted COSEWIC name change / Acceptation du changement de nom du COSEPAC";
