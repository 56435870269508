import FormButtons from "components/molecules/FormButtons/FormButtons";
import { BilingualRichText, SpeciesProfile } from "generated/gql-types";
import { PublicProfilePathHelpers } from "pages/publicProfile/PublicProfileRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { filterForDirtyFields } from "util/forms";
import { BiologyCard } from "./BiologyCard";
import { CriticalHabitatCard } from "./CriticalHabitatCard";
import { DescriptionCard } from "./DescriptionCard";
import { DistributionAndPopulationCard } from "./DistributionAndPopulationCard";
import { HabitatCard } from "./HabitatCard";
import { ProtectionCard } from "./ProtectionCard";
import { ThreatsCard } from "./ThreatsCard";
import { setNullForEmptyFields } from "./utils";

export interface PublicProfileFormProps {
  speciesProfile?: SpeciesProfile | null;
  initialValues: Partial<SpeciesProfileFormFields>;
  onSubmit: SubmitHandler<Partial<SpeciesProfileFormFields>>;
}

export const PublicProfileForm: React.FC<PublicProfileFormProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { speciesProfileId } = params as any;

  const form = useForm<SpeciesProfileFormFields>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState } = form;
  const { dirtyFields, isDirty, isSubmitting } = formState;

  const onSubmit: SubmitHandler<Partial<SpeciesProfileFormFields>> = async (
    formData
  ) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, initialValues);

    await props.onSubmit(FilteredFormData);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: PublicProfilePathHelpers.PublicProfile(
        speciesProfileId ?? "error"
      ),
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DescriptionCard initialValues={initialValues} />
        <DistributionAndPopulationCard initialValues={initialValues} />
        <HabitatCard initialValues={initialValues} />
        <CriticalHabitatCard initialValues={initialValues} />
        <BiologyCard initialValues={initialValues} />
        <ThreatsCard initialValues={initialValues} />
        <ProtectionCard initialValues={initialValues} />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
};

export default PublicProfileForm;

///////////////////////////////////////////////////

export interface SpeciesProfileFormFields {
  description: BilingualRichText;
  distributionAndPopulation: BilingualRichText;
  habitat: BilingualRichText;
  criticalHabitat: BilingualRichText;
  biology: BilingualRichText;
  threats: BilingualRichText;
  protection: BilingualRichText;
}
