import Alert, { AlertTypes } from "components/atoms/Alert";
import SectionCard from "components/atoms/SectionCard";
import { CosewicWsPendingRegistryUpdate, Maybe } from "generated/gql-types";

import React from "react";
import { useTranslation } from "react-i18next";
import { convertPathToTag } from "util/changeLog/convertPathToTag";

interface UnpublishedChangesWarningProps {
  cosewicWsPendingRegistryUpdates:
    | Maybe<Array<Maybe<CosewicWsPendingRegistryUpdate>>>
    | undefined;
  isPublished: boolean;
}

const UnpublishedChangesWarning: React.FC<UnpublishedChangesWarningProps> = (
  props
) => {
  const { cosewicWsPendingRegistryUpdates, isPublished } = props;
  const { t } = useTranslation();

  const showWarning =
    isPublished &&
    cosewicWsPendingRegistryUpdates != null &&
    cosewicWsPendingRegistryUpdates.length > 0;

  return showWarning ? (
    <Alert
      type={AlertTypes.WARNING}
      content={t("ws_has_unpublished_changes_warning_title")}
    >
      <SectionCard
        collapsible
        open={false}
        header={<h3>{t("view_details")}</h3>}
        showLine={false}
        contentAreaProps={{ className: "font-size-16" }}
        className={"mrgn-bttm-0"}
        classNameSummary={"py-2"}
      >
        {cosewicWsPendingRegistryUpdates.map((item, index) => {
          return (
            <div key={index} className="mrgn-tp-md">
              {t("change_has_not_been_published", {
                change: t(convertPathToTag(item?.pathProperty ?? "")),
              })}
            </div>
          );
        })}
      </SectionCard>
    </Alert>
  ) : null;
};

export default UnpublishedChangesWarning;
