import ExportButton from "components/atoms/ExportButton";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import AddContactButtonAndModal from "components/organisms/contacts/AddContactButtonAndModal/AddContactButtonAndModal";
import { ContactType } from "components/organisms/contacts/types";
import EditResponseStatementModalButton from "components/organisms/listing/EditResponseStatementModal/EditResponseStatementBtn";
import { mapDomainModelToForm } from "components/organisms/listing/ResponseStatementForm/mappers";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import { getCosewicCommonNameWithPopulation } from "pages/responseStatement/utils";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import { fetchExportPdf } from "util/responseStatement/fetchExportPdf";
import {
  RenderWhenAuthorized,
  ROLE_ACTIONS,
} from "../../../../features/auth/components";
import { useGlobalAlertContext } from "../../../../features/globalAlert";
import {
  Contact,
  Gender,
  Maybe,
  Organization,
  ResponseStatement,
  ResponseStatementStage,
  ResponseStatementState,
} from "../../../../generated/gql-types";
import { bilingualRichTextForLanguage } from "../../../../mappers";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import MissingData from "../../../atoms/MissingData";
import SafeRenderHtml from "../../../atoms/SafeRenderHtml";
import SectionCard from "../../../atoms/SectionCard";
import HtmlButtonAndModal from "../HtmlButtonAndModal/HtmlButtonAndModal";

export interface ResponseStatementCardProps {
  responseStatement?: Maybe<ResponseStatement>;
  onSaveContactOrOrg: (
    newValue: Contact | Organization | undefined | null,
    contactType: ContactType
  ) => void;
  onRemoveContactOrOrg: () => void;
}

export const ResponseStatementCard: React.FC<ResponseStatementCardProps> = (
  props
) => {
  const { responseStatement } = props;
  const alertContext = useGlobalAlertContext();
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const { t, i18n } = useTranslation();

  const cosewicCommonNameWithPopulation = getCosewicCommonNameWithPopulation(
    responseStatement?.referenceData
  );

  const cosewicScientificName =
    responseStatement?.referenceData?.cosewicRef?.scientificName?.text;

  const ministerResponses =
    i18n.language === "fr"
      ? responseStatement?.scenario?.message?.french?.text
      : responseStatement?.scenario?.message?.english?.text;

  const occurrence = responseStatement?.referenceData?.listingRef?.ranges
    ?.map((range) => (i18n.language === "fr" ? range?.nameFr : range?.nameEn))
    .join("<br/>");

  const competentMinisters =
    responseStatement?.referenceData?.listingRef?.competentMinisters
      ?.map((x) =>
        i18n.language === "fr" ? x?.name?.french : x?.name?.english
      )
      .join("<br/>");

  const applicableFederalLegislations =
    responseStatement?.referenceData?.listingRef?.federalLegislations
      ?.map((fl) =>
        i18n.language === "fr"
          ? `<div>${fl?.message?.french?.text!}</div>`
          : `<div>${fl?.message?.english?.text!}</div>`
      )
      .join("");

  const consultedProvincesAndTerritories =
    responseStatement?.consultedProvincesAndTerritories
      ?.map((x) => (i18n.language === "fr" ? x?.nameFr : x?.nameEn))
      .join("<br/>");

  const isDraftStage = React.useMemo(() => {
    if (responseStatement?.stage === ResponseStatementStage.Draft) {
      return true;
    }
    return false;
  }, [responseStatement?.stage]);

  const exportPdf = async () => {
    if (responseStatement?.id) {
      try {
        setDownloadingPdf(true);
        await fetchExportPdf(responseStatement?.id, i18n.language);
      } catch (e) {
        console.error("Error downloading response statement pdf");
        console.error(e);
      } finally {
        setDownloadingPdf(false);
      }
    }
  };

  const contactRef = responseStatement?.referenceData?.contactRef;
  const organizationRef = responseStatement?.referenceData?.organizationRef;
  const hasContact = contactRef != null || organizationRef != null;

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("response_statement_information")}</h2>
          <div className="flex gap-sm">
            <ExportButton
              text="PDF"
              onExport={exportPdf}
              loading={downloadingPdf}
              showIcon={false}
            />
            <HtmlButtonAndModal
              id={responseStatement?.id ?? "MISSING_RESPONSE_STATEMENT_ID"}
            />
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.responseStatement.update}
            >
              {isDraftStage ? (
                <EditResponseStatementModalButton
                  buttonLabel={t("edit")}
                  responseStatementId={responseStatement?.id ?? ""}
                  refetchQueries={["ResponseStatement"]}
                  defaultValues={mapDomainModelToForm(responseStatement)}
                  onCompleted={(data) => {
                    console.log("onCompleted!");
                    // TODO: Add onSuccess message
                    // data?.updateAdminResponseStatement &&
                    //           adminAlertMsg.onEditSuccess(
                    //             data?.updateAdminResponseStatement?.name?.english,
                    //             data?.updateAdminResponseStatement?.name?.french
                    //           );
                    // data?.renameGenus &&
                    //   adminAlertMsg.onEditSuccess(
                    //     data?.renameGenus?.name,
                    //     data?.renameGenus?.name
                    //   );
                  }}
                  // TODO: Add onError message
                  // onError={adminAlertMsg.onCreateError}
                  onError={() => console.log("onError!")}
                />
              ) : null}
            </RenderWhenAuthorized>
          </div>
        </div>
      }
    >
      <div className="species-field">
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("due_date")}</dt>
              <dd>
                {responseStatement?.dueDate == null ? (
                  <MissingData />
                ) : (
                  formatTimestamp(responseStatement?.dueDate)
                )}
              </dd>
            </div>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("date_published")}</dt>
              <dd>
                {responseStatement?.publishedDate == null ? (
                  <MissingData />
                ) : (
                  formatTimestamp(responseStatement?.publishedDate)
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("cosewic_common_name_with_population")}
              </dt>
              <dd>
                {htmlIsNullOrEmpty(cosewicCommonNameWithPopulation) ? (
                  <MissingData />
                ) : (
                  <Link
                    to={CosewicPathHelpers.CosewicProfile(
                      responseStatement?.referenceData?.cosewicRef
                        ?.cosewicWsRefId ?? "error"
                    )}
                    className="hover-underline"
                    data-testid="link-to-cosewic"
                  >
                    <SafeRenderHtml
                      htmlString={cosewicCommonNameWithPopulation}
                    />
                  </Link>
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("cosewic_scientific_name")}</dt>
              <dd>
                {htmlIsNullOrEmpty(cosewicScientificName) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={cosewicScientificName} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("cosewic_assessment_status")}</dt>
              <dd>
                {responseStatement?.referenceData?.assessmentRef
                  ?.assessmentStatus == null ? (
                  <MissingData />
                ) : (
                  t(
                    responseStatement?.referenceData?.assessmentRef
                      ?.assessmentStatus
                  )
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("minister_response")},{" "}
                {i18n.language === "en" ? "English" : "Français"}
                <TooltipAlert
                  isVisible={
                    responseStatement?.referenceData?.listingRef?.gender ==
                      null ||
                    responseStatement?.referenceData?.listingRef?.gender ===
                      Gender.NotInitialized
                  }
                >
                  {t("gender_required_to_make_rs_final_tooltip")}
                </TooltipAlert>
                <TooltipAlert
                  isVisible={
                    responseStatement?.scenario?.includeConsultationPeriod ===
                      true &&
                    responseStatement.referenceData?.listingRef
                      ?.consultationPath == null
                  }
                >
                  {t("consultation_path_required_to_make_rs_final_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {htmlIsNullOrEmpty(ministerResponses) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={ministerResponses} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("reason_for_status_designation")}, {t("english")}
                <TooltipAlert
                  isVisible={
                    responseStatement?.referenceData?.assessmentRef
                      ?.designationReason?.english == null
                  }
                >
                  {t("required_to_make_rs_final_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {htmlIsNullOrEmpty(
                  responseStatement?.referenceData?.assessmentRef
                    ?.designationReason?.english?.text
                ) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={
                      responseStatement?.referenceData?.assessmentRef
                        ?.designationReason?.english?.text
                    }
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("reason_for_status_designation")}, {t("french")}
                <TooltipAlert
                  isVisible={
                    responseStatement?.referenceData?.assessmentRef
                      ?.designationReason?.french == null
                  }
                >
                  {t("required_to_make_rs_final_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {htmlIsNullOrEmpty(
                  responseStatement?.referenceData?.assessmentRef
                    ?.designationReason?.french?.text
                ) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={
                      responseStatement?.referenceData?.assessmentRef
                        ?.designationReason?.french?.text
                    }
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("occurrence")}</dt>
              <dd>
                {htmlIsNullOrEmpty(occurrence) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={occurrence} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("competent_ministers")}
                <TooltipAlert
                  isVisible={
                    responseStatement?.referenceData?.listingRef
                      ?.competentMinisters?.length === 0
                  }
                >
                  {t("required_to_make_rs_final_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {htmlIsNullOrEmpty(competentMinisters) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={competentMinisters} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("applicable_federal_legislation")}
              </dt>
              <dd>
                {htmlIsNullOrEmpty(applicableFederalLegislations) ? (
                  <MissingData message={t("not_applicable")} />
                ) : (
                  <SafeRenderHtml htmlString={applicableFederalLegislations} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("provinces_and_territories_consulted")}
              </dt>
              <dd>
                {htmlIsNullOrEmpty(consultedProvincesAndTerritories) ? (
                  <MissingData message={t("not_applicable")} />
                ) : (
                  <SafeRenderHtml
                    htmlString={consultedProvincesAndTerritories}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("conservation_activities_underway")}, {t("english")}
              </dt>
              <dd>
                {htmlIsNullOrEmpty(
                  responseStatement?.referenceData?.listingRef
                    ?.conservationActivities?.english?.text
                ) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={
                      responseStatement?.referenceData?.listingRef
                        ?.conservationActivities?.english?.text
                    }
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("conservation_activities_underway")}, {t("french")}
              </dt>
              <dd>
                {htmlIsNullOrEmpty(
                  responseStatement?.referenceData?.listingRef
                    ?.conservationActivities?.french?.text
                ) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={
                      responseStatement?.referenceData?.listingRef
                        ?.conservationActivities?.french?.text
                    }
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        <div className="row flex justify-start">
          <dl className="width-100">
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {t("contact")}
                <TooltipAlert isVisible={!hasContact}>
                  {t("required_to_make_rs_final_tooltip")}
                </TooltipAlert>
              </dt>
              <dd className="width-100">
                {hasContact ? (
                  <div className="flex width-100 align-start justify-between">
                    <SafeRenderHtml
                      htmlString={bilingualRichTextForLanguage(
                        i18n.language,
                        responseStatement?.contactOrOrganizationFormatted
                      )}
                    />
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.responseStatement.update}
                    >
                      {responseStatement?.state ===
                      ResponseStatementState.NotPublished ? (
                        <button
                          type="button"
                          className="btn btn-link card-header-button-link mrgn-0 py-0 px-2"
                          onClick={props.onRemoveContactOrOrg}
                          data-testid="button-remove-contact"
                        >
                          <i className="far fa-times-circle mrgn-rght-sm"></i>
                          {t("remove_contact")}
                        </button>
                      ) : null}
                    </RenderWhenAuthorized>
                  </div>
                ) : (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.responseStatement.update}
                  >
                    {isDraftStage && !hasContact && (
                      <AddContactButtonAndModal
                        id={"rs_add_contact"}
                        onSave={props.onSaveContactOrOrg}
                      />
                    )}
                  </RenderWhenAuthorized>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default ResponseStatementCard;
