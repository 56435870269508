import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { ListingProcessRegulatoryProcess } from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

export interface RegulatoryProcessSectionProps {
  regulatoryProcess?: ListingProcessRegulatoryProcess | null;
}

export const RegulatoryProcessSection: React.FC<
  RegulatoryProcessSectionProps
> = (props) => {
  const { regulatoryProcess } = props;
  const { t } = useTranslation();

  const regulatoryBundle = regulatoryProcess?.regulatoryBundle;
  const rationale = regulatoryProcess?.rationale;
  const gicReceiptDate = regulatoryProcess?.gicReceiptDate;
  const anticipatedDecisionDate = regulatoryProcess?.anticipatedDecisionDate;
  const gicDecisionDate = regulatoryProcess?.gicDecisionDate;
  const cgiListingProposal =
    i18n.language === "fr"
      ? regulatoryProcess?.cgiListingProposal?.name?.french
      : regulatoryProcess?.cgiListingProposal?.name?.english;
  const cosewicToReassess =
    i18n.language === "fr"
      ? regulatoryProcess?.cosewicToReassess?.name?.french
      : regulatoryProcess?.cosewicToReassess?.name?.english;
  const gicDecision =
    i18n.language === "fr"
      ? regulatoryProcess?.gicDecision?.name?.french
      : regulatoryProcess?.gicDecision?.name?.english;

  return (
    <>
      <h4 className="mrgn-bttm-md">{t("regulatory_process")}</h4>
      <div className="species-field">
        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("regulatory_bundle")}</dt>
              <dd>{regulatoryBundle ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("gic_receipt_date")}</dt>
              <dd>{formatTimestamp(gicReceiptDate) ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("anticipated_decision_date")}</dt>
              <dd>
                {formatTimestamp(anticipatedDecisionDate) ?? <MissingData />}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("gic_decision_date")}</dt>
              <dd>{formatTimestamp(gicDecisionDate) ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("cgi_listing_proposal")}</dt>
              <dd>{cgiListingProposal ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("cosewic_to_reassess")}</dt>
              <dd>{cosewicToReassess ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("gic_decision")}</dt>
              <dd>{gicDecision ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("rationale_english")}</dt>
              <dd>
                {htmlIsNullOrEmpty(rationale?.english?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={rationale?.english?.text!} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("rationale_french")}</dt>
              <dd>
                {htmlIsNullOrEmpty(rationale?.french?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={rationale?.french?.text!} />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default RegulatoryProcessSection;
