import {
  BilingualNoteInput,
  CreateListingWsProcessInput,
  ListingProcessTypePost,
} from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { ListingProcessCreateFormFields } from "./ListingProcessCreateForm";

export function mapFormToCreateInput(
  form: Partial<ListingProcessCreateFormFields>
) {
  const out: Partial<CreateListingWsProcessInput> = {};

  if (form.listingProcessType)
    out.listingProcessType =
      form.listingProcessType as unknown as ListingProcessTypePost;

  if (form.ministerOpinionDate)
    out.ministerOpinionDate = form.ministerOpinionDate;

  if (form.assessmentId) out.assessmentId = form.assessmentId;

  const processNote: Partial<BilingualNoteInput> = {};

  if (form.processNote?.english) processNote.english = form.processNote.english;
  if (form.processNote?.french) processNote.french = form.processNote.french;
  if (Object.keys(processNote).length > 0) out.processNote = processNote;

  return out;
}

export function setNullForEmptyProcessNoteFields(
  createFormData: Partial<ListingProcessCreateFormFields>,
  dirtyFields: FieldNamesMarkedBoolean<any>
) {
  if (
    dirtyFields?.processNote?.english != null &&
    htmlIsNullOrEmpty(createFormData.processNote?.english?.text)
  ) {
    set(createFormData, "processNote.english", null);
  }
  if (
    dirtyFields?.processNote?.french != null &&
    htmlIsNullOrEmpty(createFormData.processNote?.french?.text)
  ) {
    set(createFormData, "processNote.french", null);
  }
}
