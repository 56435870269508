import { useTranslation } from "react-i18next";
import NoResults from "components/atoms/NoResults";
import useBasicSearch from "pages/search/hooks/useBasicSearch";
import { convertPathToTag } from "util/changeLog/convertPathToTag";

export interface BasicSearchPathPropertyCheckboxListProps {
  facetName: string;
  header: string;
  translateItems?: boolean;
  open?: boolean; // whether or not the details section is expanded or not
  allFacets: any;
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const BasicSearchPathPropertyCheckboxList: React.FC<
  BasicSearchPathPropertyCheckboxListProps
> = (props) => {
  const { t } = useTranslation();

  const items: any[] =
    props.allFacets == null ? [] : props.allFacets[props.facetName] ?? [];

  const buildLabelForCheckbox = (filterName: string) => {
    let value = filterName;

    if (props.translateItems) {
      return t(convertPathToTag(value));
    }

    return convertPathToTag(value);
  };

  const selectedCountInSection = props.basicSearch.getCountForSection(
    props.facetName
  );

  return (
    <>
      <details className="species-details" open={props.open}>
        <summary>
          {props.header}{" "}
          {selectedCountInSection > 0 ? (
            <span className="badge">
              {selectedCountInSection}
              <span className="wb-inv"> options selected</span>
            </span>
          ) : null}
        </summary>

        <div className="flex-col">
          {items.length === 0 ? (
            <NoResults />
          ) : (
            items
              .sort((a, b) => t(a.value).localeCompare(t(b.value)))
              .map((filter) => {
                if (filter.count === 0) return null;
                if (filter.value === "/dateSentToMinister") {
                  return null;
                }
                const name = `${props.facetName}/${filter.value}`;
                const label = buildLabelForCheckbox(filter.value);

                return (
                  <div className="checkbox" key={name}>
                    <div className="flex justify-between">
                      <label>
                        <input
                          type="checkbox"
                          name={name}
                          id={name}
                          data-testid={name}
                          checked={props.basicSearch.isCheckboxSelected(
                            props.facetName,
                            filter.value
                          )}
                          onChange={() => {
                            props.basicSearch.dispatch({
                              type: "toggle_checkbox_filter",
                              filter: {
                                value: filter.value,
                                label: `${props.header} = ${label}`,
                                facet: props.facetName,
                              },
                            });
                          }}
                        />{" "}
                        {label}
                      </label>
                      <span className="badge">{filter.count}</span>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </details>
    </>
  );
};

export default BasicSearchPathPropertyCheckboxList;
