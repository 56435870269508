import UploadCloud from "assets/svg/upload-cloud.svg";
import { DocumentAttachment, Photo } from "generated/gql-types";
import { zip } from "lodash";
import prettyBytes from "pretty-bytes";
import * as React from "react";
import { useRef, useState } from "react";
import { FileError, useDropzone } from "react-dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import { getI18n, useTranslation } from "react-i18next";
import { getApiImageManagementServiceUrl } from "../../../../azure/environment";
import { getUserAccessToken } from "../../../../features/auth/CustomMsalProvider";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { useGlobalAlertContext } from "features/globalAlert";

interface AddPhotosFormProps {
  defaultValues?: PhotosFields;
  photoLicenseId: string;
  onClose: () => void;
}

enum UploadStatus {
  Success,
  Error,
}

type UploadResponse = {
  status: UploadStatus;
  message?: string;
};

const AddPhotosForm: React.FC<AddPhotosFormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const form = useForm<PhotosFields>({
    defaultValues: props.defaultValues,
  });
  const alertContext = useGlobalAlertContext();

  const [saveButtonText, setSaveButtonText] = useState<string>(t("save"));
  const [uploadStatuses, setUploadStatuses] = useState<
    Record<string, UploadResponse>
  >({});
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);

  const { register, handleSubmit, formState, getValues, setValue } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const setPhotoStatusComplete = (
    fileName: string,
    uploadResponse: UploadResponse
  ) => {
    setUploadStatuses((x) => ({ ...x, [fileName]: uploadResponse }));
  };

  const removeFileStatus = (fileName: string) => {
    setUploadStatuses((x) => {
      const temp = { ...x };
      delete temp[fileName];
      return temp;
    });
  };

  const onSubmit: SubmitHandler<PhotosFields> = async (formData) => {
    const token = await getUserAccessToken();
    const baseUrl = getApiImageManagementServiceUrl("7096") + `image`;
    const all = zip(photos, formData.photos) as any;

    const promises = all.map(([photo]: any) => {
      if (!photo) return Promise.resolve(false);
      if (uploadStatuses[photo.name]?.status === UploadStatus.Success)
        return Promise.resolve(true);

      const formData = new FormData();
      formData.append("file", photo);

      const url = new URL(baseUrl);
      url.searchParams.append("aggregateid", props.photoLicenseId);
      url.searchParams.append("aggregatename", "photolicense");
      url.searchParams.append("category", "photo");
      url.searchParams.append("size", "thumbnail");
      url.searchParams.append("size", "medium");
      url.searchParams.append("size", "large");

      return fetch(url.toString(), {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (x) => {
          if (x.status >= 200 && x.status <= 299) {
            setPhotoStatusComplete(photo.name, {
              status: UploadStatus.Success,
            });
            alertContext.showSuccess({
              title: t("photo_added"),
              timeOut: 5000,
            });
            return true;
          } else if (x.status === 409) {
            const errorMessage = t("conflict_photo_already_uploaded");
            throw new Error(errorMessage);
          } else {
            const bodyRes = await x.text();
            const bodyResObj = JSON.parse(bodyRes);
            const errorMessage =
              i18n.language === "fr"
                ? bodyResObj?.messages[0]?.frenchMessage
                : bodyResObj?.messages[0]?.englishMessage;
            throw new Error(errorMessage);
          }
        })
        .catch((e) => {
          setPhotoStatusComplete(photo.name, {
            status: UploadStatus.Error,
            message: e.message,
          });
          return false;
        });
    });

    const statuses = await Promise.allSettled(promises);

    if (statuses.every((x) => x.status === "fulfilled" && x.value === true)) {
      setUploadCompleted(true);
    } else {
      setSaveButtonText(t("retry"));
    }
  };

  React.useEffect(() => {
    if (
      uploadCompleted === true &&
      closeBtnRef != null &&
      closeBtnRef.current != null &&
      isSubmitting === false
    ) {
      closeBtnRef.current.focus();
    }
  }, [uploadCompleted, isSubmitting]);

  const [photos, setPhotos] = React.useState<File[]>([]);

  const onDrop = React.useCallback((acceptedPhotos, rejectedPhotos) => {
    if (acceptedPhotos?.length > 0) {
      setPhotos((previousPhotos) => [...previousPhotos, ...acceptedPhotos]);
      setUploadCompleted(false);
    }
    if (rejectedPhotos.length > 0) {
      console.warn("Rejected files:", rejectedPhotos);
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    validator: (photo) => {
      const matchingFileName = findMatchingFileNameIn(photos, photo);

      // Additional validation for image types
      const allowedExtensions = /\.(jpe?g|png|gif)$/i;
      const fileName = photo.name.toLowerCase();
      const isValidImage = allowedExtensions.test(fileName);

      if (!isValidImage) {
        return {
          code: "INVALID_IMAGE_TYPE",
          message: t("please_upload_jpeg_gif_png"), 
        };
      }

      return matchingFileName;
    },
  });

  const removePhoto = (name: string, indexRemovedFile: number) => {
    removeFileStatus(name);
    setPhotos((photos) => photos.filter((photo) => photo.name !== name));
    if (
      photos.every(
        (photo) =>
          uploadStatuses[photo.name]?.status === UploadStatus.Success ||
          photo.name === name
      )
    ) {
      setUploadCompleted(true);
    }
    // Form reset will re-sync the state of the Files (useState) and the Hook form.
    form.reset();
    // After form reset, restore language back to the remaining files.
  };

  const renderUploadStatusIndicator = (uploadResponse: UploadResponse) => {
    if (uploadResponse == null) return null;
    switch (uploadResponse.status) {
      case UploadStatus.Success:
        return <i className="fa fa-check text-success mrgn-lft-sm" />;
      case UploadStatus.Error:
        return (
          <>
            <span className="label label-danger">{uploadResponse.message}</span>
          </>
        );
      default:
        return null;
    }
  };

  const noPhotoAdded = (photos: File[]) => {
    if (photos.length === 0) return true;
    else return false;
  };

  return (
    <>
      {/* -------------- Drag and drop files -------------- */}
      <section className="mrgn-bttm-lg">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} data-testid="dropzone-add-file" />
          <p>
            <img className="mrgn-rght-lg" src={UploadCloud} alt="" />
            {t("drag_and_drop_some_files_here")}
          </p>
        </div>
      </section>

      {/* -------------- Form -------------- */}
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {photos.map((photo, indexPhoto) => (
          <div key={photo.name} className="well-normal">
            <div className="flex justify-between align-start mrgn-bttm-10">
              <span className="font-size-16">
                <b>{photo.name}</b> (
                {prettyBytes(photo.size, { locale: i18n.language })}){" "}
                {renderUploadStatusIndicator(uploadStatuses[photo.name])}
              </span>
              <button
                type="button"
                title={t("remove_file")}
                className="btn btn-link btn-xs p-0 hover-grey"
                data-testid={`button-remove-photo-${photo.name}`}
                onClick={() => removePhoto(photo.name, indexPhoto)}
                hidden={
                  isSubmitting ||
                  uploadStatuses[photo.name]?.status === UploadStatus.Success
                }
              >
                <i className="fas fa-times font-size-14 color-danger"></i>
              </button>
            </div>
          </div>
        ))}

        {/* -------------- List of rejected photos -------------- */}
        {/* ------------- (usually due to duplicate file name) -------------- */}
        {fileRejections != null && fileRejections.length > 0 ? (
          <div className="width-fit-content">
            {fileRejections.map((x, index) => (
              <div key={index} className="label label-danger mrgn-bttm-md">
                {x.file.name} {t("rejected")}{" "}
                {x.errors?.length > 0
                  ? `(${x.errors.map((e) => e.message).join(", ")})`
                  : null}
              </div>
            ))}
          </div>
        ) : null}

        {!uploadCompleted ? (
          <FormButtons
            disabled={noPhotoAdded(photos)}
            isSubmitting={isSubmitting}
            onCancel={props.onClose}
            errors={errors}
            submitBtnText={saveButtonText}
            submitBtnIcon="fas fa-cloud-upload-alt"
          />
        ) : (
          <button
            ref={closeBtnRef}
            type="button"
            onClick={props.onClose}
            className="btn btn-primary"
            data-testid="form-button-close"
            disabled={isSubmitting}
          >
            {t("close")}
          </button>
        )}
      </form>
    </>
  );
};

export default AddPhotosForm;

/////////////////////////////////////////////

export interface PhotosFields {
  photos: Photo[];
}

function findMatchingFileNameIn<T extends File>(
  allFiles: T[],
  file: T
): FileError | FileError[] | null {
  if (allFiles.find((x) => x.name === file.name)) {
    return {
      code: "duplicate-file-name",
      message: getI18n().t("duplicate_filename_error"),
    };
  }

  return null;
}
