import * as React from "react";

type INAICSVersionContext = [
  string,
  React.Dispatch<React.SetStateAction<string>>
];

const NAICSVersionContext = React.createContext<
  INAICSVersionContext | undefined
>(undefined);

/**
 * NAICSVersionContext;
 * For showing the version number in the card title of the listing sector form (or elsewhere).
 * The form itself doesn't talk to the backend so it can't know the version number,
 * but the dropdown fields inside the form query the backend for their options and
 * receive the version number with each response. These field components can call setNaicsVersion
 * when they get a response, and the form component can read the current version number
 * from the context without passing anything for this feature down to the fields through props.
 * The intent is to keep the field components' props as focused as possible.
 */

const NAICSVersionProvider: React.FC = ({ children }) => {
  const [naicsVersion, setNaicsVersion] = React.useState("");

  return (
    <NAICSVersionContext.Provider value={[naicsVersion, setNaicsVersion]}>
      {children}
    </NAICSVersionContext.Provider>
  );
};

/*
 * Since a version number display may not always be required when using NAICS lookup components (e.g. in stories),
 * we'd like to avoid them having a hard dependency on a NAICSVersionProvider being rendered
 * above them in the tree. To achieve this, instead of throwing an error when the provider is
 * missing like most of our contexts do, this one will return an undefined value and a no-op function.
 *
 * It's defined a module constant to avoid re-creating it on every call.
 */
const FALLBACK_NO_OP_CONTEXT = [undefined, () => {}];
const useNAICSVersionContext = () => {
  const context = React.useContext(NAICSVersionContext);

  if (context === undefined) {
    return FALLBACK_NO_OP_CONTEXT as [undefined, () => {}];
  }

  return context!;
};

export { NAICSVersionProvider, useNAICSVersionContext };
