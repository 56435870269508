import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import { AuthorizationSarDmtsRole } from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import RoleAutocomplete from "../RoleAutocomplete/RoleAutocomplete";
import useRoleAutocomplete from "../RoleAutocomplete/useRoleAutocomplete";

export interface RoleMultiSelectListProps {
  value: Array<AuthorizationSarDmtsRole>;
  onChange: (listItems: Array<AuthorizationSarDmtsRole>) => void;
  required?: boolean;
  currentUserProgram: string;
}

export const RoleMultiSelectList: React.FC<RoleMultiSelectListProps> = (
  props
) => {
  const { value = [], onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    AuthorizationSarDmtsRole | undefined
  >(undefined);
  const roleAutocompleteInstance = useRoleAutocomplete(
    props.currentUserProgram
  );

  const onRemoveFromList = (id: string) => {
    const newList = value.filter((x) => x.appRoleId !== id);
    onChange(newList);
  };

  const onSelectionChange = (
    newRole?: AuthorizationSarDmtsRole | undefined
  ) => {
    setSelectedSearchItem(newRole);
    roleAutocompleteInstance.clear();
  };

  const isFound = value.some(
    (x) => x.appRoleId === selectedSearchItem?.appRoleId
  );

  React.useEffect(() => {
    if (value && selectedSearchItem && !isFound) {
      onChange([...value, selectedSearchItem]);
    }
  }, [isFound, onChange, selectedSearchItem, value]);

  React.useEffect(() => {
    if (selectedSearchItem) {
      setSelectedSearchItem(undefined);
    }
  }, [selectedSearchItem]);

  return (
    <>
      <div className="flex gap-sm align-end">
        <RoleAutocomplete
          onSelectionChange={onSelectionChange}
          label={t("roles")}
          required={props.required}
          useRoleAutocompleteInstance={roleAutocompleteInstance}
        />
      </div>
      <ul className="multi-select-list">
        {value.map((x) => {
          const roleId = x.appRoleId as string;
          return (
            <MultiSelectListItem
              id={roleId}
              name={x.name ?? "error"}
              onRemove={() => onRemoveFromList(roleId)}
              key={roleId}
            />
          );
        })}
      </ul>
    </>
  );
};

export interface RoleMultiSelectListWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<RoleMultiSelectListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
  currentUserProgram: string;
}

export const RoleMultiSelectListWithController = <
  TFieldValues extends FieldValues
>(
  props: RoleMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      rules={{ required: props.required }}
      render={({ field: { value, onChange } }) => (
        <RoleMultiSelectList
          value={value as Array<AuthorizationSarDmtsRole>}
          onChange={onChange}
          required={props.required}
          currentUserProgram={props.currentUserProgram}
        />
      )}
    />
  );
};

export default RoleMultiSelectList;
