import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import { UpdateResponseStatementDocument } from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGlobalAlertContext } from "../../../../features/globalAlert";
import ResponseStatementForm, {
  ResponseStatementFormFields,
} from "../ResponseStatementForm/ResponseStatementForm";
import { mapFormToDomainModel } from "../ResponseStatementForm/mappers";

export interface EditResponseStatementModalProps {
  modalState: OverlayTriggerState;
  responseStatementId: string;
  defaultValues: Partial<ResponseStatementFormFields>;
  refetchQueries: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditResponseStatementModal: React.FC<EditResponseStatementModalProps> = (
  props
) => {
  const { modalState, responseStatementId, defaultValues } = props;
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();

  const [updateResponseStatement, { loading, error, reset }] = useMutation(
    UpdateResponseStatementDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
    }
  );
  const onSubmit: SubmitHandler<Partial<ResponseStatementFormFields>> = async (
    formData
  ) => {
    try {
      const model = mapFormToDomainModel(
        formData,
        defaultValues.scenario?.includeConsultationPeriod
      );

      const res = await updateResponseStatement({
        variables: {
          updateResponseStatementId: responseStatementId,
          input: {
            dueDate: model.dueDate,
            scenario: model.scenario,
            consultedProvincesAndTerritories:
              model.consultedProvincesAndTerritories,
          },
        },
      });

      if (res.errors == null) {
        alertContext.showSuccess({
          title: t("response_statement_success"),
          message: t("you_can_preview_your_changes"),
        });

        console.log(
          "Saved response statement",
          res.data?.updateResponseStatement?.id
        );
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving response statement");
      alertContext.showError({
        title: t("error"),
        message: "Error saving response statement",
      });
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_response_statement")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
      >
        {error && (
          <Alert
            type={AlertTypes.WARNING}
            content={
              i18n.language === "fr"
                ? error.graphQLErrors[0].extensions?.response?.body
                    ?.frenchMessage
                : error.graphQLErrors[0].extensions?.response?.body
                    ?.englishMessage
            }
          />
        )}
        {loading ? (
          <LoadingIndicator centered />
        ) : (
          <ResponseStatementForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        )}
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditResponseStatementModal;

export const useResponseStatementModal = useOverlayTriggerState;
