import { useMutation, useQuery } from "@apollo/client";
import AddPhaseIcon from "assets/svg/add-phase-icon.svg";
import GraphqlError from "components/GraphqlError";
import Alert, { AlertTypes } from "components/atoms/Alert";
import CreatedUpdatedInfo from "components/atoms/CreatedUpdatedInfo";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import AddPhaseBtn from "components/molecules/admin/bptTemplates/Phase/CreatePhaseModal/AddPhaseBtn";
import PhaseDetails from "components/molecules/admin/bptTemplates/Phase/PhaseDetails/PhaseDetails";
import PhaseSectionCardHeader from "components/molecules/admin/bptTemplates/Phase/PhaseInformationCard/PhaseSectionCardHeader";
import MakeActivateButtonAndModal from "components/molecules/admin/bptTemplates/Template/MakeActivateButtonAndModal";
import ReturnToButton from "components/molecules/admin/bptTemplates/utility/ReturnToButton";
import TotalCalculatedDuration from "components/molecules/admin/bptTemplates/utility/TotalCalculatedDuration";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateDocument,
  BptTemplatePhaseListDocument,
  BptTemplateStage,
  DeleteBptTemplatePhaseDocument,
  Scalars,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { TemplateInformationCard } from "../../../../components/molecules/admin/bptTemplates/Template/TemplateInformationCard/TemplateInformationCard";
import { AdminPathHelpers } from "../../AdminRouter";

const BptTemplateIndexPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { templateId } = params as any;
  const [collapse, setCollapse] = React.useState(false);
  const adminAlertMsg = useAdminAlertMsg(t("phase"));
  const alertContext = useGlobalAlertContext();
  const history = useHistory();

  // Call the back-end graphQL services
  const { loading, error, data } = useQuery(BptTemplateDocument, {
    variables: {
      templateId: templateId,
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingPhaseList,
    error: errorPhaseList,
    data: dataPhaseList,
  } = useQuery(BptTemplatePhaseListDocument, {
    variables: {
      params: {
        templateId: templateId,
        pageNumber: 1,
        pageSize: 200,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
  });

  const [deletePhase, { loading: deletingPhase, error: deletingPhaseError }] =
    useMutation(DeleteBptTemplatePhaseDocument, {
      refetchQueries: ["BptTemplatePhaseList"],
    });

  // Active the template
  const onActivate = async () => {
    try {
      // TODO: Activate link
    } catch (e) {
      console.error(e);
    }
  };

  // Delete template draft
  const onDeleteTemplate = async (templateId: Scalars["ID"]) => {
    try {
      // TODO: Delete link
    } catch (e) {
      console.error(e);
    }
  };

  // Delete phase
  const onDeletePhase = async (
    templateId: Scalars["ID"],
    phaseId: Scalars["ID"]
  ) => {
    try {
      const res = await deletePhase({
        variables: {
          templateId: templateId,
          phaseId: phaseId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_delete_phase"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Controll the expandAll/collapseAll functions
  const onExpandAll = async () => {
    setCollapse(true);
  };

  const onCollapseAll = async () => {
    $("details").removeAttr("open");
    setCollapse(false);
  };

  // Return to manage templates page
  const onReturnToManageTemplates = async () => {
    history.push({
      pathname: AdminPathHelpers.BptTemplates(),
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          {/* ----- Template name header ----- */}
          <h1>
            <SafeRenderHtml
              htmlString={htmlRemoveOuterPTag(data?.bptTemplate?.name?.text)}
            />
          </h1>
          {/* ----- Alert / Errors ----- */}
          <GlobalAlert />
          <GraphqlError
            title={t("error_loading_bpt_Template")}
            errors={error}
          />

          <GraphqlError
            title={t("error_loading_bpt_template_phase_list")}
            errors={errorPhaseList}
          />

          {loading || loadingPhaseList ? (
            <LoadingIndicator />
          ) : !error && !errorPhaseList ? (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  {/* ----- Stage ----- */}
                  <div>
                    {t("stage")}{" "}
                    <div className="label label-info">
                      {data?.bptTemplate?.stage ? (
                        t(data?.bptTemplate?.stage.toString())
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {/* ----- Activate button ----- */}
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.bpt.updateRoles
                    }
                  >
                    <MakeActivateButtonAndModal
                      canMakeActivated={true}
                      onMakeActivated={onActivate}
                      warningMessage={t("activate_template_warning")}
                      buttonText={t("activate")}
                      ConfirmationMessage="confirm"
                    />
                  </RenderWhenAuthorized>

                  {/* ----- Delete draft button ----- */}
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.bpt.updateRoles
                    }
                  >
                    <DeleteTemplateButtonAndModal
                      onDelete={() => onDeleteTemplate(templateId)}
                    ></DeleteTemplateButtonAndModal>
                  </RenderWhenAuthorized>
                </div>
              </div>

              {/* ----- Created Updated Info ----- */}
              <CreatedUpdatedInfo
                createdBy={data?.bptTemplate?.createdBy}
                createdDate={data?.bptTemplate?.createdAt}
                updatedBy={data?.bptTemplate?.modifiedBy}
                updatedDate={data?.bptTemplate?.modifiedAt}
                version={data?.bptTemplate?.templateVersion}
              />

              {/* ----- Template information section ----- */}
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : (
                <TemplateInformationCard
                  bptTemplate={data?.bptTemplate}
                  calculatedDuration={data?.bptTemplate?.calculatedDuration}
                />
              )}

              {/* ----- PhasesCard section ----- */}
              <SectionCard
                header={
                  <PhaseSectionCardHeader
                    templateId={templateId}
                    phases={dataPhaseList?.bptTemplatePhaseList?.phases}
                    disabled={
                      dataPhaseList?.bptTemplatePhaseList?.phases == null ||
                      dataPhaseList?.bptTemplatePhaseList?.phases?.length === 0
                    }
                    onExpandAll={onExpandAll}
                    onCollapseAll={onCollapseAll}
                  />
                }
                contentAreaProps={{ className: "mrgn-tp-sm" }}
                className="pb-0"
                showLine={true}
              >
                {dataPhaseList?.bptTemplatePhaseList?.phases == null ||
                dataPhaseList?.bptTemplatePhaseList?.phases?.length === 0 ? (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.bpt.create}
                    fallbackComponent={<NoResults centered />}
                  >
                    <div className="text-center mrgn-tp-md">
                      <div className="lead mrgn-tp-md mrgn-bttm-sm">
                        {/* ------------ Add phase button ------------ */}
                        {data?.bptTemplate?.stage === BptTemplateStage.Draft ? (
                          <AddPhaseBtn
                            templateId={data?.bptTemplate?.id ?? ""}
                            refetchQueries={["BptTemplatePhaseList"]}
                            buttonLabel={t("no_phases_please_add")}
                            showImage
                            onCompleted={(data: any) => {
                              data?.name &&
                                adminAlertMsg.onCreateSuccess(data?.name?.text);
                            }}
                            onError={adminAlertMsg.onCreateError}
                          />
                        ) : (
                          <>
                            <img src={AddPhaseIcon} alt="" />
                            <div className={"no-results-content font-size-18"}>
                              {t("no_phases")}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </RenderWhenAuthorized>
                ) : (
                  dataPhaseList?.bptTemplatePhaseList?.phases?.map(
                    (item, index) => {
                      return (
                        <PhaseDetails
                          key={index}
                          index={index}
                          isOpen={collapse}
                          item={item!}
                          bptTemplate={data?.bptTemplate!}
                          templateId={templateId}
                          isLoading={loading}
                          onDeletePhase={onDeletePhase}
                        />
                      );
                    }
                  )
                )}

                <div className="pt-0 separator-line-top mrgn-tp-20"></div>
                <div className="flex justify-between align-center py-3">
                  <div>
                    {data?.bptTemplate?.stage === BptTemplateStage.Draft ? (
                      <RenderWhenAuthorized
                        authorizedRoles={ROLE_ACTIONS.administration.bpt.create}
                      >
                        {/* ------------ Add phase button ------------ */}
                        <AddPhaseBtn
                          templateId={data?.bptTemplate?.id ?? ""}
                          refetchQueries={["BptTemplatePhaseList"]}
                          buttonLabel={t("add_phase")}
                          onCompleted={(data: any) => {
                            data?.name &&
                              adminAlertMsg.onCreateSuccess(data?.name?.text);
                          }}
                          onError={adminAlertMsg.onCreateError}
                        />
                      </RenderWhenAuthorized>
                    ) : null}
                  </div>

                  {/* ------------ Total calculated duration ------------ */}
                  <TotalCalculatedDuration
                    calculatedDuration={data?.bptTemplate?.calculatedDuration}
                  />
                </div>
              </SectionCard>

              {/* ------------- Return to Manage templates button ------------- */}
              <ReturnToButton
                returnTarget={t("manage_templates")!}
                onClick={onReturnToManageTemplates}
              />
            </>
          ) : null}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

const DeleteTemplateButtonAndModal: React.FC<{
  onDelete: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const confirmDeleteModal = useConfirmationModal({});
  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-sm"
        onClick={confirmDeleteModal.open}
        data-testid="button-delete"
      >
        {t("delete_draft")}
      </button>

      <ConfirmationModal
        modalState={confirmDeleteModal}
        title={t("delete_draft")}
        confirmBtnEnabled={confirmDeleteState}
        onConfirm={props.onDelete}
        onCancel={() => {
          confirmDeleteModal.close();
          setConfirmDeleteState(false);
        }}
      >
        <Alert type={AlertTypes.WARNING} content={t("delete_draft_warning")} />

        <label>
          <input
            type="checkbox"
            checked={confirmDeleteState}
            onChange={(e) => setConfirmDeleteState((x) => !x)}
            data-testid="modal-checkbox-confirm-delete"
          />{" "}
          {t("delete_confirm")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default BptTemplateIndexPage;
