import cc from "classcat";
import * as React from "react";
import isNullOrEmpty from "../../util/isNullOrEmpty";
import { isHTMLString } from "util/strings";
import SafeRenderHtml from "./SafeRenderHtml";

export enum AlertTypes {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
}

export interface AlertProps {
  type: string;
  title?: string;
  content?: string;
  onClose?: () => void;
  timeOut?: number;
  className?: string;
}

export const Alert: React.FC<AlertProps> = (props) => {
  const { type, title, content, children, onClose, timeOut } = props;
  // const [show, setShow] = React.useState(true);
  const isHtmlAlertContent = content != null && isHTMLString(content);

  const className = React.useMemo(() => {
    switch (type) {
      case AlertTypes.SUCCESS:
        return "alert alert-success";
      case AlertTypes.INFO:
        return "alert alert-info";
      case AlertTypes.WARNING:
        return "alert alert-warning";
      case AlertTypes.DANGER:
        return "alert alert-danger";
      default:
        console.error("Invalid AlertTypes.");
        return "";
    }
  }, [type]);

  React.useEffect(() => {
    let timeId: any;

    if (timeOut) {
      timeId = setTimeout(() => {
        // After timeOut seconds set the show value to false
        //setShow(false);
        onClose?.();
      }, timeOut);
    }

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  // If show is false the component will return null and stop here
  // if (!show) {
  //   return null;
  // }

  if (isNullOrEmpty(title)) {
    return (
      <div className={cc([className, props.className])} tabIndex={0}>
        <div className="pull-left">
          {isHtmlAlertContent ? (
            <SafeRenderHtml htmlString={content} />
          ) : (
            content && <p>{content}</p>
          )}
          {children}
        </div>
        {onClose != null ? (
          <span
            className="glyphicon glyphicon-remove mrgn-rght-sm font-size-12 pointer pull-right"
            onClick={onClose}
            tabIndex={0}
          />
        ) : null}
        <div className="pb-2_5 clearfix" />
      </div>
    );
  } else {
    return (
      <section
        role="alert"
        className={cc([className, props.className])}
        tabIndex={0}
      >
        <h3 className="pull-left">{title}</h3>
        {onClose != null ? (
          <span
            className="glyphicon glyphicon-remove mrgn-rght-sm font-size-12 pointer pull-right"
            onClick={onClose}
            tabIndex={0}
          />
        ) : null}
        <div className="clearfix" />
        {children ? (
          <div>
            {isHtmlAlertContent ? (
              <SafeRenderHtml htmlString={content} />
            ) : (
              content && <p>{content}</p>
            )}
            {children}
          </div>
        ) : (
          <div>
            {isHtmlAlertContent ? (
              <SafeRenderHtml htmlString={content} />
            ) : (
              content && <p>{content}</p>
            )}
          </div>
        )}
        <div className="pb-2_5" />
      </section>
    );
  }
};

export default Alert;
