import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface MoveUpButtonProps {
  title?: string;
  onClick: () => any;
  className?: string;
  disabled?: boolean;
}

export const MoveUpButton: React.FC<MoveUpButtonProps> = (props) => {
  const { t } = useTranslation();
  const { title = t("move_up"), onClick, className, disabled = true } = props;

  return (
    <button
      type="button"
      title={title}
      className={cc(["btn btn-link font-size-14 hover-grey", className])}
      onClick={onClick}
      disabled={disabled}
      data-testid="button-move-up"
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
};

export default MoveUpButton;
