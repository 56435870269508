import { Reducer, useCallback, useEffect, useMemo, useReducer } from "react";
import {
  AzureSearchDateRange,
  AzureSearchNumberRange,
} from "../../../components/organisms/search/FilterOptionsSection/filters/filters";
import { xorWith } from "lodash";

type FacetKey = string;

export interface Filter<T> {
  facet: FacetKey;
  label: string;
  value: T;
}

export interface BasicSearchState {
  keywordSearchText: string;
  filterSectionCounts: Record<FacetKey, number>;
  checkboxFilters: Record<FacetKey, Array<Filter<string>>>;
  dateRangeFilters: Record<FacetKey, Filter<AzureSearchDateRange>>;
  numberRangeFilters: Record<FacetKey, Filter<AzureSearchNumberRange>>;
}

export const defaultState: BasicSearchState = {
  keywordSearchText: "",
  filterSectionCounts: {},
  checkboxFilters: {},
  dateRangeFilters: {},
  numberRangeFilters: {},
};

export type BasicSearchAction =
  | { type: "reset_all" }
  | { type: "update_keyword_search"; text: string }
  | { type: "reset_keyword_search" }
  | {
      type: "toggle_checkbox_filter";
      filter: Filter<string>;
    }
  | {
      type: "set_checkbox_filter";
      filter: Filter<string>;
    }
  | {
      type: "delete_checkbox_filter_by_facet";
      filter: Pick<Filter<string>, "facet">;
    }
  | {
      type: "update_number_range_filter";
      filter: Filter<AzureSearchNumberRange>;
    }
  | {
      type: "delete_number_range_filter";
      filter: Filter<AzureSearchNumberRange>;
    }
  | {
      type: "update_date_range_filter";
      filter: Filter<AzureSearchDateRange>;
    }
  | {
      type: "delete_date_range_filter";
      filter: Filter<AzureSearchDateRange>;
    };

export function basicSearchReducer(
  state: BasicSearchState,
  action: BasicSearchAction
) {
  switch (action.type) {
    case "reset_all": {
      return defaultState;
    }

    case "reset_keyword_search": {
      return { ...state, keywordSearchText: "" };
    }

    case "update_keyword_search": {
      return { ...state, keywordSearchText: action.text };
    }

    case "toggle_checkbox_filter": {
      const existingValues = state.checkboxFilters[action.filter.facet] ?? []; // NOTE: we default to empty array here so xorWith has something to work on.
      const newCheckboxFilters = { ...state.checkboxFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };

      // put the `action.filter` into the values array, or remove it if it's already in there.
      const newValues = xorWith(
        existingValues,
        [action.filter],
        (a: Filter<string>, b: Filter<string>) => {
          return a.value === b.value;
        }
      );

      if (newValues.length === 0) {
        // values is empty; nothing is being stored under this facet key - let's entirely remove the facet key from the state.
        delete newCheckboxFilters[action.filter.facet];
        delete newFilterSectionCounts[action.filter.facet];
      } else {
        // this facet key has values - let's update them in the state, and increment the counter
        newCheckboxFilters[action.filter.facet] = newValues;
        newFilterSectionCounts[action.filter.facet] = newValues.length;
      }

      return Object.assign({}, state, {
        checkboxFilters: newCheckboxFilters,
        filterSectionCounts: newFilterSectionCounts,
      });
    }

    case "set_checkbox_filter": {
      const existingValues = state.checkboxFilters[action.filter.facet] ?? []; // NOTE: we default to empty array here so xorWith has something to work on.
      const newCheckboxFilters = { ...state.checkboxFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };

      const newValues = [...existingValues];
      const foundIndex = newValues.findIndex(
        (x) => x.value === action.filter.value
      );

      if (foundIndex === -1) {
        newValues[newValues.length] = action.filter;
      } else {
        newValues[foundIndex] = action.filter;
      }

      if (newValues.length === 0) {
        // values is empty; nothing is being stored under this facet key - let's entirely remove the facet key from the state.
        delete newCheckboxFilters[action.filter.facet];
        delete newFilterSectionCounts[action.filter.facet];
      } else {
        // this facet key has values - let's update them in the state, and increment the counter
        newCheckboxFilters[action.filter.facet] = newValues;
        newFilterSectionCounts[action.filter.facet] = newValues.length;
      }

      return Object.assign({}, state, {
        checkboxFilters: newCheckboxFilters,
        filterSectionCounts: newFilterSectionCounts,
      });
    }

    case "delete_checkbox_filter_by_facet": {
      const newCheckboxFilters = { ...state.checkboxFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };

      delete newCheckboxFilters[action.filter.facet];
      delete newFilterSectionCounts[action.filter.facet];

      return Object.assign({}, state, {
        checkboxFilters: newCheckboxFilters,
        filterSectionCounts: newFilterSectionCounts,
      });
    }

    case "update_number_range_filter": {
      const { facet, value, label } = action.filter;
      const { le, ge, label: _label } = value; // TODO: can we get rid of the redundant 'label' in this type?

      const newNumberRangeFilters = { ...state.numberRangeFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };

      let selectedCountInSection = 1;
      if (ge == null && le == null) selectedCountInSection = 0;
      if (ge != null && le != null) selectedCountInSection = 2;

      // if the filter has no values, remove it, and remove the 'count' for this facet.
      if (selectedCountInSection === 0) {
        delete newNumberRangeFilters[facet];
        delete newFilterSectionCounts[facet];

        return Object.assign({}, state, {
          filterSectionCounts: newFilterSectionCounts,
          numberRangeFilters: newNumberRangeFilters,
        });
      }

      // otherwise, set properties as needed, and update the count
      newFilterSectionCounts[facet] = selectedCountInSection;
      newNumberRangeFilters[facet] = { facet, label, value };

      return Object.assign({}, state, {
        filterSectionCounts: newFilterSectionCounts,
        numberRangeFilters: newNumberRangeFilters,
      });
    }

    case "delete_number_range_filter": {
      const newNumberRangeFilters = { ...state.numberRangeFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };
      delete newNumberRangeFilters[action.filter.facet];
      delete newFilterSectionCounts[action.filter.facet];

      return Object.assign({}, state, {
        filterSectionCounts: newFilterSectionCounts,
        numberRangeFilters: newNumberRangeFilters,
      });
    }

    case "update_date_range_filter": {
      const { facet, value, label } = action.filter;

      const newDateRangeFilters = { ...state.dateRangeFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };

      const geString = value?.ge ? value.ge.toISOString().substring(0, 10) : "";
      const leString = value?.le ? value.le.toISOString().substring(0, 10) : "";

      let selectedCountInSection = 1;
      if (geString.length === 0 && leString.length === 0)
        selectedCountInSection = 0;
      if (geString.length > 0 && leString.length > 0)
        selectedCountInSection = 2;

      // if the filter has no values, remove it, and remove the 'count' for this facet.
      if (selectedCountInSection === 0) {
        delete newDateRangeFilters[facet];
        delete newFilterSectionCounts[facet];

        return Object.assign({}, state, {
          filterSectionCounts: newFilterSectionCounts,
          dateRangeFilters: newDateRangeFilters,
        });
      }

      // otherwise, set properties as needed, and update the count
      newFilterSectionCounts[facet] = selectedCountInSection;
      newDateRangeFilters[facet] = { facet, label, value };

      return Object.assign({}, state, {
        filterSectionCounts: newFilterSectionCounts,
        dateRangeFilters: newDateRangeFilters,
      });
    }

    case "delete_date_range_filter": {
      const newDateRangeFilters = { ...state.dateRangeFilters };
      const newFilterSectionCounts = { ...state.filterSectionCounts };
      delete newDateRangeFilters[action.filter.facet];
      delete newFilterSectionCounts[action.filter.facet];

      return Object.assign({}, state, {
        filterSectionCounts: newFilterSectionCounts,
        dateRangeFilters: newDateRangeFilters,
      });
    }

    default: {
      console.warn("basicSearchReducer got unrecognized action:", action);
      return state;
    }
  }
}

export default function useBasicSearch(searchKey: string) {
  const persistedState = useMemo(() => {
    return localStorageGetAndParse(searchKey);
  }, [searchKey]);

  const [state, dispatch] = useReducer<
    Reducer<BasicSearchState, BasicSearchAction>
  >(basicSearchReducer, persistedState ?? defaultState);

  useEffect(
    function persistStateToLocalStorage() {
      safeLocalStorageSetItem(searchKey, state);
    },
    [state]
  );

  const getCountForSection = useCallback(
    (facet: string) => {
      return state.filterSectionCounts[facet];
    },
    [state.filterSectionCounts]
  );

  const isCheckboxSelected = useCallback(
    (facet: FacetKey, filterKey: string) => {
      const maybeFilter = state.checkboxFilters[facet];
      if (maybeFilter == null) return false;

      const maybeValue = maybeFilter.find((x) => x.value === filterKey);
      if (maybeValue != null) return true;

      return false;
    },
    [state.checkboxFilters]
  );

  return {
    state,
    dispatch,

    isCheckboxSelected,
    getCountForSection,
  };
}

/**
 * get basic search data from localstorage and parse items into their correct types;
 * correct type information may be lost if we've saved a complex type (e.g. Date) as JSON.
 */
const localStorageGetAndParse = (key: string): BasicSearchState | undefined => {
  const state = safeLocalStorageGetItem(key) as BasicSearchState | undefined;
  if (!state) return undefined;

  mutConvertDateRangeFilters(state);
  return state;
};

export const mutConvertDateRangeFilters = (
  state: BasicSearchState | undefined
) => {
  if (!state) return undefined;

  // Convert date strings back into Date objects
  if (state.dateRangeFilters != null) {
    for (let key in state.dateRangeFilters) {
      if (state.dateRangeFilters[key]?.value?.le) {
        state.dateRangeFilters[key].value.le = new Date(
          state.dateRangeFilters[key].value.le!
        );
      }

      if (state.dateRangeFilters[key]?.value?.ge) {
        state.dateRangeFilters[key].value.ge = new Date(
          state.dateRangeFilters[key].value.ge!
        );
      }
    }
  }
};

// TODO(SEARCH): below are generic, can be moved
const safeLocalStorageSetItem = <T>(key: string, value: T) => {
  try {
    return window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
};

const safeLocalStorageGetItem = <T>(key: string) => {
  try {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return undefined;
  } catch (e) {
    console.error(e);
  }
};
