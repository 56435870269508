import { MultiSelectListItem } from "components/atoms/MultiSelectListItem";
import { BilingualAbbreviationLookupTextInput } from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import WildlifeManagementBoardAutocomplete from "../WildlifeManagementBoardsAutocomplete/WildlifeManagementBoardsAutocomplete";

export interface WildlifeManagementBoardsMultiSelectListProps {
  value: Array<BilingualAbbreviationLookupTextInput>;
  onChange: (listItems: Array<BilingualAbbreviationLookupTextInput>) => void;
}

export const WildlifeManagementBoardsMultiSelectList: React.FC<
  WildlifeManagementBoardsMultiSelectListProps
> = (props) => {
  const { value, onChange } = props;
  const { t, i18n } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    BilingualAbbreviationLookupTextInput | undefined
  >(undefined);

  const onRemoveFromList = (id: string) => {
    const newList = value.filter((x) => x.id !== id);
    onChange(newList);
  };

  const isFound = value.some((x) => x.id === selectedSearchItem?.id);

  return (
    <div>
      <div className="flex gap-sm align-end">
        <WildlifeManagementBoardAutocomplete onChange={setSelectedSearchItem} />

        {value &&
          selectedSearchItem &&
          !isFound &&
          onChange([...value, selectedSearchItem])}
        {selectedSearchItem && setSelectedSearchItem(undefined)}
        {/* <button
          type="button"
          className="btn btn-default flex align-center"
          onClick={onAddSelectedToList}
          data-testid="activityMultiSelectList_addToListBtn"
        >
          <i className="fa fa-plus-circle mrgn-rght-sm" />
        </button> */}
      </div>

      <ul className="multi-select-list">
        {value.map((x) => {
          return (
            <MultiSelectListItem
              id={x.id as string}
              name={bilingualLookupTextNameForLanguage(i18n.language, x)}
              onRemove={() => onRemoveFromList(x.id as string)}
              key={x.id}
            />
          );
        })}
      </ul>
    </div>
  );
};

export interface WildlifeManagementBoardsMultiSelectListWithControllerProps<
  TFieldValues
> extends Omit<
      WildlifeManagementBoardsMultiSelectListProps,
      "onChange" | "value"
    >,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const WildlifeManagementBoardsMultiSelectListWithController = <
  TFieldValues,
>(
  props: WildlifeManagementBoardsMultiSelectListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <WildlifeManagementBoardsMultiSelectList
          value={value as Array<BilingualAbbreviationLookupTextInput>}
          onChange={onChange}
        />
      )}
    />
  );
};

export default WildlifeManagementBoardsMultiSelectList;
