import { OverlayContainer } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { ContactType } from "components/organisms/contacts/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Organization } from "../../../generated/gql-types";
import { TextInput } from "../../atoms/forms/TextInput";
import ModalDialog from "../../molecules/Modal";
import { AddOrganizationModalSearchOrganization } from "./AddOrganizationModalSearch";
import { FormOrganizationDisplay } from "./FormOrganizationDisplay";
import isNullOrEmpty from "util/isNullOrEmpty";

interface AddRemoveOrganizationButtonAndModalProps {
  id: string;
  contact: Organization | null | undefined;
  onSave: (
    newValue: Organization | null | undefined,
    type: ContactType
  ) => void;
  onRemove: () => void;
  isPhotographer?: boolean;
  isContactOnly?: boolean; // Add Organization only?
}

const AddRemoveOrganizationButtonAndModal: React.FC<
  AddRemoveOrganizationButtonAndModalProps
> = (props) => {
  const { isPhotographer = false, isContactOnly = false } = props;
  const { t } = useTranslation();
  const modalState = useOverlayTriggerState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState<ContactType>("organization");
  const [selectedValue, setSelectedValue] = useState<
    Organization | null | undefined
  >(null);

  useEffect(
    function resetSearchTermWhenTypeChanges() {
      setSearchTerm("");
    },
    [searchType]
  );

  useEffect(() => {
    setSelectedValue(null);
  }, [searchTerm, searchType]);

  const onOpen = () => {
    setSearchTerm("");
    setSearchType("organization");
    setSelectedValue(null);
    modalState.open();
  };

  const onSave = () => {
    props.onSave(selectedValue, searchType);
    modalState.close();
  };

  return props.contact != null && !isNullOrEmpty(props.contact.id) ? (
    <div className="flex width-100 align-start justify-between">
      <FormOrganizationDisplay contact={props.contact} />
      <button
        type="button"
        className="btn btn-link py-0 px-2"
        onClick={props.onRemove}
        data-testid="button-remove-organization"
      >
        <i className="far fa-times-circle mrgn-rght-sm"></i>
        {t("remove_organization")}
      </button>
    </div>
  ) : (
    <div>
      <button
        type="button"
        className="btn btn-link mrgn-0 py-0 px-2"
        onClick={onOpen}
        data-testid="button-add-contact"
      >
        <i className="fas fa-plus-circle mrgn-rght-sm"></i>
        {t("select_organization")}
      </button>

      {modalState.isOpen ? (
        <OverlayContainer>
          <ModalDialog
            id="add_contact_modal"
            data-testid="add_contact_modal"
            title={t("select_organization")}
            className={"modal-width-70"}
            onClose={modalState.close}
            showModalFooter={false}
          >
            <div className="flex gap-md flex-wrap">
              <TextInput
                label={t("who_are_you_looking_for")}
                placeholder={t("enter_organization_name")}
                id="search-for"
                inputClassName="width-100"
                divClassName="flex-auto "
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
            </div>
            <div className="min-height-auto">
              <AddOrganizationModalSearchOrganization
                search={searchTerm}
                tags={isPhotographer ? "PHOTO" : undefined}
                onChange={setSelectedValue}
              />
            </div>
            <hr className="mrgn-tp-0 mrgn-bttm-md" />
            <ul className="list-inline lst-spcd mrgn-bttm-0">
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-testid="form-button-save"
                  disabled={selectedValue == null}
                  onClick={onSave}
                >
                  <i className="fas fa-plus-circle" />
                  <span>&nbsp; {t("select")}</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={modalState.close}
                  className="btn btn-default"
                  data-testid="form-button-cancel"
                >
                  {t("cancel")}
                </button>
              </li>
            </ul>
          </ModalDialog>
        </OverlayContainer>
      ) : null}
    </div>
  );
};

export default AddRemoveOrganizationButtonAndModal;
