import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface EditLinkProps {
  text?: string;
  showText?: boolean;
  linkTitle?: string;
  linkTo: string;
  state?: any;
  className?: string;
  disabled?: boolean;
}

export const EditLink: React.FC<EditLinkProps> = (props) => {
  const { t } = useTranslation();
  const {
    text = t("edit"),
    showText = true,
    linkTitle = t("edit"),
    linkTo,
    state,
    className,
    disabled,
  } = props;

  if (disabled) {
    return (
      <button
        type="button"
        title={linkTitle}
        className={cc(["btn btn-link card-header-button-link", className])}
        disabled
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {text}
      </button>
    );
  }

  return (
    <Link
      to={{ pathname: linkTo, state: state }}
      title={linkTitle}
      className={cc(["card-header-button-link hover-underline", className])}
      data-testid="link-edit"
    >
      {showText ? (
        <>
          <i className="fas fa-pen mrgn-rght-sm"></i>
          {text}
        </>
      ) : (
        <i className="fas fa-pen"></i>
      )}
    </Link>
  );
};

export default EditLink;
