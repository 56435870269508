import * as React from "react";
import { useTranslation } from "react-i18next";
import type { AddPhotoLicensePopupProps } from "./AddLicenseFilePopup";
import AddPhotoLicensePopup, {
  useAddPhotoLicensePopup,
} from "./AddLicenseFilePopup";

interface AddPhotoLicenseBtnProps
  extends Omit<AddPhotoLicensePopupProps, "modalState"> {
  buttonLabel?: string;
}

const AddPhotoLicenseBtn: React.FC<AddPhotoLicenseBtnProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useAddPhotoLicensePopup({});
  const validPhotoLicenseId =
    props.photoLicenseId != null && props.photoLicenseId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validPhotoLicenseId}
        data-testid="button-add-license"
      >
        <i className="far fa-list-alt mrgn-rght-sm" />
        {buttonLabel ? t(buttonLabel) : t("add_licence")}
      </button>
      {validPhotoLicenseId ? (
        <AddPhotoLicensePopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddPhotoLicenseBtn;
