import { useQuery } from "@tanstack/react-query";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { isEqual, unionWith } from "lodash";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getFieldsDefinition, getQueryOperators } from "../fetch";
import { mapFormToAPIDomainModel } from "./mappers";
import { SelectColumnsSection } from "./SelectColumnsSection/SelectColumnsSection";
import {
  QueryFieldsListResponse,
  QueryOperatorsResponse,
  SetConditionsSection,
} from "./SetConditionsSection/SetConditionsSection";
import SettingsSection from "./SettingsSection/SettingsSection";

export interface QueryToolFormFields {
  id?: string;
  nameInEnglish: string;
  nameInFrench: string;
  sharingSettings: string;
  sharingWith: string[];
  conditionRules: any; // Query-builder
  setFixedProperty: string;
  cosewicItems: string[];
  listingItems: string[];
}

export interface QueryToolFormProps {
  onSubmit: any;
  initialValues: QueryToolFormFields;
  onCancel: () => void;
}

export const QueryToolForm: React.FC<QueryToolFormProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { queryId } = params as any;

  const form = useForm<QueryToolFormFields>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState } = form;
  const { dirtyFields, isDirty } = formState;

  const cosewicFilterableFieldsQuery = useQuery<QueryFieldsListResponse>(
    ["queryTool", "setConditions", "cosewicFilterableFields"],
    () => getFieldsDefinition("key=cosewic*%26customMetadata.isFilterable=true")
  );

  const listingFilterableFieldsQuery = useQuery<QueryFieldsListResponse>(
    ["queryTool", "setConditions", "listingFilterableFields"],
    () =>
      getFieldsDefinition(
        "key=Listing*%26name=!Abbreviation*%26customMetadata.isFilterable=true"
      )
  );

  const operatorsQuery = useQuery<QueryOperatorsResponse>(
    ["queryTool", "setConditions", "operators"],
    () => getQueryOperators()
  );

  const cosewicSelectableFieldsQuery = useQuery<QueryFieldsListResponse>(
    ["queryTool", "selectColumnsForOutput", "cosewicSelectableFields"],
    () => getFieldsDefinition("key=cosewic*%26customMetadata.isSelectable=true")
  );

  const listingSelectableFieldsQuery = useQuery<QueryFieldsListResponse>(
    ["queryTool", "selectColumnsForOutput", "listingSelectableFields"],
    () =>
      getFieldsDefinition(
        "key=Listing*%26name=!Abbreviation*%26customMetadata.isSelectable=true"
      )
  );

  const cosewicCombinedFields = () => {
    const FilterableFields =
      cosewicFilterableFieldsQuery.data?.queryFieldsList.map((x) => ({
        key: x.key,
        type: x.type,
      }));
    const SelectableFields =
      cosewicSelectableFieldsQuery.data?.queryFieldsList.map((x) => ({
        key: x.key,
        type: x.type,
      }));
    return unionWith(FilterableFields, SelectableFields, isEqual);
  };

  const listingCombinedFields = () => {
    const FilterableFields =
      listingFilterableFieldsQuery.data?.queryFieldsList.map((x) => ({
        key: x.key,
        type: x.type,
      }));
    const SelectableFields =
      listingSelectableFieldsQuery.data?.queryFieldsList.map((x) => ({
        key: x.key,
        type: x.type,
      }));
    return unionWith(FilterableFields, SelectableFields, isEqual);
  };

  const getFieldTypeByKey = (key: string) => {
    const program = key.split("/", 1)[0];
    const type =
      program === "Cosewic"
        ? cosewicCombinedFields().find((x) => x.key === key)?.type
        : listingCombinedFields().find((x) => x.key === key)?.type;
    return type;
  };

  const onSubmit: SubmitHandler<Partial<QueryToolFormFields>> = async (
    formData
  ) => {
    const domainData = mapFormToAPIDomainModel(formData, getFieldTypeByKey, t);

    // const values = filterForDirtyFields(
    //   formData,
    //   dirtyFields,
    //   initialValues
    // );
    //console.log(values);

    await props.onSubmit(domainData);
    window.scrollTo(0, 0);
  };

  const loadingQuery =
    cosewicFilterableFieldsQuery.isLoading ||
    listingFilterableFieldsQuery.isLoading ||
    operatorsQuery.isLoading ||
    cosewicSelectableFieldsQuery.isLoading ||
    listingSelectableFieldsQuery.isLoading;

  const errorQuery =
    cosewicFilterableFieldsQuery.isError ||
    listingFilterableFieldsQuery.isError ||
    operatorsQuery.isError ||
    cosewicSelectableFieldsQuery.isError ||
    listingSelectableFieldsQuery.isError;

  return (
    <>
      {loadingQuery ? (
        <LoadingIndicator centered className="mrgn-tp-md mrgn-bttm-md" />
      ) : errorQuery ? (
        <Alert type={AlertTypes.DANGER} title="Error loading form fields" />
      ) : null}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SettingsSection initialValues={initialValues} />
          <SetConditionsSection
            initialValues={initialValues}
            cosewicFieldsQuery={cosewicFilterableFieldsQuery}
            listingFieldsQuery={listingFilterableFieldsQuery}
            operatorsQuery={operatorsQuery}
            getFieldTypeByKey={getFieldTypeByKey}
          />
          <SelectColumnsSection
            initialValues={initialValues}
            cosewicFieldsQuery={cosewicSelectableFieldsQuery}
            listingFieldsQuery={listingSelectableFieldsQuery}
          />
          <FormButtons
            errors={errorQuery ? ["error"] : undefined}
            isDirty={isDirty}
            onCancel={props.onCancel}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default QueryToolForm;
