import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import { ListingProcessDocument } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import {
  convertDocTypeToDocUriTitle,
  convertDocTypeToPubDateTitle,
} from "util/listing/convertDocTypeToTitle";

export interface DocumentsSectionProps {
  documents?: any; // ListingProcessDocument[];
}

export const DocumentsSection: React.FC<DocumentsSectionProps> = (props) => {
  const { documents } = props;
  const { t } = useTranslation();

  return (
    <>
      <h4 className="mrgn-bttm-md">{t("documents")}</h4>
      {!documents || documents.length === 0 ? (
        <NoResults centered />
      ) : (
        documents?.map((item: ListingProcessDocument) => {
          const type = item.type;
          const publicationDate = item.publicationDate;
          const uri = item.uri;

          return (
            <div className="species-field" key={item.type}>
              <div className="row">
                <dl>
                  <div className="species-data col-sm-3">
                    <dt className="text-muted">
                      {t(convertDocTypeToPubDateTitle(type))}
                    </dt>
                    <dd>
                      {formatTimestamp(publicationDate) ?? <MissingData />}
                    </dd>
                  </div>
                  <div className="species-data col-sm-9">
                    <dt className="text-muted">
                      {t(convertDocTypeToDocUriTitle(type))}
                    </dt>
                    <dd className="redacted">
                      {uri ? (
                        <a target="_blank" href={uri} rel="noreferrer noopener">
                          {uri}
                        </a>
                      ) : (
                        <MissingData />
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default DocumentsSection;
