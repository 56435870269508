import { BilingualAbbreviationAcronymTagsLookupTextInput, DocumentPrograms, ProgramTypeLookupListDocument } from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import { useQuery } from "@apollo/client";
import i18n from "i18n";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { bilingualTextNameForLanguage } from "mappers";

export interface ProgramDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  required?: boolean;
  disabled?: boolean;
  onChange: (value: BilingualAbbreviationAcronymTagsLookupTextInput) => void;
}

export const ProgramDropdown = React.forwardRef<
  HTMLSelectElement,
  ProgramDropdownProps
>((props, ref) => {
  const { t } = useTranslation();
  
  const { loading, error, data } = useQuery(ProgramTypeLookupListDocument, {
    errorPolicy: "all",
    variables: {
      params: {
        category: "programs",
        pageNumber: 1,
        pageSize: 200,
        sortBy: i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
      },
    },
  });

  if (loading) return <LoadingIndicator className="mrgn-lft-sm" />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget?.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.lookupList?.lookups?.find((x) => x?.id === id);

    if (!value) {
      console.error(`Program dropdown couldn't find a value for ID ${id}`);
      return;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control"
      value={props?.defaultValue?.id ?? ""}
      ref={ref}
      {...props}
      onChange={onChange}
    >
      <option value={""}>
        {t(props.placeholder ?? "please_select_option")}
      </option>
      {data?.lookupList?.lookups?.map((x) => {
        return (
          <option
            key={x?.id ?? "error - missing id"}
            value={x?.id ?? "error - missing id"}
          >
            {bilingualTextNameForLanguage(i18n.language, x?.name)}
          </option>
        );
      })}
    </select>
  );
});

export interface ProgramDropdownWithControllerProps<TFieldValues>
  extends Omit<ProgramDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const ProgramDropdownWithController = <TFieldValues,>(
  props: ProgramDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <ProgramDropdown
          id={props.id}
          placeholder={props.placeholder}
          onChange={onChange}
          defaultValue={value}
          required={props.required}
          disabled={props.disabled}
        />
      )}
      rules={{
        required: props.required,
      }}
    />
  );
};

export default ProgramDropdownWithController;
