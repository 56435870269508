import RepeatIcon from "assets/svg/repeat-icon.svg";
import SlidersIcon from "assets/svg/sliders-icon.svg";
import MissingData from "components/atoms/MissingData";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { convertPathToTag } from "util/changeLog/convertPathToTag";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import { ChangeLog } from "../../../generated/gql-types";

interface ChangeLogListItemProps {
  changeLog: ChangeLog;
  changeLogDetailsLinkCallback?: (
    correlationId: string,
    changeLogId: string
  ) => string;
  showVersion?: boolean;
}

const ChangeLogListItem: React.FC<ChangeLogListItemProps> = (props) => {
  const { changeLog, changeLogDetailsLinkCallback, showVersion = true } = props;
  const { t } = useTranslation();
  if (!changeLog) return <div>Loading...</div>;

  const correlationId = changeLog.correlationId!;
  const changeLogId = changeLog.id!;
  const isActionUpdate =
    changeLog.actionDescription?.toLowerCase() === "update";
  const modifiedAt = changeLog.modifiedAt;
  const modifiedBy = changeLog.modifiedBy;
  const version = changeLog.version;
  const changeDescription =
    changeLog.aggregateName?.toLowerCase() +
    "_" +
    changeLog.actionDescription?.toLowerCase();
  const details = changeLog.details;

  return (
    <div className="flex">
      <div className="flex-noshrink flex align-start pt-2">
        <img
          src={isActionUpdate ? SlidersIcon : RepeatIcon}
          className="list-item-icon mrgn-tp-sm mrgn-rght-md"
          alt=""
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between">
          <div className="list-item-title mrgn-bttm-sm">
            {isActionUpdate && changeLogDetailsLinkCallback != null ? (
              <Link
                to={changeLogDetailsLinkCallback(correlationId, changeLogId)}
              >
                {formatTimestamp(modifiedAt, DisplayFormat.DATE_AND_TIME) ?? (
                  <MissingData />
                )}
              </Link>
            ) : (
              <span>
                {formatTimestamp(modifiedAt, DisplayFormat.DATE_AND_TIME) ?? (
                  <MissingData />
                )}
              </span>
            )}
          </div>
          {showVersion && (
            <div className="flex-noshrink flex align-center list-item-description">
              v. {version}
            </div>
          )}
        </div>
        <div className="list-item-description mrgn-bttm-md">
          {t(changeDescription)}&nbsp;
          <span className="text-primary">{modifiedBy}</span>
        </div>
        {isActionUpdate ? (
          <div className="tag-group pb-3">
            {details?.map((item) => {
              return (
                <div className="tag-item" key={item?.pathProperty}>
                  {item ? t(convertPathToTag(item.pathProperty!)) : ""}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ChangeLogListItem;
