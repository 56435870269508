import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface AddLinkProps {
  text?: string;
  linkTo: string;
  className?: string;
  disabled?: boolean;
}

export const AddLink: React.FC<AddLinkProps> = (props) => {
  const { t } = useTranslation();
  const { text = t("add"), disabled, linkTo, className } = props;

  if (disabled) {
    return (
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        disabled
      >
        <i className="far fa-plus-square mrgn-rght-sm"></i>
        {text}
      </button>
    );
  }

  return (
    <Link
      to={linkTo}
      className={cc(["card-header-button-link hover-underline", className])}
      data-testid="link-add"
    >
      <i className="far fa-plus-square mrgn-rght-sm"></i>
      {text}
    </Link>
  );
};

export default AddLink;
