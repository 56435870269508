import i18n from "i18n";
import isNullOrEmpty from "../isNullOrEmpty";

/**
 * This function is for a few edge case autocomplete components,
 * currently the TaxonomicGroupAutocomplete and PopulationAutocomplete.
 * Both are required to have their english and french names concatenated when
 * displaying their list items. Both are also required to handle two separate
 * data formats; they may be formatting a BilingualName or FullBilingualName object.
 *
 * @param item the population or taxonomic group object
 * returns a combined name string, or falls back to whichever name is defined, or empty string.
 */

export default function formatFullOrShortBilingualName(item: any) {
  if (isNullOrEmpty(item)) return "";

  const en = maybeFindNonEmpty(item.nameEnglish, item.nameEn);
  const fr = maybeFindNonEmpty(item.nameFrench, item.nameFr);

  if (en && fr) {
    if (i18n.language === "fr") {
      return `${fr} / ${en}`;
    }
    return `${en} / ${fr}`;
  }
  if (en) return en;
  if (fr) return fr;
  return "";
}

function maybeFindNonEmpty(
  first?: string,
  second?: string
): string | undefined {
  if (!isNullOrEmpty(first)) return first;
  if (!isNullOrEmpty(second)) return second;
}
