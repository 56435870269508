import _ from "lodash";

export enum Roles {
  // Basic user
  BasicUser = "Sar.Global.Viewer",

  // Admin
  GlobalAdministrator = "Sar.Global.Admin",
  COSEWICAdministrator = "Sar.Cosewic.Admin",
  ListingAdministrator = "Sar.Listing.Admin",
  DFORSAdministrator = "Sar.DFORS.Admin",
  ContactAdministrator = "Sar.Contact.Admin",
  PermitAdmin = "Sar.Permit.Admin",
  documentAdmin = "Sar.Document.Admin",
  RegistryAdmin = "Sar.Registry.Admin",
  RecoveryAdmin = "Sar.Recovery.Admin",
  ProtectionAdmin = "Sar.Protection.Admin",
  BptAdmin = "Sar.BPT.Admin",
  

  // Editor
  COSEWICEditor = "Sar.Cosewic.Editor",
  ListingEditor = "Sar.Listing.Editor",
  SpeciesProfileEditor = "Sar.SpeciesProfile.Editor",
  RecoveryEditor = "Sar.Recovery.Editor",
  ProtectionEditor = "Sar.Protection.Editor",
  DFOPhotoEditor = "Sar.DFOPhoto.Editor",
  PermitEditor = "Sar.Permit.Editor",

  // Region Tif editor
  AtlanticTIFEditor = "Sar.AtlanticTIF.Editor",
  QuebecTIFEditor = "Sar.QuebecTIF.Editor",
  NorthernTIFEditor = "Sar.NorthernTIF.Editor",
  OntarioTIFEditor = "Sar.OntarioTIF.Editor",
  PacificTIFEditor = "Sar.PacificTIF.Editor",
  PrairieTIFEditor = "Sar.PrairieTIF.Editor",

  // Publisher
  GlobalPublisher = "Sar.Global.Publisher",
  PermitPublisher = "Sar.Permit.Publisher"
}

/* ---------------------------------------------------- */

/*
 * NOTE(feb 2022): Global administrators are implied to have access to everything,
 *  so they aren't included in these mappings at the moment.
 *  They may be added in the future if necessary.
 */
/**
 * All the admin pages use the same permissions.
 * Includes: Genus, Species, Subspecies, Population, Variety, Range, and Taxonomic group.
 */
const ADMIN_PAGE_ROLES = Object.freeze({
  view: [Roles.COSEWICAdministrator, Roles.ListingAdministrator],
  create: [Roles.COSEWICAdministrator, Roles.ListingAdministrator],
  update: [Roles.COSEWICAdministrator, Roles.ListingAdministrator],
  deactivateReactivate: [
    Roles.COSEWICAdministrator,
    Roles.ListingAdministrator,
  ],
});

const ADMIN_LAND_PAGE_ROLES = Object.freeze({
  view: [Roles.ListingAdministrator],
  create: [Roles.ListingAdministrator],
  update: [Roles.ListingAdministrator],
  deactivateReactivate: [Roles.ListingAdministrator],
});

const ADMIN_RESPONSE_STATEMENT_FIELD_PAGE_ROLES = Object.freeze({
  view: [Roles.ListingAdministrator, Roles.DFORSAdministrator],
  create: [Roles.ListingAdministrator, Roles.DFORSAdministrator],
  update: [Roles.ListingAdministrator, Roles.DFORSAdministrator],
  deactivateReactivate: [Roles.ListingAdministrator, Roles.DFORSAdministrator],
});

const ADMIN_CONTACT_PAGE_ROLES = Object.freeze({
  view: [Roles.ContactAdministrator],
  create: [Roles.ContactAdministrator],
  update: [Roles.ContactAdministrator],
  deactivateReactivate: [Roles.ContactAdministrator],
});

const ADMIN_USER_PAGE_ROLES = Object.freeze({
  view: [
    Roles.GlobalAdministrator,
    Roles.COSEWICAdministrator,
    Roles.ListingAdministrator,
    Roles.RecoveryAdmin,
    Roles.ProtectionAdmin,
    Roles.PermitAdmin,
  ],
  create: [
    Roles.GlobalAdministrator,
    Roles.COSEWICAdministrator,
    Roles.ListingAdministrator,
    Roles.RecoveryAdmin,
    Roles.ProtectionAdmin,
    Roles.PermitAdmin,
  ],
  updateProgram: [Roles.GlobalAdministrator],
  updateRoles: [
    Roles.GlobalAdministrator,
    Roles.COSEWICAdministrator,
    Roles.ListingAdministrator,
    Roles.RecoveryAdmin,
    Roles.ProtectionAdmin,
    Roles.PermitAdmin,
  ],
});

const ADMIN_BPT_PAGE_ROLES = Object.freeze({
  view: [
    Roles.GlobalAdministrator,
    Roles.BptAdmin,
  ],
  create: [
    Roles.GlobalAdministrator,
    Roles.BptAdmin,
  ],
  updateProgram: [Roles.GlobalAdministrator],
  updateRoles: [
    Roles.GlobalAdministrator,
    Roles.BptAdmin,
  ],
});

/**
 * Map CRUD actions to role arrays for each section of the website.
 *
 * Note: documentAdmin doesn't need access to the Administration page.
 */
export const ROLE_ACTIONS = Object.freeze({
  administration: {
    viewAllAdminPages: _.union(
      ADMIN_PAGE_ROLES.view,
      ADMIN_LAND_PAGE_ROLES.view,
      ADMIN_RESPONSE_STATEMENT_FIELD_PAGE_ROLES.view,
      ADMIN_CONTACT_PAGE_ROLES.view,
      ADMIN_BPT_PAGE_ROLES.view,
    ),
    genus: ADMIN_PAGE_ROLES,
    species: ADMIN_PAGE_ROLES,
    subspecies: ADMIN_PAGE_ROLES,
    variety: ADMIN_PAGE_ROLES,
    population: ADMIN_PAGE_ROLES,
    range: ADMIN_PAGE_ROLES,
    taxonomicGroup: ADMIN_PAGE_ROLES,
    land: ADMIN_LAND_PAGE_ROLES,
    contact: ADMIN_CONTACT_PAGE_ROLES,
    responseStatementField: ADMIN_RESPONSE_STATEMENT_FIELD_PAGE_ROLES,
    user: ADMIN_USER_PAGE_ROLES,
    bpt: ADMIN_BPT_PAGE_ROLES,
  },
  cosewic: {
    view: [
      Roles.BasicUser,
      Roles.COSEWICEditor,
      Roles.COSEWICAdministrator,
      Roles.ListingEditor,
      Roles.ListingAdministrator,
    ],
    create: [Roles.COSEWICAdministrator, Roles.COSEWICEditor],
    update: [Roles.COSEWICAdministrator, Roles.COSEWICEditor],
    delete: [Roles.COSEWICAdministrator],
    publish: [Roles.COSEWICAdministrator],
    makePermanent: [Roles.COSEWICAdministrator],
    manageRelatedSpecies: [Roles.COSEWICAdministrator],
    changeLog: {
      view: [
        Roles.GlobalAdministrator,
        Roles.BasicUser,
        Roles.COSEWICAdministrator,
        Roles.ListingAdministrator,
        Roles.COSEWICEditor,
        Roles.ListingEditor,
      ],
    },
  },
  listing: {
    view: [
      Roles.BasicUser,
      Roles.ListingEditor,
      Roles.ListingAdministrator,
      Roles.COSEWICEditor,
      Roles.COSEWICAdministrator,
    ],
    create: [Roles.ListingAdministrator, Roles.ListingEditor],
    update: [Roles.ListingAdministrator, Roles.ListingEditor],
    delete: [Roles.ListingAdministrator],
    publish: [Roles.GlobalAdministrator, Roles.ListingAdministrator],
  },
  overview: {
    view: [
      Roles.BasicUser,
      Roles.ListingEditor,
      Roles.ListingAdministrator,
      Roles.COSEWICEditor,
      Roles.COSEWICAdministrator,
    ],
  },
  speciesProfile: {
    view: [Roles.SpeciesProfileEditor],
    create: [Roles.SpeciesProfileEditor],
    update: [Roles.SpeciesProfileEditor],
    delete: [Roles.SpeciesProfileEditor],
  },
  land: {
    view: [
      Roles.BasicUser,
      Roles.ListingEditor,
      Roles.ListingAdministrator,
      Roles.COSEWICEditor,
      Roles.COSEWICAdministrator,
    ],
    create: [Roles.ListingAdministrator, Roles.ListingEditor],
    update: [Roles.ListingAdministrator, Roles.ListingEditor],
    delete: [Roles.ListingAdministrator],
  },
  sector: {
    view: [
      Roles.BasicUser,
      Roles.ListingEditor,
      Roles.ListingAdministrator,
      Roles.COSEWICEditor,
      Roles.COSEWICAdministrator,
    ],
    create: [Roles.ListingAdministrator, Roles.ListingEditor],
    update: [Roles.ListingAdministrator, Roles.ListingEditor],
    delete: [Roles.ListingAdministrator],
  },
  naics: {
    view: [
      Roles.BasicUser,
      Roles.ListingEditor,
      Roles.ListingAdministrator,
      Roles.COSEWICEditor,
      Roles.COSEWICAdministrator,
    ],
    create: [Roles.ListingAdministrator, Roles.ListingEditor],
    update: [Roles.ListingAdministrator, Roles.ListingEditor],
    delete: [Roles.ListingAdministrator],
  },
  atlanticTif: {
    update: [
      Roles.AtlanticTIFEditor,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
    ],
  },
  quebecTif: {
    update: [
      Roles.QuebecTIFEditor,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
    ],
  },
  northernTif: {
    update: [
      Roles.NorthernTIFEditor,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
    ],
  },
  ontarioTif: {
    update: [
      Roles.OntarioTIFEditor,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
    ],
  },
  pacificTif: {
    update: [
      Roles.PacificTIFEditor,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
    ],
  },
  prairieTif: {
    update: [
      Roles.PrairieTIFEditor,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
    ],
  },
  permit: {
    publish: [Roles.GlobalAdministrator, Roles.GlobalPublisher, Roles.PermitPublisher],
    create: [Roles.GlobalAdministrator, Roles.PermitAdmin, Roles.PermitEditor],
    update: [Roles.GlobalAdministrator, Roles.PermitAdmin, Roles.PermitEditor],
    delete: [Roles.GlobalAdministrator, Roles.PermitAdmin],
    finalize: [Roles.GlobalAdministrator, Roles.PermitAdmin],
  },
  responseStatement: {
    // No create: Response statements are generated by the system.
    update: [
      Roles.GlobalAdministrator,
      Roles.DFORSAdministrator,
      Roles.ListingAdministrator,
    ],
    publish: [Roles.GlobalAdministrator, Roles.GlobalPublisher],
    unPublish: [Roles.GlobalAdministrator, Roles.GlobalPublisher],
  },

  documents: {
    create: [Roles.GlobalAdministrator, Roles.documentAdmin],
    update: [Roles.GlobalAdministrator, Roles.documentAdmin],
    delete: [Roles.GlobalAdministrator, Roles.documentAdmin],
    deactivateReactivate: [Roles.GlobalAdministrator, Roles.documentAdmin],
  },

  registry: {
    create: [Roles.GlobalAdministrator, Roles.RegistryAdmin],
    update: [Roles.GlobalAdministrator, Roles.RegistryAdmin],
    delete: [Roles.GlobalAdministrator, Roles.RegistryAdmin],
    publish: [Roles.GlobalAdministrator, Roles.GlobalPublisher],
    unPublish: [Roles.GlobalAdministrator, Roles.GlobalPublisher],
  },

  distributionList: {
    view: [Roles.GlobalAdministrator, Roles.ContactAdministrator],
    create: [Roles.GlobalAdministrator, Roles.ContactAdministrator],
    update: [Roles.GlobalAdministrator, Roles.ContactAdministrator],
    delete: [Roles.GlobalAdministrator, Roles.ContactAdministrator],
  },

  photos: {
    view: [
      Roles.GlobalAdministrator,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
      Roles.GlobalPublisher,
      Roles.DFOPhotoEditor,
    ],
    create: [
      Roles.GlobalAdministrator,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
      Roles.DFOPhotoEditor,
    ],
    update: [
      Roles.GlobalAdministrator,
      Roles.ListingAdministrator,
      Roles.ListingEditor,
      Roles.DFOPhotoEditor,
    ],
    publish: [
      Roles.GlobalAdministrator,
      Roles.ListingAdministrator,
      Roles.GlobalPublisher,
      Roles.DFOPhotoEditor,
    ],
  },
});
