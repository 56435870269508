const globalTranslation = {
  en: {
    sar_dmts: "Species at Risk Data Management Tracking System",
    enter_name_or_id: "Enter a name or ID",
    sort_by: "Sort by",
    sort_order: "Sort order",
    program: "Program",
    what_are_you_looking_for: "What are you looking for?",
    edit_properties: "Edit properties",
    select_property: "Select property",
    none_selected: "None selected",
    apply: "Apply",
    enter_a_name: "Enter a name",
    show_archived_only: "Show archived only",
    search: "Search",
    clear_search: "Clear search",
    add: "Add",
    edit: "Edit",
    deactivate: "Deactivate",
    reactivate: "Reactivate",
    showing: "Showing",
    show: "Show",
    entries: "entries",
    of: "of",
    name: "Name",
    created_by: "Created by",
    creation_date: "Creation date",
    created_date: "Created date",
    updated_by: "Updated by",
    updated_date: "Updated date",
    previous: "Previous",
    next: "Next",
    required: "required",
    create: "Create",
    cancel: "Cancel",
    success: "Success",
    error: "Error",
    warning: "Warning",
    results: "Results",
    filter: "Filter",
    loading: "Loading",
    close: "Close",
    save: "Save",
    close_popup: "Close popup",
    deactivate_confirm: "I confirm that I'd like to deactivate.",
    reactivate_confirm: "I confirm that I'd like to reactivate.",
    archive_confirm: "I confirm that I'd like to archive.",
    unarchive_confirm: "I confirm that I'd like to unarchive.",
    create_and_close: "Create and close popup",
    save_and_close: "Save and close popup",
    reactivate_and_close: "Reactivate and close popup",
    deactivate_and_close: "Deactivate and close popup",
    create_fail: "Failed to create",
    add_fail: "Failed to add",
    update_fail: "Failed to update",
    remove_fail: "Failed to remove",
    reactivate_fail: "Failed to reactivate",
    deactivate_fail: "Failed to deactivate",
    archive_fail: "Failed to archive",
    unarchive_fail: "Failed to unarchive",
    publish_fail: "Failed to publish",
    unpublish_fail: "Failed to unpublish",
    fetch_fail: "Failed to fetch",
    delete_fail: "Failed to delete",
    create_success: "was successfully created",
    update_success: "was successfully updated",
    reactivate_success: "was successfully reactivated",
    deactivate_success: "was successfully deactivated",
    archive_success: "was successfully archived",
    unarchive_success: "was successfully unarchived",
    delete_success: "was successfully deleted",
    unknown_error: "Unknown error",
    english: "English",
    french: "French",
    abbreviation: "Abbreviation",
    enter_some_text: "Enter some text",
    english_only: "english only",
    delete: "Delete",
    please_select_option: "Please select an option",
    select_an_option: "Select an option",
    user_count: "User Count",
    manage: "Manage",
    status: "Status",
    select_all: "Select all",
    confirm: "Confirm",
    i_confirm: "I confirm",
    clear_all: "Clear all",
    note: "Note",
    wildlife_species_note: "Wildlife species note",
    assessment_note: "Assessment note",
    listing_note: "Listing note",
    not_applicable: "Not applicable",
    home: "Home",
    change_type: "Change type",
    ChangeType: "Change type",
    change_reason: "Enter a comment",
    ReasonForChange: "Comment",
    NameChangeLinkToAssessment: "Associated to assessment",
    NotRelatedToAssessment: "Not related to an assessment",
    MINOR: "Minor",
    REPORTED_ON: "Reported on",
    DELETE: "Delete",
    ALL: "All",
    start_date: "Start date",
    end_date: "End date",
    reset: "Reset",
    date: "Date",
    type: "Type",
    empty: "Empty",
    no_results: "No results",
    no_files: "No files",
    loading_error: "Error: failed to fetch",
    field_is_required: "This field is required",
    only_numbers_capital_letters_hyphens:
      "Only numbers, capital letters and hyphens",
    reach_the_max_length:
      "Please enter no more than {{count}} characters (<= {{count}}).",
    name_english: "English name",
    name_french: "French name",
    missing_population: "Missing population",
    archive: "Archive",
    unarchive: "Unarchive",
    administration: "Administration",
    add_to_list: "Add to list",
    remove: "Remove",
    add_name: "Add name",
    state: "State",
    filters: "Filters",
    no_information: "No information",
    new_item: "New Item",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    multi_select_list_no_items_added:
      "This list is empty. Type your search above, then click the Plus button to add the item.",
    no_data: "No data",
    NO_DATA: "No data",
    no_name: "No name",
    new: "New",
    page_title: "Page title",
    view_details: "View details",
    start_typing_here: "Start to type here",
    max_of_number_alphanumeric_chars:
      "maximum of {{number}} alphanumeric characters",
    max_of_number_digits: "maximum of {{number}} digits",
    pending: "Pending",
    leave_dirty_page_warning:
      "Are you sure you want to leave without saving your changes?",
    submit: "Submit",
    programs: "Programs",
    enter_a_note: "Enter a note",
    ALBERTA: "Alberta",
    BRITISH_COLUMBIA: "British Columbia",
    MANITOBA: "Manitoba",
    NEW_BRUNSWICK: "New Brunswick",
    NEWFOUNDLAND_AND_LABRADOR: "Newfoundland and Labrador",
    NORTHWEST_TERRITORIES: "Northwest Territories",
    NOVA_SCOTIA: "Nova Scotia",
    NUNAVUT: "Nunavut",
    ONTARIO: "Ontario",
    PRINCE_EDWARD_ISLAND: "Prince Edward Island",
    QUEBEC: "Québec",
    SASKATCHEWAN: "Saskatchewan",
    YUKON: "Yukon",
    CANADIAN_WATERS: "Canadian Waters",
    ARCTIC_OCEAN: "Arctic Ocean",
    ATLANTIC_OCEAN: "Atlantic Ocean",
    PACIFIC_OCEAN: "Pacific Ocean",

    FEDERAL: "Federal",
    PROVINCE_TERRITORY: "Province / Territory",
    PRIVATE: "Private",
    GLOBAL: "Global",
    create_a_place_name: "Create a place name",
    saving: "Saving",
    select_a_value: "Select a value",
    maximize_table: "Maximize table",
    restore_table: "Restore table",
    ascending: "Ascending",
    descending: "Descending",
    enter_number_between_1_and_number:
      "Please enter a number between 1 and {{number}}.",
    refresh: "Refresh",
    at_least_one_field: "at least one field",
    at_least_one_field_is_required: "At least one field is required",
    at_least_one_language_is_required: "At least one language is required",
    all: "All",
    latest: "Latest",
    schedule_1_on_receipt_info:
      "Prior to October 2022, no data is available for the Schedule 1 status on receipt.",
    export: "Export",
    export_success: "Export success",
    export_failure: "Export failure",
    download_failure: "Download failure",
    minimum: "Minimum",
    maximum: "Maximum",
    from_date: "From date",
    to_date: "To date",
    notice: "Notice",
    accept: "Accept",
    enter_the_url: "Enter the URL",
    both: "Both",
    view_html: "View HTML",
    start: "start",
    end: "end",
    who_are_you_looking_for: "Who are you looking for?",
    enter_a_name_or_email: "Enter a name or email address",
    enter_text: "Enter text",
    at: "at",
    retry: "Retry",
    public: "Public",
    internal: "Internal",
    upload_date: "Updated Date",
    title: "Title",
    job_title: "Job title",
    address: "Address",
    select: "Select",
    from: "From",
    to: "To",
  },

  fr: {
    sar_dmts:
      "Système de gestion et de suivi des données sur les espèces en péril",
    enter_name_or_id: "Entrez un nom ou un ID",
    sort_by: "Trier par",
    sort_order: "Ordre de tri",
    program: "Programme",
    what_are_you_looking_for: "Que cherchez-vous?",
    edit_properties: "Modifier les propriétés",
    select_property: "Sélectionner une propriété",
    none_selected: "Aucune sélection",
    apply: "Enregistrer",
    enter_a_name: "Entrez un nom",
    show_archived_only: "Afficher seulement les archives",
    search: "Rechercher",
    clear_search: "Effacer la recherche",
    add: "Ajouter",
    edit: "Modifier",
    deactivate: "Désactiver",
    reactivate: "Réactiver",
    showing: "Affichage des",
    show: "Afficher",
    entries: "entrées",
    of: "de",
    name: "Nom",
    created_by: "Créé par",
    creation_date: "Date de création",
    created_date: "Date de création",
    updated_by: "Mis à jour par",
    updated_date: "Date de mise à jour",
    previous: "Précédent",
    next: "Suivant",
    required: "obligatoire",
    create: "Créer",
    cancel: "Annuler",
    success: "Succès",
    created_success: "a été créé avec succès",
    error: "Erreur",
    warning: "Avertissement",
    results: "Résultats",
    filter: "Filtre",
    loading: "Chargement",
    close: "Fermer",
    save: "Enregistrer",
    close_popup: "Fermer la fenêtre contextuelle",
    deactivate_confirm: "Je confirme que je souhaite désactiver",
    reactivate_confirm: "Je confirme que je souhaite réactiver",
    archive_confirm: "Je confirme que je souhaite archiver.",
    unarchive_confirm: "Je confirme que je souhaite désarchiver.",
    create_and_close: "Créer et fermer une fenêtre contextuelle",
    save_and_close: "Enregistrer et fermer la fenêtre contextuelle",
    reactivate_and_close: "Réactiver et fermer la fenêtre contextuelle",
    deactivate_and_close: "Désactiver et fermer la fenêtre contextuelle",
    create_fail: "Échec de la création",
    add_fail: "Échec de l'ajout",
    update_fail: "Échec de la mise à jour",
    remove_fail: "Échec de la suppression",
    reactivate_fail: "Échec de la réactivation",
    deactivate_fail: "Échec de la désactivation",
    archive_fail: "Échec de l'archivage",
    unarchive_fail: "Échec du désarchivage",
    publish_fail: "Échec de la publication",
    unpublish_fail: "Échec du retirement la publication",
    fetch_fail: "Échec de la récupération",
    delete_fail: "Échec de la suppression",
    create_success: "a été créé avec succès",
    update_success: "a été mis à jour avec succès",
    reactivate_success: "a été réactivé avec succès",
    deactivate_success: "a été désactivé avec succès",
    archive_success: "a été archivé avec succès",
    unarchive_success: "a été désarchivé avec succès",
    delete_success: "was successfully deleted",
    unknown_error: "Erreur inconnue",
    english: "Anglais",
    french: "Français",
    abbreviation: "Abréviation",
    enter_some_text: "Entrez du texte",
    english_only: "en anglais uniquement",
    delete: "Supprimer",
    please_select_option: "Veuillez sélectionner une option",
    select_an_option: "Sélectionnez une option",
    user_count: "Nombre d'utilisateurs",
    manage: "Gérer",
    status: "Statut",
    select_all: "Sélectionner tout",
    confirm: "Confirmer",
    i_confirm: "Je confirme",
    clear_all: "Effacer tout",
    note: "Remarque",
    wildlife_species_note: "Note sur l’espèce sauvage",
    assessment_note: "Note sur l’évaluation",
    listing_note: "Note sur l’inscription",
    not_applicable: "Sans objet",
    home: "Accueil",
    change_type: "Type de changement",
    ChangeType: "Type de changement",
    change_reason: "Entrez un commentaire",
    ReasonForChange: "Commentaire",
    NameChangeLinkToAssessment: "Lié à une évaluation",
    NotRelatedToAssessment: "Non lié à une évaluation",
    MINOR: "Mineur",
    REPORTED_ON: "Rapporté le",
    DELETE: "Supprimer",
    ALL: "Tout",
    start_date: "Date de début",
    end_date: "Date de fin",
    reset: "Réinitialiser",
    date: "Date",
    type: "Type",
    empty: "Vide",
    no_results: "Aucun résultat",
    no_files: "Aucun fichier",
    loading_error: "Erreur : Échec de la récupération",
    field_is_required: "Ce champ est obligatoire",
    only_numbers_capital_letters_hyphens:
      "Uniquement des chiffres, des majuscules et des traits d'union",
    reach_the_max_length:
      "Veuillez ne pas saisir plus de {{count}} caractères (<= {{count}}).",
    name_english: "Nom anglais",
    name_french: "Nom français",
    missing_population: "Population manquante",
    archive: "Archiver",
    unarchive: "Désarchiver",
    administration: "Administration",
    add_to_list: "Ajouter à la liste",
    remove: "Retirer",
    add_name: "Ajouter un nom",
    state: "État",
    filters: "Filtres",
    no_information: "Aucune information",
    new_item: "Nouvel item",
    ACTIVE: "Actif",
    INACTIVE: "Inactif",
    multi_select_list_no_items_added:
      "Cette liste est vide. Tapez votre recherche ci-dessus, puis cliquez sur le bouton Plus pour ajouter l'article.",
    no_data: "Aucune donnée",
    NO_DATA: "Aucune donnée",
    no_name: "Pas de nom",
    new: "Nouveau",
    page_title: "Page titre",
    view_details: "Voir les détails",
    start_typing_here: "Commencez à taper ici",
    max_of_number_alphanumeric_chars:
      "maximum de {{nombre}} caractères alphanumériques",
    max_of_number_digits: "maximum de {{number}} chiffres",
    pending: "En attente",
    leave_dirty_page_warning:
      "Êtes-vous certain de vouloir quitter sans enregistrer vos modifications ?",
    submit: "Soumettre",
    programs: "Programmes",
    enter_a_note: "Entrez une note",
    ALBERTA: "Alberta",
    BRITISH_COLUMBIA: "Colombie-Britannique",
    MANITOBA: "Manitoba",
    NEW_BRUNSWICK: "Nouveau-Brunswick",
    NEWFOUNDLAND_AND_LABRADOR: "Terre-Neuve-et-Labrador",
    NORTHWEST_TERRITORIES: "Territoires du Nord-Ouest",
    NOVA_SCOTIA: "Nouvelle-Écosse",
    NUNAVUT: "Nunavut",
    ONTARIO: "Ontario",
    PRINCE_EDWARD_ISLAND: "Île-du-Prince-Édouard",
    QUEBEC: "Québec",
    SASKATCHEWAN: "Saskatchewan",
    YUKON: "Yukon",
    CANADIAN_WATERS: "Eaux Canadiennes",
    ARCTIC_OCEAN: "Océan Arctique",
    ATLANTIC_OCEAN: "Océan Atlantique",
    PACIFIC_OCEAN: "Océan Pacifique",

    FEDERAL: "Fédéral",
    PROVINCE_TERRITORY: "Province ou territoire",
    PRIVATE: "Privé",
    GLOBAL: "Global",
    create_a_place_name: "Créer un nom de lieu",
    saving: "Enregistrement en cours",
    select_a_value: "Sélectionnez une valeur",
    maximize_table: "Agrandir le tableau",
    restore_table: "Rétablir le tableau",
    ascending: "Ascendant",
    descending: "Descendant",
    enter_number_between_1_and_number:
      "Veuillez entrer un nombre entre 1 et {{number}}.",
    refresh: "Actualiser",
    at_least_one_field: "au moins un champ",
    at_least_one_field_is_required: "Au moins un champ est obligatoire",
    at_least_one_language_is_required: "Au moins une langue est requise",
    all: "Tout",
    latest: "Dernier",
    schedule_1_on_receipt_info:
      "Avant octobre 2022, il n’y avait aucune donnée sur le statut à l’Annexe 1 à la réception.",
    export: "Exporter",
    export_success: "Succès de l’exportation",
    export_failure: "Défaut d'exportation",
    download_failure: "Échec de l’exportation",
    minimum: "Minimum",
    maximum: "Maximum",
    from_date: "Date de début",
    to_date: "Date de fin",
    notice: "Avis",
    accept: "Accepter",
    enter_the_url: "Entrez l'URL ",
    both: "Les deux",
    view_html: "Voir HTML",
    start: "début",
    end: "fin",
    who_are_you_looking_for: "Qui recherchez-vous ?",
    enter_a_name_or_email: "Saisir un nom ou un adresse courriel",
    enter_text: "[FR]Enter text",
    at: "à",
    retry: "Réessayer",
    public: "Public",
    internal: "Interne",
    upload_date: "Date de mise à jour",
    title: "Titre",
    job_title: "Titre du poste",
    address: "Adresse",
    select: "Select [Fr]",
    from: "De",
    to: "À",
  },
};

export default globalTranslation;
