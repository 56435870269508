import { useQuery } from "@apollo/client";
import {
  BilingualAbbreviationLookupTextInput,
  CosewicToReassessLookupDocument,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import LoadingIndicator from "../../../atoms/LoadingIndicator";

export interface CosewicToReassessDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue: any; // TODO:TYPES:any
  onChange: (value: BilingualAbbreviationLookupTextInput) => void;
}

export const CosewicToReassessDropdown = React.forwardRef<
  HTMLSelectElement,
  CosewicToReassessDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(CosewicToReassessLookupDocument);
  const { t, i18n } = useTranslation();

  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const value = data?.listingWsProcessCosewicToReassess?.find(
      (x) => x?.id === id
    );

    if (!value) {
      console.error(
        `CosewicToReassessDropdown couldn't find a value for ID ${id}`
      );
      return;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control"
      value={props?.defaultValue?.id}
      ref={ref}
      {...props}
      data-testid={props.id}
      onChange={onChange}
    >
      <option value={""}>
        {t(props.placeholder ?? "please_select_option")}
      </option>
      {data?.listingWsProcessCosewicToReassess?.map((x) => {
        return (
          <option value={x?.id ?? "error - missing id"} key={x?.id}>
            {bilingualLookupTextNameForLanguage(i18n.language, x)}
          </option>
        );
      })}
    </select>
  );
});

export interface CosewicToReassessDropdownWithControllerProps<TFieldValues>
  extends Omit<CosewicToReassessDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const CosewicToReassessDropdownWithController = <TFieldValues,>(
  props: CosewicToReassessDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <CosewicToReassessDropdown
          id={props.id}
          placeholder={props.placeholder}
          onChange={onChange}
          defaultValue={value}
        />
      )}
    />
  );
};

export default CosewicToReassessDropdown;
