import placeholderImage from "assets/svg/placeholder.svg";
import { getApiImageManagementServiceUrl } from "azure/environment";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { PhotoType } from "generated/gql-types";
import React from "react";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface PhotoImageProps {
  name: string;
  size?: PhotoType;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
}

export const PhotoImage: React.FC<PhotoImageProps> = (props) => {
  const {
    name,
    size = PhotoType.Thumbnail,
    className = "",
    style,
    alt = "",
  } = props;

  if (isNullOrEmpty(name)) {
    <img src={placeholderImage} className={className} alt={alt} />;
  }

  const [imagesString, setImagesString] = React.useState<any>("");

  const getBase64Image = async (res: { blob: () => any }) => {
    const blob = await res.blob();
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    return reader.result;
  };

  React.useEffect(() => {
    async function fetchData() {
      const token = await getUserAccessToken();

      const baseUrl = getApiImageManagementServiceUrl("7096") + `image/${name}`;
      const url = new URL(baseUrl);
      url.searchParams.append("size", size);

      fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // 204 = No content
          if (res.status === 204 || res.status < 200 || res.status >= 300) {
            throw new Error(res.statusText);
          }
          return res;
        })
        .then(getBase64Image)
        .then((imgString) => setImagesString(imgString))
        .catch((e) => {
          console.log("Failed to fetch photo image");
          setImagesString("");
        });
    }
    fetchData();
  }, []);

  return (
    <img
      src={!isNullOrEmpty(imagesString) ? imagesString : placeholderImage}
      className={className}
      style={style}
      alt={alt}
    />
  );
};
