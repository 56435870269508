import Alert, { AlertTypes } from "components/atoms/Alert";
import SectionCard from "components/atoms/SectionCard";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { Roles } from "features/auth/roles";
import { useUserAuthorization } from "features/auth/useUserAuthorization";
import { ChangeTracking } from "features/changeTracking";
import {
  Assessment,
  AssessmentReportType,
  AssessmentState,
  AssessmentStatusChange,
  AssessmentType,
  BilingualRichTextInput,
  ChangeMetaDataInput,
  RichTextInput,
  WsStatus,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { FullHTMLEditorWithController } from "../../../FullHTMLEditor";
import AssessmentBasicInfoCard from "./AssessmentBasicInfoCard";
import { processAssessmentEditFormData } from "./assessmentFormSubmitMapper";

export interface AssessmentFormProps {
  assessment?: Assessment;
  defaultValues: AssessmentFormFields;
  areRelatedWildlifeSpeciesStillDraft: boolean;
  showDateSentToMinister: boolean;
  onSubmit: any;
}

export interface AssessmentFormFields {
  date: string;
  type: AssessmentType;
  reportType?: AssessmentReportType;
  order: string;
  dateSentToMinister: string;
  // statusAndCriteria
  statusAndCriteria_status?: WsStatus;
  statusAndCriteria_statusComment: DefinitelyGQL<BilingualRichTextInput>;
  statusAndCriteria_statusChange?: AssessmentStatusChange;
  statusAndCriteria_statusCriteria: DefinitelyGQL<BilingualRichTextInput>;
  statusAndCriteria_applicabilityOfCriteriaA: RichTextInput;
  statusAndCriteria_applicabilityOfCriteriaB: RichTextInput;
  statusAndCriteria_applicabilityOfCriteriaC: RichTextInput;
  statusAndCriteria_applicabilityOfCriteriaD: RichTextInput;
  statusAndCriteria_applicabilityOfCriteriaE: RichTextInput;
  // designation
  designation_historyStatus: DefinitelyGQL<BilingualRichTextInput>;
  designation_reason: DefinitelyGQL<BilingualRichTextInput>;
}

export interface AssessmentFormChangeTracking {
  dateChangeMetaData: DefinitelyGQL<ChangeMetaDataInput>;

  // statusAndCriteria
  statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_English: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_French: DefinitelyGQL<ChangeMetaDataInput>;

  statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataA: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataB: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataC: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataD: DefinitelyGQL<ChangeMetaDataInput>;
  statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataE: DefinitelyGQL<ChangeMetaDataInput>;

  // designation
  designationChangeMetaData_historyStatusChangeMetaData_English: DefinitelyGQL<ChangeMetaDataInput>;
  designationChangeMetaData_historyStatusChangeMetaData_French: DefinitelyGQL<ChangeMetaDataInput>;
  designationChangeMetaData_reasonChangeMetaData_English: DefinitelyGQL<ChangeMetaDataInput>;
  designationChangeMetaData_reasonChangeMetaData_French: DefinitelyGQL<ChangeMetaDataInput>;
}

export interface AssessmentFormOutput
  extends AssessmentFormFields,
    AssessmentFormChangeTracking {}

const AssessmentForm: React.FC<AssessmentFormProps> = (props) => {
  const {
    defaultValues,
    areRelatedWildlifeSpeciesStillDraft,
    showDateSentToMinister = false,
    assessment,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { cosewicWsId, assessmentId } = params as any;

  const form = useForm<AssessmentFormFields & AssessmentFormChangeTracking>({
    defaultValues,
  });
  const { handleSubmit, register, formState, watch, control } = form;
  const { dirtyFields, isDirty } = formState;
  const { checkUserIsAuthorized } = useUserAuthorization();

  const onSubmit: SubmitHandler<Partial<AssessmentFormOutput>> = async (
    formData
  ) => {
    const processedFormData = await processAssessmentEditFormData(
      formData,
      dirtyFields,
      defaultValues,
      assessment?.stage
    );

    props.onSubmit(processedFormData, dirtyFields);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: CosewicPathHelpers.AssessmentProfile(
        cosewicWsId ?? "error",
        assessmentId ?? "error"
      ),
    });
  };

  // Disable the COSEWIC WS status and status change dropdowns, if
  // - there ARE children and ANY are non-permanent. (Task 8296), or
  // - assessment is Published and user is not Global Admin. (Bug 7755)
  const disableStatusAndStatusChange = React.useMemo(() => {
    if (areRelatedWildlifeSpeciesStillDraft) {
      return true;
    }

    const isAdmin = checkUserIsAuthorized([Roles.GlobalAdministrator]);

    if (assessment?.state === AssessmentState.Published && !isAdmin) {
      return true;
    }
    return false;
  }, [
    areRelatedWildlifeSpeciesStillDraft,
    assessment?.state,
    checkUserIsAuthorized,
  ]);

  // Show or hide the Extinct and Extirpated comments (US 4745).
  const showExtinctExtirpatedComments = React.useMemo(() => {
    if (disableStatusAndStatusChange) {
      return (
        defaultValues.statusAndCriteria_status === WsStatus.Extinct ||
        defaultValues.statusAndCriteria_status === WsStatus.Extirpated
      );
    }

    return (
      watch("statusAndCriteria_status") === WsStatus.Extinct ||
      watch("statusAndCriteria_status") === WsStatus.Extirpated
    );
  }, [
    disableStatusAndStatusChange,
    defaultValues.statusAndCriteria_status,
    watch("statusAndCriteria_status"),
  ]);

  const location = useLocation();
  const editLinkClicked = location.state;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* ------------ Assessment Information Section ----------------- */}
        <AssessmentBasicInfoCard
          assessment={assessment}
          open={
            editLinkClicked === t("assessment_information") ||
            editLinkClicked == null
          }
          defaultValues={defaultValues}
          showDateSentToMinister={showDateSentToMinister}
        />

        {/*--------------------- Wildlife Species Status and Criteria Section ---------------------*/}
        <SectionCard
          collapsible
          open={
            editLinkClicked === t("wildlife_species_status_and_criteria") ||
            editLinkClicked == null
          }
          header={
            <h2 id="statusAndCriteria">
              {t("wildlife_species_status_and_criteria")}
            </h2>
          }
        >
          <div className="flex-col gap-md">
            {areRelatedWildlifeSpeciesStillDraft ? (
              <Alert
                type={AlertTypes.INFO}
                title={t("related_ws_are_still_draft_alert_title")}
                content={t("related_ws_are_still_draft_alert_content")}
              />
            ) : null}

            {/* COSEWIC WS Status Dropdown */}
            <div>
              <label htmlFor="sel_wsStatus">{t("cosewic_ws_status")}</label>
              <select
                id="sel_wsStatus"
                className="form-control"
                disabled={disableStatusAndStatusChange}
                {...register("statusAndCriteria_status")}
              >
                <option selected value={WsStatus.NotInitialized}>
                  {t("please_select_option")}
                </option>
                <option value={WsStatus.NoStatus}>
                  {t(WsStatus.NoStatus)}
                </option>
                <option value={WsStatus.DataDeficient}>
                  {t(WsStatus.DataDeficient)}
                </option>
                <option value={WsStatus.DeferredRejectWithdrawn}>
                  {t(WsStatus.DeferredRejectWithdrawn)}
                </option>
                <option value={WsStatus.Endangered}>
                  {t(WsStatus.Endangered)}
                </option>
                <option value={WsStatus.Extinct}>{t(WsStatus.Extinct)}</option>
                <option value={WsStatus.Extirpated}>
                  {t(WsStatus.Extirpated)}
                </option>
                <option value={WsStatus.NonActive}>
                  {t(WsStatus.NonActive)}
                </option>
                <option value={WsStatus.SpecialConcern}>
                  {t(WsStatus.SpecialConcern)}
                </option>
                <option value={WsStatus.Threatened}>
                  {t(WsStatus.Threatened)}
                </option>
                <option value={WsStatus.NotAtRisk}>
                  {t(WsStatus.NotAtRisk)}
                </option>
              </select>
            </div>

            {/* Status and Criteria -> StatusComment English section */}
            {showExtinctExtirpatedComments && (
              <>
                <div>
                  <label>
                    {t("extinct_or_extirpated_since")}, {t("english")}
                  </label>

                  <FullHTMLEditorWithController
                    control={control}
                    name="statusAndCriteria_statusComment.english.text"
                    id="statusAndCriteria_statusComment.english.text"
                    defaultValue={
                      defaultValues.statusAndCriteria_statusComment.english
                        ?.text ?? ""
                    }
                    placeholder={t("change_reason")}
                  />

                  <ChangeTracking
                    control={control}
                    changeMetaDataName="statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English"
                    cachedValue={
                      defaultValues.statusAndCriteria_statusComment.english
                        ?.text
                    }
                    trackedValue={watch(
                      "statusAndCriteria_statusComment.english.text"
                    )}
                  />
                </div>

                {/* Status and Criteria -> StatusComment French section */}
                <div>
                  <label>
                    {t("extinct_or_extirpated_since")}, {t("french")}
                  </label>

                  <FullHTMLEditorWithController
                    control={control}
                    name="statusAndCriteria_statusComment.french.text"
                    id="statusAndCriteria_statusComment.french.text"
                    defaultValue={
                      defaultValues.statusAndCriteria_statusComment.french
                        ?.text ?? ""
                    }
                    placeholder={t("change_reason")}
                  />

                  <ChangeTracking
                    control={control}
                    changeMetaDataName="statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French"
                    cachedValue={
                      defaultValues.statusAndCriteria_statusComment.french?.text
                    }
                    trackedValue={watch(
                      "statusAndCriteria_statusComment.french.text"
                    )}
                  />
                </div>
              </>
            )}

            {/* Status Change Dropdown */}
            <div>
              <label htmlFor="sel_wsStatusChange">{t("status_change")}</label>
              <select
                id="sel_wsStatusChange"
                className="form-control"
                disabled={disableStatusAndStatusChange}
                {...register("statusAndCriteria_statusChange")}
              >
                <option selected value={AssessmentStatusChange.NotInitialized}>
                  {t("please_select_option")}
                </option>
                <option value={AssessmentStatusChange.NotApplicable}>
                  {t(AssessmentStatusChange.NotApplicable)}
                </option>
                <option value={AssessmentStatusChange.Changed}>
                  {t(AssessmentStatusChange.Changed)}
                </option>
                <option value={AssessmentStatusChange.Deactivated}>
                  {t(AssessmentStatusChange.Deactivated)}
                </option>
                <option value={AssessmentStatusChange.InHigherRiskCategory}>
                  {t(AssessmentStatusChange.InHigherRiskCategory)}
                </option>
                <option value={AssessmentStatusChange.InLowerRiskCategory}>
                  {t(AssessmentStatusChange.InLowerRiskCategory)}
                </option>
                <option value={AssessmentStatusChange.New}>
                  {t(AssessmentStatusChange.New)}
                </option>
                <option value={AssessmentStatusChange.NoChange}>
                  {t(AssessmentStatusChange.NoChange)}
                </option>
                <option value={AssessmentStatusChange.NoLongerAtRisk}>
                  {t(AssessmentStatusChange.NoLongerAtRisk)}
                </option>
                <option value={AssessmentStatusChange.ReassignedAndReassessed}>
                  {t(AssessmentStatusChange.ReassignedAndReassessed)}
                </option>
              </select>
            </div>

            {/* Status and Criteria -> Status Criteria English section */}
            <div>
              <label>{t("status_criteria_english")}</label>
              <FullHTMLEditorWithController
                control={control}
                name="statusAndCriteria_statusCriteria.english.text"
                id="statusAndCriteria_statusCriteria.english.text"
                defaultValue={
                  defaultValues.statusAndCriteria_statusCriteria.english
                    ?.text ?? ""
                }
                placeholder={t("change_reason")}
              />

              <ChangeTracking
                control={control}
                changeMetaDataName="statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_English"
                cachedValue={
                  defaultValues.statusAndCriteria_statusCriteria.english?.text
                }
                trackedValue={watch(
                  "statusAndCriteria_statusCriteria.english.text"
                )}
              />
            </div>

            {/* Status and Criteria -> Status Criteria French section */}
            <div>
              <label>{t("status_criteria_french")}</label>
              <FullHTMLEditorWithController
                control={control}
                name="statusAndCriteria_statusCriteria.french.text"
                id="statusAndCriteria_statusCriteria.french.text"
                defaultValue={
                  defaultValues.statusAndCriteria_statusCriteria.french?.text ??
                  ""
                }
                placeholder={t("change_reason")}
              />

              <ChangeTracking
                control={control}
                changeMetaDataName="statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_French"
                cachedValue={
                  defaultValues.statusAndCriteria_statusCriteria.french?.text
                }
                trackedValue={watch(
                  "statusAndCriteria_statusCriteria.french.text"
                )}
              />
            </div>

            {/* Applicability of Criteria Section */}
            <fieldset className="fieldset-grp">
              <legend>
                <span className="field-name">
                  {t("applicability_of_criteria")}
                </span>
              </legend>
              <div className="inline-flex-col gap-md full-width">
                <div className="flex">
                  <label
                    className="pr-3"
                    htmlFor="statusAndCriteria_applicabilityOfCriteriaA.text"
                  >
                    <div className="circle-letter">A</div>
                  </label>
                  <FullHTMLEditorWithController
                    control={control}
                    defaultValue={
                      defaultValues.statusAndCriteria_applicabilityOfCriteriaA
                        .text ?? ""
                    }
                    id="statusAndCriteria_applicabilityOfCriteriaA.text"
                    name="statusAndCriteria_applicabilityOfCriteriaA.text"
                    placeholder={t("change_reason")}
                    className="full-width height-fit-content"
                  />
                </div>

                <ChangeTracking
                  control={control}
                  changeMetaDataName="statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataA"
                  cachedValue={
                    defaultValues.statusAndCriteria_applicabilityOfCriteriaA
                      .text
                  }
                  trackedValue={watch(
                    "statusAndCriteria_applicabilityOfCriteriaA.text"
                  )}
                />

                {/**/}

                <div className="flex">
                  <label
                    className="pr-3"
                    htmlFor="statusAndCriteria_applicabilityOfCriteriaB.text"
                  >
                    <div className="circle-letter">B</div>
                  </label>
                  <FullHTMLEditorWithController
                    control={control}
                    defaultValue={
                      defaultValues.statusAndCriteria_applicabilityOfCriteriaB
                        .text ?? ""
                    }
                    id="statusAndCriteria_applicabilityOfCriteriaB.text"
                    name="statusAndCriteria_applicabilityOfCriteriaB.text"
                    placeholder={t("change_reason")}
                    className="full-width height-fit-content"
                  />
                </div>

                <ChangeTracking
                  control={control}
                  changeMetaDataName="statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataB"
                  cachedValue={
                    defaultValues.statusAndCriteria_applicabilityOfCriteriaB
                      .text
                  }
                  trackedValue={watch(
                    "statusAndCriteria_applicabilityOfCriteriaB.text"
                  )}
                />
                {/**/}

                <div className="flex">
                  <label
                    className="pr-3"
                    htmlFor="statusAndCriteria_applicabilityOfCriteriaC.text"
                  >
                    <div className="circle-letter">C</div>
                  </label>
                  <FullHTMLEditorWithController
                    control={control}
                    defaultValue={
                      defaultValues.statusAndCriteria_applicabilityOfCriteriaC
                        .text ?? ""
                    }
                    id="statusAndCriteria_applicabilityOfCriteriaC.text"
                    name="statusAndCriteria_applicabilityOfCriteriaC.text"
                    placeholder={t("change_reason")}
                    className="full-width height-fit-content"
                  />
                </div>

                <ChangeTracking
                  control={control}
                  changeMetaDataName="statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataC"
                  cachedValue={
                    defaultValues.statusAndCriteria_applicabilityOfCriteriaC
                      .text
                  }
                  trackedValue={watch(
                    "statusAndCriteria_applicabilityOfCriteriaC.text"
                  )}
                />
                {/**/}

                <div className="flex">
                  <label
                    className="pr-3"
                    htmlFor="statusAndCriteria_applicabilityOfCriteriaD.text"
                  >
                    <div className="circle-letter">D</div>
                  </label>
                  <FullHTMLEditorWithController
                    control={control}
                    defaultValue={
                      defaultValues.statusAndCriteria_applicabilityOfCriteriaD
                        .text ?? ""
                    }
                    id="statusAndCriteria_applicabilityOfCriteriaD.text"
                    name="statusAndCriteria_applicabilityOfCriteriaD.text"
                    placeholder={t("change_reason")}
                    className="full-width height-fit-content"
                  />
                </div>

                <ChangeTracking
                  control={control}
                  changeMetaDataName="statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataD"
                  cachedValue={
                    defaultValues.statusAndCriteria_applicabilityOfCriteriaD
                      .text
                  }
                  trackedValue={watch(
                    "statusAndCriteria_applicabilityOfCriteriaD.text"
                  )}
                />

                {/**/}

                <div className="flex">
                  <label
                    className="pr-3"
                    htmlFor="statusAndCriteria_applicabilityOfCriteriaE.text"
                  >
                    <div className="circle-letter">E</div>
                  </label>
                  <FullHTMLEditorWithController
                    control={control}
                    defaultValue={
                      defaultValues.statusAndCriteria_applicabilityOfCriteriaE
                        .text ?? ""
                    }
                    id="statusAndCriteria_applicabilityOfCriteriaE.text"
                    name="statusAndCriteria_applicabilityOfCriteriaE.text"
                    placeholder={t("change_reason")}
                    className="full-width height-fit-content"
                  />
                </div>

                <ChangeTracking
                  control={control}
                  changeMetaDataName="statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataE"
                  cachedValue={
                    defaultValues.statusAndCriteria_applicabilityOfCriteriaE
                      .text
                  }
                  trackedValue={watch(
                    "statusAndCriteria_applicabilityOfCriteriaE.text"
                  )}
                />
              </div>
            </fieldset>
          </div>
        </SectionCard>

        {/* ---------------- Designation Section ------------------ */}

        <SectionCard
          collapsible
          open={
            editLinkClicked === t("history_status_designation") ||
            editLinkClicked == null
          }
          header={<h2 id="designation">{t("history_status_designation")}</h2>}
        >
          <div className="flex-col gap-md">
            {/* Designation History English section*/}
            <div>
              <label>{t("designation_history_english")}</label>
              <FullHTMLEditorWithController
                control={control}
                name="designation_historyStatus.english.text"
                id="designation_historyStatus.english.text"
                defaultValue={
                  defaultValues.designation_historyStatus.english?.text ?? ""
                }
                placeholder={t("change_reason")}
              />

              <ChangeTracking
                control={control}
                changeMetaDataName="designationChangeMetaData_historyStatusChangeMetaData_English"
                cachedValue={
                  defaultValues.designation_historyStatus.english?.text
                }
                trackedValue={watch("designation_historyStatus.english.text")}
              />
            </div>

            <div>
              {/*   Designation History French section */}
              <label>{t("designation_history_french")}</label>
              <FullHTMLEditorWithController
                control={control}
                defaultValue={
                  defaultValues.designation_historyStatus.french?.text ?? ""
                }
                name="designation_historyStatus.french.text"
                id="designation_historyStatus.french.text"
                placeholder={t("change_reason")}
              />

              <ChangeTracking
                control={control}
                changeMetaDataName="designationChangeMetaData_historyStatusChangeMetaData_French"
                cachedValue={
                  defaultValues.designation_historyStatus.french?.text
                }
                trackedValue={watch("designation_historyStatus.french.text")}
              />
            </div>

            {/* Designation Reason English section */}
            <div>
              <label>{t("designation_reason_english")}</label>
              <FullHTMLEditorWithController
                control={control}
                name="designation_reason.english.text"
                id="designation_reason.english.text"
                defaultValue={
                  defaultValues.designation_reason.english?.text ?? ""
                }
                placeholder={t("change_reason")}
              />

              <ChangeTracking
                control={control}
                changeMetaDataName="designationChangeMetaData_reasonChangeMetaData_English"
                cachedValue={defaultValues.designation_reason.english?.text}
                trackedValue={watch("designation_reason.english.text")}
              />
            </div>

            {/* Designation Reason French section */}
            <div>
              <label>{t("designation_reason_french")}</label>
              <FullHTMLEditorWithController
                control={control}
                name="designation_reason.french.text"
                id="designation_reason.french.text"
                defaultValue={
                  defaultValues.designation_reason.french?.text ?? ""
                }
                placeholder={t("change_reason")}
              />

              <ChangeTracking
                control={control}
                changeMetaDataName="designationChangeMetaData_reasonChangeMetaData_French"
                cachedValue={defaultValues.designation_reason.french?.text}
                trackedValue={watch("designation_reason.french.text")}
              />
            </div>
          </div>
        </SectionCard>

        <FormButtons isDirty={isDirty} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};

export default AssessmentForm;
