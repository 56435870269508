import * as React from "react";
import { AlertTypes } from "components/atoms/Alert";
import { useLocation } from "react-router-dom";

interface IGlobalAlertContext {
  visible: boolean;
  alertType: AlertTypes;
  alertInfo: AlertInfo | null | undefined;
  showInfo: (alertInfo: AlertInfo) => void;
  showWarning: (alertInfo: AlertInfo) => void;
  showSuccess: (alertInfo: AlertInfo) => void;
  showError: (alertInfo: AlertInfo) => void;
  showDanger: (alertInfo: AlertInfo) => void;
  clear: () => void;
}

const GlobalAlertContext = React.createContext<IGlobalAlertContext | undefined>(
  undefined
);

export interface AlertInfo {
  title?: string;
  message?: string;
  error?: string;
  timeOut?: number;
}

const GlobalAlertProvider: React.FC = ({ children }) => {
  const [alertType, setAlertType] = React.useState<AlertTypes>(AlertTypes.INFO);
  const [alertInfo, setAlertInfo] = React.useState<
    AlertInfo | null | undefined
  >(null);

  const location = useLocation();
  const [alertSourceRoute, setAlertSourceRoute] = React.useState<string | null>(
    null
  );
  const [firstVisitedRoute, setFirstVisitedRoute] = React.useState<
    string | null
  >(null);

  const clear = () => {
    setAlertType(AlertTypes.INFO);
    setAlertInfo(null);

    setAlertSourceRoute(null);
    setFirstVisitedRoute(null);
  };

  const cacheSourceRoute = () => {
    setAlertSourceRoute(location.pathname);
  };

  // useEffect to clear the alert once the user visits a new page.
  // To do this, we track:
  // 1. the URL where the alert was first created (named alertSourceRoute),
  // 2. the first URL the user visits after the alertSourceRoute (named firstVisitedRoute).
  React.useEffect(() => {
    // No alert has been shown; do nothing
    if (alertSourceRoute == null) return;

    // An alert has been set but we've not cached the first visited page yet; cache it
    if (firstVisitedRoute == null) {
      setFirstVisitedRoute(location.pathname);
      return;
    }

    // We're on the source route, no change; do nothing
    if (location.pathname === alertSourceRoute) return;

    // useEffect was triggered, but the route hasn't changed; do nothing
    if (location.pathname === firstVisitedRoute) return;

    // Else - we're not on the source page and the route has changed more than once; clear the alert
    clear();
  }, [location.pathname, alertSourceRoute, firstVisitedRoute]);


  const showInfo = (alertInfo: AlertInfo) => {
    setAlertInfo(alertInfo);
    setAlertType(AlertTypes.INFO);

    cacheSourceRoute();
  };

  const showWarning = (alertInfo: AlertInfo) => {
    setAlertInfo(alertInfo);
    setAlertType(AlertTypes.WARNING);

    cacheSourceRoute();
  };

  const showSuccess = (alertInfo: AlertInfo) => {
    setAlertInfo(alertInfo);
    setAlertType(AlertTypes.SUCCESS);

    cacheSourceRoute();
  };

  const showError = (alertInfo: AlertInfo) => {
    setAlertInfo(alertInfo);
    setAlertType(AlertTypes.DANGER);

    cacheSourceRoute();
  };

  const showDanger = (alertInfo: AlertInfo) => {
    setAlertInfo(alertInfo);
    setAlertType(AlertTypes.DANGER);

    cacheSourceRoute();
  };

  const api = {
    visible: alertInfo != null,
    alertType,
    alertInfo,
    showInfo,
    showWarning,
    showSuccess,
    showError,
    showDanger,
    clear,
  };

  return (
    <GlobalAlertContext.Provider value={api}>
      {children}
    </GlobalAlertContext.Provider>
  );
};

const useGlobalAlertContext = () => {
  const context = React.useContext(GlobalAlertContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalAlertContext must be used within a GlobalAlertProvider"
    );
  }

  return context;
};

export { GlobalAlertProvider, useGlobalAlertContext };
