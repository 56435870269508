import FormButtons from "components/molecules/FormButtons/FormButtons";
import { CustomContact } from "components/organisms/contacts/types";
import {
  BilingualRichText,
  BilingualUriReference,
  DocumentRef,
} from "generated/gql-types";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "util/forms";
import { RegistryPageInformationCard } from "./RegistryPageInformationCard";
import { setNullForEmptyFields } from "./utils";

export interface RegistryFormProps {
  initialValues: Partial<RegistryPageFormFields>;
  onSubmit: SubmitHandler<Partial<RegistryPageFormFields>>;
  onCancel: () => void;
}

export const RegistryForm: React.FC<RegistryFormProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  // const history = useHistory();
  // const { params } = useRouteMatch();
  // const { documentId } = params as any;

  const form = useForm<RegistryPageFormFields>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<RegistryPageFormFields>> = async (
    formData
  ) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, initialValues);

    await props.onSubmit(FilteredFormData);
    window.scrollTo(0, 0);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RegistryPageInformationCard initialValues={initialValues} />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onCancel}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
};

export default RegistryForm;

export interface RegistryPageFormFields {
  title: BilingualRichText;
  description?: BilingualRichText;
  documentRefs?: DocumentRef[];
  contact: CustomContact | null;
  relatedResources?: BilingualUriReference[];
}
