import SectionCard from "components/atoms/SectionCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useBasicSearch from "../../../../pages/search/hooks/useBasicSearch";
import BasicSearchCheckboxListDetails from "../../../molecules/search/BasicSearchCheckboxListDetails";
import BasicSearchDateRange from "../../../molecules/search/BasicSearchDateRange";
import BasicSearchNumberRange from "../../../molecules/search/BasicSearchNumberRange";
import BasicSearchRangeCheckboxListDetails from "../../../molecules/search/BasicSearchRangeCheckboxListDetails";
import { BasicSearchFacetMultiSelectList } from "../Autocomplete/BasicSearchFacetMultiSelectList";

export interface FilterOptionsSectionProps {
  expandedSection?: "cosewic" | "listing";
  allFacets: any;
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const FilterOptionsSection: React.FC<FilterOptionsSectionProps> = (
  props
) => {
  const { t, i18n } = useTranslation();

  const [showAllCosewicFilters, setShowAllCosewicFilters] = useState(false);
  const [showAllListingFilters, setShowAllListingFilters] = useState(false);

  return (
    <SectionCard
      header={<h2 className="mrgn-tp-0">{t("search_filters")}</h2>}
      id="search-filters"
      className="pb-0"
      showLine={false}
    >
      <div className="pt-0 separator-line-top"></div>
      <details
        className="species-details"
        open={props.expandedSection === "cosewic"}
      >
        {/* ============ COSEWIC filters ============ */}
        <summary>
          <h3 className="mrgn-tp-0 mrgn-bttm-sm">{t("cosewic_filters")}</h3>
        </summary>
        <section id="cosewic-filters" className="pt-2 mrgn-bttm-md">
          <button
            type="button"
            className="btn btn-link px-2 py-2"
            onClick={() => setShowAllCosewicFilters(!showAllCosewicFilters)}
            data-testid="button-show-all-cosewic"
          >
            {!showAllCosewicFilters ? t("show_all") : t("hide_all")}
          </button>

          <BasicSearchCheckboxListDetails
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
            facetName={"Cosewic/ActiveStatus"}
            header={t("active_status")}
            open={showAllCosewicFilters}
            infoMessage={t("active_status_info_message")}
            infoTitle={t("active_status_info")}
          />

          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
              facetName={"Cosewic/TaxonomicGroup/NameFr"}
              header={t("taxonomic_group")}
              open={showAllCosewicFilters}
            />
          ) : (
            <BasicSearchCheckboxListDetails
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
              facetName={"Cosewic/TaxonomicGroup/NameEn"}
              header={t("taxonomic_group")}
              open={showAllCosewicFilters}
            />
          )}
          {i18n.language === "fr" ? (
            <BasicSearchRangeCheckboxListDetails
              facetName={"Cosewic/Ranges/NameFr"}
              header={t("range")}
              open={showAllCosewicFilters}
              basicSearch={props.basicSearch}
              allFacets={props.allFacets}
            />
          ) : (
            <BasicSearchRangeCheckboxListDetails
              facetName={"Cosewic/Ranges/NameEn"}
              header={t("range")}
              open={showAllCosewicFilters}
              basicSearch={props.basicSearch}
              allFacets={props.allFacets}
            />
          )}

          <BasicSearchDateRange
            label={t("assessment_date")}
            facetName={"Cosewic/LatestPublishedAssessment/Date"}
            basicSearch={props.basicSearch}
            allFacets={props.allFacets}
          />

          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/LatestPublishedAssessment/Type"}
            header={t("assessment_type")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/LatestPublishedAssessment/ReportType"}
            header={t("type_of_report")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/LatestAssessment/StatusAndCriteria/Status"}
            header={t("latest_assessment_decision")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/Assessments/StatusAndCriteria/Status"}
            header={t("historical_assessment_decision")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={
              "Cosewic/LatestPublishedAssessment/StatusAndCriteria/Status"
            }
            header={t("cosewic_status_on_the_registry")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={
              "Cosewic/LatestPublishedAssessment/StatusAndCriteria/StatusChange"
            }
            header={t("status_change")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/Status"}
            header={t("cosewic_ws_id_stage")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/Assessments/Stage"}
            header={t("cosewic_assessment_stage")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/State"}
            header={t("cosewic_ws_id_state")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />

          <BasicSearchDateRange
            label={t("year_to_be_sent_to_minister")}
            facetName={"Cosewic/LatestPublishedAssessment/YearSentToMinister"}
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchDateRange
            label={t("date_sent_to_minister")}
            facetName={"Cosewic/LatestPublishedAssessment/DateSentToMinister"}
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchCheckboxListDetails
            facetName={"Cosewic/EndemicStatus"}
            header={t("endemic_status")}
            open={showAllCosewicFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
        </section>
      </details>

      <div className="pt-0 separator-line-top"></div>
      <details
        className="species-details"
        open={props.expandedSection === "listing"}
      >
        {/* ============ Listing filters ============ */}
        <summary>
          <h3 className="mrgn-tp-0 mrgn-bttm-sm">{t("listing_filters")}</h3>
        </summary>
        <section id="listing-filters" className="pt-2 mrgn-bttm-md">
          <button
            type="button"
            className="btn btn-link px-2 py-2"
            onClick={() => setShowAllListingFilters(!showAllListingFilters)}
            data-testid="button-show-all-listing"
          >
            {!showAllListingFilters ? t("show_all") : t("hide_all")}
          </button>

          <BasicSearchNumberRange
            label={t("listing_batch")}
            facetName={"Listing/LatestProcess/ListingBatch"}
            open={showAllListingFilters}
            basicSearch={props.basicSearch}
            allFacets={props.allFacets}
          />

          <BasicSearchCheckboxListDetails
            facetName={"Listing/State"}
            header={t("state")}
            open={showAllListingFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />

          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LegalStatus/CompetentMinister1/NameFr"}
              header={t("competent_minister_1")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          ) : (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LegalStatus/CompetentMinister1/NameEn"}
              header={t("competent_minister_1")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          )}
          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LegalStatus/CompetentMinister2/NameFr"}
              header={t("competent_minister_2")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          ) : (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LegalStatus/CompetentMinister2/NameEn"}
              header={t("competent_minister_2")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          )}
          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              facetName={
                "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameFr"
              }
              header={t("consultation_path")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          ) : (
            <BasicSearchCheckboxListDetails
              facetName={
                "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameEn"
              }
              header={t("consultation_path")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          )}
          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LatestGicDecision/NameFr"}
              header={t("gic_decision")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          ) : (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LatestGicDecision/NameEn"}
              header={t("gic_decision")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          )}

          <BasicSearchCheckboxListDetails
            facetName={"Listing/ListingSchedule"}
            header={t("sara_schedule")}
            open={showAllListingFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />

          <BasicSearchCheckboxListDetails
            facetName={"Listing/LatestProcess/Schedule1Status"}
            header={t("schedule_1_status")}
            open={showAllListingFilters}
            translateItems
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />

          <BasicSearchDateRange
            label={t("response_statement_due_date")}
            facetName={
              "Listing/LatestProcess/ConsultationProcess/ResponseStatementDueDate"
            }
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchDateRange
            label={t("response_statement_posted_date")}
            facetName={
              "Listing/LatestProcess/ConsultationProcess/ResponseStatementPostedDate"
            }
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />

          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              facetName={
                "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameFr"
              }
              header={t("amendment_aligned_to_assessment")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          ) : (
            <BasicSearchCheckboxListDetails
              facetName={
                "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameEn"
              }
              header={t("amendment_aligned_to_assessment")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
            />
          )}
          {i18n.language === "fr" ? (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LegalProtection/Jurisdiction/NameFr"}
              header={t("jurisdiction")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
              translateItems
            />
          ) : (
            <BasicSearchCheckboxListDetails
              facetName={"Listing/LegalProtection/Jurisdiction/NameEn"}
              header={t("jurisdiction")}
              open={showAllListingFilters}
              allFacets={props.allFacets}
              basicSearch={props.basicSearch}
              translateItems
            />
          )}
          <BasicSearchDateRange
            label={t("gic_decision_date")}
            facetName={
              "Listing/LatestProcess/RegulatoryProcess/GicDecisionDate"
            }
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <BasicSearchDateRange
            label={t("gic_receipt_date")}
            facetName={"Listing/LatestProcess/RegulatoryProcess/GicReceiptDate"}
            allFacets={props.allFacets}
            basicSearch={props.basicSearch}
          />
          <hr className="mrgn-bttm-md" />
          <BasicSearchFacetMultiSelectList
            id="regulatory_bundle"
            label={t("regulatory_bundle")}
            facetName="Listing/LatestProcess/RegulatoryProcess/RegulatoryBundle"
            basicSearch={props.basicSearch}
          />
        </section>
      </details>
      {/* ============ Buttons ============ */}
      {/* <button
          type="button"
          data-testid="search-btn"
          className="btn btn-primary btn-sm mrgn-rght-sm"
        >
          {t("apply_filters")}
        </button>
        <button
          type="button"
          data-testid="reset-btn"
          className="btn btn-default btn-sm mrgn-rght-sm"
        >
          {t("reset")}
        </button> */}
    </SectionCard>
  );
};
