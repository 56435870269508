import { PhotoLicenseInput, PhotoLicenseQuery } from "generated/gql-types";
import * as FormMappers from "util/formMappers";
import { PhotoLicenseDocumentFormFields } from "./PhotoLicenseDocumentForm";

export const mapDomainModelToForm = (
  domainModel: PhotoLicenseQuery["photoLicense"]
) => {
  const out: Partial<PhotoLicenseDocumentFormFields> = {};

  FormMappers.mapBilingualRichText(out, "title", domainModel?.title);
  FormMappers.mapPrimitiveType(out, "licenseType", domainModel?.licenseType);

  FormMappers.mapCustomContact(
    out,
    "photographer",
    domainModel?.photographer?.contactRef,
    domainModel?.photographer?.organizationRef
  );
  FormMappers.mapRichText(out, "note", domainModel?.note);

  return out;
};

export function mapFormToDomainModel(
  form: Partial<PhotoLicenseDocumentFormFields>
) {
  const out: Partial<PhotoLicenseInput> = {};

  FormMappers.mapBilingualRichTextInput(out, "title", form.title);
  FormMappers.mapPrimitiveTypeInput(out, "licenseType", form.licenseType);

  FormMappers.mapCustomContactInput(out, "photographer", form?.photographer);

  FormMappers.mapRichTextInput(out, "note", form.note);

  return out;
}
