import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "../../util/router";
import DocumentsProfilePage from "./[id]";
import DocumentsEditPage from "./[id]/edit";
import DocumentsCreatePage from "./create";
import PhotoLicenseProfilePage from "./photoLicense/[id]";
import PhotoLicenseEditPage from "./photoLicense/[id]/edit";
import PhotoLicenseCreatePage from "./photoLicense/create";
import RegistryProfilePage from "./registry/[id]";
import RegistryEditPage from "./registry/[id]/edit";
import RegistryCreatePage from "./registry/create";
import AllDocumentsSearchPage from "./search";

export interface DocumentsRouterProps {}

export const DocumentsPathHelpers = {
  DocumentsSearch: "/documents/search",
  DocumentsCreate: "/documents/create",
  DocumentsProfile: (documentId: string) => `/documents/${documentId}`,
  DocumentsEdit: (documentId: string) => `/documents/${documentId}/edit`,
  RegistryCreate: "/documents/registry/create",
  RegistryProfile: (registryPageId: string) =>
    `/documents/registry/${registryPageId}`,
  RegistryEdit: (registryPageId: string) =>
    `/documents/registry/${registryPageId}/edit`,
  PhotoLicenseCreate: "/documents/photoLicense/create",
  PhotoLicenseProfile: (photoLicenseId: string) =>
    `/documents/photoLicense/${photoLicenseId}`,
  PhotoLicenseEdit: (photoLicenseId: string) =>
    `/documents/photoLicense/${photoLicenseId}/edit`,
};

export const DocumentsRouter: React.FC<DocumentsRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route path={relative("/registry/create")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.registry.create}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <RegistryCreatePage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/photoLicense/create")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.photos.create}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <PhotoLicenseCreatePage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/photoLicense/:photoLicenseId/edit")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.photos.update}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <PhotoLicenseEditPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/photoLicense/:photoLicenseId")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
          <PhotoLicenseProfilePage />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/registry/:registryPageId/edit")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.EditDocument}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.registry.update}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <RegistryEditPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/registry/:registryPageId")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <RegistryProfilePage />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/search")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <AllDocumentsSearchPage />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/create")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.documents.create}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <DocumentsCreatePage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/:documentId/edit")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.EditDocument}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.documents.update}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <DocumentsEditPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/:documentId")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <DocumentsProfilePage />
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default DocumentsRouter;
