import * as React from "react";
import { useTranslation } from "react-i18next";
import EditGenusModal, { EditGenusModalProps, useGenusModal } from "./";

interface EditGenusModalWithButtonProps
  extends Omit<EditGenusModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditGenusModalWithButton: React.FC<EditGenusModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useGenusModal({});

  const label = buttonLabel ?? t("edit");

  const validGenusId = props.genusId != null && props.genusId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validGenusId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validGenusId ? (
        <EditGenusModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditGenusModalWithButton;
