import EditImage from "components/atoms/EditImage";
import MissingData from "components/atoms/MissingData";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { ListingWsLegalStatus, Maybe } from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import isNullOrEmpty from "util/isNullOrEmpty";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../../../../features/auth/components";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../../atoms/SafeRenderHtml";

export interface LegalStatusSectionProps {
  legalStatus?: Maybe<ListingWsLegalStatus>;
  listingWsId: string;
}

export const LegalStatusSection: React.FC<LegalStatusSectionProps> = (
  props
) => {
  const { legalStatus, listingWsId } = props;
  const { t, i18n } = useTranslation();

  const [showEditImage, setShowEditImage] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!legalStatus) setShowEditImage(true);
    else setShowEditImage(false);
  }, [legalStatus]);

  const competentMinister1String =
    i18n.language === "fr"
      ? legalStatus?.competentMinister1?.name?.french
      : legalStatus?.competentMinister1?.name?.english;

  const competentMinister2 = legalStatus?.competentMinister2;

  const listingHistoryEnglishString = legalStatus?.listingHistory?.english;
  const listingHistoryFrenchString = legalStatus?.listingHistory?.french;

  const showTooltipAlertForCompetentMinister1 =
    legalStatus?.competentMinister1?.id == null;

  return (
    <>
      {showEditImage ? (
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.update}
          fallbackComponent={<MissingData />}
        >
          <div className="text-center mrgn-tp-sm">
            <Link
              to={ListingPathHelpers.ListingEdit(listingWsId)}
              className="hover-underline"
            >
              <EditImage />
              <div className="no-results-content mrgn-tp-sm">
                {t("no_information")}
              </div>
            </Link>
          </div>
        </RenderWhenAuthorized>
      ) : (
        <div className="species-field">
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("competent_minister_1")}
                  <TooltipAlert
                    isVisible={showTooltipAlertForCompetentMinister1}
                  >
                    {t("required_to_complete_listing_process_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {isNullOrEmpty(competentMinister1String) ? (
                    <MissingData />
                  ) : (
                    competentMinister1String
                  )}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("competent_minister_2")}</dt>
                <dd>
                  {!competentMinister2 || competentMinister2.length === 0 ? (
                    <MissingData />
                  ) : (
                    <ul className="list-unstyled lst-spcd">
                      {competentMinister2?.map((item) => (
                        <li key={item?.id}>
                          {i18n.language === "fr"
                            ? item?.name?.french
                            : item?.name?.english}
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12 text-break">
                <dt className="text-muted">
                  {t("sara_status_history_english")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(listingHistoryEnglishString?.text) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={listingHistoryEnglishString?.text!}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12 text-break">
                <dt className="text-muted">
                  {t("sara_status_history_french")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(listingHistoryFrenchString?.text) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={listingHistoryFrenchString?.text!}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default LegalStatusSection;
