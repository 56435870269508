import { Roles } from "./roles";

export function checkIsAuthorized(
  userRoles: string[],
  authorizedRoles: Roles[]
) {
  if (userRoles == null || userRoles?.length === 0) return false;

  // Global admin has access to everything.
  if (userRoles.includes(Roles.GlobalAdministrator)) {
    return true;
  }

  // User must have at least one of the authorized roles to gain access.
  for (const role of authorizedRoles) {
    if (userRoles.includes(role)) {
      return true;
    }
  }

  console.log("[checkIsAuthorized] unauthorized");
  return false;
}
