import { useTooltipTriggerState } from "@react-stately/tooltip";
import { TooltipTriggerProps } from "@react-types/tooltip";
import React from "react";
import { useTooltipTrigger } from "react-aria";
import { Tooltip } from "./Tooltip";

export interface TooltipAlertTriggerProps extends TooltipTriggerProps {
  isVisible?: boolean;
}

export const TooltipAlert: React.FC<TooltipAlertTriggerProps> = (props) => {
  const { delay = 300, isVisible = true } = props;

  // Manages state for a tooltip trigger
  const state = useTooltipTriggerState({ ...props, delay });
  const ref = React.useRef(null);

  // Get props for the trigger and its tooltip
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);

  return (
    <>
      {isVisible && (
        <span className="mrgn-lft-sm" style={{ position: "relative" }}>
          <span
            ref={ref}
            {...triggerProps}
            data-testid="tooltip-alert"
            className="fa fa-exclamation-circle text-danger"
            onClick={() => {
              state.open();
            }}
          />
          {state.isOpen && (
            <Tooltip state={state} {...tooltipProps}>
              {props.children}
            </Tooltip>
          )}
        </span>
      )}
    </>
  );
};
