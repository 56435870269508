import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteForOutcomeDocument,
  BilingualLookupText,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import LookupQueryAutocomplete from "../../LookupQueryAutocomplete/LookupQueryAutocomplete";

export interface OutcomeAutocompleteProps {
  initialValue?: BilingualLookupText;
  isRequired?: boolean;
  onChange: (selectedItem?: BilingualLookupText) => void;
}

export const OutcomeAutocomplete: React.FC<OutcomeAutocompleteProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  return (
    <LookupQueryAutocomplete
      id={"outcomeAutocomplete"}
      label={t("outcome")}
      queryDocument={AutocompleteForOutcomeDocument}
      listDisplayFormatter={(item) =>
        bilingualLookupTextNameForLanguage(i18n.language, item)
      }
      onSelectionChange={props.onChange}
      placeholder={
        props.initialValue
          ? bilingualLookupTextNameForLanguage(
              i18n.language,
              props.initialValue
            )
          : t("select_one_or_many_items")
      }
      isRequired={props.isRequired}
      onClear={() => props.onChange(undefined)}
    />
  );
};

export default OutcomeAutocomplete;
