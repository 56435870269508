import { useQuery } from "@tanstack/react-query";
import Layout from "components/layouts/OneColumn";
import { Item, Tabs } from "components/organisms/Tabs/Tabs";
import { GlobalAlert } from "features/globalAlert";
import { DOCUMENT_INDEX } from "features/search/documents/constants";
import documentsFacets from "features/search/documents/documentsFacets.json";
import odataMappers from "features/search/documents/documentsOdataMappers";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import useBasicSearch from "pages/search/hooks/useBasicSearch";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBasicSearchQuery } from "../../features/search/hooks/useBasicSearchQuery";
import { fetchFilters } from "../../features/search/utils/fetchFilters";
import { makeFilterString } from "../../features/search/utils/makeFilterString";
import { makePaging } from "../../features/search/utils/makePaging";
import { formatTimestamp } from "../../util/formatTimestamp";
import RegistrySearchPage from "./registry/search";
import DocumentsSearchPage from "./searchDocuments";
import PhotoLicenseSearchPage from "./photoLicense/search";

interface DocumentsSearchPageColumns {
  id: string;
  "Title/English/Text"?: string;
  "Title/French/Text"?: string;
  ContentOwner: string;
  Programs: string;
  "DocumentType/NameEn"?: string;
  "DocumentType/NameFr"?: string;
  UploadedDate: string;
  State: string;
}

const searchFields =
  "Title/English/PlainText, Title/French/PlainText, DocumentType/NameEn, DocumentType/NameFr,";
const queryType = "full";
const searchMode = "any";
const select =
  "Id, DocumentId, Title/English/Text, Title/French/Text, ContentOwner, Programs/NameEn, Programs/NameFr, DocumentType/Tags, DocumentType/NameEn, DocumentType/NameFr, UploadedDate";

const AllDocumentsSearchPage: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);

  const basicSearch = useBasicSearch("basicsearch_documents");

  const [showAllFilters, setShowAllFilters] = React.useState(true);

  const filtersQuery = useQuery(
    ["basicSearch", "filters"],
    () => {
      return fetchFilters(documentsFacets, DOCUMENT_INDEX);
    },
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      const column = "Stage asc, ContentOwner";
      return makeOrderByString({
        column,
        direction: "ascending",
      });
    }

    return makeOrderByString(sortDescriptor);
  }, [i18n.language, sortDescriptor]);

  const { data, loading, error, newRunSearchQuery } =
    useBasicSearchQuery(DOCUMENT_INDEX);

  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      const filterString = makeFilterString(odataMappers, basicSearch.state);

      const paging = makePaging(pagination);

      return {
        filter: filterString,
        search: basicSearch.state.keywordSearchText,
        queryType,
        searchMode,
        searchFields,
        select,
        orderby: orderByString,
        count: true,
        ...paging,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      newRunSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  useEffect(
    function autoResetPageNumber() {
      const newPageNumber = 1;
      pagination.goToPage(newPageNumber);
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      i18n.language,
    ]
  );

  const onSearchClick = async () => {
    await newRunSearchQuery(azureSearchArguments);
  };

  const onResetSearch = async () => {
    basicSearch.dispatch({ type: "reset_keyword_search" });
    pagination.goToPage(1);
  };

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const columns: Array<{
    name: string;
    key: keyof DocumentsSearchPageColumns;
    sortable: boolean;
    isHTML?: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("document_name"),
        key:
          i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text",
        sortable: false,
        hasLink: true,
        isHTML: true,
      },
      {
        name: t("content_owner"),
        key: "ContentOwner",
        sortable: true,
      },
      {
        name: t("program"),
        key: "Programs",
        sortable: false,
      },
      {
        name: t("type"),
        key:
          i18n.language === "fr"
            ? "DocumentType/NameFr"
            : "DocumentType/NameEn",
        sortable: false,
      },
      {
        name: t("state"),
        key: "State",
        sortable: true,
      },
      {
        name: t("upload_date"),
        key: "UploadedDate",
        sortable: true,
      },
    ],
    [i18n.language]
  );

  //
  // Map search response to columns

  const rows: Array<DocumentsSearchPageColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let documentName = "";
        let documentType = "";
        let programText = value?.Programs?.map((x: any) =>
          i18n.language === "fr" ? (x.NameFr as string) : (x.NameEn as string)
        ).join(", ");
        if (i18n.language === "fr") {
          documentName = value?.Title?.French?.Text;
          documentType = value?.DocumentType?.NameFr;
        } else {
          documentName = value?.Title?.English?.Text;
          documentType = value?.DocumentType?.NameEn;
        }

        return {
          _href: `/documents/${value?.Id}`,
          id: value?.DocumentId,
          [i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text"]:
            documentName,
          ContentOwner: t(value?.ContentOwner),
          Programs: programText as string,
          [i18n.language === "fr"
            ? "DocumentType/NameFr"
            : "DocumentType/NameEn"]: documentType,
          State: t(value?.State),
          UploadedDate: formatTimestamp(value?.UploadedDate),
        };
      }),
    [data?.value]
  );
  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("search_documents")}</h1>

          <Tabs className="tabs-style-2 full-width">
            <Item title={t("documents")}>
              <DocumentsSearchPage />
            </Item>
            <Item title={t("registry_pages")}>
              <RegistrySearchPage />
            </Item>
            <Item title={t("photo_licence")}>
              <PhotoLicenseSearchPage />
            </Item>
          </Tabs>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AllDocumentsSearchPage;
