import { set } from "lodash";
import {
  Address,
  AddressInput,
  AffectedSpecies,
  BilingualAbbreviationAcronymLookupText,
  BilingualAbbreviationAcronymTagsLookupText,
  BilingualAbbreviationAcronymTagsLookupTextInput,
  BilingualAbbreviationLookupText,
  BilingualLookupText,
  BilingualLookupTextInput,
  BilingualName,
  BilingualNameInput,
  BilingualNote,
  BilingualRichText,
  BilingualRichTextInput,
  BilingualText,
  BilingualTextInput,
  BilingualUriReference,
  BilingualUriReferenceInput,
  CommonName,
  CommonNameInput,
  ContactDetails,
  ContactDetailsInput,
  ContactNameInput,
  ContactRef,
  ContactRefInput,
  DateRange,
  DateRangeInput,
  DocumentRef,
  DocumentRefInput,
  ListingProcessConsultationProcess,
  ListingProcessDocument,
  ListingProcessRegulatoryProcess,
  NoteInput,
  OrganizationDetails,
  OrganizationDetailsInput,
  OrganizationRef,
  OrganizationRefInput,
  OverviewWsSpecies,
  OverviewWsSpeciesInput,
  PermitIdDetails,
  RichText,
  RichTextInput,
  SpeciesReferenceId,
  SpeciesReferenceIdInput,
  UriReference,
  UriReferenceInput,
} from "../generated/gql-types";
import isNullOrEmpty from "./isNullOrEmpty";
import { CustomContact } from "components/organisms/contacts/types";
import {
  mapContactToContactRef,
  mapOrganizationToOrganizationRef,
} from "components/organisms/contacts/AddContactButtonAndModal/addContactUtil";
import { DisplayFormat, formatTimestamp } from "./formatTimestamp";
import { isNullOrEmptyAddressInput } from "./contact/isNullOrEmptyAddress";

export function mapArrayUsingFnInput<T extends object>(
  mapperFunction: (output: T, outputKey: string, input: any) => void,
  output: T,
  outputKey: string,
  input?: any[] | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  // initialize an empty array to work with
  set(output, outputKey, []);

  // call the mapper function for each, storing the result at the corresponding index (inside the outputKey path)
  for (let i = 0; i < input.length; i++) {
    const value = input[i];
    mapperFunction(output, `${outputKey}[${i}]`, value);
  }
}

export function mapArrayUsingFn<T extends object>(
  mapperFunction: (output: T, outputKey: string, input: any) => void,
  output: T,
  outputKey: string,
  input?: any[] | null,
  inputKey?: string
) {
  // initialize an empty array to work with
  set(output, outputKey, []);

  if (!input) return;
  if (input.length < 1) return;

  // call the mapper function for each, storing the result at the corresponding index (inside the outputKey path)
  for (let i = 0; i < input.length; i++) {
    const value = inputKey == null ? input[i] : input[i][inputKey];
    mapperFunction(output, `${outputKey}[${i}]`, value);
  }
}

export function mapBilingualTextInput<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualTextInput | null | undefined
) {
  if (input === undefined) return;

  if (input?.english !== undefined) {
    set(output, `${outputKey}[english]`, input.english);
  }

  if (input?.french !== undefined) {
    set(output, `${outputKey}[french]`, input.french);
  }
}

export function mapBilingualText<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualText | null | undefined
) {
  set(output, `${outputKey}[english]`, input?.english ?? "");
  set(output, `${outputKey}[french]`, input?.french ?? "");
}

export function mapRichTextInput<T extends object>(
  output: T,
  outputKey: string,
  input?: RichTextInput | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  set(output, `${outputKey}[text]`, input?.text);
}

export function mapNoteInput<T extends object>(
  output: T,
  outputKey: string,
  input?: NoteInput | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  set(output, `${outputKey}[text][text]`, input);
}

export function mapNote<T extends object>(
  output: T,
  outputKey: string,
  input?: NoteInput | null
) {
  mapPrimitiveType(output, `${outputKey}[text][text]`, input?.text?.text);
}

export function mapRichText<T extends object>(
  output: T,
  outputKey: string,
  input?: RichText | null
) {
  set(output, `${outputKey}[text]`, input?.text ?? "");
  set(output, `${outputKey}[plainText]`, input?.plainText ?? "");
}

export function mapBilingualRichTextInput(
  output: any,
  outputKey: string,
  input?: BilingualRichTextInput | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapRichTextInput(output, `${outputKey}[english]`, input.english);
  mapRichTextInput(output, `${outputKey}[french]`, input.french);
}

export function mapBilingualRichText<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualRichText | null
) {
  mapRichText(output, `${outputKey}[english]`, input?.english);
  mapRichText(output, `${outputKey}[french]`, input?.french);
}

export function mapContactRef<T extends object>(
  output: T,
  outputKey: string,
  input?: Partial<ContactRef> | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id);
  mapContactName(output, `${outputKey}[name]`, input?.name);
  mapBilingualText(output, `${outputKey}[jobTitle]`, input?.jobTitle);
  if (input?.contactDetails) {
    mapContactDetails(
      output,
      `${outputKey}[contactDetails]`,
      input?.contactDetails ?? ""
    );
  }
  if (input?.organizationDetails) {
    mapOrganizationDetails(
      output,
      `${outputKey}[organizationDetails]`,
      input?.organizationDetails ?? ""
    );
  }
}

export function mapContactRefInput<T extends object>(
  output: T,
  outputKey: string,
  input?: Partial<ContactRefInput> | null
) {
  if (input == null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(output, `${outputKey}[id]`, input.id);

  mapContactNameInput(output, `${outputKey}[name]`, input.name);

  mapBilingualTextInput(output, `${outputKey}[jobTitle]`, input.jobTitle);

  mapContactDetailsInput(
    output,
    `${outputKey}[contactDetails]`,
    input.contactDetails
  );

  mapOrganizationDetailsInput(
    output,
    `${outputKey}[organizationDetails]`,
    input.organizationDetails
  );
}

export function mapContactRefInput_New<T extends object>(
  output: T,
  outputKey: string,
  input?: Partial<ContactRefInput> | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, input);
    return;
  }

  if (input?.id !== undefined) {
    set(output, `${outputKey}[id]`, input.id);
  }

  mapContactNameInput_New(output, `${outputKey}[name]`, input?.name);

  mapBilingualTextInput(output, `${outputKey}[jobTitle]`, input?.jobTitle);

  mapContactDetailsInput_New(
    output,
    `${outputKey}[contactDetails]`,
    input?.contactDetails
  );

  mapOrganizationDetailsInput(
    output,
    `${outputKey}[organizationDetails]`,
    input?.organizationDetails
  );
}

export function mapContactNameInput(
  output: any,
  outputKey: string,
  input?: ContactNameInput | null
) {
  if (!input) return;

  if (input.firstName) {
    set(output, `${outputKey}[firstName]`, input.firstName);
  }

  if (input.lastName) {
    set(output, `${outputKey}[lastName]`, input.lastName);
  }
}

export function mapContactName(
  output: any,
  outputKey: string,
  input?: ContactNameInput | null
) {
  set(output, `${outputKey}[firstName]`, input?.firstName ?? "");
  set(output, `${outputKey}[lastName]`, input?.lastName ?? "");
}

export function mapContactNameInput_New(
  output: any,
  outputKey: string,
  input?: ContactNameInput | null
) {
  if (input === undefined) return;

  if (input?.firstName !== undefined) {
    set(output, `${outputKey}[firstName]`, input.firstName);
  }

  if (input?.lastName !== undefined) {
    set(output, `${outputKey}[lastName]`, input.lastName);
  }
}

export function mapContactDetailsInput(
  output: any,
  outputKey: string,
  input?: ContactDetailsInput | null
) {
  if (!input) return;

  mapAddressInput(output, `${outputKey}[address]`, input.address);

  if (input.emailAddress) {
    set(output, `${outputKey}[emailAddress]`, input.emailAddress);
  }

  if (input.phoneNumber) {
    set(output, `${outputKey}[phoneNumber]`, input.phoneNumber);
  }

  if (input.faxNumber) {
    set(output, `${outputKey}[faxNumber]`, input.faxNumber);
  }
}

export function mapContactDetails(
  output: any,
  outputKey: string,
  input?: ContactDetails | null
) {
  mapAddress(output, `${outputKey}[address]`, input?.address);
  mapPrimitiveType(output, `${outputKey}[emailAddress]`, input?.emailAddress);
  mapPrimitiveType(output, `${outputKey}[phoneNumber]`, input?.phoneNumber);
  mapPrimitiveType(output, `${outputKey}[faxNumber]`, input?.faxNumber);
}

export function mapContactDetailsInput_New(
  output: any,
  outputKey: string,
  input?: ContactDetailsInput | null
) {
  if (input === undefined) return;

  if (input?.address !== undefined) {
    mapAddressInput(output, `${outputKey}[address]`, input.address);
  }

  if (input?.emailAddress !== undefined) {
    set(output, `${outputKey}[emailAddress]`, input.emailAddress);
  }

  if (input?.phoneNumber !== undefined) {
    set(output, `${outputKey}[phoneNumber]`, input.phoneNumber);
  }

  if (input?.faxNumber !== undefined) {
    set(output, `${outputKey}[faxNumber]`, input.faxNumber);
  }
}

export function mapAddressInput<T extends object>(
  output: T,
  outputKey: string,
  input?: AddressInput | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  if (isNullOrEmptyAddressInput(input)) {
    set(output, `${outputKey}`, null);
    return;
  }

  if (input?.addressLines !== undefined) {
    // TODO: TEST: what does it do with an empty array
    // TODO: TEST: what does it do with an undefined value?(input.addressLines undefined
    // TODO: TEST: what does it do when we don't initialize the output array first? (does it put an object with keys 0,1,2 inside the output?)

    if (input?.addressLines && input?.addressLines?.length > 0) {
      mapArrayUsingFnInput(
        mapBilingualTextInput,
        output,
        `${outputKey}[addressLines]`,
        input.addressLines
      );
    }
  }

  mapPrimitiveTypeInput(output, `${outputKey}[city]`, input?.city);

  mapPrimitiveTypeInput(
    output,
    `${outputKey}[provinceOrState]`,
    input?.provinceOrState
  );

  mapPrimitiveTypeInput(
    output,
    `${outputKey}[postalOrZipCode]`,
    input?.postalOrZipCode
  );

  if (input?.countryCode !== undefined) {
    if (
      input?.countryCode != null &&
      input?.countryCode === "NOT_INITIALIZED"
    ) {
      mapPrimitiveTypeInput(output, `${outputKey}[countryCode]`, undefined);
    } else {
      mapPrimitiveTypeInput(
        output,
        `${outputKey}[countryCode]`,
        input?.countryCode
      );
    }
  }
}

export function mapAddress<T extends object>(
  output: T,
  outputKey: string,
  input?: Address | null
) {
  let addresslines: BilingualText[] = [
    { english: "", french: "" },
    { english: "", french: "" },
  ];

  if (input?.addressLines !== undefined) {
    if (input?.addressLines && input?.addressLines?.length > 0) {
      input?.addressLines?.forEach((v, i) => {
        if (!isNullOrEmpty(v?.english)) addresslines[i].english = v?.english;
        if (!isNullOrEmpty(v?.french)) addresslines[i].french = v?.french;
      });
    }
  }

  mapArrayUsingFn(
    mapBilingualText,
    output,
    `${outputKey}[addressLines]`,
    addresslines
  );

  mapPrimitiveType(output, `${outputKey}[city]`, input?.city);
  mapPrimitiveType(
    output,
    `${outputKey}[provinceOrState]`,
    input?.provinceOrState
  );
  mapBilingualText(
    output,
    `${outputKey}[provinceOrStateName]`,
    input?.provinceOrStateName
  );
  mapPrimitiveType(
    output,
    `${outputKey}[postalOrZipCode]`,
    input?.postalOrZipCode
  );
  mapPrimitiveType(output, `${outputKey}[countryCode]`, input?.countryCode);
  // }
}

export function mapOrganizationDetailsInput<T extends object>(
  output: T,
  outputKey: string,
  input?: OrganizationDetailsInput | null
) {
  if (!input) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  if (input.id) {
    set(output, `${outputKey}[id]`, input.id);
  }

  if (input.name) {
    mapBilingualTextInput(output, `${outputKey}[name]`, input.name);
  }

  if (input.departments && input.departments.length > 0) {
    mapArrayUsingFnInput(
      mapBilingualTextInput,
      output,
      `${outputKey}[departments]`,
      input.departments
    );
  }
}

export function mapOrganizationDetails<T extends object>(
  output: T,
  outputKey: string,
  input?: OrganizationDetails | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id);
  mapBilingualText(output, `${outputKey}[name]`, input?.name);
  if (input?.departments && input?.departments.length > 0) {
    mapArrayUsingFn(
      mapBilingualText,
      output,
      `${outputKey}[departments]`,
      input?.departments
    );
  } else {
    mapArrayUsingFn(
      mapBilingualText,
      output,
      `${outputKey}[departments]`,
      Array(1).fill("")
    );
  }
}

export function mapOrganizationRefInput<T extends object>(
  output: T,
  outputKey: string,
  input?: OrganizationRefInput | null
) {
  if (!input) return;

  if (input.organizationDetails) {
    mapOrganizationDetailsInput(
      output,
      `${outputKey}[organizationDetails]`,
      input.organizationDetails
    );
  }

  if (input.contactDetails) {
    mapContactDetailsInput(
      output,
      `${outputKey}[contactDetails]`,
      input.contactDetails
    );
  }
}

export function mapOrganizationRef<T extends object>(
  output: T,
  outputKey: string,
  input?: OrganizationRef | null
) {
  mapOrganizationDetails(
    output,
    `${outputKey}[organizationDetails]`,
    input?.organizationDetails
  );

  mapContactDetails(
    output,
    `${outputKey}[contactDetails]`,
    input?.contactDetails
  );
}

export function mapDocumentRefInput<T extends object>(
  output: T,
  outputKey: string,
  input?: DocumentRefInput | null
) {
  if (!input) return;

  if (input.id) {
    set(output, `${outputKey}[id]`, input.id);
  }

  if (input.consultationDates) {
    mapDateRangeInput(
      output,
      `${outputKey}[consultationDates]`,
      input.consultationDates
    );
  }

  if (input.publishedDate) {
    set(output, `${outputKey}[publishedDate]`, input.publishedDate);
  }
}

export function mapDocumentRef<T extends object>(
  output: T,
  outputKey: string,
  input?: Partial<DocumentRef> | null | undefined
) {
  set(output, `${outputKey}[id]`, input?.id ?? "");
  set(output, `${outputKey}[documentId]`, input?.documentId ?? "");
  mapBilingualRichText(output, `${outputKey}[title]`, input?.title);

  mapArrayUsingFn(
    mapOverviewWsSpecies,
    output,
    `${outputKey}[associatedSpecies]`,
    input?.associatedSpecies
  );
  mapDateRange(
    output,
    `${outputKey}[consultationDates]`,
    input?.consultationDates
  );
  mapBilingualAbbreviationAcronymTagsLookupText(
    output,
    `${outputKey}[documentType]`,
    input?.documentType
  );
  set(output, `${outputKey}[publishedDate]`, input?.publishedDate ?? "");
  set(output, `${outputKey}[state]`, input?.state ?? "");
}

export function mapOverviewWsSpeciesInput<T extends object>(
  output: T,
  outputKey: string,
  input?: OverviewWsSpeciesInput | null
) {
  if (!input) return;

  if (input.cosewicRefId) {
    mapSpeciesReferenceIdInput(
      output,
      `${outputKey}[cosewicRefId]`,
      input.cosewicRefId
    );
  }

  if (input.listingRefId) {
    mapSpeciesReferenceIdInput(
      output,
      `${outputKey}[listingRefId]`,
      input.listingRefId
    );
  }

  if (input.commonNameEnglish) {
    mapCommonNameInput(
      output,
      `${outputKey}[commonNameEnglish]`,
      input.commonNameEnglish
    );
  }

  if (input.commonNameFrench) {
    mapCommonNameInput(
      output,
      `${outputKey}[commonNameFrench]`,
      input.commonNameFrench
    );
  }

  if (input.population) {
    mapBilingualNameInput(output, `${outputKey}[population]`, input.population);
  }

  if (input.scientificName) {
    mapRichTextInput(
      output,
      `${outputKey}[scientificName]`,
      input.scientificName
    );
  }

  if (input.taxonomicGroup) {
    mapBilingualNameInput(
      output,
      `${outputKey}[taxonomicGroup]`,
      input.taxonomicGroup
    );
  }
}

export function mapOverviewWsSpecies<T extends object>(
  output: T,
  outputKey: string,
  input?: OverviewWsSpecies | null
) {
  mapSpeciesReferenceId(
    output,
    `${outputKey}[cosewicRefId]`,
    input?.cosewicRefId
  );
  mapSpeciesReferenceId(
    output,
    `${outputKey}[listingRefId]`,
    input?.listingRefId
  );
  mapCommonName(
    output,
    `${outputKey}[commonNameEnglish]`,
    input?.commonNameEnglish
  );
  mapCommonName(
    output,
    `${outputKey}[commonNameFrench]`,
    input?.commonNameFrench
  );
  mapBilingualName(output, `${outputKey}[population]`, input?.population);
  mapRichText(output, `${outputKey}[scientificName]`, input?.scientificName);
  mapBilingualName(
    output,
    `${outputKey}[taxonomicGroup]`,
    input?.taxonomicGroup
  );
}

export function mapSpeciesReferenceIdInput<T extends object>(
  output: T,
  outputKey: string,
  input?: SpeciesReferenceIdInput | null
) {
  if (!input) return;

  if (input.id) {
    set(output, `${outputKey}[id]`, input.id);
  }

  if (input.legacyId) {
    set(output, `${outputKey}[legacyId]`, input.legacyId);
  }
}

export function mapSpeciesReferenceId<T extends object>(
  output: T,
  outputKey: string,
  input?: SpeciesReferenceId | null
) {
  set(output, `${outputKey}[id]`, input?.id ?? "");
  set(output, `${outputKey}[legacyId]`, input?.legacyId ?? "");
}

export function mapCommonNameInput<T extends object>(
  output: T,
  outputKey: string,
  input?: CommonNameInput | null
) {
  if (!input) return;

  if (input.name) {
    mapRichTextInput(output, `${outputKey}[name]`, input.name);
  }
}

export function mapCommonName<T extends object>(
  output: T,
  outputKey: string,
  input?: CommonName | null
) {
  mapRichText(output, `${outputKey}[name]`, input?.name);
}

export function mapBilingualNameInput<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualNameInput | null
) {
  if (!input) return;

  if (input.id) {
    set(output, `${outputKey}[id]`, input.id);
  }

  if (input.nameEn) {
    set(output, `${outputKey}[nameEn]`, input.nameEn);
  }

  if (input.nameFr) {
    set(output, `${outputKey}[nameFr]`, input.nameFr);
  }
}

export function mapBilingualName<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualName | null
) {
  set(output, `${outputKey}[id]`, input?.id ?? "");
  set(output, `${outputKey}[nameEn]`, input?.nameEn ?? "");
  set(output, `${outputKey}[nameFr]`, input?.nameFr ?? "");
}

export function mapDateRangeInput<T extends object>(
  output: T,
  outputKey: string,
  input?: DateRangeInput | null
) {
  if (!input) return;

  if (input.fromDate) {
    set(output, `${outputKey}[fromDate]`, input.fromDate);
  }

  if (input.toDate) {
    set(output, `${outputKey}[toDate]`, input.toDate);
  }
}

export function mapDateRange<T extends object>(
  output: T,
  outputKey: string,
  input?: DateRange | null
) {
  set(output, `${outputKey}[fromDate]`, input?.fromDate ?? "");
  set(output, `${outputKey}[toDate]`, input?.toDate ?? "");
}

export function mapBilingualLookupTextInput<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualLookupTextInput | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  set(output, `${outputKey}[id]`, input.id);
  mapBilingualTextInput(output, `${outputKey}[name]`, input.name);
}

export function mapBilingualLookupText<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualLookupText | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id ?? "");
  mapBilingualText(output, `${outputKey}[name]`, input?.name);
}

export function mapBilingualAbbreviationAcronymTagsLookupTextInput<
  T extends object
>(
  output: T,
  outputKey: string,
  input?: BilingualAbbreviationAcronymTagsLookupTextInput | null
) {
  if (!input) return;

  if (input.id) {
    set(output, `${outputKey}[id]`, input.id);
  }

  if (input.acronym) {
    set(output, `${outputKey}[acronym]`, input.acronym);
  }

  if (input.abbreviation) {
    mapBilingualTextInput(
      output,
      `${outputKey}[abbreviation]`,
      input.abbreviation
    );
  }

  if (input.name) {
    mapBilingualTextInput(output, `${outputKey}[name]`, input.name);
  }

  if (input.tags && input.tags.length > 0) {
    mapArrayUsingFnInput(set, output, `${outputKey}[tags]`, input.tags);
  }
}

export function mapBilingualAbbreviationAcronymTagsLookupText<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualAbbreviationAcronymTagsLookupText | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id);
  mapPrimitiveType(output, `${outputKey}[acronym]`, input?.acronym);
  mapBilingualText(output, `${outputKey}[abbreviation]`, input?.abbreviation);
  mapBilingualText(output, `${outputKey}[name]`, input?.name);
  mapArrayUsingFn(set, output, `${outputKey}[tags]`, input?.tags);
}

export function mapBilingualUriReferenceInput<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualUriReferenceInput | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  if (input.enReference) {
    mapUriReferenceInput(
      output,
      `${outputKey}[enReference]`,
      input.enReference
    );
  }

  if (input.frReference) {
    mapUriReferenceInput(
      output,
      `${outputKey}[frReference]`,
      input.frReference
    );
  }
}

export function mapBilingualUriReference<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualUriReference | null
) {
  mapUriReference(output, `${outputKey}[enReference]`, input?.enReference);
  mapUriReference(output, `${outputKey}[frReference]`, input?.frReference);
}

export function mapUriReferenceInput<T extends object>(
  output: T,
  outputKey: string,
  input?: UriReferenceInput | null
) {
  if (!input) return;

  if (input.name) {
    mapRichTextInput(output, `${outputKey}[name]`, input.name);
  }

  if (input.uri) {
    set(output, `${outputKey}[uri]`, input.uri);
  }
}

export function mapUriReference<T extends object>(
  output: T,
  outputKey: string,
  input?: UriReference | null
) {
  mapRichText(output, `${outputKey}[name]`, input?.name);
  mapPrimitiveType(output, `${outputKey}[uri]`, input?.uri);
}

export function mapPrimitiveType<T extends object>(
  output: T,
  outputKey: string,
  input?: string | number | boolean | null,
  value?: any // This is the value when the input is undefined or null
) {
  if (input != null) {
    switch (typeof input) {
      case "number":
        set(output, `${outputKey}`, String(input));
        break;
      case "boolean":
        set(output, `${outputKey}`, input);
        break;
      case "string":
      default:
        set(output, `${outputKey}`, input);
    }
  } else {
    if (value !== undefined) {
      set(output, `${outputKey}`, value);
    } else {
      set(output, `${outputKey}`, "");
    }
  }
}

export function mapPrimitiveTypeInput<T extends object>(
  output: T,
  outputKey: string,
  input?: string | number | boolean | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  set(output, outputKey, input);
}

export function mapAnyArrayType<T extends object>(
  output: T,
  outputKey: string,
  input?: any[] | null
) {
  if (input != null) {
    set(output, `${outputKey}`, input);
  } else {
    set(output, `${outputKey}`, []);
  }
}

export function mapAnyArrayTypeInput<T extends object>(
  output: T,
  outputKey: string,
  input?: any[] | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  set(output, outputKey, input);
}

export function mapBilingualAbbreviationAcronymLookupText<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualAbbreviationAcronymLookupText | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id);
  mapPrimitiveType(output, `${outputKey}[acronym]`, input?.acronym);
  mapBilingualText(output, `${outputKey}[abbreviation]`, input?.abbreviation);
  mapBilingualText(output, `${outputKey}[name]`, input?.name);
}

export function mapBilingualAbbreviationAcronymLookupTextInput<
  T extends object
>(
  output: T,
  outputKey: string,
  input?: BilingualAbbreviationAcronymLookupText | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(output, `${outputKey}[id]`, input?.id);
  mapPrimitiveTypeInput(output, `${outputKey}[acronym]`, input?.acronym);
  mapBilingualTextInput(
    output,
    `${outputKey}[abbreviation]`,
    input?.abbreviation
  );
  mapBilingualTextInput(output, `${outputKey}[name]`, input?.name);
}

export function mapAffectedSpecies<T extends object>(
  output: T,
  outputKey: string,
  input?: AffectedSpecies | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id);
  mapPrimitiveType(output, `${outputKey}[listingId]`, input?.listingId);
  mapCommonName(
    output,
    `${outputKey}[commonNameEnglish]`,
    input?.commonNameEnglish
  );
  mapCommonName(
    output,
    `${outputKey}[commonNameFrench]`,
    input?.commonNameFrench
  );

  mapBilingualName(output, `${outputKey}[population]`, input?.population);
}

export function mapAffectedSpeciesInput<T extends object>(
  output: T,
  outputKey: string,
  input?: AffectedSpecies | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(output, `${outputKey}[id]`, input?.id);
  mapPrimitiveTypeInput(
    output,
    `${outputKey}[listingId]`,
    input?.listingId != null ? parseInt(String(input?.listingId)) : undefined
  );
  mapCommonNameInput(
    output,
    `${outputKey}[commonNameEnglish]`,
    input?.commonNameEnglish
  );
  mapCommonNameInput(
    output,
    `${outputKey}[commonNameFrench]`,
    input?.commonNameFrench
  );

  mapBilingualNameInput(output, `${outputKey}[population]`, input?.population);
}

export function mapPermitIdDetails<T extends object>(
  output: T,
  outputKey: string,
  input?: PermitIdDetails | null
) {
  mapPrimitiveType(output, `${outputKey}[permitId]`, input?.permitId);
  mapPrimitiveType(output, `${outputKey}[issuanceDate]`, input?.issuanceDate);
}

export function mapPermitIdDetailsInput<T extends object>(
  output: T,
  outputKey: string,
  input?: PermitIdDetails | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(output, `${outputKey}[permitId]`, input?.permitId);
  mapPrimitiveTypeInput(
    output,
    `${outputKey}[issuanceDate]`,
    input?.issuanceDate
  );
}

export function mapCustomContact<T extends object>(
  output: T,
  outputKey: string,
  inputContactRef?: ContactRef | null,
  inputOrganizationRef?: OrganizationRef | null
) {
  if (inputContactRef) {
    mapContactRef(output, `${outputKey}[contactRef]`, inputContactRef);
    set(output, `${outputKey}[contactType]`, "contact");
  } else if (inputOrganizationRef) {
    mapOrganizationRef(
      output,
      `${outputKey}[organizationRef]`,
      inputOrganizationRef
    );
    set(output, `${outputKey}[contactType]`, "organization");
  } else {
    set(output, `${outputKey}`, null);
  }
}

export function mapCustomContactInput<T extends object>(
  output: T,
  outputKey: string,
  input?: CustomContact | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  // Map custom 'contact' type;
  //   it acts as a container to hold either a contact or organization,
  //   as picked by the contact modal.
  switch (input.contactType) {
    case "contact": {
      mapContactRefInput(
        output,
        `${outputKey}[contactRef]`,
        mapContactToContactRef(input.contactRef)
      );
      // when we're setting a contact, organization should be un-set.
      set(output, `${outputKey}[organizationRef]`, null);
      break;
    }
    case "organization": {
      const organizationRef = mapOrganizationToOrganizationRef(
        input.organizationRef
      );
      mapOrganizationRefInput(
        output,
        `${outputKey}[organizationRef]`,
        organizationRef
      );
      // when we're setting an organization, contact should be un-set.
      set(output, `${outputKey}[contactRef]`, null);

      break;
    }
  }
}

export function mapBilingualNote<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualNote | null
) {
  mapRichText(output, `${outputKey}[english]`, input?.english);
  mapRichText(output, `${outputKey}[french]`, input?.french);
}

export function mapBilingualNoteInput<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualNote | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapRichTextInput(output, `${outputKey}[english]`, input?.english);
  mapRichTextInput(output, `${outputKey}[french]`, input?.french);
}

export function mapConsultationProcess<T extends object>(
  output: T,
  outputKey: string,
  input?: ListingProcessConsultationProcess | null
) {
  mapPrimitiveType(
    output,
    `${outputKey}[responseStatementPostedDate]`,
    formatTimestamp(
      input?.responseStatementPostedDate,
      DisplayFormat.YEAR_MONTH_DAY
    )
  );
  mapBilingualLookupText(
    output,
    `${outputKey}[consultationPath]`,
    input?.consultationPath
  );

  mapPrimitiveType(output, `${outputKey}[listingBatch]`, input?.listingBatch);
  mapBilingualLookupText(
    output,
    `${outputKey}[amendmentAlignedToAssessment]`,
    input?.amendmentAlignedToAssessment
  );
}

export function mapConsultationProcessInput<T extends object>(
  output: T,
  outputKey: string,
  input?: ListingProcessConsultationProcess | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(
    output,
    `${outputKey}[responseStatementPostedDate]`,
    input?.responseStatementPostedDate
  );
  mapBilingualLookupTextInput(
    output,
    `${outputKey}[consultationPath]`,
    input?.consultationPath
  );
  mapPrimitiveTypeInput(
    output,
    `${outputKey}[listingBatch]`,
    input?.listingBatch != null
      ? parseInt(String(input?.listingBatch))
      : input?.listingBatch
  );
  mapBilingualLookupTextInput(
    output,
    `${outputKey}[amendmentAlignedToAssessment]`,
    input?.amendmentAlignedToAssessment
  );
}

export function mapBilingualAbbreviationLookupText<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualAbbreviationLookupText | null
) {
  mapPrimitiveType(output, `${outputKey}[id]`, input?.id);
  mapBilingualText(output, `${outputKey}[abbreviation]`, input?.abbreviation);
  mapBilingualText(output, `${outputKey}[name]`, input?.name);
}

export function mapBilingualAbbreviationLookupTextInput<T extends object>(
  output: T,
  outputKey: string,
  input?: BilingualAbbreviationLookupText | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(output, `${outputKey}[id]`, input?.id);
  mapBilingualTextInput(
    output,
    `${outputKey}[abbreviation]`,
    input?.abbreviation
  );
  mapBilingualTextInput(output, `${outputKey}[name]`, input?.name);
}

export function mapRegulatoryProcess<T extends object>(
  output: T,
  outputKey: string,
  input?: ListingProcessRegulatoryProcess | null
) {
  mapPrimitiveType(
    output,
    `${outputKey}[regulatoryBundle]`,
    input?.regulatoryBundle
  );
  mapPrimitiveType(
    output,
    `${outputKey}[gicReceiptDate]`,
    formatTimestamp(input?.gicReceiptDate, DisplayFormat.YEAR_MONTH_DAY)
  );
  mapPrimitiveType(
    output,
    `${outputKey}[gicDecisionDate]`,
    formatTimestamp(input?.gicDecisionDate, DisplayFormat.YEAR_MONTH_DAY)
  );
  mapBilingualAbbreviationLookupText(
    output,
    `${outputKey}[gicDecision]`,
    input?.gicDecision
  );
  mapBilingualAbbreviationLookupText(
    output,
    `${outputKey}[cgiListingProposal]`,
    input?.cgiListingProposal
  );
  mapBilingualLookupText(
    output,
    `${outputKey}[cosewicToReassess]`,
    input?.cosewicToReassess
  );
  mapBilingualRichText(output, `${outputKey}[rationale]`, input?.rationale);
}

export function mapRegulatoryProcessInput<T extends object>(
  output: T,
  outputKey: string,
  input?: ListingProcessRegulatoryProcess | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveTypeInput(
    output,
    `${outputKey}[regulatoryBundle]`,
    input?.regulatoryBundle
  );
  mapPrimitiveTypeInput(
    output,
    `${outputKey}[gicReceiptDate]`,
    input?.gicReceiptDate
  );
  mapPrimitiveTypeInput(
    output,
    `${outputKey}[gicDecisionDate]`,
    input?.gicDecisionDate
  );
  mapBilingualAbbreviationLookupTextInput(
    output,
    `${outputKey}[gicDecision]`,
    input?.gicDecision
  );
  mapBilingualAbbreviationLookupTextInput(
    output,
    `${outputKey}[cgiListingProposal]`,
    input?.cgiListingProposal
  );
  mapBilingualLookupTextInput(
    output,
    `${outputKey}[cosewicToReassess]`,
    input?.cosewicToReassess
  );
  mapBilingualRichTextInput(
    output,
    `${outputKey}[rationale]`,
    input?.rationale
  );
}

export function mapListingProcessDocument<T extends object>(
  output: T,
  outputKey: string,
  input?: ListingProcessDocument | null
) {
  mapPrimitiveType(output, `${outputKey}[type]`, input?.type);
  mapPrimitiveType(
    output,
    `${outputKey}[publicationDate]`,
    input?.publicationDate
  );
  mapPrimitiveType(output, `${outputKey}[uri]`, input?.uri);
}

export function mapListingProcessDocumentInput<T extends object>(
  output: T,
  outputKey: string,
  input?: ListingProcessDocument | null
) {
  if (input === undefined) return;
  if (input === null) {
    set(output, `${outputKey}`, null);
    return;
  }

  mapPrimitiveType(output, `${outputKey}[type]`, input?.type);
  mapPrimitiveType(
    output,
    `${outputKey}[publicationDate]`,
    input?.publicationDate
  );
  mapPrimitiveType(output, `${outputKey}[uri]`, input?.uri);
}
