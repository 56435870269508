import UploadCloud from "assets/svg/upload-cloud.svg";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import {
  DocumentAttachment,
  DocumentLanguage,
  Maybe,
  UriDocumentReference,
  UriDocumentReferenceInput,
} from "generated/gql-types";
import { zip } from "lodash";
import prettyBytes from "pretty-bytes";
import * as React from "react";
import { useRef, useState } from "react";
import { FileError, useDropzone } from "react-dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import { getI18n, useTranslation } from "react-i18next";
import { getApiDocumentManagementServiceUrl } from "../../../../azure/environment";
import { getUserAccessToken } from "../../../../features/auth/CustomMsalProvider";

interface AddPhotoLicenseFormProps {
  defaultValues?: LicenseFileFields;
  photoLicenseId: string;
  onClose: () => void;
}

enum UploadStatus {
  Success,
  Error,
}

type UploadResponse = {
  status: UploadStatus;
  message?: string;
};

const AddPhotoLicenseForm: React.FC<AddPhotoLicenseFormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const form = useForm<LicenseFileFields>({
    defaultValues: props.defaultValues,
  });

  const [saveButtonText, setSaveButtonText] = useState<string>(t("save"));
  const [uploadStatuses, setUploadStatuses] = useState<
    Record<string, UploadResponse>
  >({});
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);

  const { register, handleSubmit, formState, getValues, setValue } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const setFileStatusComplete = (
    fileName: string,
    uploadResponse: UploadResponse
  ) => {
    setUploadStatuses((x) => ({ ...x, [fileName]: uploadResponse }));
  };

  const removeFileStatus = (fileName: string) => {
    setUploadStatuses((x) => {
      const temp = { ...x };
      delete temp[fileName];
      return temp;
    });
  };

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const token = await getUserAccessToken();
    const baseUrl =
      getApiDocumentManagementServiceUrl("7094") +
      `photolicense/${props.photoLicenseId}/license`;
    const file = files[0] as any;
    if (!file) {
      return Promise.resolve(false);
    } else if (uploadStatuses[file.name]?.status === UploadStatus.Success) {
      return Promise.resolve(true);
    } else {
      const formData = new FormData();
      formData.append("licenseFile", file);

      const url = new URL(baseUrl);

      const promise = fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        const x = await promise;

        if (x.status >= 200 && x.status <= 299) {
          setUploadCompleted(true);
          setFileStatusComplete(file.name, {
            status: UploadStatus.Success,
          });
        } else {
          const bodyRes = await x.text();
          const bodyResObj = JSON.parse(bodyRes);
          const errorMessage =
            i18n.language === "fr"
              ? bodyResObj?.messages[0]?.frenchMessage
              : bodyResObj?.messages[0]?.englishMessage;
          throw new Error(errorMessage);
        }
      } catch (e: any) {
        setFileStatusComplete(file.name, {
          status: UploadStatus.Error,
          message: e.message,
        });
        setSaveButtonText(t("retry"));
      }
    }
  };

  React.useEffect(() => {
    if (
      uploadCompleted === true &&
      closeBtnRef != null &&
      closeBtnRef.current != null &&
      isSubmitting === false
    ) {
      closeBtnRef.current.focus();
    }
  }, [uploadCompleted, isSubmitting]);

  const [files, setFiles] = React.useState<File[]>([]);

  const onDrop = React.useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length > 0) {
      setFiles((previousFiles) => [...previousFiles, ...acceptedFiles]);
      setFileUploaded(true);
      setUploadCompleted(false);
    }
    if (rejectedFiles.length > 0) {
      console.warn("Rejected files:", rejectedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: false,
    disabled: fileUploaded,
  });

  const removeFile = (name: string, indexRemovedFile: number) => {
    removeFileStatus(name);
    setFileUploaded(false);
    setFiles((files) => files.filter((file) => file.name !== name));
    if (
      files.every(
        (file) =>
          uploadStatuses[file.name]?.status === UploadStatus.Success ||
          file.name === name
      )
    ) {
      setUploadCompleted(true);
    }

    // Form reset will re-sync the state of the Files (useState) and the Hook form.
    form.reset();
    // After form reset, restore language back to the remaining files.
  };

  const renderUploadStatusIndicator = (uploadResponse: UploadResponse) => {
    if (uploadResponse == null) return null;
    switch (uploadResponse.status) {
      case UploadStatus.Success:
        return <i className="fa fa-check text-success mrgn-lft-sm" />;
      case UploadStatus.Error:
        return (
          <>
            <span className="label label-danger">{uploadResponse.message}</span>
          </>
        );
      default:
        return null;
    }
  };

  const noLicenseFile = (licenseFile: File[]) => {
    if (licenseFile === null || licenseFile.length === 0) return true;
    else return false;
  };

  return (
    <>
      {/* -------------- Drag and drop files -------------- */}
      {files.length === 0 ? (
        <section className="mrgn-bttm-lg">
          <div {...getRootProps({ className: "dropzone" })}>
            <input
              {...getInputProps()}
              multiple={false}
              data-testid="dropzone-add-file"
            />
            <p>
              <img className="mrgn-rght-lg" src={UploadCloud} alt="" />
              {t("drag_and_drop_a_file_here")}
            </p>
          </div>
        </section>
      ) : null}

      {/* -------------- Form -------------- */}
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {files.map((file, indexFile) => (
          <div key={file.name} className="well-normal">
            <div className="flex justify-between align-start mrgn-bttm-10">
              <span className="font-size-16">
                <b>{file.name}</b> (
                {prettyBytes(file.size, { locale: i18n.language })}){" "}
                {renderUploadStatusIndicator(uploadStatuses[file.name])}
              </span>
              <button
                type="button"
                title={t("remove_file")}
                className="btn btn-link btn-xs p-0 hover-grey"
                data-testid={`button-remove-file-${file.name}`}
                onClick={() => removeFile(file.name, indexFile)}
                hidden={
                  isSubmitting ||
                  uploadStatuses[file.name]?.status === UploadStatus.Success
                }
              >
                <i className="fas fa-times font-size-14 color-danger"></i>
              </button>
            </div>
          </div>
        ))}

        {/* -------------- List of rejected files -------------- */}
        {/* ------------- (usually due to duplicate file name) -------------- */}
        {fileRejections != null && fileRejections.length > 0 ? (
          <div className="width-fit-content">
            {fileRejections.map((x, index) => (
              <div key={index} className="label label-danger mrgn-bttm-md">
                {x.file.name} {t("rejected")}{" "}
                {x.errors?.length > 0
                  ? `(${x.errors.map((e) => e.message).join(", ")})`
                  : null}
              </div>
            ))}
          </div>
        ) : null}

        {!uploadCompleted ? (
          <FormButtons
            disabled={noLicenseFile(files)}
            isSubmitting={isSubmitting}
            onCancel={props.onClose}
            errors={errors}
            submitBtnText={saveButtonText}
            submitBtnIcon="fas fa-cloud-upload-alt"
          />
        ) : (
          <button
            ref={closeBtnRef}
            type="button"
            onClick={props.onClose}
            className="btn btn-primary"
            data-testid="form-button-close"
            disabled={isSubmitting}
          >
            {t("close")}
          </button>
        )}
      </form>
    </>
  );
};

export default AddPhotoLicenseForm;

/////////////////////////////////////////////

export interface LicenseFileFields {
  licenseFile: UriDocumentReference;
}
