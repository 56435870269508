import * as React from "react";
import { useTranslation } from "react-i18next";
import EditRangeModal, { EditRangeModalProps, useRangeModal } from "./";

interface EditRangeModalWithButtonProps
  extends Omit<EditRangeModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditRangeModalWithButton: React.FC<EditRangeModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useRangeModal({});

  const label = buttonLabel ?? t("edit");

  const validRangeId = props.rangeId != null && props.rangeId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validRangeId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validRangeId ? (
        <EditRangeModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditRangeModalWithButton;
