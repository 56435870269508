import cc from "classcat";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";

export interface DatePickerProps {
  id?: string;
  required?: boolean;
  label: string;
  min?: string;
  max?: string;
  value: string;
  onInput: (newDate: string) => void; // TODO: maybe emit a timestamp instead of date string?
  disabled?: boolean;
  className?: string;
  fromController?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { className = "width-230" } = props;
  const { t } = useTranslation();

  const onDateInput = (e: any) => {
    props.onInput(e.currentTarget.value);
  };

  const id = props.id ?? "";
  return (
    <>
      <label htmlFor={id} className={props.required ? "required" : ""}>
        {props.label}
        {/*TODO: class datepicker-format*/}
        <span className="datepicker-format">
          (
          <abbr title={t("datepicker_abbr_title")}>{t("datepicker_abbr")}</abbr>
          )
        </span>

        {props.required ? (
          <strong className="required"> ({t("required")})</strong>
        ) : null}
      </label>
      <input
        className={cc(["form-control", className])}
        type="date"
        id={id}
        name={id}
        data-testid={id}
        min={props.min}
        max={props.max ?? "9999-12-31"}
        value={props.value}
        required={!props.fromController && props.required}
        onInput={onDateInput}
        disabled={props.disabled}
      />
    </>
  );
};

export interface DatePickerWithControllerProps<TFieldValues>
  extends Omit<DatePickerProps, "onInput" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const DatePickerWithController = <TFieldValues,>({
  id,
  label,
  required,
  name,
  disabled,
  className,
  ...props
}: DatePickerWithControllerProps<TFieldValues>) => {
  const { t } = useTranslation();

  return (
    <Controller
      {...props}
      name={name}
      rules={{
        required: {
          value: required ?? false,
          message: t("field_is_required"),
        },
        ...props.rules,
      }}
      render={({ field: { value, onChange } }) => (
        <DatePicker
          id={id ?? name}
          required={required}
          label={label}
          onInput={onChange}
          value={value as string}
          disabled={disabled}
          className={className}
          fromController
        />
      )}
    />
  );
};

export default DatePicker;

/// For some reason, the date pickers in this component aren't accepting
/// date strings like "2024-01-01T00:00:00", even though they work on other pages..
/// trimming off the "T00:00:00" part seems to make it work correctly.
export function formatDateForDatePicker(dateString?: string | null) {
  return dateString ? dateString.split("T")[0] : "";
}
