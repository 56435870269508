import { useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import NAICSSectorDetails from "components/molecules/listing/sectors/NAICSSectorDetails";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import { NAICS_VERSION } from "config/constants";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import {
  ListingWsSector,
  Maybe,
  NaicsCodeDetailsDocument,
} from "generated/gql-types";
import { bilingualTextNameForLanguage } from "mappers";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface SectorCardProps {
  sector?: Maybe<ListingWsSector>;
  listingId: string;
  onDelete: (sectorId: string) => void;
}

export const SectorCard: React.FC<SectorCardProps> = (props) => {
  const { i18n, t } = useTranslation();

  const { loading, error, data } = useQuery(NaicsCodeDetailsDocument, {
    variables: {
      params: {
        code: props?.sector?.naics?.code ?? "error",
        version: NAICS_VERSION,
      },
    },
    skip: props?.sector?.naics == null,
  });

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  if (loading) {
    return (
      <SectionCard
        header={
          isNullOrEmpty(props.sector?.naics) ? (
            <MissingData />
          ) : (
            <h2>
              {props.sector?.naics?.code}
              {" - "}
              {bilingualTextNameForLanguage(
                i18n.language,
                props.sector?.naics?.className
              )}
            </h2>
          )
        }
      >
        <LoadingIndicator centered />
      </SectionCard>
    );
  }

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-center mrgn-bttm-md">
          {isNullOrEmpty(props.sector?.naics) ? (
            <MissingData />
          ) : (
            <h2 className="mrgn-0">
              {props.sector?.naics?.code}
              {" - "}
              {bilingualTextNameForLanguage(
                i18n.language,
                props.sector?.naics?.className
              )}
            </h2>
          )}
          <div>
            <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.sector.delete}>
              <DeleteButtonAndModal
                onDelete={() =>
                  props.onDelete(props?.sector?.sectorId ?? "error")
                }
                sectorId={props.sector?.sectorId ?? "error"}
              />
            </RenderWhenAuthorized>
            <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.sector.update}>
              {/* TODO: button shouldn't be inside link */}
              <Link
                to={ListingPathHelpers.ListingSectorsEdit(
                  props.listingId,
                  props.sector?.sectorId ?? "error"
                )}
              >
                <button
                  type="button"
                  className="btn btn-link py-0"
                  data-testid={`sector-edit-btn-${
                    props.sector?.sectorId ?? "error"
                  }`}
                >
                  {t("edit")}
                </button>
              </Link>
            </RenderWhenAuthorized>
          </div>
        </div>
      }
    >
      <div className="species-field">
        <NAICSSectorDetails naicsCodeDetails={data?.naicsCodeDetails} />
      </div>

      <div className="species-field separator-line-top">
        <div className="row">
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{`${t("activities")}`}</dt>
              {isNullOrEmpty(props?.sector?.activities) ? (
                <MissingData />
              ) : null}
              {props?.sector?.activities?.map((x, i) => (
                <dd key={i + "" + x?.id}>
                  {isNullOrEmpty(x) ? (
                    <MissingData />
                  ) : (
                    <p>
                      {bilingualTextNameForLanguage(i18n.language, x?.name)}
                    </p>
                  )}
                </dd>
              ))}
            </div>
          </dl>
          <dl>
            <div className="species-data col-sm-6">
              <dt className="text-muted">{`${t("outcomes")}`}</dt>
              {isNullOrEmpty(props?.sector?.outcomes) ? <MissingData /> : null}
              {props?.sector?.outcomes?.map((x, i) => (
                <dd key={i + "" + x?.id}>
                  {isNullOrEmpty(x) ? (
                    <MissingData />
                  ) : (
                    <p>
                      {bilingualTextNameForLanguage(i18n.language, x?.name)}
                    </p>
                  )}
                </dd>
              ))}
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default SectorCard;

const DeleteButtonAndModal: React.FC<{
  onDelete: () => void;
  disabled?: boolean;
  sectorId: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmDeleteModal = useConfirmationModal({});
  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  return (
    <>
      <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.sector.delete}>
        <button
          type="button"
          className="btn btn-link py-0"
          onClick={confirmDeleteModal.open}
          disabled={props.disabled}
          data-testid={`sector-delete-btn-${props.sectorId}`}
        >
          {t("delete")}
        </button>
      </RenderWhenAuthorized>
      {props.disabled ? null : (
        <ConfirmationModal
          modalState={confirmDeleteModal}
          title={t("delete_a_sector")}
          confirmBtnEnabled={confirmDeleteState}
          onConfirm={props.onDelete}
          onCancel={() => {
            confirmDeleteModal.close();
            setConfirmDeleteState(false);
          }}
        >
          <Alert
            type={AlertTypes.WARNING}
            content={t("delete_sector_warning")}
          />

          <label>
            <input
              type="checkbox"
              checked={confirmDeleteState}
              onChange={(e) => setConfirmDeleteState((x) => !x)}
              data-testid="modal-checkbox-confirm-delete"
            />{" "}
            {t("delete_confirm")}
          </label>
        </ConfirmationModal>
      )}
    </>
  );
};
