import { isString } from "lodash";

const htmlRemoveOuterPTag = (html: string | null | undefined) => {
  if (isString(html)) {
    return html.replace(/^<p>|<\/p>$/g, "");
  }
  return html;
};

export default htmlRemoveOuterPTag;
