import cc from "classcat";
import { i18n } from "i18n";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { sanitizeAndTrimHtml } from "../../util/richtext";
import styles from "./RichMDEditor.module.css";

const modules = {
  toolbar: {
    container: [
      [{ header: [false, 2, 3, 4] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      ["link"],
      ["clean"],
    ],
    // Create custom link handler to resolve overlay conflict issue (bug 39708)
    handlers: {
      link: function (value: any) {
        const toolbar: any = this;
        if (value) {
          var href = prompt(i18n.t("enter_the_url") + ":");
          toolbar.quill.format("link", href);
        } else {
          toolbar.quill.format("link", false);
        }
      },
    },
  },
  keyboard: { bindings: { tab: false } },
};

interface FullHTMLEditorProps {
  defaultValue: string;
  placeholder?: string;
  onBlur?: (newMd: string) => void;
  onChange?: (newMd: string) => void;
  id: string;
  maxLength?: number;
  className?: string;
  disabled?: boolean;
}

/**
 * FullHTMLEditor
 *
 * @param {string} defaultValue - Markdown string
 * @param {string} placeholder
 */
export const FullHTMLEditor: React.FC<FullHTMLEditorProps> = (props) => {
  const isReadOnly = props.disabled != null ? props.disabled : false;
  const onChange = (newValue: string) => {
    if (props.onChange) {
      const sanitized = sanitizeAndTrimHtml(newValue);
      props.onChange(sanitized);
    }
  };

  return (
    // TODO: add a warning message for max length?
    <ReactQuill
      id={props.id}
      theme="snow"
      defaultValue={props.defaultValue}
      onChange={onChange}
      placeholder={props.placeholder}
      modules={modules}
      className={cc([styles.richMdEditor, props.className])}
      readOnly={isReadOnly}
    />
  );
};

interface FullHTMLEditorWithControllerProps<TFieldValues extends FieldValues>
  extends FullHTMLEditorProps,
    Omit<ControllerProps<TFieldValues>, "render" | "defaultValue"> {
  defaultValue: string; // defaultValue type should match that of FullHTMLEditor, not the one in Controller, so we re-define it here.
  render?: never; // Make editor hints be quiet
  control: Control<TFieldValues>;
  disabled?: boolean | undefined;
}

// Wrapper component to work with react-hook-form's <Controller />
export const FullHTMLEditorWithController = <TFieldValues extends FieldValues>(
  props: FullHTMLEditorWithControllerProps<TFieldValues>
) => {
  const {
    id,
    placeholder,
    defaultValue,
    maxLength,
    className,
    disabled,
    ...controllerProps
  } = props;

  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange } }) => (
        <FullHTMLEditor
          id={id}
          maxLength={maxLength}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={className}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    />
  );
};
