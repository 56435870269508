import { useQuery } from "@apollo/client";
import NoResults from "components/atoms/NoResults";
import * as React from "react";
import { useMemo } from "react";
import { getI18n, useTranslation } from "react-i18next";
import {
  AddContactSearchOrganizationsDocument,
  ArchiveStatus,
  ContactTag,
  Maybe,
  Organization,
} from "../../../generated/gql-types";
import { usePagination } from "../../../hooks/util/usePagination";
import isNullOrEmpty from "../../../util/isNullOrEmpty";
import GraphqlError from "../../GraphqlError";
import LoadingIndicator from "../../atoms/LoadingIndicator";
import { default as PaginationComponent } from "../../molecules/Pagination";
import { formatOrganizationListItem } from "../contacts/AddContactButtonAndModal/AddContactModalSearch";
import AddContactModalResult from "../contacts/AddContactButtonAndModal/AddContactModalResult";

export interface AddOrganizationSearchProps {
  search: string;
  tags?: ContactTag | string;
  onChange: (newValue: Maybe<Organization>) => void;
}

export const AddOrganizationModalSearchOrganization: React.FC<
  AddOrganizationSearchProps
> = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination();

  React.useEffect(() => {
    pagination.setPageSize(5);
  }, []);

  React.useEffect(() => {
    props.onChange(null);
  }, [pagination.currentPage]);

  const { loading, error, data } = useQuery(
    AddContactSearchOrganizationsDocument,
    {
      variables: {
        params: {
          pageNumber: pagination.currentPage,
          pageSize: pagination.pageSize,
          search: props.search,
          status: ArchiveStatus.Active,
          searchType: "contains",
          tags: props.tags,
          sortBy:
            i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
        },
      },
    }
  );

  const formattedResults = useMemo(() => {
    if (isNullOrEmpty(data)) return [];
    if (isNullOrEmpty(data?.organizationList?.organization)) return [];
    return data?.organizationList?.organization?.map((x: any) =>
      formatOrganizationListItem(x)
    );
  }, [data]);

  if (loading) return <LoadingIndicator />;
  if (error) return <GraphqlError errors={error} />;
  return (
    <>
      <fieldset className="chkbxrdio-grp">
        <legend className="pull-left width-auto pb-2_5">
          <span className="field-name">{t("search_results")}</span>
        </legend>
        <div className="pull-right py-2">
          {pagination.makeShowingString(
            data?.organizationList?.pagination?.totalCount
          )}
        </div>
        <div className="clearfix"></div>

        {formattedResults == null || formattedResults.length === 0 ? (
          <NoResults centered className="mrgn-bttm-md" />
        ) : null}
        {formattedResults?.map(
          (result: any, index: React.Key | null | undefined) => (
            <div key={index}>
              <div className="list-item px-2">
                <AddContactModalResult
                  result={result}
                  onChange={(id: string) => {
                    const newSelectedValue =
                      data?.organizationList?.organization?.find(
                        (x) => x?.id === id
                      );
                    props.onChange(newSelectedValue as Organization);
                  }}
                />
              </div>
              {index !== formattedResults.length - 1 && (
                <hr className="mrgn-tp-0 mrgn-bttm-0" />
              )}
            </div>
          )
        )}
      </fieldset>

      <PaginationComponent
        {...pagination.paginationComponentProps}
        totalPages={data?.organizationList?.pagination?.totalPages ?? 0}
      />
    </>
  );
};
