import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForVarietyDocument,
  IdNameType,
  Variety,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";

const additionalQueryVariables = {
  params: { searchType: "startsWith", isCaseSensitive: false },
};

const VarietyAutocomplete: React.FC<VarietyAutocompleteProps> = (props) => {
  return (
    <QueryAutocomplete
      id={"varietyAutocomplete"}
      queryDocument={AutocompleteForVarietyDocument}
      label={props.label}
      listDisplayFormatter={(item) => item.name}
      onSelectionChange={props.onSelectionChange}
      placeholder={props.initialVariety?.name ?? ""}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

interface VarietyAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newVariety?: null | Variety | IdNameType) => void;
  initialVariety?: Variety | IdNameType;
  label: string | ReactElement;
}

export default VarietyAutocomplete;
