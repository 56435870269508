import { useMutation } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import DocumentForm, {
  DocumentFormFields,
} from "components/organisms/documents/DocumentForm/DocumentForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/documents/DocumentForm/mappers";
import { useGlobalAlertContext } from "features/globalAlert";
import { CreateDocumentDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DocumentsPathHelpers } from "./DocumentsRouter";

export interface DocumentsCreatePageProps {}

export const DocumentsCreatePage: React.FC<DocumentsCreatePageProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const [createDocument, { loading: saving, error: savingError }] = useMutation(
    CreateDocumentDocument
  );

  const onSubmit: SubmitHandler<Partial<DocumentFormFields>> = async (
    FilteredFormData
  ) => {
    const mappedDomainModel = mapFormToDomainModel(FilteredFormData);

    try {
      const res = await createDocument({
        variables: {
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("document_successfully_added"),
        // message:
        //   bilingualRichTextObjectForLanguage(
        //     i18n.language,
        //     res.data?.createPermitDescription?.title
        //   )?.plainText ?? "",
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.DocumentsProfile(
          res.data?.createDocument?.id ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: DocumentsPathHelpers.DocumentsSearch,
    });
  };

  const initialFormFields = mapDomainModelToForm(null);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("create_a_document")}</h1>
          <GraphqlError
            title="Error creating new document"
            errors={savingError}
          />
          <DocumentForm
            initialValues={initialFormFields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default DocumentsCreatePage;
