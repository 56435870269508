import ResetIcon from "assets/svg/reset-icon.svg";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface QueryFields {
  search: string;
}

export const defaultQueryFields = {
  search: "",
};

export interface PermitIdSearchFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const PermitIdSearchForm: React.FC<PermitIdSearchFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, handleSubmit, setFocus, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    setFocus("search");
  };

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    props.onSubmit(newValues);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1-1-auto align-end flex-wrap pb-2"
    >
      <div className="flex-1-1-auto">
        <label htmlFor="permitIdSearch">{t("search_permit_id")}</label>
        <div className="input-group mrgn-rght-sm">
          <input
            type="text"
            id="permitIdSearch"
            className="form-control search-box"
            placeholder={t("enter_a_permit_id")}
            {...register("search")}
          />
          <button
            type="submit"
            aria-label="Clear search"
            className="search-box-reset"
            hidden={watch("search") === ""}
            onClick={onClearSearch}
            data-testid="button-clear"
          >
            <img src={ResetIcon} alt={t("reset")} />
          </button>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-tp-sm"
        data-testid="button-search"
      >
        <span className="glyphicon glyphicon-search mrgn-rght-sm" />
        {t("search")}
      </button>
    </form>
  );
};

export default PermitIdSearchForm;
