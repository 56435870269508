import SectionCard from "components/atoms/SectionCard";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import BilingualToggleContainer from "components/molecules/BilingualToggleContainer";
import { DatePickerWithController } from "components/molecules/DatePicker";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { AssessmentDateDropdownWithController } from "components/organisms/cosewic/AssessmentDateDropdown/AssessmentDateDropdown";
import { setNullForEmptyProcessNoteFields } from "components/organisms/listing/ListingProcessCreateForm/mappers";
import { MAX_LENGTH_OF_NOTE } from "config/constants";
import { BilingualNoteInput } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ListingProcessType } from "types/listing/enums";
import { filterForDirtyFields } from "util/forms";
import { htmlToPlainText } from "util/richtext";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";

export interface ListingProcessCreateFormProps {
  onSubmit: SubmitHandler<Partial<ListingProcessCreateFormFields>>;
  isStandaloneListing?: boolean;
}

export const ListingProcessCreateForm: React.FC<
  ListingProcessCreateFormProps
> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;

  const [listingProcessType, setListingProcessType] = React.useState({});

  const form = useForm<ListingProcessCreateFormFields>({});

  const {
    handleSubmit,
    register,
    formState,
    control,
    setValue,
    getValues,
    clearErrors,
  } = form;
  const { isDirty, errors, dirtyFields } = formState;

  const onSubmit: SubmitHandler<
    Partial<ListingProcessCreateFormFields>
  > = async (values) => {
    const filteredValues = filterForDirtyFields(values, dirtyFields);
    setNullForEmptyProcessNoteFields(filteredValues, dirtyFields);
    props.onSubmit(filteredValues);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingProcesses(listingWsId ?? "error"),
    });
  };

  const validateAtLeastOneProcessNote = () =>
    htmlToPlainText(getValues("processNote.english.text") ?? "") !== "" ||
    htmlToPlainText(getValues("processNote.french.text") ?? "") !== "";

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_NOTE;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionCard header={<h2>{t("listing_type")}</h2>}>
          <div className="flex-col gap-md">
            <div className="form-group">
              <fieldset className="chkbxrdio-grp">
                <legend className="required">
                  <span className="field-name">
                    {t("select_a_type_of_listing_process")}
                  </span>{" "}
                  <strong className="required">({t("required")})</strong>
                </legend>
                <div className="radio">
                  <label htmlFor="rdo_associate_to_assessment">
                    <input
                      type="radio"
                      value={ListingProcessType.LinkToAssessment}
                      id="rdo_associate_to_assessment"
                      disabled={props.isStandaloneListing}
                      checked={
                        listingProcessType === undefined ||
                        listingProcessType ===
                          ListingProcessType.LinkToAssessment
                      }
                      onClick={() => {
                        setListingProcessType(
                          ListingProcessType.LinkToAssessment
                        );
                        setValue("ministerOpinionDate", "");
                        clearErrors("ministerOpinionDate");
                      }}
                      data-rule-required="true"
                      {...register("listingProcessType", {
                        required: true,
                      })}
                    />
                    &#160;&#160;
                    {t(ListingProcessType.LinkToAssessment)}
                  </label>
                </div>
                <div className="radio">
                  <label htmlFor="rdo_imminent_threat" className="radio-inline">
                    <input
                      type="radio"
                      value={ListingProcessType.ImminentThreat}
                      id="rdo_imminent_threat"
                      checked={
                        listingProcessType === ListingProcessType.ImminentThreat
                      }
                      onClick={() => {
                        setListingProcessType(
                          ListingProcessType.ImminentThreat
                        );
                        setValue("assessmentId", "");
                        clearErrors("assessmentId");
                      }}
                      {...register("listingProcessType", {
                        required: true,
                      })}
                    />
                    &#160;&#160;{t(ListingProcessType.ImminentThreat)}
                  </label>
                </div>
                <div className="radio">
                  <label htmlFor="rdo_other" className="radio-inline">
                    <input
                      type="radio"
                      value={ListingProcessType.Other}
                      id="rdo_other"
                      checked={listingProcessType === ListingProcessType.Other}
                      onClick={() => {
                        setListingProcessType(ListingProcessType.Other);
                        setValue("assessmentId", "");
                        clearErrors("assessmentId");
                      }}
                      {...register("listingProcessType", {
                        required: true,
                      })}
                    />
                    &#160;&#160;{t(ListingProcessType.Other)}
                  </label>
                </div>
              </fieldset>
              {errors.listingProcessType && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            </div>

            {listingProcessType !==
            ListingProcessType.LinkToAssessment ? null : (
              <div className="form-group">
                <label htmlFor="select_assessment" className="required">
                  <span className="field-name">
                    {t("select_an_assessment")}
                  </span>{" "}
                  <strong className="required">({t("required")})</strong>
                </label>
                <AssessmentDateDropdownWithController
                  name="assessmentId"
                  control={control}
                  listingId={listingWsId}
                  id={"select_assessment"}
                  required
                />
                {errors.assessmentId && (
                  <FieldValidationError>
                    {errors.assessmentId.message}
                  </FieldValidationError>
                )}
              </div>
            )}
            {listingProcessType !== ListingProcessType.ImminentThreat &&
            listingProcessType !== ListingProcessType.Other ? null : (
              <div className="form-group">
                <DatePickerWithController
                  name="ministerOpinionDate"
                  control={control}
                  id="select_ministersOpinion"
                  label={
                    listingProcessType === ListingProcessType.ImminentThreat
                      ? t("threat_date")
                      : t("date")
                  }
                  required
                />
                {errors.ministerOpinionDate && (
                  <FieldValidationError>
                    {errors.ministerOpinionDate.message}
                  </FieldValidationError>
                )}
              </div>
            )}
            <div>
              <fieldset className="subheader-grp">
                <legend className="required border-bottom-none">
                  <span className="field-name">{t("process_note")}</span>{" "}
                  <strong className="required">({t("required")})</strong>
                </legend>

                <BilingualToggleContainer
                  className="form-group border-light"
                  data-testid="process-note-rte"
                  english={
                    <div className="form-group">
                      <label
                        className="font-size-14"
                        htmlFor="processNote.english.text"
                      >
                        {t("english_text")}
                      </label>
                      <FullHTMLEditorWithController
                        control={control}
                        rules={{
                          validate: {
                            atLeastOneProcessNote:
                              validateAtLeastOneProcessNote,
                            maxLengthLimit: validateHtmlTextMaxLengthLimit,
                          },
                        }}
                        id="processNote.english.text"
                        name="processNote.english.text"
                        defaultValue={""}
                      />
                      {errors.processNote?.english &&
                        (errors.processNote.english as any)?.text?.type ===
                          "maxLengthLimit" && (
                          <FieldValidationError>
                            {t("reach_the_max_length", {
                              count: MAX_LENGTH_OF_NOTE,
                            })}
                          </FieldValidationError>
                        )}
                      {errors.processNote?.english &&
                        (errors.processNote.english as any)?.text?.type ===
                          "atLeastOneProcessNote" &&
                        errors.processNote?.french &&
                        (errors.processNote.french as any)?.text?.type ===
                          "atLeastOneProcessNote" && (
                          <FieldValidationError>
                            {t("at_least_one_language_is_required")}
                          </FieldValidationError>
                        )}
                    </div>
                  }
                  french={
                    <div className="form-group">
                      <label
                        className="font-size-14"
                        htmlFor="processNote.french.text"
                      >
                        {t("french_text")}
                      </label>
                      <FullHTMLEditorWithController
                        control={control}
                        rules={{
                          validate: {
                            atLeastOneProcessNote:
                              validateAtLeastOneProcessNote,
                            maxLengthLimit: validateHtmlTextMaxLengthLimit,
                          },
                        }}
                        defaultValue={""}
                        id="processNote.french.text"
                        name="processNote.french.text"
                      />
                      {errors.processNote?.french &&
                        (errors.processNote.french as any)?.text?.type ===
                          "maxLengthLimit" && (
                          <FieldValidationError>
                            {t("reach_the_max_length", {
                              count: MAX_LENGTH_OF_NOTE,
                            })}
                          </FieldValidationError>
                        )}
                      {errors.processNote?.english &&
                        (errors.processNote.english as any)?.text?.type ===
                          "atLeastOneProcessNote" &&
                        errors.processNote?.french &&
                        (errors.processNote.french as any)?.text?.type ===
                          "atLeastOneProcessNote" && (
                          <FieldValidationError>
                            {t("at_least_one_language_is_required")}
                          </FieldValidationError>
                        )}
                    </div>
                  }
                />
              </fieldset>
            </div>
          </div>
        </SectionCard>

        <FormButtons isDirty={isDirty} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};

export default ListingProcessCreateForm;

export interface ListingProcessCreateFormFields {
  listingProcessType: ListingProcessType;
  assessmentId: Maybe<string> | undefined;
  ministerOpinionDate: Maybe<string> | undefined;
  processNote: BilingualNoteInput;
}
