import * as React from "react";
import { useTranslation } from "react-i18next";
import { EditPhasePopupProps, usePhasePopup } from "./EditPhaseModal";
import EditPhasePopup from "./EditPhaseModal";

interface EditPhaseModalWithButtonProps
  extends Omit<EditPhasePopupProps, "modalState"> {
  buttonLabel?: string;
}

const EditPhaseBtn: React.FC<EditPhaseModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = usePhasePopup({});
  const label = buttonLabel ?? t("edit");
  const validPhaseId = props.templateId != null && props.phaseInfo?.id != null;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        title={t("edit_phase")}
        disabled={!validPhaseId}
        data-testid="button-edit"
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {validPhaseId ? (
        <EditPhasePopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditPhaseBtn;
