import * as React from "react";
import { useTranslation } from "react-i18next";
import AddLinkPopup, { useManageFilesPopup } from "./AddLinkPopup";

const AddLinkBtn: React.FC<any> = ({
   ...props
}) => {
  const { t } = useTranslation();
  const modalState = useManageFilesPopup({});
  const validDocumentId =
    props.documentId != null && props.documentId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validDocumentId}
        data-testid="button-add-link"
      >      
          <i className="fas fa-link mrgn-rght-sm"></i>
          {t("add_link")}        
      </button>
      {validDocumentId ? (
        <AddLinkPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddLinkBtn;
