import {
  ListingProcessDocumentType,
  ListingWsProcess,
  ListingWsProcessQuery,
  Maybe,
  UpdateListingWsProcessInput,
} from "generated/gql-types";
import { set } from "lodash";
import { makeListingProcessDocument } from "mappers";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import { filterForDirtyFields } from "../../../../util/forms";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import type { ListingProcessFormFields } from "./ListingProcessForm";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import * as FormMappers from "util/formMappers";

export function mapDomainModelToForm(
  domainModel:
    | Maybe<Partial<ListingWsProcess>>
    | ListingWsProcessQuery["listingWsProcess"]
): ListingProcessFormFields {
  const defaultOrFindDocumentMatchingType = (
    type: ListingProcessDocumentType
  ) => {
    if (!domainModel || !domainModel.documents) {
      return makeListingProcessDocument(type);
    }

    const found = domainModel?.documents?.find((x) => x?.type === type);
    return makeListingProcessDocument(type, found);
  };

  const out: any = {};
  FormMappers.mapPrimitiveType(
    out,
    "ministerOpinionDate",
    formatTimestamp(
      domainModel?.ministerOpinionDate,
      DisplayFormat.YEAR_MONTH_DAY
    )
  );
  FormMappers.mapConsultationProcess(
    out,
    "consultationProcess",
    domainModel?.consultationProcess
  );
  FormMappers.mapRegulatoryProcess(
    out,
    "regulatoryProcess",
    domainModel?.regulatoryProcess
  );
  FormMappers.mapListingProcessDocument(
    out,
    "documents_consultationEndDate",
    defaultOrFindDocumentMatchingType(
      ListingProcessDocumentType.ConsultationEndDate
    )
  );
  FormMappers.mapListingProcessDocument(
    out,
    "documents_cgIProposalDate",
    defaultOrFindDocumentMatchingType(
      ListingProcessDocumentType.CgIProposalDate
    )
  );
  FormMappers.mapListingProcessDocument(
    out,
    "documents_cgIiGicReceipt",
    defaultOrFindDocumentMatchingType(ListingProcessDocumentType.CgIiGicReceipt)
  );
  FormMappers.mapListingProcessDocument(
    out,
    "documents_cgIiListingOrder",
    defaultOrFindDocumentMatchingType(
      ListingProcessDocumentType.CgIiListingOrder
    )
  );
  FormMappers.mapListingProcessDocument(
    out,
    "documents_cgIiNotListReferBack",
    defaultOrFindDocumentMatchingType(
      ListingProcessDocumentType.CgIiNotListReferBack
    )
  );
  FormMappers.mapBilingualNote(out, "processNote", domainModel?.processNote);
  FormMappers.mapPrimitiveType(
    out,
    "dateOfAdditionOnSchedule1ParentId",
    domainModel?.dateOfAdditionOnSchedule1ParentId
  );

  return out as ListingProcessFormFields;
}

export async function processListingProcessFormData(
  formData: UnpackNestedValue<Partial<ListingProcessFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: ListingProcessFormFields
) {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForEmptyFields(values, dirtyFields, initialValues);
  //convertNumberFields(values, dirtyFields);
  const formInput = mapFormToUpdateInput(values);

  if (checkDocumentsIsDirty(dirtyFields)) {
    convertDocumentsFieldsToArray(formInput, dirtyFields, initialValues);
  }

  return formInput;
}

export function mapFormToUpdateInput(form: Partial<ListingProcessFormFields>) {
  const out: any = {};
  FormMappers.mapPrimitiveTypeInput(
    out,
    "ministerOpinionDate",
    form?.ministerOpinionDate
  );
  FormMappers.mapConsultationProcessInput(
    out,
    "consultationProcess",
    form?.consultationProcess
  );
  FormMappers.mapRegulatoryProcessInput(
    out,
    "regulatoryProcess",
    form?.regulatoryProcess
  );
  FormMappers.mapListingProcessDocumentInput(
    out,
    "documents_consultationEndDate",
    form?.documents_consultationEndDate
  );
  FormMappers.mapListingProcessDocumentInput(
    out,
    "documents_cgIProposalDate",
    form?.documents_cgIProposalDate
  );
  FormMappers.mapListingProcessDocumentInput(
    out,
    "documents_cgIiGicReceipt",
    form?.documents_cgIiGicReceipt
  );
  FormMappers.mapListingProcessDocumentInput(
    out,
    "documents_cgIiListingOrder",
    form?.documents_cgIiListingOrder
  );
  FormMappers.mapListingProcessDocumentInput(
    out,
    "documents_cgIiNotListReferBack",
    form?.documents_cgIiNotListReferBack
  );
  FormMappers.mapBilingualNoteInput(out, "processNote", form?.processNote);
  FormMappers.mapPrimitiveTypeInput(
    out,
    "dateOfAdditionOnSchedule1ParentId",
    form?.dateOfAdditionOnSchedule1ParentId
  );

  return out as UpdateListingWsProcessInput;
}

// function convertNumberFields(
//   formData: UnpackNestedValue<Partial<ListingProcessFormFields>>,
//   dirtyFields: FieldNamesMarkedBoolean<any>
// ) {
//   if (
//     dirtyFields?.consultationProcess?.listingBatch &&
//     formData.consultationProcess?.listingBatch != null
//   ) {
//     const asString = formData.consultationProcess?.listingBatch.toString();
//     if (isNullOrEmpty(asString)) return;
//     formData.consultationProcess.listingBatch = parseInt(asString);
//   }
// }

function setNullForEmptyFields(
  formData: UnpackNestedValue<Partial<ListingProcessFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: ListingProcessFormFields
) {
  if (
    dirtyFields?.consultationProcess?.responseStatementPostedDate != null &&
    isNullOrEmpty(formData?.consultationProcess?.responseStatementPostedDate)
  ) {
    set(formData, "consultationProcess.responseStatementPostedDate", null);
  }

  if (
    dirtyFields?.consultationProcess?.consultationPath &&
    isNullOrEmpty(formData?.consultationProcess?.consultationPath)
  ) {
    set(formData, "consultationProcess.consultationPath", null);
  }

  if (
    dirtyFields?.consultationProcess?.listingBatch != null &&
    isNullOrEmpty(formData?.consultationProcess?.listingBatch)
  ) {
    set(formData, "consultationProcess.listingBatch", null);
  }

  if (
    dirtyFields?.consultationProcess?.amendmentAlignedToAssessment != null &&
    isNullOrEmpty(formData?.consultationProcess?.amendmentAlignedToAssessment)
  ) {
    set(formData, "consultationProcess.amendmentAlignedToAssessment", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.regulatoryBundle != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.regulatoryBundle)
  ) {
    set(formData, "regulatoryProcess.regulatoryBundle", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.rationale?.english != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.rationale?.english?.text)
  ) {
    set(formData, "regulatoryProcess.rationale.english", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.rationale?.french != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.rationale?.french?.text)
  ) {
    set(formData, "regulatoryProcess.rationale.french", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.gicReceiptDate != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.gicReceiptDate)
  ) {
    set(formData, "regulatoryProcess.gicReceiptDate", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.gicDecisionDate != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.gicDecisionDate)
  ) {
    set(formData, "regulatoryProcess.gicDecisionDate", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.gicDecision != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.gicDecision)
  ) {
    set(formData, "regulatoryProcess.gicDecision", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.cgiListingProposal != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.cgiListingProposal)
  ) {
    set(formData, "regulatoryProcess.cgiListingProposal", null);
  }

  if (
    dirtyFields?.regulatoryProcess?.cosewicToReassess != null &&
    isNullOrEmpty(formData?.regulatoryProcess?.cosewicToReassess)
  ) {
    set(formData, "regulatoryProcess.cosewicToReassess", null);
  }

  if (
    dirtyFields?.documents_consultationEndDate != null &&
    isNullOrEmpty(formData?.documents_consultationEndDate?.publicationDate) &&
    isNullOrEmpty(formData?.documents_consultationEndDate?.uri)
  ) {
    set(formData, "documents_consultationEndDate", null);
  }

  if (
    dirtyFields?.documents_cgIProposalDate != null &&
    isNullOrEmpty(formData?.documents_cgIProposalDate?.publicationDate) &&
    isNullOrEmpty(formData?.documents_cgIProposalDate?.uri)
  ) {
    set(formData, "documents_cgIProposalDate", null);
  }

  if (
    dirtyFields?.documents_cgIiGicReceipt != null &&
    isNullOrEmpty(formData?.documents_cgIiGicReceipt?.publicationDate) &&
    isNullOrEmpty(formData?.documents_cgIiGicReceipt?.uri)
  ) {
    set(formData, "documents_cgIiGicReceipt", null);
  }

  if (
    dirtyFields?.documents_cgIiListingOrder != null &&
    isNullOrEmpty(formData?.documents_cgIiListingOrder?.publicationDate) &&
    isNullOrEmpty(formData?.documents_cgIiListingOrder?.uri)
  ) {
    set(formData, "documents_cgIiListingOrder", null);
  }

  if (
    dirtyFields?.documents_cgIiNotListReferBack != null &&
    isNullOrEmpty(formData?.documents_cgIiNotListReferBack?.publicationDate) &&
    isNullOrEmpty(formData?.documents_cgIiNotListReferBack?.uri)
  ) {
    set(formData, "documents_cgIiNotListReferBack", null);
  }

  if (
    dirtyFields?.processNote?.english != null &&
    htmlIsNullOrEmpty(formData.processNote?.english?.text)
  ) {
    set(formData, "processNote.english", null);
  }
  if (
    dirtyFields?.processNote?.french != null &&
    htmlIsNullOrEmpty(formData.processNote?.french?.text)
  ) {
    set(formData, "processNote.french", null);
  }
}

function convertDocumentsFieldsToArray(
  formData: any,
  dirtyFields: any,
  initialValues: any
) {
  const documents: any = [];

  const maybePushDocumentValueForKey = (
    key: keyof typeof formData | keyof typeof initialValues
  ) => {
    // this function assumes the formData has gone through filterForDirtyFields and setNullForEmptyFields first;

    // null means it was cleared; do nothing, it should be excluded from the output array.
    // we could additionally check dirtyFields here?
    if (formData[key] === null) return;

    // undefined means it wasn't touched (filterForDirtyFields excluded it from the formData);
    // try adding the value from initialValues, as long as it's valid
    if (formData[key] === undefined) {
      if (!initialValues[key]) return;
      if (isNullOrEmpty(initialValues[key]?.publicationDate)) return;
      if (isNullOrEmpty(initialValues[key]?.uri)) return;

      documents.push(initialValues[key]);
      return;
    }

    // fallback; add any other value the form gave us
    documents.push(formData[key]);
  };

  allDocumentFieldKeys.forEach((key) => {
    maybePushDocumentValueForKey(key);
    delete formData[key];
  });

  (formData as any).documents = documents;
}

const allDocumentFieldKeys = [
  "documents_consultationEndDate",
  "documents_cgIProposalDate",
  "documents_cgIiGicReceipt",
  "documents_cgIiNotListReferBack",
  "documents_cgIiListingOrder",
];

function checkDocumentsIsDirty(dirtyFields: FieldNamesMarkedBoolean<any>) {
  return allDocumentFieldKeys
    .map((x) => (dirtyFields as any)[x] === true)
    .includes(true);
}

// export function mapFormToUpdateInput(form: Partial<ListingProcessFormFields>) {
//   const out: Partial<UpdateListingWsProcessInput> = {};
//
//   if (form.ministerOpinionDate)
//     out.ministerOpinionDate = form.ministerOpinionDate;
//
//   const consultationProcess: Partial<ListingProcessConsultationProcessInput> =
//     {};
//   if (form.consultationProcess?.responseStatementPostedDate)
//     consultationProcess.responseStatementPostedDate =
//       form.consultationProcess?.responseStatementPostedDate;
//   if (form.consultationProcess?.consultationPath)
//     consultationProcess.consultationPath =
//       form.consultationProcess?.consultationPath;
//   if (form.consultationProcess?.listingBatch !== undefined)
//     consultationProcess.listingBatch =
//       form.consultationProcess?.listingBatch.toString() !== ""
//         ? parseInt(form.consultationProcess?.listingBatch.toString())
//         : null;
//   if (form.consultationProcess?.amendmentAlignedToAssessment)
//     consultationProcess.amendmentAlignedToAssessment =
//       form.consultationProcess?.amendmentAlignedToAssessment;
//   if (Object.keys(consultationProcess).length > 0)
//     out.consultationProcess = consultationProcess;
//
//   const regulatoryProcess: Partial<ListingProcessRegulatoryProcessInput> = {};
//   if (form.regulatoryProcess?.regulatoryBundle)
//     regulatoryProcess.regulatoryBundle =
//       form.regulatoryProcess?.regulatoryBundle;
//   if (form.regulatoryProcess?.gicReceiptDate)
//     regulatoryProcess.gicReceiptDate = form.regulatoryProcess?.gicReceiptDate;
//   if (form.regulatoryProcess?.gicDecisionDate)
//     regulatoryProcess.gicDecisionDate = form.regulatoryProcess?.gicDecisionDate;
//   if (form.regulatoryProcess?.gicDecision)
//     regulatoryProcess.gicDecision = form.regulatoryProcess?.gicDecision;
//   if (form.regulatoryProcess?.cgiListingProposal)
//     regulatoryProcess.cgiListingProposal =
//       form.regulatoryProcess?.cgiListingProposal;
//   if (form.regulatoryProcess?.cosewicToReassess)
//     regulatoryProcess.cosewicToReassess =
//       form.regulatoryProcess?.cosewicToReassess;
//   if (Object.keys(regulatoryProcess).length > 0)
//     out.regulatoryProcess = regulatoryProcess;
//
//   const documents = [];
//   if (form.documents_consultationEndDate)
//     documents.push(form.documents_consultationEndDate);
//   if (form.documents_cgIProposalDate)
//     documents.push(form.documents_cgIProposalDate);
//   if (form.documents_cgIiGicReceipt)
//     documents.push(form.documents_cgIiGicReceipt);
//   if (form.documents_cgIiListingOrder)
//     documents.push(form.documents_cgIiListingOrder);
//   if (form.documents_cgIiNotListReferBack)
//     documents.push(form.documents_cgIiNotListReferBack);
//   if (documents.length > 0) out.documents = documents;
//
//   if (form.note) out.note = form.note;
//
//   return out as UpdateListingWsProcessInput;
// }
