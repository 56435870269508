import { useMutation } from "@apollo/client";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GraphqlError from "../../../components/GraphqlError";
import Layout from "../../../components/layouts/TwoColumn";
import AdminSideNav from "../../../components/molecules/AdminSideNav";
import * as ContactsFormMappers from "../../../components/organisms/contacts/contactsFormUtil";
import ContactsForm, {
  ContactFormFields,
} from "../../../components/organisms/contacts/form";
import { useGlobalAlertContext } from "../../../features/globalAlert";
import { CreateContactDocument } from "../../../generated/gql-types";
import { AdminPathHelpers } from "../AdminRouter";

const ContactsCreatePage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const [runSubmit, { loading: saving, error: savingError }] = useMutation(
    CreateContactDocument
  );

  const onSubmit: SubmitHandler<Partial<ContactFormFields>> = async (
    formData
  ) => {
    const input = ContactsFormMappers.formIntoCreateInput(formData, true);

    try {
      const res = await runSubmit({
        variables: {
          contactCreateInput: input,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_added_contact"),
        timeOut: 5000,
      });

      history.push({
        pathname: AdminPathHelpers.Contacts(),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const formDefaultValues = ContactsFormMappers.domainModelIntoForm(undefined);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("create_a_contact")}</h1>
          <GraphqlError title="Error creating contact" errors={savingError} />
          <ContactsForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            isSaving={saving}
          />
        </Layout.Content>

        <Layout.Sidebar>
          <AdminSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ContactsCreatePage;
