import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import {
  BptTemplate,
  BptTemplatePhase,
  BptTemplateStage,
} from "generated/gql-types";
import { AdminPathHelpers } from "pages/admin/AdminRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { PhaseInformation } from "../PhaseInformationCard/PhaseInformation";

export interface PhaseDetailsProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  index?: number;
  isOpen?: boolean;
  item?: BptTemplatePhase;
  bptTemplate?: BptTemplate;
  templateId?: string;
  isLoading?: boolean;
  onDeletePhase: (a: any, b: any) => void;
}

export const PhaseDetails = React.forwardRef(
  (props: PhaseDetailsProps, ref) => {
    const { t } = useTranslation();

    const {
      index,
      isOpen,
      item,
      bptTemplate,
      templateId,
      isLoading,
      onDeletePhase,
    } = props;

    return (
      <details
        key={index}
        className="detail-list bg-grey expand-collapse-list"
        open={isOpen}
      >
        <summary>
          <div className="flexbug-9-workaround">
            <div className="flex justify-between align-center">
              <div className="font-size-18">
                {t("phase")} {index! + 1}
                {": "}
                {
                  <SafeRenderHtml
                    htmlString={htmlRemoveOuterPTag(item?.name?.text!)}
                  />
                }
              </div>
              <div className="flex gap-sm align-start flex-wrap justify-right">
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.administration.bpt.updateRoles}
                >
                  <Link
                    to={{
                      pathname: AdminPathHelpers.BptTemplatesPhaseIndex(
                        templateId ?? "error",
                        item?.id ?? "error"
                      ),
                    }}
                    title={t("view_details")}
                    className="card-header-button-link hover-underline font-size-14 hover-grey"
                    data-testid="link-view-details"
                  >
                    <i className="fas fa-eye mrgn-rght-sm"></i>
                    {t("view_details")}
                  </Link>
                </RenderWhenAuthorized>
                {bptTemplate?.stage === BptTemplateStage.Draft ? (
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.bpt.updateRoles
                    }
                  >
                    <DeleteButtonModal
                      buttonText={t("delete")}
                      buttonTitle={t("delete_phase")}
                      modalTitle={t("delete_phase")}
                      alertContent={t("html_delete_phase_warning")}
                      alertConfirm={t("delete_confirm")}
                      alertType={AlertTypes.DANGER}
                      onDelete={() => onDeletePhase(templateId!, item?.id!)}
                      className="font-size-14 hover-grey vertical-align-baseline"
                    />
                  </RenderWhenAuthorized>
                ) : null}
              </div>
            </div>
          </div>
        </summary>

        {isLoading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : (
          <PhaseInformation templateId={templateId} phaseInfo={item} />
        )}
      </details>
    );
  }
);

export default PhaseDetails;
