import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import NoteModal, { useNoteModal } from "components/organisms/NoteModal";
import { Note } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import { RenderWhenAuthorized, Roles } from "../../../features/auth/components";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";
import SectionCard from "../../atoms/SectionCard";
//
export interface NoteCardProps {
  header: string;
  note?: Note | null;
  loading: boolean;
  editDisabled?: boolean;
  maxCharacters?: number;
  onUpdateNote: any;
  role: Roles[];
}


export const NoteCard: React.FC<NoteCardProps> = (props) => {
  const { header, note, loading, editDisabled = false, onUpdateNote, role, maxCharacters = 256} = props;
  const { t } = useTranslation();
  const noteModalState = useNoteModal({});

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2 className="mrgn-tp-sm" id="noteSection">
            {header}
          </h2>
          <RenderWhenAuthorized authorizedRoles={role}>
            {!loading ? (
              <EditButton onClick={noteModalState.open} disabled={editDisabled}></EditButton>
            ) : null}
          </RenderWhenAuthorized>
        </div>
      }
    >
      {loading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : htmlIsNullOrEmpty(note?.text?.text) ? (
        <MissingData />
      ) : (
        <>
          <div className="font-size-18 text-break">
            <SafeRenderHtml htmlString={note?.text?.text ?? ""} />
          </div>
          <div className="separator-line-top text-muted font-size-16">
            {formatTimestamp(note?.modifiedAt, DisplayFormat.DATE_AND_TIME) +
              " by " +
              note?.modifiedBy}
          </div>
        </>
      )}
      <NoteModal
        modalState={noteModalState}
        defaultValue={note?.text?.text ?? ""}
        maxCharacters={maxCharacters}
        onSave={onUpdateNote}
      />
    </SectionCard>
  );
};

const EditButton: React.FC<{
  onClick: () => void;
  disabled?: boolean;
}> = (props) => {
  const { t } = useTranslation();

    return (
      <div className="flex justify-between align-start">
        <button
          type="button"
          className="btn btn-link card-header-button-link"
          onClick={props.onClick}
          data-testid="note-button-edit"
          disabled={props.disabled}
        >
          <i className="fas fa-pen mrgn-rght-sm"></i>
          {t("edit")}
        </button>
      </div>
    );
};

export default NoteCard;
