import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import {
  ListingProcessSchedule1Status,
  ListingWsProcess,
} from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ListingProcessType } from "types/listing/enums";
import { formatTimestamp } from "util/formatTimestamp";

export interface SummaryOfProcessesTableProps {
  listingWsProcesses?: any; // ListingWsProcess[];
}

export const SummaryOfProcessesTable: React.FC<SummaryOfProcessesTableProps> = (
  props
) => {
  const { listingWsProcesses } = props;
  const { t } = useTranslation();

  return (
    <table
      className="species-table provisional gc-table table table-striped table-hover mrgn-bttm-sm"
      id="summaryOfProcessesTable"
    >
      <thead>
        <tr>
          <th>{t("assessment_date")}</th>
          <th>{t("assessment_status")}</th>
          <th>{t("sch_1_status_on_receipt")}</th>
          <th>{t("minister_receipt_date")}</th>
          <th>{t("consultation_path")}</th>
          <th>{t("gic_decision_date")}</th>
          <th>{t("gic_decision")}</th>
          <th>{t("listing_batch")}</th>
        </tr>
      </thead>
      <tbody>
        {!listingWsProcesses || listingWsProcesses.length === 0 ? (
          <tr>
            <td colSpan={8}>
              <NoResults centered />
            </td>
          </tr>
        ) : (
          listingWsProcesses?.map((item: ListingWsProcess) => {
            const listingProcessType =
              item.ministerOpinionDate == null
                ? ListingProcessType.LinkToAssessment
                : ListingProcessType.ImminentThreat;

            const assessmentDate = item.assessmentRef?.assessmentDate;
            const assessmentStatus = item.assessmentRef?.assessmentStatus;
            const schedule1StatusOnReceipt = item.schedule1StatusOnReceipt;
            const ministerReceiptDate =
              item.consultationProcess?.ministerReceiptDate;
            const consultationPath =
              i18n.language === "fr"
                ? item.consultationProcess?.consultationPath?.name?.french
                : item.consultationProcess?.consultationPath?.name?.english;
            const consultationPathEn =
              item.consultationProcess?.consultationPath?.name?.english;
            const gicDecisionDate = item.regulatoryProcess?.gicDecisionDate;
            const gicDecision =
              i18n.language === "fr"
                ? item.regulatoryProcess?.gicDecision?.name?.french
                : item.regulatoryProcess?.gicDecision?.name?.english;
            const listingBatch = item.consultationProcess?.listingBatch;

            const makeSchedule1StatusDisplay = (
              schedule1Status: ListingProcessSchedule1Status | null | undefined
            ) => {
              if (schedule1Status == null) {
                return <MissingData />;
              }

              if (schedule1Status === ListingProcessSchedule1Status.NoData) {
                return <MissingData />;
              }

              if (
                schedule1Status === ListingProcessSchedule1Status.NotInitialized
              ) {
                return t("not_applicable");
              }

              return t(schedule1Status);
            };

            return (
              <tr key={item.id}>
                <td data-label={t("assessment_date")}>
                  {listingProcessType === ListingProcessType.LinkToAssessment
                    ? formatTimestamp(assessmentDate) ?? <MissingData />
                    : t("not_applicable")}
                </td>
                <td data-label={t("assessment_status")}>
                  {listingProcessType ===
                  ListingProcessType.LinkToAssessment ? (
                    assessmentStatus ? (
                      t(assessmentStatus)
                    ) : (
                      <MissingData />
                    )
                  ) : (
                    t("not_applicable")
                  )}
                </td>
                <td data-label={t("sch_1_status_on_receipt")}>
                  {/* Note: Schedule 1 on receipt is populated by the system if the species was previously listed on Schedule 1
                   if not, the value should be Not applicable (US 9460) */}
                  {makeSchedule1StatusDisplay(schedule1StatusOnReceipt)}
                </td>
                <td data-label={t("minister_receipt_date")}>
                  {listingProcessType === ListingProcessType.LinkToAssessment
                    ? formatTimestamp(ministerReceiptDate) ?? <MissingData />
                    : t("not_applicable")}
                </td>
                <td data-label={t("consultation_path")}>
                  {consultationPath ?? <MissingData />}
                </td>
                <td data-label={t("gic_decision_date")}>
                  {/* Note: (US 8611) Scenario 1- COSEWIC status confirmed (the last two status are equal)
                  GIC decision date : Not applicable (the user cannot edit)*/}
                  {/*
                   * NOTE: Sept 23 2022 - This functionality is being deprecated. User story 8611 has been updated.
                   * We are allowing GIC decision/date to be edited any time (and adding "not applicable" as a user-selectable value),
                   * In order to allow for this behaviour without significant changes:
                   * https://dev.azure.com/ECCC-DevOps/SAR DMTS/_queries/edit/34367/
                   */}
                  {/*{consultationPathEn === "Confirmed"*/}
                  {/*  ? t("not_applicable")*/}
                  {/*  : formatTimestamp(gicDecisionDate) ?? <MissingData />}*/}
                  {formatTimestamp(gicDecisionDate) ?? <MissingData />}
                </td>
                <td data-label={t("gic_decision")}>
                  {gicDecision ?? <MissingData />}
                </td>
                <td data-label={t("listing_batch")}>
                  {listingBatch ?? <MissingData />}
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default SummaryOfProcessesTable;
