import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import i18n from "i18n";
import { getApiSearchServiceUrl } from "../../../../azure/environment";

const searchServiceUrl = getApiSearchServiceUrl();

export interface FetchBasicSearchAutocompleteFacetsParams {
  indexName: string;
  facetName: string;
  search: string;
  pageSize: number;
  pageNumber: number;
}

export interface BasicSearchFacet {
  value: string;
  count: number;
}

export async function fetchBasicSearchAutocompleteFacets(
  params: FetchBasicSearchAutocompleteFacetsParams
) {
  const token = await getUserAccessToken();

  if (!token) {
    throw new Error("Missing access token");
  }

  // NOTE: we can't use the standard URL.searchParams here, since it encodes the facet name slashes, causing the search to fail.
  const url = `${searchServiceUrl}index/${params.indexName}/facets/values?key=${
    params.facetName
  }&search=${
    params.search
  }&pageSize=${params.pageSize.toString()}&pageNumber=${params.pageNumber.toString()}&isCaseSensitive=false&searchType=contains&sortOrder=asc`;

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Accept-Language", i18n.language + "-CA");

  return await fetch(url.toString(), {
    headers,
  }).then((x) => x.json());
}
