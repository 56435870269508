// import Select from "@atlaskit/select";
import { OperatorSelectorProps, ValueSelector } from "react-querybuilder";

export const CustomOperatorSelector = (props: OperatorSelectorProps) => {
  if (props.level === 0) {
    return null;
  }

  return <ValueSelector {...props} />;
};
