const adminPopulationTranslation = {
  en: {
    population: "Population",
    list_of_population: "List of populations",
    create_a_population: "Create a Population",
    enter_population_name: "Enter a population name",
    edit_population: "Edit Population",
    reactivate_a_population: "Reactivate a Population",
    deactivate_a_population: "Deactivate a Population",
    archive_population_warning:
      "You will no longer be able to add this population to a Wildlife Species.",
    unarchive_population_warning:
      "You will be able to add this population to a Wildlife Species.",
    no_population_selected: "No population was selected",
    archive_a_population: "Archive a population",
    unarchive_a_population: "Unarchive a population",
  },
  fr: {
    population: "Population",
    list_of_population: "Liste des populations",
    create_a_population: "Créer une population",
    enter_population_name: "Entrez un nom de population",
    edit_population: "Modifier la population",
    reactivate_a_population: "Réactiver une population",
    deactivate_a_population: "Désactiver une population",
    archive_population_warning:
      "Vous ne pourrez plus ajouter cette population à une espèce sauvage.",
    unarchive_population_warning:
      "Vous pourrez ajouter cette population à une espèce sauvage.",
    no_population_selected: "Aucune population n'a été sélectionnée",
    archive_a_population: "Archiver une population",
    unarchive_a_population: "Désarchiver une population",
  },
};

export default adminPopulationTranslation;
