import * as React from "react";
import { useTranslation } from "react-i18next";
import { BptTemplatePhase, Maybe } from "generated/gql-types";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import ReorderPhasesBtn from "../PhasesReorder/ReorderPhasesBtn";
import { ApolloError } from "@apollo/client";

export interface PhaseSectionCardHeaderProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  phases?: Maybe<Maybe<BptTemplatePhase>[]>;
  disabled?: boolean;
  templateId: string;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export const PhaseSectionCardHeader = React.forwardRef(
  (props: PhaseSectionCardHeaderProps, ref) => {
    const { t } = useTranslation();
    const { templateId, phases, disabled, onExpandAll, onCollapseAll } = props;

    return (
      <div className="flex justify-between align-start">
        <h2>{t("phases")}</h2>

        <div className="flex gap-md">
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bpt.updateRoles}
          >
            <ReorderPhasesBtn
              templateId={templateId}
              phases={phases}
              onCompleted={function (data: any): void {
                throw new Error("Function not implemented.");
              }}
              onError={function (error: ApolloError | undefined): void {
                throw new Error("Function not implemented.");
              }}
            />
          </RenderWhenAuthorized>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-default card-header-button-link"
              onClick={onExpandAll}
              data-testid="button-expand-all"
              name="expandAll"
              disabled={disabled}
            >
              {t("expand_all")}
            </button>

            <button
              type="button"
              className="btn btn-default card-header-button-link"
              onClick={onCollapseAll}
              data-testid="button-collapse-all"
              name="collapseAll"
              disabled={disabled}
            >
              {t("collapse_all")}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default PhaseSectionCardHeader;
