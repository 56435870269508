import EditLink from "components/atoms/EditLink";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { Maybe, OverviewWsSpecies, RegistryPage } from "generated/gql-types";
import i18n from "i18n";
import { bilingualRichTextForLanguage } from "mappers";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import isNullOrEmpty from "util/isNullOrEmpty";

interface RegistryInfoCardProps {
  registryData?: Maybe<RegistryPage>;
}

export const RegistryInfoCard: React.FC<RegistryInfoCardProps> = (props) => {
  const { registryData } = props;
  const { t } = useTranslation();

  // For the bug #73764: Fixed the issue that both the document type and its associated species are duplicated
  const uniqueTypeDocumentRefs = registryData?.documentRefs?.filter(
    (value, index, self) =>
      index ===
      self.findIndex((x) => x?.documentType?.id === value?.documentType?.id)
  );

  const documentType =
    i18n.language === "fr"
      ? uniqueTypeDocumentRefs
          ?.map((x) => t(x?.documentType?.name?.french as string))
          .join(", ")
      : uniqueTypeDocumentRefs
          ?.map((x) => t(x?.documentType?.name?.english as string))
          .join(", ");

  const pageTitle =
    i18n.language === "fr"
      ? registryData?.title?.french?.text
      : registryData?.title?.english?.text;

  const description =
    i18n.language === "fr"
      ? registryData?.description?.french?.text
      : registryData?.description?.english?.text;

  const associatedSpecies: OverviewWsSpecies[] = [];
  registryData?.documentRefs?.map((docRef, index) =>
    docRef?.associatedSpecies?.map((x, idx) => associatedSpecies.push(x!))
  );

  // For the bug #73764: Fixed the issue that both the document type and its associated species are duplicated
  const uniqueSpeciesDocumentRefs = associatedSpecies?.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (x) =>
          x?.taxonomicGroup?.id === value?.taxonomicGroup?.id &&
          x?.listingRefId?.legacyId === value?.listingRefId?.legacyId &&
          x?.cosewicRefId?.legacyId === value?.cosewicRefId?.legacyId
      )
  );

  // Sorting for the bug# 64709: Classify species based on their taxonomic group in alphabetical order
  const sortedAssociatedSpecies = uniqueSpeciesDocumentRefs.sort((a, b) => {
    const aa =
      (i18n.language === "fr"
        ? a.taxonomicGroup?.nameFr
        : a.taxonomicGroup?.nameEn) ?? "";
    const bb =
      (i18n.language === "fr"
        ? b.taxonomicGroup?.nameFr
        : b.taxonomicGroup?.nameEn) ?? "";
    return aa.localeCompare(bb);
  });

  const groupedAssociatedSpecies: OverviewWsSpecies[] =
    sortedAssociatedSpecies.reduce(function (r, a) {
      if (i18n.language === "fr") {
        r[a.taxonomicGroup?.nameFr!] = r[a.taxonomicGroup?.nameFr!] || [];
        r[a.taxonomicGroup?.nameFr!].push(a);
      } else {
        r[a.taxonomicGroup?.nameEn!] = r[a.taxonomicGroup?.nameEn!] || [];
        r[a.taxonomicGroup?.nameEn!].push(a);
      }
      return r;
    }, Object.create(null));

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("registry_page_information")}</h2>
          <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.registry.update}>
            <EditLink
              linkTo={DocumentsPathHelpers.RegistryEdit(
                registryData?.id ?? "error"
              )}
            />
          </RenderWhenAuthorized>
        </div>
      }
    >
      <div className="species-field">
        <div className="row">
          <dl>
            {/* ------------ Document Type ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("document_types")}</dt>
              <dd>{documentType == null ? <MissingData /> : documentType}</dd>
            </div>
            {/* ------------ Publication Date ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("publication_date")}</dt>

              <dd>
                {registryData?.publishedDate == null ? (
                  <MissingData />
                ) : (
                  formatTimestamp(registryData?.publishedDate)
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Page Title ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {i18n.language === "fr"
                  ? t("page_title") + " (" + t("french") + ")"
                  : t("page_title") + " (" + t("english") + ")"}
              </dt>
              <dd>
                {pageTitle == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={htmlRemoveOuterPTag(pageTitle)} />
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Description ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">
                {i18n.language === "fr"
                  ? t("description") + " (" + t("french") + ")"
                  : t("description") + " (" + t("english") + ")"}
              </dt>
              <dd>
                {description == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={htmlRemoveOuterPTag(description)}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Documents ------------ */}
        <h3 className="font-size-18 mrgn-tp-sm">{t("documents")}</h3>
        <hr className="mrgn-0" />

        {registryData?.documentRefs == null ||
        registryData?.documentRefs.length === 0 ? (
          <MissingData />
        ) : (
          registryData?.documentRefs?.map((x, index) => (
            <div key={index}>
              {/* ------------ Document Name ------------ */}
              <div className="row">
                <dl>
                  <div className="species-data col-sm-12">
                    <dt className="text-muted">{t("document_name")}</dt>
                    <dd>
                      {x?.documentType == null ? (
                        <MissingData />
                      ) : (
                        <Link
                          to={DocumentsPathHelpers.DocumentsProfile(
                            x?.id ?? "error"
                          )}
                          data-testid={`document-id-${index}`}
                        >
                          <SafeRenderHtml
                            htmlString={
                              i18n.language === "fr"
                                ? x?.title?.french?.text
                                : x?.title?.english?.text
                            }
                          />
                        </Link>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="row">
                <dl>
                  {/* ------------ Public Consultation start date ------------ */}
                  <div className="species-data col-sm-6">
                    <dt className="text-muted">
                      {t("public_consultation_start_date")}
                    </dt>
                    <dd>
                      {x?.consultationDates?.fromDate == null ? (
                        <MissingData />
                      ) : (
                        formatTimestamp(x?.consultationDates?.fromDate)
                      )}
                    </dd>
                  </div>
                  {/* ------------ Public Consultation end date ------------ */}
                  <div className="species-data col-sm-6">
                    <dt className="text-muted">
                      {t("public_consultation_end_date")}
                    </dt>

                    <dd>
                      {x?.consultationDates?.toDate == null ? (
                        <MissingData />
                      ) : (
                        formatTimestamp(x?.consultationDates?.toDate)
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="row">
                <dl>
                  {/* ------------ Published date ------------ */}
                  <div className="species-data col-sm-6">
                    <dt className="text-muted">{t("publishing_date")}</dt>
                    <dd>
                      {x?.publishedDate == null ? (
                        <MissingData />
                      ) : (
                        formatTimestamp(x?.publishedDate)
                      )}
                    </dd>
                  </div>
                  {/* ------------ Document state ------------ */}
                  <div className="species-data col-sm-6">
                    <dt className="text-muted">{t("document_state")}</dt>

                    <dd>{x?.state == null ? <MissingData /> : t(x?.state)}</dd>
                  </div>
                </dl>
              </div>
            </div>
          ))
        )}

        <div className="row mrgn-tp-md">
          <dl>
            {/* ------------ Contact Person ------------ */}
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("contact_person")}</dt>
              <dd className="width-100">
                {registryData?.contactOrOrganizationFormatted == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={bilingualRichTextForLanguage(
                      i18n.language,
                      registryData?.contactOrOrganizationFormatted
                    )}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Associated Species ------------ */}
        <h3 className="font-size-18 mrgn-tp-sm">{t("associated_species")}</h3>
        <hr className="mrgn-0" />
        {registryData?.documentRefs == null ||
        registryData?.documentRefs.length === 0 ? (
          <MissingData />
        ) : (
          Object.entries(groupedAssociatedSpecies).map((data, indx) => (
            <div className="row" key={`taxonomic-group-${indx}`}>
              <dl>
                <div className="species-data col-sm-12">
                  <dt>{data[0]}</dt>

                  <dd>
                    {Object.entries(data[1]).map((element, index) => {
                      const x = element[1] as OverviewWsSpecies;
                      return x?.cosewicRefId == null &&
                        x?.listingRefId == null ? (
                        <MissingData />
                      ) : x?.listingRefId == null ? (
                        <div key={`associated-species-${index}`}>
                          <Link
                            to={CosewicPathHelpers.CosewicProfile(
                              x?.cosewicRefId?.id ?? "error"
                            )}
                            data-testid={`associated-species-${indx}-${index}`}
                          >
                            <SafeRenderHtml
                              htmlString={
                                i18n.language === "fr"
                                  ? htmlRemoveOuterPTag(
                                      x?.commonNameFrench?.name?.text
                                    ) +
                                    (x?.population?.nameFr
                                      ? ", " + x?.population?.nameFr!
                                      : "")
                                  : htmlRemoveOuterPTag(
                                      x?.commonNameEnglish?.name?.text
                                    ) +
                                    (x?.population?.nameEn
                                      ? ", " + x?.population?.nameEn!
                                      : "")
                              }
                            />
                          </Link>
                          {" ("}
                          <SafeRenderHtml
                            htmlString={htmlRemoveOuterPTag(
                              x?.scientificName?.text
                            )}
                          />
                          {") "}
                          <span className="text-muted">
                            {x?.cosewicRefId?.legacyId &&
                              t("cos_id") + ": " + x?.cosewicRefId?.legacyId}
                          </span>
                          <br />
                        </div>
                      ) : (
                        <div key={`associated-species-${index}`}>
                          <Link
                            to={ListingPathHelpers.ListingProfile(
                              x?.listingRefId.id ?? "error"
                            )}
                            data-testid={`associated-species-${indx}-${index}`}
                          >
                            <SafeRenderHtml
                              htmlString={
                                i18n.language === "fr"
                                  ? htmlRemoveOuterPTag(
                                      x?.commonNameFrench?.name?.text
                                    ) +
                                    (x?.population?.nameFr
                                      ? ", " + x?.population?.nameFr!
                                      : "")
                                  : htmlRemoveOuterPTag(
                                      x?.commonNameEnglish?.name?.text
                                    ) +
                                    (x?.population?.nameEn
                                      ? ", " + x?.population?.nameEn!
                                      : "")
                              }
                            />
                          </Link>
                          {" ("}
                          <SafeRenderHtml
                            htmlString={htmlRemoveOuterPTag(
                              x?.scientificName?.text
                            )}
                          />
                          {") "}
                          <span className="text-muted">
                            {x?.cosewicRefId?.legacyId &&
                              t("cos_id") + ": " + x?.cosewicRefId?.legacyId}
                            {x?.listingRefId?.legacyId &&
                              ", " +
                                t("list_id") +
                                ": " +
                                x?.listingRefId?.legacyId}
                          </span>
                          <br />
                        </div>
                      );
                    })}
                  </dd>
                </div>
              </dl>
            </div>
          ))
        )}

        {/* ------------ Related resources ------------ */}
        <div className="row mrgn-tp-md">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("related_resources")}</dt>

              <dd>
                {registryData?.relatedResources == null ||
                registryData?.relatedResources.length <= 0 ? (
                  <MissingData />
                ) : (
                  registryData?.relatedResources?.map((x, index) => (
                    <div key={index}>
                      {i18n.language === "en" && (
                        <>
                          <a
                            href={x?.enReference?.uri || ""}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-testid={`related-resources-${index}`}
                          >
                            <SafeRenderHtml
                              htmlString={htmlRemoveOuterPTag(
                                x?.enReference?.name?.text
                              )}
                            />
                          </a>
                          <br />
                        </>
                      )}
                      {i18n.language === "fr" && (
                        <>
                          <a
                            href={x?.frReference?.uri || ""}
                            data-testid={`related-resources-${index}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <SafeRenderHtml
                              htmlString={htmlRemoveOuterPTag(
                                x?.frReference?.name?.text
                              )}
                            />
                          </a>
                          <br />
                        </>
                      )}
                    </div>
                  ))
                )}
              </dd>
            </div>
          </dl>
        </div>
        {/* ------------ Created details ------------ */}
        <div className="row">
          <dl>
            {/* ------------ Created by ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("created_by")}</dt>
              <dd>
                {isNullOrEmpty(registryData?.createdBy) ? (
                  <MissingData />
                ) : (
                  registryData?.createdBy
                )}
              </dd>
            </div>

            {/* ------------ Created Date ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("creation_date")}</dt>

              <dd>
                {isNullOrEmpty(registryData?.createdAt) ? (
                  <MissingData />
                ) : (
                  formatTimestamp(registryData?.createdAt)
                )}
              </dd>
            </div>
          </dl>
        </div>
        {/* ------------ Updated details ------------ */}
        <div className="row">
          <dl>
            {/* ------------ Updated by ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("updated_by")}</dt>
              <dd>
                {isNullOrEmpty(registryData?.modifiedBy) ? (
                  <MissingData />
                ) : (
                  registryData?.modifiedBy
                )}
              </dd>
            </div>

            {/* ------------ Updated Date ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("updated_date")}</dt>

              <dd>
                {isNullOrEmpty(registryData?.modifiedAt) ? (
                  <MissingData />
                ) : (
                  formatTimestamp(registryData?.modifiedAt)
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default RegistryInfoCard;
