import { useCheckbox } from "@react-aria/checkbox";
import { useFocusRing } from "@react-aria/focus";
import {
  useTable,
  useTableCell,
  useTableColumnHeader,
  useTableHeaderRow,
  useTableRow,
  useTableRowGroup,
  useTableSelectAllCheckbox,
  useTableSelectionCheckbox,
} from "@react-aria/table";
import { mergeProps } from "@react-aria/utils";
import { TableStateProps, useTableState } from "@react-stately/table";
import { useToggleState } from "@react-stately/toggle";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export interface TableProps extends TableStateProps<object> {}

function Table(props: TableProps) {
  let state = useTableState({
    showSelectionCheckboxes: props.selectionMode === "multiple",
    ...props,
  });
  let ref = useRef(null);
  let { collection } = state;
  let { gridProps } = useTable(props as any, state, ref);

  return (
    <div className="scrollbar-x">
      <table
        {...gridProps}
        ref={ref}
        className="species-table provisional gc-table table table-striped table-hover"
        id="dataset_filter"
      >
        <TableRowGroup type="thead">
          {collection.headerRows.map((headerRow) => (
            <TableHeaderRow key={headerRow.key} item={headerRow} state={state}>
              {/*TODO:TYPES:any*/}
              {[...(headerRow.childNodes as any)].map((column) =>
                column.props.isSelectionCell ? (
                  <TableSelectAllCell
                    key={column.key}
                    column={column}
                    state={state}
                  />
                ) : (
                  <TableColumnHeader
                    key={column.key}
                    column={column}
                    state={state}
                  />
                )
              )}
            </TableHeaderRow>
          ))}
        </TableRowGroup>
        <TableRowGroup type="tbody">
          {/*TODO:TYPES:any*/}
          {[...(collection.body.childNodes as any)].map((row) => (
            <TableRow key={row.key} item={row} state={state}>
              {[...row.childNodes].map((cell) =>
                cell.props.isSelectionCell ? (
                  <TableCheckboxCell key={cell.key} cell={cell} state={state} />
                ) : (
                  <TableCell key={cell.key} cell={cell} state={state} />
                )
              )}
            </TableRow>
          ))}
        </TableRowGroup>
      </table>
    </div>
  );
}

export default Table;

// TODO:TYPES:any
function TableRowGroup({ type: Element, style, children }: any) {
  let { rowGroupProps } = useTableRowGroup();
  return (
    <Element {...rowGroupProps} style={style}>
      {children}
    </Element>
  );
}

// TODO:TYPES:any
function TableHeaderRow({ item, state, children }: any) {
  let ref = useRef(null);
  let { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <tr {...rowProps} ref={ref}>
      {children}
    </tr>
  );
}

// TODO:TYPES:any
function TableColumnHeader({ column, state }: any) {
  const { t } = useTranslation();
  let ref = useRef(null);
  let { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref
  );
  let { isFocusVisible, focusProps } = useFocusRing();
  // let arrowIcon =
  //   state.sortDescriptor?.column === column.key &&
  //   state.sortDescriptor?.direction === "ascending"
  //     ? "▲"
  //     : "▼";

  const isCurrentSortedColumn = state.sortDescriptor?.column === column.key;
  const tableHeaderTitle = column.props.allowsSorting
    ? state.sortDescriptor?.column === column.key &&
      state.sortDescriptor?.direction === "ascending"
      ? "table_header_desc_title"
      : "table_header_asc_title"
    : null;

  return (
    <th
      {...mergeProps(columnHeaderProps, focusProps)}
      colSpan={column.colspan}
      style={{
        textAlign: column.colspan > 1 ? "center" : "left",
        padding: "5px 10px",
        outline: isFocusVisible ? "2px solid orange" : "none",
        cursor: column.props.allowsSorting ? "pointer" : "default",
        verticalAlign: "middle",
        minWidth: column.props.minWidth ?? "none",
      }}
      title={
        tableHeaderTitle
          ? t(tableHeaderTitle, {
              column: column.value.name,
              interpolation: { escapeValue: false },
            })
          : ""
      }
      ref={ref}
    >
      <div className="flex justify-between align-center">
        {column.rendered}
        {column.props.allowsSorting && (
          <div className="small mrgn-lft-sm flex-col">
            <span
              style={{
                color:
                  state.sortDescriptor?.direction === "ascending" &&
                  isCurrentSortedColumn
                    ? "#333"
                    : "#ddd",
              }}
            >
              ▲
            </span>
            <span
              style={{
                color:
                  state.sortDescriptor?.direction === "descending" &&
                  isCurrentSortedColumn
                    ? "#333"
                    : "#ddd",
              }}
            >
              ▼
            </span>
          </div>
        )}
      </div>
    </th>
  );
}

// TODO:TYPES:any
function TableRow({ item, children, state }: any) {
  let ref = useRef(null);
  let isSelected = state.selectionManager.isSelected(item.key);
  let { rowProps } = useTableRow({ node: item }, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <tr
      style={{
        background: isSelected ? "#b0bed9" : undefined,
        color: isSelected ? "black" : undefined,
        outline: isFocusVisible ? "2px solid orange" : "none",
      }}
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
    >
      {children}
    </tr>
  );
}

// TODO:TYPES:any
function TableCell({ cell, state }: any) {
  let ref = useRef(null);
  let { gridCellProps } = useTableCell({ node: cell }, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <td
      data-label={cell.column.value.name}
      {...mergeProps(gridCellProps, focusProps)}
      style={{
        padding: "5px 10px",
        outline: "none",
        boxShadow: isFocusVisible ? "inset 0 0 0 2px orange" : "none",
        // outline: isFocusVisible ? "2px solid orange" : "none",
        // cursor: "default",
        maxWidth: cell.column.value.maxWidth,
        wordWrap: cell.column.value.maxWidth ? "break-word" : "normal",
      }}
      ref={ref}
    >
      {cell.rendered}
    </td>
  );
}

// TODO:TYPES:any
function TableCheckboxCell({ cell, state }: any) {
  let ref = useRef(null);
  let { gridCellProps } = useTableCell({ node: cell }, state, ref);
  let { checkboxProps } = useTableSelectionCheckbox(
    { key: cell.parentKey },
    state
  );

  let inputRef = useRef(null);
  let { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef
  );

  return (
    <td {...gridCellProps} ref={ref}>
      <label style={{ display: "block" }}>
        <input {...inputProps} ref={inputRef} />
      </label>
    </td>
  );
}

// TODO:TYPES:any
function TableSelectAllCell({ column, state }: any) {
  let ref = useRef(null);
  let isSingleSelectionMode = state.selectionManager.selectionMode === "single";
  let { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref
  );

  let { checkboxProps } = useTableSelectAllCheckbox(state);
  let inputRef = useRef(null);
  let { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef
  );

  return (
    <th {...columnHeaderProps} ref={ref}>
      <input
        {...inputProps}
        className="checkbox gc-chckbxrdio"
        ref={inputRef}
        style={{ visibility: isSingleSelectionMode ? "hidden" : "visible" }}
      />
    </th>
  );
}
