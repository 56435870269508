import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME } from "config/constants";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { htmlToPlainText } from "util/richtext";
import SectionCard from "../../../atoms/SectionCard";
import { SpeciesProfileFormFields } from "./PublicProfileForm";

export interface BiologyCardProps {
  initialValues: Partial<SpeciesProfileFormFields>;
}

export const BiologyCard: React.FC<BiologyCardProps> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const editLinkClicked = location.state;

  const form = useFormContext<SpeciesProfileFormFields>();
  const { control, formState, watch } = form;
  const { errors } = formState;

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME;

  return (
    <SectionCard
      collapsible
      open={editLinkClicked === t("biology") || editLinkClicked == null}
      header={<h2>{t("biology")}</h2>}
    >
      <div className="form-group">
        <label htmlFor="biologyEnglish">{t("english")}</label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            required:
              watch("biology.english.text") === "" &&
              watch("biology.french.text") !== "",
            validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
          }}
          defaultValue={initialValues?.biology?.english?.text ?? ""}
          id="biologyEnglish"
          name="biology.english.text"
        />

        {errors.biology?.english &&
          (errors.biology.english as any)?.text?.type === "required" && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

        {errors.biology?.english &&
          (errors.biology.english as any)?.text?.type === "maxLengthLimit" && (
            <FieldValidationError>
              {t("reach_the_max_length", {
                count: MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME,
              })}
            </FieldValidationError>
          )}
      </div>

      <div className="form-group">
        <label htmlFor="biologyFrench">{t("french")}</label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            required:
              watch("biology.french.text") === "" &&
              watch("biology.english.text") !== "",
            validate: { maxLengthLimit: validateHtmlTextMaxLengthLimit },
          }}
          defaultValue={initialValues?.biology?.french?.text ?? ""}
          id="biologyFrench"
          name="biology.french.text"
        />

        {errors.biology?.french &&
          (errors.biology.french as any)?.text?.type === "required" && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}

        {errors.biology?.french &&
          (errors.biology.french as any)?.text?.type === "maxLengthLimit" && (
            <FieldValidationError>
              {t("reach_the_max_length", {
                count: MAX_LENGTH_OF_SPECIES_PROFILE_FIELD_NAME,
              })}
            </FieldValidationError>
          )}
      </div>
    </SectionCard>
  );
};
