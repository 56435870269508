import {
  Maybe,
  SpeciesProfileDetails,
  SpeciesProfileInput,
} from "generated/gql-types";
import { makeBilingualRichText } from "mappers";
import { SpeciesProfileFormFields } from "./PublicProfileForm";

export const mapDomainModelToForm = (
  model?: Partial<Maybe<SpeciesProfileDetails>>
) => {
  const out: Partial<SpeciesProfileFormFields> = {
    description: makeBilingualRichText(model?.description),
    distributionAndPopulation: makeBilingualRichText(
      model?.distributionAndPopulation
    ),
    habitat: makeBilingualRichText(model?.habitat),
    criticalHabitat: makeBilingualRichText(model?.criticalHabitat),
    biology: makeBilingualRichText(model?.biology),
    threats: makeBilingualRichText(model?.threats),
    protection: makeBilingualRichText(model?.protection),
  };

  return out;
};

export function mapFormToDomainModel(form: Partial<SpeciesProfileFormFields>) {
  const out: Partial<SpeciesProfileInput> = { speciesDetails: {} };

  if (form.description !== undefined)
    out.speciesDetails!.description = form.description;
  if (form.distributionAndPopulation !== undefined)
    out.speciesDetails!.distributionAndPopulation =
      form.distributionAndPopulation;
  if (form.habitat !== undefined) out.speciesDetails!.habitat = form.habitat;
  if (form.criticalHabitat !== undefined)
    out.speciesDetails!.criticalHabitat = form.criticalHabitat;
  if (form.biology !== undefined) out.speciesDetails!.biology = form.biology;
  if (form.threats !== undefined) out.speciesDetails!.threats = form.threats;
  if (form.protection !== undefined)
    out.speciesDetails!.protection = form.protection;

  return out;
}
