import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import { useGlobalAlertContext } from "features/globalAlert";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import RegistryPageForm, {
  RegistryPageFormFields,
} from "../../../../components/organisms/documents/Registry/RegistryPageForm/RegistryPageForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "../../../../components/organisms/documents/Registry/RegistryPageForm/mappers";
import {
  RegistryPageDocument,
  RegistryPageState,
  UpdateRegistryPageDocument,
} from "../../../../generated/gql-types";
import { DocumentsPathHelpers } from "../../DocumentsRouter";
import timeoutPromise from "util/timeoutPromise";

export interface RegistryEditPageProps {}

export const RegistryEditPage: React.FC<RegistryEditPageProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { registryPageId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const { loading, error, data } = useQuery(RegistryPageDocument, {
    errorPolicy: "all",
    variables: {
      registryPageId: registryPageId,
    },
  });

  const [runSubmit, { error: savingError }] = useMutation(
    UpdateRegistryPageDocument
  );

  const onSubmit: SubmitHandler<Partial<RegistryPageFormFields>> = async (
    filteredFormData
  ) => {
    const mappedDomainModel = mapFormToDomainModel(filteredFormData);

    try {
      const res = await runSubmit({
        variables: {
          id: registryPageId,
          input: mappedDomainModel,
        },
      });
      if (res.errors) throw res.errors;

      // Added for the bug #78450: No warning message appears when updating
      // the contact person for a published document
      if (data?.registryPage?.state === RegistryPageState.Published) {
        await timeoutPromise(3000); // Wait for 3 seconds
      }

      history.push({
        pathname: DocumentsPathHelpers.RegistryProfile(registryPageId),
      });

      alertContext.showSuccess({
        message: t("successfully_updated_registry_page"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: DocumentsPathHelpers.RegistryProfile(registryPageId),
    });
  };

  const initialFormFields = mapDomainModelToForm(data?.registryPage ?? null);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("edit_registry_page")}</h1>
          <GraphqlError title="Error loading registry" errors={error} />
          <GraphqlError title="Error updating registry" errors={savingError} />

          {loading ? (
            <LoadingIndicator />
          ) : (
            <RegistryPageForm
              initialValues={initialFormFields}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default RegistryEditPage;
