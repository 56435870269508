import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { FeatureFlagKeys } from "../../features/featureFlags/config";
import RenderWhenFeatureIsEnabled from "../../features/featureFlags/RenderWhenFeatureIsEnabled";
import { makeRelativeNestedRoute } from "../../util/router";
import ListingCreate from "./create";
import ListingHome from "./index";
import ListingChangeLogDetails from "./[id]/changeLog/details";
import ListingChangeLog from "./[id]/changeLog/index";
import ListingEdit from "./[id]/edit";
import ListingProfile from "./[id]/index";
import ListingLandsPage from "./[id]/lands";
import CreateListingLandsPage from "./[id]/lands/create";
import ListingLandsEditPage from "./[id]/lands/[id]/edit";
import ListingProcesses from "./[id]/processes";
import ListingProcessesCreate from "./[id]/processes/create";
import ListingProcessesEditPage from "./[id]/processes/[id]/edit";
import ListingRelatedSpecies from "./[id]/relatedSpecies";
import AddParents from "./[id]/relatedSpecies/addParents";
import ViewChildren from "./[id]/relatedSpecies/viewChildren";
import ViewParents from "./[id]/relatedSpecies/viewParents";
import ListingSectorsPage from "./[id]/sectors";
import ListingSectorsCreatePage from "./[id]/sectors/create";
import ListingSectorsEditPage from "./[id]/sectors/[id]/edit";

export interface ListingRouterProps {}

export const ListingPathHelpers = {
  Home: "/listing",
  ListingCreate: "/listing/create",
  ListingProfile: (listingWsId: string) => `/listing/${listingWsId}`,
  ListingEdit: (listingWsId: string) => `/listing/${listingWsId}/edit`,
  ChangeLog: (listingWsId: string) => `/listing/${listingWsId}/changeLog`,
  ChangeLogDetails: (listingWsId: string, changeLogId: string) =>
    `/listing/${listingWsId}/changeLog/details?changeLogId=${changeLogId}`,
  ListingRelatedSpecies: (listingWsId: string) =>
    `/listing/${listingWsId}/relatedSpecies`,
  AddParents: (listingWsId: string) =>
    `/listing/${listingWsId}/relatedSpecies/addParents`,
  ViewParents: (listingWsId: string) =>
    `/listing/${listingWsId}/relatedSpecies/viewParents`,
  ViewChildren: (listingWsId: string) =>
    `/listing/${listingWsId}/relatedSpecies/viewChildren`,
  ListingProcesses: (listingWsId: string) =>
    `/listing/${listingWsId}/processes`,
  ListingProcessCreate: (listingWsId: string) =>
    `/listing/${listingWsId}/processes/create`,
  ListingProcessEdit: (listingWsId: string, listingProcessId: string) =>
    `/listing/${listingWsId}/processes/${listingProcessId}/edit`,
  ListingSectors: (listingWsId: string) => `/listing/${listingWsId}/sectors`,
  ListingSectorsCreate: (listingWsId: string) =>
    `/listing/${listingWsId}/sectors/create`,
  ListingSectorsEdit: (listingWsId: string, sectorId: string) =>
    `/listing/${listingWsId}/sectors/${sectorId}/edit`,
  ListingLands: (listingWsId: string) => `/listing/${listingWsId}/lands`,
  ListingLandsCreate: (listingWsId: string) =>
    `/listing/${listingWsId}/lands/create`,
  ListingLandsEdit: (listingWsId: string, landId: string) =>
    `/listing/${listingWsId}/lands/${landId}/edit`,
};

export const ListingRouter: React.FC<ListingRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <ListingHome />
      </Route>

      <Route path={relative("/create")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <ListingCreate />
        </RenderWhenAuthorized>
      </Route>

      <Route exact path={relative("/:listingWsId/changeLog")}>
        <ListingChangeLog />
      </Route>

      <Route path={relative("/:listingWsId/changeLog/details")}>
        <ListingChangeLogDetails />
      </Route>

      <Route path={relative("/:listingWsId/relatedSpecies/addParents")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <AddParents />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:listingWsId/relatedSpecies/viewParents")}>
        <ViewParents />
      </Route>

      <Route path={relative("/:listingWsId/relatedSpecies/viewChildren")}>
        <ViewChildren />
      </Route>

      <Route path={relative("/:listingWsId/relatedSpecies")}>
        <ListingRelatedSpecies />
      </Route>

      <Route path={relative("/:listingWsId/edit")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <ListingEdit />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:listingWsId/processes/create")}>
        <ListingProcessesCreate />
      </Route>

      <Route path={relative("/:listingWsId/processes/:listingProcessId/edit")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <ListingProcessesEditPage />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:listingWsId/processes")}>
        <ListingProcesses />
      </Route>

      {/*Sectors*/}
      <Route path={relative("/:listingWsId/sectors/:sectorId/edit")}>
        <ListingSectorsEditPage />
      </Route>

      <Route path={relative("/:listingWsId/sectors/create")}>
        <ListingSectorsCreatePage />
      </Route>

      <Route path={relative("/:listingWsId/sectors")}>
        <ListingSectorsPage />
      </Route>

      {/*Lands*/}
      <Route path={relative("/:listingWsId/lands/create")}>
        <CreateListingLandsPage />
      </Route>

      <Route path={relative("/:listingWsId/lands/:landId/edit")}>
        <ListingLandsEditPage />
      </Route>

      <Route path={relative("/:listingWsId/lands")}>
        <ListingLandsPage />
      </Route>

      <Route path={relative("/:listingWsId")}>
        <ListingProfile />
      </Route>
    </Switch>
  );
};

export default ListingRouter;
