import type { FieldNamesMarkedBoolean } from "react-hook-form";
import { isArray, isObject } from "lodash";

/**
 * This function is to satisfy a requirement set by our back-end:
 * The SAR-DMTS services expect to receive a PATCH (or graphql mutation) payload containing ONLY the fields that have been changed.
 *
 * It accepts `allValues` (state for the entire form) and react-hook-form's `dirtyFields` property.
 * It returns all the items in `allValues` that contain changed children or that have been changed themselves (if the item is a top-level primitive type).
 *
 * This is one of the most arcane and sketchy parts of the whole frontend project, and it's a critical part of all our forms.
 * If you're changing this function: be very careful, run the tests often, and write as many new tests as you can!
 *
 * Originally adapted from snippet https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-31308 - credit to github/@dandv
 */
export function filterForDirtyFields<T extends object>(
  allValues: T,
  dirtyFields: FieldNamesMarkedBoolean<T>
): Partial<T> {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if ((dirtyFields as any) === true) {
    return allValues;
  }

  if (allValues === null) {
    return allValues;
  }

  let entries: any[] = [];
  for (let key of Object.keys(dirtyFields) as any) {
    const dirtyFieldAtKey = (dirtyFields as any)[key];
    if (dirtyFieldAtKey === false) {
      continue;
    }

    if (isArray(dirtyFieldAtKey)) {
      const foundIndex = dirtyFieldAtKey.findIndex((x) => {
        if (x === false) return false;
        if (x === null) return false;
        if (x === undefined) return false;
        if (Object.values(x) && Object.values(x).includes(false)) return false;
        return true;
      });

      if (foundIndex > -1) {
        entries.push([key, (allValues as any)[key]]);
      }
      continue;
    }

    if (isObject(dirtyFieldAtKey)) {
      const keysInNestedDirtyField = Object.keys(dirtyFieldAtKey);

      if (keysInNestedDirtyField.length === 0) {
        continue;
      }

      const valuesInNestedDirtyField = Object.values(dirtyFieldAtKey);
      const valuesWithoutFalse = valuesInNestedDirtyField.filter(
        (x) => x !== false
      );

      if (valuesWithoutFalse.length === 0) {
        continue;
      }

      // recursion here
      const filteredNestedValues = filterForDirtyFields((allValues as any)[key], dirtyFieldAtKey);

      // this case handles an entire field object being set null
      if (filteredNestedValues == null) {
        entries.push([ key, filteredNestedValues ]);
        continue;
      }

      // this case is for when a field object (eg commonNameEnglish) is in allValues, but none of its children have been touched (eg .name.text hasn't been dirtied)
      if (Object.keys(filteredNestedValues).length > 0) {
        entries.push([ key, filteredNestedValues ]);
        continue;
      }

      continue;
    }

    if (dirtyFieldAtKey === true) {
      entries.push([key, (allValues as any)[key]]);
      continue;
    }
  }

  return Object.fromEntries(entries) as any;
}
