import {
  BptTemplate,
  BptTemplateInput,
  BptTemplatePhase,
  BptTemplatePhaseInput,
  BptTemplateStep,
  BptTemplateStepCreateInput,
  BptTemplateStepUpdateInput,
  BptTemplateVisibility,
} from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import * as FormMappers from "util/formMappers";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { filterForDirtyFields } from "../../../../util/forms";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import { BptTemplateFormFields, Visibility } from "./Template/BptTemplateForm";
import { PhaseFormFields } from "./Phase/PhaseForm";
import { StepFormFields } from "./Step/StepForm";

export const processPhaseFormValues = (
  formData: UnpackNestedValue<Partial<PhaseFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<PhaseFormFields>
): Partial<PhaseFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<PhaseFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<PhaseFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // PhaseFormFields

  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }

  if (
    dirtyFields?.description &&
    htmlIsNullOrEmpty(formData?.description?.text) &&
    !isNullOrEmpty(initialValues?.description?.text)
  ) {
    set(formData, "description.text", null);
  }

  // if (
  //   dirtyFields?.estimatedDuration != null &&
  //   isNullOrEmpty(formData?.estimatedDuration) &&
  //   !isNullOrEmpty(initialValues?.estimatedDuration)
  // ) {
  //   set(formData, "estimatedDuration", null);
  // }

  // if (
  //   dirtyFields?.includeCommonHoliday &&
  //   formData?.includeCommonHoliday == initialValues?.includeCommonHoliday
  // ) {
  //   set(formData, "includeCommonHoliday", null);
  // }
};

export const domainModelIntoPhaseForm = (
  phase?: BptTemplatePhase | null,
  bptTemplateId?: string
): Partial<PhaseFormFields> => {
  const out: Partial<PhaseFormFields> = {};

  // name
  FormMappers.mapRichText(out, "name", phase?.name);

  // Phase description
  FormMappers.mapRichText(out, "description", phase?.description);

  // // Estimate duration
  // FormMappers.mapPrimitiveType(
  //   out,
  //   "estimatedDuration",
  //   phase?.estimatedDuration
  // );

  // // Phase includeCommonHoliday
  // FormMappers.mapPrimitiveType(
  //   out,
  //   "includeCommonHoliday",
  //   phase?.includeHouseOfCommons,
  //   false
  // );

  // // id
  // FormMappers.mapPrimitiveType(out, "id", phase?.id);

  return out;
};

export const phaseFormIntoCreateInput = (
  formData: Partial<PhaseFormFields>,
  isCreatPhase?: boolean
): BptTemplatePhaseInput => {
  const out: BptTemplatePhaseInput = {};

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);

  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);

  // // estimatedDuration
  // FormMappers.mapPrimitiveTypeInput(
  //   out,
  //   "estimatedDuration",
  //   formData?.estimatedDuration != null
  //     ? parseInt(String(formData?.estimatedDuration))
  //     : 0
  // );

  // // includeHouseOfCommons
  // FormMappers.mapPrimitiveTypeInput(
  //   out,
  //   "includeHouseOfCommons",
  //   formData?.includeCommonHoliday
  // );

  return out;
};

export const BptTemplateDomainModelIntoForm = (
  bptTemplate?: BptTemplate | null
): Partial<BptTemplateFormFields> => {
  const out: Partial<BptTemplateFormFields> = {};

  // name
  FormMappers.mapRichText(out, "name", bptTemplate?.name);
  // description
  FormMappers.mapRichText(out, "description", bptTemplate?.description);
  // program
  FormMappers.mapBilingualAbbreviationAcronymTagsLookupText(
    out,
    "program",
    bptTemplate?.program
  );
  // object type
  FormMappers.mapBilingualAbbreviationAcronymTagsLookupText(
    out,
    "objectType",
    bptTemplate?.objectType
  );
  // Visibility
  out.visibility =
    bptTemplate?.visibility === BptTemplateVisibility.AllPrograms
      ? Visibility.ALL_PROGRAMS
      : Visibility.PRIVATE;

  return out;
};

export const formIntoCreateInputBptTemplate = (
  formData: Partial<BptTemplateFormFields>
): BptTemplatePhaseInput => {
  const out: BptTemplateInput = {
    name: undefined,
    description: undefined,
    program: formData?.program,
    objectType: formData?.objectType,
    visibility:
      formData?.visibility === Visibility.ALL_PROGRAMS
        ? BptTemplateVisibility.AllPrograms
        : BptTemplateVisibility.Private,
  };

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);
  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);
  return out;
};

export const processStepFormValues = (
  formData: UnpackNestedValue<Partial<StepFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<StepFormFields>
): Partial<StepFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForStepEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForStepEmptyFields = (
  formData: UnpackNestedValue<Partial<StepFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<StepFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // StepFormFields

  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }

  if (
    dirtyFields?.owner != null &&
    isNullOrEmpty(formData?.owner?.text) &&
    !isNullOrEmpty(initialValues?.owner?.text)
  ) {
    set(formData, "owner.text", null);
  }

  if (
    dirtyFields?.estimatedDuration != null &&
    isNullOrEmpty(formData?.estimatedDuration) &&
    !isNullOrEmpty(initialValues?.estimatedDuration)
  ) {
    set(formData, "estimatedDuration", null);
  }

  if (
    dirtyFields?.description &&
    htmlIsNullOrEmpty(formData?.description?.text) &&
    !isNullOrEmpty(initialValues?.description?.text)
  ) {
    set(formData, "description.text", null);
  }

  if (
    dirtyFields?.includeCommonHoliday &&
    formData?.includeCommonHoliday === initialValues?.includeCommonHoliday
  ) {
    set(formData, "includeCommonHoliday", null);
  }
};

export const domainModelIntoStepForm = (
  step?: BptTemplateStep | null
): Partial<StepFormFields> => {
  const out: Partial<StepFormFields> = {};

  // name
  FormMappers.mapRichText(out, "name", step?.name);

  // Owner
  FormMappers.mapRichText(out, "owner", step?.owner);

  // Estimate duration
  FormMappers.mapPrimitiveType(
    out,
    "estimatedDuration",
    step?.estimatedDuration
  );

  // Step description
  FormMappers.mapRichText(out, "description", step?.description);

  // Step includeCommonHoliday
  FormMappers.mapPrimitiveType(
    out,
    "includeCommonHoliday",
    step?.includeHouseOfCommons,
    false
  );

  // id
  FormMappers.mapPrimitiveType(out, "id", step?.id);

  return out;
};

export const addStepFormIntoCreateInput = (
  formData: Partial<StepFormFields>
): BptTemplateStepCreateInput => {
  const out: BptTemplateStepCreateInput = {};

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);

  // Owner
  FormMappers.mapRichTextInput(out, "owner", formData?.owner);

  // estimatedDuration
  FormMappers.mapPrimitiveTypeInput(
    out,
    "estimatedDuration",
    formData?.estimatedDuration != null
      ? parseInt(String(formData?.estimatedDuration))
      : 0
  );

  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);

  // includeHouseOfCommons
  FormMappers.mapPrimitiveTypeInput(
    out,
    "includeHouseOfCommons",
    formData?.includeCommonHoliday
  );

  return out;
};

export const editStepFormIntoCreateInput = (
  formData: Partial<StepFormFields>
): BptTemplateStepUpdateInput => {
  const out: BptTemplateStepUpdateInput = {};

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);

  // Owner
  FormMappers.mapRichTextInput(out, "owner", formData?.owner);

  // estimatedDuration
  FormMappers.mapPrimitiveTypeInput(
    out,
    "estimatedDuration",
    formData?.estimatedDuration != null
      ? parseInt(String(formData?.estimatedDuration))
      : formData?.estimatedDuration === null
      ? 0
      : formData?.estimatedDuration
  );

  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);

  // includeHouseOfCommons
  FormMappers.mapPrimitiveTypeInput(
    out,
    "includeHouseOfCommons",
    formData?.includeCommonHoliday
  );

  return out;
};
