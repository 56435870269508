import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

export interface ReturnToButtonProps {
  returnTarget: string;
  onClick: () => void;
}

export const ReturnToButton: React.FC<ReturnToButtonProps> = (props) => {
  const { t } = useTranslation();
  const { returnTarget, onClick } = props;

  return (
    <button
      type="button"
      className="btn btn-default"
      onClick={onClick}
      data-testid="button-return-to"
    >
      {"« "}
      {t("return_to")}{" "}
      {<SafeRenderHtml htmlString={htmlRemoveOuterPTag(returnTarget)} />}
    </button>
  );
};

export default ReturnToButton;
