import { OverlayContainer } from "@react-aria/overlays";
import { OverlayTriggerState } from "@react-stately/overlays";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BilingualTextInput } from "../../../../../generated/gql-types";
import isNullOrEmpty from "../../../../../util/isNullOrEmpty";
import FieldValidationError from "../../../../atoms/forms/FieldValidationError";
import ModalDialog from "../../../../molecules/Modal";
import { TextInput } from "components/atoms/forms/TextInput";

export interface CreateLandManagerModalProps {
  modalState: OverlayTriggerState;
  onSubmit: (data: BilingualTextInput) => void;
}

interface LandManagerFormFields {
  english?: string;
  french?: string;
}

export const CreateLandManagerModal: React.FC<CreateLandManagerModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const { modalState } = props;

  const form = useForm<LandManagerFormFields>();

  const { register, handleSubmit, formState, watch } = form;
  const { isDirty, errors } = formState;

  const onSubmit: SubmitHandler<LandManagerFormFields> = async (formData) => {
    props.onSubmit(formData);
  };

  const englishIsEmpty = isNullOrEmpty(watch("english"));
  const frenchIsEmpty = isNullOrEmpty(watch("french"));

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("create_a_land_manager")}
        isOpen
        onClose={modalState.close}
        isDismissable
      >
        <form
          onSubmit={(e) => {
            // this component is a nested form; prevent default and stop propagation so we don't also submit the parent form.
            e.preventDefault();
            e.stopPropagation();
            return handleSubmit(onSubmit)(e);
          }}
        >
          <TextInput
            type="text"
            label={t("name_english")}
            id="landManagerEnglishInput"
            inputClassName="full-width"
            placeholder={t("name_english")}
            required={frenchIsEmpty}
            {...register("english", {
              required: {
                value: frenchIsEmpty,
                message: t("field_is_required"),
              },
            })}
            errors={errors}
          />

          <TextInput
            type="text"
            label={t("name_french")}
            id="landManagerNameFrenchInput"
            inputClassName="full-width"
            placeholder={t("name_french")}
            required={englishIsEmpty}
            {...register("french", {
              required: {
                value: englishIsEmpty,
                message: t("field_is_required"),
              },
            })}
            errors={errors}
          />

          <button
            type="submit"
            disabled={!isDirty}
            className="btn btn-primary"
            data-testid="modal-button-save"
          >
            {t("save")}
          </button>
        </form>
      </ModalDialog>
    </OverlayContainer>
  );
};
