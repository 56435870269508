import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import AddLink from "components/atoms/AddLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/TwoColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import PermitIdSearchForm, {
  QueryFields,
  defaultQueryFields,
} from "components/molecules/permit/PermitIdSearchForm";
import PermitSideNav from "components/molecules/permit/PermitSideNav";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert } from "features/globalAlert";
import { PermitDescriptionListDocument } from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import i18n from "i18n";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PermitPathHelpers } from "./PermitRouter";

const PermitBrowsePage: React.FC = () => {
  const { t } = useTranslation();
  const pagination = usePagination();

  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);

  const { data, loading, error } = useQuery(PermitDescriptionListDocument, {
    errorPolicy: "all",
    variables: {
      params: {
        permitId: filters.search.length > 0 ? filters.search : undefined,
        listingWsRefId: undefined,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        sortBy: "createdAt:desc",
      },
    },
    fetchPolicy: "network-only",
  });

  function onFilterSubmit(arr: QueryFields) {
    flushSync(() => {
      setFilters(arr);
      pagination.goToPage(1);
    });
  }

  const permitDescriptions = data?.permitDescriptionList?.permitDescription;

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("permit_descriptions")}</h1>
          <GraphqlError
            title="Error loading Permit descriptions"
            errors={error}
          />
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("list_of_permit_descriptions")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.permit.update}
                >
                  <AddLink
                    text={t("add")}
                    linkTo={PermitPathHelpers.PermitCreate}
                    data-testid="button-add"
                  ></AddLink>
                </RenderWhenAuthorized>
              </div>
            }
          >
            <div className="flex justify-between align-center flex-wrap mrgn-bttm-md">
              <PermitIdSearchForm onSubmit={onFilterSubmit} />

              <div className="flex-col align-end justify-between flex-auto">
                <PageSizeSelect
                  pageSize={pagination.pageSize}
                  onChangePageSize={pagination.setPageSize}
                />
                <div className="font-size-16">
                  {pagination.makeShowingString(
                    data?.permitDescriptionList?.pagination?.totalCount
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <LoadingIndicator centered />
            ) : (
              <>
                {!permitDescriptions || permitDescriptions.length === 0 ? (
                  <NoResults centered />
                ) : null}

                {permitDescriptions?.map((x, index) => {
                  const title =
                    i18n.language === "fr"
                      ? x?.title?.french?.text
                      : x?.title?.english?.text;

                  return (
                    <div key={index} className="list-item separator-line pb-3">
                      <Link
                        to={PermitPathHelpers.PermitProfile(x?.id ?? "error")}
                        className="hover-underline"
                      >
                        <div className="h3 font-size-18 mrgn-0">
                          <SafeRenderHtml htmlString={title} />
                        </div>
                      </Link>
                    </div>
                  );
                })}
                <Pagination
                  {...pagination.paginationComponentProps}
                  totalPages={
                    data?.permitDescriptionList?.pagination?.totalPages ?? 0
                  }
                />
              </>
            )}
          </SectionCard>
        </Layout.Content>
        <Layout.Sidebar>
          <PermitSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PermitBrowsePage;
