import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import MissingData from "components/atoms/MissingData";
import Layout from "components/layouts/TwoColumn";
import NoteCard from "components/molecules/NoteCard/NoteCard";
import SideNav from "components/molecules/SideNav";
import BilingualHtmlCard from "components/molecules/publicProfile/BilingualHtmlCard";
import MakePublishedButtonAndModal from "components/organisms/MakePublishedButtonAndModal/MakePublishedButtonAndModal";
import UnPublishButtonAndModal from "components/organisms/UnPublishButtonAndModal/UnPublishButtonAndModal";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  OverviewWsSpeciesProfileDocument,
  SpeciesDetailsState,
  UpdateSpeciesProfileDocument,
  UpdateSpeciesProfileStateDocument,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { PublicProfilePathHelpers } from "../PublicProfileRouter";

const PublicProfilePage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { speciesProfileId } = params as any;
  const overviewContext = useOverviewContext();

  const [updateSpeciesProfile, updateSpeciesProfileMutationStatus] =
    useMutation(UpdateSpeciesProfileDocument, {
      refetchQueries: ["OverviewWsSpeciesProfile"],
    });

  const [updateSpeciesProfileState, updateSpeciesProfileStateMutationStatus] =
    useMutation(UpdateSpeciesProfileStateDocument, {
      refetchQueries: ["OverviewWsSpeciesProfile"],
    });

  const { loading, error, data } = useQuery(OverviewWsSpeciesProfileDocument, {
    variables: { overviewWsSpeciesProfileId: speciesProfileId },
  });

  const overviewProgram = "cosewicws";
  const overviewId = data?.overviewWsSpeciesProfile?.references?.find(
    (x) => x?.name?.toLowerCase() === "cosewicws"
  )?.referenceId;

  // Update Ribbon and SideNav.
  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId!);
  }, [overviewProgram, overviewId]);

  const onUpdateNote = async (newNoteText: string) => {
    try {
      const note = htmlIsNullOrEmpty(newNoteText)
        ? null
        : { text: { text: newNoteText } };

      const res = await updateSpeciesProfile({
        variables: {
          updateSpeciesProfileId: speciesProfileId,
          input: { speciesDetails: { note: note } },
        },
      });

      if (res.errors) throw res.errors;
    } catch (e) {}

    console.log("Saved Note for Species Profile:", speciesProfileId);
  };

  const speciesDetails = data?.overviewWsSpeciesProfile?.speciesDetails;

  const canPublish = React.useMemo(() => {
    if (loading) return false;

    if (speciesDetails?.state !== SpeciesDetailsState.Published) {
      return true;
    }
    return false;
  }, [loading, speciesDetails?.state]);

  const isPublishEnabled = React.useMemo(() => {
    if (loading) return false;

    if (
      (speciesDetails?.description?.english?.text === undefined &&
        speciesDetails?.description?.english?.text !== undefined) ||
      (speciesDetails?.description?.english?.text !== undefined &&
        speciesDetails?.description?.english?.text === undefined)
    ) {
      return false;
    }
    if (
      (speciesDetails?.biology?.english?.text === undefined &&
        speciesDetails?.biology?.english?.text !== undefined) ||
      (speciesDetails?.biology?.english?.text !== undefined &&
        speciesDetails?.biology?.english?.text === undefined)
    ) {
      return false;
    }
    if (
      (speciesDetails?.criticalHabitat?.english?.text === undefined &&
        speciesDetails?.criticalHabitat?.english?.text !== undefined) ||
      (speciesDetails?.criticalHabitat?.english?.text !== undefined &&
        speciesDetails?.criticalHabitat?.english?.text === undefined)
    ) {
      return false;
    }
    if (
      (speciesDetails?.distributionAndPopulation?.english?.text === undefined &&
        speciesDetails?.distributionAndPopulation?.english?.text !==
          undefined) ||
      (speciesDetails?.distributionAndPopulation?.english?.text !== undefined &&
        speciesDetails?.distributionAndPopulation?.english?.text === undefined)
    ) {
      return false;
    }
    if (
      (speciesDetails?.habitat?.english?.text === undefined &&
        speciesDetails?.habitat?.english?.text !== undefined) ||
      (speciesDetails?.habitat?.english?.text !== undefined &&
        speciesDetails?.habitat?.english?.text === undefined)
    ) {
      return false;
    }
    if (
      (speciesDetails?.protection?.english?.text === undefined &&
        speciesDetails?.protection?.english?.text !== undefined) ||
      (speciesDetails?.protection?.english?.text !== undefined &&
        speciesDetails?.protection?.english?.text === undefined)
    ) {
      return false;
    }
    if (
      (speciesDetails?.threats?.english?.text === undefined &&
        speciesDetails?.threats?.english?.text !== undefined) ||
      (speciesDetails?.threats?.english?.text !== undefined &&
        speciesDetails?.threats?.english?.text === undefined)
    ) {
      return false;
    }

    if (speciesDetails?.state !== SpeciesDetailsState.Published) {
      return true;
    }
    return false;
  }, [
    loading,
    speciesDetails?.state,
    speciesDetails?.biology,
    speciesDetails?.criticalHabitat,
    speciesDetails?.description,
    speciesDetails?.distributionAndPopulation,
    speciesDetails?.habitat,
    speciesDetails?.protection,
    speciesDetails?.threats,
  ]);

  const onPublish = async () => {
    try {
      const res = await updateSpeciesProfileState({
        variables: {
          updateSpeciesProfileStateId: speciesProfileId,
          input: { state: SpeciesDetailsState.Published },
        },
      });
      if (res.errors) throw res.errors;

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const onUnPublish = async () => {
    try {
      const res = await updateSpeciesProfileState({
        variables: {
          updateSpeciesProfileStateId: speciesProfileId,
          input: { state: SpeciesDetailsState.NotPublished },
        },
      });
      if (res.errors) throw res.errors;

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("public_profile")}</h1>

          <GraphqlError
            title="Error fetching Public profile data"
            errors={error}
          />
          <GraphqlError
            title="Error updating Public profile note"
            errors={updateSpeciesProfileMutationStatus.error}
          />
          <GraphqlError
            title="Error updating Public profile state"
            errors={updateSpeciesProfileStateMutationStatus.error}
          />

          <div className="flex justify-between align-center mrgn-bttm-md">
            <div className="flex font-size-18">
              <div>
                {t("state")}{" "}
                <div className="label label-info">
                  {speciesDetails?.state ? (
                    t(speciesDetails.state)
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
            </div>
            <div>
              {!loading && (
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.speciesProfile.update}
                >
                  {canPublish ? (
                    <MakePublishedButtonAndModal
                      canMakePublished={isPublishEnabled}
                      onMakePublished={onPublish}
                      warningMessage={t(
                        "publish_public_profile_to_registry_warning"
                      )}
                    />
                  ) : (
                    <UnPublishButtonAndModal
                      canUnPublish={true}
                      onUnPublish={onUnPublish}
                      warningMessage={t(
                        "unpublish_public_profile_from_registry_warning"
                      )}
                    />
                  )}
                </RenderWhenAuthorized>
              )}
            </div>
          </div>

          {/* ------------- Description ------------- */}
          <BilingualHtmlCard
            header={t("description")}
            htmlStringEnglish={speciesDetails?.description?.english?.text}
            htmlStringFrench={speciesDetails?.description?.french?.text}
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Distribution and population ------------- */}
          <BilingualHtmlCard
            header={t("distribution_and_population")}
            htmlStringEnglish={
              speciesDetails?.distributionAndPopulation?.english?.text
            }
            htmlStringFrench={
              speciesDetails?.distributionAndPopulation?.french?.text
            }
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Habitat ------------- */}
          <BilingualHtmlCard
            header={t("habitat")}
            htmlStringEnglish={speciesDetails?.habitat?.english?.text}
            htmlStringFrench={speciesDetails?.habitat?.french?.text}
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Critical habitat ------------- */}
          <BilingualHtmlCard
            header={t("critical_habitat")}
            htmlStringEnglish={speciesDetails?.criticalHabitat?.english?.text}
            htmlStringFrench={speciesDetails?.criticalHabitat?.french?.text}
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Biology ------------- */}
          <BilingualHtmlCard
            header={t("biology")}
            htmlStringEnglish={speciesDetails?.biology?.english?.text}
            htmlStringFrench={speciesDetails?.biology?.french?.text}
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Threats ------------- */}
          <BilingualHtmlCard
            header={t("threats")}
            htmlStringEnglish={speciesDetails?.threats?.english?.text}
            htmlStringFrench={speciesDetails?.threats?.french?.text}
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Protection ------------- */}
          <BilingualHtmlCard
            header={t("protection")}
            htmlStringEnglish={speciesDetails?.protection?.english?.text}
            htmlStringFrench={speciesDetails?.protection?.french?.text}
            loading={loading}
            editLinkTo={PublicProfilePathHelpers.PublicProfileEdit(
              speciesProfileId
            )}
            role={ROLE_ACTIONS.speciesProfile.update}
          />

          {/* ------------- Note ------------- */}
          <NoteCard
            header={t("note")}
            note={speciesDetails?.note}
            loading={loading}
            onUpdateNote={onUpdateNote}
            role={ROLE_ACTIONS.speciesProfile.update}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PublicProfilePage;
