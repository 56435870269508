// Mapping backend error code to frontend error message.
const errorMessagesTranslation = {
  en: {
    Contact_DistList_MemberMustBeUniqueBR:
      "The selected contact/organization is already in the list.",
    Contact_DistList_EmailMemberRequired:
      "The selected contact/organization must have an email address.",
  },

  fr: {
    Contact_DistList_MemberMustBeUniqueBR:
      "Le contact/l'organisation sélectionné(e) figure déjà dans la liste.",
    Contact_DistList_EmailMemberRequired:
      "Le contact/l'organisation sélectionné(e) doit disposer d'une adresse électronique.",
  },
};

export default errorMessagesTranslation;
