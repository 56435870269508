import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "util/router";
import RenderWhenFeatureIsEnabled from "../../features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "../../features/featureFlags/config";
import SearchAssessment from "./assessment";
import SearchChangelogCosewic from "./changelogCosewic";
import SearchChangelogListing from "./changelogListing";
import SearchCosewic from "./cosewic";
import SearchListing from "./listing";
import SearchListingLand from "./listingLands/listingLands";
import SpeciesByLand from "./listingLands/speciesByLand";
import SearchQueryTool from "./queryTool";
import QueryToolCreate from "./queryTool/create";
import SearchWildlifeSpecies from "./wildlifeSpecies";

export interface SearchRouterProps {}

export const SearchPathHelpers = {
  WildlifeSpecies: () => `/search/wildlifeSpecies`,
  Cosewic: () => `/search/cosewic`,
  Assessment: () => `/search/assessment`,
  Listing: () => `/search/listing`,
  ChangeLog: () => `/search/changelog`,
  ListingLands: () => `/search/listingLands`,
  ListingLandsSpeciesByLand: () => `/search/listingLands/speciesByLand`,
  QueryTool: () => `/search/queryTool`,
  QueryToolCreate: "/search/queryTool/create",
};

export const SearchRouter: React.FC<SearchRouterProps> = (props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route path={relative("/")} exact>
        {/* Fallback route, render search wildlife species page */}
        <SearchWildlifeSpecies />
      </Route>
      <Route path={relative("/wildlifeSpecies")}>
        <SearchWildlifeSpecies />
      </Route>
      <Route path={relative("/cosewic")}>
        <SearchCosewic />
      </Route>
      <Route path={relative("/assessment")}>
        <SearchAssessment />
      </Route>
      <Route path={relative("/listing")}>
        <SearchListing />
      </Route>
      <Route path={relative("/changelog")} exact>
        <Redirect to={"/search/changelog/cosewic"} />
      </Route>
      <Route path={relative("/changelog/cosewic")}>
        <SearchChangelogCosewic />
      </Route>
      <Route path={relative("/changelog/listing")}>
        <SearchChangelogListing />
      </Route>
      <Route path={relative("/listingLands")} exact>
        <SearchListingLand />
      </Route>
      <Route path={relative("/listingLands/speciesByLand")}>
        <SpeciesByLand />
      </Route>
      <Route path={relative("/queryTool")} exact>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.QueryTool}>
          <SearchQueryTool />
        </RenderWhenFeatureIsEnabled>
      </Route>
      <Route path={relative("/queryTool/create")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.QueryTool}>
          <QueryToolCreate />
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default SearchRouter;
