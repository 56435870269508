import { ListingProcessSchedule1Date } from "../../generated/gql-types";
import { formatTimestamp } from "../formatTimestamp";

export const parseSchedule1Date = (
  date?:
    | ListingProcessSchedule1Date
    | {
        Date?: null | string;
        DateRange?: null | { FromDate: string; ToDate: string };
      }
    | null
) => {
  if (date == null) return null;
  const _date = date as any; // Make typescript happy..

  if (_date.dateRange != null) {
    return `${formatTimestamp(_date.dateRange.fromDate)} - ${formatTimestamp(
      _date.dateRange.toDate
    )}`;
  }

  // Upper case version (for azure search)
  if (_date.DateRange != null) {
    return `${formatTimestamp(_date.DateRange.FromDate)} - ${formatTimestamp(
      _date.DateRange.ToDate
    )}`;
  }

  if (_date.date?.value) return formatTimestamp(_date.date?.value);
  // Upper case version (for azure search)
  if (_date.Date?.value) return formatTimestamp(_date.Date?.value);

  // Upper case version (for azure search)
  if (_date.Date) return formatTimestamp(_date.Date as any);
  return formatTimestamp(_date.date as any);
};

export default parseSchedule1Date;
