import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  DistinctLandManagerLookupDocument,
  LandType,
} from "generated/gql-types";
import { bilingualLookupLandManagerForLanguage } from "mappers";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface DistinctLandManagerDropdownProps {
  listingWsId: string;
  landType: LandType;
  id?: string;
  placeholder?: string;
  defaultValue?: any;
  onChange: (value: any) => void;
}

export const DistinctLandManagerDropdown = React.forwardRef<
  HTMLSelectElement,
  DistinctLandManagerDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(DistinctLandManagerLookupDocument, {
    variables: {
      params: {
        listingWsId: props.listingWsId,
        filters: `landType=${props.landType}`,
        pageNumber: 1,
        pageSize: 100,
      },
    },
  });
  const { t, i18n } = useTranslation();

  if (loading)
    return (
      <div>
        <LoadingIndicator />
      </div>
    );

  //
  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;
    const landManager =
      (data?.listingWsDistinctLandManagers?.land?.find(
        (x) => x?.landRef?.id === id
      )?.landRef?.landManager as any) ?? null;

    let value = "";
    if (landManager) {
      value = (
        i18n.language === "fr" ? landManager.french : landManager.english
      ) as string;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control full-width"
      ref={ref}
      id={props.id}
      onChange={onChange}
      defaultValue={props.defaultValue}
    >
      <option value={""}>{t(props.placeholder ?? "select_an_option")}</option>
      {data?.listingWsDistinctLandManagers?.land?.map((x) => {
        return (
          <option
            key={x?.landRef?.id}
            value={x?.landRef?.id ?? "error - missing id"}
          >
            {bilingualLookupLandManagerForLanguage(i18n.language, x?.landRef)}
          </option>
        );
      })}
    </select>
  );
});

export default DistinctLandManagerDropdown;
