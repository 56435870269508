import * as React from "react";
import { useTranslation } from "react-i18next";

export interface PaginationProps {
  pageIndex: number;
  totalPages: number;
  onPageSelected: (number: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = React.useState(props.pageIndex);
  const pageRange = 2; // determines the number of pagination buttons to display.

  if (pageIndex !== props.pageIndex) setPageIndex(props.pageIndex);

  const pageSelected = (index: number) => {
    setPageIndex(index);
    props.onPageSelected(index);
  };

  const makePageNumberArray = () => {
    if (props.totalPages === 0) return [];

    const pageNumArray = [];
    let rangeStart = pageIndex - pageRange;
    let rangeEnd = pageIndex + pageRange;

    if (rangeEnd > props.totalPages) {
      rangeEnd = props.totalPages;
      rangeStart = props.totalPages - pageRange * 2;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;
    }

    if (rangeStart <= 1) {
      rangeStart = 1;
      rangeEnd = Math.min(pageRange * 2 + 1, props.totalPages);
    }

    if (rangeStart > 1) {
      pageNumArray.push(1);
    }
    for (let p = rangeStart; p <= rangeEnd; p++) {
      pageNumArray.push(p);
    }
    if (rangeEnd < props.totalPages) {
      pageNumArray.push(props.totalPages);
    }

    return pageNumArray;
  };

  const pageNumberArray = makePageNumberArray();
  if (props.totalPages === 0) return null;

  return (
    <div>
      <div className="pull-right">
        <ul className="pagination pagination-sm mrgn-tp-sm mrgn-bttm-0">
          <li className={pageIndex <= 1 ? "disabled" : ""}>
            <a
              id="btn_pagination_previous"
              data-testid={"pagination-btn-previous"}
              rel="prev"
              tabIndex={0}
              href="#dataset_filter"
              onClick={() => pageSelected(pageIndex - 1)}
            >
              {t("previous")}
            </a>
          </li>
          {pageNumberArray.map((page) => (
            <li
              key={"page" + page}
              className={page === pageIndex ? "active" : ""}
            >
              <a
                tabIndex={0}
                data-testid={"pagination-btn-gotopage-" + page}
                href="#dataset_filter"
                role={"button"}
                aria-pressed={page === pageIndex ? "true" : "false"}
                onClick={() => pageSelected(page)}
                style={{
                  borderTopRightRadius:
                    pageIndex === props.totalPages && page === props.totalPages
                      ? "4px"
                      : "",
                  borderBottomRightRadius:
                    pageIndex === props.totalPages && page === props.totalPages
                      ? "4px"
                      : "",
                }}
              >
                <span className="wb-inv">Jump to: Page </span>
                {page}
              </a>
            </li>
          ))}

          <li className={pageIndex === props.totalPages ? "disabled" : ""}>
            <a
              id="btn_pagination_next"
              data-testid={"pagination-btn-next"}
              rel="next"
              tabIndex={0}
              href="#dataset_filter"
              onClick={() => pageSelected(pageIndex + 1)}
            >
              {t("next")}
            </a>
          </li>
        </ul>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default Pagination;
