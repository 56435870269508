import * as AzureSearchConfig from "../../../pages/search/hooks/azureSearchConfig";
import { AzureSearchParams } from "../types";

export async function fetchResults(
  indexName: string,
  params: AzureSearchParams
) {
  const url = new URL(`${AzureSearchConfig.BASE_URL}/${indexName}/docs/search`);
  url.searchParams.append("api-version", AzureSearchConfig.API_VERSION);

  const headers = new Headers();
  headers.append("api-key", AzureSearchConfig.API_KEY);
  headers.append("Content-Type", "application/json");

  return await fetch(url.toString(), {
    method: "post",
    headers,
    body: JSON.stringify(params),
  }).then((x) => x.json());
}
