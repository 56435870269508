import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import {
  BilingualLookupText,
  JurisdictionSelectorListDocument,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface JurisdictionSelectorProps {
  legend?: string;
  value?: BilingualLookupText;
  onChange: (value: BilingualLookupText) => void;
}

export const JurisdictionSelector: React.FC<JurisdictionSelectorProps> = (
  props
) => {
  const { t, i18n } = useTranslation();

  const { loading, error, data } = useQuery(JurisdictionSelectorListDocument, {
    variables: {},
  });

  // TODO: show the field legend outside this loading check
  if (loading) return <LoadingIndicator />;
  if (error) return <GraphqlError errors={error} />;

  const onChange = (e: any) => {
    const id = e.currentTarget?.value;

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    const found = data?.listingWsJurisdiction?.find((x) => x?.id === id);
    if (!found) return;

    props.onChange(found);
  };

  return (
    <fieldset className="chkbxrdio-grp">
      <legend>
        <span className="field-name">
          {props.legend ? props.legend : t("jurisdiction")}
        </span>
      </legend>
      <div className="radio">
        <label htmlFor="jurisdiction_unset">
          <input
            type="radio"
            name="jurisdictionInput"
            id="jurisdiction_unset"
            value={""}
            checked={isNullOrEmpty(props?.value?.id)}
            onChange={onChange}
          />
          <span className="mrgn-lft-sm">{t("None")}</span>
        </label>
      </div>
      {data?.listingWsJurisdiction?.map((x) => (
        <div className="radio" key={x?.id ?? "err - missing key"}>
          <label htmlFor={`jurisdiction_${x?.id}`}>
            <input
              type="radio"
              name="jurisdictionInput"
              id={`jurisdiction_${x?.id}`}
              value={x?.id ?? ""}
              checked={props.value?.id === x?.id}
              onChange={onChange}
            />
            <span className="mrgn-lft-sm">
              {bilingualLookupTextNameForLanguage(i18n.language, x)}
            </span>
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export default JurisdictionSelector;

export interface JurisdictionSelectorWithControllerProps<TFieldValues>
  extends Omit<JurisdictionSelectorProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const JurisdictionSelectorWithController = <TFieldValues,>(
  props: JurisdictionSelectorWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <JurisdictionSelector
          value={value as BilingualLookupText}
          onChange={onChange}
        />
      )}
    />
  );
};
