import { useMutation } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import PermitSideNav from "components/molecules/permit/PermitSideNav";
import PermitDescriptionForm, {
  PermitDescriptionFormFields,
} from "components/organisms/permit/permitDescriptionForm/PermitDescriptionForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/permit/permitDescriptionForm/mappers";
import { useGlobalAlertContext } from "features/globalAlert";
import { CreatePermitDescriptionDocument } from "generated/gql-types";
import { bilingualRichTextObjectForLanguage } from "mappers";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { PermitPathHelpers } from "./PermitRouter";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";

export interface PermitCreatePageProps {}

export const PermitCreatePage: React.FC<PermitCreatePageProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const [createPermitDescription, { loading: saving, error: savingError }] =
    useMutation(CreatePermitDescriptionDocument);

  const onSubmit: SubmitHandler<Partial<PermitDescriptionFormFields>> = async (
    FilteredFormData
  ) => {
    const mappedDomainModel = mapFormToDomainModel(FilteredFormData);

    try {
      const res = await createPermitDescription({
        variables: {
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("permit_description_successfully_added"),
        message:
          bilingualRichTextObjectForLanguage(
            i18n.language,
            res.data?.createPermitDescription?.title
          )?.plainText ?? "",
        timeOut: 5000,
      });

      history.push({
        pathname: PermitPathHelpers.PermitBrowse,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: PermitPathHelpers.PermitBrowse,
    });
  };

  const initialFormFields = mapDomainModelToForm(null);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("add_permit_description")}</h1>
          <GraphqlError
            title="Error creating new permit description"
            errors={savingError}
          />
          <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.permit.create}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <PermitDescriptionForm
              initialValues={initialFormFields}
              isFinalStage={false}
              isDmtsPermitDescription={true}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </RenderWhenAuthorized>
        </Layout.Content>
        <Layout.Sidebar>
          <PermitSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PermitCreatePage;
