import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import {
  BilingualText,
  Contact,
  ContactCreateInput,
  ContactDetails,
  ContactUpdateInput,
  MethodOfCommunication,
  Organization,
  OrganizationDetailsInput,
} from "../../../generated/gql-types";
import { makeBilingualText } from "../../../mappers";
import { filterForDirtyFields } from "../../../util/forms";
import isNullOrEmpty from "../../../util/isNullOrEmpty";
import { ContactFormFields } from "./form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import {
  mapContactDetailsInput_New,
  mapContactNameInput,
  mapNoteInput,
} from "util/formMappers";
import * as FormMappers from "../../../util/formMappers";

export const processFormValues = (
  formData: UnpackNestedValue<Partial<ContactFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ContactFormFields>
): Partial<ContactFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<ContactFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: ContactFormFields
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // contactDetails

  if (
    dirtyFields?.contactDetails?.emailAddress != null &&
    isNullOrEmpty(formData?.contactDetails?.emailAddress) &&
    !isNullOrEmpty(initialValues?.contactDetails?.emailAddress)
  ) {
    set(formData, "contactDetails.emailAddress", null);
  }

  if (
    dirtyFields?.contactDetails?.phoneNumber != null &&
    isNullOrEmpty(formData?.contactDetails?.phoneNumber) &&
    !isNullOrEmpty(initialValues?.contactDetails?.phoneNumber)
  ) {
    set(formData, "contactDetails.phoneNumber", null);
  }

  if (
    dirtyFields?.contactDetails?.faxNumber != null &&
    isNullOrEmpty(formData?.contactDetails?.faxNumber) &&
    !isNullOrEmpty(initialValues?.contactDetails?.faxNumber)
  ) {
    set(formData, "contactDetails.faxNumber", null);
  }

  // contactDetails.address

  if (
    dirtyFields?.contactDetails?.address?.city != null &&
    isNullOrEmpty(formData?.contactDetails?.address?.city) &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.city)
  ) {
    set(formData, "contactDetails.address.city", null);
  }

  if (
    dirtyFields?.contactDetails?.address?.postalOrZipCode != null &&
    isNullOrEmpty(formData?.contactDetails?.address?.postalOrZipCode) &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.postalOrZipCode)
  ) {
    set(formData, "contactDetails.address.postalOrZipCode", null);
  }

  // NOTE: countryCode is handled differently because it's a select element.
  // Instead of checking for null, we check for the "please select an option" value: `NOT_INITIALIZED`
  if (
    dirtyFields?.contactDetails?.address?.countryCode != null &&
    formData?.contactDetails?.address?.countryCode == "NOT_INITIALIZED" &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.countryCode)
  ) {
    set(formData, "contactDetails.address.countryCode", null);
  }

  // jobTitle

  if (
    dirtyFields?.jobTitle?.english != null &&
    isNullOrEmpty(formData?.jobTitle?.english) &&
    !isNullOrEmpty(initialValues?.jobTitle?.english)
  ) {
    set(formData, "jobTitle.english", null);
  }
  if (
    dirtyFields?.jobTitle?.french != null &&
    isNullOrEmpty(formData?.jobTitle?.french) &&
    !isNullOrEmpty(initialValues?.jobTitle?.french)
  ) {
    set(formData, "jobTitle.french", null);
  }

  // name

  if (
    dirtyFields?.name?.firstName != null &&
    isNullOrEmpty(formData?.name?.firstName) &&
    !isNullOrEmpty(initialValues?.name?.firstName)
  ) {
    set(formData, "name.firstName", null);
  }
  if (
    dirtyFields?.name?.lastName != null &&
    isNullOrEmpty(formData?.name?.lastName) &&
    !isNullOrEmpty(initialValues?.name?.lastName)
  ) {
    set(formData, "name.lastName", null);
  }

  // note
  if (dirtyFields?.note && htmlIsNullOrEmpty(formData?.note)) {
    set(formData, "note", null);
  }

  // organizationID is selected by an autocomplete, we probably don't need to map it to null in this function
  // tags is an array of enum values, we probably don't need to map it to null in this function
  // useOrganizationAddress is a boolean, we probably don't need to map it to null in this function
  if (
    dirtyFields?.organizationDetails != null &&
    isNullOrEmpty(formData?.organizationDetails?.id) &&
    !isNullOrEmpty(initialValues?.organizationDetails?.id)
  ) {
    set(formData, "organizationDetails", null);
  }
};

export const domainModelIntoForm = (
  contact?: Contact | null,
  orgContactDetails?: ContactDetails | null
): Partial<ContactFormFields> => {
  const out: Partial<ContactFormFields> = {};

  // Name
  FormMappers.mapContactName(out, "name", contact?.name);

  // Contact details
  //out.contactDetails = mapContactDetails(contact?.contactDetails ?? undefined);
  FormMappers.mapContactDetails(out, "contactDetails", contact?.contactDetails);

  // Set up the initial value to "NOT_INITIALIZED" for country code if is is empty
  FormMappers.mapPrimitiveType(
    out,
    "contactDetails.address.countryCode",
    contact?.contactDetails?.address?.countryCode ?? "NOT_INITIALIZED"
  );

  // job title
  FormMappers.mapBilingualText(out, "jobTitle", contact?.jobTitle);

  // method of communication
  FormMappers.mapPrimitiveType(
    out,
    "methodOfCommunication",
    contact?.methodOfCommunication ?? MethodOfCommunication.MailingAddress
  );

  // note
  FormMappers.mapNote(out, "note", contact?.note);

  // tags
  FormMappers.mapArrayUsingFn(set, out, "tags", contact?.tags);

  FormMappers.mapPrimitiveType(
    out,
    "useOrganizationAddress",
    contact?.useOrganizationAddress ?? false
  );

  // organization ref needs to be mapped into the form representation
  FormMappers.mapOrganizationDetails(
    out,
    "organizationDetails",
    contact?.organizationDetails
  );

  // mapping formOrganizationRef
  FormMappers.mapOrganizationDetails(
    out,
    "formOrganizationRef",
    contact?.organizationDetails
  );

  FormMappers.mapContactDetails(
    out,
    "formOrganizationRef.contactDetails",
    orgContactDetails
  );

  return out;
};

export const formIntoCreateInput = (
  formData: Partial<ContactFormFields>,
  isCreatContact?: boolean
): ContactCreateInput => {
  const out: ContactCreateInput = {};

  mapContactDetailsInput_New(out, "contactDetails", formData?.contactDetails);

  if (formData.jobTitle !== undefined) {
    out.jobTitle = makeBilingualText(formData.jobTitle);
  }

  if (formData.methodOfCommunication !== undefined) {
    out.methodOfCommunication = formData.methodOfCommunication;
  } else if (isCreatContact) {
    out.methodOfCommunication = MethodOfCommunication.MailingAddress;
  }

  if (formData.name !== undefined) {
    mapContactNameInput(out, "name", formData.name);
  }

  if (formData.note !== undefined) {
    mapNoteInput(out, "note", formData.note);
  }

  if (formData.organizationDetails !== undefined) {
    out.organizationDetails = formData.organizationDetails;
  }

  if (formData.tags !== undefined) {
    out.tags = formData.tags;
  }

  if (formData.useOrganizationAddress !== undefined) {
    out.useOrganizationAddress = formData.useOrganizationAddress;
  }

  return out;
};

export const formIntoUpdateInput = (
  formData: Partial<ContactFormFields>
): ContactUpdateInput => {
  // Remove the field that isn't a part of the data model
  delete formData["formOrganizationRef"];
  return formIntoCreateInput(formData);
};

/**
 * This is used to map one field in the form component: organizationDetails.
 * The organization returns an `organization` object, but that doesn't match 1:1 with
 * the organizationDetails structure inside ContactFormFields.
 *
 * @param organization
 * @returns organizationDetails
 */
export const mapOrganizationToOrganizationDetails = (
  organization?: Organization | null
): Partial<OrganizationDetailsInput> | undefined => {
  if (organization == null) return undefined;

  const out: Partial<OrganizationDetailsInput> = {};

  out.id = organization?.id;
  out.departments = organization?.departments;
  out.name = organization?.name;

  return out;
};

export const mapContactDetails = (
  contactDetails: ContactDetails | undefined
): ContactDetails | undefined => {
  const out: ContactDetails = {};

  set(out, "emailAddress", contactDetails?.emailAddress ?? "");
  set(out, "phoneNumber", contactDetails?.phoneNumber ?? "");
  set(out, "faxNumber", contactDetails?.faxNumber ?? "");
  set(out, "address.city", contactDetails?.address?.city ?? "");
  set(
    out,
    "address.postalOrZipCode",
    contactDetails?.address?.postalOrZipCode ?? ""
  );
  set(
    out,
    "address.provinceOrState",
    contactDetails?.address?.provinceOrState ?? ""
  );
  set(
    out,
    "address.provinceOrStateName",
    contactDetails?.address?.provinceOrStateName ?? ""
  );
  set(
    out,
    "address.countryCode",
    contactDetails?.address?.countryCode ?? "NOT_INITIALIZED"
  );

  let addresslines: BilingualText[] = [
    { english: "", french: "" },
    { english: "", french: "" },
  ];
  contactDetails?.address?.addressLines?.forEach((v, i) => {
    if (!isNullOrEmpty(v?.english)) addresslines[i].english = v?.english;
    if (!isNullOrEmpty(v?.french)) addresslines[i].french = v?.french;
  });
  set(out, "address.addressLines", addresslines);

  return out;
};
