import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";
import LinkForm, { LinkFields } from "../LinkForm/LinkForm";
import { UpdateDocumentLinkDocument } from "generated/gql-types";
import { SubmitHandler } from "react-hook-form";

export interface EditLinkPopupProps {
  modalState: OverlayTriggerState;
  documentId: string;
  linkId: string;
  defaultValues?: LinkFields;
  refetchQuery: () => void;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditLinkPopup: React.FC<EditLinkPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, documentId, defaultValues, refetchQuery } = props;

  const [updateDocumentLink, { loading, error, reset }] = useMutation(
    UpdateDocumentLinkDocument,
    {
      onCompleted: props.onCompleted,
      onError: props.onError,
    }
  );

  const onSubmit: SubmitHandler<LinkFields> = async (formData) => {
    try {
      const res = await updateDocumentLink({
        variables: {
          documentId: props?.documentId,
          linkId: props?.linkId,
          input: {
            language: formData?.language,
            reference: {
              name: {
                text: formData.name,
              },
              uri: formData?.urlToDocument,
            },
          },
        },
      });
      if (res.errors == null) {
        modalState.close();
        reset();
        refetchQuery();
      }
    } catch (e) {
      console.warn("Error editing Link");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
    refetchQuery();
  };

  if (!modalState.isOpen) return null;
  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_link")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
      >
        <LinkForm
          documentId={documentId}
          defaultValues={defaultValues}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditLinkPopup;

export const useManageLinkPopup = useOverlayTriggerState;
export type { LinkFields as AddLinksFields };
