import MissingData from "components/atoms/MissingData";
import { Assessment, CosewicWs, Maybe } from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import isNullOrEmpty from "util/isNullOrEmpty";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../../../features/auth/components";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../atoms/SafeRenderHtml";

export interface CosewicInformationSectionProps {
  cosewicWs?: Maybe<CosewicWs>;
  cosewicWsAssessmentLatest?: Maybe<Assessment>;
}

export const CosewicInformationSection: React.FC<
  CosewicInformationSectionProps
> = (props) => {
  const { cosewicWs, cosewicWsAssessmentLatest } = props;
  const { t, i18n } = useTranslation();

  const id = cosewicWs?.id;

  const commonNameEn = htmlRemoveOuterPTag(
    cosewicWs?.commonNameEnglish?.name?.text
  );
  const commonNameFr = htmlRemoveOuterPTag(
    cosewicWs?.commonNameFrench?.name?.text
  );

  const populationEn = cosewicWs?.population?.nameEn;
  const populationFr = cosewicWs?.population?.nameFr;

  const nameWithComma = cosewicWs?.nameWithComma?.name?.text;

  const scientificName =
    cosewicWs?.scientificName?.generatedScientificName?.name?.text;

  const status = cosewicWsAssessmentLatest?.statusAndCriteria?.status;

  const cosewicId = cosewicWs?.cosewicId;

  const rangesString =
    i18n.language === "fr"
      ? cosewicWs?.ranges?.map((x) => x?.nameFr).join(", ")
      : cosewicWs?.ranges?.map((x) => x?.nameEn).join(", ");

  const latestAssessmentDate = cosewicWsAssessmentLatest?.date;

  const CommonNameAndPopulation = (
    <>
      {htmlIsNullOrEmpty(commonNameEn) ? (
        <MissingData />
      ) : (
        <SafeRenderHtml htmlString={commonNameEn!} />
      )}
      {populationEn && (
        <>
          {", "}
          {populationEn}
        </>
      )}
    </>
  );

  return (
    <div className="species-field">
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("english_common_name")}</dt>
            <dd>
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.view}
                fallbackComponent={CommonNameAndPopulation}
              >
                <Link
                  to={CosewicPathHelpers.CosewicProfile(id!)}
                  className="hover-underline"
                  data-testid="link-to-cosewic"
                >
                  {CommonNameAndPopulation}
                </Link>
              </RenderWhenAuthorized>
            </dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("cosewic_status")}</dt>
            <dd>{status ? t(status) : <MissingData />}</dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">ID</dt>
            <dd>{cosewicId ?? <MissingData />}</dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("french_common_name")}</dt>
            <dd>
              {isNullOrEmpty(commonNameFr) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={commonNameFr!} />
              )}
              {populationFr && (
                <>
                  {", "}
                  {populationFr}
                </>
              )}
            </dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("english_name_with_comma")}</dt>
            <dd>
              {htmlIsNullOrEmpty(nameWithComma) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={nameWithComma!} />
              )}
            </dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("scientific_name")}</dt>
            <dd>
              {htmlIsNullOrEmpty(scientificName) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={scientificName!} />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("range")}</dt>
            <dd>
              {isNullOrEmpty(rangesString) ? <MissingData /> : rangesString}
            </dd>
          </div>
        </dl>
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("last_assessment_date")}</dt>
            <dd>{formatTimestamp(latestAssessmentDate) ?? <MissingData />}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default CosewicInformationSection;
