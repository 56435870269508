import * as React from "react";
import { useTranslation } from "react-i18next";
import Alert, { AlertTypes } from "../../../atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "../../ConfirmationModal";

export interface AdminUnarchiveModalProps {
  selectedItemId: string;
  onUnarchive: () => void;
  modalTitle: string;
  warningText: string;
}

export const AdminUnarchiveModal: React.FC<AdminUnarchiveModalProps> = (
  props
) => {
  const { onUnarchive, modalTitle, warningText, selectedItemId } = props;
  const { t } = useTranslation();

  const confirmationModal = useConfirmationModal({});
  const [confirmCheckboxState, setConfirmCheckboxState] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setConfirmCheckboxState(false);
  }, [selectedItemId]);

  return (
    <>
      <button
        className="btn btn-link card-header-button-link hover-underline-danger"
        type="button"
        data-testid="button-unarchive"
        onClick={confirmationModal.open}
        disabled={selectedItemId === ""}
      >
        <span className="text-danger">{t("unarchive")}</span>
      </button>
      <ConfirmationModal
        onConfirm={onUnarchive}
        onCancel={() => {}}
        title={modalTitle}
        confirmBtnEnabled={confirmCheckboxState}
        modalState={confirmationModal}
      >
        <>
          <Alert type={AlertTypes.WARNING} content={warningText} />

          <label>
            <input
              type="checkbox"
              checked={confirmCheckboxState}
              onChange={(e) => setConfirmCheckboxState((x) => !x)}
              data-testid="modal-checkbox-confirm-unarchive"
            />{" "}
            {t("unarchive_confirm")}
          </label>
        </>
      </ConfirmationModal>
    </>
  );
};

export default AdminUnarchiveModal;
