const publicProfileTranslation = {
  en: {
    public_profile: "Public profile",
    description: "Description",
    distribution_and_population: "Distribution and population",
    habitat: "Habitat",
    critical_habitat: "Critical habitat",
    biology: "Biology",
    threats: "Threats",
    protection: "Protection",
    edit_public_profile: "Edit public profile",
    publish_public_profile_to_registry_warning:
      "Publishing the species details will add this information to the species profile page of the Registry.",
    unpublish_from_registry: "Unpublish from registry",
    unpublish_public_profile_from_registry_warning:
      "Unpublishing the species details will remove the information on the species profile page on the Registry.",
    unpublish_from_registry_confirm:
      "I confirm that I’d like to unpublish the species details from Registry.",
  },

  fr: {
    public_profile: "Profil public",
    description: "Description",
    distribution_and_population: "Répartition et population",
    habitat: "Habitat",
    critical_habitat: "Habitat essentiel",
    biology: "Biologie",
    threats: "Menaces",
    protection: "Protection",
    edit_public_profile: "Modifier le profil public",
    publish_public_profile_to_registry_warning:
      "Publier les details de l'espèce ajoutera cette information sur la page du profile de l’espèce sur le Registre.",
    unpublish_from_registry: "Dépublier du Registre",
    unpublish_public_profile_from_registry_warning:
      "Dépublier les details de l'espèce supprimera cette information sur la page du profile l'espèce sur le Registre.",
    unpublish_from_registry_confirm:
      "Je confirme que je souhaite dépublier les détails de l’espèce du Registre.",
  },
};

export default publicProfileTranslation;
