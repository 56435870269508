/// Sanitize strings, mainly used for rich text fields.
const REGEX_DOUBLE_SPACE = /\s\s/g;
export const sanitizeDoubleSpaces = (str?: string | null) => {
  if (str == null) return str;

  return str.replace(REGEX_DOUBLE_SPACE, " ").trim();
};

/// Capitalize first letter and lowercase rest
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// Left trim string
export const lTrimInputText = (e: any) => {
  e.target.value = e.target.value.trimStart();
};

// Right trim string
export const rTrimInputText = (e: any) => {
  e.target.value = e.target.value.trimEnd();
};

export const isHTMLString = (input: string) => {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(input);
};
