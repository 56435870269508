import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButtons from "../FormButtons/FormButtons";
import ProgramAutocomplete from "components/organisms/DistributionLists/ProgramAutocomplete";
import { BilingualAbbreviationAcronymLookupTextInput } from "generated/gql-types";

interface ProgramFormProps {
  initialValues: Partial<ProgramFields>;
  onSubmit: SubmitHandler<Partial<ProgramFields>>;
  onClose: () => void;
}

const ProgramForm: React.FC<ProgramFormProps> = (props) => {
  const { initialValues } = props;
  const form = useForm<ProgramFields>({
    defaultValues: initialValues,
  });

  const { handleSubmit, formState, control } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<Partial<ProgramFields>> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/*---------------Program Type Auto Complete---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="program"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <ProgramAutocomplete
              label={t("program")}
              isRequired={true}
              onSelectionChange={onChange}
              disabled={false}
              initialValue={value}
            />
          )}
        />
      </div>
      <hr />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
        errors={errors}
      />
    </form>
  );
};

export default ProgramForm;

export interface ProgramFields {
  program?: BilingualAbbreviationAcronymLookupTextInput;
}

// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
