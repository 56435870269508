import { useMutation, useQuery } from "@tanstack/react-query";
import Alert, { AlertTypes } from "components/atoms/Alert";
import ExportButton from "components/atoms/ExportButton";
import Layout from "components/layouts/OneColumn";
import { GlobalAlert } from "features/globalAlert";
import { useBasicSearchQuery } from "features/search/hooks/useBasicSearchQuery";
import { LISTING_LAND_INDEX } from "features/search/listingLands/constants";
import speciesByLandFacets from "features/search/listingLands/speciesByLandFacets.json";
import odataMappers from "features/search/listingLands/speciesByLandOdataMappers";
import { fetchFilters } from "features/search/utils/fetchFilters";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import useQueryParams from "hooks/util/useQueryParams";
import { omit } from "lodash";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchExport,
  SearchServiceExportParams,
} from "util/basicSearch/fetchExport";
import LoadingIndicator from "../../../components/atoms/LoadingIndicator";
import NoResults from "../../../components/atoms/NoResults";
import SectionCard from "../../../components/atoms/SectionCard";
import BasicSearchCheckboxListDetails from "../../../components/molecules/search/BasicSearchCheckboxListDetails";
import PageSizeSelect from "../../../components/molecules/PageSizeSelect";
import Pagination from "../../../components/molecules/Pagination";
import FilterTags from "../../../components/organisms/search/FilterTags/FilterTags";
import ResultsTable from "../../../components/organisms/search/ResultsTable/ResultsTable";
import { makePaging } from "../../../features/search/utils/makePaging";
import { makeFilterString } from "../../../features/search/utils/makeFilterString";
import useBasicSearch from "../hooks/useBasicSearch";

interface SearchSpeciesByLandColumns {
  id: string;
  "Listing/CommonNameEnglish/Name"?: string;
  "Listing/CommonNameFrench/Name"?: string;
  "Listing/Id"?: string;
  "Listing/ListingId"?: string;
  "Listing/TaxonomicGroup/NameEn"?: string;
  "Listing/TaxonomicGroup/NameFr"?: string;
  "ListingLand/WildlifeSpeciesPresence"?: string;
  "Listing/ListingStatus"?: string;
  "Listing/CosewicStatus"?: string;
  "Listing/Population/NameEn"?: string;
  "Listing/Population/NameFr"?: string;
  "Listing/ListingBatch"?: string;
  "ListingLand/LandRef/LandName"?: string;
  "ListingLand/LandRef/LandType"?: string;
  "ListingLand/LandRef/LandManager/English"?: string;
  "ListingLand/LandRef/LandManager/French"?: string;
  "ListingLand/LandRef/ProvinceAndTerritory"?: string;
  "ListingLand/LandRef/Dfrp"?: string;
  "ListingLand/NumberOfSites"?: string;
  "ListingLand/ApproximateNumberOfIndividuals"?: string;
  "ListingLand/Circumstance"?: string;
  "ListingLand/Source"?: string;
}

const searchFields = "";
const queryType = "full";
const select =
  "Id, Listing/ListingId, Listing/Id, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/TaxonomicGroup, Listing/ListingStatus, Listing/CosewicStatus, ListingLand/WildlifeSpeciesPresence";
const exportSelect =
  "Listing/ListingId, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/ScientificName/GeneratedScientificName/Name, Listing/TaxonomicGroup, Listing/ListingStatus, Listing/CosewicStatus, Listing/ListingBatch, ListingLand/LandRef/LandName, ListingLand/LandRef/LandType, ListingLand/LandRef/LandManager/English, ListingLand/LandRef/LandManager/French, ListingLand/LandRef/ProvinceAndTerritory, ListingLand/LandRef/Dfrp, ListingLand/WildlifeSpeciesPresence, ListingLand/NumberOfSites, ListingLand/ApproximateNumberOfIndividuals, ListingLand/Circumstance, ListingLand/Source";

const SpeciesByLand: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);
  const query = useQueryParams();

  const listingLandsExtraFilters = useMemo(() => {
    return {
      and: [
        {
          ListingLand: {
            LandRef: {
              LandId: { eq: query.get("landId") },
            },
          },
        },
      ],
    };
  }, [query]);

  const basicSearch = useBasicSearch("basicsearch_speciesByLand");

  const filtersQuery = useQuery(["basicSearch", "filters"], () => {
    return fetchFilters(
      speciesByLandFacets,
      LISTING_LAND_INDEX,
      listingLandsExtraFilters
    );
  });

  const { data, loading, error, newRunSearchQuery } =
    useBasicSearchQuery(LISTING_LAND_INDEX);

  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      const column =
        i18n.language === "fr"
          ? "Listing/CommonNameFrench/Name"
          : "Listing/CommonNameEnglish/Name";

      return makeOrderByString({
        column,
        direction: "ascending",
      });
    }

    return makeOrderByString(sortDescriptor);
  }, [i18n.language, sortDescriptor]);

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      const filterString = makeFilterString(
        odataMappers,
        basicSearch.state,
        listingLandsExtraFilters
      );

      const paging = makePaging(pagination);

      return {
        filter: filterString,
        // search: basicSearch.state.keywordSearchText,
        queryType,
        searchFields,
        select,
        orderby: orderByString,
        count: true,
        ...paging,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      newRunSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  const columns: Array<{
    name: string;
    key: keyof SearchSpeciesByLandColumns;
    sortable: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("listing_id"),
        key: "Listing/ListingId",
        sortable: true,
      },
      {
        name: t("common_name_with_population"),
        key:
          i18n.language === "fr"
            ? "Listing/CommonNameFrench/Name"
            : "Listing/CommonNameEnglish/Name",
        sortable: true,
        hasLink: true,
      },
      {
        name: t("taxonomic_group"),
        key:
          i18n.language === "fr"
            ? "Listing/TaxonomicGroup/NameFr"
            : "Listing/TaxonomicGroup/NameEn",
        sortable: true,
      },
      {
        name: t("wildlife_species_presence"),
        key: "ListingLand/WildlifeSpeciesPresence",
        sortable: true,
      },
      {
        name: t("listing_status"),
        key: "Listing/ListingStatus",
        sortable: true,
      },
      {
        name: t("cosewic_status"),
        key: "Listing/CosewicStatus",
        sortable: true,
      },
    ],
    [i18n.language]
  );

  //
  const rows: Array<SearchSpeciesByLandColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let wildlifeSpeciesName,
          taxonomicGroup = "";
        if (i18n.language === "fr") {
          wildlifeSpeciesName =
            value?.Listing?.CommonNameFrench?.Name ??
            value?.Listing?.CommonNameEnglish?.Name;
          taxonomicGroup =
            value?.Listing?.TaxonomicGroup?.NameFr ??
            value?.Listing?.TaxonomicGroup?.NameEn;
          if (value?.Listing?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Listing?.Population?.NameFr ??
              value?.Listing?.Population?.NameEn;
        } else {
          wildlifeSpeciesName =
            value?.Listing?.CommonNameEnglish?.Name ??
            value?.Listing?.CommonNameFrench?.Name;
          taxonomicGroup =
            value?.Listing?.TaxonomicGroup?.NameEn ??
            value?.Listing?.TaxonomicGroup?.NameFr;
          if (value?.Listing?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Listing?.Population?.NameEn ??
              value?.Listing?.Population?.NameFr;
        }

        let _href = ListingPathHelpers.ListingLands(value?.Listing?.Id);

        return {
          _href,
          id: value?.Id,
          "Listing/ListingId": value?.Listing?.ListingId,
          [i18n.language === "fr"
            ? "Listing/CommonNameFrench/Name"
            : "Listing/CommonNameEnglish/Name"]: wildlifeSpeciesName,
          [i18n.language === "fr"
            ? "Listing/TaxonomicGroup/NameFr"
            : "Listing/TaxonomicGroup/NameEn"]: taxonomicGroup,
          "ListingLand/WildlifeSpeciesPresence": t(
            value?.ListingLand?.WildlifeSpeciesPresence
          ),

          "Listing/ListingStatus": t(value?.Listing?.ListingStatus),
          "Listing/CosewicStatus": t(value?.Listing?.CosewicStatus),
        };
      }),
    [data?.value]
  );

  const exportMutation = useMutation(
    ["basicSearch", "listingLands", "export"],
    (params: any) => fetchExport(params, LISTING_LAND_INDEX)
  );

  const runExport = async () => {
    const languageCode = i18n.language as any;
    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    const baseSearchArguments = omit(azureSearchArguments, ["count"]);

    const searchParameters: SearchServiceExportParams = {
      ...baseSearchArguments,

      queryType: queryType,
      search: basicSearch.state.keywordSearchText,
      includeTotalCount: true,
      searchFields: searchFields.split(/,\s?/g),
      orderby: orderByString?.split(/,\s?/g),
      select: exportSelect.split(/,\s?/g),
      searchTemplateName: "FULL",
    };

    return exportMutation.mutate({
      searchParameters,
      languageCode,
    });
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>
            {t("listing_wildlife_species_located_in", {
              landName: query.get("landName"),
            })}
          </h1>
          <div className="row">
            <div className="col-md-3">
              <SectionCard
                header={<h2 className="mrgn-tp-0">{t("filters")}</h2>}
                className="pb-0"
              >
                {/* ============ Wildlife Species Presence filters ============ */}
                <BasicSearchCheckboxListDetails
                  allFacets={allFacets}
                  basicSearch={basicSearch}
                  facetName={"ListingLand/WildlifeSpeciesPresence"}
                  header={t("wildlife_species_presence")}
                  open={true}
                  translateItems
                />
              </SectionCard>
            </div>
            <div className="col-md-9  ">
              {exportMutation.isSuccess ? (
                <Alert
                  type={AlertTypes.SUCCESS}
                  title={t("export_success")}
                  onClose={exportMutation.reset}
                />
              ) : null}

              {exportMutation.isError ? (
                <Alert
                  type={AlertTypes.DANGER}
                  title={t("export_failure")}
                  onClose={exportMutation.reset}
                />
              ) : null}

              <SectionCard
                header={
                  <div className="flex justify-between align-start">
                    <h2>{t("listing_wildlife_species")}</h2>
                    <div className="flex gap-md align-start">
                      <ExportButton
                        text={t("export")}
                        onExport={runExport}
                        loading={exportMutation.isLoading}
                      />
                    </div>
                  </div>
                }
              >
                <>
                  <FilterTags onReset={onResetTags} basicSearch={basicSearch} />

                  {/*RESULTS:*/}
                  {loading ? (
                    <LoadingIndicator centered className="mrgn-bttm-md" />
                  ) : rows && rows?.length === 0 ? (
                    <NoResults centered />
                  ) : (
                    <>
                      <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                        <div className="font-size-18">
                          {pagination.makeShowingString(
                            data ? data["@odata.count"] : 0
                          )}
                        </div>
                        <div>
                          <PageSizeSelect
                            pageSize={pagination.pageSize}
                            onChangePageSize={onChangePageSize}
                          />
                        </div>
                      </div>
                      {!loading && error ? (
                        <pre>{JSON.stringify(error)}</pre>
                      ) : null}
                      {rows == null || rows.length === 0 ? null : (
                        <ResultsTable
                          rows={rows}
                          columns={columns}
                          sortable
                          sortDescriptor={sortDescriptor}
                          onSortChange={onSortChange as any}
                        />
                      )}

                      <Pagination
                        {...pagination.paginationComponentProps}
                        onPageSelected={onPageSelected}
                        totalPages={
                          data && data["@odata.count"] && pagination.pageSize
                            ? Math.ceil(
                                data["@odata.count"] / pagination.pageSize
                              )
                            : 0
                        }
                      />
                    </>
                  )}
                </>
              </SectionCard>
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default SpeciesByLand;
