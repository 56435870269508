import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  BilingualAbbreviationLookupText,
  GicDecisionLookupDocument,
} from "generated/gql-types";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";

export interface GICDecisionDropdownProps {
  id?: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  disabled?: boolean;
  onChange: (value: BilingualAbbreviationLookupText) => void;
}

export const GICDecisionDropdown = React.forwardRef<
  HTMLSelectElement,
  GICDecisionDropdownProps
>((props, ref) => {
  const { loading, error, data } = useQuery(GicDecisionLookupDocument);
  const { t, i18n } = useTranslation();

  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;
    const value = data?.listingWsProcessGicDecision?.find((x) => x?.id === id);

    // Handle user un-setting the value
    if (isNullOrEmpty(id)) {
      props.onChange(null as any);
      return;
    }

    if (!value) {
      console.error(`GICDecisionDropdown couldn't find a value for ID ${id}`);
      return;
    }
    props.onChange(value);
  };

  return (
    <select
      className="form-control"
      ref={ref}
      {...props}
      data-testid={props.id}
      onChange={onChange}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
    >
      <option value={""}>{t("please_select_option")}</option>
      {data?.listingWsProcessGicDecision?.map((x) => {
        return (
          <option key={x?.id} value={x?.id ?? "error - missing id"}>
            {bilingualLookupTextNameForLanguage(i18n.language, x)}
          </option>
        );
      })}
    </select>
  );
});

export interface GICDecisionDropdownWithControllerProps<TFieldValues>
  extends Omit<GICDecisionDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const GICDecisionDropdownWithController = <TFieldValues,>(
  props: GICDecisionDropdownWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <GICDecisionDropdown
          id={props.id}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={onChange}
          defaultValue={(value as any)?.id ?? ""}
        />
      )}
    />
  );
};

export default GICDecisionDropdown;
