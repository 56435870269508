import Filters from "components/organisms/search/FilterOptionsSection/filters/filters";
import { useTranslation } from "react-i18next";
import NoResults from "../../atoms/NoResults";
import useBasicSearch from "../../../pages/search/hooks/useBasicSearch";
import { partition } from "lodash";

const RGX_OTHER = /other/gi;

export interface BasicSearchProgramsCheckboxListDetailsProps {
  facetName: keyof typeof Filters;
  header: string;
  translateItems?: boolean;
  open?: boolean; // whether or not the details section is expanded or not
  allFacets: any;
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const BasicSearchProgramsCheckboxListDetails: React.FC<
  BasicSearchProgramsCheckboxListDetailsProps
> = (props) => {
  const { t } = useTranslation();

  const items: any[] =
    props.allFacets == null ? [] : props.allFacets[props.facetName] ?? [];

  const selectedCountInSection = props.basicSearch.getCountForSection(
    props.facetName
  );

  const sortCompareFunction = (a: { value: string }, b: { value: string }) =>
    t(a.value).localeCompare(t(b.value));

  // SPECIAL CASE: Ticket #BUG 63237: Programs items should be in alphabetical order, with Other always at the bottom.
  // Split the list into others and non-others
  const [others, nonOthers] = partition(items, (x) => {
    RGX_OTHER.lastIndex = 0;
    return RGX_OTHER.test(x.value);
  });
  // Merge back into one list, with others at the end
  const rearrangedItems = nonOthers
    .sort(sortCompareFunction)
    .concat(others.sort(sortCompareFunction));

  return (
    <>
      <details className="species-details" open={props.open}>
        <summary>
          {props.header}{" "}
          {selectedCountInSection > 0 ? (
            <span className="badge">
              {selectedCountInSection}
              <span className="wb-inv"> options selected</span>
            </span>
          ) : null}
        </summary>

        <div className="flex-col">
          {rearrangedItems.length === 0 ? (
            <NoResults />
          ) : (
            rearrangedItems.map((filter) => {
              if (filter.count === 0) return null;
              const name = `${props.facetName}/${filter.value}`;
              const label = props.translateItems
                ? t(filter.value)
                : filter.value;

              return (
                <div className="checkbox" key={name}>
                  <div className="flex justify-between">
                    <label>
                      <input
                        type="checkbox"
                        name={name}
                        id={name}
                        data-testid={name}
                        checked={props.basicSearch.isCheckboxSelected(
                          props.facetName,
                          filter.value
                        )}
                        onChange={() => {
                          props.basicSearch.dispatch({
                            type: "toggle_checkbox_filter",
                            filter: {
                              value: filter.value,
                              label: `${props.header} = ${label}`,
                              facet: props.facetName,
                            },
                          });
                        }}
                      />{" "}
                      {label}
                    </label>
                    <span className="badge">{filter.count}</span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </details>
    </>
  );
};

export default BasicSearchProgramsCheckboxListDetails;
