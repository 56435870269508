import { BilingualRichText } from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";
import { ListingProcessTifFormFields } from "./ListingProcessTifForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<ListingProcessTifFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ListingProcessTifFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  if (
    dirtyFields?.proposedConsultationPath &&
    isNullOrEmpty(formData?.proposedConsultationPath)
  ) {
    set(formData, "proposedConsultationPath", null);
  }

  setNullForEmptyBilingualRichTextFields(
    "rangeOfImpact",
    formData,
    dirtyFields,
    initialValues
  );
  setNullForEmptyBilingualRichTextFields(
    "consultationRequirements",
    formData,
    dirtyFields,
    initialValues
  );
  setNullForEmptyBilingualRichTextFields(
    "informationUncertainties",
    formData,
    dirtyFields,
    initialValues
  );
  setNullForEmptyBilingualRichTextFields(
    "speciesDataSource",
    formData,
    dirtyFields,
    initialValues
  );
  setNullForEmptyBilingualRichTextFields(
    "methodology",
    formData,
    dirtyFields,
    initialValues
  );
  // For the "gisContactPerson" field, no need to set null.
  if (
    dirtyFields?.gisContactNotes?.text &&
    htmlIsNullOrEmpty(formData?.gisContactNotes?.text) &&
    !htmlIsNullOrEmpty(initialValues?.gisContactNotes?.text)
  ) {
    set(formData, "gisContactNotes", null);
  }
  // For the "biologistContactPerson" field, no need to set null.
  if (
    dirtyFields?.biologistContactNotes?.text &&
    htmlIsNullOrEmpty(formData?.biologistContactNotes?.text) &&
    !htmlIsNullOrEmpty(initialValues?.biologistContactNotes?.text)
  ) {
    set(formData, "biologistContactNotes", null);
  }
  setNullForEmptyBilingualRichTextFields(
    "comments",
    formData,
    dirtyFields,
    initialValues
  );
};

export const setNullForEmptyBilingualRichTextFields = (
  bilingualFieldName: keyof typeof formData,
  formData: UnpackNestedValue<Partial<ListingProcessTifFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ListingProcessTifFormFields>
) => {
  // English
  const enIsDirty = dirtyFields[bilingualFieldName]?.english?.text;
  const enIsEmptyInForm = htmlIsNullOrEmpty(
    (formData[bilingualFieldName] as BilingualRichText)?.english?.text
  );

  const enIsEmptyInInitialValues = htmlIsNullOrEmpty(
    (initialValues[bilingualFieldName] as BilingualRichText)?.english?.text
  );

  // French
  const frIsDirty = dirtyFields[bilingualFieldName]?.french?.text;
  const frIsEmptyInForm = htmlIsNullOrEmpty(
    (formData[bilingualFieldName] as BilingualRichText)?.french?.text
  );

  const frIsEmptyInInitialValues = htmlIsNullOrEmpty(
    (initialValues[bilingualFieldName] as BilingualRichText)?.french?.text
  );

  // Cases where full object should be null
  if (enIsDirty && frIsDirty && enIsEmptyInForm && frIsEmptyInForm) {
    set(formData, bilingualFieldName, null);
    return;
  } else if (
    enIsDirty &&
    enIsEmptyInForm &&
    !frIsDirty &&
    frIsEmptyInInitialValues
  ) {
    set(formData, bilingualFieldName, null);
    return;
  } else if (
    frIsDirty &&
    frIsEmptyInForm &&
    !enIsDirty &&
    enIsEmptyInInitialValues
  ) {
    set(formData, bilingualFieldName, null);
    return;
  }

  // Cases where only en should be set null
  if (enIsDirty && enIsEmptyInForm) {
    set(formData, bilingualFieldName + ".english", null);
    return;
  }

  // Cases where only fr should be set null
  if (frIsDirty && frIsEmptyInForm) {
    set(formData, bilingualFieldName + ".french", null);
    return;
  }

  return;
};
