import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { Item, Tabs } from "components/organisms/Tabs/Tabs";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import useQueryParams from "hooks/util/useQueryParams";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import InternalDocumentsPage from "./internal";
import PermitDocumentsPage from "./permitDescriptions";
import PublicDocumentsPage from "./public";
import ResponseStatementsDocumentsPage from "./responseStatements";

const SpeciesDocumentsPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;
  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  // Update Ribbon and SideNav.
  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId!);
  }, [overviewProgram, overviewId]);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("documents")}</h1>
          <Tabs className="tabs-style-1 full-width">
            <Item title={t("response_statements")}>
              <ResponseStatementsDocumentsPage />
            </Item>
            <Item title={t("permit_descriptions")}>
              <PermitDocumentsPage />
            </Item>
            <Item title={t("public")}>
              <PublicDocumentsPage />
            </Item>
            <Item title={t("internal")}>
              <InternalDocumentsPage />
            </Item>
          </Tabs>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default SpeciesDocumentsPage;
