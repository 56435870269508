import {
  AfterCdts,
  AppFooter,
  AppFooterProps,
  AppTop,
  AppTopProps,
  PreFooterProps,
  RefFooter,
  RefFooterProps,
  RefTop,
  RefTopProps,
} from "@arcnovus/wet-boew-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppTopMenuLinks } from "./useAppTopMenuLinks";
import { useAutoLngLinks } from "./useAutoLngLinks";

export type CustomAppTemplateProps = PropsWithChildren<
  Omit<RefTopProps, "isApplication"> &
    Omit<AppTopProps, "appName"> &
    PreFooterProps &
    AppFooterProps &
    RefFooterProps
>;

export function CustomAppTemplate({
  children,
  ...props
}: CustomAppTemplateProps) {
  return (
    <>
      <RefTop {...props} isApplication={true} />
      <CustomAppTop />
      <AfterCdts>{children}</AfterCdts>
      {/* Note: The pre-footer section should be placed just before the closing of the </main> element
      as explained here, https://cenw-wscoe.github.io/sgdc-cdts/docs/internet-en.html#Section_pre-footer_references.
      Otherwise the accessibility test (axe DevTools) will fail with the following Moderate issues, 
      "All page content should be contained by landmarks". 
      The pre-footer section is commented out at this time. /}
      {/* <PreFooter {...props} /> */}
      <AppFooter {...props} />
      <RefFooter {...props} isApplication={true} />
    </>
  );
}

/**
 * CustomAppTop
 *
 * This component exists only to reduce the number of re-renders for CustomAppTemplate;
 * useAutoLngLinks triggers a render every time the route changes in order to
 * calculate a new en/fr language switch link. Originally, the logic below was
 * inside CustomAppTemplate, but isolating it to CustomAppTop means that we
 * won't unnecessarily run WET-BOEW code for reftop, appfooter, etc on every route change.
 */
const CustomAppTop = (props: any) => {
  const { i18n } = useTranslation();
  const { lngLinks } = useAutoLngLinks();
  const isAuthenticated = useIsAuthenticated();
  const appTopMenuLinks = useAppTopMenuLinks();

  const { signIn, signOut } = useMemo(() => {
    if (isAuthenticated) {
      return { signOut: [{ href: "/signout" }] };
    } else {
      return { signIn: [{ href: "/signIn" }] };
    }
  }, [isAuthenticated]);

  const appName = useMemo(
    () => [
      {
        href: "/" as any,
        text:
          i18n.language === "fr"
            ? "Système de gestion et de suivi des données sur les espèces en péril"
            : "Species at Risk Data Management Tracking System",
      },
    ],
    [i18n.language]
  );

  return (
    <AppTop
      {...props}
      appName={appName}
      menuLinks={appTopMenuLinks}
      lngLinks={lngLinks}
      signIn={signIn}
      signOut={signOut}
    />
  );
};

export default CustomAppTemplate;
