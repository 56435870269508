import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { SpeciesProfileFormFields } from "./PublicProfileForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<SpeciesProfileFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<SpeciesProfileFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  let formSection: keyof typeof formData;
  for (formSection in formData) {
    // English
    const enIsDirty = dirtyFields[formSection]?.english?.text;
    const enIsEmptyInForm = htmlIsNullOrEmpty(
      formData[formSection]?.english?.text
    );
    const enIsEmptyInInitialValues = htmlIsNullOrEmpty(
      initialValues[formSection]?.english?.text
    );

    // French
    const frIsDirty = dirtyFields[formSection]?.french?.text;
    const frIsEmptyInForm = htmlIsNullOrEmpty(
      formData[formSection]?.french?.text
    );
    const frIsEmptyInInitialValues = htmlIsNullOrEmpty(
      initialValues[formSection]?.french?.text
    );

    // Cases where full object should be null
    if (enIsDirty && frIsDirty && enIsEmptyInForm && frIsEmptyInForm) {
      set(formData, formSection, null);
      continue;
    } else if (
      enIsDirty &&
      enIsEmptyInForm &&
      !frIsDirty &&
      frIsEmptyInInitialValues
    ) {
      set(formData, formSection, null);
      continue;
    } else if (
      frIsDirty &&
      frIsEmptyInForm &&
      !enIsDirty &&
      enIsEmptyInInitialValues
    ) {
      set(formData, formSection, null);
      continue;
    }

    // Cases where only en should be set null
    if (enIsDirty && enIsEmptyInForm) {
      set(formData, formSection + ".english", null);
      continue;
    }

    // Cases where only fr should be set null
    if (frIsDirty && frIsEmptyInForm) {
      set(formData, formSection + ".french", null);
      continue;
    }
  }
  return;
};
