import isNullOrEmpty from "util/isNullOrEmpty";

export const maybeParseRichText = (maybeJson?: string | null) => {
  if (isNullOrEmpty(maybeJson)) return "<p></p>";

  try {
    const parsed = JSON.parse(maybeJson!);
    if (isNullOrEmpty(parsed)) return `<p>${maybeJson}</p>`;

    if (!isNullOrEmpty(parsed.text) || !isNullOrEmpty(parsed.Text)) {
      return parsed.text ?? parsed.Text;
    }

    if (!isNullOrEmpty(parsed.plainText) || !isNullOrEmpty(parsed.PlainText)) {
      return parsed.plainText ?? parsed.PlainText;
    }
  } catch {
    // it's probably not JSON, return the string wrapped as HTML
    return `<p>${maybeJson}</p>`;
  }
};

export const maybeParseBilingualName = (
  maybeJson?: string | null,
  field?: string
) => {
  if (isNullOrEmpty(maybeJson)) return null;

  try {
    const parsed = JSON.parse(maybeJson!);

    const id = parsed.id ?? parsed.Id;
    if (isNullOrEmpty(id)) return null;
    if (field === "id") return id;

    const nameFr = parsed.nameFr ?? parsed.NameFr;
    if (field === "nameFr" || field === "fr") return nameFr;

    const nameEn = parsed.nameEn ?? parsed.NameEn;
    if (field === "nameEn" || field === "en") return nameEn;

    // return the parsed object if no field provided.
    return {
      id: parsed.id ?? parsed.Id,
      nameEn: parsed.nameEn ?? parsed.NameEn,
      nameFr: parsed.nameFr ?? parsed.NameFr,
    };
  } catch {
    return null;
  }
};

export const maybeParseIdNameType = (
  maybeJson?: string | null,
  field?: string
) => {
  if (isNullOrEmpty(maybeJson)) return null;

  try {
    const parsed = JSON.parse(maybeJson!);

    const id = parsed.id ?? parsed.Id;
    if (isNullOrEmpty(id)) return null;
    if (field === "id") return id;

    const name = parsed.name ?? parsed.Name;
    if (field === "name") return name;

    // return the parsed object if nothing provided.
    return {
      id: id,
      name: name,
    };
  } catch {
    return null;
  }
};
