import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButtons from "../FormButtons/FormButtons";
import { BilingualAbbreviationAcronymLookupTextInput } from "generated/gql-types";
import ProgramAutocomplete from "components/organisms/DistributionLists/ProgramAutocomplete";
import AuthorizationAdUserAutoComplete, {
  UserAutoCompleteFields,
} from "components/organisms/Users/AuthorizationAdUserAutoComplete";
import FieldValidationError from "components/atoms/forms/FieldValidationError";

const UserForm: React.FC<UserFormProps> = (props) => {
  const form = useForm<UserFields>({
    defaultValues: props.intialValues,
  });

  const { control, handleSubmit, formState } = form;
  const { isDirty, isSubmitting } = formState;
  const { t } = useTranslation();
  const { errors } = formState;

  const onSubmit: SubmitHandler<UserFields> = (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/*---------------User Auto Complete---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="user"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <AuthorizationAdUserAutoComplete
              label={t("user")}
              isRequired={true}
              onSelectionChange={onChange}
              disabled={false}
            />
          )}
        />
        {errors.user && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/*---------------Program Type Auto Complete---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="program"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <ProgramAutocomplete
              label={t("program")}
              isRequired={true}
              onSelectionChange={onChange}
              disabled={props?.intialValues?.program?.acronym !== "DMTSADMIN"}
              initialValue={value}
            />
          )}
        />
        {errors.program && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      <hr />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
      />
    </form>
  );
};

export default UserForm;

export interface UserFields {
  user?: UserAutoCompleteFields;
  program?: BilingualAbbreviationAcronymLookupTextInput;
}

interface UserFormProps {
  intialValues: UserFields;
  onSubmit: SubmitHandler<UserFields>;
  onClose: () => void;
}
// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
