import { Item } from "@react-stately/collections";
import Autocomplete from "components/molecules/Autocomplete";
import { AuthorizationSarDmtsRole } from "generated/gql-types";
import type { ReactElement } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { UseRoleAutocompleteProps } from "./useRoleAutocomplete";

interface RoleAutocompleteProps {
  onSelectionChange: (newRole?: AuthorizationSarDmtsRole) => void;
  initialValue?: AuthorizationSarDmtsRole;
  label?: string | ReactElement;
  placeholder?: string;
  useRoleAutocompleteInstance: UseRoleAutocompleteProps;
  disabled?: boolean;
  required?: boolean;
  onClear?: () => void;
}

const RoleAutocomplete: React.FC<RoleAutocompleteProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { list } = props.useRoleAutocompleteInstance;

  const defaultValue = props.initialValue?.name ?? "";

  const onSelectionChange = (key: string | number) => {
    const item = list.items.find((x) => x.appRoleId === key);
    if (item == null) {
      props.onSelectionChange(undefined);
      return;
    }

    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <Autocomplete
      label={props.label}
      items={list.items}
      inputValue={list.filterText}
      defaultInputValue={defaultValue}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      onSelectionChange={onSelectionChange}
      placeholder={(props.placeholder ?? props.label ?? "").toString()}
      disabled={props.disabled}
      isRequired={props.required}
      onClear={onClear}
    >
      {(item: AuthorizationSarDmtsRole) => {
        return (
          <Item key={item.appRoleId} textValue={item.name ?? ""}>
            <span className="font-size-16">{item.name}</span>
          </Item>
        );
      }}
    </Autocomplete>
  );
};

export default RoleAutocomplete;
