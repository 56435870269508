import { useQuery } from "@apollo/client";
import {
  DateOfAdditionToSchedule1SelectorDocument,
  ListingWs,
} from "generated/gql-types";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import parseSchedule1Date from "../../../../util/listing/parseSchedule1Date";
import LoadingIndicator from "../../../atoms/LoadingIndicator";

export interface DateOfAdditionToSchedule1SelectorProps {
  childId: string;
  disabled?: boolean;
}

export const DateOfAdditionToSchedule1Selector = React.forwardRef<
  HTMLSelectElement,
  DateOfAdditionToSchedule1SelectorProps
>((props, ref) => {
  const { loading, error, data } = useQuery(
    DateOfAdditionToSchedule1SelectorDocument,
    {
      variables: {
        params: {
          id: props.childId,
          pageNumber: 1,
          pageSize: 10,
        },
      },
    }
  );
  const { t, i18n } = useTranslation();
  const { register } = useFormContext();

  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  // Bug #38950
  // We should only be able to see the DateOfAddtionOnSchedule1 Selector if the listingWs has a standalone parent.
  // Since listing ws do not inherit cosewic parent/child relationships and you can't add a parent to a listing unless the parent is a standalone listing,
  // the only possible parents are standalone. We can check for the existence of any parents before showing the selector.
  if (
    data?.listingWsParents?.items == null ||
    data?.listingWsParents?.items?.length === 0
  )
    return null;

  const parsePopulation = (population: ListingWs["population"]) => {
    let populationFromListing =
      i18n.language === "fr" ? population?.nameFr : population?.nameEn;

    if (!populationFromListing) return "";
    return `, ${populationFromListing}`;
  };

  return (
    <fieldset className="chkbxrdio-grp">
      <legend /*className="required"*/>
        <span className="field-name">
          {t("date_of_addition_on_Schedule_1")}
        </span>{" "}
        {/*<strong className="required">({t("required")})</strong>*/}
      </legend>
      <div className="radio">
        <label htmlFor="rdo_child_decision_date">
          <input
            type="radio"
            value="Unset"
            disabled={props.disabled}
            // data-rule-required={true}
            id="rdo_child_decision_date"
            data-testid="rdo_child_decision_date"
            {...register("dateOfAdditionOnSchedule1ParentId")}
          />
          &#160;&#160;{t("gic_decision_date_of_child")}
        </label>
      </div>

      {data?.listingWsParents?.items?.map((x) => {
        if (!x) return null;
        const { listingWs, latestListingWsProcess } = x;

        const name =
          i18n.language === "fr"
            ? listingWs?.commonNameFrench?.name?.plainText
            : listingWs?.commonNameEnglish?.name?.plainText;

        const population = parsePopulation(listingWs?.population);
        const status =
          latestListingWsProcess?.schedule1Status ?? "MISSING LISTING STATUS";
        const date =
          parseSchedule1Date(listingWs?.schedule1Date) ??
          "MISSING LISTING DATE";

        const label = `${name}${population} - ${listingWs?.listingId} - ${t(
          status
        )} - ${date}`;
        const listingWsId = listingWs?.id ?? "MISSING LISTING ID";

        const elementId = `rdo_date_of_addition_${listingWsId}`;

        return (
          <div className="radio" key={x.listingWs?.id}>
            <label htmlFor={elementId}>
              <input
                type="radio"
                value={listingWsId}
                disabled={props.disabled}
                // data-rule-required={true}
                id={elementId}
                data-testid={elementId}
                {...register("dateOfAdditionOnSchedule1ParentId")}
              />
              &#160;&#160;{label}
            </label>
          </div>
        );
      })}
    </fieldset>
  );
});

export default DateOfAdditionToSchedule1Selector;
