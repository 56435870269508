import { TextInput } from "components/atoms/forms/TextInput";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DocumentLanguage } from "../../../../generated/gql-types";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";

interface AddLinkFormProps {
  defaultValues?: LinkFields;
  documentId: string;
  onClose: () => void;
  onSubmit: SubmitHandler<LinkFields>;
}

const LinkForm: React.FC<AddLinkFormProps> = (props) => {
  const form = useForm<LinkFields>({
    defaultValues: props.defaultValues,
  });

  const { register, handleSubmit, formState } = form;
  const { isDirty, errors, isSubmitting } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<LinkFields> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* -------------- Url to doccuemnt -------------- */}
        {/* 
          Update max length of link URL to 512
          https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/65436
        */}
        <TextInput
          type="text"
          label={t("url_to_document")}
          id="urlToDocumentInput"
          inputClassName="full-width"
          placeholder={t("url")}
          maxLength={512}
          required={true}
          {...register("urlToDocument", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
            pattern: {
              value: /^(https?:\/\/)[^\s\/$.?#].[^\s]*$/,
              message: t("invalid_url_message"),
            },
          })}
          errors={errors}
        />
        {/* -------------- link title -------------- */}
        <TextInput
          type="text"
          label={t("link_title")}
          id="linkTitleInput"
          inputClassName="full-width"
          placeholder={t("link_title")}
          maxLength={500}
          required={true}
          {...register("name", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
          })}
          errors={errors}
        />
        {/* {errors.name && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )} */}
        {/* </div> */}
        {/* -------------- language -------------- */}
        <div className="form-group">
          <fieldset className="chkbxrdio-grp">
            <legend className="required">
              <span className="field-name">{t("language")}</span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>
            <label htmlFor="languageEnglish" className="radio-inline">
              <input
                type="radio"
                value={DocumentLanguage.English}
                defaultChecked={
                  props?.defaultValues?.language === DocumentLanguage.English
                }
                id="languageEnglish"
                data-rule-required="true"
                {...register("language", {
                  required: true,
                })}
              />
              &#160;&#160;{t("english")}
            </label>
            <label htmlFor="languageFrench" className="radio-inline">
              <input
                type="radio"
                value={DocumentLanguage.French}
                defaultChecked={
                  props?.defaultValues?.language === DocumentLanguage.French
                }
                id="languageFrench"
                {...register("language", {
                  required: true,
                })}
              />
              &#160;&#160;{t("french")}
            </label>
          </fieldset>
          {errors.language && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>

        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default LinkForm;

export interface LinkFields {
  urlToDocument: string;
  name: string;
  language?: Maybe<DocumentLanguage>;
}
