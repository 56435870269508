import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import {
  BilingualAbbreviationLookupTextInput,
  CompetentMinisterLookupDocument,
} from "generated/gql-types";
import { xor } from "lodash";
import { bilingualLookupTextNameForLanguage } from "mappers";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";

export interface CompetentMinisterCheckboxListProps {
  onChange: (checked: Array<any>) => void; // TODO:TYPES: any
  defaultChecked: Array<any>; // TODO:TYPES: any
}

export const CompetentMinisterCheckboxList: React.FC<
  CompetentMinisterCheckboxListProps
> = (props) => {
  const { onChange, defaultChecked = [] } = props;
  const { loading, error, data } = useQuery(CompetentMinisterLookupDocument);
  const { t, i18n } = useTranslation();

  const [selectedIds, setSelectedIds] = React.useState<string[]>(
    defaultChecked?.map((x) => x?.id ?? "")
  );

  const onToggleId = (id: string) => {
    if (data == null || data?.listingWsCompetentMinister == null) return;
    const newIds = xor(selectedIds, [id]);

    const resolved = data?.listingWsCompetentMinister.filter((x) => {
      if (x == null || x.id == null) return false;
      return newIds.includes(x.id);
    });

    onChange(resolved);
    setSelectedIds(newIds);
  };

  if (loading) return <LoadingIndicator />;

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const competentMinisterList = data?.listingWsCompetentMinister;
  if (!competentMinisterList) return <p>{t("empty")}</p>;

  return (
    <>
      {competentMinisterList?.map((x) => {
        if (x == null || x.id == null) return <label>{t("empty")}</label>;
        const id = `competentminister_checkbox_${x?.id}`;

        const onSelected = () => {
          if (x == null || x.id == null) return;
          onToggleId(x.id);
        };

        return (
          <div className="checkbox" key={x.id}>
            <label htmlFor={id}>
              <input
                type="checkbox"
                id={id}
                key={id}
                onChange={onSelected}
                checked={selectedIds.includes(x.id)}
              />
              <span className="mrgn-lft-sm">
                {bilingualLookupTextNameForLanguage(i18n.language, x)}
              </span>
            </label>
          </div>
        );
      })}
    </>
  );
};

export default CompetentMinisterCheckboxList;

export interface CompetentMinisterCheckboxListWithControllerProps<TFieldValues>
  extends Omit<CompetentMinisterCheckboxListProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const CompetentMinisterCheckboxListWithController = <TFieldValues,>(
  props: CompetentMinisterCheckboxListWithControllerProps<TFieldValues>
) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <CompetentMinisterCheckboxList
          defaultChecked={value as Array<BilingualAbbreviationLookupTextInput>}
          onChange={onChange}
        />
      )}
    />
  );
};
