import { useQuery } from "@apollo/client";
import PlaceholderImage from "assets/svg/placeholder.svg";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { NoResults } from "components/atoms/NoResults";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import RelatedSpeciesListItem from "components/molecules/cosewic/RelatedSpeciesListItem";
import { PageSizeSelect } from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { useOverviewContext } from "features/overview";
import {
  Assessment,
  AssessmentStage,
  AssessmentState,
  CosewicWs,
  ViewParentDocument,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import i18n from "i18n";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { getSortByOrder } from "util/relatedSpecies/listSortBy";

export interface ViewParentsProps {}

const ViewParents: React.FC<ViewParentsProps> = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const pagination = usePagination();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortByInput, setSortByInput] = React.useState("commonName");
  const sortBy = getSortByOrder(sortByInput, sortOrder, i18n.language);

  const { data, loading, error } = useQuery(ViewParentDocument, {
    variables: {
      cosewicWsParentsParams: {
        cosewicId: cosewicWsId,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        assessmentStage: AssessmentStage.Assessed,
        assessmentState: AssessmentState.Published,
        sortBy,
      },
    },
  });

  const onChangeSortBy = async (e: any) => {
    setSortByInput(e.currentTarget.value);
    pagination.goToPage(1);
  };

  const onChangeSortOrder = (e: any) => {
    setSortOrder(e.currentTarget.value);
  };

  const viewParentsListItems = data?.cosewicWsParents?.items;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          {/*TODO: i18n*/}
          <h1>{t("view_parents")}</h1>
          <GraphqlError errors={error} />
          <div id="dataset_filter" className="Card mrgn-tp-md">
            {/*instant filters:*/}
            <div className="filter-controls">
              <section className="flex justify-between mrgn-bttm-md">
                <div className="flex">
                  <div className="flex-col">
                    <label htmlFor="sel_sort_by">{t("sort_by")}</label>
                    <select
                      id="sel_sort_by"
                      className="form-control mrgn-rght-md"
                      value={sortByInput}
                      onChange={onChangeSortBy}
                    >
                      {/*TODO:enum members*/}
                      <option value="commonName">{t("common_name")}</option>
                      <option value="scientificName">
                        {t("scientific_name")}
                      </option>
                      <option value="population">{t("population")}</option>
                      <option value="cosewicId">{t("cosewic_id")}</option>
                    </select>
                  </div>

                  <div className="flex-col">
                    <label htmlFor="sel_sort_order">{t("sort_order")}</label>
                    <select
                      id="sel_sort_order"
                      className="form-control"
                      value={sortOrder}
                      onChange={onChangeSortOrder}
                    >
                      <option value="asc">{t("ascending")}</option>
                      <option value="desc">{t("descending")}</option>
                    </select>
                  </div>
                </div>

                <div className="flex">
                  {/* <p>Export to Excel</p> */}
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
              </section>
            </div>

            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !viewParentsListItems || viewParentsListItems?.length === 0 ? (
              <NoResults centered />
            ) : (
              viewParentsListItems?.map((item) => {
                const cosewicWs = item?.cosewicWs as CosewicWs; // Only for type conversion
                const latestAssessment = item?.latestAssessment as Assessment;
                return (
                  <div
                    className="list-item separator-line"
                    key={item?.cosewicWs?.id}
                  >
                    <RelatedSpeciesListItem
                      cosewicWs={cosewicWs}
                      cosewicWsAssessmentLatest={latestAssessment}
                      titleLink={CosewicPathHelpers.CosewicProfile(
                        item?.cosewicWs?.id ?? "error"
                      )}
                      imgUrl={PlaceholderImage}
                      showImage={false}
                    />
                  </div>
                );
              })
            )}

            <Pagination
              {...pagination.paginationComponentProps}
              totalPages={data?.cosewicWsParents?.pagination?.totalPages ?? 0}
            />
          </div>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ViewParents;
