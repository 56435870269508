import * as React from "react";

export const useCheckbox = (defaultChecked = false) => {
  const [checked, setChecked] = React.useState<boolean>(defaultChecked);

  const toggle = () => {
    setChecked((x) => !x);
  };

  const reset = () => {
    setChecked(defaultChecked);
  };

  return {
    checked,
    setChecked,
    toggle,
    reset,
  };
};

export default useCheckbox;
