import * as React from "react";
import { useTranslation } from "react-i18next";
import EditProgramPopup, { EditProgramPopupProps, useProgramPopup } from ".";

interface EditProgramModalWithButtonProps
  extends Omit<EditProgramPopupProps, "modalState"> {
  buttonLabel?: string;
  disabled?: boolean;
}

const EditProgramButtonWithModal: React.FC<EditProgramModalWithButtonProps> = ({
  buttonLabel,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useProgramPopup({});
  const label = buttonLabel ?? t("edit_program");
  const validUserId = props.userId != null && props.userId.length > 0;
  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validUserId || disabled}
        data-testid="button-edit-program"
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {validUserId && !disabled ? (
        <EditProgramPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditProgramButtonWithModal;
