import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { AssessmentDateDropdownDocument } from "generated/gql-types";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../../../util/formatTimestamp";

export interface AssessmentDateDropdownProps {
  listingId: string;
  placeholder?: string;
  defaultValue?: any; // TODO:TYPES:any
  id: string;
  onChange: (value: string) => void;
  required?: boolean;
}

export const AssessmentDateDropdown = React.forwardRef<
  HTMLSelectElement,
  AssessmentDateDropdownProps
>(({ listingId, ...props }, ref) => {
  const { loading, error, data } = useQuery(AssessmentDateDropdownDocument, {
    variables: {
      id: listingId,
    },
  });
  const { t } = useTranslation();

  if (loading || !listingId) return <LoadingIndicator />;
  //
  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChange = (e: any) => {
    const id = e.currentTarget.value;
    props.onChange(id);
  };

  return (
    <select
      className="form-control"
      ref={ref}
      {...props}
      onChange={onChange}
      defaultValue={props.defaultValue}
    >
      <option disabled value={""} hidden>
        {t("select_an_assessment")}
      </option>
      {data?.listingWsProcessWithDeferredOrReferBackGicDecision.map((x) => {
        return (
          <option
            key={x?.assessmentRef?.assessmentId}
            value={x?.assessmentRef?.assessmentId ?? "error - missing id"}
          >
            {formatTimestamp(x?.assessmentRef?.assessmentDate)}
          </option>
        );
      })}

      {data?.listingWsProcessWithDeferredOrReferBackGicDecision &&
      data?.listingWsProcessWithDeferredOrReferBackGicDecision?.length >
        0 ? null : (
        <option disabled>{t("no_results")}</option>
      )}
    </select>
  );
});

export interface AssessmentDateDropdownWithControllerProps<TFieldValues>
  extends Omit<AssessmentDateDropdownProps, "onChange">,
    Omit<ControllerProps<TFieldValues>, "defaultValue" | "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const AssessmentDateDropdownWithController = <TFieldValues,>({
  id,
  listingId,
  required,
  ...props
}: AssessmentDateDropdownWithControllerProps<TFieldValues>) => {
  const { t } = useTranslation();

  return (
    <Controller
      {...props}
      rules={{
        required: {
          value: required ?? false,
          message: t("field_is_required"),
        },
      }}
      render={({ field: { value, onChange } }) => (
        <AssessmentDateDropdown
          id={id}
          onChange={onChange}
          defaultValue={(value as any)?.id ?? ""}
          listingId={listingId}
        />
      )}
    />
  );
};

export default AssessmentDateDropdown;
