import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "../../util/router";
import CosewicAssessmentCreate from "./assessment/create";
import CosewicAssessmentList from "./assessment/list";
import CosewicAssessmentEdit from "./assessment/[id]/edit";
import CosewicAssessmentProfile from "./assessment/[id]/index";
import CosewicCreate from "./create";
import CosewicHome from "./index";
import CosewicChangeLogDetails from "./[id]/changeLog/details";
import CosewicChangeLog from "./[id]/changeLog/index";
import CosewicEdit from "./[id]/edit";
import CosewicProfile from "./[id]/index";
import Merge from "./[id]/merge";
import RelatedSpecies from "./[id]/relatedSpecies";
import Split from "./[id]/split";
import ViewChildren from "./[id]/viewChildren";
import ViewParents from "./[id]/viewParents";
import ViewSiblings from "./[id]/viewSiblings";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { Roles } from "../../features/auth/roles";

export interface CosewicRouterProps {}

export const CosewicPathHelpers = {
  Home: "/cosewic",
  CosewicCreate: "/cosewic/create",
  CosewicProfile: (cosewicWsId: string) => `/cosewic/${cosewicWsId}`,
  CosewicEdit: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/edit`,
  CosewicSearch: "/search/cosewic",
  CosewicManageDistributionList: "/manage/list",
  CosewicManageNotification: "/manage/notification",
  ChangeLog: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/changeLog`,
  ChangeLogDetails: (cosewicWsId: string, changeLogId: string) =>
    `/cosewic/${cosewicWsId}/changeLog/details?changeLogId=${changeLogId}`,
  RelatedSpecies: (cosewicWsId: string) =>
    `/cosewic/${cosewicWsId}/relatedSpecies`,
  Split: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/split`,
  Merge: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/merge`,
  ViewParents: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/viewParents`,
  ViewChildren: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/viewChildren`,
  ViewSiblings: (cosewicWsId: string) => `/cosewic/${cosewicWsId}/viewSiblings`,

  AssessmentCreate: (cosewicWsId: string) =>
    `/cosewic/${cosewicWsId}/assessment/create`,
  AssessmentSearch: "/cosewic/assessment/search",
  AssessmentList: "/cosewic/assessment/list",
  AssessmentProfile: (cosewicWsId: string, assessmentId: string) =>
    `/cosewic/${cosewicWsId}/assessment/${assessmentId}`,
  AssessmentEdit: (cosewicWsId: string, assessmentId: string) =>
    `/cosewic/${cosewicWsId}/assessment/${assessmentId}/edit`,
};

export const CosewicRouter: React.FC<CosewicRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <CosewicHome />
      </Route>

      <Route path={relative("/create")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <CosewicCreate />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/assessment/list")}>
        <CosewicAssessmentList />
      </Route>

      <Route exact path={relative("/:cosewicWsId/changeLog")}>
        <CosewicChangeLog />
      </Route>

      <Route path={relative("/:cosewicWsId/changeLog/details")}>
        <CosewicChangeLogDetails />
      </Route>

      <Route path={relative("/:cosewicWsId/relatedSpecies")}>
        <RelatedSpecies />
      </Route>

      <Route path={relative("/:cosewicWsId/split")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <Split />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:cosewicWsId/merge")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <Merge />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:cosewicWsId/viewParents")}>
        <ViewParents />
      </Route>

      <Route path={relative("/:cosewicWsId/viewChildren")}>
        <ViewChildren />
      </Route>

      <Route path={relative("/:cosewicWsId/viewSiblings")}>
        <ViewSiblings />
      </Route>

      <Route path={relative("/:cosewicWsId/assessment/:assessmentId/edit")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <CosewicAssessmentEdit />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:cosewicWsId/assessment/create")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <CosewicAssessmentCreate />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:cosewicWsId/assessment/:assessmentId")}>
        <CosewicAssessmentProfile />
      </Route>

      <Route path={relative("/:cosewicWsId/edit")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <CosewicEdit />
        </RenderWhenAuthorized>
      </Route>

      <Route path={relative("/:cosewicWsId")}>
        <CosewicProfile />
      </Route>
    </Switch>
  );
};

export default CosewicRouter;
