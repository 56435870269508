import AddImage from "assets/svg/add.svg";
import AddLink from "components/atoms/AddLink";
import MissingData from "components/atoms/MissingData";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { RenderWhenAuthorized } from "features/auth/components";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import { ROLE_ACTIONS } from "../../../features/auth/roles";
import {  
  Assessment,
  AssessmentStage,
  AssessmentState,
  Maybe,
  WsStatus,
} from "../../../generated/gql-types";
import SectionCard from "../../atoms/SectionCard";

interface AssessmentListItem
  extends Pick<
    Assessment,
    "id" | "date" | "statusAndCriteria" | "stage" | "state"
  > {}

export interface WSAssessmentCardProps {
  assessmentList?: Maybe<Array<Maybe<AssessmentListItem>>>;
  cosewicWsId: string;
  loading?: boolean;
}

export const WSAssessmentCard: React.FC<WSAssessmentCardProps> = (props) => {
  const { assessmentList, cosewicWsId, loading } = props;
  const { t } = useTranslation();

  const [showAddImage, setShowAddImage] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!assessmentList) return;
    if (assessmentList.length === 0) setShowAddImage(true);
    else setShowAddImage(false);
  }, [assessmentList]);

  // BUG 31831: This constant is used to check whether or not any of the assessments are Not Published.
  // If any associated assessment is Not Published, the button should not be visible.
  //
  // Aug 2 2023: UPDATE TO BUG 31831: this check should exclude non-published assessments with the status 'Deferred/Rejected/Withdrawn'.
  // This decision was made in an email thread, which has been added in azure devops as an attachment on bug 31831.
  //
  const notPublishedAssessmentFound =
    assessmentList?.find((assessment) => {
      if (
        assessment?.statusAndCriteria?.status ===
        WsStatus.DeferredRejectWithdrawn
      ) {
        return false;
      } else {
        return assessment?.state === AssessmentState.NotPublished;
      }
    }) !== undefined;

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("assessments")}</h2>
          <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.cosewic.update}>
            {notPublishedAssessmentFound || loading ? null : (
              <AddLink
                linkTo={CosewicPathHelpers.AssessmentCreate(cosewicWsId)}
              />
            )}
          </RenderWhenAuthorized>
        </div>
      }
      showLine={false}
      contentAreaProps={{ className: "mrgn-tp-0" }}
    >
      {showAddImage ? (
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.update}
          fallbackComponent={<MissingData />}
        >
          {!loading ? (
            <div className="text-center mrgn-tp-sm">
              <Link
                to={CosewicPathHelpers.AssessmentCreate(cosewicWsId)}
                className="hover-underline"
              >
                <img src={AddImage} alt="" />
                <div className="no-results-content">
                  {t("add_your_first_assessment")}
                </div>
              </Link>
            </div>
          ) : null}
        </RenderWhenAuthorized>
      ) : null}

      {assessmentList?.map((assessment) => {
        if (!assessment) return null;

        const showTooltipAlertForWsStatus =
          assessmentList.length === 1 &&
          (assessment?.statusAndCriteria?.status == null ||
            assessment?.statusAndCriteria?.status === WsStatus.NotInitialized);

        const showTooltipAlertForWsStage =
          assessmentList.length === 1 &&
          (assessment?.stage == null ||
            assessment?.stage === AssessmentStage.Draft);

        return (
          <div
            className="row list-item separator-line species-field px-0"
            key={assessment.id}
          >
            <dl>
              <div className="col-sm-3 species-data">
                <dt className="text-muted">{t("date_of_assessment")}</dt>
                <dd>
                  <Link
                    to={CosewicPathHelpers.AssessmentProfile(
                      cosewicWsId,
                      assessment?.id ?? "error"
                    )}
                  >
                    {formatTimestamp(assessment.date) ?? <MissingData />}
                  </Link>
                </dd>
              </div>
              <div className="col-sm-3 species-data">
                <dt className="text-muted">
                  {t("cosewic_ws_status")}
                  <TooltipAlert isVisible={showTooltipAlertForWsStatus}>
                    {t("required_to_make_ws_permanent_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {assessment.statusAndCriteria?.status &&
                  assessment.statusAndCriteria.status !==
                    WsStatus.NotInitialized ? (
                    t(assessment.statusAndCriteria?.status)
                  ) : (
                    <MissingData />
                  )}
                </dd>
              </div>
              <div className="col-sm-3 species-data">
                <dt className="text-muted">
                  {t("stage")}
                  <TooltipAlert isVisible={showTooltipAlertForWsStage}>
                    {t("must_be_assessed_to_make_ws_permanent_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {assessment.stage ? t(assessment.stage) : <MissingData />}
                </dd>
              </div>
              <div className="col-sm-3 species-data">
                <dt className="text-muted">{t("state")}</dt>
                <dd>
                  {assessment.state ? t(assessment.state) : <MissingData />}
                </dd>
              </div>
            </dl>
          </div>
        );
      })}
    </SectionCard>
  );
};

export default WSAssessmentCard;
