import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/TwoColumn";

import { useGlobalAlertContext } from "features/globalAlert";
import { DocumentDocument, UpdateDocumentDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import DocumentForm, {
  DocumentFormFields,
} from "components/organisms/documents/DocumentForm/DocumentForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/documents/DocumentForm/mappers";
import { DocumentsPathHelpers } from "../DocumentsRouter";

export interface DocumentsEditPageProps {}

export const DocumentsEditPage: React.FC<DocumentsEditPageProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { documentId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const { loading, error, data } = useQuery(DocumentDocument, {
    errorPolicy: "all",
    variables: { documentId: documentId },
    fetchPolicy: "network-only",
  });

  const [updateDocument, { loading: saving, error: savingError }] = useMutation(
    UpdateDocumentDocument
  );

  const onSubmit: SubmitHandler<Partial<DocumentFormFields>> = async (
    FilteredFormData
  ) => {
    const mappedDomainModel = mapFormToDomainModel(FilteredFormData);
    try {
      const res = await updateDocument({
        variables: {
          updateDocumentId: documentId,
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_updated_document"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.DocumentsProfile(
          res.data?.updateDocument?.id ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: DocumentsPathHelpers.DocumentsProfile(documentId ?? "error"),
    });
  };

  const document = data?.document;
  const initialFormFields = mapDomainModelToForm(document);
  const documentState = data?.document?.state;

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("edit_document")}</h1>
          <GraphqlError
            title="Error loading document information"
            errors={error}
          />
          <GraphqlError
            title="Error updating document information"
            errors={savingError}
          />
          {loading ? (
            <LoadingIndicator />
          ) : (
            <DocumentForm
              initialValues={initialFormFields}
              documentState={documentState}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default DocumentsEditPage;
