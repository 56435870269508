const adminTaxonomicGroupTranslation = {
  en: {
    taxonomicgroup: "Taxonomic group",
    list_of_taxonomic_groups: "List of taxonomic groups",
    create_a_taxonomicgroup: "Create a Taxonomic group",
    enter_taxonomicgroup_name: "Enter a Taxonomic group name",
    edit_taxonomicgroup: "Edit a Taxonomic group",
    reactivate_a_taxonomicgroup: "Reactivate a Taxonomic group",
    deactivate_a_taxonomicgroup: "Deactivate a Taxonomic group",
    archive_taxonomicgroup_warning:
      "You will no longer be able to add this Taxonomic group to a Wildlife Species.",
    unarchive_taxonomicgroup_warning:
      "You will be able to add this Taxonomic group to a Wildlife Species.",
    no_taxonomicgroup_selected: "No Taxonomic group was selected",
    archive_a_taxonomicgroup: "Archive a taxonomic group",
    unarchive_a_taxonomicgroup: "Unarchive a taxonomic group",
  },
  fr: {
    taxonomicgroup: "Groupe taxonomique",
    list_of_taxonomic_groups: "Liste des groupes taxinomiques",
    create_a_taxonomicgroup: "Créer un groupe taxonomique",
    enter_taxonomicgroup_name: "Entrez un nom de groupe taxonomique",
    edit_taxonomicgroup: "Editer un groupe taxonomique",
    reactivate_a_taxonomicgroup: "Réactiver un groupe taxonomique",
    deactivate_a_taxonomicgroup: "Désactiver un groupe taxonomique",
    archive_taxonomicgroup_warning:
      "Vous ne pourrez plus ajouter ce groupe taxonomique à une espèce sauvage.",
    unarchive_taxonomicgroup_warning:
      "Vous pourrez ajouter ce groupe taxonomique à une espèce sauvage..",
    no_taxonomicgroup_selected: "Aucun groupe taxonomique n'a été sélectionnée",
    archive_a_taxonomicgroup: "Archiver un groupe taxonomique",
    unarchive_a_taxonomicgroup: "Désarchiver un groupe taxonomique",
  },
};

export default adminTaxonomicGroupTranslation;
