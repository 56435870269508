import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BilingualAbbreviationAcronymTagsLookupText,
  Maybe,
  RichText,
  RichTextInput,
} from "generated/gql-types";

import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import ProgramAutocomplete from "components/organisms/DistributionLists/ProgramAutocomplete";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import ObjectTypeAutocomplete from "components/organisms/admin/ObjectTypeAutocomplete";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import VisibilityDropdown from "./VisibilityDropdown/VisibilityDropdown";

const BptTemplateForm: React.FC<BptTemplateFormProps> = (props) => {
  const form = useForm<BptTemplateFormFields>({
    defaultValues: props.intialValues,
  });

  const { control, handleSubmit, formState, register } = form;
  const { isDirty, isSubmitting } = formState;
  const { t } = useTranslation();
  const { errors } = formState;

  const onSubmit: SubmitHandler<BptTemplateFormFields> = (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/*---------------Program Type Auto Complete---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="program"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <ProgramAutocomplete
              label={t("program")}
              isRequired={true}
              onSelectionChange={onChange}
              disabled={false}
              initialValue={value}
            />
          )}
        />
        {errors.program && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/*---------------Visibility Type Auto Complete---------------------*/}
      <div className="form-group">
        <VisibilityDropdown
          {...register("visibility", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
          })}
          required={true}
          disabled={false}
          inputClassName="full-width"
          label={t("visibility")}
          errors={errors}
        />
      </div>
      {/* =============== Name =============== */}
      <div className="form-group">
        <label htmlFor="nameWithCommaInput" className="required">
          {t("name")} <strong className="required">({t("required")})</strong>
        </label>
        <HTMLItalicsInputWithController
          control={control}
          rules={{
            required: true,
          }}
          defaultValue={props?.intialValues?.name?.text ?? ""}
          id="name"
          name="name.text"
          placeholder={`${t("name")}`}
        />
        {errors.name && (errors.name as any)?.text?.type === "required" && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* =============== Description =============== */}
      <div className="form-group">
        <label htmlFor="description">
          <span className="field-name">{t("description")}</span>{" "}
        </label>
        <FullHTMLEditorWithController
          control={control}
          defaultValue={props?.intialValues?.description?.text ?? ""}
          id="description"
          name="description.text"
        />
        {errors.description && (
          <FieldValidationError>{errors.description}</FieldValidationError>
        )}
      </div>
      {/*---------------objectType Type Auto Complete---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="objectType"
          rules={{
            required: false,
          }}
          render={({ field: { onChange, value } }) => (
            <ObjectTypeAutocomplete
              label={t("objectType")}
              isRequired={false}
              onSelectionChange={onChange}
              disabled={false}
              initialValue={props?.intialValues?.objectType}
            />
          )}
        />
      </div>
      <hr />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
      />
    </form>
  );
};

export default BptTemplateForm;

export interface BptTemplateFormFields {
  program?: Maybe<BilingualAbbreviationAcronymTagsLookupText>;
  visibility?: Visibility;
  name: Maybe<RichText> | undefined;
  description: Maybe<RichTextInput> | undefined;
  objectType?: Maybe<BilingualAbbreviationAcronymTagsLookupText>;
}

interface BptTemplateFormProps {
  intialValues: BptTemplateFormFields;
  onSubmit: SubmitHandler<BptTemplateFormFields>;
  onClose: () => void;
}
export enum Visibility {
  PRIVATE = "Private",
  ALL_PROGRAMS = "All Programs",
}
// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
