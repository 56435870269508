import * as React from "react";
import { useTranslation } from "react-i18next";
import EditSpeciesModal, { EditSpeciesModalProps, useSpeciesModal } from "./";

interface EditSpeciesModalWithButtonProps
  extends Omit<EditSpeciesModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditSpeciesModalWithButton: React.FC<EditSpeciesModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useSpeciesModal({});

  const label = buttonLabel ?? t("edit");

  const validSpeciesId = props.speciesId != null && props.speciesId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validSpeciesId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validSpeciesId ? (
        <EditSpeciesModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditSpeciesModalWithButton;
