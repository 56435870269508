import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GraphqlError from "../../../../../GraphqlError";
import ModalDialog from "../../../../Modal";
import { CreateBptTemplateStepDocument } from "../../../../../../generated/gql-types";
import LoadingIndicator from "../../../../../atoms/LoadingIndicator";
import StepForm, { StepFormFields } from "../StepForm";
import {
  addStepFormIntoCreateInput,
  domainModelIntoStepForm,
} from "../../bptFormUtil";
import { useGlobalAlertContext } from "features/globalAlert";

export interface AddStepPopupProps {
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  templateId: string;
  phaseId: string;
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
}

const AddStepPopup: React.FC<AddStepPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();
  const { templateId, phaseId } = props;

  const [createBptTemplateStep, { loading, error, reset }] = useMutation(
    CreateBptTemplateStepDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
    }
  );

  const onSubmit: SubmitHandler<Partial<StepFormFields>> = async (formData) => {
    const input = addStepFormIntoCreateInput(formData);
    try {
      const res = await createBptTemplateStep({
        variables: {
          templateId: templateId ?? "error",
          phaseId: phaseId ?? "error",
          input: input,
        },
      });
      if (res.errors == null) {
        modalState.close();
      }

      alertContext.showSuccess({
        message: t("successfully_added_step"),
        timeOut: 5000,
      });
    } catch (e) {
      console.warn("Error saving new step");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues = domainModelIntoStepForm(undefined);

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_step")}
        className={"modal-width-50"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
      >
        <GraphqlError
          title={t("error_create_bpt_template_step")}
          errors={error}
        />
        {loading && <LoadingIndicator centered />}
        <StepForm
          defaultValues={formDefaultValues}
          onSubmit={onSubmit}
          onClose={onClose}
          isSaving={loading}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddStepPopup;

export const useStepPopup = useOverlayTriggerState;
