import * as React from "react";
import { useTranslation } from "react-i18next";
import type { EditPhotoPopupProps } from "./EditPhotoPopup";
import EditPhotoPopup, { useManagePhotoPopup } from "./EditPhotoPopup";

interface EditPhotoBtnProps extends Omit<EditPhotoPopupProps, "modalState"> {}

const EditPhotoBtn: React.FC<EditPhotoBtnProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const modalState = useManagePhotoPopup({});
  const validPhotoLicenseId =
    props.photoLicenseId != null && props.photoLicenseId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link font-size-14 hover-grey"
        onClick={modalState.open}
        disabled={!validPhotoLicenseId}
        data-testid="button-edit-photo"
        title={t("edit_photo")}
      >
        <i className="fas fa-pen"></i>
      </button>
      {validPhotoLicenseId ? (
        <EditPhotoPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditPhotoBtn;
