import { AzureSearchDateRange } from "components/organisms/search/FilterOptionsSection/filters/filters";
import { ODataMapper } from "../types";

const photoLicensePageOdataMappers: Record<string, ODataMapper> = {
  Stage: {
    key: "Stage",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ Stage: x })),
      };
    },
  },
  LicenseType: {
    key: "LicenseType",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ LicenseType: x })),
      };
    },
  },
  PhotoCount: {
    key: "PhotoCount",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ PhotoCount: x })),
      };
    },
  },
  CreatedAt: {
    key: "CreatedAt",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        CreatedAt: dateRange,
      };
    },
  },
};

export default Object.freeze(photoLicensePageOdataMappers);
