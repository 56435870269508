import { useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import {
  RegistryPagePendingUpdatesDocument,
  RegistryPagePendingUpdatesQuery,
} from "generated/gql-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface RegistryPageHasUnpublishedChangesWarningProps {
  pendingUpdates: RegistryPagePendingUpdatesQuery | undefined;
  isPublished: boolean;
}

const RegistryPageHasUnpublishedChangesWarning: React.FC<
  RegistryPageHasUnpublishedChangesWarningProps
> = (props) => {
  const { t, i18n } = useTranslation();

  const registryPagePendingUpdates =
    props.pendingUpdates?.registryPagePendingUpdates;
  const isPublished = props.isPublished;

  return isPublished &&
    (registryPagePendingUpdates?.isMetaDataUpdates === true ||
      (registryPagePendingUpdates?.documentPendingUpdates != null &&
        registryPagePendingUpdates.documentPendingUpdates.length > 0)) ? (
    <Alert
      type={AlertTypes.WARNING}
      title={t("registry_page_information_has_unpublished_changes")}
      content={t(
        "registry_page_information_needs_publish_changes_to_view_on_registry"
      )}
    >
      <SectionCard
        collapsible
        open={false}
        header={<h3>{t("view_details")}</h3>}
        showLine={false}
        contentAreaProps={{ className: "font-size-16" }}
        className={"mrgn-bttm-0"}
        classNameSummary={"py-2"}
      >
        {registryPagePendingUpdates.isMetaDataUpdates === true && (
          <div className="mrgn-tp-sm">
            {t("registry_page_information_has_been_changed")}
          </div>
        )}

        {registryPagePendingUpdates.documentPendingUpdates?.map(
          (item, index) => {
            return (
              <div key={index} className="mrgn-tp-sm">
                {t("document_name_has_been_changed", {
                  name:
                    i18n.language === "fr"
                      ? item?.title?.french?.plainText
                      : item?.title?.english?.plainText,
                })}
              </div>
            );
          }
        )}
      </SectionCard>
    </Alert>
  ) : null;
};

export default RegistryPageHasUnpublishedChangesWarning;
