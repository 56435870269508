export default function makeQueryStringWithoutEncoding(
  urlSearchParams: URLSearchParams
) {
  // NOTE: here we use .entries() and map the result, because the standard filters.toString() adds URL encoding,
  //  and this can cause problems since we're mostly using GQL and just passing normal strings.
  //  E.g. problem: a GQL query for a search operation that contains a
  //  "filters" string (server expects '&' separated 'K=V' pairs), finds nothing when we want
  //  to search for "mykey=my value" but actually searched for the encoded version "mykey=my+value".
  const paramsAsTupleArray = Array.from(urlSearchParams.entries()); // => [["k", "v"], ["k", "v"]
  const paramPairsArray = paramsAsTupleArray.map((x) => x.join("=")); // => ["k=v", "k=v"]
  return paramPairsArray.join("&"); // => "k=v&k=v"
}
