import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { RichText, Scalars } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processStepFormValues } from "../bptFormUtil";

export interface StepFormFields {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<RichText>;
  description?: Maybe<RichText>;
  owner?: Maybe<RichText>;
  estimatedDuration?: Maybe<number>;
  calculatedDuration?: Maybe<number>;
  includeCommonHoliday?: boolean;
}

export interface StepFormProps {
  defaultValues?: Partial<StepFormFields>;
  onSubmit: SubmitHandler<Partial<StepFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

export const StepForm: React.FC<StepFormProps> = (props) => {
  const defaultValues = props.defaultValues;
  const { t } = useTranslation();

  const form = useForm<StepFormFields>({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, formState, register, control } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<StepFormFields>> = async (formData) => {
    const cleanedValues = processStepFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={props.isSaving}>
          {/* ---------- Step name ---------- */}
          <div className="row">
            <div className="form-group col-md-7">
              <label htmlFor="nameInput" className="required">
                {t("step_name")}
                <strong className="required"> ({"required"})</strong>
              </label>
              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  required: true,
                }}
                name="name.text"
                id="nameInput"
                defaultValue={(defaultValues?.name as any)?.text ?? ""}
                placeholder={`${t("step_name")}`}
              />
              {errors.name &&
                (errors.name as any)?.text?.type === "required" && (
                  <FieldValidationError>
                    {t("field_is_required")}
                  </FieldValidationError>
                )}
            </div>
          </div>

          {/* ---------- Step Description ---------- */}
          <div className="form-group full-width">
            <label htmlFor="descriptionField">{t("step_description")}</label>
            <FullHTMLEditorWithController
              control={control}
              defaultValue={(defaultValues?.description as any)?.text ?? ""}
              id="descriptionField"
              name="description.text"
              placeholder={`${t("enter_your_text")}`}
            />
          </div>

          {/* ---------- Step Owner ---------- */}
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="owner">{t("owner")}</label>
              <HTMLItalicsInputWithController
                control={control}
                rules={{
                  required: false,
                }}
                name="owner.text"
                id="ownerField"
                defaultValue={(defaultValues?.owner as any)?.text ?? ""}
                placeholder={`${t("enter_group_role_or_person_resposible")}`}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              {/* ---------- Estimated duration ---------- */}
              <label htmlFor="estimatedDurationField">
                {t("estimated_duration_business_days")}
              </label>
              <input
                type="number"
                id="estimatedDurationField"
                className="form-control width-100"
                min={0}
                max={999999}
                placeholder={t("business_days_for_placeholder")}
                {...register("estimatedDuration")}
              />

              {/* ---------- Include House of Common Holidays ---------- */}
              <div className="checkbox">
                <label htmlFor="IncludeHouseOfCommonHolidaysCheckbox">
                  <input
                    type="checkbox"
                    id="IncludeHouseOfCommonHolidaysCheckbox"
                    {...register("includeCommonHoliday")}
                    name="includeCommonHoliday"
                  />
                  &nbsp;
                  {t("include_house_of_common_holidays")}
                </label>
              </div>
            </div>
          </div>
        </fieldset>

        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.isSaving}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default StepForm;
