import { useMutation } from "@apollo/client";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { Maybe, UpdateDistributionListDocument } from "generated/gql-types";
import * as React from "react";
import { OverlayContainer } from "react-aria";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DistributionListForm, {
  DistributionListFormFields,
} from "../DistributionListForm/DistributionListForm";

export const EditDistributionListButtonAndModal: React.FC<{
  distributionListId: string;
  defaultValues: Maybe<DistributionListFormFields>;
}> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const popupState = editDistributionListPopup({});
  const validDistributionListId =
    props.distributionListId != null && props.distributionListId.length > 0;

  const [updateDistributionList, { loading: saving, error: savingError }] =
    useMutation(UpdateDistributionListDocument, {
      refetchQueries: ["DistributionList"],
    });

  const onClose = () => {
    popupState.close();
  };

  const onSubmit: SubmitHandler<DistributionListFormFields> = async (
    formData
  ) => {
    try {
      const res = await updateDistributionList({
        variables: {
          updateDistributionListId: props.distributionListId,
          input: {
            name: formData.name,
            note: formData.note,
          },
        },
      });

      if (res.errors) throw res.errors;

      popupState.close();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-primary card-header-button-link"
        onClick={popupState.open}
        disabled={!validDistributionListId}
        data-testid="button-edit-distribution-list"
        title={t("edit_distribution_list")}
      >
        {t("edit_distribution_list")}
      </button>
      {popupState.isOpen && (
        <OverlayContainer>
          <ModalDialog
            title={t("edit_distribution_list")}
            className={"modal-width-70"}
            isOpen={false}
            onClose={onClose}
            showModalFooter={false}
          >
            <GraphqlError
              title="Error updating distribution list"
              errors={savingError}
            />
            <DistributionListForm
              isEditMode={true}
              defaultValues={{
                name: props.defaultValues?.name,
                program: props.defaultValues?.program,
                note: props.defaultValues?.note,
              }}
              onClose={onClose}
              onSubmit={onSubmit}
            />
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
};

export default EditDistributionListButtonAndModal;
export const editDistributionListPopup = useOverlayTriggerState;
