import FormButtons from "components/molecules/FormButtons/FormButtons";
import { CustomContact } from "components/organisms/contacts/types";
import {
  BilingualAbbreviationAcronymLookupText,
  BilingualName,
  BilingualRichText,
  DateRange,
  ListingWs,
  Maybe,
  PermitDescriptionAuthorityUsed,
  PermitDescriptionExplanationFor,
  PermitDescriptionPermitType,
  PermitDescriptionPurpose,
  PermitIdDetails,
  RichText,
} from "generated/gql-types";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { filterForDirtyFields } from "util/forms";
import { AffectedWildlifeSpeciesCard } from "./affectedWildlifeSpeciesCard";
import { PermitDescriptionInformationCard } from "./permitDescriptionInformationCard";
import { PermitIdsCard } from "./permitIdsCard";
import { setNullForEmptyFields } from "./utils";

export interface PermitDescriptionFormProps {
  initialValues: Partial<PermitDescriptionFormFields>;
  isFinalStage: boolean;
  isDmtsPermitDescription?: Maybe<boolean>;
  onSubmit: SubmitHandler<Partial<PermitDescriptionFormFields>>;
  onCancel: () => void;
}

export const PermitDescriptionForm: React.FC<PermitDescriptionFormProps> = (
  props
) => {
  const { initialValues, isFinalStage, isDmtsPermitDescription } = props;
  const { t } = useTranslation();
  const { params } = useRouteMatch();

  const form = useForm<PermitDescriptionFormFields>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<PermitDescriptionFormFields>> = async (
    formData
  ) => {
    const FilteredFormData = filterForDirtyFields(formData, dirtyFields);
    setNullForEmptyFields(FilteredFormData, dirtyFields, initialValues);

    await props.onSubmit(FilteredFormData);
    window.scrollTo(0, 0);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PermitIdsCard
          initialValues={initialValues}
          isDmtsPermitDescription={isDmtsPermitDescription}
        />
        <PermitDescriptionInformationCard
          initialValues={initialValues}
          isFinalStage={isFinalStage}
          isDmtsPermitDescription={isDmtsPermitDescription}
        />
        <AffectedWildlifeSpeciesCard
          initialValues={initialValues}
          isDmtsPermitDescription={isDmtsPermitDescription}
        />

        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onCancel}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
};

export default PermitDescriptionForm;

///////////////////////////////////////////////////

export interface PermitDescriptionFormFields {
  permitIdDetails?: PermitIdDetails[];
  issuingAuthority: BilingualAbbreviationAcronymLookupText;
  type: PermitDescriptionPermitType;
  explanationFor: PermitDescriptionExplanationFor;
  permitDates: DateRange;
  purposes: PermitDescriptionPurpose[];
  description: BilingualRichText;
  preConditions: BilingualRichText;
  authoritiesUsed: PermitDescriptionAuthorityUsed[];
  locationOfActivities: BilingualName[];
  otherRelevantInformation: BilingualRichText;
  affectedSpecies: ListingWs[];
  federalCanadaPlaces: BilingualAbbreviationAcronymLookupText[];
  customContact: CustomContact | null;
  note: RichText;
}
