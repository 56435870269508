import { useMutation, useQuery } from "@apollo/client";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { CosewicWsFormOutput } from "components/organisms/cosewic/CosewicWsForm";
import Ribbon from "components/organisms/cosewic/Ribbon";
import CosewicDataEntry from "components/templates/cosewic/DataEntry";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import {
  CosewicProfileEditDocument,
  CosewicWsStatus,
  UpdateCosewicWsDocument,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import GraphqlError from "../../../components/GraphqlError";

const Edit: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const { loading, error, data } = useQuery(CosewicProfileEditDocument, {
    variables: { id: cosewicWsId },
  });

  const [updateCosewicWs, { loading: saving, error: savingError }] =
    useMutation(UpdateCosewicWsDocument);

  const onSubmit: SubmitHandler<Partial<CosewicWsFormOutput>> = async (
    values
  ) => {
    try {
      const res = await updateCosewicWs({
        variables: {
          id: cosewicWsId,
          input: {
            // TODO:TYPES: any
            ...(values as any),
          },
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: CosewicPathHelpers.CosewicProfile(cosewicWsId ?? "error"),
      });

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) return <p>Loading...</p>;

  if (!data || !data.cosewicWs) {
    console.error(
      "COSEWIC WS edit page received empty data for: COSEWIC WS ID:",
      cosewicWsId
    );
    return <p>Invalid data!</p>;
  }

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GraphqlError
            title="Error fetching COSEWIC WS profile"
            errors={error}
          />
          <GraphqlError
            title="Error saving COSEWIC WS profile"
            errors={savingError}
          />

          <h1>{t("edit_cosewic_information")}</h1>
          {saving ? <p>Saving...</p> : null}
          <ChangeTrackingProvider
            isEnabled={data.cosewicWs.status === CosewicWsStatus.Permanent}
          >
            <CosewicDataEntry cosewicWs={data.cosewicWs} onSubmit={onSubmit} />
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Edit;
