import { Alert, AlertTypes } from "components/atoms/Alert";
import {
  ConfirmationModal,
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import { MAX_NUMBER_OF_CHILDREN } from "config/constants";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import FieldValidationError from "../../atoms/forms/FieldValidationError";

export enum DuplicateType {
  SPLIT = "split",
  MERGE = "merge",
}

export interface DuplicateChildrenFields {
  numberOfDuplicates: number;
}

export interface DuplicateChildrenFormProps {
  disabled?: boolean;
  duplicateType: DuplicateType;
  defaultValues?: DuplicateChildrenFields;
  onSubmit: SubmitHandler<DuplicateChildrenFields>;
}

export const DuplicateChildrenForm: React.FC<DuplicateChildrenFormProps> = (
  props
) => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;

  const form = useForm<DuplicateChildrenFields>({
    defaultValues: props.defaultValues ?? { numberOfDuplicates: 1 },
  });

  const { getValues, register, handleSubmit, formState, trigger } = form;
  const { errors } = formState;
  const { t } = useTranslation();

  let buttonText: string = "";
  let modalTitle: string = "";
  let modalAlertTitle: string = "";
  let modalAlertContent: string = "";
  let modalCheckboxText: string = "";

  switch (props.duplicateType) {
    case DuplicateType.SPLIT:
      buttonText = "split";
      modalTitle = "split_cosewic_ws";
      modalAlertTitle = "split_warning";
      modalAlertContent =
        "Some info here to let the user know what will happen when this action is taken place.";
      modalCheckboxText = "confirm_split";
      break;
    case DuplicateType.MERGE:
      buttonText = "merge";
      modalTitle = "merge_cosewic_ws";
      modalAlertTitle = "merge_warning";
      modalAlertContent =
        "Some info here to let the user know what will happen when this action is taken place.";
      modalCheckboxText = "confirm_merge";
      break;
    default:
      console.error("Missing DuplicateType.");
  }

  const onSubmit: SubmitHandler<DuplicateChildrenFields> = async (formData) => {
    props.onSubmit(formData);
    setConfirmCheckboxState(false);
  };

  const onCancel = () => {
    history.push({
      pathname: CosewicPathHelpers.RelatedSpecies(cosewicWsId ?? "error"),
    });
  };

  const confirmationModal = useConfirmationModal({});
  const [confirmCheckboxState, setConfirmCheckboxState] =
    React.useState<boolean>(false);

  const validateThenOpenModal = () => {
    trigger().then((valid) => {
      if (valid) confirmationModal.open();
    });
  };

  return (
    <form>
      <div className="flex-col mrgn-bttm-md">
        <label htmlFor="numberOfDuplicatesInput" className="required">
          {t("number_of_duplicates")}
          <strong className="required"> ({t("required")})</strong>
        </label>

        <input
          className="form-control"
          type="number"
          min={1}
          max={MAX_NUMBER_OF_CHILDREN}
          placeholder={t("number_of_duplicates")}
          {...register("numberOfDuplicates", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
            max: {
              value: MAX_NUMBER_OF_CHILDREN,
              message: t("enter_number_between_1_and_number", {
                number: MAX_NUMBER_OF_CHILDREN,
              }),
            },
            min: {
              value: 1,
              message: t("enter_number_between_1_and_number", {
                number: MAX_NUMBER_OF_CHILDREN,
              }),
            },
          })}
          disabled={props.disabled}
        />
        {errors.numberOfDuplicates && (
          <FieldValidationError>
            {errors.numberOfDuplicates.message ?? "error"}
          </FieldValidationError>
        )}
      </div>

      <ul className="list-inline lst-spcd">
        <li>
          <button
            type="button"
            disabled={props.disabled}
            className="btn btn-primary"
            onClick={validateThenOpenModal}
            data-testid={`button-${buttonText}`}
          >
            {t(buttonText)}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-default"
            data-testid="button-cancel"
          >
            {t("cancel")}
          </button>
        </li>
      </ul>

      <ConfirmationModal
        onConfirm={handleSubmit(onSubmit)}
        onCancel={() => {
          setConfirmCheckboxState(false);
        }}
        title={t(modalTitle)}
        confirmBtnEnabled={confirmCheckboxState}
        modalState={confirmationModal}
      >
        <>
          <Alert
            type={AlertTypes.INFO}
            title={t(modalAlertTitle, {
              count: getValues("numberOfDuplicates"),
            })}
            content={modalAlertContent}
          />

          <label>
            <input
              type="checkbox"
              checked={confirmCheckboxState}
              onChange={(e) => setConfirmCheckboxState((x) => !x)}
              data-testid="modal-checkbox-confirm-duplicate"
            />{" "}
            {t(modalCheckboxText)}
          </label>
        </>
      </ConfirmationModal>
    </form>
  );
};

export default DuplicateChildrenForm;
