import { BilingualRichText } from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";
import { PhotoFormFields } from "./PhotoForm";

export const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<PhotoFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<PhotoFormFields>,
  initialValues: Partial<PhotoFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // name: BilingualTextInput
  if (
    dirtyFields.title?.english &&
    htmlIsNullOrEmpty(formData.title?.english?.text) &&
    !htmlIsNullOrEmpty(initialValues.title?.english?.text)
  ) {
    set(formData, "title.english", null);
  }
  if (
    dirtyFields?.title?.french &&
    htmlIsNullOrEmpty(formData?.title?.french?.text) &&
    !htmlIsNullOrEmpty(initialValues?.title?.french?.text)
  ) {
    set(formData, "title.french", null);
  }

  // dateTaken: String
  if (dirtyFields.dateTaken && isNullOrEmpty(formData.dateTaken)) {
    set(formData, "dateTaken", null);
  }

  // description: BilingualRichTextInput
  setNullForEmptyBilingualRichTextFields(
    "description",
    formData,
    dirtyFields,
    initialValues
  );

  // photoSpecies: [OverviewWsSpeciesInput]
  if (
    dirtyFields.photoSpecies &&
    isNullOrEmpty(formData.photoSpecies) &&
    !isNullOrEmpty(initialValues.photoSpecies)
  ) {
    set(formData, "photoSpecies", null);
  }

  // usageHistory: RichTextInput
  if (
    dirtyFields.usageHistory?.text &&
    htmlIsNullOrEmpty(formData.usageHistory?.text) &&
    !htmlIsNullOrEmpty(initialValues.usageHistory?.text)
  ) {
    set(formData, "usageHistory", null);
  }
};

export const setNullForEmptyBilingualRichTextFields = (
  bilingualFieldName: keyof typeof formData,
  formData: UnpackNestedValue<Partial<PhotoFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<PhotoFormFields>
) => {
  // English
  const enIsDirty = dirtyFields[bilingualFieldName]?.english?.text;
  const enIsEmptyInForm = htmlIsNullOrEmpty(
    (formData[bilingualFieldName] as BilingualRichText)?.english?.text
  );

  const enIsEmptyInInitialValues = htmlIsNullOrEmpty(
    (initialValues[bilingualFieldName] as BilingualRichText)?.english?.text
  );

  // French
  const frIsDirty = dirtyFields[bilingualFieldName]?.french?.text;
  const frIsEmptyInForm = htmlIsNullOrEmpty(
    (formData[bilingualFieldName] as BilingualRichText)?.french?.text
  );

  const frIsEmptyInInitialValues = htmlIsNullOrEmpty(
    (initialValues[bilingualFieldName] as BilingualRichText)?.french?.text
  );

  // Cases where full object should be null
  if (enIsDirty && frIsDirty && enIsEmptyInForm && frIsEmptyInForm) {
    set(formData, bilingualFieldName, null);
    return;
  } else if (
    enIsDirty &&
    enIsEmptyInForm &&
    !frIsDirty &&
    frIsEmptyInInitialValues
  ) {
    set(formData, bilingualFieldName, null);
    return;
  } else if (
    frIsDirty &&
    frIsEmptyInForm &&
    !enIsDirty &&
    enIsEmptyInInitialValues
  ) {
    set(formData, bilingualFieldName, null);
    return;
  }

  // Cases where only en should be set null
  if (enIsDirty && enIsEmptyInForm) {
    set(formData, bilingualFieldName + ".english", null);
    return;
  }

  // Cases where only fr should be set null
  if (frIsDirty && frIsEmptyInForm) {
    set(formData, bilingualFieldName + ".french", null);
    return;
  }

  return;
};
