import PlaceholderImage from "assets/svg/placeholder.svg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import { Assessment, CosewicWs } from "../../../generated/gql-types";
import MissingData from "../../atoms/MissingData";

interface RelatedSpeciesListItemProps {
  titleLink?: string;
  cosewicWs?: CosewicWs;
  cosewicWsAssessmentLatest?: Assessment;
  imgUrl?: string;
  showImage?: boolean;
}

const RelatedSpeciesListItem: React.FC<RelatedSpeciesListItemProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  if (!props.cosewicWs) return <div>Invalid COSEWIC WS</div>;

  const name =
    i18n.language === "fr"
      ? props.cosewicWs.commonNameFrench?.name?.plainText
      : props.cosewicWs.commonNameEnglish?.name?.plainText;

  const population =
    i18n.language === "fr"
      ? props.cosewicWs.population?.nameFr
      : props.cosewicWs.population?.nameEn;

  const cosewicId = props.cosewicWs.cosewicId;
  const wsStage = props.cosewicWs.status;

  const latestAssessmentDate = props.cosewicWsAssessmentLatest?.date;

  return (
    <div className="flex">
      {props.showImage && (
        <div>
          <img
            src={props.imgUrl ? props.imgUrl : PlaceholderImage}
            className="list-item-thumbnail mrgn-rght-md"
            alt=""
          />
        </div>
      )}
      <div className="flex-col width-100">
        <div className="flex justify-between">
          <div>
            {props.titleLink ? (
              <Link to={props.titleLink} className="hover-underline">
                <h2 className="mrgn-tp-0 mrgn-bttm-0">
                  {name ?? <MissingData />}
                </h2>
              </Link>
            ) : (
              <h2 className="mrgn-tp-0 mrgn-bttm-sm">
                {name ?? <MissingData />}
              </h2>
            )}
            <div className="font-size-14 mrgn-bttm-md text-muted">
              {population ?? <MissingData />}
            </div>
          </div>
          <div>{props.children}</div>
        </div>
        <div className="species-field row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("ws_stage")}</dt>
              <dd className="font-size-16">
                {wsStage ? t(wsStage) : <MissingData />}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("cosewic_id")}</dt>
              <dd className="font-size-16">{cosewicId ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("latest_assessment_date")}</dt>
              <dd className="font-size-16">
                {formatTimestamp(latestAssessmentDate) ?? <MissingData />}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default RelatedSpeciesListItem;
