import * as React from "react";
import { useTranslation } from "react-i18next";
import AddBptTemplatePopup, {
  AddBptTemplatePopupProps,
  useAddBptTemplateModal,
} from ".";

interface AddBptTemplateModalWithButtonProps
  extends Omit<AddBptTemplatePopupProps, "modalState"> {
  buttonLabel?: string;
  disabled?: boolean;
}

const AddBptTemplateModalWithButton: React.FC<
  AddBptTemplateModalWithButtonProps
> = ({ buttonLabel, disabled = false, ...props }) => {
  const { t } = useTranslation();
  const modalState = useAddBptTemplateModal({});
  const label = buttonLabel ?? t("add_new_bpt_template");
  return (
    <>
      <button
        type="button"
        className="btn btn-link mrgn-0 py-0 px-2 card-header-button-link"
        onClick={modalState.open}
        disabled={disabled}
        data-testid="button-add-new-bpt-template"
        title={label}
      >
        <i className="fas fa-plus-circle mrgn-rght-sm"></i>
        {label}
      </button>
      {!disabled ? (
        <AddBptTemplatePopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddBptTemplateModalWithButton;
