import { useCallback, useState } from "react";
import Filters from "features/search/changelog/odataMappers";
import buildQuery from "../../../util/odata/buildQuery";
import * as AzureSearchConfig from "./azureSearchConfig";
import { SearchServiceExportParams } from "../../../util/basicSearch/fetchExport";
import {
  AzureSearchParams,
  FilterArguments,
  PagingArguments,
} from "../../../features/search/types";

function buildFilterString(params: FilterArguments) {
  const checkboxFilterOptions = Object.entries(
    params.allSelectedFilters
  ).flatMap(([facetName, valuesForFacet]) => {
    if (valuesForFacet.length === 0) return null;

    const mapper = Filters[facetName as keyof typeof Filters];
    if (mapper == null) {
      console.warn("buildFilterString could not find a mapper for", facetName);
      return null;
    }

    const valueStrings = valuesForFacet.map((x) => x.value);

    return mapper.filter(valueStrings);
  });

  const dateRangeObjects = Object.entries(params.allDateRanges).flatMap(
    ([facetName, filter]) => {
      const { label, ...dateRange } = filter.value;

      const mapper = (Filters as any)[facetName];
      if (mapper == null) {
        console.warn(
          "buildFilterString could not find a mapper for",
          facetName
        );
        return null;
      }

      return mapper.filter(dateRange);
    }
  );

  const numberRangeObjects = Object.entries(params.allNumberRanges).flatMap(
    ([facetName, filter]) => {
      const { label, ...numberRange } = filter.value;

      const mapper = (Filters as any)[facetName];
      if (mapper == null) {
        console.warn(
          "buildFilterString could not find a mapper for",
          facetName
        );
        return null;
      }

      return mapper.filter(numberRange);
    }
  );

  return buildQuery({
    filter: {
      or: params.extraFilters?.or ?? [],
      and: dateRangeObjects
        .concat(params.extraFilters?.and ?? [])
        .concat(checkboxFilterOptions)
        .concat(numberRangeObjects),
    },
  });
}

export function buildQueryParameters<
  T extends Partial<AzureSearchParams> | Partial<SearchServiceExportParams>
>(filterArgs: FilterArguments, paging: PagingArguments, extra?: T): T {
  const filterString = buildFilterString(filterArgs);

  return {
    filter: filterString,
    skip: paging.pageSize * (paging.pageNumber - 1), // NOTE: skip should be 0 based, so we subtract 1 from page number
    top: paging.pageSize,
    ...extra,
  } as T;
}
