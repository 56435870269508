import { get } from "lodash";
import * as React from "react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { BptTemplatePhase, Maybe } from "generated/gql-types";

export interface PhaseSelectionDropdownProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  id?: string;
  phaseid: string;
  label?: string;
  required?: boolean;
  errors?: FieldErrors;
  phaseList?: Maybe<BptTemplatePhase>[] | null;
}

export const PhaseSelectionDropdown = React.forwardRef(
  (props: PhaseSelectionDropdownProps, ref) => {
    const { t } = useTranslation();

    const name = props.name ?? "";
    const labelText = props.label ?? props.name ?? "";
    const id = props.id ?? name;

    const err = get(props.errors, name);
    const shouldShowErrors = err != null;

    const { required, phaseList, ...propsWithoutRequired } = props;

    return (
      <div className="form-group col-md-16">
        <div className="flex justify-left align-start">
          <label htmlFor={id} className="mrgn-rght-sm mrgn-tp-sm">
            {labelText}
            {required ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </label>

          <div className="flex justify-left align-start">
            <select
              id={id}
              className="form-control full-width"
              defaultValue={props.defaultValue}
              {...propsWithoutRequired}
              placeholder={t("select_phase")}
              ref={ref as any}
            >
              <option value="">{t("select_phase")}</option>
              {phaseList?.map((value, index) => {
                return value?.id !== props.phaseid ? (
                  <option key={value?.id} value={value?.id!}>
                    {t("phase")} {index + 1}
                    {": "}
                    {value?.name?.plainText}
                  </option>
                ) : null;
              })}
            </select>
            {shouldShowErrors && (
              <FieldValidationError>{err.message}</FieldValidationError>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default PhaseSelectionDropdown;
