import React from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { loginRequestConfig, loginSilentRequestConfig } from "./config";

/**
 * Attempt to get a user's token from a given MSAL instance
 * @param instance The instance, usually from a useMsal() call
 * @param inProgress Optional inProgress string, usually from a useMsal() call
 */
export const asyncTokenLookup = async (
  instance: IPublicClientApplication,
  inProgress?: InteractionStatus
) => {
  const account = await instance.getActiveAccount();
  await instance.handleRedirectPromise();

  // some action was in progress; we shouldn't attempt anything.
  // this SHOULD never happen, since we call handleRedirectPromise above.
  if (inProgress !== "none") {
    return;
  }

  // no account found; redirect user to login and return the token if possible.
  if (account == null) {
    await instance.acquireTokenRedirect(loginRequestConfig);
    const result = await instance.handleRedirectPromise();
    return result?.accessToken;
  }

  // found an account; try to get their token.
  // on error, redirect to login again
  try {
    const result = await instance.acquireTokenSilent({
      ...loginSilentRequestConfig,
      account,
    });
    return result.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      await instance.handleRedirectPromise();
      await instance.acquireTokenRedirect(loginRequestConfig);
      const result = await instance.handleRedirectPromise();
      return result?.accessToken;
    }
  }
};
