import Filters from "components/organisms/search/FilterOptionsSection/filters/filters";
import { useTranslation } from "react-i18next";
import { AzureSearchNumberRange } from "../../organisms/search/FilterOptionsSection/filters/filters";
import useBasicSearch from "../../../pages/search/hooks/useBasicSearch";

export interface BasicSearchNumberRangeProps {
  label: string;
  facetName: keyof typeof Filters;
  open?: boolean; // whether or not the details section is expanded or not
  allFacets: any;
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const BasicSearchNumberRange: React.FC<BasicSearchNumberRangeProps> = (
  props
) => {
  const { t } = useTranslation();

  const value =
    props.basicSearch.state.numberRangeFilters[props.facetName]?.value;

  const ge = value?.ge;
  const le = value?.le;

  const setFromNumber = (newNumber?: number) => {
    let newValue: AzureSearchNumberRange;

    if (numberIsInvalid(newNumber)) {
      newValue = { ...value, ge: undefined };
    } else {
      newValue = { ...value, ge: newNumber };
    }

    props.basicSearch.dispatch({
      type: "update_number_range_filter",
      filter: {
        value: newValue,
        facet: props.facetName,
        label: props.label,
      },
    });
  };

  const setToNumber = (newNumber?: number) => {
    let newValue: AzureSearchNumberRange;

    if (numberIsInvalid(newNumber)) {
      newValue = { ...value, le: undefined };
    } else {
      newValue = { ...value, le: newNumber };
    }

    props.basicSearch.dispatch({
      type: "update_number_range_filter",
      filter: {
        value: newValue,
        facet: props.facetName,
        label: props.label,
      },
    });
  };

  const numberFromInputEvent = (e: any) => {
    if (e.currentTarget?.value == null) return undefined;
    return parseInt(e.currentTarget.value);
  };

  const selectedCountInSection = props.basicSearch.getCountForSection(
    props.facetName
  );

  return (
    <>
      <details className="species-details" open={props.open}>
        <summary>
          {props.label}
          {selectedCountInSection > 0 ? (
            <span className="badge">
              {selectedCountInSection}
              <span className="wb-inv"> options selected</span>
            </span>
          ) : null}
        </summary>

        <label htmlFor={`${props.facetName}_fromNumber`}>{t("from")}</label>
        <input
          type="number"
          id={`${props.facetName}_fromNumber`}
          name={`${props.facetName}_fromNumber`}
          data-testid={`${props.facetName}_fromNumber`}
          value={ge ?? ""}
          min={0}
          className="form-control width-100"
          onInput={(e) => setFromNumber(numberFromInputEvent(e))}
        />

        <label htmlFor={`${props.facetName}_toNumber`}>{t("to")}</label>
        <input
          type="number"
          id={`${props.facetName}_toNumber`}
          name={`${props.facetName}_toNumber`}
          data-testid={`${props.facetName}_toNumber`}
          value={le ?? ""}
          min={0}
          className="form-control width-100"
          onInput={(e) => setToNumber(numberFromInputEvent(e))}
        />
      </details>
    </>
  );
};

export default BasicSearchNumberRange;

const numberIsInvalid = (newNumber?: number) =>
  newNumber == null || isNaN(newNumber);
