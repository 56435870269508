import { Item } from "@react-stately/collections";
import Autocomplete from "components/molecules/Autocomplete";
import * as React from "react";

import { CosewicWs } from "generated/gql-types";

import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import type { UseCosewicWsAutocomplete } from "./useCosewicWsAutocomplete";

interface CosewicWsAutocompleteProps {
  onSelectionChange: (newCosewicWs?: CosewicWs) => void;
  initialCosewicWs?: CosewicWs;
  label: string | ReactElement;
  placeholder?: string;
  useCosewicWsAutocompleteInstance: UseCosewicWsAutocomplete;
  disabled?: boolean;
  onClear?: () => void;
}

const CosewicWsAutocomplete: React.FC<CosewicWsAutocompleteProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { list } = props.useCosewicWsAutocompleteInstance;

  const defaultValue = React.useMemo(
    () => parseInitialCosewicWs(i18n.language, props.initialCosewicWs),
    [i18n.language, props.initialCosewicWs]
  );

  const cosewicWsNameForLanguage = React.useCallback(
    (item: CosewicWs) => {
      if (i18n.language === "fr") {
        return {
          id: item?.id,
          name: item?.commonNameFrench?.name?.plainText,
          population: item?.population?.nameFr,
        };
      }

      return {
        id: item?.id,
        name: item?.commonNameEnglish?.name?.plainText,
        population: item?.population?.nameEn,
      };
    },
    [i18n.language]
  );

  const onSelectionChange = (key: string | number) => {
    const item = list.getItem(key);
    if (item == null) {
      props.onSelectionChange(undefined);
      return;
    }

    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <Autocomplete
      label={props.label}
      items={list.items}
      inputValue={list.filterText}
      defaultInputValue={defaultValue}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      onSelectionChange={onSelectionChange}
      placeholder={(props.placeholder ?? props.label).toString()}
      disabled={props.disabled}
      onClear={onClear}
    >
      {(item: CosewicWs) => {
        const name = cosewicWsNameForLanguage(item).name ?? "error";
        const cosewicId = t("cosewic_id") + " " + item.cosewicId;
        const population = cosewicWsNameForLanguage(item).population;
        let item_value = name;
        if (population) item_value += ", " + population;

        return (
          <Item key={item.id} textValue={item_value + " - " + cosewicId}>
            <span className="font-size-16">
              {item_value}
              {" - "}
              <span className="text-muted">{cosewicId}</span>
            </span>
          </Item>
        );
      }}
    </Autocomplete>
  );
};

const parseInitialCosewicWs = (
  language: string,
  initialCosewic?: CosewicWs
): string => {
  if (!initialCosewic) return "";
  if (language === "fr")
    return initialCosewic.commonNameFrench?.name?.text ?? "";
  return initialCosewic.commonNameEnglish?.name?.text ?? "";
};

export default CosewicWsAutocomplete;
