import { useMutation, useQuery } from "@tanstack/react-query";
import ExportButton from "components/atoms/ExportButton";
import Layout from "components/layouts/OneColumn";
import { GlobalAlert } from "features/globalAlert";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { INDEX_NAME } from "features/search/wildlifespecies/constants";
import { usePagination } from "hooks/util/usePagination";
import { omit } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert, { AlertTypes } from "../../components/atoms/Alert";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import SectionCard from "../../components/atoms/SectionCard";
import PageSizeSelect from "../../components/molecules/PageSizeSelect";
import Pagination from "../../components/molecules/Pagination";
import { FilterKeywordSection } from "../../components/organisms/search/FilterKeywordSection/FilterKeywordSection";
import { FilterOptionsSection } from "../../components/organisms/search/FilterOptionsSection/FilterOptionsSection";
import FilterTags from "../../components/organisms/search/FilterTags/FilterTags";
import ResultsTable from "../../components/organisms/search/ResultsTable/ResultsTable";
import { useBasicSearchQuery } from "../../features/search/hooks/useBasicSearchQuery";
import { fetchFilters } from "../../features/search/utils/fetchFilters";
import { makeFilterString } from "../../features/search/utils/makeFilterString";
import { makePaging } from "../../features/search/utils/makePaging";
import facetsList from "../../features/search/wildlifespecies/facets.json";
import odataMappers from "../../features/search/wildlifespecies/odataMappers";
import {
  SearchServiceExportParams,
  fetchExport,
} from "../../util/basicSearch/fetchExport";
import { formatTimestamp } from "../../util/formatTimestamp";
import useBasicSearch from "./hooks/useBasicSearch";

interface WsSearchPageColumns {
  id: string;
  "Cosewic/CommonNameEnglish/Name"?: string;
  "Cosewic/CommonNameFrench/Name"?: string;
  "Cosewic/ScientificName/GeneratedScientificName/Name": string;
  "Cosewic/LatestPublishedAssessment/Status": string;
  "Cosewic/LatestPublishedAssessment/Date": string;
  "Cosewic/LatestAssessment/Status": string;
  "Cosewic/LatestAssessment/Date": string;
  "Listing/LatestProcess/Schedule1Status": string;
  "Listing/DateOfAdditionOnSchedule1/SortableScheduleDate": string;
}

const wsSearchExtraFilters = {};

const searchFields =
  "Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/ScientificName/GeneratedScientificName/Name, Listing/ListingIdStr, Cosewic/CommonNameEnglish/Name, Cosewic/CommonNameFrench/Name, Cosewic/Population/NameEn, Cosewic/Population/NameFr, Cosewic/ScientificName/GeneratedScientificName/Name, Cosewic/CosewicIdStr, Listing/LatestProcess/RegulatoryProcess/RegulatoryBundle, Cosewic/OtherRecognizedCommonNameEnglish/PlainText, Cosewic/OtherRecognizedCommonNameFrench/PlainText, Cosewic/OtherRecognizedScientificName/PlainText, Cosewic/IndigenousName/PlainText";
const queryType = "full";
const searchMode = "any";
const select =
  "Id, Cosewic/Id, Cosewic/CommonNameEnglish/Name, Cosewic/CommonNameFrench/Name, Cosewic/Population/NameEn, Cosewic/Population/NameFr, Cosewic/ScientificName/GeneratedScientificName/Name, Cosewic/ScientificName/GeneratedScientificName/FormattedName, Cosewic/CosewicId, Cosewic/LatestAssessment/Status, Cosewic/LatestAssessment/Date, Cosewic/LatestPublishedAssessment/Status, Cosewic/LatestPublishedAssessment/Date, Listing/Id, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/ScientificName/GeneratedScientificName/Name, Listing/LatestProcess/Schedule1Status, Listing/DateOfAdditionOnSchedule1/Date, Listing/DateOfAdditionOnSchedule1/SortableScheduleDate, Listing/DateOfAdditionOnSchedule1/DateRange/FromDate, Listing/DateOfAdditionOnSchedule1/DateRange/ToDate, Listing/LatestProcess/RegulatoryProcess/GicDecisionDate, Listing/LatestProcess/RegulatoryProcess/RegulatoryBundle";
const exportSelect =
  "Id, Cosewic/Id, Cosewic/CommonNameEnglish/Name, Cosewic/CommonNameFrench/Name, Cosewic/Population/NameEn, Cosewic/Population/NameFr, Cosewic/ScientificName/GeneratedScientificName/Name, Cosewic/CosewicId, Cosewic/LatestPublishedAssessment/Status, Cosewic/LatestPublishedAssessment/Date, Cosewic/LatestAssessment/Status, Cosewic/LatestAssessment/Date, Listing/Id, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/ScientificName/GeneratedScientificName/Name, Listing/LatestProcess/Schedule1Status, Listing/DateOfAdditionOnSchedule1/Date, Listing/DateOfAdditionOnSchedule1/DateRange/FromDate, Listing/DateOfAdditionOnSchedule1/DateRange/ToDate";

const WildlifeSpecies: React.FC = (props) => {
  const { t, i18n } = useTranslation();

  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);
  const basicSearch = useBasicSearch("basicsearch_overview");
  const filtersQuery = useQuery(
    ["basicSearch", "listing", "filters"],
    () => {
      return fetchFilters(facetsList, INDEX_NAME, wsSearchExtraFilters);
    },
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      const column =
        i18n.language === "fr"
          ? "Cosewic/CommonNameFrench/Name"
          : "Cosewic/CommonNameEnglish/Name";

      return makeOrderByString({
        column,
        direction: "ascending",
      });
    }

    return makeOrderByString(sortDescriptor);
  }, [i18n.language, sortDescriptor]);

  const { data, loading, error, newRunSearchQuery } =
    useBasicSearchQuery(INDEX_NAME);

  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      const filterString = makeFilterString(
        odataMappers,
        basicSearch.state,
        wsSearchExtraFilters
      );
      const paging = makePaging(pagination);

      return {
        filter: filterString,
        search: basicSearch.state.keywordSearchText,
        queryType,
        searchMode,
        searchFields,
        select,
        orderby: orderByString,
        count: true,
        ...paging,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoResetPageNumber() {
      const newPageNumber = 1;
      pagination.goToPage(newPageNumber);
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      newRunSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  const onSearchClick = async () => {
    await newRunSearchQuery(azureSearchArguments);
  };

  const onResetSearch = async () => {
    basicSearch.dispatch({ type: "reset_keyword_search" });
    pagination.goToPage(1);
    exportMutation.reset();
  };

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const columns: Array<{
    name: string;
    key: keyof WsSearchPageColumns;
    sortable: boolean;
    isHTML?: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("common_name_with_population"),
        key:
          i18n.language === "fr"
            ? "Cosewic/CommonNameFrench/Name"
            : "Cosewic/CommonNameEnglish/Name",
        sortable: true,
        hasLink: true,
      },
      {
        name: t("scientific_name"),
        key: "Cosewic/ScientificName/GeneratedScientificName/Name",
        sortable: true,
        isHTML: true,
      },
      {
        name: t("cosewic_status"),
        key: "Cosewic/LatestPublishedAssessment/Status",
        sortable: true,
      },
      {
        name: t("cosewic_status_date"),
        key: "Cosewic/LatestPublishedAssessment/Date",
        sortable: true,
      },
      {
        name: t("latest_assessment_decision"),
        key: "Cosewic/LatestAssessment/Status",
        sortable: true,
      },
      {
        name: t("latest_assessment_date"),
        key: "Cosewic/LatestAssessment/Date",
        sortable: true,
      },
      {
        name: t("listing_status"),
        key: "Listing/LatestProcess/Schedule1Status",
        sortable: true,
      },
      {
        name: t("addition_to_schedule_1"),
        key: "Listing/DateOfAdditionOnSchedule1/SortableScheduleDate",
        sortable: true,
      },
    ],
    [i18n.language]
  );

  //
  // Map search response to columns

  const rows: Array<WsSearchPageColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let wildlifeSpeciesName = "";
        if (i18n.language === "fr") {
          wildlifeSpeciesName =
            value?.Cosewic?.CommonNameFrench?.Name ??
            value?.Listing?.CommonNameFrench?.Name ??
            value?.Cosewic?.CommonNameEnglish?.Name ??
            value?.Listing?.CommonNameEnglish?.Name;
          if (value?.Cosewic?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Cosewic?.Population.NameFr ??
              value?.Listing?.Population?.NameFr ??
              value?.Cosewic?.Population?.NameEn ??
              value?.Listing?.Population?.NameEn;
        } else {
          wildlifeSpeciesName =
            value?.Cosewic?.CommonNameEnglish?.Name ??
            value?.Listing?.CommonNameEnglish?.Name ??
            value?.Cosewic?.CommonNameFrench?.Name ??
            value?.Listing?.CommonNameFrench?.Name;
          if (value?.Cosewic?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Cosewic?.Population?.NameEn ??
              value?.Listing?.Population?.NameEn ??
              value?.Cosewic?.Population?.NameFr ??
              value?.Listing?.Population?.NameFr;
        }

        const dateOfAdditionOnSchedule1 = formatTimestamp(
          value?.Listing?.DateOfAdditionOnSchedule1?.SortableScheduleDate
        );

        /*
         * Figure out if we should link to the overview page, or the page for a single resource.
         *
         * This works for now, and should hopefully handle the future ability to
         * manually create a listing WS without a COSEWIC WS.
         */
        let _href;
        if (value?.Cosewic?.Id && value?.Listing?.Id) {
          _href = `/overview?program=cosewicws&id=${value?.Cosewic?.Id}`;
        } else if (value?.Cosewic?.Id) {
          _href = `/cosewic/${value?.Cosewic?.Id}`;
        } else if (value?.Listing?.Id) {
          _href = `/listing/${value?.Listing?.Id}`;
        }

        return {
          _href,
          id: value?.Id,
          [i18n.language === "fr"
            ? "Cosewic/CommonNameFrench/Name"
            : "Cosewic/CommonNameEnglish/Name"]: wildlifeSpeciesName,
          "Cosewic/ScientificName/GeneratedScientificName/Name":
            value?.Cosewic?.ScientificName?.GeneratedScientificName
              ?.FormattedName,
          "Cosewic/LatestPublishedAssessment/Status": value?.Cosewic
            ?.LatestPublishedAssessment?.Status
            ? t(value?.Cosewic?.LatestPublishedAssessment?.Status)
            : undefined,
          "Cosewic/LatestPublishedAssessment/Date": formatTimestamp(
            value?.Cosewic?.LatestPublishedAssessment?.Date
          ),
          "Cosewic/LatestAssessment/Status": value?.Cosewic?.LatestAssessment
            ?.Status
            ? t(value?.Cosewic?.LatestAssessment?.Status)
            : undefined,
          "Cosewic/LatestAssessment/Date": formatTimestamp(
            value?.Cosewic?.LatestAssessment?.Date
          ),
          "Listing/LatestProcess/Schedule1Status": value?.Listing?.LatestProcess
            ?.Schedule1Status
            ? t(value?.Listing?.LatestProcess?.Schedule1Status)
            : undefined,
          "Listing/DateOfAdditionOnSchedule1/SortableScheduleDate":
            dateOfAdditionOnSchedule1,
        };
      }),
    [data?.value]
  );

  const exportMutation = useMutation(
    ["basicSearch", "wildlifeSpecies", "export"],
    (params: any) => fetchExport(params, INDEX_NAME)
  );

  const fullExportMutation = useMutation(
    ["basicSearch", "listing", "export", "full"],
    (params: any) => fetchExport(params, INDEX_NAME)
  );

  const runExport = async () => {
    const languageCode = i18n.language as any;
    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    // We filter out DateOfAdditionOnSchedule1 so we can turn it into three separate columns.
    // If it's left as one, the export service can't turn its value into a row since it might be a single date or might be an object.
    const columnHeaders = columns
      .filter(
        (x) =>
          x.key !== "Listing/DateOfAdditionOnSchedule1/SortableScheduleDate" &&
          // We filter out common name with population so we can replace it with "Cosewic English common name" in the export (ticket 50885 & 39955)
          x.key !== "Cosewic/CommonNameFrench/Name" &&
          x.key !== "Cosewic/CommonNameEnglish/Name"
      )
      .map((x) => ({ name: x.name, key: x.key })) as any;

    // Additionally show separate population columns (ticket 50885 & 39955)
    columnHeaders.unshift({
      name: t("cosewic_population_french"),
      key: "Cosewic/Population/NameFr",
    });
    columnHeaders.unshift({
      name: t("cosewic_french_common_name"),
      key: "Cosewic/CommonNameFrench/Name",
    });
    columnHeaders.unshift({
      name: t("cosewic_population_english"),
      key: "Cosewic/Population/NameEn",
    });
    // Replace "common name with population" with "Cosewic English common name" in the export (ticket 350885 & 9955)
    columnHeaders.unshift({
      name: t("cosewic_english_common_name"),
      key: "Cosewic/CommonNameEnglish/Name",
    });

    columnHeaders.push({
      name: `${t("addition_to_schedule_1")} ${t("date").toLowerCase()}`,
      key: "Listing/DateOfAdditionOnSchedule1/Date",
    });
    columnHeaders.push({
      name: `${t("addition_to_schedule_1")} ${t("start")}`,
      key: "Listing/DateOfAdditionOnSchedule1/DateRange/FromDate",
    });
    columnHeaders.push({
      name: `${t("addition_to_schedule_1")} ${t("end")}`,
      key: "Listing/DateOfAdditionOnSchedule1/DateRange/ToDate",
    });

    const baseSearchArguments = omit(azureSearchArguments, ["count"]);

    const searchParameters: SearchServiceExportParams = {
      ...baseSearchArguments,

      includeTotalCount: true,
      searchFields: searchFields.split(/,\s?/g),
      orderby: orderByString?.split(/,\s?/g),
      select: exportSelect.split(/,\s?/g),
    };

    return exportMutation.mutate({
      searchParameters,
      columnHeaders,
      languageCode,
    });
  };

  const runFullExport = async () => {
    const languageCode = i18n.language as any;
    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    const baseSearchArguments = omit(azureSearchArguments, ["count", "select"]);

    const searchParameters: SearchServiceExportParams = {
      ...baseSearchArguments,

      searchTemplateName: "FULL",
      includeTotalCount: true,
      searchFields: searchFields.split(/,\s?/g),
      orderby: orderByString?.split(/,\s?/g),
    };

    return fullExportMutation.mutate({
      searchParameters,
      languageCode,
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("wildlife_species_search")}</h1>
          <p>{t("wildlife_species_search_explanation")}</p>
          <div className="row">
            <div className="col-md-3">
              <FilterOptionsSection
                basicSearch={basicSearch}
                allFacets={allFacets}
              />
            </div>
            <div className="col-md-9">
              {exportMutation.isSuccess ? (
                <Alert
                  type={AlertTypes.SUCCESS}
                  title={t("export_success")}
                  onClose={exportMutation.reset}
                />
              ) : null}

              {fullExportMutation.isError ? (
                <Alert
                  type={AlertTypes.DANGER}
                  title={t("export_failure")}
                  onClose={fullExportMutation.reset}
                />
              ) : null}

              {exportMutation.isError ? (
                <Alert
                  type={AlertTypes.DANGER}
                  title={t("export_failure")}
                  onClose={exportMutation.reset}
                />
              ) : null}

              <FilterKeywordSection
                defaultValue={basicSearch.state.keywordSearchText}
                onChange={(text) =>
                  basicSearch.dispatch({ type: "update_keyword_search", text })
                }
                onReset={onResetSearch}
                onSearch={onSearchClick}
              />

              <SectionCard
                header={
                  <div className="flex justify-between align-start">
                    <h2>{t("wildlife_species_search_results")}</h2>
                    <div className="flex gap-md align-start">
                      <ExportButton
                        text={t("full_export")}
                        onExport={runFullExport}
                        loading={fullExportMutation.isLoading}
                      />
                      <ExportButton
                        text={t("export")}
                        onExport={runExport}
                        loading={exportMutation.isLoading}
                      />
                    </div>
                  </div>
                }
              >
                <>
                  <FilterTags basicSearch={basicSearch} onReset={onResetTags} />

                  {/*RESULTS:*/}
                  {loading ? (
                    <LoadingIndicator centered className="mrgn-bttm-md" />
                  ) : rows && rows?.length === 0 ? (
                    <NoResults centered />
                  ) : (
                    <>
                      <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                        <div className="font-size-18">
                          {pagination.makeShowingString(
                            data ? data["@odata.count"] : 0
                          )}
                        </div>
                        <div>
                          <PageSizeSelect
                            pageSize={pagination.pageSize}
                            onChangePageSize={onChangePageSize}
                          />
                        </div>
                      </div>
                      {!loading && error ? (
                        <pre>{JSON.stringify(error)}</pre>
                      ) : null}
                      {rows == null || rows.length === 0 ? null : (
                        <ResultsTable
                          rows={rows}
                          columns={columns}
                          sortable
                          sortDescriptor={sortDescriptor}
                          onSortChange={onSortChange as any}
                        />
                      )}

                      <Pagination
                        {...pagination.paginationComponentProps}
                        onPageSelected={onPageSelected}
                        totalPages={
                          data && data["@odata.count"] && pagination.pageSize
                            ? Math.ceil(
                                data["@odata.count"] / pagination.pageSize
                              )
                            : 0
                        }
                      />
                    </>
                  )}
                </>
              </SectionCard>
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default WildlifeSpecies;
