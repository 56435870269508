import { ODataMapper } from "../types";
import { AzureSearchDateRange } from "../../../components/organisms/search/FilterOptionsSection/filters/filters";

const changelogOdataMappers: Record<string, ODataMapper> = {
  "LogEntry/TransactionType": {
    key: "LogEntry/TransactionType",
    filter(searchValues: string[]) {
      return {
        LogEntry: {
          or: searchValues.map((x) => ({ TransactionType: x })),
        },
      };
    },
  },

  "LogEntry/ActionDescription": {
    key: "LogEntry/ActionDescription",
    filter(searchValues: string[]) {
      return {
        LogEntry: {
          or: searchValues.map((x) => ({ ActionDescription: x })),
        },
      };
    },
  },

  "LogEntry/ModifiedAt": {
    key: "LogEntry/ModifiedAt",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        LogEntry: {
          ModifiedAt: dateRange,
        },
      };
    },
  },

  "LogEntry/DataChange/PathProperty/PropertyPath": {
    key: "LogEntry/DataChange/PathProperty",
    filter(searchValues: string[]) {
      return {
        LogEntry: {
          DataChange: {
            PathProperty: {
              or: searchValues.map((x) => ({ PropertyPath: x })),
            },
          },
        },
      };
    },
  },

  "LogEntry/DataChange/PathProperty/PropertyPathEnglish": {
    key: "LogEntry/DataChange/PathProperty",
    filter(searchValues: string[]) {
      return {
        LogEntry: {
          DataChange: {
            PathProperty: {
              or: searchValues.map((x) => ({ PropertyPathEnglish: x })),
            },
          },
        },
      };
    },
  },

  "LogEntry/DataChange/PathProperty/PropertyPathFrench": {
    key: "LogEntry/DataChange/PathProperty",
    filter(searchValues: string[]) {
      return {
        LogEntry: {
          DataChange: {
            PathProperty: {
              or: searchValues.map((x) => ({ PropertyPathFrench: x })),
            },
          },
        },
      };
    },
  },

  "LogEntry/DataChange/MetaData/TypeOfChange": {
    key: "LogEntry/DataChange/MetaData/TypeOfChange",
    filter(searchValues: string[]) {
      return {
        LogEntry: {
          DataChange: {
            MetaData: {
              or: searchValues.map((x) => ({ TypeOfChange: x })),
            },
          },
        },
      };
    },
  },
};

export default Object.freeze(changelogOdataMappers);
