import {
  AutocompleteForDocumentTypeDocument,
  DocumentPublicationType,
  IdNameType,
} from "generated/gql-types";
import i18n from "i18n";
import type { ReactElement } from "react";
import * as React from "react";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";

interface DocumentTypeAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newDocumentType?: null | IdNameType) => void;
  initialDocumentType?: any;
  label: string | ReactElement;
  publicationType: DocumentPublicationType;
}

const DocumentTypeAutocomplete: React.FC<DocumentTypeAutocompleteProps> = (
  props
) => {
  const additionalQueryVariables = React.useMemo(() => {
    return {
      params: {
        category:
          props.publicationType !== DocumentPublicationType.Internal
            ? "documenttype"
            : "internaldocumenttype",
        searchType: "startsWith",
        isCaseSensitive: false,
        sortBy: i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
      },
    };
  }, [props.publicationType]);

  return (
    <QueryAutocomplete
      id={"documentTypeAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForDocumentTypeDocument}
      listDisplayFormatter={(item) =>
        i18n.language === "fr" ? item.name.french : item.name.english
      }
      onSelectionChange={props.onSelectionChange}
      placeholder={
        i18n.language === "fr"
          ? props.initialDocumentType?.name.french ?? ""
          : props.initialDocumentType?.name.english ?? ""
      }
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

export default DocumentTypeAutocomplete;
