import { useMutation } from "@apollo/client";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import RelatedSpeciesWarning from "components/molecules/listing/relatedSpecies/relatedSpeciesWarning";
import { Ribbon } from "components/organisms/cosewic/Ribbon";
import { useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import { union } from "lodash";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import GraphqlError from "../../../../components/GraphqlError";
import LoadingIndicator from "../../../../components/atoms/LoadingIndicator";
import SectionCard from "../../../../components/atoms/SectionCard";
import FormButtons from "../../../../components/molecules/FormButtons/FormButtons";
import AddListingRelatedSpeciesListItem from "../../../../components/molecules/listing/relatedSpecies/AddListingRelatedSpeciesListItem";
import ListingWsAutocomplete from "../../../../components/organisms/listing/ListingWsAutocomplete";
import useListingWsAutocomplete from "../../../../components/organisms/listing/ListingWsAutocomplete/useListingWsAutocomplete";
import {
  ListingAddParentsDocument,
  ListingWs,
} from "../../../../generated/gql-types";
import { ListingPathHelpers } from "../../ListingRouter";

export interface AddParentsProps {}

const AddParents: React.FC<AddParentsProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const alertContext = useGlobalAlertContext();
  const overviewContext = useOverviewContext();
  const listingWsAutocompleteInstance = useListingWsAutocomplete();
  const { handleSubmit } = useForm();

  const [listingWsList, setListingWsList] = React.useState<
    Array<Partial<ListingWs> | undefined>
  >([]);

  const [currentSelectedSpecies, setCurrentSelectedSpecies] = React.useState<
    ListingWs | undefined
  >(undefined);

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  // const foundInList = (id?: string) => {
  //   if (!id) return false;
  //   if (listingWsList.find((x) => id === x?.id)) {
  //     return true;
  //   }
  //   return false;
  // };

  const [saveParents, saveParentsMutationStatus] = useMutation(
    ListingAddParentsDocument
  );

  const onSubmit = async () => {
    try {
      const additionalParentIds = listingWsList
        .filter((x) => x != null && x.id != null)
        .map((x) => x!.id as string);

      if (additionalParentIds == null || additionalParentIds.length === 0) {
        return;
      }

      const res = await saveParents({
        variables: {
          id: listingWsId,
          input: {
            additionalParentIds,
          },
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("todo", {}),
      });
      history.push({ pathname: ListingPathHelpers.ViewParents(listingWsId) });
    } catch (e) {}
  };

  const onSelectListingWs = () => {
    if (currentSelectedSpecies)
      setListingWsList((x) => union([currentSelectedSpecies], x));
    listingWsAutocompleteInstance.clear();
  };

  const onRemoveFromList = (id?: string) => {
    if (!id) return;
    setListingWsList((list) => list.filter((x) => x?.id !== id));
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingRelatedSpecies(
        listingWsId ?? "error"
      ),
    });
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("add_parents")}</h1>

          <GraphqlError errors={saveParentsMutationStatus.error} />
          <RelatedSpeciesWarning listingWsId={listingWsId} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <SectionCard
              header={
                <h2>
                  {t("selected_parents")}{" "}
                  {saveParentsMutationStatus.loading ? (
                    <LoadingIndicator className="mrgn-lft-sm" />
                  ) : null}
                </h2>
              }
            >
              <ListingWsAutocomplete
                onSelectionChange={setCurrentSelectedSpecies}
                label={t("search_wildlife_species")}
                useListingWsAutocompleteInstance={listingWsAutocompleteInstance}
              />

              <button
                type="button"
                className="btn btn-primary mrgn-tp-md mrgn-bttm-md"
                disabled={!currentSelectedSpecies}
                onClick={onSelectListingWs}
                data-testid="button-add-to-list"
              >
                {t("add_to_list")}
              </button>

              {listingWsList?.map((item) => {
                return (
                  <div className="list-item separator-line-top" key={item?.id}>
                    <AddListingRelatedSpeciesListItem
                      listingWs={item as ListingWs}
                      titleLink={ListingPathHelpers.ListingProfile(
                        item?.id ?? "error"
                      )}
                    >
                      <div className="button">
                        <button
                          onClick={() => onRemoveFromList(item?.id)}
                          tabIndex={0}
                          type="submit"
                          data-testid={`remove-btn-${item?.id}`}
                          className="btn btn-warning btn-xs"
                        >
                          {t("remove")}
                        </button>
                      </div>
                    </AddListingRelatedSpeciesListItem>
                  </div>
                );
              })}
            </SectionCard>

            <FormButtons
              onCancel={onCancel}
              isDirty={listingWsList.length > 0}
            />
          </form>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default AddParents;
