import { useApolloClient, useQuery } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/OneColumn";
import UsersAdminPageFilterForm, {
  defaultUserQueryFields,
  UserQueryFields,
} from "components/molecules/admin/UsersAdminPageFilterForm";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import AddUserModalWithButton from "components/organisms/admin/AddUserModal/AddUserButtonWithModal";
import EditProgramButtonWithModal from "components/organisms/admin/EditProgramModal/EditProgramButtonWithModal";
import EditRoleButtonWithModal from "components/organisms/admin/EditRoleModal/EditRoleButtonWithModal";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import {
  RenderWhenAuthorized,
  ROLE_ACTIONS,
  Roles,
} from "features/auth/components";
import { useUserAuthorization } from "features/auth/useUserAuthorization";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  ArchiveStatus,
  AuthorizationCurrentUserDocument,
  AuthorizationSarDmtsUserListDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";

export const AdminUsersPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const history = useHistory();
  const globalAlertContext = useGlobalAlertContext();
  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<UserQueryFields>(
    defaultUserQueryFields
  );
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const pagination = usePagination();
  const adminAlertMsg = useAdminAlertMsg(t("users"));
  const { checkUserIsAuthorized } = useUserAuthorization();
  const [isShowingDeactivatedUsers, setIsShowingDeactivatedUsers] =
    React.useState<boolean>(false);

  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(AuthorizationCurrentUserDocument, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "name":
          return "details.displayName";
        case "email":
          return "details.email";
        case "theProgram":
          return "program";
        case "theRoles":
          return "roles";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const rows = useAsyncList<any>({
    initialSortDescriptor: {
      column: "modifiedAt",
      direction: "descending",
    },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        errorPolicy: "all",
        query: AuthorizationSarDmtsUserListDocument,
        variables: {
          params: {
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
            search:
              filterText.search.length > 0 ? filterText.search : undefined,
            status: filterText.showInactive
              ? ArchiveStatus.Inactive
              : ArchiveStatus.Active,
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
            searchType: "Contains",
          },
        },
        fetchPolicy: "network-only",
      });

      const items =
        results.data.authorizationSarDmtsUserList?.users?.map((user) => {
          return {
            ...user,
            id: user?.id,
            name: user?.details?.displayName,
            email: user?.details?.email,
            theProgram:
              i18n.language === "fr"
                ? user?.program?.name?.french
                : user?.program?.name?.english,
            theRoles: user?.roles?.map((role) => role?.name).join(",<br>"),
            modifiedAt: formatTimestamp(user?.modifiedAt),
          };
        }) ?? [];

      setTotalCount(
        results.data.authorizationSarDmtsUserList?.pagination?.totalCount ?? 0
      );
      setTotalPages(
        results.data.authorizationSarDmtsUserList?.pagination?.totalPages ?? 0
      );

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  React.useEffect(() => {
    rows.reload(); // refetchQueries
    setSelectedRowId("");
  }, [pagination.currentPage, pagination.pageSize]);

  const columns = React.useMemo(
    () => [
      {
        name: t("name"),
        key: "name",
        sortable: true,
      },
      {
        name: t("email"),
        key: "email",
        sortable: true,
      },
      {
        name: t("program"),
        key: "theProgram",
        sortable: true,
      },
      {
        name: t("roles"),
        key: "theRoles",
        sortable: true,
        isHTML: true,
      },
      {
        name: t("updated_date"),
        key: "modifiedAt",
        sortable: true,
      },
    ],
    [t]
  );

  const onFilterSubmit = (value: UserQueryFields) => {
    flushSync(() => {
      setFilters(value);
      rows.setFilterText(value as any);
      pagination.goToPage(1);
    });

    setIsShowingDeactivatedUsers(value.showInactive);
  };

  const isGlobalAdmin = checkUserIsAuthorized([Roles.GlobalAdministrator]);
  const currentUserProgramAcronym =
    dataCurrentUser?.authorizationCurrentUser?.program?.acronym ?? "";
  const selectedRow = rows.items.find((x) => x.id === selectedRowId);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("manage_users")}</h1>
          <GraphqlError title={t("fetch_fail")} errors={errorCurrentUser} />
          <GraphqlError title={t("fetch_fail")} errors={rows?.error} />

          <SafeRenderHtml
            htmlString={
              isGlobalAdmin
                ? t("global_admin_user_description")
                : t("program_admin_user_description")
            }
          />
          {loadingCurrentUser && (
            <LoadingIndicator centered className="mrgn-bttm-md" />
          )}

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("users")}</h2>
                <div className="flex gap-md">
                  {/* ------- Add new user (Button) ------- */}
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.user.create}
                  >
                    <AddUserModalWithButton
                      onCompleted={(data: any) => {
                        rows.reload(); // refetchQueries
                        //Todo: Need to fix this message to show
                        globalAlertContext.showSuccess({
                          message:
                            i18n.language === "fr"
                              ? data?.createUser?.name?.english
                              : data?.createUser?.name?.french,
                          timeOut: 5000,
                        });
                        history.push({
                          pathname: "/admin/users",
                        });
                      }}
                      onError={adminAlertMsg.onCreateError}
                      disabled={isShowingDeactivatedUsers}
                      program={{
                        id: dataCurrentUser?.authorizationCurrentUser?.program
                          ?.id,
                        acronym:
                          dataCurrentUser?.authorizationCurrentUser?.program
                            ?.acronym,
                        abbreviation:
                          dataCurrentUser?.authorizationCurrentUser?.program
                            ?.abbreviation,
                        name: dataCurrentUser?.authorizationCurrentUser?.program
                          ?.name,
                      }}
                    />
                  </RenderWhenAuthorized>

                  {/* ------- Edit program (Button) ------- */}
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.user.updateProgram
                    }
                  >
                    <EditProgramButtonWithModal
                      userId={selectedRowId}
                      userEmail={selectedRow?.email ?? "error"}
                      defaultValues={{
                        program: selectedRow?.program,
                      }}
                      buttonLabel={t("edit_program")}
                      onCompleted={(data) => {
                        rows.reload(); // refetchQueries
                      }}
                      disabled={isShowingDeactivatedUsers}
                    />
                  </RenderWhenAuthorized>

                  {/* ------- Edit roles (Button) ------- */}
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.user.updateRoles
                    }
                  >
                    <EditRoleButtonWithModal
                      userId={selectedRowId}
                      userEmail={selectedRow?.email ?? "error"}
                      currentUserProgram={currentUserProgramAcronym}
                      defaultValues={{
                        roles: selectedRow?.roles,
                      }}
                      buttonLabel={t("edit_roles")}
                      onCompleted={(data) => {
                        rows.reload(); // refetchQueries
                      }}
                      disabled={isShowingDeactivatedUsers}
                    />
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <UsersAdminPageFilterForm onSubmit={onFilterSubmit} />

              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16 justify-end pb-3">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>

            {/*RESULTS*/}
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows && rows?.items?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                {!rows.isLoading && rows?.error ? (
                  <pre>{JSON.stringify(rows?.error)}</pre>
                ) : null}
                {rows == null || rows?.items?.length === 0 ? null : (
                  <>
                    <ResultsTable
                      rows={rows.items}
                      columns={columns}
                      onSelectionChange={onSelectionChange as any}
                      selectedKeys={[selectedRowId]}
                      sortable
                      selectionMode={
                        isShowingDeactivatedUsers ? "none" : "single"
                      }
                      showSelectionCheckboxes
                      sortDescriptor={rows.sortDescriptor}
                      onSortChange={rows.sort}
                    />

                    <Pagination
                      {...pagination.paginationComponentProps}
                      totalPages={totalPages ?? 0}
                    />
                  </>
                )}
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AdminUsersPage;
