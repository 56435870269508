import React from "react";
import {
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { asyncTokenLookup } from "./asyncTokenLookup";
import {
  loginRequestConfig,
  loginSilentRequestConfig,
  msalInstanceConfig,
} from "./config";

const msalInstance = new PublicClientApplication(msalInstanceConfig);

export async function getUserAccessToken() {
  // NOTE: "none" here may cause problems, ideally we'd get this from the MSAL instance!
  let token = await asyncTokenLookup(msalInstance, InteractionStatus.None);

  if (token == null) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      await msalInstance.acquireTokenRedirect(loginRequestConfig);
      const result = await msalInstance.handleRedirectPromise();
      token = result?.accessToken;
    } else {
      const result = await msalInstance.acquireTokenSilent({
        ...loginSilentRequestConfig,
        account,
      });
      token = result.accessToken;
    }
  }

  return token;
}

export const CustomMsalProvider: React.FC = ({ children }) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
