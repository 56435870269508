import * as React from "react";
import { useTranslation } from "react-i18next";
import Alert, { AlertTypes } from "../../../atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "../../ConfirmationModal";

export interface AdminArchiveModalProps {
  selectedItemId: string;
  onArchive: () => void;
  modalTitle: string;
  warningText: string;
}

export const AdminArchiveModal: React.FC<AdminArchiveModalProps> = (props) => {
  const { onArchive, modalTitle, warningText, selectedItemId } = props;
  const { t } = useTranslation();

  const confirmationModal = useConfirmationModal({});
  const [confirmCheckboxState, setConfirmCheckboxState] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setConfirmCheckboxState(false);
  }, [selectedItemId]);

  return (
    <>
      <button
        className="btn btn-link card-header-button-link hover-underline-danger"
        type="button"
        data-testid="button-archive"
        onClick={confirmationModal.open}
        disabled={selectedItemId === ""}
      >
        <span className="text-danger">{t("archive")}</span>
      </button>
      <ConfirmationModal
        onConfirm={onArchive}
        onCancel={() => {}}
        title={modalTitle}
        confirmBtnEnabled={confirmCheckboxState}
        modalState={confirmationModal}
      >
        <>
          <Alert type={AlertTypes.WARNING} content={warningText} />

          <label>
            <input
              type="checkbox"
              checked={confirmCheckboxState}
              onChange={(e) => setConfirmCheckboxState((x) => !x)}
              data-testid="modal-checkbox-confirm-archive"
            />{" "}
            {t("archive_confirm")}
          </label>
        </>
      </ConfirmationModal>
    </>
  );
};

export default AdminArchiveModal;
