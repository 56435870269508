import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { resolveAzureEnvironment } from "azure/environment";
import { appInsights } from "azure/appinsights";
import { SARDMTSApiErrorExtensions } from "../../components/GraphqlError";

const env = resolveAzureEnvironment();

export const roundTripTimeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ rttStart: new Date() });
  return forward(operation);
});

export const roundTripTimeEndLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    // @ts-ignore
    const time = new Date() - operation.getContext().rttStart; // start time from roundTripTimeStartLink

    console.log(`GraphQL operation ${operation.operationName} took ${time}ms`);

    appInsights?.trackEvent({
      name: "Apollo RTT",
      properties: {
        env,
        operation: operation.operationName,
        time,
      },
    });

    return data;
  });
});

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.error(
          `[${env}][GraphQL Error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        const typedExtensions = extensions as SARDMTSApiErrorExtensions;
        const errUrl = typedExtensions?.response?.url ?? "(Missing)";
        const serverErrMsgs = typedExtensions?.response?.body?.messages ?? [];

        if (serverErrMsgs.length > 0) {
          serverErrMsgs
            .filter((x) => x.reasonCode === "BrokenBusinessRule")
            .forEach((x) => {
              appInsights?.trackEvent({
                name: "Broken Business Rule",
                properties: {
                  env,
                  operation: operation.operationName,
                  message,
                  path,
                  url: errUrl,
                  code: x.code,
                  reasonCode: x.reasonCode,
                  englishMessage: x.englishMessage,
                  frenchMessage: x.frenchMessage,
                },
              });
            });
        }

        appInsights?.trackEvent({
          name: "GraphQL Error",
          properties: {
            env,
            operation: operation.operationName,
            message,
            path,
            url: errUrl,
            messages: serverErrMsgs,
          },
        });
      });
    }

    if (networkError) {
      console.error(`[${env}][Network error]: ${networkError}`);

      appInsights?.trackEvent({
        name: "GraphQL Network Error",
        properties: {
          env,
          operation: operation.operationName,
          networkError,
        },
      });
    }
  }
);
