import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { useTranslation } from "react-i18next";
import ListingSectorForm, {
  ListingSectorFormFields,
} from "components/organisms/listing/ListingSectorForm/ListingSectorForm";
import { useOverviewContext } from "features/overview";
import { useMutation, useQuery } from "@apollo/client";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import {
  ListingWsSectorDocument,
  UpdateListingWsSectorDocument,
} from "generated/gql-types";
import {
  mapDomainModelToForm,
  mapFormToUpdateInput,
} from "components/organisms/listing/ListingSectorForm/mappers";
import LoadingIndicator from "../../../../../components/atoms/LoadingIndicator";
import Ribbon from "../../../../../components/organisms/cosewic/Ribbon";

export interface ListingSectorsEditPageProps {}

export const ListingSectorsEditPage: React.FC<ListingSectorsEditPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId, sectorId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { data, loading, error } = useQuery(ListingWsSectorDocument, {
    variables: {
      listingWsId,
      sectorId,
    },
  });

  const [updateListingWsSector, { loading: saving, error: savingError }] =
    useMutation(UpdateListingWsSectorDocument, {
      refetchQueries: ["ListingWsSectorsList"],
    });

  const onSubmit = async (formData: Partial<ListingSectorFormFields>) => {
    const converted = mapFormToUpdateInput(formData);

    try {
      const res = await updateListingWsSector({
        variables: {
          listingWsId,
          sectorId,
          input: {
            ...converted,
          },
        },
      });
      if (res.errors) throw res.errors;

      history.push({
        pathname: ListingPathHelpers.ListingSectors(listingWsId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const initialValues = mapDomainModelToForm(data?.listingWsSector);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("edit_sector")}</h1>
          {loading ? (
            <LoadingIndicator centered />
          ) : (
            <ListingSectorForm
              onSubmit={onSubmit}
              initialValues={initialValues}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingSectorsEditPage;
