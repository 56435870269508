import FieldValidationError from "components/atoms/forms/FieldValidationError";
import DatePicker, {
  formatDateForDatePicker,
} from "components/molecules/DatePicker";
import * as React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SectionCard from "../../../atoms/SectionCard";
import { PermitDescriptionFormFields } from "./PermitDescriptionForm";
import { Maybe } from "generated/gql-types";

export interface PermitIdsCardProps {
  initialValues: Partial<PermitDescriptionFormFields>;
  isDmtsPermitDescription?: Maybe<boolean>;
}

export const PermitIdsCard: React.FC<PermitIdsCardProps> = (props) => {
  const { initialValues, isDmtsPermitDescription } = props;
  const { t } = useTranslation();

  const form = useFormContext<PermitDescriptionFormFields>();
  const { control, register, formState } = form;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "permitIdDetails",
  });

  return (
    <SectionCard header={<h2>{t("permit_ids")}</h2>}>
      {/* -------------- Permit ID & Issuance date -------------- */}
      <div className="form-group">
        {fields.map((item, index) => {
          return (
            <div key={item.id} className="well-normal">
              <div className="flex justify-between align-top">
                <div className="row mrgn-0 full-width">
                  {/* ------ Permit ID input ------ */}
                  <div className="col-sm-6 form-group">
                    <label
                      htmlFor={`text-permit-id-details-${index}`}
                      className="required"
                    >
                      {t("permit_id")}
                      <strong className="required"> ({t("required")})</strong>
                    </label>
                    <input
                      id={`text-permit-id-${index}`}
                      data-testid={`text-permit-id-${index}`}
                      className="form-control full-width"
                      placeholder="ID"
                      maxLength={30} // confirmed 30 chars max length with Karina(refer the bug #65487)
                      {...register(
                        `permitIdDetails.${index}.permitId` as const,
                        {
                          required: isDmtsPermitDescription!,
                          pattern: /^[A-Z0-9-]*$/,
                        }
                      )}
                      disabled={!isDmtsPermitDescription}
                    />

                    {errors.permitIdDetails &&
                      (errors.permitIdDetails[index] as any)?.permitId?.type ===
                        "required" && (
                        <FieldValidationError>
                          {t("field_is_required")}
                        </FieldValidationError>
                      )}
                    {errors.permitIdDetails &&
                      (errors.permitIdDetails[index] as any)?.permitId?.type ===
                        "pattern" && (
                        <FieldValidationError>
                          {t("only_numbers_capital_letters_hyphens")}
                        </FieldValidationError>
                      )}
                  </div>
                  {/* ------ Issuance Date Picker ------ */}
                  <div className="col-sm-6 form-group">
                    <Controller
                      control={control}
                      name={`permitIdDetails.${index}.issuanceDate`}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          label={t("issuance_date")}
                          value={formatDateForDatePicker(value)}
                          onInput={onChange}
                          id={`permitIdDetails.${index}.issuanceDate`}
                          className="min-width-fit-available line-height-normal"
                          disabled={!isDmtsPermitDescription}
                        />
                      )}
                    />
                  </div>
                </div>
                {/* ------ Remove button ------ */}
                <div>
                  {fields && fields.length > 1 && (
                    <button
                      type="button"
                      className="btn btn-link btn-xs hover-grey"
                      data-testid={`button-delete-${index}`}
                      onClick={() => remove(index)}
                      title={t("remove")}
                      disabled={!isDmtsPermitDescription}
                    >
                      <span className="glyphicon glyphicon-remove font-size-14 color-danger" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() => {
            append({ permitId: "", issuanceDate: "" });
          }}
          data-testid="button-add-permit-id"
          disabled={!isDmtsPermitDescription}
        >
          {/* <span className="far fa-plus-square mrgn-rght-sm" /> */}
          {t("new_item")}
        </button>
      </div>
    </SectionCard>
  );
};
