// import { SharingSettings } from "generated/gql-types";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import SectionCard from "components/atoms/SectionCard";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { QueryToolFormFields } from "../QueryToolForm";
import { TextInput } from "components/atoms/forms/TextInput";

export enum SharingSettings {
  Private = "PRIVATE",
  Global = "GLOBAL",
}

export interface SettingsSectionProps {
  initialValues: QueryToolFormFields;
}

export const SettingsSection: React.FC<SettingsSectionProps> = (props) => {
  const { t } = useTranslation();

  // const form = useFormContext<QueryToolFormFields>();
  const form = useFormContext<QueryToolFormFields>();
  const { register, formState } = form;
  const { errors } = formState;

  return (
    <SectionCard header={<h2>{t("settings")}</h2>}>
      <div className="flex-col gap-sm">
        {/* NAME IN ENGLISH */}
        <TextInput
          id="tbx_nameInEnglish"
          data-testid="tbx_nameInEnglish"
          inputClassName="full-width"
          type="text"
          label={t("name_in_english")}
          defaultValue={props.initialValues.nameInEnglish ?? ""}
          placeholder={t("name_in_english")}
          required={true}
          {...register("nameInEnglish", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
          })}
          errors={errors}
        />

        {/* NAME IN FRENCH */}
        <TextInput
          id="tbx_nameInFrench"
          data-testid="tbx_nameInFrench"
          inputClassName="full-width"
          type="text"
          label={t("name_in_french")}
          defaultValue={props.initialValues.nameInFrench ?? ""}
          placeholder={t("name_in_french")}
          required={true}
          {...register("nameInFrench", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
          })}
        />

        {/* SHARING SETTINGS */}

        <div className="form-group">
          <fieldset className="form-inline chkbxrdio-grp">
            <legend className="required">
              <span className="field-name">{t("sharing_settings")}</span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>
            <label htmlFor="rdo_private" className="radio-inline">
              <input
                type="radio"
                id="rdo_private"
                data-testid="rdo_private"
                // name="sharing_settings"
                value={SharingSettings.Private}
                defaultChecked={
                  props.initialValues.sharingSettings ===
                  SharingSettings.Private
                }
                data-rule-required="true"
                {...register("sharingSettings", { required: true })}
              />
              &#160;{t("private")}
            </label>
            <label htmlFor="rdo_global" className="radio-inline">
              <input
                type="radio"
                id="rdo_global"
                data-testid="rdo_global"
                // name="sharing_settings"
                value={SharingSettings.Global}
                defaultChecked={
                  props.initialValues.sharingSettings === SharingSettings.Global
                }
                {...register("sharingSettings", { required: true })}
              />
              &#160;{t("global")}
            </label>
          </fieldset>
          {errors.sharingSettings && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>

        {/* SHARE WITH A USER OR USER GROUP */}
        <TextInput
          disabled={false} // TODO: Change this field to a list (string[]).
          id="tbx_shareWithUserOrGroup"
          data-testid="tbx_shareWithUserOrGroup"
          inputClassName="full-width"
          type="text"
          label={t("share_with_user_or_group")}
          defaultValue={props.initialValues.sharingWith ?? ""}
          placeholder={t("enter_user_name_group_name_or_email")}
          {...register("sharingWith")}
        />
      </div>
    </SectionCard>
  );
};

export default SettingsSection;
