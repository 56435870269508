import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import {
  BilingualTextInput,
  MethodOfCommunication,
  OrganizationCreateInput,
} from "../../../generated/gql-types";
import { filterForDirtyFields } from "../../../util/forms";
import isNullOrEmpty from "../../../util/isNullOrEmpty";
import { OrganizationFields } from "../../molecules/admin/OrganizationForm";
import {
  mapBilingualTextInput,
  mapContactDetailsInput_New,
  mapNoteInput,
} from "util/formMappers";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { mapContactDetails } from "../contacts/contactsFormUtil";

export const processFormValues = (
  formData: UnpackNestedValue<Partial<OrganizationFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<OrganizationFields>
): Partial<OrganizationFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForEmptyFields = (
  formData: UnpackNestedValue<Partial<OrganizationFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<OrganizationFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // Name
  if (
    dirtyFields?.name?.english != null &&
    isNullOrEmpty(formData?.name?.english) &&
    !isNullOrEmpty(initialValues?.name?.english)
  ) {
    set(formData, "name.english", null);
  }
  if (
    dirtyFields?.name?.french != null &&
    isNullOrEmpty(formData?.name?.french) &&
    !isNullOrEmpty(initialValues?.name?.french)
  ) {
    set(formData, "name.french", null);
  }

  // departments
  if (
    dirtyFields?.departments != null &&
    isNullOrEmpty(formData.departments) &&
    !isNullOrEmpty(initialValues?.departments)
  ) {
    set(formData, "departments", null);
  }

  // Tag
  if (
    dirtyFields?.tag != null &&
    isNullOrEmpty(formData?.tag) &&
    !isNullOrEmpty(initialValues?.tag)
  ) {
    set(formData, "tag", null);
  }

  // contactDetails
  if (
    dirtyFields?.contactDetails?.address?.city != null &&
    isNullOrEmpty(formData?.contactDetails?.address?.city) &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.city)
  ) {
    set(formData, "contactDetails.address.city", null);
  }
  if (
    dirtyFields?.contactDetails?.address?.provinceOrState != null &&
    isNullOrEmpty(formData?.contactDetails?.address?.provinceOrState) &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.provinceOrState)
  ) {
    set(formData, "contactDetails.address.provinceOrState", null);
  }
  if (
    dirtyFields?.contactDetails?.address?.postalOrZipCode != null &&
    isNullOrEmpty(formData?.contactDetails?.address?.postalOrZipCode) &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.postalOrZipCode)
  ) {
    set(formData, "contactDetails.address.postalOrZipCode", null);
  }
  // NOTE: countryCode is handled differently because it's a select element.
  // Instead of checking for null, we check for the "please select an option" value: `NOT_INITIALIZED`
  if (
    dirtyFields?.contactDetails?.address?.countryCode != null &&
    formData?.contactDetails?.address?.countryCode === "NOT_INITIALIZED" &&
    !isNullOrEmpty(initialValues?.contactDetails?.address?.countryCode)
  ) {
    set(formData, "contactDetails.address.countryCode", null);
  }

  if (
    dirtyFields?.contactDetails?.emailAddress != null &&
    isNullOrEmpty(formData?.contactDetails?.emailAddress) &&
    !isNullOrEmpty(initialValues?.contactDetails?.emailAddress)
  ) {
    set(formData, "contactDetails.emailAddress", null);
  }

  if (
    dirtyFields?.contactDetails?.phoneNumber != null &&
    isNullOrEmpty(formData?.contactDetails?.phoneNumber) &&
    !isNullOrEmpty(initialValues?.contactDetails?.phoneNumber)
  ) {
    set(formData, "contactDetails.phoneNumber", null);
  }

  if (
    dirtyFields?.contactDetails?.faxNumber != null &&
    isNullOrEmpty(formData?.contactDetails?.faxNumber) &&
    !isNullOrEmpty(initialValues?.contactDetails?.faxNumber)
  ) {
    set(formData, "contactDetails.faxNumber", null);
  }

  //Method of Communication
  if (
    dirtyFields?.methodOfCommunication != null &&
    isNullOrEmpty(formData?.methodOfCommunication) &&
    !isNullOrEmpty(initialValues?.methodOfCommunication)
  ) {
    set(formData, "methodOfCommunication", null);
  }

  // note
  if (dirtyFields?.note && htmlIsNullOrEmpty(formData?.note)) {
    set(formData, "note", null);
  }
};

export const domainModelIntoForm = (
  Organization?: OrganizationFields | undefined
): Partial<OrganizationFields> => {
  const out: Partial<OrganizationFields> = {};

  // name
  out.name = {
    english: Organization?.name?.english ?? "",
    french: Organization?.name?.french ?? "",
  };

  // Departments
  let departments: BilingualTextInput[] = [
    { english: "", french: "" },
    { english: "", french: "" },
    { english: "", french: "" },
  ];
  Organization?.departments?.forEach((v, i) => {
    if (!isNullOrEmpty(v?.english)) departments[i].english = v?.english;
    if (!isNullOrEmpty(v?.french)) departments[i].french = v?.french;
  });
  set(out, "departments", departments);

  // tag
  set(
    out,
    "tag.abbreviation.english",
    Organization?.tag.abbreviation?.english ?? ""
  );
  set(
    out,
    "tag.abbreviation.french",
    Organization?.tag.abbreviation?.french ?? ""
  );
  set(out, "tag.acronym", Organization?.tag.acronym ?? "");
  set(out, "tag.id", Organization?.tag.id ?? "");
  set(out, "tag.name.english", Organization?.tag.name?.english ?? "");
  set(out, "tag.name.french", Organization?.tag.name?.french ?? "");

  // contact details
  out.contactDetails = mapContactDetails(
    Organization?.contactDetails ?? undefined
  );

  // Method of communication
  set(
    out,
    "methodOfCommunication",
    Organization?.methodOfCommunication ?? MethodOfCommunication.MailingAddress
  );

  // note
  set(out, "note.text.text", Organization?.note?.text?.text ?? "");

  return out;
};

export const formIntoCreateInput = (
  formData: Partial<OrganizationFields>,
  isCreatOrganization?: boolean
): OrganizationCreateInput => {
  const out: OrganizationCreateInput = {};

  mapBilingualTextInput(out, "name", formData?.name);

  mapContactDetailsInput_New(out, "contactDetails", formData?.contactDetails);

  if (formData.departments !== undefined) {
    set(out, "departments", formData.departments);
  }

  if (formData.tag !== undefined) {
    set(out, "tag", formData.tag);
  }

  if (formData.methodOfCommunication !== undefined) {
    set(out, "methodOfCommunication", formData.methodOfCommunication);
  } else if (isCreatOrganization) {
    set(out, "methodOfCommunication", MethodOfCommunication.MailingAddress);
  }

  if (formData.note !== undefined) {
    mapNoteInput(out, "note", formData.note);
  }

  return out;
};
