import type { ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForGenusDocument,
  Genus,
  IdNameType,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";

const additionalQueryVariables = {
  params: { searchType: "startsWith", isCaseSensitive: false },
};

const GenusAutocomplete: React.FC<GenusAutocompleteProps> = (props) => {
  return (
    <QueryAutocomplete
      id={"genusAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForGenusDocument}
      listDisplayFormatter={(item) => item.name}
      onSelectionChange={props.onSelectionChange}
      placeholder={props.initialGenus?.name ?? ""}
      isRequired={props.isRequired}
      onClear={() => props.onSelectionChange(null)}
      additionalQueryVariables={additionalQueryVariables}
    />
  );
};

interface GenusAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (newGenus?: null | Genus | IdNameType) => void;
  initialGenus?: Genus | IdNameType;
  label: string | ReactElement;
}

export default GenusAutocomplete;
