import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "../../util/router";
import { DistributionListsPage } from "./distributionList";
import { DistributionListPage } from "./distributionList/[id]";

export interface CommunicationsRouterProps {}

export const CommunicationsPathHelpers = {
  DistributionLists: "/communications/distributionList",
  DistributionList: (distributionListId: string) =>
    `/communications/distributionList/${distributionListId}`,
};

export const CommunicationsRouter: React.FC<CommunicationsRouterProps> = (
  props
) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route path={relative("/distributionList/:distributionListId")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DistributionList}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.distributionList.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <DistributionListPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/distributionList")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DistributionList}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.distributionList.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <DistributionListsPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default CommunicationsRouter;
