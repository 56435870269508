import Autocomplete from "components/molecules/Autocomplete";
import { useAsyncList } from "@react-stately/data";
import { Item } from "@react-stately/collections";
import {
  ApolloQueryResult,
  TypedDocumentNode,
  useApolloClient,
} from "@apollo/client";
import * as React from "react";
import { ReactElement, useEffect, useMemo } from "react";
import pDebounce from "p-debounce";
import { SEARCH_DEBOUNCE_TIME_MS } from "config/constants";
import i18n from "i18n";
import { bilingualLookupTextNameForLanguage } from "mappers";

// TODO: try and have the callback props' args be typed correctly
// TODO: withController version
// TODO: types should restrict query document prop to something that matches the TypeOfQueryData shape?
export interface LookupQueryAutocompleteProps<
  TQuery extends TypeOfQueryData<any>
> {
  id: string;
  label: string | ReactElement;
  queryDocument: any;
  // TODO: below any -> derived type of query variables
  additionalQueryVariables?: any;
  // TODO: below any -> derived type of query list results
  listDisplayFormatter: (listItem: any) => string;
  // TODO: below any -> derived type of selected item
  onSelectionChange: (item: any) => void;
  placeholder?: string;
  isRequired?: boolean;
  onClear?: () => void;
  showArrow?: boolean;
}

export interface TypeOfQueryData<TItem> {
  autocompleteItems?: TItem[];
}

export const LookupQueryAutocomplete = <TQuery,>(
  props: LookupQueryAutocompleteProps<TQuery>
) => {
  const {} = props;
  const client = useApolloClient();

  const debouncedSearch = React.useMemo(() => {
    return pDebounce((_pageNumber: number, _filterText: string) => {
      return client.query({
        query: props.queryDocument,
        variables: props.additionalQueryVariables,
      }) as Promise<ApolloQueryResult<TypeOfQueryData<TQuery>>>; // TODO: passing TQuery here isnt correct, the type arg wants the ITEM type not the query type
    }, SEARCH_DEBOUNCE_TIME_MS);
  }, [props?.additionalQueryVariables]);

  // TODO: any here - derive from document type?
  const list = useAsyncList<any>({
    async load({ cursor, filterText }: any) {
      const results = await debouncedSearch(cursor, filterText);
      const items = results?.data?.autocompleteItems ?? [];

      return {
        items,
        cursor,
      };
    },
  });

  useEffect(() => {
    list.reload();
  }, [props?.additionalQueryVariables]);

  const onSelectionChange = (key: string | number) => {
    const item = list.getItem(key);
    props.onSelectionChange(item);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  const sortedListItems = useMemo(() => {
    return list.items.sort((a, b) =>
      bilingualLookupTextNameForLanguage(i18n.language, a).localeCompare(
        bilingualLookupTextNameForLanguage(i18n.language, b)
      )
    );
  }, [list.items]);

  return (
    <>
      <Autocomplete
        id={props.id}
        label={props.label}
        items={sortedListItems}
        inputValue={list.filterText}
        onInputChange={list.setFilterText}
        loadingState={list.loadingState}
        placeholder={props.placeholder}
        onLoadMore={list.loadMore}
        onSelectionChange={onSelectionChange}
        onClear={onClear}
        isRequired={props.isRequired}
        showArrow={props.showArrow}
      >
        {(item) => (
          <Item key={item.id}>{props.listDisplayFormatter(item)}</Item>
        )}
      </Autocomplete>
    </>
  );
};

export default LookupQueryAutocomplete;
