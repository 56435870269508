import FieldValidationError from "components/atoms/forms/FieldValidationError";
import { ListingWsMultiSelectListWithController } from "components/organisms/listing/ListingWsMultiSelectList/ListingWsMultiSelectList";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SectionCard from "../../../atoms/SectionCard";
import { PermitDescriptionFormFields } from "./PermitDescriptionForm";
import { Maybe } from "generated/gql-types";

export interface AffectedWildlifeSpeciesCardProps {
  initialValues: Partial<PermitDescriptionFormFields>;
  isDmtsPermitDescription?: Maybe<boolean>;
}

export const AffectedWildlifeSpeciesCard: React.FC<
  AffectedWildlifeSpeciesCardProps
> = (props) => {
  const { initialValues, isDmtsPermitDescription } = props;
  const { t } = useTranslation();

  const form = useFormContext<PermitDescriptionFormFields>();
  const { control, formState } = form;
  const { errors } = formState;

  return (
    <SectionCard header={<h2>{t("affected_wildlife_species")}</h2>}>
      <div className="form-group">
        <ListingWsMultiSelectListWithController
          control={control}
          name="affectedSpecies"
          required
          disabled={!isDmtsPermitDescription}
        />
        {errors.affectedSpecies && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
    </SectionCard>
  );
};
