import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ExportButtonProps {
  text?: string;
  onExport: () => any;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  showIcon?: boolean;
}

export const ExportButton: React.FC<ExportButtonProps> = (props) => {
  const { t } = useTranslation();
  const {
    text = t("export"),
    onExport,
    loading = false,
    className,
    disabled,
    showIcon = true,
  } = props;

  return (
    <button
      type="button"
      className={cc(["btn btn-link card-header-button-link", className])}
      onClick={onExport}
      disabled={disabled || loading}
      data-testid="button-export"
    >
      {loading ? (
        <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
      ) : (
        showIcon && <i className="fas fa-download mrgn-rght-sm"></i>
      )}
      {text}
    </button>
  );
};

export default ExportButton;
