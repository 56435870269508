import buildQuery from "util/odata/buildQuery";
import type { AdditionalFilters, ODataMapper } from "features/search/types";
import type { BasicSearchState } from "pages/search/hooks/useBasicSearch";

/**
 * makeFilterString
 * Builds an OData string out of basic search state.
 *
 * @param mappers - OData mappers related to a set of filter paths
 * @param basicSearchState - state object from useBasicSearch instance
 * @param extraFilters - any extra "and"/"or" filters to include in the output OData string
 */
// TODO(SEARCH): tests for this function
export function makeFilterString(
  mappers: Record<string, ODataMapper>,
  basicSearchState: BasicSearchState,
  extraFilters?: AdditionalFilters
) {
  const checkboxFilterOptions = Object.entries(
    basicSearchState.checkboxFilters
  ).flatMap(([facetName, valuesForFacet]) => {
    if (valuesForFacet.length === 0) return null;

    const mapper = mappers[facetName];
    if (mapper == null) {
      console.warn("buildFilterString could not find a mapper for", facetName);
      return null;
    }

    const valueStrings = valuesForFacet.map((x) => x.value);
    return mapper.filter(valueStrings);
  });

  const dateRangeObjects = Object.entries(
    basicSearchState.dateRangeFilters
  ).flatMap(([facetName, filter]) => {
    const { label, ...dateRange } = filter.value;

    const mapper = mappers[facetName];
    if (mapper == null) {
      console.warn("buildFilterString could not find a mapper for", facetName);
      return null;
    }

    return mapper.filter(dateRange);
  });

  const numberRangeObjects = Object.entries(
    basicSearchState.numberRangeFilters
  ).flatMap(([facetName, filter]) => {
    const { label, ...numberRange } = filter.value;

    const mapper = mappers[facetName];
    if (mapper == null) {
      console.warn("buildFilterString could not find a mapper for", facetName);
      return null;
    }

    return mapper.filter(numberRange);
  });

  return buildQuery({
    filter: {
      or: extraFilters?.or ?? [],
      and: dateRangeObjects
        .concat(extraFilters?.and ?? [])
        .concat(checkboxFilterOptions)
        .concat(numberRangeObjects),
    },
  });
}
