import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import {
  formatContactListItem,
  formatOrganizationListItem,
} from "components/organisms/contacts/AddContactButtonAndModal/AddContactModalSearch";
import { CustomContact } from "components/organisms/contacts/types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const FormContactDisplay: React.FC<{
  contact?: CustomContact;
}> = (props) => {
  const { t } = useTranslation();
  const formattedContact = formatItem(props.contact);

  return (
    <div className="mrgn-lft-sm">
      <address className="text-muted mrgn-bttm-0">
        <strong>{formattedContact?.name ?? t("no_name")}</strong>
        <br />
        {formattedContact?.address && (
          <SafeRenderHtml htmlString={formattedContact.address} />
        )}
      </address>
      {formattedContact?.note && (
        <div className="text-muted mrgn-tp-sm">
          {t("note")}: <span>{formattedContact.note}</span>
        </div>
      )}
    </div>
  );
};

function formatItem(contact?: CustomContact) {
  if (contact?.contactType === "contact") {
    return formatContactListItem(contact.contactRef);
  } else if (contact?.contactType === "organization") {
    return formatOrganizationListItem(contact.organizationRef as any);
  }

  return undefined;
}
