import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ModalDialog from "components/molecules/Modal";
import {
  BptTemplateStep,
  Maybe,
  UpdateBptTemplateStepDocument,
} from "generated/gql-types";
import StepForm, { StepFormFields } from "../StepForm";
import {
  domainModelIntoStepForm,
  editStepFormIntoCreateInput,
} from "../../bptFormUtil";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { useGlobalAlertContext } from "features/globalAlert";

export interface EditStepPopupProps {
  modalState: OverlayTriggerState;
  templateId?: string;
  stepInfo?: Maybe<BptTemplateStep>;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditStepPopup: React.FC<EditStepPopupProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const { modalState, templateId, stepInfo } = props;

  const [updateBptTemplateStep, { loading: saving, error, reset }] =
    useMutation(UpdateBptTemplateStepDocument, {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
    });

  const onSubmit: SubmitHandler<Partial<StepFormFields>> = async (formData) => {
    const input = editStepFormIntoCreateInput(formData);
    try {
      const res = await updateBptTemplateStep({
        variables: {
          templateId: templateId ?? "error",
          stepId: stepInfo?.id ?? "error",
          input: input,
        },
      });
      if (res.errors == null) {
        modalState.close();
      }

      alertContext.showSuccess({
        message: t("successfully_updated_step"),
        timeOut: 5000,
      });
    } catch (e) {
      console.warn("Error saving new step");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues = domainModelIntoStepForm(stepInfo);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={t("edit_step")}
          className={"modal-width-50"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError
            title={t("error_update_bpt_template_step")}
            errors={error}
          />
          {saving && <LoadingIndicator centered />}
          <StepForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default EditStepPopup;

export const useStepPopup = useOverlayTriggerState;
