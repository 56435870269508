import { useCallback, useState } from "react";
import { buildQueryParameters } from "../../../pages/search/hooks/useAzureSearchQuery";

import { fetchResults } from "../utils/fetchResults";
import { AzureSearchParams, FilterArguments, PagingArguments } from "../types";

export const useBasicSearchQuery = (indexName: string) => {
  const [data, setData] = useState<undefined | any>(); // TODO: any
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const runSearchQuery = useCallback(
    async (
      params: FilterArguments & PagingArguments & Partial<AzureSearchParams>
    ) => {
      setLoading(true);

      // extract paging, filters, etc from the mega-params object
      const {
        pageNumber,
        pageSize,

        allSelectedFilters,
        allDateRanges,
        allNumberRanges,
        extraFilters,
        ...extraParams
      } = params;

      const filterArgs = {
        allSelectedFilters,
        allDateRanges,
        allNumberRanges,
        extraFilters,
      };

      const paging = {
        pageNumber,
        pageSize,
      };

      const builtParams = buildQueryParameters(filterArgs, paging, extraParams);

      const res = await fetchResults(indexName, builtParams).catch(setError);
      if (res) setData(res);

      setLoading(false);
    },
    [indexName]
  );

  const newRunSearchQuery = useCallback(
    async (params: AzureSearchParams) => {
      setLoading(true);
      const res = await fetchResults(indexName, params).catch(setError);
      if (res) setData(res);
      setLoading(false);
    },
    [indexName]
  );

  return {
    data,
    loading,
    error,
    runSearchQuery,
    newRunSearchQuery,
  };
};
