import * as React from "react";
import { useTranslation } from "react-i18next";
import EditSubspeciesModal, {
  EditSubspeciesModalProps,
  useSubspeciesModal,
} from "./";

interface EditSubspeciesModalWithButtonProps
  extends Omit<EditSubspeciesModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditSubspeciesModalWithButton: React.FC<
  EditSubspeciesModalWithButtonProps
> = ({ buttonLabel, ...props }) => {
  const { t } = useTranslation();
  const modalState = useSubspeciesModal({});

  const label = buttonLabel ?? t("edit");

  const validSubspeciesId =
    props.subspeciesId != null && props.subspeciesId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validSubspeciesId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validSubspeciesId ? (
        <EditSubspeciesModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditSubspeciesModalWithButton;
