const RGX_LEADING_SLASH = /^\//;

export function makeRelativeNestedRoute(base: string) {
  return function relative(nestedPath: string) {
    if (RGX_LEADING_SLASH.test(nestedPath)) {
      return base + nestedPath;
    }

    return `${base}/${nestedPath}`;
  };
}
