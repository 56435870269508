import * as React from "react";
import { Link } from "react-router-dom";

interface BadgeBarProps {
  title: string;
  titleLink: string;
  counter: number;
}

const BadgeBar: React.FC<BadgeBarProps> = (props) => {
  return (
    <div className="flex justify-between align-center mrgn-bttm-md">
      <Link to={props.titleLink} data-testid="link-badge-bar">
        {props.title}
      </Link>
      <p className="badge mrgn-bttm-0">{props.counter}</p>
    </div>
  );
};

export default BadgeBar;
