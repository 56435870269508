import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { loginRequestConfig } from "../features/auth/config";

function SignIn() {
  const { instance } = useMsal();

  useEffect(() => {
    (async () => {
      // TODO: use asyncTokenLookup here?
      await instance.handleRedirectPromise();
      await instance.acquireTokenRedirect(loginRequestConfig);
    })();
  }, [instance]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
}

export default SignIn;
