import {
  BptTemplatePhase,
  BptTemplatePhasesOrderInput,
} from "generated/gql-types";
import { set } from "lodash";
import { PhasesReorderFormFields } from "./ReorderPhasesForm";

export const formIntoCreateInput = (
  formData: Partial<PhasesReorderFormFields>
): BptTemplatePhasesOrderInput => {
  const out: BptTemplatePhasesOrderInput = {};
  if (formData.phases != null) {
    const phases = formData.phases as BptTemplatePhase[];
    const phaseIds = phases.map((x) => x.id);
    set(out, "phaseIds", phaseIds);
  }

  return out;
};
