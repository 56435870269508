import cc from "classcat";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface LoadingIndicatorProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  centered?: boolean;
  className?: string;
  message?: string;
  visible?: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const { t } = useTranslation();
  const {
    centered,
    className,
    message = t("loading"),
    visible = false,
  } = props;

  const _className = React.useMemo(
    () =>
      cc([
        {
          "width-100 flex justify-center opct-90 align-center": centered,
          "opct-90 align-center": !centered,
        },
        className,
      ]),
    [centered, className]
  );

  return (
    <span className={_className}>
      <i className="fa fa-spinner fa-spin" />
      {/* <i className="fa fa-sync fa-spin"></i> */}
      {/* <i className="fa fa-sync-alt fa-spin fa-2x"></i> */}
      {visible ? (
        <span>&nbsp;{message}...</span>
      ) : (
        <span className="wb-inv">&nbsp;{message}...</span>
      )}
    </span>
  );
};

export default LoadingIndicator;
