import * as React from "react";
import { useTranslation } from "react-i18next";
import EditResponseStatementModal, {
  EditResponseStatementModalProps,
  useResponseStatementModal,
} from ".";

interface EditResponseStatementModalButtonProps
  extends Omit<EditResponseStatementModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditResponseStatementModalButton: React.FC<
  EditResponseStatementModalButtonProps
> = ({ buttonLabel, ...props }) => {
  const { t } = useTranslation();
  const modalState = useResponseStatementModal({});

  const label = buttonLabel ?? t("edit");
  const validResponseStatementId =
    props.responseStatementId != null && props.responseStatementId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link hover-underline"
        onClick={modalState.open}
        disabled={!validResponseStatementId}
        data-testid={"button-edit-response-statement"}
      >
        <span className="fas fa-pen mrgn-rght-sm" />
        {label}
      </button>
      {validResponseStatementId ? (
        <EditResponseStatementModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditResponseStatementModalButton;
