import { useQuery } from "@apollo/client";
import PlaceholderImage from "assets/svg/placeholder.svg";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import RelatedSpeciesListItem from "components/molecules/listing/relatedSpecies/RelatedSpeciesListItem";
import RelatedSpeciesWarning from "components/molecules/listing/relatedSpecies/relatedSpeciesWarning";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { useOverviewContext } from "features/overview";
import {
  ListingWs,
  ListingWsProcess,
  ListingWsViewChildrenDocument,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { getSortByOrder } from "util/relatedSpecies/listSortBy";
import { ListingPathHelpers } from "../../ListingRouter";

export interface ViewChildrenProps {}

const ViewChildren: React.FC<ViewChildrenProps> = () => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;

  const pagination = usePagination();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortByInput, setSortByInput] = React.useState("commonName");
  const sortBy = getSortByOrder(sortByInput, sortOrder, i18n.language);

  const { data, loading, error } = useQuery(ListingWsViewChildrenDocument, {
    variables: {
      params: {
        id: listingWsId,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        sortBy,
      },
    },
  });

  const onChangeSortBy = async (e: any) => {
    setSortByInput(e.currentTarget.value);
    pagination.goToPage(1);
  };

  const onChangeSortOrder = (e: any) => {
    setSortOrder(e.currentTarget.value);
  };

  const viewChildrenListItems = data?.listingWsChildren?.items;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("view_children")}</h1>
          <GraphqlError errors={error} />
          <RelatedSpeciesWarning listingWsId={listingWsId} />
          <div id="dataset_filter" className="Card mrgn-tp-md">
            {/*instant filters:*/}
            <div className="filter-controls">
              <section className="flex justify-between align-center flex-wrap mrgn-bttm-md">
                <div className="flex">
                  <div className="flex-col">
                    <label htmlFor="sel_sort_by">{t("sort_by")}</label>
                    <select
                      id="sel_sort_by"
                      className="form-control mrgn-rght-md"
                      value={sortByInput}
                      onChange={onChangeSortBy}
                    >
                      {/*TODO:enum members*/}
                      <option value="commonName">{t("common_name")}</option>
                      <option value="scientificName">
                        {t("scientific_name")}
                      </option>
                      <option value="population">{t("population")}</option>
                      <option value="listingId">{t("listing_id")}</option>
                    </select>
                  </div>

                  <div className="flex-col">
                    <label htmlFor="sel_sort_order">{t("sort_order")}</label>
                    <select
                      id="sel_sort_order"
                      className="form-control"
                      value={sortOrder}
                      onChange={onChangeSortOrder}
                    >
                      <option value="asc">{t("ascending")}</option>
                      <option value="desc">{t("descending")}</option>
                    </select>
                  </div>
                </div>

                <div className="flex-col align-end justify-between flex-auto">
                  {/* <p>Export to Excel</p> */}
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                  <div className="font-size-16">
                    {pagination.makeShowingString(
                      data?.listingWsChildren?.pagination?.totalCount
                    )}
                  </div>
                </div>
              </section>
            </div>

            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !viewChildrenListItems ||
              viewChildrenListItems?.length === 0 ? (
              <NoResults centered />
            ) : (
              viewChildrenListItems?.map((item) => {
                const listingWs = item?.listingWs as ListingWs; // Only for type conversion
                const latestListingProcess =
                  item?.latestListingWsProcess as ListingWsProcess;
                return (
                  <div className="list-item separator-line" key={listingWs?.id}>
                    <RelatedSpeciesListItem
                      listingWs={listingWs}
                      listingProcessLatest={latestListingProcess}
                      titleLink={ListingPathHelpers.ListingProfile(
                        listingWs?.id ?? "error"
                      )}
                      imgUrl={PlaceholderImage}
                      showImage={false}
                    />
                  </div>
                );
              })
            )}

            <Pagination
              {...pagination.paginationComponentProps}
              totalPages={data?.listingWsChildren?.pagination?.totalPages ?? 0}
            />
          </div>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ViewChildren;
