import { get } from "lodash";
import * as React from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "./FieldValidationError";
import ProvinceTerritoryDropdownWithController from "components/organisms/contacts/ProvinceTerritoryDropdown/ProvinceTerritoryDropdown";
import {
  Maybe,
  ProvinceTerritoryLookupListDocument,
} from "generated/gql-types";
import { useQuery } from "@apollo/client";
import i18n from "i18n";
import LoadingIndicator from "../LoadingIndicator";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface ProvinceTerritoryInputProps<TFieldValues extends FieldValues>
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  control: Control<TFieldValues | any>;
  register: UseFormRegister<TFieldValues | any>;
  countryCode: Maybe<string | undefined>;
  isRequired: boolean;
  disabled: boolean;
  errors?: FieldErrors;
}

export const ProvinceTerritoryInput = React.forwardRef(
  (
    {
      name,
      control,
      register,
      countryCode,
      isRequired,
      disabled,
      errors,
      children,
      ...props
    }: ProvinceTerritoryInputProps<FieldValues>,
    ref
  ) => {
    const { t } = useTranslation();

    const {
      loading: loadingProvinceTerritory,
      error: errorProvinceTerritory,
      data: dataProvinceTerritory,
    } = useQuery(ProvinceTerritoryLookupListDocument, {
      errorPolicy: "all",
      variables: {
        params: {
          category: "iso3166_subdivisions",
          pageNumber: 1,
          pageSize: 200,
          sortBy:
            i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
          tags: countryCode,
        },
      },
      skip: isNullOrEmpty(countryCode),
      fetchPolicy: "cache-first",
    });

    if (loadingProvinceTerritory)
      return <LoadingIndicator className="mrgn-lft-sm" />;

    // TODO: better error handling; send the error to a page error context or something?
    if (errorProvinceTerritory) {
      console.error(errorProvinceTerritory);
      return <p>{t("loading_error")}</p>;
    }

    if (
      dataProvinceTerritory?.lookupList?.lookups != null &&
      dataProvinceTerritory?.lookupList?.lookups?.length <= 0
    ) {
      isRequired = false;
      disabled = true;
    }

    return (
      <div className="form-group col-md-6">
        <ProvinceTerritoryDropdownWithController
          control={control}
          name={name}
          id="iso3166_subdivisions"
          required={isRequired}
          disabled={disabled}
          countryCode={countryCode}
        />
        {get(errors, name) && (
          <FieldValidationError>
            {get(errors, name).message}
          </FieldValidationError>
        )}
      </div>
    );
  }
);
