import { Roles, ROLE_ACTIONS } from "./roles";
import type { ReactElement } from "react";
import { useUserAuthorization } from "./useUserAuthorization";

export { Roles, ROLE_ACTIONS };

export interface RenderWhenAuthorizedProps {
  authorizedRoles: Roles[];
  fallbackComponent?: ReactElement;
}

/**
 * Render the child component ONLY when the current user has all of the specified authorizedRoles.
 * @param authorizedRoles roles authorized to view the child components.
 * @param fallbackComponent Optional fallback component; Renders when the user IS NOT authorized. In most cases, <RenderWhenForbidden /> should be used instead.
 * @param children React children to render
 */
export const RenderWhenAuthorized: React.FC<RenderWhenAuthorizedProps> = ({
  authorizedRoles,
  fallbackComponent,
  children,
}) => {
  const { checkUserIsAuthorized } = useUserAuthorization();
  const isAuthorized = checkUserIsAuthorized(authorizedRoles);

  if (isAuthorized) {
    return <>{children}</>;
  }

  if (fallbackComponent != null) {
    return fallbackComponent;
  }

  return null;
};

// commented out since we don't need it at the moment
// export interface RenderWhenForbiddenProps {
//   authorizedRoles: Roles[];
//   fallbackComponent?: ReactElement;
// }
//
// /**
//  * Render the child component ONLY when the current user lacks any of the specified authorizedRoles.
//  * @param authorizedRoles roles authorized to view the child components.
//  * @param fallbackComponent Optional fallback component; Renders when the user IS authorized. In most cases, <RenderWhenAuthorized /> should be used instead.
//  * @param children React children to render.
//  */
// export const RenderWhenForbidden: React.FC<RenderWhenForbiddenProps> = ({
//   authorizedRoles,
//   fallbackComponent,
//   children,
// }) => {
//   const { userRoles } = useUserAuthorization();
//
//   const isAuthorized = useMemo(
//     () => checkIsAuthorized(userRoles, authorizedRoles),
//     [authorizedRoles, userRoles]
//   );
//
//   if (!isAuthorized) {
//     return <>{children}</>;
//   }
//
//   if (fallbackComponent != null) {
//     return fallbackComponent;
//   }
//
//   return null;
// };
