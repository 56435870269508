import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
} from "@react-stately/table";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import MissingData from "../../../atoms/MissingData";
import Table, { TableProps } from "../../Table";

export type ColumnHeader = {
  name: string;
  key: string;
  sortable?: boolean;
  hasLink?: boolean;
  LinkOpenTab?: boolean;
  isHTML?: boolean;
  isCallback?: boolean;
  callbackFn?: (item: any) => JSX.Element;
  minWidth?: number;
  maxWidth?: number;
};

export interface ResultsTableProps extends Omit<TableProps, "children"> {
  columns: ColumnHeader[];
  sortable?: boolean;
  rows: any[];
}

export const ResultsTable: React.FC<ResultsTableProps> = (props) => {
  const { t } = useTranslation();

  const buildCellString = (item: any, columnKey: React.Key) => {
    let resultString = item[columnKey];
    const columnHeader = props.columns.find((x) => x.key === columnKey);

    // ======== Run Callback function ========
    if (columnHeader?.isCallback) {
      return columnHeader.callbackFn != null
        ? columnHeader.callbackFn(item)
        : resultString;
    }

    // ======== Show HTML format ========
    if (columnHeader?.isHTML && !isNullOrEmpty(resultString)) {
      resultString = <SafeRenderHtml htmlString={resultString} />;
    }

    // ======== Show No Data if null or empty ========
    if (!isNullOrEmpty(resultString)) {
      resultString = <span>{resultString}</span>;
    } else {
      resultString = <MissingData />;
    }

    // ======== Include a link ========
    if (columnHeader?.hasLink && item._href != null) {
      if (columnHeader.LinkOpenTab) {
        resultString = (
          <Link to={item._href} target="_blank" rel="noopener noreferrer">
            {resultString}
          </Link>
        );
      } else {
        resultString = <Link to={item._href}>{resultString}</Link>;
      }
    }
    return resultString;
  };

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Table {...props}>
      <TableHeader columns={props.columns}>
        {(col) => (
          <Column
            allowsSorting={props.sortable && col.sortable}
            minWidth={col.minWidth}
          >
            {col.name}
          </Column>
        )}
      </TableHeader>
      <TableBody items={props.rows}>
        {(item: any) => (
          <Row>
            {(columnKey) => <Cell>{buildCellString(item, columnKey)}</Cell>}
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

export default ResultsTable;
