import { useQuery } from "@apollo/client";
import { getApiListingServiceUrl } from "azure/environment";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import ResultsTable, {
  ColumnHeader,
} from "components/organisms/search/ResultsTable/ResultsTable";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { useOverviewContext } from "features/overview";
import { SortDescriptor } from "features/search/utils/makeOrderByString";
import { OverviewWsResponseStatementsDocument } from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import useQueryParams from "hooks/util/useQueryParams";
import i18n from "i18n";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";

interface ResponseStatementDocumentPageColumns {
  id: string;
  "Title/English/Text"?: string;
  "Title/French/Text"?: string;
  State?: string;
  PublicationDate?: string;
}

const ResponseStatementsDocumentsPage: React.FC = (props) => {
  const { t } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;
  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const lowercaseOverviewProgram = String(overviewProgram).toLowerCase();
  const listingWsRefIdForLegacy =
    lowercaseOverviewProgram === "listingws" ? overviewId : undefined;
  const cosewicWsRefIdForLegacy =
    lowercaseOverviewProgram === "cosewicws" ? overviewId : undefined;

  const { data, loading, error } = useQuery(
    OverviewWsResponseStatementsDocument,
    {
      errorPolicy: "all",
      variables: {
        referenceId: overviewId,
        referenceName: overviewProgram,
        listingWsRefIdForLegacy,
        cosewicWsRefIdForLegacy,
      },
      skip: isNullOrEmpty(overviewId) || isNullOrEmpty(overviewProgram),
      fetchPolicy: "network-only",
    }
  );

  // Update Ribbon and SideNav.
  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId!);
  }, [overviewProgram, overviewId]);

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("name"),
        key:
          i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text",
        sortable: false,
        hasLink: true,
        LinkOpenTab: true,
      },

      {
        name: t("state"),
        key: "State",
        sortable: false,
      },
      {
        name: t("publication_date"),
        key: "PublicationDate",
        sortable: false,
      },
    ],
    [t]
  );

  const rows: Array<ResponseStatementDocumentPageColumns> | any = useMemo(
    () =>
      data?.overviewWsResponseStatements?.map((value: any) => {
        let responseStatementName = "";
        if (i18n.language === "fr") {
          responseStatementName = value?.title?.french?.text;
        } else {
          responseStatementName = value?.title?.english?.text;
        }

        return {
          _href: `/responseStatement/${value?.id}`,
          id: value?.id,
          [i18n.language === "fr" ? "Title/French/Text" : "Title/English/Text"]:
            responseStatementName,
          State: t(value?.state),
          PublicationDate: formatTimestamp(value?.publishedDate),
        };
      }),
    [data?.overviewWsResponseStatements]
  );

  const hasLegacyResponseStatements = !isNullOrEmpty(
    data?.legacyResponseStatementList?.responseStatements
  );

  return (
    <>
      {loading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : rows == null || rows?.length <= 0 ? (
        <NoResults centered />
      ) : error ? (
        <pre>{JSON.stringify(error)}</pre>
      ) : (
        <div className="mrgn-tp-lg">
          <ResultsTable
            rows={rows}
            columns={columns}
            sortable
            sortDescriptor={sortDescriptor}
            onSortChange={onSortChange as any}
          />
        </div>
      )}

      {hasLegacyResponseStatements ? (
        <>
          <div className="flex justify-between">
            <h2>{t("legacy_response_statements")}</h2>
          </div>

          {data?.legacyResponseStatementList?.responseStatements.map(
            (x, index) => {
              if (!x) return null;

              const attachmentMapper = (att: any) => {
                const fileHref = `${getApiListingServiceUrl()}legacyresponsestatement/${
                  x.id
                }/pdf`;
                const htmlHref = `${getApiListingServiceUrl()}legacyresponsestatement/${
                  x.id
                }/html`;
                const rowId = "attachment_" + att.id;

                return (
                  <div className="font-size-16" key={rowId} id={rowId}>
                    <span>
                      {!isNullOrEmpty(att?.file) ? (
                        <a
                          href="#"
                          onClick={() =>
                            fetchDownload(fileHref, "html", i18n.language)
                          }
                        >
                          pdf
                        </a>
                      ) : (
                        <span className="text-muted">html</span>
                      )}
                      {" | "}
                      {!isNullOrEmpty(att?.html) ? (
                        <a
                          href="#"
                          onClick={() =>
                            fetchDownload(htmlHref, "pdf", i18n.language)
                          }
                        >
                          html
                        </a>
                      ) : (
                        <span className="text-muted">html</span>
                      )}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span>
                      {att?.title} ({formatTimestamp(att.publicationDate)})
                    </span>
                  </div>
                );
              };

              return (
                <div key={index}>
                  {x?.englishAttachments?.map(attachmentMapper)}
                  {x?.frenchAttachments?.map(attachmentMapper)}
                </div>
              );
            }
          )}
        </>
      ) : null}
    </>
  );
};

export const fetchDownload = async (
  downloadUrl: string,
  fileExtension: string,
  languageCode?: string
) => {
  let outputFileName =
    "sardmts-download-" + new Date().toDateString() + fileExtension; // default fallback filename
  const token = await getUserAccessToken();

  const languageHeader = languageCode === "fr" ? "fr-CA" : "en-CA";

  return await fetch(downloadUrl, {
    method: "get",
    headers: {
      "Accept-Language": languageHeader,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }

      const contentDispositionHeader = res.headers.get("Content-Disposition");

      if (contentDispositionHeader != null) {
        const pattern = /filename=(?<filename>.+.\w+);/g;
        const groups = pattern.exec(contentDispositionHeader)?.groups;

        if (
          groups != null &&
          groups.filename != null &&
          groups.filename.length > 0
        ) {
          outputFileName = groups.filename;
        }
      }

      return res;
    })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
};

export default ResponseStatementsDocumentsPage;
