import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import PhotosByLicenseTypeCard from "components/organisms/documents/PhotoLicense/PhotosByLicenseTypeCard/PhotosByLicenseTypeCard";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  PhotoGallery,
  PhotoGalleryByReferenceDocument,
  PhotoLicenseType,
} from "generated/gql-types";
import useQueryParams from "hooks/util/useQueryParams";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const GalleryPageByReference: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;

  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId);
  }, [overviewProgram, overviewId]);

  const { loading, error, data } = useQuery(PhotoGalleryByReferenceDocument, {
    errorPolicy: "all",
    variables: {
      referenceId: overviewId as any,
      referenceName: overviewProgram as any,
    },
    skip: overviewId == null || overviewProgram == null,
    fetchPolicy: "network-only",
  });

  const photoGalleryData = data?.photoGalleryByReference as PhotoGallery[];

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("gallery")}</h1>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <GraphqlError
                title="Error fetching PhotoGalleryByReference"
                errors={error}
              />
              <PhotosByLicenseTypeCard
                photoLicenseType={PhotoLicenseType.FullUse}
                photoGalleryData={photoGalleryData}
              />
              <PhotosByLicenseTypeCard
                photoLicenseType={PhotoLicenseType.LimitedUse}
                photoGalleryData={photoGalleryData}
              />
              <PhotosByLicenseTypeCard
                photoLicenseType={PhotoLicenseType.OneTimeUse}
                photoGalleryData={photoGalleryData}
              />
              <PhotosByLicenseTypeCard
                photoLicenseType={PhotoLicenseType.CrownCopyright}
                photoGalleryData={photoGalleryData}
              />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default GalleryPageByReference;
