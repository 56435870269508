import {
  CreateRegistryPageInput,
  Maybe,
  RegistryPage,
} from "generated/gql-types";
import * as FormMappers from "util/formMappers";
import { RegistryPageFormFields } from "./RegistryPageForm";

export const mapDomainModelToForm = (
  domainModel: Maybe<Partial<RegistryPage>>
) => {
  const out: Partial<RegistryPageFormFields> = {};

  FormMappers.mapBilingualRichText(out, "title", domainModel?.title);
  FormMappers.mapBilingualRichText(
    out,
    "description",
    domainModel?.description
  );

  FormMappers.mapArrayUsingFn(
    FormMappers.mapDocumentRef,
    out,
    `[documentRefs]`,
    domainModel?.documentRefs
  );

  FormMappers.mapCustomContact(
    out,
    "contact",
    domainModel?.contactRef,
    domainModel?.organizationRef
  );

  FormMappers.mapArrayUsingFn(
    FormMappers.mapBilingualUriReference,
    out,
    `[relatedResources]`,
    domainModel?.relatedResources && domainModel?.relatedResources.length > 0
      ? domainModel?.relatedResources
      : [
          {
            enReference: { name: { text: "" }, uri: "" },
            frReference: { name: { text: "" }, uri: "" },
          },
        ]
  );

  return out;
};

export function mapFormToDomainModel(form: Partial<RegistryPageFormFields>) {
  const out: Partial<CreateRegistryPageInput> = {};

  FormMappers.mapBilingualRichTextInput(out, "title", form.title);
  FormMappers.mapBilingualRichTextInput(out, "description", form.description);
  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapDocumentRefInput,
    out,
    `[documentRefs]`,
    form.documentRefs
  );

  FormMappers.mapCustomContactInput(out, "", form?.contact);

  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapBilingualUriReferenceInput,
    out,
    `[relatedResources]`,
    form.relatedResources
  );

  return out;
}
