import { ResponseStatementReferenceData } from "generated/gql-types";
import i18n from "i18n";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

export const getCosewicCommonNameWithPopulation = (
  ResponseStatementReferenceData:
    | ResponseStatementReferenceData
    | null
    | undefined
) => {
  if (ResponseStatementReferenceData?.cosewicRef?.name == null) return "";

  let commonNameWithPopulation;

  const species = ResponseStatementReferenceData.cosewicRef.name;
  if (i18n.language === "fr") {
    commonNameWithPopulation =
      species?.commonNameFrench?.name?.text ??
      species?.commonNameEnglish?.name?.text ??
      "Error";
    commonNameWithPopulation = htmlRemoveOuterPTag(commonNameWithPopulation);
    if (species?.population?.nameFr || species?.population?.nameEn) {
      commonNameWithPopulation +=
        ", " + species.population.nameFr ?? species.population.nameEn;
    }
  } else {
    commonNameWithPopulation = species?.commonNameEnglish?.name?.text;
    commonNameWithPopulation = htmlRemoveOuterPTag(commonNameWithPopulation);
    if (species?.population?.nameEn || species?.population?.nameFr) {
      commonNameWithPopulation +=
        ", " + species.population.nameEn ?? species.population.nameFr;
    }
  }
  return commonNameWithPopulation;
};
