import { useMutation, useQuery } from "@apollo/client";
import PlaceholderImage from "assets/svg/placeholder.svg";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { NoResults } from "components/atoms/NoResults";
import Layout from "components/layouts/TwoColumn";
import { PageSizeSelect } from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SideNav from "components/molecules/SideNav";
import RelatedSpeciesListItem from "components/molecules/cosewic/RelatedSpeciesListItem";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  Assessment,
  AssessmentStage,
  AssessmentState,
  CosewicSiblingsByParentDocument,
  CosewicWs,
  UpdateSiblingsDocument,
  ViewChildrenDocument,
} from "generated/gql-types";
import useCosewicPaginatedRowSelect from "hooks/util/useCosewicPaginatedRowSelect";
import { usePagination } from "hooks/util/usePagination";
import i18n from "i18n";
import { difference } from "lodash";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { getSortByOrder } from "util/relatedSpecies/listSortBy";
import SectionCard from "../../../components/atoms/SectionCard";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../../features/auth/components";

export interface ViewChildrenProps {}

const ViewChildren: React.FC<ViewChildrenProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const alertContext = useGlobalAlertContext();
  const pagination = usePagination();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortByInput, setSortByInput] = React.useState("commonName");
  const sortBy = getSortByOrder(sortByInput, sortOrder, i18n.language);

  const { data, loading, error } = useQuery(ViewChildrenDocument, {
    variables: {
      params: {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        cosewicId: cosewicWsId,
        assessmentStage: AssessmentStage.Assessed,
        assessmentState: AssessmentState.Published,
        sortBy,
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: siblingsData } = useQuery(CosewicSiblingsByParentDocument, {
    variables: {
      id: cosewicWsId,
    },
  });

  const [runUpdateSiblings, updateSiblingsStatus] = useMutation(
    UpdateSiblingsDocument
  );

  const paginatedRowSelect = useCosewicPaginatedRowSelect(
    (data?.cosewicWsChildren?.items as any) ?? undefined,
    (siblingsData?.cosewicWsSiblingsByParent?.siblingIds as any) ?? undefined
  );

  const onUpdateSiblings = async () => {
    const siblingIdsByParent =
      siblingsData?.cosewicWsSiblingsByParent?.siblingIds ?? [];

    // Prevent submitting when the list has no selections stored in the database and when the user has selected fewer than 2 list items.
    // The submit button is also disabled based on the same rules.
    if (
      siblingIdsByParent.length === 0 &&
      paginatedRowSelect.selectedRowIds.length < 2
    ) {
      console.log(
        "Bailing out of update siblings: No prior siblings exist, or less than 2 siblings were selected."
      );
      return;
    }

    const siblingsToBeAdded = difference<string>(
      paginatedRowSelect.selectedRowIds,
      siblingIdsByParent as any
    );

    const siblingsToBeRemoved = difference<string>(
      siblingIdsByParent as any,
      paginatedRowSelect.selectedRowIds
    );

    try {
      await runUpdateSiblings({
        variables: {
          id: cosewicWsId,
          input: {
            siblingsToBeAdded:
              siblingsToBeAdded.length > 0 ? siblingsToBeAdded : undefined,
            siblingsToBeRemoved:
              siblingsToBeRemoved.length > 0 ? siblingsToBeRemoved : undefined,
          },
        },
      });

      alertContext.showSuccess({
        message: t("sibling_update_success_msg"),
      });
    } catch (e) {}
  };

  const onChangeSortBy = async (e: any) => {
    setSortByInput(e.currentTarget.value);
    pagination.goToPage(1);
  };

  const onChangeSortOrder = (e: any) => {
    setSortOrder(e.currentTarget.value);
  };

  // const viewChildrenListItems = data?.cosewicWsChildren?.cosewicWs;
  const viewChildrenListItems = data?.cosewicWsChildren?.items;
  const updateSiblingsDisabled =
    siblingsData?.cosewicWsSiblingsByParent?.siblingIds?.length === 0 &&
    paginatedRowSelect.selectedRowIds.length < 2;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          {/*TODO: i18n*/}
          <h1>{t("view_children")}</h1>
          <GlobalAlert />
          <GraphqlError errors={error} />
          <GraphqlError errors={updateSiblingsStatus.error} />
          <SectionCard
            id="dataset_filter"
            header={
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.cosewic.manageRelatedSpecies}
              >
                <div className="flex justify-between mrgn-bttm-md">
                  <h2 aria-hidden hidden>
                    {t("update_siblings")}
                  </h2>
                  <button
                    className="btn btn-default btn-sm mrgn-rght-md"
                    onClick={onUpdateSiblings}
                    disabled={updateSiblingsDisabled}
                    data-testid="button-update-siblings"
                  >
                    {t("update_siblings")}
                  </button>
                  <div className="checkbox">
                    <label htmlFor="chkbox-all-sibling">
                      <input
                        type="checkbox"
                        name="chkbox-all-sibling"
                        id="chkbox-all-sibling"
                        checked={paginatedRowSelect.selectAllEnabled}
                        onChange={paginatedRowSelect.onToggleSelectAll}
                      />
                      {t("select_all_siblings")}
                    </label>
                  </div>
                </div>
              </RenderWhenAuthorized>
            }
          >
            {/*instant filters:*/}
            <div className="filter-controls">
              <section className="flex justify-between mrgn-bttm-md">
                <div className="flex">
                  <div className="flex-col">
                    <label htmlFor="sel_sort_by">{t("sort_by")}</label>
                    <select
                      id="sel_sort_by"
                      className="form-control mrgn-rght-md"
                      value={sortByInput}
                      onChange={onChangeSortBy}
                    >
                      {/*TODO:enum members*/}
                      <option value="commonName">{t("common_name")}</option>
                      <option value="cosewicId">{t("cosewic_id")}</option>
                    </select>
                  </div>

                  <div className="flex-col">
                    <label htmlFor="sel_sort_order">{t("sort_order")}</label>
                    <select
                      id="sel_sort_order"
                      className="form-control"
                      value={sortOrder}
                      onChange={onChangeSortOrder}
                    >
                      <option value="asc">{t("ascending")}</option>
                      <option value="desc">{t("descending")}</option>
                    </select>
                  </div>
                </div>

                <div className="flex">
                  {/* <p>Export to Excel</p> */}
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
              </section>
            </div>

            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !viewChildrenListItems ||
              viewChildrenListItems?.length === 0 ? (
              <NoResults centered />
            ) : (
              viewChildrenListItems?.map((item) => {
                const cosewicWs = item?.cosewicWs as CosewicWs; // Only for type conversion
                const latestAssessment = item?.latestAssessment as Assessment;
                return (
                  <div
                    className="list-item separator-line"
                    key={item?.cosewicWs?.id}
                  >
                    <RelatedSpeciesListItem
                      cosewicWs={cosewicWs}
                      cosewicWsAssessmentLatest={latestAssessment}
                      titleLink={CosewicPathHelpers.CosewicProfile(
                        item?.cosewicWs?.id ?? "error"
                      )}
                      imgUrl={PlaceholderImage}
                      showImage={false}
                    >
                      <RenderWhenAuthorized
                        authorizedRoles={ROLE_ACTIONS.cosewic.manageRelatedSpecies}
                      >
                        <div className="checkbox">
                          <label
                            htmlFor={"chkbox-sibling-" + item?.cosewicWs?.id}
                          >
                            {/*TODO:i18n*/}
                            <input
                              type="checkbox"
                              name={"chkbox-sibling-" + item?.cosewicWs?.id}
                              id={"chkbox-sibling-" + item?.cosewicWs?.id}
                              checked={paginatedRowSelect.selectedRowIds.includes(
                                item!.cosewicWs!.id
                              )}
                              onChange={() =>
                                paginatedRowSelect.onToggleRow(
                                  item?.cosewicWs?.id
                                )
                              }
                            />
                            {t("sibling")}
                          </label>
                        </div>
                      </RenderWhenAuthorized>
                    </RelatedSpeciesListItem>
                  </div>
                );
              })
            )}

            <Pagination
              {...pagination.paginationComponentProps}
              totalPages={data?.cosewicWsChildren?.pagination?.totalPages ?? 0}
            />
          </SectionCard>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ViewChildren;
