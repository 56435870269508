import { useQuery } from "@apollo/client";
import EditLink from "components/atoms/EditLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import {
  Document,
  DocumentRegistryPageDocument,
  DocumentStage,
  Maybe,
} from "generated/gql-types";
import i18n from "i18n";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import isNullOrEmpty from "util/isNullOrEmpty";

interface DocumentInfoCardProps {
  documentData?: Maybe<Document>;
}

export const DocumentInfoCard: React.FC<DocumentInfoCardProps> = (props) => {
  const { documentData } = props;
  const { t } = useTranslation();

  const documentType =
    i18n.language === "fr"
      ? documentData?.documentType?.name?.french
      : documentData?.documentType?.name?.english;

  const programs = documentData?.programs
    ?.map((x) => (i18n.language === "fr" ? x?.name?.french : x?.name?.english))
    .join(", ");

  const {
    data: dataRegistryPage,
    loading: loadingRegistryPage,
    error: errorRegistryPage,
  } = useQuery(DocumentRegistryPageDocument, {
    variables: {
      documentId: documentData?.id ?? "error",
    },
  });

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("document_information")}</h2>
          {documentData?.stage === DocumentStage.Active && (
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.documents.update}
            >
              <EditLink
                linkTo={DocumentsPathHelpers.DocumentsEdit(
                  documentData?.id ?? "error"
                )}
                disabled={documentData?.id == null}
              />
            </RenderWhenAuthorized>
          )}
        </div>
      }
    >
      <div className="species-field">
        <div className="row">
          <dl>
            {/* ------------ Document Type ------------ */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("document_type")}</dt>
              <dd>{documentType == null ? <MissingData /> : documentType}</dd>
            </div>
            {/* ------------ Created by ------------ */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("created_by")}</dt>
              <dd>
                {isNullOrEmpty(documentData?.createdBy) ? (
                  <MissingData />
                ) : (
                  documentData?.createdBy
                )}
              </dd>
            </div>

            {/* ------------ Created Date ------------ */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("creation_date")}</dt>

              <dd>
                {isNullOrEmpty(documentData?.createdAt) ? (
                  <MissingData />
                ) : (
                  formatTimestamp(documentData?.createdAt)
                )}
              </dd>
            </div>
          </dl>
        </div>

        <div className="row">
          <dl>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("content_owner")}</dt>
              <dd>
                {documentData?.contentOwner == null ? (
                  <MissingData />
                ) : (
                  t(documentData?.contentOwner)
                )}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("document_id")}</dt>
              <dd>
                {documentData?.documentId == null ? (
                  <MissingData />
                ) : (
                  documentData?.documentId
                )}
              </dd>
            </div>
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("programs")}</dt>
              <dd>{programs == null ? <MissingData /> : programs}</dd>
            </div>
          </dl>
        </div>

        {/* ------------ Associated wildlife species ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("associated_wildlife_species")}</dt>
              <dd>
                {documentData?.associatedSpecies == null ||
                documentData?.associatedSpecies.length <= 0 ? (
                  <MissingData />
                ) : (
                  documentData?.associatedSpecies?.map((x, index) => (
                    <div key={`associated-species-${index}`}>
                      {x?.listingRefId == null ? (
                        <>
                          <Link
                            to={CosewicPathHelpers.CosewicProfile(
                              x?.cosewicRefId?.id ?? "error"
                            )}
                            data-testid={`associated-species-${index}`}
                          >
                            <SafeRenderHtml
                              htmlString={
                                i18n.language === "fr"
                                  ? htmlRemoveOuterPTag(
                                      x?.commonNameFrench?.name?.text
                                    ) +
                                    (x?.population?.nameFr
                                      ? ", " + x?.population?.nameFr!
                                      : "")
                                  : htmlRemoveOuterPTag(
                                      x?.commonNameEnglish?.name?.text
                                    ) +
                                    (x?.population?.nameEn
                                      ? ", " + x?.population?.nameEn!
                                      : "")
                              }
                            />
                          </Link>
                          <br />
                        </>
                      ) : (
                        <>
                          <Link
                            to={ListingPathHelpers.ListingProfile(
                              x.listingRefId.id ?? "error"
                            )}
                            data-testid={`associated-species-${index}`}
                          >
                            <SafeRenderHtml
                              htmlString={
                                i18n.language === "fr"
                                  ? htmlRemoveOuterPTag(
                                      x?.commonNameFrench?.name?.text
                                    ) +
                                    (x?.population?.nameFr
                                      ? ", " + x?.population?.nameFr!
                                      : "")
                                  : htmlRemoveOuterPTag(
                                      x?.commonNameEnglish?.name?.text
                                    ) +
                                    (x?.population?.nameEn
                                      ? ", " + x?.population?.nameEn!
                                      : "")
                              }
                            />
                          </Link>
                          <br />
                        </>
                      )}
                    </div>
                  ))
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Registry page ------------ */}
        {loadingRegistryPage || errorRegistryPage ? (
          <div className="mrgn-bttm-md">
            <span className="font-size-14 text-muted mrgn-rght-md">
              {t("registry_page")}
            </span>
            {loadingRegistryPage ? (
              <LoadingIndicator />
            ) : (
              <span className="label label-danger font-size-12">
                {t("fetch_fail")}
              </span>
            )}
          </div>
        ) : (
          dataRegistryPage?.documentRegistryPage?.id && (
            <div className="row">
              <dl>
                <div className="species-data col-sm-12">
                  <dt className="text-muted">{t("registry_page")}</dt>
                  <dd>
                    <Link
                      to={DocumentsPathHelpers.RegistryProfile(
                        dataRegistryPage.documentRegistryPage.id
                      )}
                      data-testid={`link-registry-page`}
                    >
                      <SafeRenderHtml
                        htmlString={
                          i18n.language === "fr"
                            ? htmlRemoveOuterPTag(
                                dataRegistryPage.documentRegistryPage?.title
                                  ?.french?.text
                              )
                            : htmlRemoveOuterPTag(
                                dataRegistryPage.documentRegistryPage?.title
                                  ?.english?.text
                              )
                        }
                      />
                    </Link>
                  </dd>
                </div>
              </dl>
            </div>
          )
        )}

        {/* ------------ Note ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("note")}</dt>
              <dd>
                {htmlIsNullOrEmpty(documentData?.note?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={documentData?.note?.text ?? ""} />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default DocumentInfoCard;
