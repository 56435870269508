// Mapping Listing key (from response) to tag (for display)
// Used in React-Query-Builder field selector.
import i18n from "i18n";

const listingKeyToTag = new Map([
  ["Listing/ListingIdStr", "listing_id"],
  ["Listing/LatestGicDecision/NameEn", "latest, gic_decision, english"],
  ["Listing/LatestGicDecision/NameFr", "latest, gic_decision, french"],
  ["Listing/DateSentToMinister", "date_sent_to_minister"],

  ["Listing/CommonNameEnglish/Name", "common_name, english"],
  ["Listing/CommonNameFrench/Name", "common_name, french"],
  ["Listing/NameWithComma/Name", "name_with_comma"],
  ["Listing/ScientificName/GeneratedScientificName/Name", "scientific_name"],
  ["Listing/TaxonomicGroup/NameEn", "taxonomic_group, english"],
  ["Listing/TaxonomicGroup/NameFr", "taxonomic_group, french"],
  ["Listing/Population/NameEn", "population, english"],
  ["Listing/Population/NameFr", "population, french"],

  ["Listing/LegalProtection/Jurisdiction/NameEn", "jurisdiction, english"],
  ["Listing/LegalProtection/Jurisdiction/NameFr", "jurisdiction, french"],
  [
    "Listing/LegalProtection/ProvincialAndTerritorialLegislations/NameEn",
    "provincial_territorial_legislation, english",
  ],
  [
    "Listing/LegalProtection/ProvincialAndTerritorialLegislations/NameFr",
    "provincial_territorial_legislation, french",
  ],
  [
    "Listing/LegalProtection/OtherFederalProtections/NameEn",
    "other_federal_protection, english",
  ],
  [
    "Listing/LegalProtection/OtherFederalProtections/NameFr",
    "other_federal_protection, french",
  ],
  [
    "Listing/LegalProtection/AdditionalProtectionUnderSara/NameEn",
    "additional_protection_under_sara, english",
  ],
  [
    "Listing/LegalProtection/AdditionalProtectionUnderSara/NameFr",
    "additional_protection_under_sara, french",
  ],
  [
    "Listing/ConsultationConsideration/Stakeholders/NameEn",
    "stakeholders, english",
  ],
  [
    "Listing/ConsultationConsideration/Stakeholders/NameFr",
    "stakeholders, french",
  ],
  [
    "Listing/ConsultationConsideration/IndigenousCommunities/NameEn",
    "indigenous_communities, english",
  ],
  [
    "Listing/ConsultationConsideration/IndigenousCommunities/NameFr",
    "indigenous_communities, french",
  ],
  [
    "Listing/ConsultationConsideration/WildlifeManagementBoards/NameEn",
    "wildlife_management_boards, english",
  ],
  [
    "Listing/ConsultationConsideration/WildlifeManagementBoards/NameFr",
    "wildlife_management_boards, french",
  ],
  [
    "Listing/ConsultationConsideration/ConservationActivities/French",
    "conservation_activities, french",
  ],
  [
    "Listing/ConsultationConsideration/ConservationActivities/English",
    "conservation_activities, english",
  ],

  [
    "Listing/OverallAssessment/ExpectedImpactOnFederalLands/NameEn",
    "expected_impact_on_federal_lands, english",
  ],
  [
    "Listing/OverallAssessment/ExpectedImpactOnFederalLands/NameFr",
    "expected_impact_on_federal_lands, french",
  ],
  [
    "Listing/OverallAssessment/ExpectedImpactOnFederalLandsRationale/PlainText",
    "expected_impact_on_federal_lands_rationale",
  ],
  [
    "Listing/OverallAssessment/ExpectedImpactOnNonFederalLands/NameEn",
    "expected_impact_on_non_federal_lands, english",
  ],
  [
    "Listing/OverallAssessment/ExpectedImpactOnNonFederalLands/NameFr",
    "expected_impact_on_non_federal_lands, french",
  ],
  [
    "Listing/OverallAssessment/ExpectedImpactOnNonFederalLandsRationale/PlainText",
    "expected_impact_on_non_federal_lands_rationale",
  ],
  [
    "Listing/LegalStatus/CompetentMinister1/NameEn",
    "competent_minister_1, english",
  ],
  [
    "Listing/LegalStatus/CompetentMinister1/NameFr",
    "competent_minister_1, french",
  ],
  [
    "Listing/LegalStatus/CompetentMinister2/NameEn",
    "competent_minister_2, english",
  ],
  [
    "Listing/LegalStatus/CompetentMinister2/NameFr",
    "competent_minister_2, french",
  ],
  ["Listing/LegalStatus/ListingHistory/French", "sara_status_history, french"],
  [
    "Listing/LegalStatus/ListingHistory/English",
    "sara_status_history, english",
  ],

  ["Listing/Sectors/NAICS/Code", "naics_code"],

  ["Listing/Sectors/NAICS/ClassName/French", "naics_classname, english"],
  ["Listing/Sectors/NAICS/ClassName/English", "naics_classname, french"],

  ["Listing/Sectors/Activities/NameEn", "activities, english"],
  ["Listing/Sectors/Activities/NameFr", "activities, french"],
  ["Listing/Sectors/Outcomes/NameEn", "outcomes, english"],
  ["Listing/Sectors/Outcomes/NameFr", "outcomes, french"],
  ["Listing/Sectors/Threats/Code", "threats_code"],
  ["Listing/Sectors/Threats/ClassName/French", "threats_classname, french"],
  ["Listing/Sectors/Threats/ClassName/English", "threats_classname, english"],

  ["Listing/Lands/LandRef/LandType", "land_type"],
  ["Listing/Lands/LandRef/ProvinceAndTerritory", "province_territory"],
  ["Listing/Lands/LandRef/LandName", "place_name"],

  ["Listing/Lands/LandRef/LandManager/French", "land_manager, french"],
  ["Listing/Lands/LandRef/LandManager/English", "land_manager, english"],

  ["Listing/Lands/LandRef/Dfrp", "dfrp"],
  ["Listing/Lands/WildlifeSpeciesPresence", "wildlife_species_presence"],
  ["Listing/Lands/NumberOfSites", "number_of_sites"],
  ["Listing/Lands/ApproximateNumberOfIndividuals", "approx_num_individuals"],
  ["Listing/Lands/Circumstance", "circumstance"],
  ["Listing/Lands/Source", "source"],
  ["Listing/DateOfAdditionOnSchedule1/Date", "addition_to_schedule_1, date"],
  [
    "Listing/DateOfAdditionOnSchedule1/DateRange/FromDate",
    "addition_to_schedule_1, from_date",
  ],
  [
    "Listing/DateOfAdditionOnSchedule1/DateRange/ToDate",
    "addition_to_schedule_1, to_date",
  ],
  ["Listing/ListingSchedule", "schedule"],
  ["Listing/State", "state"],
  [
    "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameEn",
    "latest, amendment_aligned_to_assessment, english",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameFr",
    "latest, amendment_aligned_to_assessment, french",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameEn",
    "latest, consultation_path, english",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameFr",
    "latest, consultation_path, french",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/GicDecision/NameEn",
    "latest, gic_decision, english",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/GicDecision/NameFr",
    "latest, gic_decision, french",
  ],
  ["Listing/LatestProcess/Schedule1Status", "latest, schedule_1_status"],
  [
    "Listing/LatestProcess/Schedule1StatusOnReceipt",
    "latest, sch_1_status_on_receipt",
  ],

  ["Listing/LatestProcess/AssessmentRef/Date", "latest, assessment_date"],
  ["Listing/LatestProcess/Stage", "latest, stage"],
  [
    "Listing/LatestProcess/ConsultationProcess/MinisterReceiptDate",
    "latest, minister_receipt_date",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/ResponseStatementDueDate",
    "latest, response_statement_due_date",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/ResponseStatementPostedDate",
    "latest, response_statement_posted_date",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/ListingBatch",
    "latest, listing_batch",
  ],
  [
    "Listing/LatestProcess/ConsultationProcess/TimelinePolicyReportDueDate",
    "latest, timeline_policy_report_due_date",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/GicReceiptDate",
    "latest, gic_receipt_date",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/AnticipatedDecisionDate",
    "latest, anticipated_decision_date",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/GicDecisionDate",
    "latest, gic_decision_date",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/CgiListingProposal/NameEn",
    "latest, cgi_listing_proposal, english",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/CgiListingProposal/NameFr",
    "latest, cgi_listing_proposal, french",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/CosewicToReassess/NameEn",
    "latest, cosewic_to_reassess, english",
  ],
  [
    "Listing/LatestProcess/RegulatoryProcess/CosewicToReassess/NameFr",
    "latest, cosewic_to_reassess, french",
  ],
  [
    "Listing/LatestProcess/MinisterOpinionDate",
    "latest, ministers_opinion_date",
  ],
  ["Listing/LatestProcess/EnglishNote", "latest, process_note, english"],
  ["Listing/LatestProcess/FrenchNote", "latest, process_note, french"],
  ["Listing/LatestProcess/Documents/Location", "latest, document_location"],
  ["Listing/LatestProcess/Documents/DocumentType", "latest, document_type"],
  [
    "Listing/LatestProcess/Documents/PublicationDate",
    "latest, publication_date",
  ],
  ["Listing/Processes/AssessmentRef/Date", "assessment_date"],
  ["Listing/Processes/Schedule1Status", "schedule_1_status"],
  ["Listing/Processes/Schedule1StatusOnReceipt", "sch_1_status_on_receipt"],
  ["Listing/Processes/Stage", "stage"],
  [
    "Listing/Processes/ConsultationProcess/MinisterReceiptDate",
    "minister_receipt_date",
  ],
  [
    "Listing/Processes/ConsultationProcess/ResponseStatementDueDate",
    "response_statement_due_date",
  ],
  [
    "Listing/Processes/ConsultationProcess/ResponseStatementPostedDate",
    "response_statement_posted_date",
  ],
  [
    "Listing/Processes/ConsultationProcess/ConsultationPath/NameEn",
    "consultation_path, english",
  ],
  [
    "Listing/Processes/ConsultationProcess/ConsultationPath/NameFr",
    "consultation_path, french",
  ],
  ["Listing/Processes/ConsultationProcess/ListingBatch", "listing_batch"],
  [
    "Listing/Processes/ConsultationProcess/TimelinePolicyReportDueDate",
    "timeline_policy_report_due_date",
  ],
  [
    "Listing/Processes/ConsultationProcess/AmendmentAlignedToAssessment/NameEn",
    "amendment_aligned_to_assessment, english",
  ],
  [
    "Listing/Processes/ConsultationProcess/AmendmentAlignedToAssessment/NameFr",
    "amendment_aligned_to_assessment, french",
  ],
  ["Listing/Processes/RegulatoryProcess/GicReceiptDate", "gic_receipt_date"],
  [
    "Listing/Processes/RegulatoryProcess/AnticipatedDecisionDate",
    "anticipated_decision_date",
  ],
  ["Listing/Processes/RegulatoryProcess/GicDecisionDate", "gic_decision_date"],
  [
    "Listing/Processes/RegulatoryProcess/CgiListingProposal/NameEn",
    "cgi_listing_proposal, english",
  ],
  [
    "Listing/Processes/RegulatoryProcess/CgiListingProposal/NameFr",
    "cgi_listing_proposal, french",
  ],
  [
    "Listing/Processes/RegulatoryProcess/CosewicToReassess/NameEn",
    "cosewic_to_reassess, english",
  ],
  [
    "Listing/Processes/RegulatoryProcess/CosewicToReassess/NameFr",
    "cosewic_to_reassess, french",
  ],
  [
    "Listing/Processes/RegulatoryProcess/GicDecision/NameEn",
    "gic_decision, english",
  ],
  [
    "Listing/Processes/RegulatoryProcess/GicDecision/NameFr",
    "gic_decision, french",
  ],
  ["Listing/Processes/Documents/Location", "document_location"],
  ["Listing/Processes/Documents/DocumentType", "document_type"],
  ["Listing/Processes/Documents/PublicationDate", "publication_date"],
  ["Listing/Processes/MinisterOpinionDate", "ministers_opinion_date"],
  ["Listing/Processes/EnglishNote", "process_note, english"],
  ["Listing/Processes/FrenchNote", "process_note, french"],
]);

export function convertListingKeyToTag(key: string): string {
  return listingKeyToTag.get(key) ?? "Undefined key '" + key + "'";
}

export function convertListingKeyToTagWithTranslation(key: string): string {
  const value = listingKeyToTag.get(key);
  if (!value) return "Undefined key '" + key + "'";

  const strings = value.split(",");
  let result = i18n.t(strings[0].trim());
  if (strings[1]) result = result + ", " + i18n.t(strings[1].trim());
  if (strings[2]) result = result + ", " + i18n.t(strings[2].trim());

  return result;
}
