import { useComboBoxState } from "@react-stately/combobox";
import type { ComboBoxProps } from "@react-types/combobox";
import type { LoadingState } from "@react-types/shared";
import cc from "classcat";
import * as React from "react";
import { useComboBox, useFilter } from "react-aria";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../atoms/LoadingIndicator";
import NoResults from "../../atoms/NoResults";
import { ListBox } from "./ListBox";
import { Popover } from "./Popover";
import isNullOrEmpty from "util/isNullOrEmpty";
export { Item, Section } from "@react-stately/collections";

interface AutocompleteComboboxProps<T> extends ComboBoxProps<T> {
  id?: string;
  loadingState?: LoadingState;
  onLoadMore?: () => void;
  onClear?: () => void;
  className?: string;
  showArrow?: boolean;
  disabled?: boolean;
  hideNoResultsMessage?: boolean;
  isForMultiSelection?: boolean;
}

export default function Autocomplete<T extends object>(
  initProps: AutocompleteComboboxProps<T>
) {
  const { onSelectionChange: onSelection, ...propsWithoutSelectionChange } =
    initProps;

  const onSelectionChange = (key: string | number) => {
    if (onSelection != null) {
      onSelection(key);
      if (initProps.isForMultiSelection) {
        state.setOpen(false);
      }
    }
  };

  const props = { onSelectionChange, ...propsWithoutSelectionChange };

  const { t } = useTranslation();
  let { contains } = useFilter({ sensitivity: "base" });

  let state = useComboBoxState({ ...props, defaultFilter: contains });

  let inputRef = React.useRef(null);
  let listBoxRef = React.useRef(null);
  let popoverRef = React.useRef(null);

  let { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  const className = React.useMemo(
    () => cc(["relative width-100", props.className]),
    [props.className]
  );

  const isLoading =
    props.loadingState === "loading" ||
    props.loadingState === "loadingMore" ||
    props.loadingState === "filtering";

  const showNoResults =
    !props.hideNoResultsMessage &&
    !isLoading &&
    (props.items as unknown[]).length === 0 &&
    state.selectedKey == null &&
    inputProps?.placeholder === "";

  if (
    initProps.isForMultiSelection &&
    isLoading &&
    !isNullOrEmpty(inputProps.value) &&
    !isNullOrEmpty(state.selectedKey)
  ) {
    state.setInputValue("");
  }

  return (
    <div className={className}>
      <label {...labelProps} className={props.isRequired ? "required" : ""}>
        {props.label}

        {props.isRequired ? (
          <strong className="required"> ({t("required")})</strong>
        ) : null}
        <span className="inline-flex gap-sm">
          {isLoading ? <LoadingIndicator className="mrgn-lft-md" /> : null}
          {showNoResults ? <NoResults className="mrgn-lft-md" /> : null}
        </span>
      </label>
      <div className="width-100 flex">
        <input
          id={props.id}
          {...inputProps}
          ref={inputRef}
          className="form-control width-100 mrgn-rght-sm autocomplete-input"
          disabled={props.disabled || props.loadingState === "loading"}
          onClick={() => state.open()}
        />
        {props.showArrow ? (
          <button
            type="button"
            className="btn btn-sm btn-default mrgn-rght-sm"
            onClick={() => state.toggle()}
            //TODO: a11y labelling for open btn
            disabled={props.disabled || props.loadingState !== "idle"}
          >
            <span className="glyphicon glyphicon-arrow-down mrgn-rght-sm font-size-12" />
          </button>
        ) : null}
        {props.onClear == null ? null : (
          <button
            type="button"
            title={t("clear_search")}
            className="btn btn-sm btn-default"
            onClick={props.onClear}
            disabled={props.disabled || props.loadingState !== "idle"}
            //TODO: a11y labelling for remove
          >
            <span className="glyphicon glyphicon-remove font-size-12"></span>
          </button>
        )}
      </div>
      {state.isOpen && (
        <Popover
          popoverRef={popoverRef}
          isOpen={state.isOpen}
          onClose={state.close}
        >
          <ListBox
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
            loadingState={props.loadingState}
            onLoadMore={props.onLoadMore}
          />
        </Popover>
      )}
    </div>
  );
}
