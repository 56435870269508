import { ChangeMetaData } from "features/changeTracking/types";
import {
  AssessmentStage,
  ChangeTypeType,
  Maybe,
  WsStatus,
} from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import { filterForDirtyFields } from "util/forms";
import isNullOrEmpty from "util/isNullOrEmpty";
import { AssessmentFormChangeTracking, AssessmentFormOutput } from "./index";
import { htmlToPlainText } from "../../../../../util/richtext";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";

export const processAssessmentEditFormData = async (
  formData: UnpackNestedValue<Partial<AssessmentFormOutput>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  defaultValues: any,
  assessmentStage?: Maybe<AssessmentStage>
) => {
  let values = filterForDirtyFields(formData, dirtyFields);

  await setNullForEmptyTextInputs(values, dirtyFields);

  await deleteExtinctExtirpatedStatusComments(
    defaultValues,
    values,
    dirtyFields,
    assessmentStage
  );

  if (checkApplicabilityOfCriteriaIsDirty(dirtyFields)) {
    const allApplicabilityOfCriteriaFields = mergeApplicabilityOfCriteria(
      defaultValues,
      values,
      dirtyFields
    );

    values = Object.assign(values, allApplicabilityOfCriteriaFields);
  }

  await defaultChangeTrackingValues(values);
  return values;
};

const allApplicabilityOfCriteriaKeys = [
  "statusAndCriteria_applicabilityOfCriteriaA",
  "statusAndCriteria_applicabilityOfCriteriaB",
  "statusAndCriteria_applicabilityOfCriteriaC",
  "statusAndCriteria_applicabilityOfCriteriaD",
  "statusAndCriteria_applicabilityOfCriteriaE",
];

/**
 * Create a new applicability of criteria object by merging any updated/dirty fields' values
 * with the edit form's initial values.
 *
 * @param defaultValues form's initial values
 * @param updatedValues form's values after the user has maybe made changes
 * @param dirtyFields dirty fields object from react-hook-form
 */
export const mergeApplicabilityOfCriteria = (
  defaultValues: Partial<AssessmentFormOutput>,
  updatedValues: Partial<AssessmentFormOutput>,
  dirtyFields: any
): Partial<AssessmentFormOutput> => {
  const dirtyKeys = Object.keys(dirtyFields);
  const defaultsKeys = allApplicabilityOfCriteriaKeys.filter(
    (x) => !dirtyKeys.includes(x)
  );

  let out: any = {};

  // Add all non-empty values from the defaults
  for (let key of defaultsKeys) {
    if (!allApplicabilityOfCriteriaKeys.includes(key)) continue;
    const initial = (defaultValues as any)[key];
    if (htmlIsNullOrEmpty(initial?.text)) {
      continue;
    }
    out[key] = initial;
  }

  // Add all non-empty updated values, overwriting keys that may be present from the defaults
  for (let [key, value] of Object.entries(dirtyFields)) {
    if (value === false) continue;
    if (!allApplicabilityOfCriteriaKeys.includes(key)) continue;

    const updated = (updatedValues as any)[key];

    out[key] = updated;
  }

  return out;
};

export const checkApplicabilityOfCriteriaIsDirty = (dirtyFields: any) => {
  return allApplicabilityOfCriteriaKeys
    .map((x) => {
      const textIsDirty = (dirtyFields as any)[x]?.text === true;
      const objectIsDirty = (dirtyFields as any)[x] === true;
      return textIsDirty || objectIsDirty;
    })
    .includes(true);
};

const defaultChangeTrackingValues = async (
  values: Partial<AssessmentFormChangeTracking>
) => {
  const formatChangeTracking = (changeMetaData: ChangeMetaData) => {
    if (changeMetaData.changeType == null) {
      changeMetaData.changeType = ChangeTypeType.Minor;
    }

    if (changeMetaData.reasonForChange == null) {
      changeMetaData.reasonForChange = "MISSING_REASON";
    }

    return changeMetaData;
  };

  const defaultsForKey = (key: keyof typeof values) => {
    if (values[key] == null) return;
    return formatChangeTracking(values[key] as ChangeMetaData);
  };

  const keys: Array<keyof typeof values> = [
    "dateChangeMetaData",
    "statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English",
    "statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French",
    "statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_English",
    "statusAndCriteriaChangeMetaData_statusCriteriaChangeMetaData_French",
    "statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataA",
    "statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataB",
    "statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataC",
    "statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataD",
    "statusAndCriteriaChangeMetaData_applicabilityOfCriteriaChangeMetaDataE",
    "designationChangeMetaData_historyStatusChangeMetaData_English",
    "designationChangeMetaData_historyStatusChangeMetaData_French",
    "designationChangeMetaData_reasonChangeMetaData_English",
    "designationChangeMetaData_reasonChangeMetaData_French",
  ];

  keys.forEach(defaultsForKey);
};

const setNullForEmptyTextInputs = async (
  formData: UnpackNestedValue<Partial<AssessmentFormOutput>>,
  dirtyFields: FieldNamesMarkedBoolean<any>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  if (dirtyFields?.order != null && isNullOrEmpty(formData?.order)) {
    set(formData, "order", null);
  }

  if (
    dirtyFields?.dateSentToMinister != null &&
    isNullOrEmpty(formData?.dateSentToMinister)
  ) {
    set(formData, "dateSentToMinister", null);
  }

  if (
    dirtyFields?.statusAndCriteria_statusCriteria?.english?.text != null &&
    htmlIsNullOrEmpty(
      formData?.statusAndCriteria_statusCriteria?.english?.text ?? "")
  ) {
    set(formData, "statusAndCriteria_statusCriteria.english", null);
  }

  if (
    dirtyFields?.statusAndCriteria_statusCriteria?.french?.text != null &&
    htmlIsNullOrEmpty(
      formData?.statusAndCriteria_statusCriteria?.french?.text ?? ""
    )
  ) {
    set(formData, "statusAndCriteria_statusCriteria.french", null);
  }

  if (
    dirtyFields?.designation_historyStatus?.english?.text != null &&
    htmlIsNullOrEmpty(
      formData?.designation_historyStatus?.english?.text ?? ""
    )
  ) {
    set(formData, "designation_historyStatus.english", null);
  }

  if (
    dirtyFields?.designation_historyStatus?.french?.text != null &&
    htmlIsNullOrEmpty(
      formData?.designation_historyStatus?.french?.text ?? ""
    )
  ) {
    set(formData, "designation_historyStatus.french", null);
  }

  if (
    dirtyFields?.designation_reason?.english?.text != null &&
    htmlIsNullOrEmpty(formData?.designation_reason?.english?.text ?? "")
  ) {
    set(formData, "designation_reason.english", null);
  }

  if (
    dirtyFields?.designation_reason?.french?.text != null &&
    htmlIsNullOrEmpty(formData?.designation_reason?.french?.text ?? "")
  ) {
    set(formData, "designation_reason.french", null);
  }
};

// Delete previous Status Comments (Extinct/Extirpated since) when WSStatus changed from
// Extinct/Extirpated to others, and add ChangeMetaData if current assessment stage is assessed.
const deleteExtinctExtirpatedStatusComments = async (
  defaultValues: Partial<AssessmentFormOutput>,
  values: Partial<AssessmentFormOutput>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  assessmentStage?: Maybe<AssessmentStage>
) => {
  if (dirtyFields.statusAndCriteria_status) {
    if (
      (defaultValues.statusAndCriteria_status === WsStatus.Extinct ||
        defaultValues.statusAndCriteria_status === WsStatus.Extirpated) &&
      values.statusAndCriteria_status !== WsStatus.Extinct &&
      values.statusAndCriteria_status !== WsStatus.Extirpated
    ) {
      if (
        !htmlIsNullOrEmpty(defaultValues.statusAndCriteria_statusComment?.english?.text)
      ) {
        set(values, "statusAndCriteria_statusComment.english", null);
        if (assessmentStage === AssessmentStage.Assessed) {
          set(
            values,
            "statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English.changeType",
            ChangeTypeType.Delete
          );
          set(
            values,
            "statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_English.reasonForChange",
            "The Extinct / Extirpated status comments has been deleted by the system due to changes on the Wildlife Species Status."
          );
        }
      }
      if (
        !htmlIsNullOrEmpty(defaultValues.statusAndCriteria_statusComment?.french?.text)
      ) {
        set(values, "statusAndCriteria_statusComment.french", null);
        if (assessmentStage === AssessmentStage.Assessed) {
          set(
            values,
            "statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French.changeType",
            ChangeTypeType.Delete
          );
          set(
            values,
            "statusAndCriteriaChangeMetaData_statusCommentChangeMetaData_French.reasonForChange",
            "The Extinct / Extirpated status comments has been deleted by the system due to changes on the Wildlife Species Status."
          );
        }
      }
    }
  }
};
