import { useMutation, useQuery } from "@tanstack/react-query";
import ExportButton from "components/atoms/ExportButton";
import Layout from "components/layouts/OneColumn";
import { GlobalAlert } from "features/globalAlert";
import { INDEX_NAME } from "features/search/changelog/constants";
import changelogFacets from "features/search/changelog/facets.json";
import { useBasicSearchQuery } from "features/search/hooks/useBasicSearchQuery";
import { fetchFilters } from "features/search/utils/fetchFilters";
import makeOrderByString, {
  SortDescriptor,
} from "features/search/utils/makeOrderByString";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert, { AlertTypes } from "../../components/atoms/Alert";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import SectionCard from "../../components/atoms/SectionCard";
import PageSizeSelect from "../../components/molecules/PageSizeSelect";
import Pagination from "../../components/molecules/Pagination";
import BasicSearchCheckboxListDetails from "../../components/molecules/search/BasicSearchCheckboxListDetails";
import BasicSearchDateRange from "../../components/molecules/search/BasicSearchDateRange";
import BasicSearchPathPropertyCheckboxList from "../../components/molecules/search/changeLog/BasicSearchPathPropertyCheckboxList";
import { FilterKeywordSection } from "../../components/organisms/search/FilterKeywordSection/FilterKeywordSection";
import FilterTags from "../../components/organisms/search/FilterTags/FilterTags";
import ResultsTable from "../../components/organisms/search/ResultsTable/ResultsTable";
import SelectProgramCard from "../../components/organisms/search/changelog/SelectProgramCard";
import { FilterArguments, PagingArguments } from "../../features/search/types";
import {
  SearchServiceExportParams,
  fetchExport,
} from "../../util/basicSearch/fetchExport";
import { convertPathToTag } from "../../util/changeLog/convertPathToTag";
import { formatTimestamp } from "../../util/formatTimestamp";
import { buildQueryParameters } from "./hooks/useAzureSearchQuery";
import useBasicSearch from "./hooks/useBasicSearch";

interface SearchListingChangelogColumns {
  id: string;
  "Listing/CommonNameEnglish/Name"?: string;
  "Listing/CommonNameFrench/Name"?: string;
  "Listing/Id"?: string;
  "Listing/ListingIdStr"?: string;
  "LogEntry/TransactionType"?: string; // change type
  "LogEntry/DataChange/PathProperty/PropertyPath"?: string; // property
  "LogEntry/DataChange/PathProperty/PropertyPathEnglish"?: string; // property
  "LogEntry/DataChange/PathProperty/PropertyPathFrench"?: string; // property
  "LogEntry/ModifiedAt"?: string; // date changed
  "LogEntry/ModifiedBy"?: string; // user
}

const changelogListingSearchExtraFilters = {
  and: [
    { Listing: { ne: null }, LogEntry: { ContextName: { eq: "ListingWS" } } },
  ],
};

const searchFields =
  "Listing/ListingIdStr, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/Id, LogEntry/ModifiedBy";
const queryType = "full";
const searchMode = "any";
const select =
  "LogEntry/ContextName, Id, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/Id, Listing/ListingIdStr, LogEntry/ChangeLogId, LogEntry/TransactionType, LogEntry/DataChange/PathProperty/PropertyPath, LogEntry/DataChange/PathProperty/PropertyPathEnglish, LogEntry/DataChange/PathProperty/PropertyPathFrench, LogEntry/ModifiedAt, LogEntry/ModifiedBy";
const exportSelect =
  "Id, Listing/CommonNameEnglish/Name, Listing/CommonNameFrench/Name, Listing/Population/NameEn, Listing/Population/NameFr, Listing/Id, Listing/ListingIdStr, LogEntry/TransactionType, LogEntry/DataChange/PathProperty/PropertyPath, LogEntry/DataChange/PathProperty/PropertyPathEnglish, LogEntry/DataChange/PathProperty/PropertyPathFrench, LogEntry/ModifiedAt, LogEntry/ModifiedBy";

const SearchListingChangelog: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);

  const basicSearch = useBasicSearch("basicsearch_changelog_listing");

  const filtersQuery = useQuery(
    ["basicSearch", "changelog", "listing", "filters"],
    () => {
      return fetchFilters(
        changelogFacets,
        INDEX_NAME,
        changelogListingSearchExtraFilters
      );
    }
  );

  const { data, loading, error, runSearchQuery } =
    useBasicSearchQuery(INDEX_NAME);

  const allFacets =
    filtersQuery.data == null ? null : filtersQuery.data["@search.facets"];

  const orderByString = useMemo(() => {
    if (sortDescriptor == null) {
      const column =
        i18n.language === "fr"
          ? "Listing/CommonNameFrench/Name"
          : "Listing/CommonNameEnglish/Name";

      return makeOrderByString({
        column,
        direction: "ascending",
      });
    }
    return makeOrderByString(sortDescriptor);
  }, [i18n.language, sortDescriptor]);

  const azureSearchArguments = useMemo(
    function makeSearchArguments() {
      return {
        queryType: queryType,
        searchMode,
        searchFields: searchFields,
        select: select,

        search: basicSearch.state.keywordSearchText,
        allSelectedFilters: basicSearch.state.checkboxFilters,
        allNumberRanges: basicSearch.state.numberRangeFilters,
        allDateRanges: basicSearch.state.dateRangeFilters,

        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        orderby: orderByString,
        count: true,
        extraFilters: changelogListingSearchExtraFilters,
      };
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      pagination.currentPage,
      pagination.pageSize,
      sortDescriptor,
      i18n.language,
    ]
  );

  useEffect(
    function autoRunSearch() {
      runSearchQuery(azureSearchArguments);
    },
    [azureSearchArguments]
  );

  useEffect(
    function autoResetPageNumber() {
      const newPageNumber = 1;
      pagination.goToPage(newPageNumber);
    },
    [
      basicSearch.state.keywordSearchText,
      basicSearch.state.checkboxFilters,
      basicSearch.state.numberRangeFilters,
      basicSearch.state.dateRangeFilters,
      i18n.language,
    ]
  );

  const onSearchClick = async () => {
    await runSearchQuery(azureSearchArguments);
  };

  const onResetSearch = async () => {
    basicSearch.dispatch({ type: "reset_keyword_search" });
    pagination.goToPage(1);
    exportMutation.reset();
  };

  const onResetTags = async () => {
    pagination.goToPage(1);
    basicSearch.dispatch({ type: "reset_all" });
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const onPageSelected = async (newPageNumber: number) => {
    pagination.goToPage(newPageNumber);
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const columns: Array<{
    name: string;
    key: keyof SearchListingChangelogColumns;
    sortable: boolean;
  }> = React.useMemo(() => {
    const keyForPathProperty =
      i18n.language === "fr"
        ? "LogEntry/DataChange/PathProperty/PropertyPathFrench"
        : "LogEntry/DataChange/PathProperty/PropertyPathEnglish";

    return [
      {
        name: t("common_name_with_population"),
        key:
          i18n.language === "fr"
            ? "Listing/CommonNameFrench/Name"
            : "Listing/CommonNameEnglish/Name",
        sortable: true,
        hasLink: true,
      },
      {
        name: t("listing_id"),
        key: "Listing/ListingIdStr",
        sortable: true,
      },
      {
        name: t("change_type"),
        key: "LogEntry/TransactionType",
        sortable: true,
      },
      {
        name: t("property"),
        key: keyForPathProperty,
        sortable: true,
      },
      {
        name: t("date_changed"),
        key: "LogEntry/ModifiedAt",
        sortable: true,
      },
      {
        name: t("user"),
        key: "LogEntry/ModifiedBy",
        sortable: true,
      },
    ];
  }, [i18n.language]);

  //
  // Map search response to columns

  const rows: Array<SearchListingChangelogColumns> = useMemo(
    () =>
      data?.value?.map((value: any) => {
        let wildlifeSpeciesName = "";
        if (i18n.language === "fr") {
          wildlifeSpeciesName =
            value?.Listing?.CommonNameFrench?.Name ??
            value?.Listing?.CommonNameFrench?.Name ??
            value?.Listing?.CommonNameEnglish?.Name ??
            value?.Listing?.CommonNameEnglish?.Name;
          if (value?.Listing?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Listing?.Population.NameFr ??
              value?.Listing?.Population?.NameFr ??
              value?.Listing?.Population?.NameEn ??
              value?.Listing?.Population?.NameEn;
        } else {
          wildlifeSpeciesName =
            value?.Listing?.CommonNameEnglish?.Name ??
            value?.Listing?.CommonNameEnglish?.Name ??
            value?.Listing?.CommonNameFrench?.Name ??
            value?.Listing?.CommonNameFrench?.Name;
          if (value?.Listing?.Population)
            wildlifeSpeciesName +=
              ", " + value?.Listing?.Population?.NameEn ??
              value?.Listing?.Population?.NameEn ??
              value?.Listing?.Population?.NameFr ??
              value?.Listing?.Population?.NameFr;
        }

        let _href;
        if (
          value?.LogEntry?.TransactionType === "CHANGE" &&
          value?.LogEntry?.ChangeLogId &&
          value?.Listing?.Id
        ) {
          _href = `/listing/${value?.Listing?.Id}/changeLog/details?changeLogId=${value?.LogEntry?.ChangeLogId}`;
        }

        const keyForPathProperty =
          i18n.language === "fr"
            ? "LogEntry/DataChange/PathProperty/PropertyPathFrench"
            : "LogEntry/DataChange/PathProperty/PropertyPathEnglish";

        const valueForPathProperty =
          i18n.language === "fr"
            ? value?.LogEntry?.DataChange?.PathProperty?.PropertyPathFrench
            : value?.LogEntry?.DataChange?.PathProperty?.PropertyPathEnglish;

        if (valueForPathProperty != null) {
          // NOTE: we don't really need the raw path property for this page anymore; it's just temporary, for finding translation differences.
          const convertedPathProperty = value?.LogEntry?.DataChange
            ?.PathProperty?.PropertyPath
            ? t(
                convertPathToTag(
                  `${value?.LogEntry?.DataChange?.PathProperty?.PropertyPath}`
                )
              )
            : "";

          if (valueForPathProperty !== convertedPathProperty) {
            console.log(
              "Translation mismatch!",
              `Translation from server: \`${valueForPathProperty}\``,
              `Translation from UI: \`${convertedPathProperty}\``
            );
          }
        }

        return {
          _href,
          id: value?.Id,
          [i18n.language === "fr"
            ? "Listing/CommonNameFrench/Name"
            : "Listing/CommonNameEnglish/Name"]: wildlifeSpeciesName,

          "Listing/ListingIdStr": value?.Listing?.ListingIdStr,
          "LogEntry/TransactionType": t(value?.LogEntry?.TransactionType),
          "LogEntry/DataChange/PathProperty": value?.LogEntry?.DataChange,
          [keyForPathProperty]: valueForPathProperty,
          "LogEntry/ModifiedAt": formatTimestamp(value?.LogEntry?.ModifiedAt),
          "LogEntry/ModifiedBy": value?.LogEntry?.ModifiedBy,
        };
      }),
    [data?.value]
  );

  const exportMutation = useMutation(
    ["basicSearch", "changelog", "listing", "export"],
    (params: any) => fetchExport(params, INDEX_NAME)
  );

  const fullExportMutation = useMutation(
    ["basicSearch", "changelog", "listing", "export", "full"],
    (params: any) => fetchExport(params, INDEX_NAME)
  );

  const runExport = async () => {
    const languageCode = i18n.language as any;
    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    const columnHeaders = columns
      .filter(
        (x) =>
          // We filter out common name with population so we can replace it with "Listing English common name" in the export (ticket 39955)
          x.key !== "Listing/CommonNameFrench/Name" &&
          x.key !== "Listing/CommonNameEnglish/Name"
      )
      .map((x) => ({ name: x.name, key: x.key })) as any;

    // Additionally show separate population columns (ticket 39955)
    columnHeaders.unshift({
      name: t("listing_population_french"),
      key: "Listing/Population/NameFr",
    });
    columnHeaders.unshift({
      name: t("listing_population_english"),
      key: "Listing/Population/NameEn",
    });
    // Replace "common name with population" with "Listing English common name" in the export (ticket 39955)
    columnHeaders.unshift({
      name: t("listing_English_common_name"),
      key: "Listing/CommonNameEnglish/Name",
    });

    const filterArgs: FilterArguments = {
      allSelectedFilters: basicSearch.state.checkboxFilters,
      allNumberRanges: basicSearch.state.numberRangeFilters,
      allDateRanges: basicSearch.state.dateRangeFilters,
      extraFilters: changelogListingSearchExtraFilters,
    };

    const paging: PagingArguments = {
      pageNumber: pagination.currentPage,
      pageSize: pagination.pageSize,
    };

    const extra: Partial<SearchServiceExportParams> = {
      queryType: queryType,
      search: basicSearch.state.keywordSearchText,
      includeTotalCount: true,
      searchFields: searchFields.split(/,\s?/g),
      orderby: orderByString?.split(/,\s?/g),
      select: exportSelect.split(/,\s?/g),
    };

    const searchParameters = buildQueryParameters(filterArgs, paging, extra);

    return exportMutation.mutate({
      columnHeaders,
      searchParameters,
      languageCode,
    });
  };

  const runFullExport = async () => {
    const languageCode = i18n.language as any;
    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    const filterArgs: FilterArguments = {
      allSelectedFilters: basicSearch.state.checkboxFilters,
      allNumberRanges: basicSearch.state.numberRangeFilters,
      allDateRanges: basicSearch.state.dateRangeFilters,
      extraFilters: changelogListingSearchExtraFilters,
    };

    const paging: PagingArguments = {
      pageNumber: pagination.currentPage,
      pageSize: pagination.pageSize,
    };

    const extra: Partial<SearchServiceExportParams> = {
      queryType: queryType,
      search: basicSearch.state.keywordSearchText,
      includeTotalCount: true,
      searchFields: searchFields.split(/,\s?/g),
      orderby: orderByString?.split(/,\s?/g),
      searchTemplateName: "FULL",
    };

    const searchParameters = buildQueryParameters(filterArgs, paging, extra);

    return fullExportMutation.mutate({
      searchParameters,
      languageCode,
    });
  };

  const [showAllChangelogFilters, setShowAllChangelogFilters] = useState(false);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("change_log_search")}</h1>
          <div className="row">
            <div className="col-md-3">
              <SelectProgramCard selected="listing" />

              <SectionCard
                header={<h2 className="mrgn-tp-0">{t("search_filters")}</h2>}
                id="search-filters"
                className="pb-0"
                showLine={false}
              >
                <div className="pt-0 separator-line-top"></div>
                <details className="species-details" open>
                  <summary>
                    <h3>{t("change_log_filters")}</h3>
                  </summary>
                  <section id="changelog-filters">
                    <button
                      type="button"
                      className="btn btn-link px-2 py-2"
                      onClick={() => setShowAllChangelogFilters((x) => !x)}
                      data-testid="button-show-all-changelog-listing"
                    >
                      {!showAllChangelogFilters ? t("show_all") : t("hide_all")}
                    </button>

                    <BasicSearchCheckboxListDetails
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      facetName={"LogEntry/TransactionType"}
                      header={t("change_type")}
                      open={showAllChangelogFilters}
                      translateItems
                    />

                    <BasicSearchDateRange
                      label={t("date_changed")}
                      facetName={"LogEntry/ModifiedAt"}
                      basicSearch={basicSearch}
                      allFacets={allFacets}
                      open={showAllChangelogFilters}
                    />

                    <BasicSearchCheckboxListDetails
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      facetName={"LogEntry/DataChange/MetaData/TypeOfChange"}
                      header={t("type_of_property_change")}
                      open={showAllChangelogFilters}
                      translateItems
                    />

                    <BasicSearchCheckboxListDetails
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      facetName={"LogEntry/ActionDescription"}
                      header={t("type_of_operation_change")}
                      open={showAllChangelogFilters}
                      translateItems
                    />

                    <BasicSearchPathPropertyCheckboxList
                      allFacets={allFacets}
                      basicSearch={basicSearch}
                      facetName={
                        "LogEntry/DataChange/PathProperty/PropertyPath"
                      }
                      header={t("property")}
                      open={showAllChangelogFilters}
                      translateItems
                    />
                  </section>
                </details>
              </SectionCard>
            </div>
            <div className="col-md-9">
              {exportMutation.isSuccess ? (
                <Alert
                  type={AlertTypes.SUCCESS}
                  title={t("export_success")}
                  onClose={exportMutation.reset}
                />
              ) : null}

              {exportMutation.isError ? (
                <Alert
                  type={AlertTypes.DANGER}
                  title={t("export_failure")}
                  onClose={exportMutation.reset}
                />
              ) : null}

              <FilterKeywordSection
                defaultValue={basicSearch.state.keywordSearchText}
                onChange={(text) =>
                  basicSearch.dispatch({ type: "update_keyword_search", text })
                }
                onSearch={onSearchClick}
                onReset={onResetSearch}
              />

              <SectionCard
                header={
                  <div className="flex justify-between align-start">
                    <h2>{t("change_log_search_results")}</h2>
                    <div className="flex gap-md align-start">
                      <ExportButton
                        text={t("export")}
                        onExport={runExport}
                        loading={exportMutation.isLoading}
                      />
                    </div>
                  </div>
                }
              >
                <>
                  <FilterTags onReset={onResetTags} basicSearch={basicSearch} />

                  {/*RESULTS:*/}
                  {loading ? (
                    <LoadingIndicator centered className="mrgn-bttm-md" />
                  ) : rows && rows?.length === 0 ? (
                    <NoResults centered />
                  ) : (
                    <>
                      <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                        <div className="font-size-18">
                          {pagination.makeShowingString(
                            data ? data["@odata.count"] : 0
                          )}
                        </div>
                        <div>
                          <PageSizeSelect
                            pageSize={pagination.pageSize}
                            onChangePageSize={onChangePageSize}
                          />
                        </div>
                      </div>
                      {!loading && error ? (
                        <pre>{JSON.stringify(error)}</pre>
                      ) : null}
                      {rows == null || rows.length === 0 ? null : (
                        <ResultsTable
                          rows={rows}
                          columns={columns}
                          sortable
                          sortDescriptor={sortDescriptor}
                          onSortChange={onSortChange as any}
                        />
                      )}

                      <Pagination
                        {...pagination.paginationComponentProps}
                        onPageSelected={onPageSelected}
                        totalPages={
                          data && data["@odata.count"] && pagination.pageSize
                            ? Math.ceil(
                                data["@odata.count"] / pagination.pageSize
                              )
                            : 0
                        }
                      />
                    </>
                  )}
                </>
              </SectionCard>
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default SearchListingChangelog;
