import * as React from "react";
import { useTranslation } from "react-i18next";
import EditResponseStatementPopup, {
  EditResponseStatementPopupProps,
  useResponseStatementPopup,
} from "./";

interface EditResponseStatementModalWithButtonProps
  extends Omit<EditResponseStatementPopupProps, "modalState"> {
  buttonLabel?: string;
}

const EditResponseStatementBtn: React.FC<
  EditResponseStatementModalWithButtonProps
> = ({ buttonLabel, ...props }) => {
  const { t } = useTranslation();
  const modalState = useResponseStatementPopup({});
  const label = buttonLabel ?? t("edit");
  const validResponseStatementId =
    props.responseStatementId != null && props.responseStatementId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validResponseStatementId}
        data-testid="button-edit"
      >
        {label}
      </button>
      {validResponseStatementId ? (
        <EditResponseStatementPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditResponseStatementBtn;
