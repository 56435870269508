import cc from "classcat";
import type { ReactElement } from "react";
import * as React from "react";

export interface SectionCardProps extends React.HTMLAttributes<HTMLDivElement> {
  collapsible?: boolean;
  open?: boolean;
  header: ReactElement;
  showHeader?: boolean;
  showLine?: boolean;
  contentAreaProps?: React.HTMLAttributes<HTMLDivElement>;
  classNameSummary?: string;
}

export const SectionCard: React.FC<SectionCardProps> = (props) => {
  const {
    collapsible,
    header,
    open = true,
    showHeader = true,
    showLine = true,
    children,
    contentAreaProps,
    className,
    classNameSummary,
    ...restProps
  } = props;

  if (collapsible)
    return (
      <section
        className={cc(["Card expand-collapse", className])}
        {...restProps}
      >
        <details open={open}>
          <summary
            className={cc([
              `${!showLine ? "border-bottom-none" : ""}`,
              classNameSummary,
            ])}
          >
            {header}
          </summary>
          <div {...contentAreaProps}>{children}</div>
        </details>
      </section>
    );

  return (
    <section className={cc(["Card", className])} {...restProps}>
      <header className={`${!showHeader ? "wb-inv" : ""}`}>{header}</header>
      <div className={`${showLine ? "separator-line-top" : ""}`} />
      <div {...contentAreaProps}>{children}</div>
    </section>
  );
};

export default SectionCard;
