import * as React from "react";
import { useTranslation } from "react-i18next";
import CreateVarietyModal, {
  CreateVarietyModalProps,
  useVarietyModal,
} from "./";

interface CreateVarietyModalWithButtonProps
  extends Omit<CreateVarietyModalProps, "modalState"> {
  buttonLabel?: string;
}

const CreateVarietyModalWithButton: React.FC<
  CreateVarietyModalWithButtonProps
> = (props) => {
  const { t } = useTranslation();
  const modalState = useVarietyModal({});

  const label = props.buttonLabel ?? t("add");

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        data-testid="button-add"
      >
        {label}
      </button>
      <CreateVarietyModal modalState={modalState} {...props} />
    </>
  );
};

export default CreateVarietyModalWithButton;
