/**
 * SortDescriptor interface
 * Mimicks the internal type from the react-aria table package,
 * since they don't export it for us to use.
 */
export interface SortDescriptor {
  column?: string;
  direction?: "ascending" | "descending";
}

/**
 * Turn a sort descriptor object (from react-stately/react-aria table and list components)
 * into an 'orderby' string for azure cognitive search.
 * @param sortDescriptor sort descriptor
 */
export default function makeOrderByString(sortDescriptor?: SortDescriptor) {
  const column = sortDescriptor?.column ?? undefined;
  const direction = sortDescriptor?.direction ?? undefined;
  const azureSortDirection = direction === "ascending" ? "asc" : "desc";
  return column && direction ? `${column} ${azureSortDirection}` : undefined;
}

/**
 * Turn a sort descriptor object (from react-stately/react-aria table and list components)
 * into an 'orderby' string for API/GraphQL search.
 * @param sortDescriptor sort descriptor
 */
export function makeSortByString(sortDescriptor?: SortDescriptor) {
  const column = sortDescriptor?.column ?? undefined;
  const direction = sortDescriptor?.direction ?? undefined;
  const SortDirection = direction === "ascending" ? "asc" : "desc";
  return column && direction ? `${column}:${SortDirection}` : undefined;
}
