import { useApolloClient, useMutation } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import Alert from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/OneColumn";
import AdminPageFilterForm, {
  defaultQueryFields,
  QueryFields,
} from "components/molecules/admin/AdminPageFilterForm";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import AdminArchiveModal from "components/organisms/admin/AdminArchiveModal";
import AdminTable from "components/organisms/admin/AdminTable";
import AdminUnarchiveModal from "components/organisms/admin/AdminUnarchiveModal";
import { SEARCH_DEBOUNCE_TIME_MS } from "config/constants";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import {
  ArchiveStatus,
  LandsForAdminLandDocument,
  UpdateLandArchiveStatusDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import pDebounce from "p-debounce";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";

export interface LandsIndexPageProps {}

export const LandsIndexPage: React.FC<LandsIndexPageProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();

  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const [showAllColumns, setShowAllColumns] = React.useState<boolean>(false);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const adminAlertMsg = useAdminAlertMsg(t("land"));

  const pagination = usePagination();

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "landManagerEn":
          return "landManager.english";
        case "landManagerFr":
          return "landManager.french";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const debouncedSearch = React.useMemo(() => {
    return pDebounce(
      (_cursor: number, _filterText: any, _sortDescriptor: any) =>
        client.query({
          query: LandsForAdminLandDocument,
          variables: {
            params: {
              search:
                _filterText.search.length > 0 ? _filterText.search : undefined,
              archiveStatus: _filterText.showInactive
                ? ArchiveStatus.Inactive
                : ArchiveStatus.Active,
              sortBy: getSortByOrder(
                _sortDescriptor.column,
                _sortDescriptor.direction
              ),
              pageNumber: pagination.currentPage,
              pageSize: pagination.pageSize,
            },
          },
          fetchPolicy: "network-only",
        }),
      SEARCH_DEBOUNCE_TIME_MS
    );
  }, [pagination.currentPage, pagination.pageSize]);

  const [runUpdateLandArchiveStatus] = useMutation(
    UpdateLandArchiveStatusDocument,
    {
      onError: (_err) => {
        filters.showInactive
          ? adminAlertMsg.onUnarchiveError()
          : adminAlertMsg.onArchiveError();
      },
      onCompleted: (data) => {
        filters.showInactive
          ? adminAlertMsg.onUnarchiveSuccess()
          : adminAlertMsg.onArchiveSuccess();
        rows.reload(); // refetchQueries LandsForAdminLand
        setSelectedRowId("");
      },
    }
  );

  const onAddLand = () => {
    history.push({
      pathname: "/admin/lands/create",
    });
  };

  const onEditLand = () => {
    history.push({
      pathname: "/admin/lands/" + selectedRowId + "/edit",
    });
  };

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const onArchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    try {
      const res = await runUpdateLandArchiveStatus({
        variables: {
          landId: selectedRowId,
          archiveStatus: ArchiveStatus.Inactive,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  const onUnarchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    try {
      const res = await runUpdateLandArchiveStatus({
        variables: {
          landId: selectedRowId,
          archiveStatus: ArchiveStatus.Active,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  React.useEffect(() => {
    rows.reload(); // refetchQueries LandsForAdminLand
    setSelectedRowId("");
  }, [pagination.currentPage, pagination.pageSize]);

  const columns = React.useMemo(
    () =>
      [
        { name: t("land_type"), key: "landType", visible: true },
        {
          name: t("province_territory"),
          key: "provinceAndTerritory",
          visible: true,
        },
        { name: t("place_name"), key: "landName", visible: true },
        {
          name: t("land_manager_english"),
          key: "landManagerEn",
          visible: true,
        },
        { name: t("land_manager_french"), key: "landManagerFr", visible: true },
        { name: t("dfrp"), key: "dfrp", visible: true },
        { name: t("land_size"), key: "landSize", visible: true },
        { name: t("created_by"), key: "createdBy", visible: false },
        { name: t("creation_date"), key: "createdAt", visible: false },
        { name: t("updated_by"), key: "modifiedBy", visible: false },
        { name: t("updated_date"), key: "modifiedAt", visible: false },
      ].filter((col) => showAllColumns || col.visible),
    [i18n.language, showAllColumns]
  );

  const rows = useAsyncList<any>({
    initialSortDescriptor: { column: "landType", direction: "ascending" },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await debouncedSearch(cursor, filterText, sortDescriptor);
      const landList = (results?.data?.landList?.land ?? []) as Array<any>;

      const items = landList.map((x) => {
        return {
          ...x,
          landType: t(x.landType),
          provinceAndTerritory: t(x.provinceAndTerritory),
          landManagerEn: x.landManager.english,
          landManagerFr: x.landManager.french,
          createdAt: formatTimestamp(x.createdAt),
          modifiedAt: formatTimestamp(x.modifiedAt),
        };
      });

      setTotalCount(results.data.landList?.pagination?.totalCount ?? 0);
      setTotalPages(results.data.landList?.pagination?.totalPages ?? 0);

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const selectedRow = rows.items.find((x) => x.id === selectedRowId);

  const onFilterSubmit = (value: QueryFields) => {
    flushSync(() => {
      rows.setFilterText(value as any); // pDebounce <- Commented out since this was causing duplicate queries upon search submission
      setFilters(value); // useState
      pagination.goToPage(1);
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("lands")}</h1>
          <GraphqlError title="Error loading Lands" errors={rows?.error} />

          {adminAlertMsg.alertIsVisible ? (
            <Alert
              type={adminAlertMsg.pageAlertType!}
              content={adminAlertMsg.pageAlertMsg!}
              onClose={adminAlertMsg.close}
            />
          ) : null}

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("list_of_lands")}</h2>
                <div className="flex gap-md">
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.land.create}
                  >
                    <button
                      type="button"
                      className="btn btn-link card-header-button-link"
                      onClick={onAddLand}
                      data-testid="button-land-add"
                    >
                      {t("add")}
                    </button>
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.land.update}
                  >
                    <button
                      type="button"
                      className="btn btn-link card-header-button-link"
                      onClick={onEditLand}
                      disabled={!selectedRow}
                      data-testid="button-land-edit"
                    >
                      {t("edit")}
                    </button>
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.land.deactivateReactivate
                    }
                  >
                    {!filters.showInactive ? (
                      <AdminArchiveModal
                        onArchive={onArchive}
                        modalTitle={t("archive_a_land")}
                        warningText={t("archive_land_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.land.deactivateReactivate
                    }
                  >
                    {filters.showInactive &&
                    (!selectedRow ||
                      selectedRow.archiveStatus === ArchiveStatus.Inactive) ? (
                      <AdminUnarchiveModal
                        onUnarchive={onUnarchive}
                        modalTitle={t("unarchive_a_land")}
                        warningText={t("unarchive_land_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <AdminPageFilterForm onSubmit={onFilterSubmit} />
              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16">
                  <span className="justify-end pb-3">
                    {pagination.makeShowingString(totalCount)}
                  </span>
                  <button
                    type="button"
                    className="btn btn-xs btn-default mrgn-lft-md"
                    // TODO: i18n
                    title={t(
                      showAllColumns ? "restore_table" : "maximize_table"
                    )}
                    onClick={() => setShowAllColumns(!showAllColumns)}
                    data-testid="button-show-hide-columns"
                  >
                    {showAllColumns ? (
                      <span className="glyphicon glyphicon-resize-small" />
                    ) : (
                      <span className="glyphicon glyphicon-resize-full" />
                    )}
                    {/* TODO: i18n */}
                    <span className="wb-inv">Show/Hide table columns</span>
                  </button>
                </div>
              </div>
            </div>

            {/*RESULTS:*/}
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !rows.items || rows?.items.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                <AdminTable
                  aria-label="Lands table" /*TODO: i18n*/
                  selectionMode="single"
                  showSelectionCheckboxes
                  onSelectionChange={onSelectionChange as any}
                  selectedKeys={[selectedRowId]}
                  columns={columns}
                  rows={rows.items}
                  sortable
                  sortDescriptor={rows.sortDescriptor}
                  onSortChange={rows.sort}
                />
                <Pagination
                  {...pagination.paginationComponentProps}
                  totalPages={totalPages}
                />
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default LandsIndexPage;
