import { ListingPathHelpers } from "pages/listing/ListingRouter";
import { PermitPathHelpers } from "pages/permit/PermitRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  RenderWhenAuthorized,
  ROLE_ACTIONS,
} from "../../../features/auth/components";
import { AffectedSpecies, Maybe } from "../../../generated/gql-types";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";
import EditLink from "../../atoms/EditLink";
import MissingData from "../../atoms/MissingData";
import SectionCard from "../../atoms/SectionCard";

export interface AffectedWsCardProps {
  pdId?: Maybe<string>;
  affectedSpecies?: Maybe<Array<Maybe<AffectedSpecies>>>;
}

export const AffectedWsCard: React.FC<AffectedWsCardProps> = (props) => {
  const { pdId, affectedSpecies } = props;
  const { t, i18n } = useTranslation();

  const CommonNameAndPopulation = (affectedSpecies: AffectedSpecies) => {
    const commonName =
      i18n.language === "fr"
        ? affectedSpecies.commonNameFrench?.name?.plainText
        : affectedSpecies.commonNameEnglish?.name?.plainText;

    const population =
      i18n.language === "fr"
        ? affectedSpecies.population?.nameFr
        : affectedSpecies.population?.nameEn;

    return (
      <>
        {commonName ?? <MissingData />}
        {population && (
          <>
            {", "}
            {population}
          </>
        )}
      </>
    );
  };

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("affected_wildlife_species")}</h2>
          {/*<RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.permit.update}>
            <EditLink linkTo={PermitPathHelpers.PermitEdit(pdId ?? "error")} />
          </RenderWhenAuthorized>*/}
        </div>
      }
      showLine={false}
    >
      {affectedSpecies?.map((as, index) => (
        <div
          className="row list-item separator-line species-field px-0 py-2_5"
          key={index}
        >
          <div className="col-sm-12 species-data">
            <dl>
              <dt className="text-muted wb-inv">
                {t("wildlife_species")} - {index + 1}
              </dt>
              <dd className="mrgn-bttm-0">
                {htmlIsNullOrEmpty(as?.commonNameEnglish?.name?.text) ? (
                  <MissingData />
                ) : (
                  <Link
                    to={ListingPathHelpers.ListingProfile(as?.id ?? "error")}
                    className="hover-underline"
                  >
                    {CommonNameAndPopulation(as!)}
                  </Link>
                )}
              </dd>
            </dl>
          </div>
        </div>
      ))}
    </SectionCard>
  );
};

export default AffectedWsCard;
