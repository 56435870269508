import {
  BilingualAbbreviationAcronymTagsLookupText,
  CreateDocumentInput,
  DocumentPrograms,
  DocumentQuery,
} from "generated/gql-types";
import { makeBilingualRichText, makeRichText } from "mappers";
import { DocumentFormFields } from "./DocumentForm";

export const mapDomainModelToForm = (
  domainModel: DocumentQuery["document"]
) => {
  const out: Partial<DocumentFormFields> = {};
  if (domainModel == null) {
    // For the Create document form
    out.publicationType = undefined;
    out.title = {
      english: undefined,
      french: undefined,
    };
    out.contentOwner = undefined;
    out.programs = [];
    out.documentType = undefined;
    out.associatedSpecies = [];
    out.note = undefined;
  } else {
    // For the Edit document form
    if (domainModel.publicationType)
      out.publicationType = domainModel.publicationType;
    if (domainModel.title) out.title = makeBilingualRichText(domainModel.title);
    if (domainModel.contentOwner) out.contentOwner = domainModel.contentOwner;
    if (domainModel.programs)
      out.programs =
        domainModel.programs as BilingualAbbreviationAcronymTagsLookupText[];
    if (domainModel.documentType) out.documentType = domainModel.documentType;
    if (domainModel.associatedSpecies) {
      out.associatedSpecies = [];
      for (const x of domainModel.associatedSpecies) {
        out.associatedSpecies.push({
          cosewicRefId: x?.cosewicRefId,
          listingRefId: x?.listingRefId,
          commonNameEnglish: x?.commonNameEnglish,
          commonNameFrench: x?.commonNameFrench,
          population: x?.population,
          taxonomicGroup: x?.taxonomicGroup ?? undefined,
          scientificName: x?.scientificName ?? undefined,
        });
      }
    }
    if (domainModel.note) out.note = makeRichText(domainModel.note);
  }

  return out;
};

export function mapFormToDomainModel(form: Partial<DocumentFormFields>) {
  const out: Partial<CreateDocumentInput> = {};
  if (form.publicationType !== undefined)
    out.publicationType = form.publicationType;
  if (form.title !== undefined) {
    out.title = {};
    if (form.title?.english !== undefined)
      out.title.english = form.title?.english;
    if (form.title?.french !== undefined) out.title.french = form.title?.french;
  }
  if (form.contentOwner !== undefined) out.contentOwner = form.contentOwner;
  if (form.programs !== undefined) out.programs = form.programs;
  if (form.documentType !== undefined) out.documentType = form.documentType;
  if (form.associatedSpecies !== undefined) {
    out.associatedSpecies = [];
    if (form.associatedSpecies) {
      for (const x of form.associatedSpecies) {
        out.associatedSpecies?.push({
          cosewicRefId: x.cosewicRefId,
          listingRefId: x.listingRefId,
          commonNameEnglish: {
            name: { text: x.commonNameEnglish?.name?.text },
          },
          commonNameFrench: { name: { text: x.commonNameFrench?.name?.text } },
          population: x.population ?? undefined,
          taxonomicGroup: x?.taxonomicGroup ?? undefined,
          scientificName: { text: x?.scientificName?.text },
        });
      }
    }
  }
  if (form.note !== undefined) out.note = form.note;

  return out;
}
