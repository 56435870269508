import { ApolloError } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddPhotoLicenseForm, {
  LicenseFileFields,
} from "../LicenseFileForm/AddLicenseFileForm";

export interface AddPhotoLicensePopupProps {
  modalState: OverlayTriggerState;
  photoLicenseId: string;
  defaultValues?: LicenseFileFields;
  refetchQuery: () => void;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const AddPhotoLicensePopup: React.FC<AddPhotoLicensePopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, photoLicenseId, defaultValues, refetchQuery } = props;

  const onClose = () => {
    modalState.close();
    refetchQuery();
    // reset();
  };

  if (!modalState.isOpen) return null;
  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_licence")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        {/* <GraphqlError title="" errors={error} /> */}
        {/* {loading && <LoadingIndicator centered />} */}

        <AddPhotoLicenseForm
          photoLicenseId={photoLicenseId}
          defaultValues={defaultValues}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddPhotoLicensePopup;

export const useAddPhotoLicensePopup = useOverlayTriggerState;
export type { LicenseFileFields };
