import * as React from "react";
import { useTranslation } from "react-i18next";

export interface PageSizeSelectProps {
  pageSize: number;
  onChangePageSize: (newPageSize: number) => void;
}

type LabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

export const PageSizeSelect: React.FC<PageSizeSelectProps & LabelProps> = ({
  pageSize,
  onChangePageSize,
  ...props
}) => {
  const { t } = useTranslation();
  const onChange = (e: any) => {
    onChangePageSize(Number(e.currentTarget.value));
  };

  return (
    <label htmlFor="sel_show_entries" {...props}>
      {t("show")}&nbsp;
      <select
        id="sel_show_entries"
        value={pageSize}
        onChange={onChange}
        data-testid="pagination-page-size-select"
      >
        <option value="10" data-testid="pagination-page-size-option-10">
          10
        </option>
        <option value="20" data-testid="pagination-page-size-option-20">
          20
        </option>
        <option value="50" data-testid="pagination-page-size-option-50">
          50
        </option>
        <option value="100" data-testid="pagination-page-size-option-100">
          100
        </option>
      </select>
      &nbsp;{t("entries")}
    </label>
  );
};

export default PageSizeSelect;
