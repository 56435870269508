import { isEmpty, isNumber, isString, trim } from "lodash";

const isNullOrEmpty = (s?: any | number | null) => {
  if (isNumber(s)) {
    return false;
  }

  if (isString(s)) {
    return !trim(s);
  }

  return isEmpty(s);
};

export default isNullOrEmpty;
