import { Alert, AlertTypes } from "components/atoms/Alert";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { bilingualRichTextForLanguage } from "mappers";
import { PermitPathHelpers } from "pages/permit/PermitRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";
import EditLink from "../../../components/atoms/EditLink";
import MissingData from "../../../components/atoms/MissingData";
import {
  ROLE_ACTIONS,
  RenderWhenAuthorized,
} from "../../../features/auth/components";
import { Maybe, PermitDescription } from "../../../generated/gql-types";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../atoms/SafeRenderHtml";
import SectionCard from "../../atoms/SectionCard";

export interface PDInfoCardProps {
  permitDescription?: Maybe<PermitDescription>;
}

export const PDInfoCard: React.FC<PDInfoCardProps> = (props) => {
  const { permitDescription } = props;
  const { t, i18n } = useTranslation();

  const locationOfActivities =
    i18n.language === "fr"
      ? permitDescription?.locationOfActivities
          ?.map((x) => x?.nameFr)
          .join(", ")
      : permitDescription?.locationOfActivities
          ?.map((x) => x?.nameEn)
          .join(", ");

  const permitPurposes = permitDescription?.purposes
    ?.map((pp) => t(pp as string))
    .join(", ");

  const authoritiesUsed = permitDescription?.authoritiesUsed
    ?.map((au) => t(au as string))
    .join(", ");

  const federalCanadaPlaces =
    i18n.language === "fr"
      ? permitDescription?.federalCanadaPlaces
          ?.map((x) => x?.name?.french)
          .join(", ")
      : permitDescription?.federalCanadaPlaces
          ?.map((x) => x?.name?.english)
          .join(", ");

  const issuingAuthorities =
    i18n.language === "fr"
      ? permitDescription?.issuingAuthority?.name?.french
      : permitDescription?.issuingAuthority?.name?.english;

  const notice =
    i18n.language === "fr"
      ? permitDescription?.notice?.french?.text
      : permitDescription?.notice?.english?.text;

  return (
    <>
      {/* -------------- Permit IDs -------------- */}
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2>{t("permit_ids")}</h2>
          </div>
        }
        showLine={false}
      >
        {permitDescription?.permitIdDetails?.map((pd, index) => (
          <div
            className="row list-item separator-line species-field px-0 py-2_5"
            key={index}
          >
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("id")}</dt>
                <dd className="mrgn-bttm-0">
                  {isNullOrEmpty(pd?.permitId) ? <MissingData /> : pd?.permitId}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("issuance_date")}</dt>
                <dd className="mrgn-bttm-0">
                  {isNullOrEmpty(pd?.issuanceDate) ? (
                    <MissingData />
                  ) : (
                    formatTimestamp(pd?.issuanceDate)
                  )}
                </dd>
              </div>
            </dl>
          </div>
        ))}
        {/* -------------- Notice -------------- */}
        {!htmlIsNullOrEmpty(notice) && (
          <dl className="font-size-16">
            <dt className="text-muted wb-inv">{t("notice")}</dt>
            <dd className="mrgn-bttm-0">
              <Alert
                className="mrgn-bttm-0"
                type={AlertTypes.INFO}
                content={notice || ""}
              />
            </dd>
          </dl>
        )}
      </SectionCard>

      {/* -------------- Permit description information -------------- */}
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2>{t("permit_description_information")}</h2>
            {permitDescription?.requiresValidation && (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.permit.update}
              >
                <EditLink
                  linkTo={PermitPathHelpers.PermitEdit(
                    permitDescription?.id ?? "error"
                  )}
                />
              </RenderWhenAuthorized>
            )}
          </div>
        }
      >
        <div className="species-field">
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("start_date")}
                  <TooltipAlert
                    isVisible={permitDescription?.permitDates?.fromDate == null}
                  >
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {permitDescription?.permitDates?.fromDate == null ? (
                    <MissingData />
                  ) : (
                    formatTimestamp(permitDescription?.permitDates?.fromDate)
                  )}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("end_date")}
                  <TooltipAlert
                    isVisible={permitDescription?.permitDates?.toDate == null}
                  >
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {permitDescription?.permitDates?.toDate == null ? (
                    <MissingData />
                  ) : (
                    formatTimestamp(permitDescription?.permitDates?.toDate)
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("permit_purpose")}
                  <TooltipAlert isVisible={isNullOrEmpty(permitPurposes)}>
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {isNullOrEmpty(permitPurposes) ? (
                    <MissingData />
                  ) : (
                    permitPurposes
                  )}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("permit_type")}</dt>
                <dd>
                  {permitDescription?.type == null ? (
                    <MissingData />
                  ) : (
                    t(permitDescription?.type)
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("description_of_activity")}, {t("english")}
                  <TooltipAlert
                    isVisible={htmlIsNullOrEmpty(
                      permitDescription?.description?.english?.text
                    )}
                  >
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    permitDescription?.description?.english?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={permitDescription?.description?.english?.text}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("description_of_activity")}, {t("french")}
                  <TooltipAlert
                    isVisible={htmlIsNullOrEmpty(
                      permitDescription?.description?.french?.text
                    )}
                  >
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    permitDescription?.description?.french?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={permitDescription?.description?.french?.text}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("pre_conditions")}, {t("english")}
                  <TooltipAlert
                    isVisible={htmlIsNullOrEmpty(
                      permitDescription?.preConditions?.english?.text
                    )}
                  >
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    permitDescription?.preConditions?.english?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        permitDescription?.preConditions?.english?.text
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("pre_conditions")}, {t("french")}
                  <TooltipAlert
                    isVisible={htmlIsNullOrEmpty(
                      permitDescription?.preConditions?.french?.text
                    )}
                  >
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    permitDescription?.preConditions?.french?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        permitDescription?.preConditions?.french?.text
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("authority_used_act_of_parliament")}
                  <TooltipAlert isVisible={isNullOrEmpty(authoritiesUsed)}>
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {isNullOrEmpty(authoritiesUsed) ? (
                    <MissingData />
                  ) : (
                    authoritiesUsed
                  )}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">
                  {t("location_of_activity")}
                  <TooltipAlert isVisible={isNullOrEmpty(locationOfActivities)}>
                    {t("required_to_make_pd_final_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {isNullOrEmpty(locationOfActivities) ? (
                    <MissingData />
                  ) : (
                    locationOfActivities
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("other_relevant_information")}, {t("english")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    permitDescription?.otherRelevantInformation?.english?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        permitDescription?.otherRelevantInformation?.english
                          ?.text
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("other_relevant_information")}, {t("french")}
                </dt>
                <dd>
                  {htmlIsNullOrEmpty(
                    permitDescription?.otherRelevantInformation?.french?.text
                  ) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        permitDescription?.otherRelevantInformation?.french
                          ?.text
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
          <div className="row">
            <dl>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("parks_canada_places")}</dt>
                <dd>
                  {!permitDescription?.federalCanadaPlaces ||
                  permitDescription.federalCanadaPlaces.length === 0 ? (
                    <MissingData />
                  ) : (
                    federalCanadaPlaces
                  )}
                </dd>
              </div>
              <div className="species-data col-sm-6">
                <dt className="text-muted">{t("issuing_authority")}</dt>
                <dd>
                  {!issuingAuthorities || issuingAuthorities.length === 0 ? (
                    <MissingData />
                  ) : (
                    issuingAuthorities
                  )}
                </dd>
              </div>
            </dl>
          </div>

          {/* -------------- Contact -------------- */}
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("contact")}</dt>
                <dd className="width-100">
                  {permitDescription?.contactOrOrganizationFormatted == null ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={bilingualRichTextForLanguage(
                        i18n.language,
                        permitDescription?.contactOrOrganizationFormatted
                      )}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>

          {/* ------------ Note ------------ */}
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("note")}</dt>
                <dd>
                  {htmlIsNullOrEmpty(permitDescription?.note?.text) ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={permitDescription?.note?.text ?? ""}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </SectionCard>
    </>
  );
};

export default PDInfoCard;
