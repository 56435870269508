import { PagingArguments } from "../types";
import { usePagination } from "../../../hooks/util/usePagination";

/**
 * Convert our usePagination type into azure cognitive search pagination type
 * @param args - object returned from the usePagination hook
 */
export function makePaging(pagination: ReturnType<typeof usePagination>) {
  return {
    skip: pagination.pageSize * (pagination.currentPage - 1), // NOTE: skip should be 0 based, so we subtract 1 from page number
    top: pagination.pageSize,
  };
}
