import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { filterForDirtyFields } from "util/forms";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import * as React from "react";
import {
  Land,
  NumericRangeInput, RichTextInput,
  WildLifeSpeciesPresence,
} from "generated/gql-types";
import SectionCard from "components/atoms/SectionCard";
import ListingLandAssignLandSection from "../ListingLandAssignLandSection/ListingLandAssignLandSection";
import ListingLandWildlifeSpeciesInfoSection from "../ListingLandWildlifeSpeciesInfoSection/ListingLandWildlifeSpeciesInfoSection";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";

export interface ListingLandFormFields {
  landRef?: Land;
  wildlifeSpeciesPresence?: WildLifeSpeciesPresence;
  numberOfSites?: number;
  approximateNumberOfIndividuals?: string;
  circumstance?: RichTextInput;
  source?: RichTextInput;
}

export interface ListingLandFormProps {
  onSubmit: SubmitHandler<ListingLandFormFields>;
  defaultValues?: Partial<ListingLandFormFields>;
  onCancel: () => void;
}

export const ListingLandForm: React.FC<ListingLandFormProps> = (props) => {
  const { t } = useTranslation();

  const form = useForm<ListingLandFormFields>({
    defaultValues: {
      landRef: props?.defaultValues?.landRef,

      wildlifeSpeciesPresence: props?.defaultValues?.wildlifeSpeciesPresence,
      numberOfSites: props?.defaultValues?.numberOfSites,
      approximateNumberOfIndividuals:
        props?.defaultValues?.approximateNumberOfIndividuals,
      circumstance: props?.defaultValues?.circumstance,
      source: props?.defaultValues?.source,
    },
  });

  const { handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty, errors } = formState;

  const onSubmit: SubmitHandler<Partial<ListingLandFormFields>> = async (
    formData
  ) => {
    const values = filterForDirtyFields(formData, dirtyFields);
    props.onSubmit(values as any);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionCard header={<h2>{t("assign_a_place")}</h2>}>
            <Controller
              control={control}
              name="landRef"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <ListingLandAssignLandSection
                  defaultValues={value}
                  onChange={onChange}
                />
              )}
            />

            {errors.landRef && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </SectionCard>

          <SectionCard
            header={<h2>{t("add_wildlife_species_information")}</h2>}
          >
            <ListingLandWildlifeSpeciesInfoSection
              defaultValues={props.defaultValues}
            />
          </SectionCard>

          <FormButtons isDirty={isDirty} onCancel={props.onCancel} />
        </form>
      </FormProvider>
    </>
  );
};

export default ListingLandForm;
