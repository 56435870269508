import { useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import { ContactOrOrgInUseDocument } from "generated/gql-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface ContactOrOrgInUseProps {
  contactRefId?: string;
  orgRefId?: string;
}

const ContactOrOrgInUseWarning: React.FC<ContactOrOrgInUseProps> = (props) => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(ContactOrOrgInUseDocument, {
    variables: {
      contactRefId: props.contactRefId ?? null,
      orgRefId: props.orgRefId ?? null,
    },
  });

  return error ? (
    <GraphqlError errors={error} />
  ) : loading ? (
    <LoadingIndicator centered className="mrgn-bttm-md" />
  ) : data?.contactOrOrgInUse === true ? (
    <Alert
      type={AlertTypes.WARNING}
      title={t("changes_publish_on_registry_warning_title")}
      content={t("changes_publish_on_registry_warning_message")}
    />
  ) : null;
};

export default ContactOrOrgInUseWarning;
