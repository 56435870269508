import {
  PermitDescriptionAuthorityUsed,
  PermitDescriptionInput,
  PermitDescriptionPurpose,
  PermitDescriptionQuery,
} from "generated/gql-types";
import { DisplayFormat, formatTimestamp } from "util/formatTimestamp";
import * as FormMappers from "../../../../util/formMappers";
import { PermitDescriptionFormFields } from "./PermitDescriptionForm";

export const mapDomainModelToForm = (
  domainModel: PermitDescriptionQuery["permitDescription"]
) => {
  const out: Partial<PermitDescriptionFormFields> = {};
  FormMappers.mapArrayUsingFn(
    FormMappers.mapPermitIdDetails,
    out,
    `[permitIdDetails]`,
    domainModel?.permitIdDetails ?? [{ permitId: "", issuanceDate: "" }]
  );

  FormMappers.mapBilingualAbbreviationAcronymLookupText(
    out,
    "issuingAuthority",
    domainModel?.issuingAuthority
  );

  FormMappers.mapPrimitiveType(out, "type", domainModel?.type);

  FormMappers.mapPrimitiveType(
    out,
    "explanationFor",
    domainModel?.explanationFor
  );

  FormMappers.mapDateRange(out, "permitDates", {
    fromDate:
      formatTimestamp(
        domainModel?.permitDates?.fromDate,
        DisplayFormat.YEAR_MONTH_DAY
      ) ?? "",
    toDate:
      formatTimestamp(
        domainModel?.permitDates?.toDate,
        DisplayFormat.YEAR_MONTH_DAY
      ) ?? "",
  });

  FormMappers.mapAnyArrayType(
    out,
    "purposes",
    domainModel?.purposes as PermitDescriptionPurpose[]
  );

  FormMappers.mapBilingualRichText(
    out,
    "description",
    domainModel?.description
  );
  FormMappers.mapBilingualRichText(
    out,
    "preConditions",
    domainModel?.preConditions
  );

  FormMappers.mapAnyArrayType(
    out,
    "authoritiesUsed",
    domainModel?.authoritiesUsed as PermitDescriptionAuthorityUsed[]
  );

  FormMappers.mapArrayUsingFn(
    FormMappers.mapBilingualName,
    out,
    `[locationOfActivities]`,
    domainModel?.locationOfActivities
  );

  FormMappers.mapArrayUsingFn(
    FormMappers.mapBilingualAbbreviationAcronymLookupText,
    out,
    `[federalCanadaPlaces]`,
    domainModel?.federalCanadaPlaces
  );

  FormMappers.mapBilingualRichText(
    out,
    "otherRelevantInformation",
    domainModel?.otherRelevantInformation
  );

  FormMappers.mapArrayUsingFn(
    FormMappers.mapAffectedSpecies,
    out,
    `[affectedSpecies]`,
    domainModel?.affectedSpecies
  );

  FormMappers.mapCustomContact(
    out,
    "customContact",
    domainModel?.contactRef,
    domainModel?.organizationRef
  );

  FormMappers.mapRichText(out, "note", domainModel?.note);

  return out;
};

export function mapFormToDomainModel(
  form: Partial<PermitDescriptionFormFields>
) {
  const out: Partial<PermitDescriptionInput> = {};

  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapPermitIdDetailsInput,
    out,
    `[permitIdDetails]`,
    form?.permitIdDetails
  );

  FormMappers.mapBilingualAbbreviationAcronymLookupTextInput(
    out,
    "issuingAuthority",
    form?.issuingAuthority
  );

  FormMappers.mapPrimitiveTypeInput(out, "type", form?.type);

  FormMappers.mapPrimitiveTypeInput(
    out,
    "explanationFor",
    form?.explanationFor
  );

  FormMappers.mapDateRangeInput(out, "permitDates", form?.permitDates);

  FormMappers.mapAnyArrayTypeInput(
    out,
    "purposes",
    form?.purposes as PermitDescriptionPurpose[]
  );

  FormMappers.mapBilingualRichTextInput(out, "description", form?.description);
  FormMappers.mapBilingualRichTextInput(
    out,
    "preConditions",
    form?.preConditions
  );

  FormMappers.mapAnyArrayTypeInput(
    out,
    "authoritiesUsed",
    form?.authoritiesUsed as PermitDescriptionAuthorityUsed[]
  );
  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapBilingualNameInput,
    out,
    `[locationOfActivities]`,
    form?.locationOfActivities
  );

  FormMappers.mapBilingualRichTextInput(
    out,
    "otherRelevantInformation",
    form?.otherRelevantInformation
  );

  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapAffectedSpeciesInput,
    out,
    `[affectedSpecies]`,
    form?.affectedSpecies
  );

  FormMappers.mapArrayUsingFnInput(
    FormMappers.mapBilingualAbbreviationAcronymLookupTextInput,
    out,
    `[federalCanadaPlaces]`,
    form?.federalCanadaPlaces
  );

  // Map custom 'contact' type;
  //   it acts as a container to hold either a contact or organization,
  //   as picked by the contact modal.
  FormMappers.mapCustomContactInput(out, "", form?.customContact);

  FormMappers.mapRichTextInput(out, "note", form.note);

  return out;
}
