import ResetIcon from "assets/svg/reset-icon.svg";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface QueryFields {
  search: string;
}

export const defaultQueryFields = {
  search: "",
};

export interface SearchFilterFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const SearchFilterForm: React.FC<SearchFilterFormProps> = (props) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, handleSubmit, setFocus, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    setFocus("search");
  };

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    const mapped = newValues;
    props.onSubmit(mapped);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-col">
        <div className="flex align-start flex-wrap gap-sm">
          <div className="flex-col">
            <div className="flex gap-sm align-start flex-wrap">
              <div className="input-group">
                <label className="wb-inv" htmlFor="adminSearch">
                  {t("search")}
                </label>
                <input
                  type="text"
                  id="adminSearch"
                  className="form-control search-box"
                  placeholder={t("search")}
                  {...register("search")}
                />

                <button
                  type="button"
                  aria-label="Clear search"
                  className="search-box-reset"
                  hidden={watch("search") === ""}
                  onClick={onClearSearch}
                  data-testid="button-clear"
                >
                  <img src={ResetIcon} alt={t("reset")} />
                </button>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-bttm-md"
            data-testid="button-search"
          >
            <span className="glyphicon glyphicon-search mrgn-rght-sm" />
            {t("search")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchFilterForm;
