const wetTranslation = {
  en: {
    datepicker_abbr: "YYYY-MM-DD",
    datepicker_abbr_title:
      "Four digits year, dash, two digits month, dash, two digits day",
    table_header_desc_title: "{{column}}: activate for descending sort",
    table_header_asc_title: "{{column}}: activate for ascending sort",
  },

  fr: {
    datepicker_abbr: "AAAA-MM-JJ",
    datepicker_abbr_title:
      "Les quatres chiffres de l'année, tiret, les deux chiffres du mois, tiret, les deux chiffres du jour",
    table_header_desc_title: "{{column}} : activer pour tri descendant",
    table_header_asc_title: "{{column}} : activer pour tri ascendant",
  },
};

export default wetTranslation;
