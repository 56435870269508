import SectionCard from "components/atoms/SectionCard";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import {
  BilingualTextInput,
  Land,
  LandType,
  Note,
  ProvinceAndTerritory,
} from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { filterForDirtyFields } from "util/forms";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import { FullHTMLEditorWithController } from "../../FullHTMLEditor";
import {
  LandManagerAutocomplete,
  useLandManagerAutocomplete,
} from "./LandManagerAutocomplete/LandManagerAutocomplete";
import { LandNameAutocomplete } from "./LandNameAutocomplete/LandNameAutocomplete";

export interface AdminLandsEditFormFields {
  landType: LandType;
  provinceAndTerritory: ProvinceAndTerritory;
  landName?: string;
  landManager?: BilingualTextInput;
  dfrp?: number;
  landSize?: number;
  note?: Note;
}

export interface AdminLandsEditFormProps {
  onSubmit: SubmitHandler<AdminLandsEditFormFields>;
  initialValues?: Partial<AdminLandsEditFormFields>;
}

export const AdminLandsEditForm: React.FC<AdminLandsEditFormProps> = (
  props
) => {
  const form = useForm<AdminLandsEditFormFields>({
    defaultValues: {
      dfrp: props?.initialValues?.dfrp,
      landManager: props?.initialValues?.landManager,
      landName: props?.initialValues?.landName,
      landSize: props?.initialValues?.landSize,
      landType: props?.initialValues?.landType,
      provinceAndTerritory: props?.initialValues?.provinceAndTerritory,
      note: props?.initialValues?.note,
    },
  });

  const { register, handleSubmit, formState, watch, control, setValue } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit: SubmitHandler<Partial<AdminLandsEditFormFields>> = async (
    formData
  ) => {
    const values = filterForDirtyFields(formData, dirtyFields);
    props.onSubmit(values as any);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    history.push({
      pathname: "/admin/lands",
    });
  };

  const landType = watch("landType");
  const provinceAndTerritory = watch("provinceAndTerritory");
  const landName = watch("landName");

  const landManagerAutocompleteInstance = useLandManagerAutocomplete(
    landName,
    landType
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <SectionCard header={<h2>{t("land_information")}</h2>}>
        <div className="form-group flex-col gap-md">
          <fieldset className="chkbxrdio-grp">
            <legend className="required">
              <span className="field-name">{t("land_type")}</span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>
            <label htmlFor="rdo_federal" className="radio-inline">
              <input
                type="radio"
                value={LandType.Federal}
                defaultChecked={
                  props?.initialValues?.landType === LandType.Federal
                }
                id="rdo_federal"
                data-rule-required="true"
                {...register("landType", {
                  required: true,
                })}
              />
              &#160;&#160;{t(LandType.Federal)}
            </label>
            <label
              htmlFor="rdo_provincial_territorial"
              className="radio-inline"
            >
              <input
                type="radio"
                value={LandType.ProvinceTerritory}
                defaultChecked={
                  props?.initialValues?.landType === LandType.ProvinceTerritory
                }
                id="rdo_provincial_territorial"
                {...register("landType", {
                  required: true,
                })}
              />
              &#160;&#160;{t(LandType.ProvinceTerritory)}
            </label>
            <label htmlFor="rdo_private" className="radio-inline">
              <input
                type="radio"
                value={LandType.Private}
                defaultChecked={
                  props?.initialValues?.landType === LandType.Private
                }
                id="rdo_private"
                {...register("landType", {
                  required: true,
                })}
              />
              &#160;&#160;{t(LandType.Private)}
            </label>
          </fieldset>
          {errors.landType && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
          <label htmlFor="provinceTerritoryInput" className="required">
            {t("province_territory")}
            <strong className="required"> ({t("required")})</strong>
            <select
              id="sel_province_territory"
              className="form-control"
              {...register("provinceAndTerritory", {
                required: true,
              })}
            >
              <option hidden value={""}>
                {t("please_select_option")}
              </option>
              <option value={ProvinceAndTerritory.Alberta}>
                {t(ProvinceAndTerritory.Alberta)}
              </option>
              <option value={ProvinceAndTerritory.BritishColumbia}>
                {t(ProvinceAndTerritory.BritishColumbia)}
              </option>
              <option value={ProvinceAndTerritory.Manitoba}>
                {t(ProvinceAndTerritory.Manitoba)}
              </option>
              <option value={ProvinceAndTerritory.NewBrunswick}>
                {t(ProvinceAndTerritory.NewBrunswick)}
              </option>
              <option value={ProvinceAndTerritory.NewfoundlandAndLabrador}>
                {t(ProvinceAndTerritory.NewfoundlandAndLabrador)}
              </option>
              <option value={ProvinceAndTerritory.NorthwestTerritories}>
                {t(ProvinceAndTerritory.NorthwestTerritories)}
              </option>
              <option value={ProvinceAndTerritory.NovaScotia}>
                {t(ProvinceAndTerritory.NovaScotia)}
              </option>
              <option value={ProvinceAndTerritory.Nunavut}>
                {t(ProvinceAndTerritory.Nunavut)}
              </option>
              <option value={ProvinceAndTerritory.Ontario}>
                {t(ProvinceAndTerritory.Ontario)}
              </option>
              <option value={ProvinceAndTerritory.PrinceEdwardIsland}>
                {t(ProvinceAndTerritory.PrinceEdwardIsland)}
              </option>
              <option value={ProvinceAndTerritory.Quebec}>
                {t(ProvinceAndTerritory.Quebec)}
              </option>
              <option value={ProvinceAndTerritory.Saskatchewan}>
                {t(ProvinceAndTerritory.Saskatchewan)}
              </option>
              <option value={ProvinceAndTerritory.Yukon}>
                {t(ProvinceAndTerritory.Yukon)}
              </option>
              <option value={ProvinceAndTerritory.CanadianWaters}>
                {t(ProvinceAndTerritory.CanadianWaters)}
              </option>
            </select>
            {errors.provinceAndTerritory && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </label>

          <div className="flex-col gap-sm">
            {/* NOTE: Not using LandNameAutocompleteWithController here because that
                    passes the entire Land object to onSelectionChange. Here we
                    only want the landName.
              */}
            <Controller
              control={control}
              rules={{ required: true }}
              name="landName"
              render={({ field: { value, onChange } }: any) => (
                <LandNameAutocomplete
                  label={t("place_name")}
                  isRequired
                  onSelectionChange={(x) =>
                    onChange(x && x.landName ? x.landName : "")
                  }
                  initialLandName={value}
                  landType={landType}
                  provinceAndTerritory={provinceAndTerritory}
                  id={"landNameInput"}
                  onClear={() => setValue("landName", undefined)}
                  disabled={false}
                />
              )}
            />
            {errors.landName && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>

          <div className="flex-col gap-sm">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="landManager"
              render={({ field: { onChange, value } }) => (
                <LandManagerAutocomplete
                  useLandManagerAutocompleteInstance={
                    landManagerAutocompleteInstance
                  }
                  isRequired
                  onSelectionChange={(newLandManager?: Land) => {
                    return onChange(newLandManager?.landManager);
                  }}
                  initialLandManager={value}
                  id="landManagerAutocomplete"
                  label={t("land_manager")}
                  onClear={() => {
                    landManagerAutocompleteInstance.clear();
                    setValue("landManager", undefined);
                  }}
                  disabled={false}
                />
              )}
            />
            {errors.landManager && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>

          <>
            <label htmlFor="dfrpInput" className="flex-col">
              {t("directory_of_federal_real_property")}
              <input
                id="dfrpInput"
                type="number"
                min={0}
                max={999999}
                className="form-control"
                {...register("dfrp")}
              />
            </label>
            <label htmlFor="landSizeInput" className="flex-col">
              <span>{t("land_size")} (ha)</span>

              <input
                id="landSizeInput"
                className="form-control"
                type="number"
                min={0}
                max={999000000} // Maximum land size (Bug 54146)
                step="any"
                {...register("landSize")}
              />
            </label>
          </>
        </div>
      </SectionCard>
      <SectionCard header={<h2>{t("note")}</h2>}>
        <div className="form-group">
          <label htmlFor="note">
            <span className="field-name">{t("enter_a_note")}</span>{" "}
          </label>
          <FullHTMLEditorWithController
            control={control}
            defaultValue={props?.initialValues?.note?.text?.text ?? ""}
            id="note_text"
            name="note.text.text"
          />
        </div>
      </SectionCard>
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
      />
    </form>
  );
};

export default AdminLandsEditForm;
