import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SearchListItem from "components/organisms/cosewic/SearchListItem";
import {
  SearchAssessmentDetailsDocument,
  SearchAssessmentView,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import useQueryParams from "hooks/util/useQueryParams";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";

export interface AssessmentListProps {}

const AssessmentList: React.FC<AssessmentListProps> = () => {
  const { t, i18n } = useTranslation();
  const query = useQueryParams() as any;

  const typeQuery: any = query.get("type");
  const dateQuery: any = query.get("date");

  const assessmentType = typeQuery;
  const fromDate = dateQuery;
  const toDate = dateQuery;
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortByInput, setSortByInput] = React.useState("commonName");

  const getSortByField = (sortBy: string) => {
    switch (sortBy) {
      case "scientificName":
        return "generatedScientificName.name";
      case "population":
        if (i18n.language === "fr") return "population.nameFr";
        return "population.nameEn";
      case "cosewicId":
        return "cosewicId";
      case "commonName":
      default:
        if (i18n.language === "fr") return "commonNameFrench.name";
        return "commonNameEnglish.name";
    }
  };

  const sortByField = getSortByField(sortByInput);
  const sortByOrderInput = `${sortByField}:${sortOrder}`;

  const pagination = usePagination();

  const { data, loading, error } = useQuery(SearchAssessmentDetailsDocument, {
    variables: {
      params: {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        type: assessmentType,
        fromDate: fromDate,
        toDate: toDate,
        sortBy: sortByOrderInput,
        view: SearchAssessmentView.Details,
      },
    },
  });

  const onChangeSortBy = (e: any) => {
    const sortBy = e.currentTarget.value;
    setSortByInput(sortBy);
  };

  const onChangeSortOrder = (e: any) => {
    const sortOrder = e.currentTarget.value;
    setSortOrder(sortOrder);
  };

  // TODO: better loading, error
  if (error) {
    console.log(error);
    return (
      <GraphqlError title="Error fetching assessment data" errors={error} />
    );
  }

  const listOfAssessments = data?.assessmentSearch?.details?.map((x) => {
    return {
      cosewicWs: {
        id: x?.cosewicWsRefId,
        cosewicId: x?.cosewicId,
        wsStage: x?.wsStage,
        wsState: x?.wsState,
        assessmentStatus: x?.assessmentStatus,
        commonNameEnglish: x?.commonNameEnglish,
        commonNameFrench: x?.commonNameFrench,
        population: x?.population,
        scientificName: {
          generatedScientificName: x?.generatedScientificName,
        },
      },
      assessmentId: x?.assessmentId,
    };
  });

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("wildlife_species_assessments")}</h1>
          <div className="mrgn-bttm-md">
            <span>{t("date")}: </span>
            {formatTimestamp(dateQuery) ?? <MissingData />}
            <span className="text-muted opct-30">&nbsp;|&nbsp;</span>
            <span>{t("type")}: </span>
            {t(typeQuery)}
          </div>

          <div className="content-wrap">
            {/*// <!-- Filter Results Section -->*/}
            <div id="dataset_filter" className="Card">
              <div className="row mrgn-lft-0 mrgn-rght-0 mrgn-bttm-md">
                {/*// <!-- Filter sortBy, sortOrder and resultPerPage controls -->*/}
                <div className="pull-left">
                  <ul className="list-inline">
                    <li>
                      <label htmlFor="sel_sort_by">{t("sort_by")}</label>
                      <select
                        id="sel_sort_by"
                        className="form-control"
                        value={sortByInput}
                        onChange={onChangeSortBy}
                      >
                        {/*TODO:i18n*/}
                        <option value="commonName">{t("common_name")}</option>
                        {/*// <!-- <option value="scientificName">{t("scientific_name")}</option> -->*/}
                        <option value="population">{t("population")}</option>
                        <option value="cosewicId">{t("cosewic_id")}</option>
                      </select>
                    </li>
                    <li>
                      <label htmlFor="sel_sort_order">{t("sort_order")}</label>
                      <select
                        id="sel_sort_order"
                        className="form-control"
                        value={sortOrder}
                        onChange={onChangeSortOrder}
                      >
                        <option value="asc">{t("ascending")}</option>
                        <option value="desc">{t("descending")}</option>
                      </select>
                    </li>
                  </ul>
                </div>
                {/*// <!-- <p>Export to Excel</p> -->*/}
                <div className="pull-right">
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
              {/*// <!-- Filter Listing -->*/}
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : !listOfAssessments || listOfAssessments?.length === 0 ? (
                <NoResults centered />
              ) : null}

              {listOfAssessments?.map((item, index) => (
                <SearchListItem
                  cosewicWs={item?.cosewicWs as any}
                  key={index}
                  titleLink={CosewicPathHelpers.AssessmentProfile(
                    item!.cosewicWs.id ?? "error",
                    item!.assessmentId ?? "error"
                  )}
                />
              ))}
              <Pagination
                {...pagination.paginationComponentProps}
                totalPages={data?.assessmentSearch?.pagination?.totalPages ?? 0}
              />
            </div>
          </div>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AssessmentList;
