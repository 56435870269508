import * as React from "react";
import {
  Maybe,
  CosewicWs,
  RichTextInput,
  CosewicWsStatus,
} from "generated/gql-types";
import { SubmitHandler } from "react-hook-form";
import CosewicWsForm, {
  CosewicWsFormFields,
  CosewicWsFormOutput,
} from "components/organisms/cosewic/CosewicWsForm";
import { CosewicEndemicStatus } from "types/cosewic/enums";
import { makeBilingualName, makeIdName, makeRichTextInput } from "mappers";

const makeDefaultArrayForOtherRecNames = (
  overrides?: Maybe<Array<Maybe<RichTextInput>>> | undefined
) => {
  if (overrides && overrides.length > 0) {
    return overrides as DefinitelyGQL<Array<RichTextInput>>;
  } else {
    return [makeRichTextInput()];
  }
};

const CosewicDataEntry: React.FC<CosewicDataEntryProps> = (props) => {
  const onSubmit: SubmitHandler<Partial<CosewicWsFormOutput>> = (data) => {
    props.onSubmit(data);
  };

  const formInitialValues: CosewicWsFormFields = {
    commonNameEnglish: {
      name: makeRichTextInput(props.cosewicWs?.commonNameEnglish?.name),
    },
    commonNameFrench: {
      name: makeRichTextInput(props.cosewicWs?.commonNameFrench?.name),
    },
    nameWithComma: {
      name: makeRichTextInput(props.cosewicWs?.nameWithComma?.name),
    },
    population: makeBilingualName(props.cosewicWs?.population),
    genus: makeIdName(props.cosewicWs?.scientificName?.genus),
    species: makeIdName(props.cosewicWs?.scientificName?.species),
    subSpecies: makeIdName(props.cosewicWs?.scientificName?.subSpecies),
    variety: makeIdName(props.cosewicWs?.scientificName?.variety),
    taxonomicGroup: makeBilingualName(props.cosewicWs?.taxonomicGroup),
    endemicStatus:
      (props?.cosewicWs?.endemicStatus as CosewicEndemicStatus) ??
      CosewicEndemicStatus.NotDetermined,
    ranges: props.cosewicWs?.ranges?.map((x) => makeBilingualName(x)) ?? [],

    otherRecognizedCommonNameEnglish: makeDefaultArrayForOtherRecNames(
      props.cosewicWs?.otherRecognizedCommonNameEnglish
    ),
    otherRecognizedCommonNameFrench: makeDefaultArrayForOtherRecNames(
      props.cosewicWs?.otherRecognizedCommonNameFrench
    ),
    otherRecognizedScientificName: makeDefaultArrayForOtherRecNames(
      props.cosewicWs?.otherRecognizedScientificName
    ),
    indigenousName: makeDefaultArrayForOtherRecNames(
      props.cosewicWs?.indigenousName
    ),
  };

  return (
    <CosewicWsForm
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      cosewicWsId={props.cosewicWs?.id}
      status={props.cosewicWs?.status}
    />
  );
};

export default CosewicDataEntry;

interface CosewicDataEntryProps {
  cosewicWs?: CosewicWs;
  onSubmit: SubmitHandler<Partial<CosewicWsFormOutput>>;
}
