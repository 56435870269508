import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { formatOrganizationListItem } from "components/organisms/contacts/AddContactButtonAndModal/AddContactModalSearch";
import { Organization } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const FormOrganizationDisplay: React.FC<{
  contact?: Organization;
}> = (props) => {
  const { t } = useTranslation();
  const formattedContact = formatOrganizationListItem(props.contact as any);

  return (
    <div className="mrgn-lft-sm">
      <address className="text-muted mrgn-bttm-0">
        <strong>{formattedContact?.name ?? t("no_name")}</strong>
        <br />
        {formattedContact?.address && (
          <SafeRenderHtml htmlString={formattedContact.address} />
        )}
      </address>
      {formattedContact?.note && (
        <div className="text-muted mrgn-tp-sm">
          {t("note")}: <span>{formattedContact.note}</span>
        </div>
      )}
    </div>
  );
};
