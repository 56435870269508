import MissingData from "components/atoms/MissingData";
import { RenderWhenAuthorized } from "features/auth/components";
import { ListingWs, ListingWsProcess, Maybe } from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { ROLE_ACTIONS } from "../../../../features/auth/roles";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../atoms/SafeRenderHtml";

export interface ListingInformationSectionProps {
  listingWs?: Maybe<ListingWs>;
  listingWsProcessLatest?: Maybe<ListingWsProcess>;
}

export const ListingInformationSection: React.FC<
  ListingInformationSectionProps
> = (props) => {
  const { listingWs, listingWsProcessLatest } = props;
  const { t } = useTranslation();

  const id = listingWs?.id;

  const commonNameEn = htmlRemoveOuterPTag(
    listingWs?.commonNameEnglish?.name?.text
  );
  const commonNameFr = htmlRemoveOuterPTag(
    listingWs?.commonNameFrench?.name?.text
  );

  const populationEn = listingWs?.population?.nameEn;
  const populationFr = listingWs?.population?.nameFr;

  const nameWithComma = listingWs?.nameWithComma?.name?.text;

  const scientificName =
    listingWs?.scientificName?.generatedScientificName?.name?.text;

  const schedule1Status = listingWsProcessLatest?.schedule1Status;

  const listingId = listingWs?.listingId;

  const batch = listingWsProcessLatest?.consultationProcess?.listingBatch;

  const CommonNameAndPopulation = (
    <>
      {htmlIsNullOrEmpty(commonNameEn) ? (
        <MissingData />
      ) : (
        <SafeRenderHtml htmlString={commonNameEn!} />
      )}
      {populationEn && (
        <>
          {", "}
          {populationEn}
        </>
      )}
    </>
  );

  return (
    <div className="species-field">
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("english_common_name")}</dt>
            <dd>
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.listing.view}
                fallbackComponent={CommonNameAndPopulation}
              >
                <Link
                  to={ListingPathHelpers.ListingProfile(id!)}
                  className="hover-underline"
                  data-testid="link-to-listing"
                >
                  {CommonNameAndPopulation}
                </Link>
              </RenderWhenAuthorized>
            </dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("schedule_1_status")}</dt>
            <dd>{schedule1Status ? t(schedule1Status) : <MissingData />}</dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">ID</dt>
            <dd>{listingId ?? <MissingData />}</dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("french_common_name")}</dt>
            <dd>
              {htmlIsNullOrEmpty(commonNameFr) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={commonNameFr!} />
              )}
              {populationFr && (
                <>
                  {", "}
                  {populationFr}
                </>
              )}
            </dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("english_name_with_comma")}</dt>
            <dd>
              {htmlIsNullOrEmpty(nameWithComma) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={nameWithComma!} />
              )}
            </dd>
          </div>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("scientific_name")}</dt>
            <dd>
              {htmlIsNullOrEmpty(scientificName) ? (
                <MissingData />
              ) : (
                <SafeRenderHtml htmlString={scientificName!} />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="row">
        <dl>
          <div className="species-data col-sm-4">
            <dt className="text-muted">{t("batch")}</dt>
            <dd>{batch ?? <MissingData />}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ListingInformationSection;
