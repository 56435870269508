import ResetIcon from "assets/svg/reset-icon.svg";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface UserQueryFields {
  search: string;
  showInactive: boolean;
}

export const defaultUserQueryFields = {
  search: "",
  showInactive: false,
};

export interface UsersAdminPageFilterFormProps {
  onSubmit: SubmitHandler<UserQueryFields>;
}

export const UsersAdminPageFilterForm: React.FC<
  UsersAdminPageFilterFormProps
> = (props) => {
  const { t } = useTranslation();
  const form = useForm<UserQueryFields>({
    defaultValues: defaultUserQueryFields,
  });
  const { register, handleSubmit, setFocus, getValues, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    form.setValue("showInactive", false); // For active ot inactive status
    form.handleSubmit(
      onSubmit({
        search: "",
        showInactive: false,
      })
    );
    setFocus("search");
  };

  const onShowInactive = () => {
    form.handleSubmit(
      onSubmit({
        search: getValues("search"),
        showInactive: !getValues("showInactive"),
      })
    );
  };

  const onSubmit: SubmitHandler<UserQueryFields> = (newValues) => {
    props.onSubmit(newValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-col">
        <label htmlFor="adminSearch">{t("search_name_or_email")}</label>
        <div className="flex align-start flex-wrap">
          <div className="flex-col">
            <div className="input-group mrgn-rght-sm">
              <input
                type="text"
                id="adminSearch"
                className="form-control search-box"
                placeholder={t("enter_name_or_email")}
                {...register("search")}
              />

              <button
                type="button"
                aria-label="Clear search"
                className="search-box-reset"
                hidden={watch("search") === ""}
                onClick={onClearSearch}
                data-testid="button-clear"
              >
                <img src={ResetIcon} alt={t("reset")} />
              </button>
            </div>
            <div className="checkbox">
              <label htmlFor="showInactiveCheckbox">
                <input
                  type="checkbox"
                  id="showInactiveCheckbox"
                  {...register("showInactive")}
                  onClick={onShowInactive}
                />
                &nbsp;
                {t("show_deactivated_users_only")}
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-bttm-md"
            data-testid="button-search"
          >
            <span className="glyphicon glyphicon-search mrgn-rght-sm" />
            {t("search")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UsersAdminPageFilterForm;
