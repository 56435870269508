import PlaceholderImage from "assets/svg/placeholder.svg";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { ListingWs, ListingWsProcess } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import parseSchedule1Date from "util/listing/parseSchedule1Date";

interface RelatedSpeciesListItemProps {
  titleLink?: string;
  listingWs?: ListingWs;
  listingProcessLatest?: ListingWsProcess;
  imgUrl?: string;
  showImage?: boolean;
}

const RelatedSpeciesListItem: React.FC<RelatedSpeciesListItemProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  if (!props.listingWs) return <div>Invalid Listing WS</div>;

  const commonName =
    i18n.language === "fr"
      ? props.listingWs.commonNameFrench?.name?.plainText
      : props.listingWs.commonNameEnglish?.name?.plainText;

  const population =
    i18n.language === "fr"
      ? props.listingWs.population?.nameFr
      : props.listingWs.population?.nameEn;

  const listingId = props.listingWs.listingId;
  const scientificName =
    props.listingWs.scientificName?.generatedScientificName?.name?.text;
  const schedule1Status = props.listingProcessLatest?.schedule1Status;
  const dateOfAdditionOnSchedule1 = parseSchedule1Date(
    props.listingWs.schedule1Date
  );

  return (
    <div className="flex">
      {props.showImage && (
        <div>
          <img
            src={props.imgUrl ? props.imgUrl : PlaceholderImage}
            className="list-item-thumbnail mrgn-rght-md"
            alt=""
          />
        </div>
      )}
      <div className="flex-col width-100">
        <div className="flex justify-between">
          <div>
            {props.titleLink ? (
              <Link to={props.titleLink} className="hover-underline">
                <h2 className="mrgn-tp-0 mrgn-bttm-0">
                  {commonName ?? <MissingData />}
                </h2>
              </Link>
            ) : (
              <h2 className="mrgn-tp-0 mrgn-bttm-sm">
                {commonName ?? <MissingData />}
              </h2>
            )}
            <div className="font-size-14 mrgn-bttm-md text-muted">
              {population ?? <MissingData />}
            </div>
          </div>
          <div>{props.children}</div>
        </div>
        <div className="species-field row">
          <dl>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("scientific_name")}</dt>
              <dd className="font-size-16">
                {htmlIsNullOrEmpty(scientificName) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={scientificName!} />
                )}
              </dd>
            </div>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("on_schedule_1")}</dt>
              <dd className="font-size-16">
                {dateOfAdditionOnSchedule1 ?? <MissingData />}
              </dd>
            </div>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("listing_id")}</dt>
              <dd className="font-size-16">{listingId ?? <MissingData />}</dd>
            </div>
            <div className="species-data col-sm-3">
              <dt className="text-muted">{t("listing_status")}</dt>
              <dd className="font-size-16">
                {schedule1Status ? t(schedule1Status) : <MissingData />}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default RelatedSpeciesListItem;
