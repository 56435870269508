import { useTranslation } from "react-i18next";
import * as React from "react";
import { useCallback, useEffect } from "react";
import BasicSearchFacetAutocomplete, {
  BasicSearchAutocompleteFilterProps,
} from "./BasicSearchFacetAutocomplete";
import useBasicSearch from "../../../../pages/search/hooks/useBasicSearch";

export interface BasicSearchAutocompleteListFilterProps {
  id: BasicSearchAutocompleteFilterProps["id"];
  label: BasicSearchAutocompleteFilterProps["label"];
  facetName: BasicSearchAutocompleteFilterProps["facetName"];
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const BasicSearchFacetMultiSelectList = (
  props: BasicSearchAutocompleteListFilterProps
) => {
  const { t } = useTranslation();
  const [selectedSearchItem, setSelectedSearchItem] = React.useState<
    string | undefined
  >(undefined);

  const makeLabel = useCallback(
    (value: string) => {
      return `${props.label} = ${value}`;
    },
    [props.label]
  );

  const onRemoveFromList = (value: string) => {
    props.basicSearch.dispatch({
      type: "toggle_checkbox_filter",
      filter: {
        value: value,
        label: makeLabel(value),
        facet: props.facetName,
      },
    });
  };

  useEffect(
    function autoAddToList() {
      if (selectedSearchItem != undefined) {
        props.basicSearch.dispatch({
          type: "toggle_checkbox_filter",
          filter: {
            value: selectedSearchItem,
            label: makeLabel(selectedSearchItem),
            facet: props.facetName,
          },
        });
      }
    },
    [selectedSearchItem]
  );

  return (
    <>
      <div className="mrgn-bttm-md">
        <BasicSearchFacetAutocomplete
          id={props.id}
          label={props.label}
          facetName={props.facetName}
          onSelectionChange={setSelectedSearchItem}
          placeholder={selectedSearchItem ?? ""}
        />
        <ul className="multi-select-list">
          {props.basicSearch.state?.checkboxFilters[props.facetName]?.map(
            (x) => {
              return (
                <BasicSearchMultiSelectListItem
                  id={x.value}
                  name={t(x.value)}
                  onRemove={() => onRemoveFromList(x.value)}
                  key={x.value}
                />
              );
            }
          )}
        </ul>
      </div>
      <div className="clearfix"></div>
    </>
  );
};

export interface BasicSearchMultiSelectListItemProps {
  id?: string;
  name?: string;
  onRemove: () => void;
}

export const BasicSearchMultiSelectListItem: React.FC<
  BasicSearchMultiSelectListItemProps
> = (props) => {
  return (
    <li className="multi-select-list-item flex justify-between font-size-14 align-start">
      {props.name ?? "-"}
      <span
        className="glyphicon glyphicon-remove mrgn-tp-sm mrgn-bttm-sm font-size-12 pointer color-danger"
        onClick={props.onRemove}
        tabIndex={0}
      />
    </li>
  );
};
