import AddFiles from "assets/svg/add.svg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddFilesPopup, {
  AddFilesPopupProps,
  useAddFilesPopup,
} from "./AddFilesPopup";

interface AddFilesBtnProps extends Omit<AddFilesPopupProps, "modalState"> {
  buttonLabel?: string;
  showImage?: boolean;
}

const AddFilesBtn: React.FC<AddFilesBtnProps> = ({
  buttonLabel,
  showImage,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useAddFilesPopup({});
  const validDocumentId =
    props.documentId != null && props.documentId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validDocumentId}
        data-testid="button-add-files"
      >
        {showImage ? (
          <>
            <img src={AddFiles} alt={t("add_files")} />
            <div className={"no-results-content"}>
              {buttonLabel ? t(buttonLabel) : t("add_files")}
            </div>
          </>
        ) : (
          <>
            <i className="far fa-file mrgn-rght-sm" />
            {buttonLabel ? t(buttonLabel) : t("add_files")}
          </>
        )}
      </button>
      {validDocumentId ? (
        <AddFilesPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddFilesBtn;
