import { useMutation, useQuery } from "@apollo/client";
import AddPhaseIcon from "assets/svg/add-phase-icon.svg";
import GraphqlError from "components/GraphqlError";
import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PhaseInformationCard from "components/molecules/admin/bptTemplates/Phase/PhaseInformationCard/PhaseInformationCard";
import AddStepBtn from "components/molecules/admin/bptTemplates/Step/AddStepModal/AddStepBtn";
import PhaseSelectionDropdownForm, {
  PhaseSelectionFields,
} from "components/molecules/admin/bptTemplates/Step/PhaseSelectionDropdown/PhaseSelectionDropdownForm";
import { StepBundle } from "components/molecules/admin/bptTemplates/Step/StepBundle/StepBundle";
import StepSectionCardHeader from "components/molecules/admin/bptTemplates/Step/StepInformationCard/StepSectionCardHeader";
import ReturnToButton from "components/molecules/admin/bptTemplates/utility/ReturnToButton";
import TotalCalculatedDuration from "components/molecules/admin/bptTemplates/utility/TotalCalculatedDuration";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateDocument,
  BptTemplatePhaseDocument,
  BptTemplatePhaseListDocument,
  BptTemplateStage,
  BptTemplateStepListDocument,
  DeleteBptTemplatePhaseDocument,
  DeleteBptTemplateStepDocument,
  Scalars,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { AdminPathHelpers } from "../../../../AdminRouter";

const PhaseIndexPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { templateId, phaseId } = params as any;
  const [collapse, setCollapse] = React.useState(false);
  const adminAlertMsg = useAdminAlertMsg(t("step"));
  const alertContext = useGlobalAlertContext();
  const history = useHistory();

  // Query Template information
  const { loading, error, data } = useQuery(BptTemplateDocument, {
    variables: {
      templateId: templateId,
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
  });

  // Query phase information
  const {
    loading: loadingPhase,
    error: errorPhase,
    data: dataPhase,
  } = useQuery(BptTemplatePhaseDocument, {
    variables: {
      templateId: templateId,
      phaseId: phaseId,
    },
    skip: templateId == null || phaseId == null,
    fetchPolicy: "network-only",
  });

  // Query phase list information of the template above
  const {
    loading: loadingPhaseList,
    error: errorPhaseList,
    data: dataPhaseList,
  } = useQuery(BptTemplatePhaseListDocument, {
    variables: {
      params: {
        templateId: templateId,
        pageNumber: 1,
        pageSize: 200,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
  });

  // Query step list information of the template/phase above
  const {
    loading: loadingStepList,
    error: errorStepList,
    data: dataStepList,
  } = useQuery(BptTemplateStepListDocument, {
    variables: {
      params: {
        templateId: templateId,
        phaseId: phaseId,
        pageNumber: 1,
        pageSize: 50,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
  });

  const [deleteStep, { loading: loadingDeleteStep, error: errorDeleteStep }] =
    useMutation(DeleteBptTemplateStepDocument, {
      refetchQueries: ["BptTemplateStepList"],
    });

  const [
    deletePhase,
    { loading: loadingDeletePhase, error: errorDeletePhase },
  ] = useMutation(DeleteBptTemplatePhaseDocument, {
    refetchQueries: ["BptTemplatePhaseList"],
  });

  const onDeletePhase = async (
    templateId: Scalars["ID"],
    phaseId: Scalars["ID"]
  ) => {
    try {
      const res = await deletePhase({
        variables: {
          templateId: templateId,
          phaseId: phaseId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_delete_phase"),
        timeOut: 5000,
      });

      history.push({
        pathname: AdminPathHelpers.BptTemplatesIndex(templateId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteStep = async (
    templateId: Scalars["ID"],
    stepId: Scalars["ID"]
  ) => {
    try {
      const res = await deleteStep({
        variables: {
          templateId: templateId,
          stepId: stepId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_delete_step"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCollapseAll = async () => {
    $("details").removeAttr("open");
    setCollapse(false);
  };

  const onReturnToTemplate = async () => {
    history.push({
      pathname: AdminPathHelpers.BptTemplatesIndex(templateId),
    });
  };

  const onPhaseSelectionSubmit = (arr: PhaseSelectionFields) => {
    flushSync(() => {
      history.push({
        pathname: AdminPathHelpers.BptTemplatesPhaseIndex(
          templateId,
          arr.selectedItem
        ),
      });
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          {/* ----- Phase name section ----- */}
          <h1>
            <SafeRenderHtml
              htmlString={htmlRemoveOuterPTag(
                dataPhase?.bptTemplatePhase?.name?.text
              )}
            />
          </h1>
          {/* ----- Alert/Errors section ----- */}
          <GlobalAlert />
          {/* ----- Loading error of template ----- */}
          <GraphqlError
            title={t("error_loading_bpt_Template")}
            errors={error}
          />
          {/* ----- Loading error of phase ----- */}
          <GraphqlError
            title={t("error_loading_bpt_template_phase")}
            errors={errorPhase}
          />
          {/* ----- Loading error of phase list ----- */}
          <GraphqlError
            title={t("error_loading_bpt_template_phase_list")}
            errors={errorPhaseList}
          />
          {/* ----- Loading error of step list ----- */}
          <GraphqlError
            title={t("error_loading_bpt_template_step_list")}
            errors={errorStepList}
          />

          {loading || loadingPhase || loadingPhaseList || loadingStepList ? (
            <LoadingIndicator />
          ) : !error && !errorPhase && !errorPhaseList && !loadingStepList ? (
            <>
              {/* ----- Switch to other phase/Phase selection section ----- */}
              <div className="flex justify-between align-center">
                <div className="flex font-size-18">
                  <PhaseSelectionDropdownForm
                    onSubmit={onPhaseSelectionSubmit}
                    phaseList={dataPhaseList?.bptTemplatePhaseList?.phases}
                    phaseid={phaseId}
                  />
                </div>

                {data?.bptTemplate?.stage === BptTemplateStage.Draft ? (
                  <div>
                    <RenderWhenAuthorized
                      authorizedRoles={
                        ROLE_ACTIONS.administration.bpt.updateRoles
                      }
                    >
                      <DeleteButtonModal
                        buttonText={t("delete_phase")}
                        buttonTitle={t("delete_phase")}
                        modalTitle={t("delete_phase")}
                        alertContent={t("html_delete_phase_warning")}
                        alertConfirm={t("delete_confirm")}
                        alertType={AlertTypes.DANGER}
                        onDelete={() =>
                          onDeletePhase(
                            templateId ?? "error",
                            phaseId ?? "error"
                          )
                        }
                        className="font-size-14 hover-grey vertical-align-baseline"
                        loading={loadingDeletePhase}
                      />
                    </RenderWhenAuthorized>
                  </div>
                ) : null}
              </div>

              {/* ----- Phase information section ----- */}
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : (
                <PhaseInformationCard
                  templateId={templateId}
                  phaseInfo={dataPhase?.bptTemplatePhase}
                  isTemplateDraft={
                    data?.bptTemplate?.stage === BptTemplateStage.Draft
                  }
                />
              )}

              {/* ----- The All Steps Section ----- */}
              <SectionCard
                header={
                  <StepSectionCardHeader
                    templateId={templateId}
                    stepBundles={dataStepList?.bptTemplateStepList?.stepBundles}
                    disabled={
                      dataStepList?.bptTemplateStepList?.stepBundles == null ||
                      dataStepList?.bptTemplateStepList?.stepBundles?.length ===
                        0
                    }
                    onCollapseAll={onCollapseAll}
                  />
                }
                contentAreaProps={{ className: "mrgn-tp-sm" }}
                className="pb-0"
                showLine={true}
              >
                {dataStepList?.bptTemplateStepList?.stepBundles == null ||
                dataStepList?.bptTemplateStepList?.stepBundles?.length === 0 ? (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.bpt.create}
                    fallbackComponent={<NoResults centered />}
                  >
                    <div className="text-center mrgn-tp-md">
                      <div className="lead mrgn-tp-md mrgn-bttm-sm">
                        {/* ------------ Add step button ------------ */}
                        {data?.bptTemplate?.stage === BptTemplateStage.Draft ? (
                          <AddStepBtn
                            templateId={data?.bptTemplate?.id ?? ""}
                            phaseId={dataPhase?.bptTemplatePhase?.id ?? ""}
                            refetchQueries={["BptTemplateStepList"]}
                            buttonLabel={t("no_steps_please_add")}
                            showImage
                            onCompleted={(data: any) => {
                              data?.name &&
                                adminAlertMsg.onCreateSuccess(data?.name?.text);
                            }}
                            onError={adminAlertMsg.onCreateError}
                          />
                        ) : (
                          <>
                            <img src={AddPhaseIcon} alt="" />
                            <div className={"no-results-content font-size-18"}>
                              {t("no_steps")}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </RenderWhenAuthorized>
                ) : (
                  dataStepList?.bptTemplateStepList?.stepBundles?.map(
                    (bundleItem, index) => {
                      return (
                        <StepBundle
                          key={index}
                          isOpen={collapse}
                          stepBundle={bundleItem!}
                          bptTemplate={data?.bptTemplate!}
                          templateId={templateId}
                          isLoading={
                            loading ||
                            loadingPhase ||
                            loadingPhaseList ||
                            loadingStepList
                          }
                          onDeleteStep={onDeleteStep}
                        />
                      );
                    }
                  )
                )}

                <div className="pt-0 separator-line-top mrgn-tp-20"></div>
                <div className="flex justify-between align-center py-3">
                  <div>
                    {data?.bptTemplate?.stage === BptTemplateStage.Draft ? (
                      <RenderWhenAuthorized
                        authorizedRoles={ROLE_ACTIONS.administration.bpt.create}
                      >
                        {/* ------------ Add step button ------------ */}
                        <AddStepBtn
                          templateId={data?.bptTemplate?.id ?? ""}
                          phaseId={dataPhase?.bptTemplatePhase?.id ?? ""}
                          refetchQueries={["BptTemplateStepList"]}
                          buttonLabel={t("add_step")}
                          onCompleted={(data: any) => {
                            data?.name &&
                              adminAlertMsg.onCreateSuccess(data?.name?.text);
                          }}
                          onError={adminAlertMsg.onCreateError}
                        />
                      </RenderWhenAuthorized>
                    ) : null}
                  </div>

                  {/* ------------ Total calculated duration ------------ */}
                  <TotalCalculatedDuration
                    calculatedDuration={
                      dataPhase?.bptTemplatePhase?.calculatedDuration
                    }
                  />
                </div>
              </SectionCard>

              {/* ------------- Return to template button ------------- */}
              <ReturnToButton
                returnTarget={data?.bptTemplate?.name?.text!}
                onClick={onReturnToTemplate}
              />
            </>
          ) : null}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default PhaseIndexPage;
