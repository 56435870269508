import { getApiSearchServiceUrl } from "azure/environment";
import { getUserAccessToken } from "../../features/auth/CustomMsalProvider";

const searchServiceUrl = getApiSearchServiceUrl();
// Similar to AzureSearchParams, but has a few differences.
// This interface is for calling our own SearchWS service.
export interface SearchServiceExportParams {
  queryType?: string;
  search?: string;
  searchFields: string[];
  filter?: string; // OData string
  searchTemplateName?: "FULL";
  select?: string[];
  count?: boolean;
  skip?: number;
  orderby?: string[];
  top?: number;
  includeTotalCount?: boolean;
}

export const fetchExport = async (
  params: {
    searchParameters: Partial<SearchServiceExportParams>;
    columnHeaders?: Array<{ key: string; name: string }>;
    languageCode?: "en" | "fr";
  },
  indexName: string
) => {
  let outputFileName = "sardmts-export-" + new Date().toDateString() + ".csv"; // default fallback filename
  const body = params;
  const token = await getUserAccessToken();

  const languageHeader = params.languageCode === "fr" ? "fr-CA" : "en-CA";

  const exportUrl = `${searchServiceUrl}index/${indexName}/export`;

  return await fetch(exportUrl, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Accept-Language": languageHeader,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }

      const contentDispositionHeader = res.headers.get("Content-Disposition");

      if (contentDispositionHeader != null) {
        const pattern = /filename=(?<filename>.+.csv);/g;
        const groups = pattern.exec(contentDispositionHeader)?.groups;

        if (
          groups != null &&
          groups.filename != null &&
          groups.filename.length > 0
        ) {
          outputFileName = groups.filename;
        }
      }

      return res;
    })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
};
