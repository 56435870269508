import { useTranslation } from "react-i18next";
import useBasicSearch from "../../../pages/search/hooks/useBasicSearch";
import isNullOrEmpty from "../../../util/isNullOrEmpty";
import { AzureSearchDateRange } from "../../organisms/search/FilterOptionsSection/filters/filters";
import DatePicker from "../DatePicker";

export interface BasicSearchDateRangeProps {
  label: string;
  facetName: string;
  open?: boolean; // whether or not the details section is expanded or not
  allFacets: any;
  basicSearch: ReturnType<typeof useBasicSearch>;
}

export const BasicSearchDateRange: React.FC<BasicSearchDateRangeProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  const value =
    props.basicSearch.state.dateRangeFilters[props.facetName]?.value;

  const geString = value?.ge ? value.ge.toISOString().substring(0, 10) : "";
  const leString = value?.le ? value.le.toISOString().substring(0, 10) : "";

  // Set time to the end of day (23:59:59).
  const setTimeToEndOfDay = (date: Date) => {
    date.setDate(date.getDate() + 1);
    date.setTime(date.getTime() - 1000);
    return date;
  };

  const setFromDate = (newDateString?: string) => {
    let newValue: AzureSearchDateRange;

    if (!newDateString || isNullOrEmpty(newDateString)) {
      newValue = { ...value, ge: undefined };
    } else {
      newValue = { ...value, ge: new Date(newDateString) };
    }

    props.basicSearch.dispatch({
      type: "update_date_range_filter",
      filter: { label: props.label, facet: props.facetName, value: newValue },
    });
  };

  const setToDate = (newDateString?: string) => {
    let newValue: AzureSearchDateRange;

    if (!newDateString || isNullOrEmpty(newDateString)) {
      newValue = { ...value, le: undefined };
    } else {
      newValue = { ...value, le: setTimeToEndOfDay(new Date(newDateString)) };
    }

    props.basicSearch.dispatch({
      type: "update_date_range_filter",
      filter: { label: props.label, facet: props.facetName, value: newValue },
    });
  };

  const selectedCountInSection = props.basicSearch.getCountForSection(
    props.facetName
  );

  return (
    <>
      <details className="species-details" open={props.open}>
        <summary>
          {props.label}
          {selectedCountInSection > 0 ? (
            <span className="badge">
              {selectedCountInSection}
              <span className="wb-inv"> options selected</span>
            </span>
          ) : null}
        </summary>

        <DatePicker
          label={t("from")}
          value={geString}
          onInput={setFromDate}
          max={leString}
        />
        <DatePicker
          label={t("to")}
          value={leString}
          onInput={setToDate}
          min={geString}
        />
      </details>
    </>
  );
};

export default BasicSearchDateRange;
