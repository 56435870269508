const adminGenusTranslation = {
  en: {
    genus: "Genus",
    list_of_genus: "List of genus",
    select_a_genus: "Select a genus",
    create_a_genus: "Create a Genus",
    enter_genus_name: "Enter a genus name",
    edit_genus: "Edit Genus",
    reactivate_a_genus: "Reactivate a Genus",
    deactivate_a_genus: "Deactivate a Genus",
    archive_genus_warning:
      "You will no longer be able to add this Genus to a Wildlife Species.",
    unarchive_genus_warning:
      "You will be able to add this Genus to a Wildlife Species.",
    no_genus_selected: "No genus was selected",
    archive_a_genus: "Archive a genus",
    unarchive_a_genus: "Unarchive a genus",
  },
  fr: {
    genus: "Genre",
    list_of_genus: "Liste des genres",
    select_a_genus: "Sélectionnez un genre",
    create_a_genus: "Créer un genre",
    enter_genus_name: "Entrez un nom de genre",
    edit_genus: "Editer le genre",
    reactivate_a_genus: "Réactiver un genre",
    deactivate_a_genus: "Désactiver un genre",
    archive_genus_warning:
      "Vous ne pourrez plus ajouter ce genre à une espèce sauvage.",
    unarchive_genus_warning:
      "Vous pourrez ajouter ce genre à une espèce sauvage.",
    no_genus_selected: "Aucun genre n'a été sélectionné",
    archive_a_genus: "Archiver un genre",
    unarchive_a_genus: "Désarchiver un genre",
  },
};

export default adminGenusTranslation;
