import { ChangeMetaData } from "features/changeTracking/types";
import { ChangeTypeType } from "generated/gql-types";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import { filterForDirtyFields } from "util/forms";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import { CosewicWsFormChangeTracking, CosewicWsFormOutput } from "./index";

export const processCosewicWsFormData = async (
  formData: UnpackNestedValue<Partial<CosewicWsFormOutput>>,
  dirtyFields: FieldNamesMarkedBoolean<any>
) => {
  const values = filterForDirtyFields(formData, dirtyFields);
  await setNullForEmptyTextInputs(values, dirtyFields);
  await filterOutEmptyStringForOtherRecognizedNames(values);
  await filterOutEmptyStringForIndigenousNames(values);
  await defaultChangeTrackingValues(values);

  return values;
};

const filterOutEmptyStringForOtherRecognizedNames = async (
  values: Partial<UnpackNestedValue<CosewicWsFormOutput>>
) => {
  // filter empty strings out of all the otherRecognizedName arrays

  if (values.otherRecognizedCommonNameEnglish)
    values.otherRecognizedCommonNameEnglish =
      values.otherRecognizedCommonNameEnglish.filter(
        (x) => !htmlIsNullOrEmpty(x.text)
      );

  if (values.otherRecognizedCommonNameFrench)
    values.otherRecognizedCommonNameFrench =
      values.otherRecognizedCommonNameFrench.filter(
        (x) => !htmlIsNullOrEmpty(x.text)
      );

  if (values.otherRecognizedScientificName)
    values.otherRecognizedScientificName =
      values.otherRecognizedScientificName.filter(
        (x) => !htmlIsNullOrEmpty(x.text)
      );
};

const filterOutEmptyStringForIndigenousNames = async (
  values: Partial<UnpackNestedValue<CosewicWsFormOutput>>
) => {
  // filter empty strings out of the indigenousName array

  if (values.indigenousName)
    values.indigenousName = values.indigenousName.filter(
      (x) => !htmlIsNullOrEmpty(x.text)
    );
};

const setNullForEmptyTextInputs = (values: any, dirtyFields: any) => {
  // NOTE: only map french here, as 'Common name english' and 'Name with comma' are required fields

  if (
    dirtyFields?.commonNameFrench?.name?.text &&
    htmlIsNullOrEmpty(values?.commonNameFrench?.name?.text) // Only checking .text, since the form input directly writes to .text
  ) {
    values.commonNameFrench = null;
  }
};

const defaultChangeTrackingValues = async (
  values: Partial<CosewicWsFormChangeTracking>
) => {
  // Local util functions

  const formatChangeTracking = (changeMetaData: ChangeMetaData) => {
    if (changeMetaData.changeType == null) {
      changeMetaData.changeType = ChangeTypeType.Minor;
    }

    if (changeMetaData.reasonForChange == null) {
      changeMetaData.reasonForChange = "MISSING_REASON";
    }

    return changeMetaData;
  };

  const defaultsForKey = (key: keyof typeof values) => {
    if (values[key] == null) return;
    return formatChangeTracking(values[key] as ChangeMetaData);
  };

  // Run util function for each key

  defaultsForKey("commonNameEnglishChangeMetaData");
  defaultsForKey("commonNameFrenchChangeMetaData");
  defaultsForKey("nameWithCommaChangeMetaData");
  defaultsForKey("populationChangeMetaData");
  defaultsForKey("genusChangeMetaData");
  defaultsForKey("speciesChangeMetaData");
  defaultsForKey("subSpeciesChangeMetaData");
  defaultsForKey("varietyChangeMetaData");
  defaultsForKey("endemicStatusChangeMetaData");
  defaultsForKey("rangesChangeMetaData");
  defaultsForKey("taxonomicGroupChangeMetaData");
};
