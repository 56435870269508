import { UseQueryResult } from "@tanstack/react-query";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { QueryToolFormFields } from "../QueryToolForm";
import { QueryFieldsListResponse } from "../SetConditionsSection/SetConditionsSection";
import { CosewicCheckboxes } from "./CosewicCheckboxes";
import { ListingCheckboxes } from "./ListingCheckboxes";

export enum SetFixedProperty {
  CosewicId = "cosewic_id",
  ListingId = "listing_id",
  CosewicCommonNameWithPopulationEnglish = "cosewic_common_name_with_population_en",
  CosewicCommonNameWithPopulationFrench = "cosewic_common_name_with_population_fr",
  ListingCommonNameWithPopulationEnglish = "listing_common_name_with_population_en",
  ListingCommonNameWithPopulationFrench = "listing_common_name_with_population_fr",
}

export interface SelectColumnsSectionProps {
  initialValues: QueryToolFormFields;
  cosewicFieldsQuery: UseQueryResult<QueryFieldsListResponse, unknown>;
  listingFieldsQuery: UseQueryResult<QueryFieldsListResponse, unknown>;
}

export const SelectColumnsSection: React.FC<SelectColumnsSectionProps> = ({
  initialValues,
  cosewicFieldsQuery,
  listingFieldsQuery,
}) => {
  const { t, i18n } = useTranslation();

  const form = useFormContext<QueryToolFormFields>(); //TODO: replace any
  const { control, register, watch, formState } = form;
  const { errors } = formState;

  const loadingQuery =
    cosewicFieldsQuery.isLoading || listingFieldsQuery.isLoading;

  const errorQuery = cosewicFieldsQuery.isError || listingFieldsQuery.isError;

  return (
    <SectionCard
      header={
        <>
          <h2>{t("select_columns_for_output")}</h2>
          {loadingQuery && <LoadingIndicator className="mrgn-lft-md" />}
        </>
      }
      collapsible
      open={errors.setFixedProperty != null}
    >
      <div>
        {loadingQuery ? (
          <LoadingIndicator centered className="mrgn-tp-md mrgn-bttm-md" />
        ) : errorQuery ? null : (
          <>
            <div className="form-group mrgn-tp-md">
              <label htmlFor="sel_set_fixed_property" className="required">
                {t("set_fixed_property")}
                <strong className="required"> ({t("required")})</strong>
              </label>
              <select
                id="sel_set_fixed_property"
                data-testid="sel_set_fixed_property"
                title={t("set_fixed_property")}
                className="form-control"
                defaultValue={initialValues.setFixedProperty ?? ""}
                {...register("setFixedProperty", { required: true })}
              >
                <option hidden value={""}>
                  {t("please_select_option")}
                </option>
                <option value={SetFixedProperty.CosewicId}>
                  {t(SetFixedProperty.CosewicId)}
                </option>
                <option value={SetFixedProperty.ListingId}>
                  {t(SetFixedProperty.ListingId)}
                </option>
                <option
                  value={
                    SetFixedProperty.CosewicCommonNameWithPopulationEnglish
                  }
                >
                  {t(SetFixedProperty.CosewicCommonNameWithPopulationEnglish)}
                </option>
                <option
                  value={SetFixedProperty.CosewicCommonNameWithPopulationFrench}
                >
                  {t(SetFixedProperty.CosewicCommonNameWithPopulationFrench)}
                </option>
                <option
                  value={
                    SetFixedProperty.ListingCommonNameWithPopulationEnglish
                  }
                >
                  {t(SetFixedProperty.ListingCommonNameWithPopulationEnglish)}
                </option>
                <option
                  value={SetFixedProperty.ListingCommonNameWithPopulationFrench}
                >
                  {t(SetFixedProperty.ListingCommonNameWithPopulationFrench)}
                </option>
              </select>
              {errors.setFixedProperty && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            </div>

            <SectionCard
              header={
                <div className="flexbug-9-workaround">
                  <div className="flex justify-between">
                    <h3 className="mrgn-tp-sm mrgn-bttm-sm">{t("cosewic")}</h3>
                    <span className="badge height-fit-content">
                      {watch("cosewicItems")?.length ?? "0"}
                      <span className="wb-inv"> options selected</span>
                    </span>
                  </div>
                </div>
              }
              collapsible
              showLine={false}
              open={true}
            >
              <Controller
                name={"cosewicItems"}
                render={({ field: { onChange } }) => (
                  <CosewicCheckboxes
                    onChange={onChange}
                    defaultSelected={[]}
                    cosewicFieldsQuery={cosewicFieldsQuery}
                  />
                )}
                control={control}
              />
            </SectionCard>

            <SectionCard
              header={
                <div className="flexbug-9-workaround">
                  <div className="flex justify-between">
                    <h3 className="mrgn-tp-sm mrgn-bttm-sm">{t("listing")}</h3>
                    <span className="badge height-fit-content">
                      {watch("listingItems")?.length ?? "0"}
                      <span className="wb-inv"> options selected</span>
                    </span>
                  </div>
                </div>
              }
              collapsible
              showLine={false}
              open={false}
            >
              <Controller
                name={"listingItems"}
                render={({ field: { onChange } }) => (
                  <ListingCheckboxes
                    onChange={onChange}
                    defaultSelected={[]}
                    listingFieldsQuery={listingFieldsQuery}
                  />
                )}
                control={control}
              />
            </SectionCard>
          </>
        )}
      </div>
    </SectionCard>
  );
};
