import { ODataMapper } from "../types";

const documentsOdataMappers: Record<string, ODataMapper> = {
  Stage: {
    key: "Stage",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ Stage: x })),
      };
    },
  },

  State: {
    key: "State",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ State: x })),
      };
    },
  },

  ContentOwner: {
    key: "ContentOwner",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ ContentOwner: x })),
      };
    },
  },

  // This is the reference for using strings as filters for Odata  queries: https://www.npmjs.com/package/odata-query?activeTab=readme#strings
  "Programs/NameEn": {
    key: "Programs/NameEn",
    filter(searchValues: string[]) {
      const commaSeparatedEncodedValues = searchValues
        .map((x) => encodeURIComponent(x))
        .join(",");
      return `Programs/any(p: search.in(p/NameEn, '${commaSeparatedEncodedValues}' ,','))`;
    },
  },

  "Programs/NameFr": {
    key: "Programs/NameFr",
    filter(searchValues: string[]) {
      const commaSeparatedEncodedValues = searchValues
        .map((x) => encodeURIComponent(x))
        .join(",");
      return `Programs/any(p: search.in(p/NameFr, '${commaSeparatedEncodedValues}' ,','))`;
    },
  },

  "DocumentType/Tags": {
    key: "DocumentType/Tags",
    filter(searchValues: string[]) {
      return `DocumentType/Tags/any(p: search.in(p, '${searchValues.join(
        ", "
      )}'))`;
    },
  },

  PublicationType: {
    key: "PublicationType",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ PublicationType: x })),
      };
    },
  },
};

export default Object.freeze(documentsOdataMappers);
