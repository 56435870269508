import FormButtons from "components/molecules/FormButtons/FormButtons";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AssessmentType } from "../../../../../generated/gql-types";
import { filterForDirtyFields } from "../../../../../util/forms";
import {
  AssessmentFormChangeTracking,
  AssessmentFormFields,
  AssessmentFormOutput,
} from "../AssessmentForm";
import AssessmentBasicInfoCard from "../AssessmentForm/AssessmentBasicInfoCard";

export interface AssessmentCreateFormProps {
  disabled?: boolean;
  onSubmit: SubmitHandler<Partial<AssessmentFormOutput>>; // TODO: need interface for only basic info fields
}

export const AssessmentCreateForm: React.FC<AssessmentCreateFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;

  const form = useForm<AssessmentFormFields & AssessmentFormChangeTracking>({
    defaultValues: { type: AssessmentType.Regular },
  });
  const { handleSubmit, formState } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<AssessmentFormOutput>> = async (
    formData
  ) => {
    const values = filterForDirtyFields(formData, dirtyFields);
    props.onSubmit(values);
  };

  const onCancel = () => {
    history.push({
      pathname: CosewicPathHelpers.CosewicProfile(cosewicWsId ?? "error"),
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={props.disabled}>
          {/* ------------ Assessment Information Section ----------------- */}
          <AssessmentBasicInfoCard />
        </fieldset>
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.disabled}
          onCancel={onCancel}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
};

export default AssessmentCreateForm;
