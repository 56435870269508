/**
 * Here we map facet keys to odata objects. These objects are passed to the `odata-query` library,
 * which turns them into formatted odata queries that we can send to azure cognitive search.
 *
 * The shape of the object should usually follow the same nested "path" structure as its key,
 * and add things like "and/or/any" logic to search through collections.
 *
 * In the basic search, the user selects the checkboxes they want to search for,
 * and we pass them to the `filter` function that corresponds to their section.
 * Each section represents one of the facets (keys) listed in this file and in the `facetsList.json` file.
 *
 * Most of these functions have a test to ensure they generate the expected filter string.
 * Tests are located in src/components/organisms/search/FilterOptionsSection/filters/filters.test.ts
 */

const facetFilterMap = {
  "Cosewic/ActiveStatus": {
    key: "Cosewic/ActiveStatus",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          or: searchValues.map((x) => ({ ActiveStatus: x })),
        },
      };
    },
  },

  "Cosewic/TaxonomicGroup/NameFr": {
    key: "Cosewic/TaxonomicGroup/NameFr",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          TaxonomicGroup: {
            or: searchValues.map((x) => ({ NameFr: x })),
          },
        },
      };
    },
  },

  "Cosewic/TaxonomicGroup/NameEn": {
    key: "Cosewic/TaxonomicGroup/NameEn",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          TaxonomicGroup: {
            or: searchValues.map((x) => ({ NameEn: x })),
          },
        },
      };
    },
  },

  "Cosewic/Ranges/NameFr": {
    key: "Cosewic/Ranges/NameFr",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          Ranges: {
            any: [{ or: searchValues.map((x) => ({ NameFr: x })) }],
          },
        },
      };
    },
  },

  "Cosewic/Ranges/NameEn": {
    key: "Cosewic/Ranges/NameEn",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          Ranges: {
            any: [{ or: searchValues.map((x) => ({ NameEn: x })) }],
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/Type": {
    key: "Cosewic/LatestAssessment/Type",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestAssessment: { or: searchValues.map((x) => ({ Type: x })) },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/Type": {
    key: "Cosewic/LatestPublishedAssessment/Type",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            or: searchValues.map((x) => ({ Type: x })),
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/ReportType": {
    key: "Cosewic/LatestAssessment/ReportType",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestAssessment: {
            or: searchValues.map((x) => ({ ReportType: x })),
          },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/ReportType": {
    key: "Cosewic/LatestPublishedAssessment/ReportType",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            or: searchValues.map((x) => ({ ReportType: x })),
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/StatusAndCriteria/Status": {
    key: "Cosewic/LatestAssessment/StatusAndCriteria/Status",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestAssessment: {
            StatusAndCriteria: {
              or: searchValues.map((x) => ({ Status: x })),
            },
          },
        },
      };
    },
  },

  "Cosewic/Assessments/StatusAndCriteria/Status": {
    key: "Cosewic/Assessments/StatusAndCriteria/Status",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          Assessments: {
            any: [
              {
                StatusAndCriteria: {
                  or: searchValues.map((x) => ({ Status: x })),
                },
              },
            ],
          },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/StatusAndCriteria/Status": {
    key: "Cosewic/LatestPublishedAssessment/StatusAndCriteria/Status",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            StatusAndCriteria: {
              or: searchValues.map((x) => ({ Status: x })),
            },
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/StatusAndCriteria/StatusChange": {
    key: "Cosewic/LatestAssessment/StatusAndCriteria/StatusChange",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestAssessment: {
            StatusAndCriteria: {
              or: searchValues.map((x) => ({ StatusChange: x })),
            },
          },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/StatusAndCriteria/StatusChange": {
    key: "Cosewic/LatestPublishedAssessment/StatusAndCriteria/StatusChange",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            StatusAndCriteria: {
              or: searchValues.map((x) => ({ StatusChange: x })),
            },
          },
        },
      };
    },
  },

  "Listing/LatestGicDecision/NameEn": {
    key: "Listing/LatestGicDecision/NameEn",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestGicDecision: {
            or: searchValues.map((x) => ({ NameEn: x })),
          },
        },
      };
    },
  },

  "Listing/LatestGicDecision/NameFr": {
    key: "Listing/LatestGicDecision/NameFr",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestGicDecision: {
            or: searchValues.map((x) => ({ NameFr: x })),
          },
        },
      };
    },
  },

  "Cosewic/Status": {
    key: "Cosewic/Status",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          or: searchValues.map((x) => ({ Status: x })),
        },
      };
    },
  },

  "Cosewic/LatestAssessment/Stage": {
    key: "Cosewic/LatestAssessment/Stage",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestAssessment: { or: searchValues.map((x) => ({ Stage: x })) },
        },
      };
    },
  },

  "Cosewic/Assessments/Stage": {
    key: "Cosewic/Assessments/Stage",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          Assessments: {
            any: [
              {
                or: searchValues.map((x) => ({ Stage: x })),
              },
            ],
          },
        },
      };
    },
  },

  "Cosewic/State": {
    key: "Cosewic/State",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          or: searchValues.map((x) => ({ State: x })),
        },
      };
    },
  },

  "Cosewic/LatestAssessment/State": {
    key: "Cosewic/LatestAssessment/State",
    filter(searchValues: string[]) {
      return {
        Cosewic: {
          LatestAssessment: {
            or: searchValues.map((x) => ({ State: x })),
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/Date": {
    key: "Cosewic/LatestAssessment/Date",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Cosewic: {
          LatestAssessment: {
            Date: dateRange,
          },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/Date": {
    key: "Cosewic/LatestPublishedAssessment/Date",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            Date: dateRange,
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/YearSentToMinister": {
    key: "Cosewic/LatestAssessment/YearSentToMinister",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Cosewic: {
          LatestAssessment: {
            YearSentToMinister: dateRange,
          },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/YearSentToMinister": {
    key: "Cosewic/LatestPublishedAssessment/YearSentToMinister",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            YearSentToMinister: dateRange,
          },
        },
      };
    },
  },

  "Cosewic/LatestAssessment/DateSentToMinister": {
    key: "Cosewic/LatestAssessment/DateSentToMinister",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Cosewic: {
          LatestAssessment: {
            DateSentToMinister: dateRange,
          },
        },
      };
    },
  },

  "Cosewic/LatestPublishedAssessment/DateSentToMinister": {
    key: "Cosewic/LatestPublishedAssessment/DateSentToMinister",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Cosewic: {
          LatestPublishedAssessment: {
            DateSentToMinister: dateRange,
          },
        },
      };
    },
  },

  "Cosewic/EndemicStatus": {
    key: "Cosewic/EndemicStatus",
    filter(searchValues: string[]) {
      return {
        Cosewic: { or: searchValues.map((x) => ({ EndemicStatus: x })) },
      };
    },
  },

  "Listing/LatestProcess/ListingBatch": {
    key: "Listing/LatestProcess/ListingBatch",
    filter(numberRange: Omit<AzureSearchNumberRange, "label">) {
      return {
        Listing: {
          LatestProcess: {
            ListingBatch: numberRange,
          },
        },
      };
    },
  },

  "Listing/State": {
    key: "Listing/State",
    filter(searchValues: string[]) {
      return {
        Listing: {
          or: searchValues.map((x) => ({ State: x })),
        },
      };
    },
  },

  "Listing/LegalStatus/CompetentMinister1/NameFr": {
    key: "Listing/LegalStatus/CompetentMinister1/NameFr",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LegalStatus: {
            CompetentMinister1: {
              or: searchValues.map((x) => ({ NameFr: x })),
            },
          },
        },
      };
    },
  },

  "Listing/LegalStatus/CompetentMinister1/NameEn": {
    key: "Listing/LegalStatus/CompetentMinister1/NameEn",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LegalStatus: {
            CompetentMinister1: {
              or: searchValues.map((x) => ({ NameEn: x })),
            },
          },
        },
      };
    },
  },

  "Listing/LegalStatus/CompetentMinister2/NameFr": {
    key: "Listing/LegalStatus/CompetentMinister2/NameFr",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LegalStatus: {
            CompetentMinister2: {
              any: { or: searchValues.map((x) => ({ NameFr: x })) },
            },
          },
        },
      };
    },
  },

  "Listing/LegalStatus/CompetentMinister2/NameEn": {
    key: "Listing/LegalStatus/CompetentMinister2/NameEn",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LegalStatus: {
            CompetentMinister2: {
              any: { or: searchValues.map((x) => ({ NameEn: x })) },
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameFr": {
    key: "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameFr",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestProcess: {
            ConsultationProcess: {
              ConsultationPath: {
                or: searchValues.map((x) => ({ NameFr: x })),
              },
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameEn": {
    key: "Listing/LatestProcess/ConsultationProcess/ConsultationPath/NameEn",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestProcess: {
            ConsultationProcess: {
              ConsultationPath: {
                or: searchValues.map((x) => ({ NameEn: x })),
              },
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/RegulatoryProcess/GicDecision/NameFr": {
    key: "Listing/LatestProcess/RegulatoryProcess/GicDecision/NameFr",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestProcess: {
            RegulatoryProcess: {
              GicDecision: {
                or: searchValues.map((x) => ({ NameFr: x })),
              },
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/RegulatoryProcess/GicDecision/NameEn": {
    key: "Listing/LatestProcess/RegulatoryProcess/GicDecision/NameEn",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestProcess: {
            RegulatoryProcess: {
              GicDecision: {
                or: searchValues.map((x) => ({ NameEn: x })),
              },
            },
          },
        },
      };
    },
  },

  "Listing/ListingSchedule": {
    key: "Listing/ListingSchedule",
    filter(searchValues: string[]) {
      return {
        Listing: {
          or: searchValues.map((x) => ({ ListingSchedule: x })),
        },
      };
    },
  },

  "Listing/LatestProcess/Schedule1Status": {
    key: "Listing/LatestProcess/Schedule1Status",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestProcess: {
            or: searchValues.map((x) => ({ Schedule1Status: x })),
          },
        },
      };
    },
  },

  "Listing/LatestProcess/ConsultationProcess/ResponseStatementDueDate": {
    key: "Listing/LatestProcess/ConsultationProcess/ResponseStatementDueDate",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Listing: {
          LatestProcess: {
            ConsultationProcess: {
              ResponseStatementDueDate: dateRange,
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/ConsultationProcess/ResponseStatementPostedDate": {
    key: "Listing/LatestProcess/ConsultationProcess/ResponseStatementPostedDate",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Listing: {
          LatestProcess: {
            ConsultationProcess: {
              ResponseStatementPostedDate: dateRange,
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameFr":
    {
      key: "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameFr",
      filter(searchValues: string[]) {
        return {
          Listing: {
            LatestProcess: {
              ConsultationProcess: {
                AmendmentAlignedToAssessment: {
                  or: searchValues.map((x) => ({ NameFr: x })),
                },
              },
            },
          },
        };
      },
    },

  "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameEn":
    {
      key: "Listing/LatestProcess/ConsultationProcess/AmendmentAlignedToAssessment/NameEn",
      filter(searchValues: string[]) {
        return {
          Listing: {
            LatestProcess: {
              ConsultationProcess: {
                AmendmentAlignedToAssessment: {
                  or: searchValues.map((x) => ({ NameEn: x })),
                },
              },
            },
          },
        };
      },
    },

  "Listing/LatestProcess/RegulatoryProcess/GicDecisionDate": {
    key: "Listing/LatestProcess/RegulatoryProcess/GicDecisionDate",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Listing: {
          LatestProcess: {
            RegulatoryProcess: {
              GicDecisionDate: dateRange,
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/RegulatoryProcess/GicReceiptDate": {
    key: "Listing/LatestProcess/RegulatoryProcess/GicReceiptDate",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Listing: {
          LatestProcess: {
            RegulatoryProcess: {
              GicReceiptDate: dateRange,
            },
          },
        },
      };
    },
  },

  "Listing/LatestProcess/RegulatoryProcess/RegulatoryBundle": {
    key: "Listing/LatestProcess/RegulatoryProcess/RegulatoryBundle",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LatestProcess: {
            RegulatoryProcess: {
              or: searchValues.map((x) => ({ RegulatoryBundle: x })),
            },
          },
        },
      };
    },
  },

  "Listing/LegalProtection/Jurisdiction/NameFr": {
    key: "Listing/LegalProtection/Jurisdiction/NameFr",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LegalProtection: {
            Jurisdiction: {
              or: searchValues.map((x) => ({ NameFr: x })),
            },
          },
        },
      };
    },
  },

  "Listing/LegalProtection/Jurisdiction/NameEn": {
    key: "Listing/LegalProtection/Jurisdiction/NameEn",
    filter(searchValues: string[]) {
      return {
        Listing: {
          LegalProtection: {
            Jurisdiction: {
              or: searchValues.map((x) => ({ NameEn: x })),
            },
          },
        },
      };
    },
  },

  "Assessment/Date": {
    key: "Assessment/Date",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Assessment: {
          Date: dateRange,
        },
      };
    },
  },

  "Assessment/Type": {
    key: "Assessment/Type",
    filter(searchValues: string[]) {
      return {
        Assessment: { or: searchValues.map((x) => ({ Type: x })) },
      };
    },
  },

  "Assessment/ReportType": {
    key: "Assessment/ReportType",
    filter(searchValues: string[]) {
      return {
        Assessment: {
          or: searchValues.map((x) => ({ ReportType: x })),
        },
      };
    },
  },

  "Assessment/StatusAndCriteria/Status": {
    key: "Assessment/StatusAndCriteria/Status",
    filter(searchValues: string[]) {
      return {
        Assessment: {
          StatusAndCriteria: {
            or: searchValues.map((x) => ({ Status: x })),
          },
        },
      };
    },
  },

  "Assessment/StatusAndCriteria/StatusChange": {
    key: "Assessment/StatusAndCriteria/StatusChange",
    filter(searchValues: string[]) {
      return {
        Assessment: {
          StatusAndCriteria: {
            or: searchValues.map((x) => ({ StatusChange: x })),
          },
        },
      };
    },
  },

  "Assessment/Stage": {
    key: "Assessment/Stage",
    filter(searchValues: string[]) {
      return {
        Assessment: { or: searchValues.map((x) => ({ Stage: x })) },
      };
    },
  },

  "Assessment/State": {
    key: "Assessment/State",
    filter(searchValues: string[]) {
      return {
        Assessment: { or: searchValues.map((x) => ({ State: x })) },
      };
    },
  },

  "Assessment/YearSentToMinister": {
    key: "Assessment/YearSentToMinister",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Assessment: {
          YearSentToMinister: dateRange,
        },
      };
    },
  },

  "Assessment/DateSentToMinister": {
    key: "Assessment/DateSentToMinister",
    filter(dateRange: Omit<AzureSearchDateRange, "label">) {
      return {
        Assessment: {
          DateSentToMinister: dateRange,
        },
      };
    },
  },

  "Programs/NameEn": {
    key: "Programs/NameEn",
    filter(searchValues: string[]) {
      return {
        Document: {
          Programs: {
            any: [{ or: searchValues.map((x) => ({ NameEn: x })) }],
          },
        },
      };
    },
  },

  "Programs/NameFr": {
    key: "Programs/NameFr",
    filter(searchValues: string[]) {
      return {
        Document: {
          Programs: {
            any: [{ or: searchValues.map((x) => ({ NameFr: x })) }],
          },
        },
      };
    },
  },

  WsInfoChangesUnpublished: {
    key: "WsInfoChangesUnpublished",
    filter(searchValues: string[]) {
      return {
        or: searchValues.map((x) => ({ WsInfoChangesUnpublished: x })),
      };
    },
  },
};

/**
 * Date range format for Azure Search/OData
 *
 * ge = greater than / equal to (optional)
 * le = less than / equal to (optional)
 * More range operators are available, but as of May 9 2022 we only use the two above.
 */
// TODO(SEARCH): move to features folder/types ?
export interface AzureSearchDateRange {
  label?: string;
  ge?: Date;
  le?: Date;
}

/**
 * Number range format for Azure Search/OData
 *
 * ge = greater than / equal to (optional)
 *
 * le = less than / equal to (optional)
 *
 * More range operators are available, but as of May 9 2022 we only use the two above.
 */
// TODO(SEARCH): move to features folder/types ?
export interface AzureSearchNumberRange {
  label?: string;
  ge?: number;
  le?: number;
}

export default Object.freeze(facetFilterMap);
