import EditLink from "components/atoms/EditLink";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { Assessment } from "generated/gql-types";
import { useTranslation } from "react-i18next";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

export interface HistoryOfStatusDesignationCardProps {
  header: string;
  open: boolean;
  editLinkTo: string;
  assessment?: Assessment;
  editDisabled: boolean;
}

export const HistoryOfStatusDesignationCard: React.FC<
  HistoryOfStatusDesignationCardProps
> = (props) => {
  const { assessment, editLinkTo, header, open } = props;

  const { t } = useTranslation();

  return (
    <SectionCard
      open={open}
      header={
        <div className="flex justify-between">
          <h2 className="mrgn-tp-sm">{t("history_status_designation")}</h2>
          <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.cosewic.update}>
            <EditLink
              linkTo={editLinkTo}
              state={header}
              disabled={props.editDisabled}
            />
          </RenderWhenAuthorized>
        </div>
      }
    >
      <div className="species-field">
        <dl>
          <dt className="text-muted">
            {t("history_status_designation")}
            {", "}
            {t("english")}
          </dt>
          <dd>
            {htmlIsNullOrEmpty(
              assessment?.designation?.historyStatus?.english?.text
            ) ? (
              <MissingData />
            ) : (
              <SafeRenderHtml
                htmlString={
                  assessment?.designation?.historyStatus?.english?.text
                }
              />
            )}
          </dd>
        </dl>

        <dl>
          <dt className="text-muted">
            {t("history_status_designation")}
            {", "}
            {t("french")}
          </dt>
          <dd>
            {htmlIsNullOrEmpty(
              assessment?.designation?.historyStatus?.french?.text
            ) ? (
              <MissingData />
            ) : (
              <SafeRenderHtml
                htmlString={
                  assessment?.designation?.historyStatus?.french?.text
                }
              />
            )}
          </dd>
        </dl>

        <dl>
          <dt className="text-muted">{t("designation_reason_english")}</dt>
          <dd>
            {htmlIsNullOrEmpty(
              assessment?.designation?.reason?.english?.text
            ) ? (
              <MissingData />
            ) : (
              <SafeRenderHtml
                htmlString={assessment?.designation?.reason?.english?.text}
              />
            )}
          </dd>
        </dl>

        <dl>
          <dt className="text-muted">{t("designation_reason_french")}</dt>
          <dd>
            {htmlIsNullOrEmpty(
              assessment?.designation?.reason?.french?.text
            ) ? (
              <MissingData />
            ) : (
              <SafeRenderHtml
                htmlString={assessment?.designation?.reason?.french?.text}
              />
            )}
          </dd>
        </dl>
      </div>
    </SectionCard>
  );
};
export default HistoryOfStatusDesignationCard;
