import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./translations";

export { resources };
export { i18n };
export { initReactI18next };

// Docs: https://react.i18next.com/latest/i18next-instance
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: false,
  });

export default i18n;

// Currently the provider does nothing but set up i18next and react-i18next when the module is first imported.
// Update Feb 25 2022 - Only StorybookCustomProviders relies on this now. Storybook setup can probably
// be updated to import i18n in another way.
export const Provider: React.FC = ({ children }) => {
  return <>{children}</>;
};
