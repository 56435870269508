const topNavTranslation = {
  en: {
    home: "Home",
    search_wildlife_species: "Search wildlife species",
    search_assessments: "Search assessments",
  },

  fr: {
    home: "Accueil",
    search_wildlife_species: "Rechercher une espèce sauvage",
    search_assessments: "Rechercher une évaluation",
  },
};

export default topNavTranslation;
