import {
  LandType,
  ListingWsLand,
  ListingWsLandInput,
  Maybe,
  ProvinceAndTerritory,
} from "generated/gql-types";
import { ListingLandFormFields } from "./ListingLandForm";
import { makeBilingualText, makeNumericRange } from "mappers";

export function mapDomainModelToForm(
  domainModel: Maybe<Partial<ListingWsLand>>
): Partial<ListingLandFormFields> {
  const out: Partial<ListingLandFormFields> = {};

  if (domainModel?.landRef)
    out.landRef = {
      id: domainModel?.landRef?.id ?? "",
      landManager: makeBilingualText(domainModel?.landRef?.landManager),
      landName: domainModel?.landRef?.landName ?? "",
      landSize: domainModel?.landRef?.landSize ?? 0,
      landType: domainModel?.landRef?.landType ?? LandType.Federal, // TODO: null?
      dfrp: domainModel?.landRef?.dfrp ?? 0,
      provinceAndTerritory:
        domainModel?.landRef?.provinceAndTerritory ??
        ProvinceAndTerritory.Alberta, // TODO: null?
    };

  if (domainModel?.wildlifeSpeciesPresence)
    out.wildlifeSpeciesPresence = domainModel?.wildlifeSpeciesPresence;
  if (domainModel?.numberOfSites)
    out.numberOfSites = domainModel?.numberOfSites;

  if (domainModel?.approximateNumberOfIndividuals)
    out.approximateNumberOfIndividuals =
      domainModel?.approximateNumberOfIndividuals;

  if (domainModel?.circumstance) out.circumstance = domainModel?.circumstance;
  if (domainModel?.source) out.source = domainModel?.source;

  return out;
}

export function mapFormToCreateInput(
  form: Partial<ListingLandFormFields>
): Partial<ListingWsLandInput> {
  const out: Partial<ListingWsLandInput> = {};

  if (form?.landRef)
    out.landRef = {
      id: form?.landRef?.id ?? "",
      landManager: makeBilingualText(form?.landRef?.landManager),
      landName: form?.landRef?.landName ?? "",
      landSize: form?.landRef?.landSize ?? 0,
      landType: form?.landRef?.landType ?? LandType.Federal, // TODO: null?
      dfrp: form?.landRef?.dfrp ?? 0,
      provinceAndTerritory:
        form?.landRef?.provinceAndTerritory ?? ProvinceAndTerritory.Alberta, // TODO: null?
    };

  if (form?.wildlifeSpeciesPresence)
    out.wildlifeSpeciesPresence = form?.wildlifeSpeciesPresence;
  if (form?.numberOfSites)
    out.numberOfSites = parseInt(form?.numberOfSites.toString()); //  Typescript says this assignment is ok, but at runtime the input may come through as a string; make sure to convert it to a number.

  if (form?.approximateNumberOfIndividuals) {
    out.approximateNumberOfIndividuals = form?.approximateNumberOfIndividuals;
  }

  if (form?.circumstance) out.circumstance = form?.circumstance;
  if (form?.source) out.source = form?.source;

  return out;
}

export function mapFormToUpdateInput(form: Partial<ListingLandFormFields>) {
  // NOTE: the types for Update and Create are the same for listing land; ListingWsLandInput.
  return mapFormToCreateInput(form);
}
