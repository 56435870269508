import EditLink from "components/atoms/EditLink";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import {
  ArchiveStatus,
  Maybe,
  PhotoLicense,
  PhotoLicenseType,
} from "generated/gql-types";
import i18n from "i18n";
import { bilingualRichTextForLanguage } from "mappers";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";

interface PhotoLicenseInfoCardProps {
  photosLicenseData?: Maybe<PhotoLicense>;
}

export const PhotoLicenseInfoCard: React.FC<PhotoLicenseInfoCardProps> = (
  props
) => {
  const { photosLicenseData } = props;
  const { t } = useTranslation();

  const getLicenseConditionMessage = (
    licenseType?: PhotoLicenseType | null
  ) => {
    switch (licenseType) {
      case PhotoLicenseType.FullUse:
        return t("licence_condition_full_use_message");
      case PhotoLicenseType.LimitedUse:
        return t("licence_condition_limited_use_message");
      case PhotoLicenseType.OneTimeUse:
        return t("licence_condition_one_time_use_message");
      case PhotoLicenseType.CrownCopyright:
        return t("licence_condition_crown_copyright_use_message");
      default:
        return "";
    }
  };

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("photo_licence_information")}</h2>
          {photosLicenseData?.stage === ArchiveStatus.Active && (
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.photos.update}
            >
              <EditLink
                linkTo={DocumentsPathHelpers.PhotoLicenseEdit(
                  photosLicenseData?.id ?? "error"
                )}
                disabled={photosLicenseData?.id == null}
              />
            </RenderWhenAuthorized>
          )}
        </div>
      }
    >
      <div className="species-field">
        {/* ------------ Created details ------------ */}
        <div className="row">
          <dl>
            {/* ------------ Created by ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("created_by")}</dt>
              <dd>
                {isNullOrEmpty(photosLicenseData?.createdBy) ? (
                  <MissingData />
                ) : (
                  photosLicenseData?.createdBy
                )}
              </dd>
            </div>

            {/* ------------ Created Date ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("creation_date")}</dt>

              <dd>
                {isNullOrEmpty(photosLicenseData?.createdAt) ? (
                  <MissingData />
                ) : (
                  formatTimestamp(photosLicenseData?.createdAt)
                )}
              </dd>
            </div>
          </dl>
        </div>
        {/* ------------ Note ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("note")}</dt>
              <dd>
                {htmlIsNullOrEmpty(photosLicenseData?.note?.text) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={photosLicenseData?.note?.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Photographer ------------ */}
        <h2 className="font-size-18 mrgn-tp-sm">{t("licence_information")}</h2>

        <div className="row">
          <dl>
            {/* ------------ License Type ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("photographer")}</dt>
              <dd className="width-100">
                {photosLicenseData?.photographerFormatted == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={bilingualRichTextForLanguage(
                      i18n.language,
                      photosLicenseData?.photographerFormatted
                    )}
                  />
                )}
              </dd>
            </div>

            {/* ------------ License Type ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">
                {t("licence_type")}
                <InfoButtonAndModal title={t("licence_conditions")}>
                  <SafeRenderHtml
                    htmlString={getLicenseConditionMessage(
                      photosLicenseData?.licenseType
                    )}
                  />
                </InfoButtonAndModal>
              </dt>
              <dd>
                {isNullOrEmpty(photosLicenseData?.licenseType) ? (
                  <MissingData />
                ) : (
                  t(photosLicenseData?.licenseType ?? "")
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </SectionCard>
  );
};

export default PhotoLicenseInfoCard;
