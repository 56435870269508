const changeLogTranslation = {
  en: {
    // Page titles
    change_log: "Change log",
    change_details: "Change details",

    // Name changes component column titles
    english_name: "English name",
    french_name: "French name",
    english_common_name: "English common name",
    french_common_name: "French common name",
    english_population: "English population",
    french_population: "French population",
    date_changed: "Date changed",
    no_change: "No change",

    // Change Log Tags
    date: "Date",
    sub_species: "Subspecies",
    variety: "Variety",
    species: "Species",
    genus: "Genus",
    endemic_status: "Endemic",
    ranges: "Ranges",
    population: "Population",
    //population_english: "Population, English",
    //population_french: "Population, French",
    taxonomic_group: "Taxonomic group",
    name_with_comma: "English Name with comma",
    common_name_english: "Common name, English",
    common_name_french: "Common name, French",
    scientific_name: "Scientific name",
    status_comment_english: "Extinct / Extirpated since, English",
    status_comment_french: "Extinct / Extirpated since, French",
    status_criteria_english: "Status criteria, English",
    status_criteria_french: "Status criteria, French",
    applicability_criteria_a: "Applicability of criteria A",
    applicability_criteria_b: "Applicability of criteria B",
    applicability_criteria_c: "Applicability of criteria C",
    applicability_criteria_d: "Applicability of criteria D",
    applicability_criteria_e: "Applicability of criteria E",
    designation_history_english: "History of status Designation  English",
    designation_history_french: "History of status Designation  French",
    designation_reason_english: "Reason for designation, English",
    designation_reason_french: "Reason for designation, French",
    date_sent_to_minister: "COSEWIC reports sent to minister date",

    // List of changes component field titles
    list_of_changes: "List of Changes",
    what_has_changed: "What has changed?",
    section: "Section",
    changeType: "Change type",
    reasonForChange: "Comment",
  },

  fr: {
    // Page titles
    change_log: "Journal des modifications",
    change_details: "Détails des modifications",

    // Name changes component column titles
    english_name: "Nom anglais",
    french_name: "Nom français",
    english_common_name: "Nom commun anglais",
    french_common_name: "Nom commun français",
    english_population: "Population anglaise",
    french_population: "Population française",
    date_changed: "Date modifiée",
    no_change: "Pas de changement",

    // Change Log Tags
    date: "Date",
    sub_species: "Sous-espèce",
    variety: "Variété",
    species: "Espèces",
    genus: "Genre",
    endemic_status: "Endémique",
    ranges: "Aire de répartition",
    population: "Population",
    //population_english: "Population, anglais",
    //population_french: "Population, français",
    taxonomic_group: "Groupe taxonomique",
    name_with_comma: "Nom anglais avec virgule",
    common_name_english: "Nom commun, anglais",
    common_name_french: "Nom commun, français",
    scientific_name: "Nom scientifique",
    status_comment_english: "Éteint / Disparu depuis, anglais",
    status_comment_french: "Éteint / Disparu depuis, français",
    status_criteria_english: "Critères de désignation du statut, Anglais",
    status_criteria_french: "Critères de désignation du statut, Français",
    applicability_criteria_a: "Applicabilité des critères A",
    applicability_criteria_b: "Applicabilité des critères B",
    applicability_criteria_c: "Applicabilité des critères C",
    applicability_criteria_d: "Applicabilité des critères D",
    applicability_criteria_e: "Applicabilité des critères E",
    designation_history_english: "Historique des désignations, anglais",
    designation_history_french: "Historique des désignations, français",
    designation_reason_english: "Justification de la désignation, anglais",
    designation_reason_french: "Justification de la désignation, français",
    date_sent_to_minister:
      "Date à laquelle les rapports du COSEPAC sont envoyés au ministre",

    // List of changes component field titles
    list_of_changes: "Liste des modifications",
    what_has_changed: "Qu'est-ce qui a changé?",
    section: "Section",
    changeType: "Type de modification",
    reasonForChange: "Commentaire",
  },
};

export default changeLogTranslation;
